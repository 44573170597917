import React, { useState } from "react";
import { useMutation } from "react-query";
import { Alert, AutoComplete, Input, Space, Typography } from "antd";

import { orderLookup } from "../../api";
import { Warehouse, Fulfillment } from "@secondcloset/types";
import { StorageCustomer, storageCustomerLookup } from "../../api/warehouse";
import { MANUAL_ITEM_TYPE } from "../../pages/ManualItems/helpers";
import SelectedCustomerInfo from "./SelectedCustomerInfo";
import SelectedOrderInfo from "./SelectedOrderInfo";

type ManualItemType = Warehouse.ManualItemType;
type Order = Fulfillment.Order;

interface Props {
  size?: any;
  style?: any;
  onLoad?: (bool: boolean) => void;
  onError?: (e: string) => void;
  onSelect?: (v: any) => void;
  type?: ManualItemType;
}

const OrderAndCustomerSearchBar: React.FC<Props> = ({
  size,
  style,
  onLoad,
  onError,
  onSelect,
  type,
}) => {
  const [query, setQuery] = useState<string>();
  const [searchedOrders, setSearchedOrders] = useState<Order[]>([]);
  const [searchedCustomers, setSearchedCustomers] = useState<StorageCustomer[]>(
    []
  );
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const [selectedCustomerName, setSelectedCustomerName] = useState<string>();

  const { mutate: searchOrder, isLoading: isFetching } = useMutation(
    (value: string) => orderLookup(value),
    {
      onError: (e: string) => {
        if (onError) onError(e);
      },
      onSuccess: (data) => {
        if (data && data.length > 0) {
          setSearchedOrders(data);
          if (onLoad) onLoad(false);
        } else {
          if (onError) onError(`No results for external order no. "${query}"`);
        }
      },
    }
  );
  const { mutate: searchCustomer, isLoading: isFetchingCustomer } = useMutation(
    (name: string) => storageCustomerLookup(name),
    {
      onError: (e: string) => {
        if (onError) onError(e);
      },
      onSuccess: (data) => {
        if (data && data.length > 0) {
          setSearchedCustomers(data);
          if (onLoad) onLoad(false);
        } else {
          if (onError) onError(`No results for customer with name: "${name}"`);
        }
      },
    }
  );

  const isStorage = type === MANUAL_ITEM_TYPE.STORAGE;

  const onSearch = (value: string) => {
    if (!value?.trim()) return;
    if (onLoad) onLoad(true);
    if (onError) onError("");
    if (onSelect) onSelect(undefined);
    setSearchedOrders([]);
    setSearchedCustomers([]);
    setSelectedCustomerName(undefined);
    setSelectedOrder(undefined);
    if (isStorage) searchCustomer(value?.trim());
    else searchOrder(value?.trim());
  };

  const onSelectOption = (_, data) => {
    if (onError) onError("");
    if (onSelect) {
      onSelect(isStorage ? data.value : data.order);
    }

    if (isStorage) setSelectedCustomerName(data.value);
    else setSelectedOrder(data.order);
    setSearchedOrders([]);
    setSearchedCustomers([]);
  };

  const onValueChange = (value: string) => {
    if (onSelect) onSelect(undefined);
    setSearchedOrders([]);
    setSearchedCustomers([]);
    setSelectedCustomerName(undefined);
    setSelectedOrder(undefined);
    setQuery(value);
  };

  const renderOrderSearchResult = (order) => {
    return (
      <Space>
        <Typography.Text>{order?.external_order_number}</Typography.Text>
        <Typography.Text style={{ fontSize: 12 }} type="secondary">
          {order?.order_id}
        </Typography.Text>
        <Typography.Text style={{ fontSize: 12 }} type="secondary">
          - {order?.organization?.name}
        </Typography.Text>
      </Space>
    );
  };

  const renderCustomerSearchResult = (name: string, userCode: string) => {
    return (
      <Space>
        <Typography.Text>{name}</Typography.Text>
        <Typography.Text style={{ fontSize: 12 }} type="secondary">
          - {userCode}
        </Typography.Text>
      </Space>
    );
  };

  const renderSelectedOption = () => {
    if (!selectedOrder && !selectedCustomerName) {
      return (
        <Alert
          type="info"
          showIcon
          message={`You must select ${
            isStorage ? "a customer" : "an order"
          } to proceed`}
        />
      );
    }

    if (isStorage) return <SelectedCustomerInfo name={selectedCustomerName} />;
    return <SelectedOrderInfo order={selectedOrder} />;
  };

  const getAutoCompleteOptions = () => {
    if (isStorage) {
      return searchedCustomers.map((e) => {
        const full_name = `${e.first_name} ${e.last_name}`;
        return {
          key: full_name,
          value: `${full_name} (${e.user_code})`,
          label: renderCustomerSearchResult(full_name, e.user_code),
        };
      });
    }

    return searchedOrders?.map((e) => ({
      key: e.id,
      order: e,
      value: e.external_order_number,
      label: renderOrderSearchResult(e),
    }));
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <AutoComplete
        open={
          !!query?.trim() &&
          (setSearchedOrders.length > 0 || setSearchedCustomers.length > 0) &&
          !isFetching &&
          !isFetchingCustomer
        }
        value={query}
        style={{ width: "100%", ...style }}
        options={getAutoCompleteOptions()}
        disabled={isFetching || isFetchingCustomer || !type}
        onSelect={onSelectOption}
        onChange={onValueChange}
      >
        <Input.Search
          size={size}
          loading={isFetching || isFetchingCustomer}
          placeholder={
            isStorage ? "Search by customer name" : "Search by external order #"
          }
          onSearch={onSearch}
          enterButton
          allowClear
        />
      </AutoComplete>
      {renderSelectedOption()}
    </Space>
  );
};

export default OrderAndCustomerSearchBar;
