import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import {
  Button,
  Col,
  Divider,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";

import {
  createShippingPackage,
  handoverPalletPackages,
} from "../../../api/warehouse";

import {
  custodyTransferFlowPalletPackageState,
  custodyTransferFlowScannedInputState,
} from "../../../recoil/custodyTransfer/atoms";

import {
  PACKAGE_STATUS_COLOR,
  PACKAGE_STATUS_LABEL,
} from "../../CarrierTransferPackagesPage/helper";

import PageContainer from "../../../components/PageContainer";
import ItemCountFooter from "../../../components/Table/ItemCountFooter";
import NullSafeDate from "../../../components/Table/NullSafeDate";
import NullSafeText from "../../../components/Table/NullSafeText";

type CarrierTransferPackageStatus = Warehouse.CarrierTransferPackageStatus;

const { Title, Text } = Typography;

const TransferCustodyScannerPage: React.FC = () => {
  const history = useHistory();
  const ctPackages = useRecoilValue(custodyTransferFlowPalletPackageState);
  const scannedInputValue = useRecoilValue(
    custodyTransferFlowScannedInputState
  );
  const [error, setError] = useState("");

  useEffect(() => {
    if (!scannedInputValue) {
      history.replace("transfer-custody-scanner");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isShippingPallet = ctPackages?.id;
  const itemsTableColumn = [
    {
      title: "Tracking Number",
      dataIndex: "tracking_number",
      key: "tracking_number",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <Tag color={PACKAGE_STATUS_COLOR[status]}>
          {PACKAGE_STATUS_LABEL[status]}
        </Tag>
      ),
    },
    {
      title: "Carrier",
      dataIndex: "carrier_name",
      key: "carrier_name",
    },
    {
      title: "Pallet ID",
      dataIndex: "carrier_transfer_pallet_id",
      key: "carrier_transfer_pallet_id",
      render: (palletId: string) => <NullSafeText value={palletId} />,
    },
    {
      title: "Organization",
      dataIndex: ["organization", "name"],
      key: "organization",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date: string) => <NullSafeDate date={date} />,
    },
  ];

  const { mutate: onCreatePackage, isLoading: isPackageUpdateLoading } =
    useMutation(
      () =>
        createShippingPackage({
          status: "with_carrier" as CarrierTransferPackageStatus,
          tracking_number:
            ctPackages?.carrier_transfer_packages?.[0]?.tracking_number || "",
        }),
      {
        onError: setError,
        onSuccess: () => {
          history.replace("/transfer-custody-success");
        },
      }
    );

  const {
    mutate: onHandoverPalletPackages,
    isLoading: isBatchHandoverLoading,
  } = useMutation(() => handoverPalletPackages(ctPackages?.id), {
    onError: setError,
    onSuccess: () => {
      history.replace("/transfer-custody-success");
    },
  });

  const handleTransferCustody = () => {
    if (isShippingPallet) {
      // batch transfer custody
      onHandoverPalletPackages();
    } else {
      onCreatePackage();
      // create/transfer single package
    }
  };

  const renderTableFooter = () => (
    <ItemCountFooter
      count={ctPackages?.carrier_transfer_packages?.length || 0}
    />
  );

  const renderTransferCustodyButton = () => (
    <Popconfirm title="Confirm transfer" onConfirm={handleTransferCustody}>
      <Button
        size="large"
        type="primary"
        block
        disabled={
          isBatchHandoverLoading ||
          isPackageUpdateLoading ||
          !ctPackages?.carrier_transfer_packages?.length
        }
      >
        Confirm Transfer
      </Button>
    </Popconfirm>
  );

  const renderPageHeader = () => (
    <>
      <PageHeader title={"Back"} onBack={() => window.history.back()} />
      <Title ellipsis>Transfer Custody</Title>
      <Title level={4} type="secondary">
        Type:{" "}
        <Text>
          {isShippingPallet ? "Shipping Pallets" : "Shipping Package"}
        </Text>
      </Title>
    </>
  );

  return (
    <PageContainer withPadding>
      {renderPageHeader()}
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <ErrorAlert error={error} />
        <Table
          size="small"
          rowKey="tracking_number"
          scroll={{ x: "max-content" }}
          style={{ width: "100%" }}
          bordered
          columns={itemsTableColumn}
          pagination={false}
          dataSource={ctPackages?.carrier_transfer_packages}
          footer={renderTableFooter}
        />
        <Divider />
        <Row align="middle" justify="center">
          <Col xs={24} lg={8}>
            {renderTransferCustodyButton()}
          </Col>
        </Row>
      </Space>
    </PageContainer>
  );
};

export default TransferCustodyScannerPage;
