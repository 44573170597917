import styled from "styled-components";

import { COLORS } from "../../styles";

export const Container = styled.div`
  display: flex;

  &.vertical {
    flex-direction: column;
  }

  &.horizontal {
    flex-direction: row;
    margin: 1rem 0;

    .label {
      margin-right: 8px;
    }
  }

  .label {
    color: ${COLORS.GREY};
  }
`;
