import Shipment from "./shipment";

import { Fulfillment } from "@secondcloset/types";
type ShipmentType = Fulfillment.Shipment;
type OrderType = Fulfillment.Order;

export default class Order {
  static hasCustomerBookingShipments = (order: OrderType) => {
    const shipments = order?.shipments || [];
    return shipments.some((shipment: ShipmentType) =>
      Shipment.isCustomerBooking(shipment)
    );
  };

  static isOrderAddressPoBox = (order?: OrderType) => {
    const address = order?.address?.address?.toLowerCase() || "";
    const keyWords = ["po box", "pobox", "p o box", "p.o. box", "box"];
    return keyWords.some((w) => address.includes(w));
  };
}
