import React from "react";
import { Button, Modal as AntdModal } from "antd";
import { ModalFuncProps } from "antd/lib/modal";

// styles
import { ModalButtons, ModalTitle } from "./styles";

interface Props extends ModalFuncProps {
  isDoneButtonDisabled?: boolean;
  isCancelButtonDisabled?: boolean;
}

const Modal: React.FC<Props> = (props) => {
  return (
    <AntdModal
      {...props}
      title={null}
      footer={null}
      closable={false}
      destroyOnClose
    >
      <ModalTitle> {props.title} </ModalTitle>
      {props.children}
      <ModalButtons>
        <Button
          onClick={props.onCancel}
          size="large"
          disabled={props.isCancelButtonDisabled}
        >
          CANCEL
        </Button>

        <Button
          type="primary"
          onClick={props.onOk}
          size="large"
          disabled={props.isDoneButtonDisabled}
        >
          DONE
        </Button>
      </ModalButtons>
    </AntdModal>
  );
};

export default Modal;
