import {
  BaseProductItem,
  VirtualKitItem,
} from "@secondcloset/fulfillment-utils";
import { InputNumber, Modal, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  currentPackageItemIndexState,
  lotNumberTrackingFlowEnabledState,
  LotTrackingList,
} from "../../../../../recoil/packingFlow/atoms";
import { useUpdateShipmentProductTrackingList } from "../../../../../recoil/packingFlow/helpers";
import LotTrackingForm from "./LotTrackingForm";
import * as S from "./styles";

interface Props {
  visible: boolean;
  setVisible: (v: boolean) => void;
  onSubmit: (itemQuantity: number) => void;
  selectedItem?: BaseProductItem | VirtualKitItem;
}

const AddItemQuantityModal: React.FC<Props> = ({
  visible,
  setVisible,
  onSubmit,
  selectedItem,
}) => {
  const [itemQuantity, setItemQuantity] = useState(0);
  const [lotTrackings, setLotTrackings] = useState<LotTrackingList>({});
  const currentPackageIndex = useRecoilValue(currentPackageItemIndexState);
  const { addLotTrackingListToPackage } =
    useUpdateShipmentProductTrackingList();
  const lotNumberTrackingFlowEnabled = useRecoilValue(
    lotNumberTrackingFlowEnabledState
  );

  useEffect(() => {
    if (visible) setItemQuantity(selectedItem?.qty || 1);
  }, [visible, selectedItem]);

  const renderLotTrackingForm = () => {
    if (!selectedItem || !lotNumberTrackingFlowEnabled) return null;
    return (
      <LotTrackingForm
        selectedItem={selectedItem}
        itemQuantity={itemQuantity}
        lotTrackings={lotTrackings}
        setLotTrackings={setLotTrackings}
      />
    );
  };

  return (
    <Modal
      title="Enter Quantity and lot / serial numbers"
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => {
        addLotTrackingListToPackage(currentPackageIndex, lotTrackings);
        onSubmit(itemQuantity);
        setLotTrackings({});
      }}
    >
      <S.QuantityInputWrap>
        <Space>
          <InputNumber
            autoFocus
            min={1}
            max={selectedItem?.qty || 1}
            value={itemQuantity}
            onChange={(v: number) => setItemQuantity(v || 0)}
          />

          <Typography.Text type="secondary">
            /{selectedItem?.qty}
          </Typography.Text>
        </Space>
      </S.QuantityInputWrap>
      {renderLotTrackingForm()}
    </Modal>
  );
};

export default AddItemQuantityModal;
