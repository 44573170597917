import styled from "styled-components";
import { Upload as AntUpload } from "antd";

import { COLORS } from "../../../../styles";

export const TemplateButton = styled.div`
  color: ${COLORS.BLUE};

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const Upload = styled(AntUpload)`
  .ant-upload.ant-upload-select {
    display: block;
  }
`;
