import React, { useState, useEffect } from "react";
import {
  Button,
  CheckboxOptionType,
  InputNumber,
  Modal,
  Radio,
  Row,
  Space,
} from "antd";
import { ErrorAlert } from "@secondcloset/web-components";
import { PalletRequestSource } from "../helpers";
import * as S from "./styles";
import { Facility, FacilityCode } from "@secondcloset/fulfillment-utils";
import { Warehouse } from "@secondcloset/types";
type PalletType = Warehouse.PalletType;
interface SubmitBody {
  count: number;
  facility: FacilityCode;
  type?: PalletType;
}

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  palletSource: PalletRequestSource;
  isLoading: boolean;
  onSubmit: (body: SubmitBody) => void;
  onCancel: () => void;
  onChange: () => void;
  errorMessage?: string;
}

const RequestIDsModal: React.FC<Props> = ({
  isModalVisible,
  setIsModalVisible,
  palletSource,
  isLoading,
  onSubmit,
  onCancel,
  onChange,
  errorMessage,
}) => {
  const [count, setCount] = useState<number>(1);
  const [type, setType] = useState<string>("single");
  const [facility, setFacility] = useState<FacilityCode>("yyz4");
  const isStoragePallet = palletSource === PalletRequestSource.PALLET;

  const resetStates = () => {
    setCount(1);
    setType("single");
    setFacility("yyz4");
  };

  useEffect(() => {
    if (!isModalVisible) resetStates();
  }, [isModalVisible]);

  useEffect(() => {
    onChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, type, facility]);

  const renderButtons = () => {
    return (
      <Row justify="end">
        <Space align="center">
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            type="primary"
            loading={isLoading}
            onClick={() =>
              onSubmit({ count, type: type as PalletType, facility })
            }
          >
            Confirm
          </Button>
        </Space>
      </Row>
    );
  };

  const renderTypeSelect = () => {
    if (!isStoragePallet) return null;
    const typeOptions: CheckboxOptionType[] = [
      {
        label: "Single",
        value: "single",
      },
      {
        label: "Double",
        value: "double",
      },
    ];

    return (
      <S.Card>
        <S.Title>Type</S.Title>
        <Radio.Group
          options={typeOptions}
          value={type}
          onChange={(e) => setType(e.target.value)}
          optionType="button"
          buttonStyle="solid"
        />
      </S.Card>
    );
  };

  const renderFacilitySelect = () => {
    const typeOptions = Facility.getFulfillmentFacilityList().map((i) => ({
      label: i.code,
      value: i.code,
    }));

    return (
      <S.Card>
        <S.Title>Facility</S.Title>
        <Radio.Group
          value={facility}
          options={typeOptions}
          onChange={(e) => setFacility(e.target.value)}
          optionType="button"
          buttonStyle="solid"
        />
      </S.Card>
    );
  };

  return (
    <Modal
      title="Request CSV"
      destroyOnClose
      onCancel={() => setIsModalVisible(false)}
      visible={isModalVisible}
      footer={false}
    >
      <ErrorAlert error={errorMessage} />
      <Space direction="vertical" style={{ padding: 15, marginBottom: 15 }}>
        <S.Card>
          <S.Title>Number of Pallet IDs</S.Title>
          <InputNumber
            value={count}
            min={1}
            onChange={setCount}
            style={{ width: 100 }}
          />
        </S.Card>
        {renderTypeSelect()}
        {renderFacilitySelect()}
      </Space>
      {renderButtons()}
    </Modal>
  );
};

export default RequestIDsModal;
