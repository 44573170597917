import styled from "styled-components";
import { Tabs as AntTabs } from "antd";

export const Tabs = styled(AntTabs)`
  .ant-tabs-nav .ant-tabs-nav-list {
    padding: 0 2rem;
  }

  .ant-tabs-tab {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

export const TabPane = styled(AntTabs.TabPane)`
  padding: 1rem;
`;
