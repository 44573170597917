import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { useMutation, useQuery } from "react-query";
import { hot } from "react-hot-loader/root";

import Cookie from "./lib/cookie";
import Router from "./router";

//components
import PageLoader from "./components/PageLoader";

//API
import { fetchUserDetails, getCurrentUser } from "./api";
import { userPermissionsState, userState } from "./recoil/user/atoms";
import { menuTitleState } from "./recoil/menuControls/atoms";

// sentry
import { withProfiler } from "./lib/sentry";

const App = () => {
  const [userID, setUserID] = useState("");
  const { isLoading: userLoading, ...userDetails } = useQuery(
    "user",
    () => fetchUserDetails(userID),
    {
      enabled: !!userID,
      retry: false,
    }
  );
  const { mutate: onFetchPermissions, isLoading: permissionsLoading } =
    useMutation(getCurrentUser, {
      onSuccess: (response) => {
        dispatchUserPermissions(response?.data?.permissions);
      },
    });
  const dispatchUser = useSetRecoilState(userState);
  const dispatchSetMenuTitle = useSetRecoilState(menuTitleState);
  const dispatchUserPermissions = useSetRecoilState(userPermissionsState);

  useEffect(() => {
    const user = userDetails.data;
    const credential = Cookie.getCredential();
    if (!user) {
      setUserID(credential?.user?.id ?? "");
    } else {
      const token = credential?.token?.token;
      dispatchSetMenuTitle("FULFILLMENT");
      dispatchUser(user);
      token && onFetchPermissions(token);
    }
    // eslint-disable-next-line
  }, [userDetails.data]);

  if (userLoading || permissionsLoading) {
    return <PageLoader text="Checking credential..." />;
  }

  return <Router />;
};

export default hot(withProfiler(App));
