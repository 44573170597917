import styled from "styled-components";

export const Wrapper = styled.div`
  overflow-y: auto;
  height: calc(100vh - 130px);
  section {
    min-height: unset;
  }
  div:nth-last-child(1) {
    z-index: 2;
  }
`;
