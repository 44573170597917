import React from "react";

// Components
import { Select } from "antd";

// Types
import { LocationType } from "../../../../api/warehouse";
import WithLabel from "../../../../components/WithLabel";

interface Props {
  selectedType?: LocationType;
  onSelectType: (type: LocationType) => void;
}

const StandardOptions: React.FC<Props> = ({ selectedType, onSelectType }) => {
  const buildFacilityOptions = () => {
    const types: LocationType[] = [
      "SHELF",
      "PACKING_STATION",
      "RACKING",
      "CART",
      "PALLET_ZONE",
    ];
    return types.map((type) => (
      <Select.Option key={type} value={type}>
        {type.replaceAll("_", " ")}
      </Select.Option>
    ));
  };

  return (
    <WithLabel name="Location Type">
      <Select
        size="large"
        style={{ width: "100%" }}
        value={selectedType}
        placeholder="Select Type"
        onSelect={(code) => onSelectType(code)}
      >
        {buildFacilityOptions()}
      </Select>
    </WithLabel>
  );
};

export default StandardOptions;
