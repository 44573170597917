import {
  CheckOutlined,
  ExportOutlined,
  HomeOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Fulfillment } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import { Button, Space, Typography } from "antd";
import { startCase } from "lodash-es";
import React, { useCallback, useState } from "react";

// Hooks
import { useMutation } from "react-query";
import { useHistory } from "react-router";
// Recoil
import { useRecoilValue } from "recoil";

// API
import { updateShipmentStatus } from "../../../api/fulfillment";
import OrderInfoSection from "../../../components/OrderInfoSection";
import PageContainer from "../../../components/PageContainer";

// Libs
import Shipment from "../../../lib/shipment";
import {
  packingFlowCurrentPackageState,
  packingFlowShipmentState,
} from "../../../recoil/packingFlow/atoms";
import { useResetPackingFlow } from "../../../recoil/packingFlow/helpers";
import colors from "../../../styles/colors";
import FreightShipmentSummary from "./FreightShipmentSummary";

// Components
import * as S from "./styles";

const PackingSuccessPage: React.FC = () => {
  const dispatchReset = useResetPackingFlow();
  const shipment = useRecoilValue(packingFlowShipmentState);
  const currentPackage = useRecoilValue(packingFlowCurrentPackageState);
  const history = useHistory();
  const [enableActions, setEnableActions] = useState(false);
  const [error, setError] = useState("");

  const isBox = currentPackage?.package_type?.toLowerCase() === "box";
  const packageCount = shipment?.packages?.length ?? 0;
  const isFreightShipment = shipment?.freight;
  const isFreightByFrontier =
    (
      shipment?.shipping_method as Fulfillment.ExternalCarrierShippingMethod
    )?.carrier_id?.toLowerCase() === "frontier";

  const { mutate: onUpdateShipmentStatus, isLoading } =
    useMutation(updateShipmentStatus);

  const checkShipmentDone = useCallback(() => {
    return shipment && Shipment.isShipmentDone(shipment);
  }, [shipment]);

  const packNextShipment = () => {
    dispatchReset();
    history.push("/packing-scan-shipment");
  };

  const markFreightAsShipped = () => {
    if (!shipment) return;
    const shipmentItems = shipment.shipment_items.map((i) => ({
      id: i.id,
      completed_actions: i.shipment_actions,
    }));
    onUpdateShipmentStatus(
      {
        shipmentID: shipment.id,
        status: "with_carrier",
        body: { shipment_items: shipmentItems },
      },
      {
        onSuccess: packNextShipment,
        onError: (error) => {
          setError(error as string);
        },
      }
    );
  };

  const getFinalizeButtonText = () => {
    if (isFreightShipment) return "MARK AS SHIPPED";
    else return "DONE";
  };

  const getFinalizeButtonClickHandler = () => {
    if (isFreightShipment) return markFreightAsShipped;
    else return packNextShipment;
  };

  const renderGreenCheckMark = () => {
    return (
      <S.GreenCircle>
        <CheckOutlined style={{ fontSize: "36px", color: colors.WHITE }} />
      </S.GreenCircle>
    );
  };

  const renderIcons = () => {
    const logo = shipment?.carrier?.logo?.url;
    if (!logo) return;
    return (
      <S.FlexRowContainer>
        {<S.CarrierLogo src={logo} alt="logo" />}
        {renderGreenCheckMark()}
      </S.FlexRowContainer>
    );
  };

  const renderMultipleLabelsWarning = (count: number) => {
    return (
      <Typography.Text type="warning">
        <WarningOutlined /> {count} labels have been generated. All labels will
        open in new tabs.
      </Typography.Text>
    );
  };

  const renderViewLabelBtn = (label: string, url: string) => (
    <Button
      type="primary"
      size="large"
      onClick={() => {
        setEnableActions(true);
        window.open(url);
      }}
    >
      {label}
      <ExportOutlined />
    </Button>
  );

  const buildParcelInfo = () => {
    if (!shipment) return null;
    const carrierName = shipment?.carrier?.name || "-";
    const shippingLabelURLs = Shipment.getShippingLabelURLs(shipment);
    const labelCount = shippingLabelURLs?.length || 0;

    return (
      <Space
        size="large"
        align="center"
        direction="vertical"
        style={{ maxWidth: 500 }}
      >
        <S.SuccessIcons>{renderIcons()}</S.SuccessIcons>
        <S.SuccessMessage>Shipping label generated!</S.SuccessMessage>
        <S.Info>
          Open link below and print the label (Ctrl + P for Windows, CMD + P for
          MacOS). Once printed, affix the label on the package. Then drop off in
          a <S.BoldText>{carrierName}</S.BoldText> collection bag.
        </S.Info>
        {labelCount > 1 && renderMultipleLabelsWarning(labelCount)}
        <Space direction="vertical" align="center" size="small">
          {shippingLabelURLs.map((url, i) =>
            renderViewLabelBtn(`View Label #${i + 1}`, url)
          )}
        </Space>
      </Space>
    );
  };

  const buildFreightInfo = () => {
    if (checkShipmentDone()) {
      return (
        <FreightShipmentSummary
          enableActions={enableActions}
          setEnableActions={setEnableActions}
        />
      );
    }
    return (
      <S.InfoContainer>
        <S.SuccessIcons>{renderGreenCheckMark()}</S.SuccessIcons>
        <S.SuccessMessage>
          Package #{packageCount} ({startCase(currentPackage?.package_type)})
          successfully packed!
        </S.SuccessMessage>
        <Space direction="vertical" align="center">
          <S.Info>
            Wrap the pallet securely, then select &quot;Pack Remaining
            Items&quot; to finish packing before printing shipping label.
          </S.Info>
          <Typography.Text type="secondary">
            You can create up to {(isBox ? 35 : 6) - packageCount} more{" "}
            {isBox ? "box" : "pallets"} for this shipment
          </Typography.Text>
        </Space>
      </S.InfoContainer>
    );
  };

  const renderButtons = () => {
    const packItemButtonText = getFinalizeButtonText();
    const packItemButtonHandler = getFinalizeButtonClickHandler();
    const isDisabled = isFreightShipment
      ? checkShipmentDone() && !enableActions
      : !enableActions;
    return (
      <S.FlexRowContainer>
        <S.ButtonContainer>
          <Button
            onClick={() => history.push("/")}
            disabled={isDisabled}
            icon={<HomeOutlined />}
            style={{ width: 220 }}
            size="large"
          >
            HOME
          </Button>
        </S.ButtonContainer>
        <S.ButtonContainer>
          <Button
            type="primary"
            onClick={packItemButtonHandler}
            disabled={isDisabled}
            style={{ width: 220 }}
            size="large"
          >
            {packItemButtonText}
          </Button>
        </S.ButtonContainer>
      </S.FlexRowContainer>
    );
  };

  return (
    <PageContainer withHeader withPadding loading={!shipment || isLoading}>
      <S.Container>
        <ErrorAlert error={error} />
        <OrderInfoSection />
        <Space direction="vertical" align="center" size="large">
          {isFreightShipment && !isFreightByFrontier
            ? buildFreightInfo()
            : buildParcelInfo()}
          {renderButtons()}
        </Space>
      </S.Container>
    </PageContainer>
  );
};

export default PackingSuccessPage;
