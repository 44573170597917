import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router";
import {
  notification,
  PageHeader,
  Skeleton,
  Space,
  Tag,
  Typography,
} from "antd";
import useUrlState from "@ahooksjs/use-url-state";
import { Common, Warehouse } from "@secondcloset/types";

// api
import {
  fetchPicksheetStats,
  fetchPicksheetRequests,
  generatePicksheetRequest,
} from "../../../api/warehouse/picksheet";
import { fetchOrganizationDetails } from "../../../api/warehouse";

import { PicksheetRequestOptions } from "./interfaces";

// components
import PageContainer from "../../../components/PageContainer";
import RequestsTable from "./RequestsTable";
import MetricDashboard from "../MetricDashboard";
import GeneratePicksheetFilter from "./GeneratePicksheetFilter";

type FacilityCode = Common.FacilityCode;
type Organization = Warehouse.Organization;

const PicksheetRequestsPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location?.search);
  const [urlState, setUrlState] = useUrlState({
    facility: params?.get("facility") || undefined,
    allowPartial: params?.get("allowPartial") || undefined,
    product_id: params?.get("product_id") || undefined,
    dateFrom: params?.get("dateFrom") || undefined,
    dateTo: params?.get("dateTo") || undefined,
    perPage: 10,
    page: 1,
  });
  const { orgId } = useParams<{ orgId: string }>();
  const [org, setOrg] = useState<Organization>();

  const { isFetching: loadingOrg } = useQuery(
    ["fetchOrganization", orgId],
    () => fetchOrganizationDetails(orgId),
    {
      enabled: !org,
      onError: (error: string) => {
        showError(error);
      },
      onSuccess: (data) => setOrg(data),
    }
  );

  const {
    data: picksheetRequests,
    refetch: refetchRequests,
    isFetching: loadingRequests,
  } = useQuery(
    [
      "fetchPicksheetRequests",
      urlState.page,
      urlState.perPage,
      urlState.facility,
      orgId,
    ],
    () =>
      fetchPicksheetRequests({
        facility: urlState.facility as FacilityCode,
        organization_id: orgId,
        page: urlState.page,
        limit: urlState.perPage,
      }),
    {
      enabled: !!orgId && !!urlState.facility,
      onError: (error: string) => {
        showError(error);
      },
    }
  );

  const {
    data: paginatedPicksheetStats,
    refetch: refetchStats,
    isLoading: fetchingStats,
  } = useQuery(
    [
      "fetchPicksheetStats",
      orgId,
      urlState.allowPartial,
      urlState.product_id,
      urlState.dateFrom,
      urlState.dateTo,
    ],
    () => {
      return fetchPicksheetStats({
        limit: urlState.perPage,
        organization_id: orgId,
        facility: urlState.facility as FacilityCode,
        include_partial: urlState.allowPartial,
        product_id: urlState.product_id,
        page: urlState.page,
        date_from: urlState.dateFrom,
        date_to: urlState.dateTo,
      });
    },
    {
      enabled: !!urlState.facility && !!orgId,
      onError: (error: string) => {
        showError(error);
      },
    }
  );

  const { mutate: createPicksheetRequest, isLoading: isCreatingRequest } =
    useMutation(generatePicksheetRequest, {
      onError: (error: string) => {
        showError(error);
      },
      onSuccess: () => {
        notification.success({
          message: `Picksheet request successfully submitted!`,
        });
        handleRefetch();
      },
    });

  const showError = (error: string) => {
    notification.error({ message: error });
  };

  const handleRefetch = () => {
    refetchStats({
      cancelRefetch: true,
      throwOnError: true,
    });
    refetchRequests({ cancelRefetch: true, throwOnError: true });
  };

  const onGeneratePicksheets = (options: PicksheetRequestOptions) => {
    createPicksheetRequest({
      facility: urlState.facility as FacilityCode,
      filters: {
        allow_partial: `${options.allowPartial}`,
        number_of_orders: `${options.orderLimit}`,
        date_from: options.dateFrom || undefined,
        date_to: options.dateTo || undefined,
        ...(options?.productId && { product_id: options.productId }),
        ...(options?.sku && { sku: options.sku }),
        ...(options?.upc && { upc: options.upc }),
      },
      organization_id: orgId,
    });
  };

  const isRequestInProcess = !!loadingRequests || !!isCreatingRequest;
  const isButtonDisabled =
    isRequestInProcess ||
    paginatedPicksheetStats?.data?.[0]?.ready_to_fulfill_count === 0 ||
    !paginatedPicksheetStats?.data?.length;
  const noOrdersFound =
    Array.isArray(paginatedPicksheetStats?.data) &&
    !paginatedPicksheetStats?.data?.length;

  const picksheetRequestPageHeader = (
    <Skeleton active paragraph={{ rows: 0 }} loading={loadingOrg}>
      <Space align="center">
        <Typography.Title style={{ margin: 0 }}>{org?.name}</Typography.Title>
        <Tag style={{ fontSize: "1.35rem", padding: 6 }}>
          {urlState.facility?.toUpperCase()}
        </Tag>
      </Space>
    </Skeleton>
  );

  const picksheetGenerateCard = (
    <div>
      <Typography.Title level={4}>Generate Pick Sheets</Typography.Title>
      <GeneratePicksheetFilter
        loading={loadingRequests}
        organizationId={orgId}
        onProductChange={() =>
          refetchStats({
            cancelRefetch: true,
            throwOnError: true,
          })
        }
        isButtonDisabled={isButtonDisabled}
        onGenerateButtonClick={onGeneratePicksheets}
      />
    </div>
  );

  const picksheetRequestTable = (
    <RequestsTable
      setError={showError}
      paginationInfo={picksheetRequests?.meta}
      picksheetRequests={picksheetRequests?.items || []}
      isRefreshButtonDisabled={isRequestInProcess}
      onRefreshButtonClick={() =>
        refetchRequests({ cancelRefetch: true, throwOnError: true })
      }
      onChangePage={(page: number, pageSize?: number | undefined) => {
        if (pageSize !== urlState.perPage) {
          setUrlState({ page: 1, perPage: pageSize });
        } else {
          setUrlState({ page });
        }
      }}
    />
  );

  return (
    <PageContainer withPadding loading={isRequestInProcess}>
      <PageHeader
        title="Print Pick Sheets Page"
        onBack={() => history.push("/pick-sheets")}
      />
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        {picksheetRequestPageHeader}
        <MetricDashboard
          noOrdersFound={noOrdersFound}
          data={paginatedPicksheetStats?.data?.[0] || undefined}
          loading={loadingRequests || fetchingStats}
        />
        {picksheetGenerateCard}
        {picksheetRequestTable}
      </Space>
    </PageContainer>
  );
};

export default PicksheetRequestsPage;
