import styled, { css, keyframes } from "styled-components";
import colors from "../../../../styles/colors";

export const Container = styled.div`
  border: 1px solid ${colors.GREY_LIGHT};
  padding: 20px;
  border-radius: 5px;
`;

export const TableTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
`;

export const TableContent = styled.div`
  height: 300px;
  overflow-y: auto;
`;

const highlight = keyframes`
  0% {
    background-color: ${colors.YELLOW_LIGHT};
  }
  100% {
    background-color: none;
  }
`;
const highlightRule = css(["", " 1s linear;"] as any, highlight);

export const TableRow = styled.div<{
  animate?: boolean;
}>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.GREY_LIGHT};
  animation: ${({ animate }) => (animate ? highlightRule : "none")};
`;

interface TableCellProps {
  isHeader?: boolean;
  isStat?: boolean;
  isRightAligned?: boolean;
  flex?: number;
}

export const TableCell = styled.div<TableCellProps>`
  flex: ${({ flex }) => flex ?? 1};
  display: flex;
  align-items: center;
  justify-content: ${({ isRightAligned }) =>
    isRightAligned ? "flex-end" : "flex-start"};
  font-size: ${({ isStat }) => (isStat ? "1.2rem" : "0.7rem")};
  font-weight: ${({ isHeader, isStat }) =>
    isHeader || isStat ? "600" : "300"};
  color: ${({ isHeader }) => (isHeader ? colors.BLACK : colors.CHARCOAL)};
  height: 50px;
  padding: 10px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;

  &:hover {
    text-overflow: clip;
    white-space: normal;
  }
`;
