import styled from "styled-components";

export const OrganizationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  text-align: right;
`;

export const Label = styled.div`
  font-size: 20px;
  margin-right: 20px;
`;
