type FirstLastName = { first_name: string; last_name: string };
type RoleSubrole = { subrole: string; role: string };

export default class User {
  static isAdmin = <T extends RoleSubrole | null>(user?: T): boolean => {
    return user?.role === "is_admin";
  };

  static isSuper = <T extends RoleSubrole | null>(user?: T): boolean => {
    if (!user) return false;
    const subrole = user?.subrole || "";
    const superRoles = ["super", "god"];
    return User.isAdmin(user) && superRoles.includes(subrole);
  };

  static getInitials = <T extends FirstLastName | null>(user?: T): string => {
    if (!user) return "";
    const firstName = user?.first_name || "";
    const lastName = user?.last_name || "";
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  };

  static getUserName = <T extends FirstLastName | null>(user?: T): string => {
    if (!user) return "";
    const firstName = user?.first_name || "";
    const lastName = user?.last_name || "";
    return `${firstName} ${lastName}`;
  };
}
