import styled from "styled-components";
import { COLORS, MIXINS } from "../../../../../styles";
import {
  EnterOutlined as EnterOutlinedAntd,
  PlusCircleFilled as PlusCircleFilledAntd,
  CloseCircleFilled as CloseCircleFilledAntd,
} from "@ant-design/icons";
export const Table = styled.div`
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  ${MIXINS.includeScrollBar()};
  border-radius: 5px;
  border: 1px solid ${COLORS.GREY_LIGHT};
  margin-top: 10px;
  padding: 10px;
`;

interface ItemRowAddButtonProps {
  type: "ADD" | "INVALID";
}

export const ItemRowAddButton = styled(
  PlusCircleFilledAntd
)<ItemRowAddButtonProps>`
  svg {
    fill: ${({ type }) =>
      type === "INVALID" ? COLORS.GREY_LIGHT : COLORS.BLUE};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const ItemRowRemoveButton = styled(CloseCircleFilledAntd)`
  svg {
    fill: ${COLORS.RED_DARK};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const TableWrap = styled.div`
  ${MIXINS.includeBoxShadow()}
  .ant-table-thead > tr > th {
    background: ${COLORS.WHITE};
    color: ${COLORS.GREY};
  }
  .ant-table-row-level-1 > td {
    background: #fbfbfb;
    border-bottom: none;
  }
  .ant-table-row-level-1 + .ant-table-row-level-0 > td {
    border-top: 1px solid #f0f0f0;
  }
  .ant-table-expanded-row {
    display: table-row !important;
  }
`;

export const EnterOutlined = styled(EnterOutlinedAntd)`
  svg {
    transform: scale(-1, 1);
    padding: 0;
    color: ${COLORS.GREY};
    font-size: 1.2em;
  }
`;
