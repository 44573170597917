import { Warehouse } from "@secondcloset/types";
import {
  fetchCarrierTransferPackageByTrackingNumber,
  fetchCarrierTransferPalletsById,
  searchForShipmentPackage,
  TransferCustodyQuery,
} from "../../../api/warehouse";

type PackageDetailsInfo = Warehouse.PackageDetailsInfo;
type CarrierTransferPallet = Warehouse.CarrierTransferPallet;
type CarrierTransferPackage = Warehouse.CarrierTransferPackage;

export const fetchPalletPackagesById = async (
  id: string
): Promise<CarrierTransferPallet | undefined> => {
  try {
    return (await fetchCarrierTransferPalletsById(id, {
      with_packages: true,
    })) as CarrierTransferPallet;
  } catch {
    return Promise.resolve(undefined);
  }
};

export const fetchPackageByTrackingNumber = async (
  trackingNumber: string
): Promise<any> => {
  try {
    const ctPackage = await fetchCarrierTransferPackageByTrackingNumber({
      tracking_number: trackingNumber,
    });
    return {
      id: ctPackage?.carrier_transfer_pallet_id,
      carrier_transfer_packages: [ctPackage],
      created_at: "",
    };
  } catch {
    return Promise.resolve(undefined);
  }
};

export const fetchPalletIdOrPackageTrackingNumber = async (
  query: TransferCustodyQuery
): Promise<CarrierTransferPallet> => {
  return (
    (await fetchPalletPackagesById(query.input)) ||
    (await fetchPackageByTrackingNumber(query.input)) ||
    (await fetchCorePackageByTrackingNumber(query.input))
  );
};

export const fetchCorePackageByTrackingNumber = async (
  trackingNumber: string
) => {
  const singlePackage = await searchForShipmentPackage({
    tracking_number: trackingNumber,
  });
  const formattedSinglePackage = {
    id: "",
    carrier_transfer_packages: singlePackage,
    created_at: "",
  };
  return formattedSinglePackage as CarrierTransferPallet;
};

export const formatShipmentPackages = (
  response: PackageDetailsInfo
): CarrierTransferPackage[] => {
  const formattedPackage = {
    id: "",
    created_by: "",
    carrier_name: response?.carrier?.name,
    tracking_number: response?.shipping_label?.tracking_number,
    status: "at_facility",
    created_at: new Date().toString(),
    organization: {
      name: response?.organization?.name,
    },
  };
  return [formattedPackage] as CarrierTransferPackage[];
};
