import React, { useEffect } from "react";

// Components
import PageContainer from "../../../components/PageContainer";
import AsnSelectClientHeader from "./AsnSelectClientHeader";
import AsnSelectClient from "./AsnSelectClient";
import {
  asnCreateFlowSelectedOrganization,
  asnCreateFlowOrganizations,
} from "../../../recoil/asnCreateFlow/atoms";

import * as S from "./styles";

import { useResetRecoilState } from "recoil";

const AsnCreatePage: React.FC = () => {
  const resetSelectedOrganization = useResetRecoilState(
    asnCreateFlowSelectedOrganization
  );
  const resetOrganizations = useResetRecoilState(asnCreateFlowOrganizations);

  useEffect(() => {
    resetSelectedOrganization();
    resetOrganizations();
    // eslint-disable-next-line
  }, []);

  return (
    <PageContainer withPadding withFooter withHeader>
      <S.Container>
        <AsnSelectClientHeader />
        <AsnSelectClient />
      </S.Container>
    </PageContainer>
  );
};

export default AsnCreatePage;
