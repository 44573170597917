import React from "react";

import { QuantityStatus } from "../QuantityStatus";

const StatusCell = ({ status, children, ...restProps }) => {
  let bgColor = "unset";
  let textCol = "unset";

  if (status === QuantityStatus.INCREASED) {
    bgColor = "rgba(0,255,0,0.12)";
    textCol = "green";
  } else if (status === QuantityStatus.DECREASED) {
    bgColor = "rgba(255,0,0,0.12)";
    textCol = "red";
  }

  return (
    <td {...restProps} style={{ backgroundColor: bgColor, color: textCol }}>
      <span>{children}</span>
    </td>
  );
};

export default StatusCell;
