import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Table,
  TablePaginationConfig,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import { FixedType } from "rc-table/lib/interface";
// API
import { fetchSupplies } from "../../../api/warehouse";
// Components
import WithLabel from "../../../components/WithLabel";
import PageContainer from "../../../components/PageContainer";
import NullSafeLink from "../../../components/Table/NullSafeLink";
import NullSafeText from "../../../components/Table/NullSafeText";
import ItemCountFooter from "../../../components/Table/ItemCountFooter";

// Hooks
import useUrlState from "@ahooksjs/use-url-state";

// Utils
import { filterSupplyTypes, getFilterType } from "../CreateSupplyPage/helpers";
import { buildTablePagination } from "../../../components/Pagination/helper";

type Supply = Warehouse.Supply;

const SuppliesIndexPage: React.FC = () => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [
    { page, perPage, search, type, active, organizationName },
    setUrlState,
  ] = useUrlState(
    {
      page: 1,
      perPage: 10,
      search: undefined,
      type: undefined,
      active: "true",
      organizationName: undefined,
    },
    { navigateMode: "replace" }
  );

  const paginatedSupplies = useQuery(
    ["fetchSupplies", search, type, organizationName, active, page, perPage],
    () =>
      fetchSupplies({
        search: search,
        type: type,
        organization_name: organizationName,
        active: active,
        page: page,
        limit: perPage,
      }),
    {
      onError: (error: string) => setError(error),
    }
  );

  const getSupplies = useCallback((): Supply[] => {
    return paginatedSupplies.data?.items || [];
  }, [paginatedSupplies]);

  const renderLink = (text, record) => {
    const suppliesId = record.id;
    return <NullSafeLink href={`/supplies/${suppliesId}`} value={text} />;
  };
  const renderText = (text: string) => {
    return <NullSafeText value={text} />;
  };

  const buildTableColumns = () => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        width: 100,
        render: (text, record) => renderLink(text, record),
        fixed: "left" as FixedType,
      },
      {
        title: "Scannable Code",
        dataIndex: "code",
        width: 100,
        render: renderText,
      },
      {
        title: "Organization",
        dataIndex: "organization",
        width: 100,
        render: (org: any) => renderText(org.name),
      },
      {
        title: "Type",
        dataIndex: "type",
        width: 50,
      },
      {
        title: "Active",
        dataIndex: "active",
        width: 50,
        render: (val: boolean) => (val ? "Yes" : "No"),
      },
    ];
  };

  const buildPagination = (): TablePaginationConfig => {
    const paginationInfo = paginatedSupplies.data?.meta;
    if (!paginationInfo) return {};
    return buildTablePagination({
      paginationInfo,
      showQuickJumper: true,
      showSizeChanger: true,
      onChange: (p: number, newPageSize?: number) => {
        if (newPageSize && newPageSize !== +perPage) {
          setUrlState({ page: 1, perPage: newPageSize });
        } else {
          setUrlState({ page: p });
        }
      },
    });
  };

  const renderCreateButton = () => {
    return (
      <Button
        type="primary"
        size="large"
        title="Create"
        block
        onClick={() => history.push("/supplies/create")}
        icon={<PlusOutlined />}
      >
        Create
      </Button>
    );
  };

  const renderSupplySearchBox = () => {
    return (
      <WithLabel name="Supply Name/Code">
        <Input.Search
          key={"search"}
          size="large"
          allowClear
          placeholder={"Supply name or scan code"}
          defaultValue={search}
          onSearch={(value: string) => {
            setUrlState({ search: value, page: 1 });
          }}
          onChange={(e) => {
            if (!e.target.value.trim()) {
              setUrlState({ search: "", page: 1 });
            }
          }}
          loading={paginatedSupplies.isLoading}
          autoCapitalize="off"
        />
      </WithLabel>
    );
  };

  const renderOrganizationSearchBox = () => {
    return (
      <WithLabel name="Organization">
        <Input.Search
          key={"organization"}
          size="large"
          allowClear
          placeholder={"Organization name"}
          defaultValue={organizationName}
          onSearch={(value: string) => {
            setUrlState({ organizationName: value, page: 1 });
          }}
          onChange={(e) => {
            if (!e.target.value.trim()) {
              setUrlState({ organizationName: "", page: 1 });
            }
          }}
          loading={paginatedSupplies.isLoading}
          autoCapitalize="off"
        />
      </WithLabel>
    );
  };

  const supplyTypeOptions = filterSupplyTypes.map((type) => (
    <Select.Option key={type} value={type}>
      {type}
    </Select.Option>
  ));

  const onSupplyTypeChange = (value) => {
    setUrlState({ type: getFilterType(value), page: 1 });
  };

  const renderSupplyTypeFilter = () => {
    return (
      <WithLabel name="Type">
        <Select
          size="large"
          placeholder="Supply Type"
          defaultValue={"All"}
          onChange={onSupplyTypeChange}
        >
          {supplyTypeOptions}
        </Select>
      </WithLabel>
    );
  };

  const renderActiveSupplySwitch = () => {
    return (
      <WithLabel name="Active Supply Only" direction="horizontal">
        <Switch
          checked={active === "true"}
          onChange={(value: boolean) => {
            setUrlState({ active: value ? "true" : "false", page: 1 });
          }}
        />
      </WithLabel>
    );
  };

  const renderSearchGroup = () => {
    return (
      <Row gutter={[8, 8]}>
        <Col xs={24} lg={4}>
          {renderSupplyTypeFilter()}
        </Col>
        <Col xs={24} lg={10}>
          {renderSupplySearchBox()}
        </Col>
        <Col xs={24} lg={10}>
          {renderOrganizationSearchBox()}
        </Col>
      </Row>
    );
  };

  const renderToolbar = () => {
    return (
      <>
        <Row justify="space-between" align="bottom" gutter={[8, 16]}>
          <Col xs={24} lg={18}>
            {renderSearchGroup()}
          </Col>
          <Col xs={24} lg={3}>
            {renderCreateButton()}
          </Col>
        </Row>
        <Row align="bottom">
          <Col>{renderActiveSupplySwitch()}</Col>
        </Row>
      </>
    );
  };

  const renderTableFooter = () => (
    <ItemCountFooter count={paginatedSupplies.data?.meta.totalItems || 0} />
  );

  return (
    <PageContainer withHeader withFooter withPadding title="Supplies">
      <Space style={{ width: "100%" }} direction="vertical">
        {renderToolbar()}
        <ErrorAlert error={error} />
        <Table
          size="small"
          rowKey="id"
          scroll={{ x: "max-content" }}
          footer={renderTableFooter}
          bordered
          loading={paginatedSupplies.isLoading}
          pagination={buildPagination()}
          columns={buildTableColumns()}
          dataSource={getSupplies()}
        />
      </Space>
    </PageContainer>
  );
};

export default SuppliesIndexPage;
