import React from "react";
import { Link } from "react-router-dom";

interface Props {
  value?: string;
  href: string;
}

const NullSafeLink: React.FC<Props> = ({ value, href }) => {
  return value ? <Link to={href}>{value}</Link> : <span>-</span>;
};
export default NullSafeLink;
