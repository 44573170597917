import styled from "styled-components";
import { COLORS } from "../../../../styles";

export const Container = styled.div``;

export const SpinContainer = styled.div`
  display: flex;
  margin-top: 200px;
  justify-content: center;
  align-items: center;
`;

export const RowContainer = styled.div`
  min-width: 150px;
  min-height: 70px;
`;

export const Title = styled.h1`
  font-size: 30px;
  margin: 0;
`;

export const ItemText = styled.span`
  font-size: 15px;
`;

export const ItemTitle = styled.div`
  font-size: 13px;
  color: ${COLORS.GREY};
`;

export const ErrorMessage = styled.div`
  max-width: 120px;
`;

export const SwitchLabel = styled.span`
  margin-top: 5px;
  margin-right: 20px;
`;
