import React from "react";
import { Card, Image, Space, Typography } from "antd";
import { Warehouse } from "@secondcloset/types";

type Return = Warehouse.Return;

interface Props {
  returnDetails?: Return;
}

const Photos: React.FC<Props> = ({ returnDetails }) => {
  const renderPhotoList = () => {
    return returnDetails?.photos?.length === 0 ? (
      <Typography.Text>No photos added</Typography.Text>
    ) : (
      returnDetails?.photos?.map((photo) => (
        <Image.PreviewGroup key={photo?.id}>
          <Image width={80} src={photo?.photo_link} />
        </Image.PreviewGroup>
      ))
    );
  };

  return (
    <Card>
      <Space direction="horizontal" wrap>
        {renderPhotoList()}
      </Space>
    </Card>
  );
};
export default Photos;
