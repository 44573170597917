import React, { useState } from "react";
import { Button, Tabs } from "antd";
import {
  GroupOutlined,
  ReconciliationOutlined,
  RotateRightOutlined,
} from "@ant-design/icons";

import PageContainer from "../../components/PageContainer";
import BatchMoveItemsView from "./BatchMoveItemsView";
import BatchMovePalletsView from "./BatchMovePalletsView";
import BatchMoveLocationView from "./BatchMoveLocationView";
import { useHistory } from "react-router";

const { TabPane } = Tabs;

const BatchMoveItemsPage: React.FC = () => {
  const [loading, isLoading] = useState<boolean>(false);
  const history = useHistory();

  return (
    <PageContainer title="Batch Move Items" withPadding loading={loading}>
      <Button type="primary" onClick={() => history.push("/inventory-audit")}>
        Go to Inventory Audit
      </Button>
      <Tabs defaultActiveKey="1" size="large">
        <TabPane
          tab={
            <span>
              <ReconciliationOutlined />
              Items
            </span>
          }
          key="1"
        >
          <BatchMoveItemsView setContainerLoading={isLoading} />
        </TabPane>
        <TabPane
          tab={
            <span>
              <GroupOutlined />
              Pallets
            </span>
          }
          key="2"
        >
          <BatchMovePalletsView setContainerLoading={isLoading} />
        </TabPane>
        <TabPane
          tab={
            <span>
              <RotateRightOutlined />
              Move Location
            </span>
          }
          key="3"
        >
          <BatchMoveLocationView setContainerLoading={isLoading} />
        </TabPane>
      </Tabs>
    </PageContainer>
  );
};

export default BatchMoveItemsPage;
