import { useQuery } from "react-query";
import React, { useEffect, useState } from "react";
import { Form, Space, Table, Tag } from "antd";
import useUrlState from "@ahooksjs/use-url-state";
import { ErrorAlert } from "@secondcloset/web-components";
import { FixedType } from "rc-table/lib/interface";

import SearchBar from "./SearchBar";
import PageContainer from "../../components/PageContainer";
import NullSafeDate from "../../components/Table/NullSafeDate";
import NullSafeLink from "../../components/Table/NullSafeLink";
import NullSafeText from "../../components/Table/NullSafeText";
import ItemCountFooter from "../../components/Table/ItemCountFooter";

import { fetchCarrierTransferPackages } from "../../api/warehouse";
import { buildTablePagination } from "../../components/Pagination/helper";
import {
  getDateString,
  PACKAGE_STATUS_COLOR,
  PACKAGE_STATUS_LABEL,
} from "./helper";

const CarrierTransferPackagesPage = () => {
  const [urlState, setUrlState] = useUrlState({
    trackingNumber: undefined,
    courier: undefined,
    startDate: undefined,
    endDate: undefined,
    status: undefined,
    limit: 10,
    page: 1,
  });
  const [error, setError] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      trackingNumber: urlState.trackingNumber,
      courier: urlState.courier,
      startDate: urlState.startDate,
      endDate: urlState.endDate,
      status: urlState.status,
    });
  }, [form, urlState]);

  const tableColumns = [
    {
      title: "Tracking #",
      width: 150,
      dataIndex: "tracking_number",
      fixed: "left" as FixedType,
    },
    {
      title: "Pallet ID",
      width: 150,
      render: (id: string) => (
        <NullSafeLink href={`/carrier-transfer-pallets/${id}`} value={id} />
      ),
      dataIndex: "carrier_transfer_pallet_id",
    },
    {
      title: "Carrier",
      width: 120,
      dataIndex: "carrier_name",
    },
    {
      title: "Status",
      width: 100,
      render: (status: string) => (
        <Tag color={PACKAGE_STATUS_COLOR[status]}>
          {PACKAGE_STATUS_LABEL[status]}
        </Tag>
      ),
      dataIndex: "status",
    },
    {
      title: "Transferred By",
      width: 150,
      dataIndex: "handover_by",
      render: (value: string) => <NullSafeText value={value} />,
    },
    {
      title: "Transferred At",
      width: 150,
      dataIndex: "handover_at",
      render: (date: string) => <NullSafeDate date={date} />,
    },
  ];

  const { data: packages, isLoading } = useQuery(
    ["packages", urlState],
    () => {
      setError("");
      return fetchCarrierTransferPackages({
        tracking_number: urlState.trackingNumber || undefined,
        carrier_name: urlState.courier || undefined,
        start_date: getDateString(urlState.startDate)
          ?.startOf("day")
          ?.toISOString(),
        end_date: getDateString(urlState.endDate)?.endOf("day")?.toISOString(),
        status: (urlState.status !== "all" && urlState.status) || undefined,
        limit: urlState.limit || 10,
        page: urlState.page || 1,
      });
    },
    {
      onError: (e: any) => setError(e),
      retry: false,
    }
  );

  const onSearch = (val: any) => {
    if (Object.keys(val).every((k) => urlState[k] === val[k])) return;

    Object.keys(val).forEach((k) => {
      val[k] = val[k] ? val[k] : undefined;
    });

    const { dateRange, ...rest } = val;
    setUrlState({
      ...rest,
      endDate: dateRange ? dateRange[1]?.format("YYYY-MM-DD") : undefined,
      startDate: dateRange ? dateRange[0]?.format("YYYY-MM-DD") : undefined,
    });
  };

  const onClear = () => {
    form.resetFields();
    setUrlState({
      trackingNumber: undefined,
      courier: undefined,
      startDate: undefined,
      endDate: undefined,
      status: undefined,
      limit: undefined,
      page: undefined,
    });
  };

  const renderTableFooter = () => (
    <ItemCountFooter count={packages?.meta?.totalItems || 0} />
  );

  const buildPagination = () => {
    const tablePaginationConfig = {
      paginationInfo: packages?.meta,
      onChange: (page: number, pageSize?: number) => {
        if (packages?.meta.itemsPerPage !== pageSize) {
          setUrlState({ page: 1, limit: pageSize });
        } else {
          setUrlState({ page });
        }
      },
    };
    return buildTablePagination(tablePaginationConfig);
  };

  return (
    <PageContainer withPadding title="Carrier Transfer Packages">
      <Space size="large" direction="vertical" style={{ width: "100%" }}>
        <SearchBar
          form={form}
          loading={isLoading}
          onClear={onClear}
          onSearch={onSearch}
        />
        <ErrorAlert error={error} />
        <Table
          size="small"
          scroll={{ x: "max-content" }}
          rowKey="id"
          footer={renderTableFooter}
          bordered
          loading={isLoading}
          columns={tableColumns}
          pagination={buildPagination()}
          dataSource={packages?.items}
        />
      </Space>
    </PageContainer>
  );
};

export default CarrierTransferPackagesPage;
