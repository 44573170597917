import { Warehouse } from "@secondcloset/types";

type CarrierTransferPallet = Warehouse.CarrierTransferPallet;

export const getHandedOverTagText = (pallet?: CarrierTransferPallet) => {
  return pallet?.handed_over ? "Handed Over" : "At Facility";
};

export const getHandedOverTagColor = (pallet?: CarrierTransferPallet) => {
  return pallet?.handed_over ? "purple" : "orange";
};
