import styled from "styled-components";
import { DEVICE, COLORS } from "../../../../styles";

export const Container = styled.div``;

export const AttachmentWrap = styled.div`
  margin-top: 20px;
`;

export const FileLink = styled.a`
  display: block;
`;

export const ContentWrap = styled.div`
  display: grid;
  /* grid-template-columns: 1fr 2fr; */
  gap: 40px;
  margin-bottom: 20px;

  @media ${DEVICE.md} {
    grid-template-columns: 1fr;
  }
`;

export const StepWrap = styled.div`
  width: 100%;
`;

export const Bold = styled.span`
  font-weight: 600;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ToggleRatesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleRates = styled.a`
  color: ${COLORS.BLUE};
  font-size: 14px;
`;

export const LinkButton = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
`;
