interface Layout {
  SITE_PADDING: string;
  SITE_PADDING_HORIZONTAL: string;
  HEADER_HEIGHT: string;
  FOOTER_HEIGHT: string;
  BORDER_RADIUS: string;
  BUTTON_WIDTH: string;
}

const SITE_PADDING = "20px";
const HEADER_HEIGHT = "70px";
const FOOTER_HEIGHT = "20px";
const BORDER_RADIUS = "2px";
const BUTTON_WIDTH = "180px";

const layout: Layout = {
  SITE_PADDING,
  SITE_PADDING_HORIZONTAL: `padding-left: ${SITE_PADDING}; padding-right: ${SITE_PADDING};`,
  HEADER_HEIGHT,
  FOOTER_HEIGHT,
  BORDER_RADIUS,
  BUTTON_WIDTH,
};

export default layout;
