import { Badge, Modal, Table } from "antd";
import React, { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import { FixedType } from "rc-table/lib/interface";

import NullSafeText from "../../../../../components/Table/NullSafeText";
import {
  lotNumberTrackingFlowEnabledState,
  shipmentProductTrackingListState,
} from "../../../../../recoil/packingFlow/atoms";
import { useReshapeShipmentProductTrackingList } from "../../../../../recoil/packingFlow/helpers";
import * as S from "./styles";

const LotTrackingButton: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const trackingList = useRecoilValue(shipmentProductTrackingListState);
  const lotNumberTrackingFlowEnabled = useRecoilValue(
    lotNumberTrackingFlowEnabledState
  );

  const { getGroupedTrackingListWithQuantity } =
    useReshapeShipmentProductTrackingList();

  const buildColumns = () => {
    const renderDefault = (v: string) => <NullSafeText value={v} />;

    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        fixed: "left" as FixedType,
      },
      {
        title: "Lot Number",
        dataIndex: "lotNumber",
        key: "lotNumber",
        render: renderDefault,
      },
      {
        title: "Serial Number",
        dataIndex: "serialNumber",
        key: "serialNumber",
        render: renderDefault,
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        render: renderDefault,
      },
    ];
  };

  const getDataSource = useCallback(() => {
    return getGroupedTrackingListWithQuantity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingList]);

  const renderModal = () => {
    return (
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title="Lot / Serial Tracking"
        footer={null}
      >
        <Table
          columns={buildColumns()}
          dataSource={getDataSource()}
          size="small"
        />
      </Modal>
    );
  };

  if (!lotNumberTrackingFlowEnabled) return null;

  return (
    <S.Container>
      <Badge dot count={getDataSource().length > 0 ? 1 : 0}>
        <S.LotTrackingButton onClick={() => setVisible(true)}>
          Lot / Serial Numbers
        </S.LotTrackingButton>
      </Badge>
      {renderModal()}
    </S.Container>
  );
};

export default LotTrackingButton;
