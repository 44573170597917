import { Typography } from "antd";
import styled from "styled-components";
import { COLORS } from "../../../../styles";
import { ScanStateType } from "../AsnProcessPage";

export const Container = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Indicator = styled.div<{ state: ScanStateType }>`
  width: 100%;
  height: 10rem;
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  background-color: ${({ state }) => {
    switch (state) {
      case "idle":
        return COLORS.YELLOW;
      case "success":
        return COLORS.GREEN;
      case "error":
        return COLORS.RED;
    }
  }};
`;

export const Label = styled(Typography.Title)<{ state?: ScanStateType }>`
  text-align: center;
  padding: 20px;
  color: ${({ state }) => (state === "idle" ? COLORS.BLACK : COLORS.WHITE)};
`;

export const Wrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputWrap = styled.div``;
export const InputTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BatchUpdateButton = styled.div`
  color: ${COLORS.BLUE};
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
