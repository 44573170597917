import React, { useState, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useLocation, Redirect } from "react-router";
import { useMutation } from "react-query";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Input, Checkbox, Spin } from "antd";
import { ErrorAlert } from "@secondcloset/web-components";

//lib and helpers
import Cookie from "../../lib/cookie";
import User from "../../lib/user";
import { userPermissionsState, userState } from "../../recoil/user/atoms";

//components
import PageContainer from "../../components/PageContainer";
import AlertBanner from "../../components/AlertBanner";
import AnimatedBackground from "./AnimatedBackground";

// API
import { login, getCurrentUser } from "../../api";

//styles
import {
  AppName,
  BackgroundContainer,
  LoginButton,
  FormContainer,
  LoginForm,
} from "./styles";

interface FormValues {
  remember: boolean;
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [shouldRememberMe, setShouldRememberMe] = useState(true);
  const { mutate: onLogin, ...credential } = useMutation(login);
  const { mutate: onFetchPermissions } = useMutation(getCurrentUser, {
    onSuccess: (response) => {
      dispatchUserPermissions(response?.data?.permissions);
    },
  });
  const [user, dispatchUser] = useRecoilState(userState);
  const dispatchUserPermissions = useSetRecoilState(userPermissionsState);
  const location = useLocation();

  const setUserDetailToStore = () => {
    const userInCookie = Cookie.getCredential()?.user;
    if (userInCookie) dispatchUser(userInCookie);
  };

  const setUserPermissionsInStore = (token: string) => {
    onFetchPermissions(token);
  };

  const onLoginSuccess = () => {
    if (!credential.data) return;
    const user = credential.data.user;
    const token = credential.data.token;
    const isAdmin = User.isAdmin(user);
    if (!isAdmin) {
      setError("Access denied");
    }
    Cookie.setCredential({ user, token }, shouldRememberMe);
    setUserDetailToStore();
    token && setUserPermissionsInStore(token.token);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onLoginSuccess, [credential.data]);

  const onFormSubmit = ({ email, password }: FormValues) => {
    setError("");
    setEmail(email);
    setPassword(password);
    onLogin({ email, password });
  };

  const renderLoginForm = () => {
    return (
      <FormContainer
        name="normal_login"
        initialValues={{ remember: shouldRememberMe, email, password }}
        onFinish={(values) => onFormSubmit(values as FormValues)} //typecast unknown to FormValues
        size="middle"
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Please input your account email!" },
            { type: "email", message: "Please input a valid email address." },
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder="Account email"
            autoCapitalize="off"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder="Password"
            autoCapitalize="off"
          />
        </Form.Item>
        <ErrorAlert error={error} />
        {credential.error && (
          <AlertBanner errorMsg="You have entered an invalid username or password" />
        )}
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox onChange={(e) => setShouldRememberMe(e.target.checked)}>
              Remember me
            </Checkbox>
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <LoginButton type="primary" htmlType="submit" size="large">
            Log in
          </LoginButton>
        </Form.Item>
      </FormContainer>
    );
  };

  if (user) {
    type TStateFrom = { from: { pathname: string } };
    const { from } = (location.state ?? {
      from: { pathname: "/" },
    }) as TStateFrom;
    return <Redirect to={from} />;
  }

  return (
    <PageContainer withPadding>
      <BackgroundContainer>
        <AnimatedBackground />
      </BackgroundContainer>
      <LoginForm>
        <AppName>Bolt Logistics Warehouse</AppName>
        <h1>LOGIN</h1>
        {credential.isLoading ? (
          <Spin tip="logging in..." />
        ) : (
          renderLoginForm()
        )}
      </LoginForm>
    </PageContainer>
  );
};

export default LoginPage;
