import React from "react";
import { Table, TablePaginationConfig } from "antd";
import { Warehouse } from "@secondcloset/types";
import { startCase } from "lodash";
import { FixedType } from "rc-table/lib/interface";

import { buildTablePagination } from "../../../../components/Pagination/helper";
import { PaginationInfo } from "../../../../api/warehouse/commonInterfaces";
import ItemCountFooter from "../../../../components/Table/ItemCountFooter";
import NullSafeText from "../../../../components/Table/NullSafeText";
import NullSafeLink from "../../../../components/Table/NullSafeLink";
import MultiDatesField from "../../../../components/Table/MultiDatesField";

type SpecialProject = Warehouse.SpecialProject;

const TRUNCATE_DETAILS_MAX = 25;

interface Props {
  projects: SpecialProject[];
  paginationInfo?: PaginationInfo;
  isLoading: boolean;
  onPageChange: (page: number, pageSize?: number) => void;
}

const SpecialProjectsTable: React.FC<Props> = ({
  projects,
  paginationInfo,
  isLoading,
  onPageChange,
}) => {
  const buildTableColumns = () => {
    const renderText = (v: string) => <NullSafeText value={v} />;

    const renderType = (v: string) => (
      <NullSafeText value={startCase(v.replace("_", " ").toLowerCase())} />
    );

    const renderTruncatedText = (v: string) => {
      const truncatedValue = v
        ? `${v.slice(0, TRUNCATE_DETAILS_MAX)}${
            v?.length <= TRUNCATE_DETAILS_MAX ? "" : "..."
          }`
        : undefined;

      return <NullSafeText value={truncatedValue} />;
    };

    const renderDates = (_: any, record: SpecialProject) => {
      const dates = [
        {
          title: "Started",
          date: record.start_time,
        },
        {
          title: "Finished",
          date: record.end_time,
        },
        {
          title: "Created",
          date: record.created_at,
        },
      ];
      return <MultiDatesField dates={dates} />;
    };

    const renderLink = (id: string) => (
      <NullSafeLink value={id} href={`/special-projects/${id}`} />
    );

    return [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: renderLink,
        width: 100,
        fixed: "left" as FixedType,
      },
      {
        title: "Details",
        dataIndex: "details",
        key: "details",
        width: 200,
        render: renderTruncatedText,
      },
      {
        title: "Organization",
        dataIndex: ["organization", "name"],
        key: "organization",
        width: 100,
        render: renderText,
      },
      {
        title: "Assignees",
        dataIndex: "user_names",
        key: "user_names",
        width: 150,
        render: renderText,
      },
      {
        title: "Facility",
        dataIndex: "facility",
        key: "facility",
        width: 50,
        render: renderText,
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        width: 50,
        render: renderType,
      },
      {
        title: "Reference #",
        dataIndex: "reference_number",
        key: "reference_number",
        width: 100,
        render: renderText,
      },
      {
        title: "Dates",
        key: "dates",
        render: renderDates,
        width: 200,
      },
    ];
  };

  const buildPagination = (): TablePaginationConfig => {
    return buildTablePagination({
      paginationInfo,
      showQuickJumper: true,
      showSizeChanger: true,
      onChange: onPageChange,
    });
  };

  const renderTableFooter = () => (
    <ItemCountFooter count={paginationInfo?.totalItems || 0} />
  );

  return (
    <Table
      size="small"
      rowKey="id"
      scroll={{ x: "max-content" }}
      bordered
      loading={isLoading}
      footer={renderTableFooter}
      pagination={buildPagination()}
      columns={buildTableColumns()}
      dataSource={projects}
    />
  );
};

export default SpecialProjectsTable;
