import styled from "styled-components";
import { COLORS } from "../../../styles";

export const OrgLink = styled.div`
  color: ${COLORS.BLUE};
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
