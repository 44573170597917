import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import { Modal, Spin, Table, Typography } from "antd";
import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { fetchPicksheetFailedReasons } from "../../../api/warehouse/picksheet";
import { buildTablePagination } from "../../../components/Pagination/helper";
import ItemCountFooter from "../../../components/Table/ItemCountFooter";
import NullSafeText from "../../../components/Table/NullSafeText";

type PicksheetFailedReasons = Warehouse.PicksheetFailedReasons;

interface Props {
  picksheetRequestId: string;
  visible: boolean;
  toggleVisible: (val: boolean) => void;
}

const { Text } = Typography;

const FailedReasonsModal: React.FC<Props> = ({
  picksheetRequestId,
  toggleVisible,
  visible,
}) => {
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const closeModal = () => {
    toggleVisible(false);
  };

  const { data: picksheetRequestFailedReasons, isLoading } = useQuery(
    ["picksheetRequestFailedReasons", picksheetRequestId, page, perPage],
    () => {
      return fetchPicksheetFailedReasons({
        page: page?.toString(),
        limit: perPage?.toString(),
        picksheet_request_id: picksheetRequestId,
      });
    },
    {
      enabled: !!picksheetRequestId,
      onError: setError,
    }
  );

  const getFailedReasons = useCallback((): PicksheetFailedReasons[] => {
    return picksheetRequestFailedReasons?.items || [];
  }, [picksheetRequestFailedReasons]);

  const buildPagination = () => {
    const paginationInfo = picksheetRequestFailedReasons?.meta;

    return buildTablePagination({
      paginationInfo,
      onChange: (p: number, newPageSize?: number) => {
        if (newPageSize && newPageSize !== +perPage) {
          setPerPage(newPageSize);
          setPage(1);
        } else {
          setPage(p);
        }
      },
      showSizeChanger: true,
    });
  };

  const tableColumns = [
    {
      key: "external_order_number",
      dataIndex: "external_order_number",
      title: "Order #",
      width: 300,
      render: (orderNum?: string) => <Text strong>{orderNum}</Text>,
    },
    {
      key: "failed_reason",
      dataIndex: "failed_reason",
      title: "Reason",
      width: 500,
      render: (text?: string) => <NullSafeText value={text} />,
    },
  ];

  const renderTableFooter = () => (
    <ItemCountFooter
      count={picksheetRequestFailedReasons?.meta?.totalItems || 0}
    />
  );
  return (
    <Modal
      centered
      destroyOnClose
      onCancel={closeModal}
      visible={visible}
      footer={null}
      title="Failed Orders"
    >
      <Spin spinning={!!isLoading}>
        <ErrorAlert error={error} />
        <Table
          size="small"
          pagination={buildPagination()}
          dataSource={getFailedReasons()}
          rowKey="id"
          bordered
          footer={renderTableFooter}
          columns={tableColumns}
        />
      </Spin>
    </Modal>
  );
};

export default FailedReasonsModal;
