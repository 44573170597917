import { BarcodeOutlined } from "@ant-design/icons";
import { ErrorAlert } from "@secondcloset/web-components";
import { Input, InputNumber, Radio, Space, Spin } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useRef, useState } from "react";
import { COLORS } from "../../../../styles";
import * as S from "./styles";

interface Props {
  visible: boolean;
  onClose: () => void;
  isLoading?: boolean;
  onSubmit: (identifier: string, quantity: number, isReceive: boolean) => void;
  error?: string;
  resetError?: () => void;
}

const BatchUpdateModal: React.FC<Props> = ({
  visible,
  onClose,
  onSubmit,
  isLoading,
  error,
  resetError,
}) => {
  const [identifier, setIdentifier] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [isDecrease, setIsDecrease] = useState(false);
  const [isReceive, setIsReceive] = useState(true);
  const inputRef = useRef<Input | null>(null);

  const resetState = () => {
    setIsReceive(true);
    setIsDecrease(false);
    setQuantity(1);
    setIdentifier("");

    if (visible) {
      setTimeout(() => inputRef?.current?.focus(), 500);
    }
  };
  useEffect(resetState, [visible]);

  const renderReceiveToggle = () => {
    return (
      <Radio.Group
        value={isReceive}
        buttonStyle="solid"
        onChange={(e) => {
          setIsReceive(e.target.value);
          resetError && resetError();
        }}
      >
        <Radio.Button value={true}>Received</Radio.Button>
        <Radio.Button value={false}>Damaged</Radio.Button>
      </Radio.Group>
    );
  };

  const renderQuantity = () => {
    return (
      <S.SectionWrap>
        <S.SectionLabel>Quantity</S.SectionLabel>
        <InputNumber
          value={quantity}
          onChange={(v) => {
            setQuantity(v);
            resetError && resetError();
          }}
          min={1}
        />
      </S.SectionWrap>
    );
  };

  const renderInput = () => {
    return (
      <S.SectionWrap>
        <S.SectionLabel>Scan SKU/UPC</S.SectionLabel>
        <Input
          allowClear
          autoFocus
          placeholder="Scan item code"
          ref={inputRef}
          value={identifier}
          onChange={(e) => {
            setIdentifier(e.target.value);
            resetError && resetError();
          }}
          suffix={<BarcodeOutlined style={{ color: COLORS.GREY }} />}
        />
      </S.SectionWrap>
    );
  };

  return (
    <Modal
      title="Batch Update"
      visible={visible}
      onCancel={onClose}
      onOk={() => {
        onSubmit(identifier, isDecrease ? -1 * quantity : quantity, isReceive);
      }}
      okButtonProps={{
        disabled: !identifier,
      }}
    >
      <S.Container>
        <Spin spinning={!!isLoading}>
          <Space size="large" direction="vertical">
            <ErrorAlert error={error} />
            {renderReceiveToggle()}
            {renderInput()}
            <Checkbox
              onChange={(e) => {
                setIsDecrease(e.target.checked);
                resetError && resetError();
              }}
              checked={isDecrease}
            >
              Decreasing by?
            </Checkbox>
            {renderQuantity()}
          </Space>
        </Spin>
      </S.Container>
    </Modal>
  );
};

export default BatchUpdateModal;
