import React from "react";

//styles
import * as S from "./styles";

export interface Props {
  text: string;
  onClick: () => any;
  disabled?: boolean;
}

const SquareButton: React.FC<Props> = (props) => {
  return (
    <S.Button
      onClick={props.disabled ? () => {} : props.onClick}
      disabled={!!props.disabled}
    >
      <S.ButtonText>{props.text}</S.ButtonText>
    </S.Button>
  );
};

export default SquareButton;
