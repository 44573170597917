import { CloseOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tag,
} from "antd";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteSupply,
  fetchSupplyById,
  updateSupply,
  UpdateSupplyBody,
} from "../../../../api/warehouse";
import { supplyTypes } from "../../CreateSupplyPage/helpers";
import DeleteSupplyButton from "../DeleteSupplyButton";
import {
  editableFields,
  formatSupplyCodeForm,
  hideInDisplayViewFields,
  unitsOptions,
  unitsOptionsDependencies,
  unitType,
} from "./helpers";
import * as S from "./styles";

type Supply = Warehouse.Supply;

const { Option } = Select;

const SupplyDetailsTable: FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [supplyData, setSupplyData] = useState<Supply | any>();
  const [isInEditSupplyView, setIsInEditSupplyView] = useState(false);
  const [error, setError] = useState("");
  const { supplyId } = useParams<{ supplyId: string }>();

  const { mutate: doDeleteSupply, isLoading: isSupplyDeleting } = useMutation(
    deleteSupply,
    {
      onSuccess: () => {
        notification.success({ message: "Supply deletion successful" });
        history.goBack();
      },
      onError: (err: string) => {
        setError(err);
      },
    }
  );

  const { isLoading: isSupplyLoading } = useQuery(
    ["fetchSupplyById", supplyId],
    () => fetchSupplyById(supplyId),
    {
      onSettled: (data) => {
        setSupplyData(data);
      },
      onError: (err: string) => {
        setError(err);
      },
    }
  );
  const { mutateAsync: onUpdateSupply, isLoading: isUpdateLoading } =
    useMutation(updateSupply);

  useEffect(() => {
    const defaultValues = {
      name: supplyData?.name || "-",
      active: supplyData?.active,
      height: supplyData?.height || 0,
      weight: supplyData?.weight || 0,
      width: supplyData?.width || 0,
      length: supplyData?.length || 0,
      created_at: supplyData?.created_at || "-",
      weight_unit: supplyData?.weight_unit,
      dimension_unit: supplyData?.dimension_unit,
      code: supplyData?.code || "",
      type: supplyData?.type || "",
    };
    if (isInEditSupplyView) form.setFieldsValue(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isInEditSupplyView]);

  const handleCancel = () => {
    setIsInEditSupplyView(false);
    setError("");
    form.resetFields();
  };

  const updateSupplyInfo = async () => {
    setError("");
    try {
      await form.validateFields();
    } catch (errorInfo) {
      return null;
    }
    const formValues = formatSupplyCodeForm(form.getFieldsValue());
    return await onUpdateSupply(
      {
        id: supplyId,
        ...formValues,
      },
      {
        onSuccess: (updatedSupply: UpdateSupplyBody) => {
          setIsInEditSupplyView(false);
          setSupplyData(updatedSupply);
          form.resetFields();
        },
        onError: (error) => {
          setError(error as string);
        },
      }
    );
  };

  const renderButtonsEdit = () => (
    <Row justify="end">
      <Space size="middle">
        <Button
          type="primary"
          danger
          disabled={isUpdateLoading}
          onClick={handleCancel}
          icon={<CloseOutlined />}
        >
          Cancel
        </Button>
        <Button
          icon={<SaveOutlined />}
          type="primary"
          disabled={isUpdateLoading}
          onClick={updateSupplyInfo}
        >
          Save
        </Button>
      </Space>
    </Row>
  );

  const renderButtonsView = () => (
    <Row justify="end">
      <Space size="middle">
        <DeleteSupplyButton
          isSupplyDeleting={isSupplyDeleting}
          doDeleteSupply={() => doDeleteSupply(supplyId)}
        />
        <Button
          icon={<EditOutlined />}
          type="primary"
          disabled={isSupplyDeleting}
          onClick={() => setIsInEditSupplyView(true)}
        >
          Edit
        </Button>
      </Space>
    </Row>
  );

  const renderUneditableItem = (label: string, value: string) => (
    <S.RowContainer>
      <S.ItemTitle>{label}</S.ItemTitle>
      <S.ItemText>{value}</S.ItemText>
    </S.RowContainer>
  );

  const renderUnitDropdownFormItem = (
    key: string,
    label: string,
    value: string,
    options: string[],
    dependencies: string[]
  ) => {
    return (
      <S.RowContainer key={key}>
        <Form.Item
          dependencies={dependencies}
          rules={[
            ({ getFieldsValue }) => ({
              required:
                Object.values(getFieldsValue(dependencies))?.filter(
                  (input) => input !== 0
                ).length > 0,
              message: `${label} is required`,
            }),
          ]}
          name={key}
          label={<S.ItemTitle>{label}</S.ItemTitle>}
        >
          <Select size="middle" style={{ width: 100 }}>
            {options.map((type, key) => (
              <Option key={`option-${value}-${key}`} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </S.RowContainer>
    );
  };

  const renderDropdownFormItem = (
    key: string,
    label: string,
    value: string,
    options: string[]
  ) => (
    <S.RowContainer key={key}>
      <Form.Item name={key} label={<S.ItemTitle>{label}</S.ItemTitle>}>
        <Select size="middle" style={{ width: 120 }} value={value}>
          {options.map((type, key) => (
            <Option key={`option-${value}-${key}`} value={type}>
              {type}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </S.RowContainer>
  );

  const renderCardTopView = () => (
    <Space align="center" size="large">
      <S.Title>{supplyData?.name}</S.Title>
      <Tag color={supplyData?.active ? "green" : "red"}>
        {supplyData?.active ? "active" : "inactive"}
      </Tag>
    </Space>
  );
  const renderCardTopEdit = () => (
    <Space align="center" size="large">
      <Form.Item
        name="name"
        rules={[
          {
            transform: (val) => val?.trim(),
            required: true,
            message: "Supply Name is required",
          },
        ]}
      >
        <Input size="large" allowClear />
      </Form.Item>
      <Row justify="center">
        <S.SwitchLabel>Active</S.SwitchLabel>
        <Form.Item name="active" valuePropName="checked">
          <Switch defaultChecked={supplyData?.active} />
        </Form.Item>
      </Row>
    </Space>
  );

  const renderDateCreated = () => (
    <Row>
      <Space>
        <S.ItemTitle>Created on:</S.ItemTitle>
        <S.ItemTitle>{moment(supplyData?.created_at).format("LL")}</S.ItemTitle>
      </Space>
    </Row>
  );

  const renderDropdownItem = (
    key: string,
    label: string,
    value: string,
    options
  ) => {
    if (isInEditSupplyView) {
      return renderDropdownFormItem(key, label, value, options);
    }
    return renderUneditableItem(label, value);
  };

  const renderTopRowDetails = () => (
    <Row>
      {renderDropdownItem("type", "Type", supplyData?.type, supplyTypes)}
      {renderUneditableItem("Organization", supplyData?.organization?.name)}
    </Row>
  );

  const renderDetailsFieldsView = () => (
    <Row>
      {editableFields.map(({ key, label }) => (
        <S.RowContainer key={key}>
          <S.ItemTitle>{label}</S.ItemTitle>
          <S.ItemText>
            {`${supplyData?.[key]} ${supplyData?.[unitType?.[key]] || ""}`}
          </S.ItemText>
        </S.RowContainer>
      ))}
    </Row>
  );

  const renderDetailsFieldsEdit = () => (
    <Row>
      {editableFields.map(({ key, label, requiredType }) => (
        <S.RowContainer key={key}>
          <Form.Item
            name={key}
            label={<S.ItemTitle>{label}</S.ItemTitle>}
            dependencies={["type"]}
            rules={[
              ({ getFieldValue }) => {
                const selectedType = getFieldValue("type");
                return {
                  required: requiredType?.includes(selectedType),
                  type: "number",
                  transform: (val) => {
                    if (val === 0) return null;
                    return val;
                  },
                  message: (
                    <S.ErrorMessage>
                      Field is required for type {selectedType}
                    </S.ErrorMessage>
                  ),
                };
              },
            ]}
          >
            <InputNumber size="large" min={0} precision={2} />
          </Form.Item>
        </S.RowContainer>
      ))}
    </Row>
  );

  const renderUnitsFields = () => (
    <Row>
      {hideInDisplayViewFields.map(({ key, label }) =>
        renderUnitDropdownFormItem(
          key,
          label,
          supplyData?.[key],
          unitsOptions[key],
          unitsOptionsDependencies[key]
        )
      )}
    </Row>
  );

  const renderCodeFieldView = () => (
    <S.RowContainer key="code">
      <S.ItemTitle>Scannable Code</S.ItemTitle>
      <S.ItemText>{supplyData?.code || "-"}</S.ItemText>
    </S.RowContainer>
  );

  const renderCodeFieldEdit = () => (
    <Row>
      <Form.Item
        name="code"
        label={<S.ItemTitle>Scannable Code</S.ItemTitle>}
        rules={[
          {
            whitespace: true,
          },
        ]}
      >
        <Input autoCapitalize="off" size="middle" allowClear />
      </Form.Item>
    </Row>
  );
  return (
    <S.Container>
      <Spin spinning={isSupplyLoading || isUpdateLoading} size="large">
        <ErrorAlert error={error} />
        {isInEditSupplyView ? renderButtonsEdit() : renderButtonsView()}
        <Card style={{ marginTop: 20 }}>
          <Form form={form} layout="vertical">
            {isInEditSupplyView ? renderCardTopEdit() : renderCardTopView()}
            {renderDateCreated()}
            <Divider />
            {renderTopRowDetails()}
            {isInEditSupplyView
              ? renderDetailsFieldsEdit()
              : renderDetailsFieldsView()}
            {isInEditSupplyView && renderUnitsFields()}
            {isInEditSupplyView ? renderCodeFieldEdit() : renderCodeFieldView()}
          </Form>
        </Card>
      </Spin>
    </S.Container>
  );
};

export default SupplyDetailsTable;
