import React, { useCallback, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { map } from "lodash-es";
import {
  Card,
  Col,
  Descriptions,
  Input,
  PageHeader,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";

import WithLabel from "../../../components/WithLabel";
import AddPhotos from "../../../components/AddPhotos";
import PageContainer from "../../../components/PageContainer";
import ProcessReturnActionButtons from "../../../components/ProcessReturnActionButtons";
import ReturnItemsTable from "../../../components/ReturnItemsTable";

import {
  addRemoveReturnPhotos,
  fetchOrganizationConfigs,
  fetchReturnById,
  updateReturn,
  UpdateReturnPhotosBody,
  updateReturnItem,
  UpdateReturnItemCondition,
  UpdateReturnItemBody,
} from "../../../api/warehouse";
import {
  getFilterConfigsByActiveConfigs,
  mapConfigLabelsToUI,
  ProductCondition,
  ReturnStatus,
  getSpecifiedConfig,
} from "../ReturnsIndexPage/helpers";
import {
  isAtEndOfFlow,
  mapPhotosToAntdType,
  OrganizationConfigKey,
  validateReturnFieldsConditions,
} from "./helpers";

import * as S from "./styles";

type Return = Warehouse.Return;
type ReturnPhoto = Warehouse.ReturnPhoto;
type ReturnItem = Warehouse.ReturnItem;
type OrganizationConfig = Warehouse.OrganizationConfig;

const { TextArea } = Input;

const ProcessRegularReturn: React.FC = () => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [internalNotes, setInternalNotes] = useState("");
  const [photos, setPhotos] = useState<ReturnPhoto[]>([]);
  const [isUpdatingReturnItem, setIsUpdatingReturnItem] = useState(false);
  const [returnItemsCondition, setReturnItemsCondition] = useState<
    ReturnItem[]
  >([]);
  const { returnId } = useParams<{ returnId: string }>();

  const {
    data: returnDetails,
    isLoading: isDetailsLoading,
    refetch: refetchReturn,
  } = useQuery(["fetchReturnById", returnId], () => fetchReturnById(returnId), {
    onSuccess: (data: Return) => {
      setError("");
      if (isAtEndOfFlow(data?.status || "")) {
        history.push(`/returns/${returnId}`);
      } else {
        setInternalNotes(data?.internal_notes as string);
        setPhotos(data?.photos || []);
        setReturnItemsCondition(data?.return_items as ReturnItem[]);
      }
    },
    onError: setError,
  });

  const organizationId = returnDetails?.organization.id;

  const organizationConfigs = useQuery(
    ["fetchOrganizationConfigs", organizationId],
    () => fetchOrganizationConfigs(organizationId || ""),
    {
      enabled: !!organizationId,
      onError: setError,
    }
  );

  const getFilteredOrgConfigs = useCallback((): OrganizationConfig[] => {
    const filteredOrgs =
      organizationConfigs?.data &&
      getFilterConfigsByActiveConfigs(
        organizationConfigs.data,
        mapConfigLabelsToUI
      );
    return filteredOrgs || [];
  }, [organizationConfigs]);

  const { mutate: updateProcessWithFlag, isLoading: isProcessUpdating } =
    useMutation(
      (status: string) =>
        updateReturn(returnId, {
          internal_notes: internalNotes,
          status,
        }),
      {
        onSuccess: () => history.push(`/returns/${returnId}`),
        onError: setError,
      }
    );
  const { mutate: doAddRemoveReturnPhotos, isLoading: isPhotoLoading } =
    useMutation(
      (body: UpdateReturnPhotosBody) => addRemoveReturnPhotos(returnId, body),
      {
        onSuccess: (data) => {
          const newPhoto = data?.added?.items;
          if (!newPhoto?.length) {
            refetchReturn({ cancelRefetch: true });
          } else {
            setPhotos([...photos, ...newPhoto]);
          }
        },
        onError: setError,
      }
    );

  const { mutate: markAsCompleted, isLoading: isMarkAsCompleteLoading } =
    useMutation(
      () =>
        updateReturn(returnId, {
          internal_notes: internalNotes,
          status: ReturnStatus.COMPLETED,
        }),
      {
        onSuccess: () => history.push(`/returns/${returnId}`),
        onError: setError,
      }
    );

  const { mutate: doUpdateReturnItem } = useMutation(
    (body: UpdateReturnItemBody) => updateReturnItem(body),
    {
      onSuccess: () => {
        setIsUpdatingReturnItem(false);
      },
      onError: setError,
    }
  );

  const handleUpdateReturnItem = (
    returnItemId: string,
    body: UpdateReturnItemCondition
  ) => {
    doUpdateReturnItem({ id: returnItemId, ...body });
  };

  const handleAddPhotos = (base64String: string) => {
    doAddRemoveReturnPhotos({ add: [base64String] });
  };

  const handleRemovePhotos = (file: UploadFile) => {
    doAddRemoveReturnPhotos({ remove: [file?.uid] });
  };

  const handleCompleteReturn = () => {
    const orgName = returnDetails?.organization?.name as string;
    const { isValid, error: errorMsg } = validateReturnFieldsConditions(
      orgName,
      returnItemsCondition,
      true,
      photos
    );
    if (isValid) {
      markAsCompleted();
    } else {
      setError(errorMsg || "");
    }
  };

  function handleMenuClick(status: string) {
    if (status === ReturnStatus.ON_HOLD && !internalNotes?.trim()) {
      return setError("Please add an internal notes for on hold reason");
    }
    updateProcessWithFlag(status);
  }

  const renderButtons = () => (
    <ProcessReturnActionButtons
      returnDetails={returnDetails}
      handleCompleteReturn={handleCompleteReturn}
      handleMenuClick={handleMenuClick}
      isUpdating={isMarkAsCompleteLoading || isProcessUpdating}
      isUpdatingReturnItem={isUpdatingReturnItem}
    />
  );

  const renderMainInfo = () => {
    return (
      <Descriptions bordered column={{ xs: 1, lg: 2 }}>
        <Descriptions.Item label="External Order #">
          <Typography.Text copyable>
            {returnDetails?.external_order_number}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="RMA">
          <Typography.Text copyable>{returnDetails?.rma}</Typography.Text>
        </Descriptions.Item>
      </Descriptions>
    );
  };

  const renderItemsTable = () => {
    return (
      <ReturnItemsTable
        productCondition={ProductCondition}
        returnItemsCondition={returnItemsCondition}
        setReturnItemsCondition={(items) => {
          setReturnItemsCondition(items);
          setError("");
        }}
        updateReturnItem={handleUpdateReturnItem}
        setIsUpdatingReturnItem={setIsUpdatingReturnItem}
      />
    );
  };

  const renderPhotos = () => {
    const mappedPhotos = mapPhotosToAntdType(photos);
    return (
      <AddPhotos
        onUploadPhoto={handleAddPhotos}
        onRemovePhoto={handleRemovePhotos}
        photos={mappedPhotos || []}
        isPhotoLoading={isPhotoLoading}
        setError={setError}
      />
    );
  };

  const renderInternalNotes = () => {
    return (
      <WithLabel name="Internal Notes">
        <TextArea
          value={internalNotes}
          rows={5}
          onChange={(e) => {
            setInternalNotes(e?.target?.value);
          }}
        />
      </WithLabel>
    );
  };

  const renderPreferences = () => {
    if (getFilteredOrgConfigs()?.length === 0) {
      return (
        <WithLabel name="Merchant Preferences">
          <Typography.Text italic type="warning" style={{ fontSize: 16 }}>
            Merchant did not add any returns preferences.
          </Typography.Text>
        </WithLabel>
      );
    }
    return (
      <Space direction="vertical" align="start">
        {map(getFilteredOrgConfigs(), (config) => {
          return (
            <Space direction="vertical" key={config?.key}>
              <S.Sublabel>
                {mapConfigLabelsToUI?.[config?.key]?.title || ""}
              </S.Sublabel>
              <S.Subtext>{config?.value}</S.Subtext>
            </Space>
          );
        })}
      </Space>
    );
  };

  const renderShipBackAddress = () => {
    const address = JSON.parse(
      getSpecifiedConfig(
        organizationConfigs?.data || [],
        OrganizationConfigKey.OPENED_GOOD_CONDITION_SHIP_TO_ADDRESS
      )?.value
    );
    return (
      <Space direction="vertical" style={{ marginLeft: 50 }}>
        <S.Sublabel>Ship back to:</S.Sublabel>
        {address?.name && <S.InlineSubtext>{address.name}</S.InlineSubtext>}
        <S.InlineSubtext>{address.address}</S.InlineSubtext>
        {address?.apt && <S.InlineSubtext>Apt # {address.apt}</S.InlineSubtext>}
        <S.InlineSubtext>
          {address.city}, {address.province}, {address.postal_code}
        </S.InlineSubtext>
        <S.InlineSubtext>{address.country}</S.InlineSubtext>
      </Space>
    );
  };

  const shipToMerchant =
    getSpecifiedConfig(
      organizationConfigs?.data || [],
      OrganizationConfigKey.SHIP_TO_ME
    )?.value?.toLowerCase() === "yes";

  const renderInternalMetadata = () => {
    return (
      <Row justify="space-between" gutter={[40, 16]}>
        <Col xs={24} lg={12}>
          {renderInternalNotes()}
        </Col>
        <Col xs={24} lg={12}>
          {renderPreferences()}
          {shipToMerchant && renderShipBackAddress()}
        </Col>
      </Row>
    );
  };

  const renderToolbar = () => {
    return (
      <Row justify="space-between" gutter={[8, 16]}>
        <Col xs={24} lg={12}>
          <Typography.Title>{returnDetails?.rma}</Typography.Title>
        </Col>
        <Col xs={24} lg={12}>
          {renderButtons()}
        </Col>
      </Row>
    );
  };

  return (
    <PageContainer withPadding>
      <Spin spinning={isDetailsLoading}>
        <PageHeader title="Returns" onBack={() => history.push("/returns")} />
        <Space direction="vertical" style={{ width: "100%" }}>
          <ErrorAlert error={error} />
          {renderToolbar()}
          {renderMainInfo()}
          <Card bordered>{renderPhotos()}</Card>
          <Card bordered>{renderInternalMetadata()}</Card>
          {renderItemsTable()}
        </Space>
      </Spin>
    </PageContainer>
  );
};

export default ProcessRegularReturn;
