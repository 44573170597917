import moment from "moment";

export const getUTCDate = (
  date: string | null | undefined
): string | undefined => {
  return date ? moment(date).utc().format() : undefined;
};

export const getUTCStartDate = (
  date: string | null | undefined
): string | undefined => {
  return date ? moment(date).startOf("day").utc().format() : undefined;
};

export const getUTCEndDate = (
  date: string | null | undefined
): string | undefined => {
  return date ? moment(date).endOf("day").utc().format() : undefined;
};
