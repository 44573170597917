import { Common } from "@secondcloset/types";
import { Button, Col, Input, Row } from "antd";
import React, { useState } from "react";
import WithLabel from "../../../../components/WithLabel";
import { useBatchUpdateUrlState } from "../../../../hooks/useURLState";
import AddOrganizationModal from "../AddOrganizationModal";

interface Props {
  facility: Common.FacilityCode;
  refetch: () => void;
}

const OrganizationSearchBar: React.FC<Props> = ({ facility, refetch }) => {
  const [addOrgModalVisible, setAddOrgModalVisible] = useState(false);
  const onBatchUrlUpdate = useBatchUpdateUrlState();
  const renderAddOrgButton = () => {
    return (
      <Button
        style={{ marginTop: 20 }}
        size="large"
        type="primary"
        onClick={() => setAddOrgModalVisible(true)}
      >
        Add a new organization
      </Button>
    );
  };

  const renderSearchBar = () => (
    <WithLabel
      name="Search for an existing organization"
      style={{ display: "flex", flex: 1, marginBottom: 20 }}
    >
      <Input.Search
        size="large"
        placeholder="Search by organization name"
        onSearch={(organization: string) => {
          onBatchUrlUpdate({ organization, page: 1 });
        }}
        enterButton
        allowClear
        autoCapitalize="off"
      />
    </WithLabel>
  );
  return (
    <div>
      <Row align="bottom" style={{ marginBottom: 20 }} justify="end">
        {renderAddOrgButton()}
        <Col xs={24} md={24} lg={24}>
          {renderSearchBar()}
        </Col>
      </Row>
      {addOrgModalVisible && (
        <AddOrganizationModal
          visible={addOrgModalVisible}
          toggleVisible={setAddOrgModalVisible}
          facility={facility}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default OrganizationSearchBar;
