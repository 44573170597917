import React, { useEffect, useState } from "react";

// components
import { Input, Form } from "antd";

// styles
import * as S from "./styles";

interface Cell {
  label: string;
  text: string;
}

interface Props {
  format?: string;
  setLocationCode: (code: string) => void;
}

const LocationCodeCells: React.FC<Props> = ({ format, setLocationCode }) => {
  const [cells, setCells] = useState<Cell[]>([]);

  useEffect(() => {
    if (!format) return;
    const cells = format.split("-").map((label) => ({ label, text: "" }));
    cells.shift(); // remove the facility for cells
    setCells(cells);
  }, [format]);

  useEffect(() => {
    const locationCode = cells.map((c) => c.text.trim()).join("-");
    setLocationCode(locationCode);
  }, [cells, setLocationCode]);

  if (!format) return null;

  const onLabelChange = (index: number, value: string) => {
    const newCells = [...cells];
    newCells[index].text = value;
    setCells(newCells);
  };

  const buildInputLabels = () => {
    return cells.map((cell: Cell, index: number) => {
      const { label, text } = cell;
      return (
        <Form.Item
          key={`label-${index}`}
          label={label}
          hasFeedback
          validateStatus={text ? "success" : undefined}
        >
          <S.LabelWrap>
            <Input
              size="large"
              allowClear
              value={text}
              placeholder="code"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const text = e.target.value
                  .trim()
                  .replace(
                    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                    ""
                  );
                onLabelChange(index, text);
              }}
              autoCapitalize="off"
            />
          </S.LabelWrap>
        </Form.Item>
      );
    });
  };

  return (
    <S.Container>
      <Form layout="inline">{buildInputLabels()}</Form>
    </S.Container>
  );
};

export default LocationCodeCells;
