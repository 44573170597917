import styled, { css } from "styled-components";
import { Typography } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { COLORS } from "../../styles";

export const BoldText = styled.div`
  font-weight: 600;
  color: ${COLORS.BLACK};
`;

export const Title = styled(BoldText)`
  font-size: 24px;
`;

export const InfoText = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: ${COLORS.BLACK};
  margin: 5px 0 20px 0;
`;

export const HoverTipIcon = styled(QuestionCircleOutlined)`
  color: ${COLORS.BLUE};
  cursor: pointer;
`;

interface AttributeTextProps {
  isRequired?: boolean;
}

export const AttributeText = styled(Typography.Text)<AttributeTextProps>`
  ${(props) =>
    props.isRequired &&
    css`
      ::before {
        content: "*";
        color: ${COLORS.RED};
      }
    `}
`;
