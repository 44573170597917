import { Common } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import { Button, Modal, Select, Spin } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { fetchOrganizations } from "../../../../api/fulfillment/organization";
import { batchFetchOrCreateOrganizations } from "../../../../api/warehouse";

type Organization = Common.Organization;
type FacilityCode = Common.FacilityCode;

interface Props {
  facility: FacilityCode;
  visible: boolean;
  toggleVisible: (visible: boolean) => void;
  refetch: () => void;
}
const { Option } = Select;

const AddOrganizationModal: React.FC<Props> = ({
  facility,
  visible,
  toggleVisible,
  refetch,
}) => {
  const [selectedOrgsIds, setSelectedOrgsIds] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");

  const { data: organizations, isLoading: fetchingOrganizations } = useQuery(
    ["organizations", search],
    () => {
      return fetchOrganizations(search);
    },
    {
      enabled: !!search?.trim(),
      onSuccess: () => {
        setError("");
      },
      onError: setError,
    }
  );

  const { mutate: addOrganizations, isLoading: isAddingOrg } = useMutation(
    batchFetchOrCreateOrganizations,
    {
      onMutate: () => setError(""),
      onError: (e: string) => setError(e),
      onSuccess: () => {
        toggleVisible(false);
        refetch();
      },
    }
  );

  const closeModal = () => {
    setError("");
    toggleVisible(false);
  };

  const getValidOrganizations = useCallback((): Organization[] => {
    return organizations || [];
  }, [organizations]);

  const onAddOrgs = () => {
    const body = selectedOrgsIds?.map((orgId) => {
      return { organization_id: orgId, facility: facility };
    });
    addOrganizations(body);
  };

  const handleSearch = (searchValue: string) => {
    setSearch(searchValue);
  };

  const debouncedSearch = debounce(handleSearch, 500);

  const handleChange = (selectedOrgsIds: string[]) => {
    setSelectedOrgsIds(selectedOrgsIds);
  };

  const renderOptions = () => {
    return getValidOrganizations()?.map((org: Organization) => (
      <Option key={org.id} value={org.id} label={org.name}>
        {org.name}
      </Option>
    ));
  };

  const renderSearchBar = () => (
    <Select
      mode="multiple"
      size="large"
      showSearch
      filterOption={false}
      style={{ width: "100%" }}
      allowClear
      placeholder="Search for Organizations"
      loading={fetchingOrganizations}
      onSearch={debouncedSearch}
      onChange={handleChange}
      notFoundContent={null}
    >
      {renderOptions()}
    </Select>
  );

  return (
    <Modal
      centered
      destroyOnClose
      onCancel={closeModal}
      visible={visible}
      bodyStyle={{ minHeight: 140 }}
      title="Select organizations to add"
      footer={[
        <Button
          loading={isAddingOrg}
          key="Cancel"
          onClick={closeModal}
          disabled={isAddingOrg}
        >
          Cancel
        </Button>,
        <Button
          key="add"
          type="primary"
          loading={!!isAddingOrg}
          onClick={onAddOrgs}
          disabled={!selectedOrgsIds?.length || isAddingOrg}
        >
          Add
        </Button>,
      ]}
    >
      <Spin spinning={!!fetchingOrganizations}>
        <ErrorAlert error={error} />
        {renderSearchBar()}
      </Spin>
    </Modal>
  );
};

export default AddOrganizationModal;
