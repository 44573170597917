import { selector } from "recoil";
import Organization from "../../lib/organization";
import { packingFlowOrderState } from "./atoms";

export const getIsEndy = selector({
  key: "getIsEndy",
  get: ({ get }) => {
    const packingOrder = get(packingFlowOrderState);
    const organization = packingOrder?.organization;
    return Organization.isEndy({ organization });
  },
});

export const getIsHamuq = selector({
  key: "getIsHamuq",
  get: ({ get }) => {
    const packingOrder = get(packingFlowOrderState);
    const organization = packingOrder?.organization;
    return Organization.isHamuq({ organization });
  },
});

export const getIsVessi = selector({
  key: "getIsVessi",
  get: ({ get }) => {
    const packingOrder = get(packingFlowOrderState);
    const organization = packingOrder?.organization;
    return Organization.isVessi({ organization });
  },
});

export const getIsAcidLeague = selector({
  key: "getIsAcidLeague",
  get: ({ get }) => {
    const packingOrder = get(packingFlowOrderState);
    const organization = packingOrder?.organization;
    return Organization.isAcidLeague({ organization });
  },
});

export const getIsOddPieces = selector({
  key: "getIsOddPieces",
  get: ({ get }) => {
    const packingOrder = get(packingFlowOrderState);
    const organization = packingOrder?.organization;
    return Organization.isOddPieces({ organization });
  },
});
