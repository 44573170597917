import { BarcodeOutlined } from "@ant-design/icons";
import { Input, Spin } from "antd";
import React from "react";
import { COLORS } from "../../styles";
import * as S from "./styles";

interface Props {
  label?: string;
  value?: string;
  onEnter?: (s: string) => void;
  onChange?: (s: string) => void;
  rightAligned?: boolean;
  allowClear?: boolean;
  autoFocus?: boolean;
  scannerRef?: React.Ref<Input>;
  size?: "small" | "middle" | "large";
  containerStyles?: Record<string, unknown>;
  loading?: boolean;
  disabled?: boolean;
}

const ScannerInput: React.FC<Props> = ({
  label,
  value,
  onEnter,
  onChange,
  rightAligned,
  allowClear,
  autoFocus,
  scannerRef,
  size,
  containerStyles,
  loading,
  disabled,
}) => {
  const onValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target?.value);
  };

  const onEnterPressed = (e: any) => {
    if (onEnter) onEnter(e.target?.value);
  };

  return (
    <S.Container rightAligned={rightAligned} style={containerStyles}>
      {label && <S.ScanInputLabel>{label}</S.ScanInputLabel>}
      <Input
        ref={scannerRef}
        size={size || "large"}
        value={value}
        allowClear={allowClear}
        onChange={onValueChanged}
        onPressEnter={onEnterPressed}
        autoCapitalize="off"
        placeholder="Waiting for input..."
        autoFocus={!!autoFocus}
        disabled={loading || !!disabled}
        suffix={<BarcodeOutlined style={{ color: COLORS.GREY }} />}
      />
      <Spin style={{ marginLeft: 20 }} spinning={!!loading} />
    </S.Container>
  );
};

export default ScannerInput;
