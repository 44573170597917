import { Warehouse } from "@secondcloset/types";
import { useRecoilValue } from "recoil";

import Shipment from "../../../../../lib/shipment";
import {
  currentPackageItemIndexState,
  PackageContainer,
  packingFlowOrderState,
  packingFlowPackageContainerState,
  packingFlowPackageOrderItemIDsState,
  packingFlowShipmentState,
  shipmentSuppliesState,
  SupplyListItem,
} from "../../../../../recoil/packingFlow/atoms";

type Supply = Warehouse.Supply;

const { convertWeightToPounds } = Shipment;

export const useCalculatePackageWeight = () => {
  const shipment = useRecoilValue(packingFlowShipmentState);
  const shipmentSupplies = useRecoilValue(shipmentSuppliesState);
  const currentPackageItemIndex = useRecoilValue(currentPackageItemIndexState);
  const packageContainers = useRecoilValue(packingFlowPackageContainerState);
  const orderItemIDs = useRecoilValue(packingFlowPackageOrderItemIDsState);
  const currentPackageOrderItemIDs =
    orderItemIDs[currentPackageItemIndex].orderItemIDs;
  const currentPackageSupplies = shipmentSupplies[currentPackageItemIndex];
  const currentPackageContainer = packageContainers[currentPackageItemIndex];

  const getBoxWeight = () => {
    if (currentPackageContainer?.boxSupplyId) {
      const currentBoxWeight = currentPackageContainer?.boxWeight || 0;
      const currentBoxWeightUnit = currentPackageContainer?.boxWeightUnit;
      return convertWeightToPounds(currentBoxWeight, currentBoxWeightUnit);
    }
    return 0;
  };

  const getSuppliesWeight = () => {
    return (
      currentPackageSupplies?.reduce((acc, supply: SupplyListItem) => {
        const supplyWeightInLbs = convertWeightToPounds(
          supply.weight,
          supply.weight_unit
        );
        return acc + supplyWeightInLbs * supply.quantity;
      }, 0) || 0
    );
  };

  const getItemsWeight = () => {
    return (
      shipment?.shipment_items.reduce((acc, shipmentItem) => {
        if (currentPackageOrderItemIDs?.includes(shipmentItem.order_item_id)) {
          if (shipmentItem.weight_unit === "lb") {
            return acc + Number(shipmentItem.weight_value);
          }
          if (shipmentItem.weight_unit === "kg") {
            const weightInLbs = Number(shipmentItem.weight_value) * 0.453592;

            return acc + weightInLbs;
          }
        }
        return acc;
      }, 0) || 0
    );
  };

  const calculateWeight = () => {
    const boxWeight = getBoxWeight();
    const suppliesWeight = getSuppliesWeight();
    const itemsWeight = getItemsWeight();

    const totalWeight = boxWeight + suppliesWeight + itemsWeight;
    return totalWeight.toFixed(1);
  };

  return { calculateWeight };
};

export const useCalculatePackageInsurance = () => {
  const order = useRecoilValue(packingFlowOrderState);
  const currentPackageItemIndex = useRecoilValue(currentPackageItemIndexState);
  const orderItemIDs = useRecoilValue(packingFlowPackageOrderItemIDsState);
  const currentPackageOrderItemIDs =
    orderItemIDs[currentPackageItemIndex].orderItemIDs;

  const calculateInsurance = () =>
    order?.items.reduce((acc, item) => {
      const product = item.product as any;
      return currentPackageOrderItemIDs?.includes(item.id) &&
        product?.insurance_required
        ? acc + product?.insurance_value
        : acc;
    }, 0) || 0;

  return { calculateInsurance };
};

export const checkPackageTypeIsBox = (supply: PackageContainer): boolean => {
  return supply?.packageType?.toLowerCase() === "box";
};
export const checkPackageTypeIsPackaging = (
  supply: PackageContainer
): boolean => {
  return supply?.packageType?.toLowerCase() === "packaging";
};

export const checkSupplyIsPackage = (supply: Supply): boolean => {
  return supply?.type?.toLowerCase() === "packaging";
};

export const checkSupplyIsBox = (supply: Supply): boolean => {
  return supply?.type?.toLowerCase() === "box";
};
