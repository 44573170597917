import { Button, Table, TablePaginationConfig } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import React from "react";

// Components
import { PaginationInfo } from "../../../../api/warehouse/commonInterfaces";
import NullSafeDate from "../../../../components/Table/NullSafeDate";
import NullSafeText from "../../../../components/Table/NullSafeText";
import ItemCountFooter from "../../../../components/Table/ItemCountFooter";
import { buildTablePagination } from "../../../../components/Pagination/helper";

// Types
import { Warehouse } from "@secondcloset/types";
type PackerActivityLog = Warehouse.PackerActivityLog;

interface Props {
  logs: PackerActivityLog[];
  paginationInfo?: PaginationInfo;
  loading: boolean;
  onPageChange: (page: number, newPageSize?: number) => void;
  onSortChange: (sort: SorterResult<PackerActivityLog>) => void;
}

const PackerActivityLogsTable: React.FC<Props> = ({
  logs,
  paginationInfo,
  loading,
  onPageChange,
  onSortChange,
}) => {
  const renderNullSafeText = (v?: string) => <NullSafeText value={v} />;
  const renderLink = (value: string, href: string) => (
    <Button
      type="link"
      style={{ padding: 0 }}
      onClick={() => window.open(href)}
    >
      {value}
    </Button>
  );

  const renderDate = (date: string) => <NullSafeDate date={date} />;

  const buildTableColumns = () => {
    return [
      {
        title: "Date",
        dataIndex: "created_at",
        key: "created_at",
        render: renderDate,
        sorter: true,
        defaultSortOrder: "descend" as "ascend" | "descend" | null | undefined,
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (action: string) => action?.toUpperCase(),
      },
      {
        title: "Shipment ID",
        dataIndex: "shipment_number",
        key: "shipment_number",
        render: renderNullSafeText,
      },
      {
        title: "External Order Number",
        dataIndex: "external_order_id",
        key: "external_order_id",
        render: (id, record) =>
          renderLink(
            record.external_order_number,
            `${process.env.REACT_APP_SIERRA_DOMAIN}/fulfillment/orders/${id}`
          ),
      },
      {
        title: "User",
        dataIndex: "user_email",
        key: "user_email",
        render: renderNullSafeText,
      },
    ];
  };

  const buildPagination = (): TablePaginationConfig => {
    return buildTablePagination({
      paginationInfo,
      showQuickJumper: true,
      showSizeChanger: true,
      onChange: onPageChange,
    });
  };

  const renderTableFooter = () => (
    <ItemCountFooter count={paginationInfo?.totalItems || 0} />
  );

  return (
    <Table
      bordered
      size="small"
      scroll={{ x: "max-content" }}
      rowKey={(r) => r.id}
      footer={renderTableFooter}
      loading={loading}
      columns={buildTableColumns()}
      dataSource={logs}
      pagination={buildPagination()}
      onChange={(pagination, filters, sorter, extra) => {
        if (extra.action === "sort") {
          onSortChange(sorter as SorterResult<PackerActivityLog>);
        }
      }}
    />
  );
};

export default PackerActivityLogsTable;
