import styled from "styled-components";
import { Input as AntdInput } from "antd";

export const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  text-align: flex-start;
  margin-bottom: 30px;
`;

export const SwitchWrap = styled.div`
  margin-bottom: 30px;
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Input = styled(AntdInput)`
  width: 80%;
  text-align: center;
  margin-bottom: 20px;
`;
