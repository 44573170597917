interface Colors {
  BLACK: string;
  BLUE: string;
  CHARCOAL: string;
  ORANGE: string;
  GREEN: string;
  LIME_GREEN: string;
  GRASS_GREEN: string;
  GREY: string;
  GREY_LIGHT: string;
  GREY_MID: string;
  RED: string;
  RED_DARK: string;
  YELLOW: string;
  YELLOW_LIGHT: string;
  WHITE: string;
  TRANSPARENT: string;
  TRANSPARENT_GREY: string;
  APP_BACKGROUND: string;
  PANEL_BG: string;
  PANEL_BORDER: string;
  [index: string]: string;
}

const colors: Colors = {
  BLACK: "#000000",
  BLUE: "#384DF6",
  CHARCOAL: "#404041",
  ORANGE: "#F5A623",
  GREEN: "#00B176",
  LIME_GREEN: "#49DB86",
  GRASS_GREEN: "#6fcf97",
  GREY: "#9B9B9B",
  GREY_LIGHT: "#EEEEEE",
  GREY_MID: "#D5D5D5",
  RED: "#FF3353",
  RED_DARK: "#CF1422",
  YELLOW: "#FFD33D",
  YELLOW_LIGHT: "#FFECAD",
  WHITE: "#FFFFFF",
  TRANSPARENT: "transparent",
  TRANSPARENT_GREY: "rgba(0,0,0,0.25)",
  APP_BACKGROUND: "#FBFBFB",
  PANEL_BG: "#FAFAFA",
  PANEL_BORDER: "#D9D9D9",
};

export default colors;
