import { Facility } from "@secondcloset/fulfillment-utils";

export type StatusOptionKey =
  | "draft"
  | "awaiting"
  | "arrived"
  | "completed"
  | "on_hold";

export const STATUS_OPTIONS: {
  key: StatusOptionKey;
  value: StatusOptionKey;
  label: string;
}[] = [
  { key: "draft", value: "draft", label: "Draft" },
  { key: "awaiting", value: "awaiting", label: "Awaiting" },
  { key: "arrived", value: "arrived", label: "Arrived" },
  { key: "completed", value: "completed", label: "Completed" },
  { key: "on_hold", value: "on_hold", label: "On Hold" },
];

export type ExceptionOptionKey =
  | "missing_date"
  | "overdue"
  | "under_received"
  | "over_received";

export const EXCEPTION_OPTIONS: {
  key: ExceptionOptionKey;
  value: ExceptionOptionKey;
  label: string;
}[] = [
  { key: "missing_date", value: "missing_date", label: "Missing Date" },
  { key: "overdue", value: "overdue", label: "Overdue" },
  { key: "under_received", value: "under_received", label: "Under Received" },
  { key: "over_received", value: "over_received", label: "Over Received" },
];

export const WAREHOUSE_OPTIONS = Facility.getFacilityList().map((f) => ({
  key: f.code,
  value: f.code,
  label: f.name,
}));
