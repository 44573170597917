import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Button, Col, PageHeader, Row, Space, Typography } from "antd";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { ErrorAlert } from "@secondcloset/web-components";

// components
import RequestLogsTable from "./RequestLogsTable";
import RequestIDsModal from "./RequestIDsModal";
import PageContainer from "../../components/PageContainer";

import useUrlState from "@ahooksjs/use-url-state";

// api
import {
  createPalletRequest,
  fetchPalletRequestLogs,
} from "../../api/warehouse/pallets";

const RequestPalletIDsPage: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [createError, setCreateError] = useState("");
  const [{ source, page, perPage }, setUrlState] = useUrlState(
    {
      source: undefined,
      page: 1,
      perPage: 10,
    },
    { navigateMode: "replace" }
  );

  const {
    data: palletRequestLogs,
    isLoading: fetchingRequests,
    refetch: refetchLogs,
  } = useQuery(
    ["fetchPalletRequestLogs", page, perPage, source],
    () => fetchPalletRequestLogs({ page, limit: perPage, source }),
    {
      onError: setError,
    }
  );

  const { mutate: createRequest, isLoading: creatingRequest } = useMutation(
    createPalletRequest,
    {
      onError: (error: string | Error) => {
        const errorMsg = typeof error === "string" ? error : error.message;
        setCreateError(errorMsg);
      },
      onSuccess: () => {
        setIsModalVisible(false);
        refetchLogs({ cancelRefetch: true, throwOnError: true });
      },
    }
  );

  const renderButtonGroups = () => {
    return (
      <Row justify="end" align="bottom" gutter={[8, 16]}>
        <Col xs={24} lg={6}>
          <Button
            size="large"
            block
            onClick={() =>
              refetchLogs({ cancelRefetch: true, throwOnError: true })
            }
          >
            <ReloadOutlined />
            Refresh
          </Button>
        </Col>
        <Col xs={24} lg={7}>
          <Button
            size="large"
            block
            type="primary"
            onClick={() => setIsModalVisible(true)}
          >
            <PlusOutlined />
            New Request
          </Button>
        </Col>
      </Row>
    );
  };

  const renderToolbar = () => {
    return (
      <Row align="top" justify="space-between" gutter={[8, 16]}>
        <Col xs={24} lg={12}>
          <Typography.Title ellipsis>Request Pallet IDs</Typography.Title>
        </Col>
        <Col xs={24} lg={12}>
          {renderButtonGroups()}
        </Col>
      </Row>
    );
  };

  const isLoading = fetchingRequests || creatingRequest;

  const renderTable = () => {
    return (
      <RequestLogsTable
        requestLogs={palletRequestLogs?.items || []}
        paginationInfo={palletRequestLogs?.meta}
        loading={isLoading}
        setError={setError}
        onPageChange={(p: number, newPageSize?: number) => {
          if (newPageSize && newPageSize !== +perPage) {
            setUrlState({ page: 1, perPage: newPageSize });
          } else {
            setUrlState({ page: p });
          }
        }}
      />
    );
  };

  return (
    <PageContainer withPadding>
      <PageHeader title="Pallets" onBack={() => window.history.back()} />
      <Space direction="vertical" style={{ width: "100%" }}>
        <ErrorAlert error={error} />
        {renderToolbar()}
        {renderTable()}
        <RequestIDsModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          palletSource={source}
          isLoading={creatingRequest}
          onSubmit={(body) => createRequest({ ...body, source })}
          onCancel={() => setIsModalVisible(false)}
          onChange={() => setCreateError("")}
          errorMessage={createError}
        />
      </Space>
    </PageContainer>
  );
};

export default RequestPalletIDsPage;
