import { Table, TablePaginationConfig } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import React from "react";
import { Warehouse } from "@secondcloset/types";
import { FixedType } from "rc-table/lib/interface";

// Components
import { PaginationInfo } from "../../../../api/warehouse/commonInterfaces";
import NullSafeDate from "../../../../components/Table/NullSafeDate";
import NullSafeLink from "../../../../components/Table/NullSafeLink";
import NullSafeText from "../../../../components/Table/NullSafeText";
import ProductIdentifiersField from "../../../../components/Table/ProductIdentifiersField";
import ItemCountFooter from "../../../../components/Table/ItemCountFooter";
import { buildTablePagination } from "../../../../components/Pagination/helper";

type LocationActivityLog = Warehouse.LocationActivityLog;

interface Props {
  logs: LocationActivityLog[];
  paginationInfo?: PaginationInfo;
  loading: boolean;
  onPageChange: (page: number, newPageSize?: number) => void;
  onSortChange: (sort: SorterResult<LocationActivityLog>) => void;
}

const LocationActivityLogsTable: React.FC<Props> = ({
  logs,
  paginationInfo,
  loading,
  onPageChange,
  onSortChange,
}) => {
  const renderNullSafeText = (v?: string) => <NullSafeText value={v} />;
  const renderNullSafeLink = (value: string, href: string) => (
    <NullSafeLink value={value} href={href} />
  );

  const renderDate = (date: string) => <NullSafeDate date={date} />;
  const renderItemDetail = (product, record) => {
    const sku = product?.sku;
    const upc = product?.upc;
    const name = product?.name;
    const productId = product?.id;
    const customer = record?.manual_item?.customer_name;
    const code = record?.manual_item?.code;
    return (
      <ProductIdentifiersField
        sku={sku}
        upc={upc}
        name={name}
        code={code}
        customer={customer}
        productId={productId}
      />
    );
  };

  const buildTableColumns = () => {
    return [
      {
        title: "Date",
        dataIndex: "created_at",
        key: "created_at",
        render: renderDate,
        sorter: true,
        defaultSortOrder: "descend" as "ascend" | "descend" | null | undefined,
        fixed: "left" as FixedType,
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (action: string) => action.toUpperCase(),
        fixed: "left" as FixedType,
      },
      {
        title: "Item",
        dataIndex: "product",
        key: "id",
        render: renderItemDetail,
      },
      {
        title: "From Location",
        dataIndex: "from_location_code",
        key: "from_location_code",
        render: (value: string) =>
          renderNullSafeLink(value, `/locations/${value}`),
      },
      {
        title: "To Location",
        dataIndex: "to_location_code",
        key: "to_location_code",
        render: (value: string) =>
          renderNullSafeLink(value, `/locations/${value}`),
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "Pallet ID",
        dataIndex: "pallet_id",
        key: "pallet_id",
        render: renderNullSafeText,
      },
      {
        title: "User",
        dataIndex: "user_email",
        key: "user_email",
      },
    ];
  };

  const buildPagination = (): TablePaginationConfig => {
    if (!paginationInfo) return {};
    return buildTablePagination({
      paginationInfo,
      showQuickJumper: true,
      showSizeChanger: true,
      onChange: onPageChange,
    });
  };

  const renderTableFooter = () => (
    <ItemCountFooter count={paginationInfo?.totalItems || 0} />
  );

  return (
    <Table
      bordered
      size="small"
      scroll={{ x: "max-content" }}
      rowKey={(r) => r.id}
      footer={renderTableFooter}
      loading={loading}
      columns={buildTableColumns()}
      dataSource={logs}
      pagination={buildPagination()}
      onChange={(pagination, filters, sorter, extra) => {
        if (extra.action === "sort") {
          onSortChange(sorter as SorterResult<LocationActivityLog>);
        }
      }}
    />
  );
};

export default LocationActivityLogsTable;
