import React from "react";

//styles
import { Container, NumberCircle, Title } from "./styles";

interface Props {
  number?: number;
  text: string;
}

const NumberedTitle: React.FC<Props> = ({ number, text }) => {
  return (
    <Container>
      {number !== undefined && <NumberCircle>{number}</NumberCircle>}
      <Title>{text}</Title>
    </Container>
  );
};

export default NumberedTitle;
