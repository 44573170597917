import React from "react";
import { notification, Select } from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";

import WithLabel from "../../../../components/WithLabel";

// apis
import { fetchLocationTemplates } from "../../../../api/warehouse";

// hooks
import { useQuery } from "react-query";

type LocationTemplate = Warehouse.LocationTemplate;

interface Props {
  setSelectedTemplate: (locationTemplate: LocationTemplate) => void;
}

const LocationTemplateLookup: React.FC<Props> = ({ setSelectedTemplate }) => {
  const { data: locationTemplates, isLoading } = useQuery(
    ["fetchLocationTemplates"],
    () => {
      return fetchLocationTemplates({
        limit: "1000",
      });
    },
    {
      onError: (error: string) => {
        notification.error({ message: error });
      },
    }
  );

  const buildOptions = () => {
    const items: LocationTemplate[] = locationTemplates?.items || [];
    return items.map((template) => {
      return (
        <Select.Option key={template.id} value={template.id}>
          {`${template.name} (${template.format})`}
        </Select.Option>
      );
    });
  };

  return (
    <WithLabel name="Select Template">
      <Select
        size="large"
        allowClear
        showSearch
        placeholder="Location code template"
        filterOption={(inputValue: string, option: any) =>
          option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        }
        notFoundContent={null}
        suffixIcon={isLoading ? <LoadingOutlined /> : <SearchOutlined />}
        onSelect={(id: string) => {
          const foundTemplate = locationTemplates?.items?.find(
            (t) => t.id === id
          );
          if (foundTemplate) {
            setSelectedTemplate(foundTemplate);
          }
        }}
      >
        {buildOptions()}
      </Select>
    </WithLabel>
  );
};

export default LocationTemplateLookup;
