import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { ErrorAlert } from "@secondcloset/web-components";
import { CheckOutlined, UndoOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Divider,
  notification,
  Popconfirm,
  Row,
  Space,
} from "antd";

import QuantityTable from "./QuantityTable/QuantityTable";
import ScannerInput from "../../../components/ScannerInput";
import WithLabel from "../../../components/WithLabel";

import { fetchLocationItems, locationTransfer } from "../../../api/warehouse";
import { useSoundEffect } from "../../../hooks/useSoundEffect";
import useUrlState from "@ahooksjs/use-url-state";
import { buildTablePagination } from "../../../components/Pagination/helper";
import { removeWhitespace } from "../../../lib/removeWhitespace";

interface Props {
  setContainerLoading: (loading: boolean) => void;
}

const BatchMoveLocationView: React.FC<Props> = ({ setContainerLoading }) => {
  const { playSuccessSound } = useSoundEffect();
  const [toLocationCode, setToLocationCode] = useState("");
  const [fromLocationCode, setFromLocationCode] = useState("");
  const [error, setError] = useState("");
  const [{ page, perPage }, setUrlState] = useUrlState(
    {
      page: 1,
      perPage: 10,
    },
    { navigateMode: "replace" }
  );

  const { mutate: moveLocation, isLoading } = useMutation(
    () =>
      locationTransfer({
        from_location_code: removeWhitespace(fromLocationCode).toUpperCase(),
        to_location_code: removeWhitespace(toLocationCode).toUpperCase(),
      }),
    {
      onSettled: () => {
        setContainerLoading(false);
      },
      onSuccess: () => {
        notification.success({
          message: `Successfully moved items to ${toLocationCode}`,
        });
        setError("");
        setFromLocationCode("");
        setToLocationCode("");
        resetAllValues();
        playSuccessSound();
      },
      onError: (e: string) => setError(e),
    }
  );

  const {
    mutate: getLocationItems,
    data: locationItemsData,
    reset: resetLocationItemsData,
  } = useMutation(
    () =>
      fetchLocationItems({
        location_code: fromLocationCode,
        page: page.toString(),
      }),
    {
      onSettled: () => {
        setContainerLoading(false);
      },
      onSuccess: ({ items }) => {
        if (items?.length === 0) {
          setError("No items found");
        } else setError("");
      },
      onError: (error: string) => {
        setError(error);
      },
    }
  );

  useEffect(() => {
    if (fromLocationCode) getLocationItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const hasPallets = locationItemsData?.items?.find((i) => !!i.pallet_id);

  const resetAllValues = () => {
    setUrlState({ page: 1 });
    resetLocationItemsData();
    setFromLocationCode("");
    setToLocationCode("");
    setError("");
  };

  const onFromLocationChange = (fromLocationCode: string) => {
    const id = fromLocationCode?.trim();
    setFromLocationCode(id);
  };

  const onFromLocationCodeInputEntered = (fromLocationCode: string) => {
    const id = fromLocationCode?.trim();
    if (!id) return;
    setContainerLoading(true);
    setUrlState({ page: 1 });
    getLocationItems();
  };

  const onClickConfirm = () => {
    if (!fromLocationCode) return setError("Please scan a location");
    if (!locationItemsData?.items?.length)
      return setError("No items on this location");
    if (!toLocationCode)
      return setError(
        'The "To location" cannot be empty, please scan/type a valid location code.'
      );

    setError("");
    setContainerLoading(true);
    moveLocation();
  };

  const buildPagination = () => {
    const tablePaginationConfig = {
      paginationInfo: locationItemsData?.meta,
      onChange: (p: number, newPageSize?: number) => {
        if (newPageSize && newPageSize !== +perPage) {
          setUrlState({ page: 1, perPage: newPageSize });
        } else {
          setUrlState({ page: p });
        }
      },
    };
    return buildTablePagination(tablePaginationConfig);
  };

  const renderLocationScannerInput = () => {
    return (
      <WithLabel name="Scan From Location">
        <ScannerInput
          value={fromLocationCode}
          onEnter={onFromLocationCodeInputEntered}
          onChange={onFromLocationChange}
          autoFocus
          allowClear
        />
      </WithLabel>
    );
  };

  const renderResetButton = () => {
    return (
      <Button
        type="primary"
        size="large"
        icon={<UndoOutlined />}
        block
        ghost
        danger
        disabled={!locationItemsData?.items?.length}
        onClick={() => resetAllValues()}
      >
        Clear all
      </Button>
    );
  };

  const renderToScannerInput = () => {
    return (
      <WithLabel name="To Location">
        <ScannerInput
          allowClear
          value={toLocationCode}
          onChange={(l) => setToLocationCode(l.trim())}
        />
      </WithLabel>
    );
  };

  const renderConfirmButton = () => {
    return (
      <Popconfirm
        title={`Are you sure to move all items from '${fromLocationCode}' to '${toLocationCode}'?`}
        onConfirm={onClickConfirm}
        okText="Yes"
        cancelText="No"
        disabled={!!hasPallets || !locationItemsData}
      >
        <Button
          block
          type="primary"
          size="large"
          icon={<CheckOutlined />}
          loading={isLoading}
          disabled={!!hasPallets || !toLocationCode || !locationItemsData}
        >
          Confirm
        </Button>
      </Popconfirm>
    );
  };

  const renderWarning = () => {
    if (!hasPallets) return null;
    return (
      <Alert
        type="warning"
        style={{ margin: "10px 0" }}
        showIcon
        message="Warning"
        description="Please move all pallets out before using this function"
      />
    );
  };

  const renderTopToolbar = () => {
    return (
      <Row justify="space-between" align="bottom" gutter={[8, 8]}>
        <Col xs={24} lg={16}>
          {renderLocationScannerInput()}
        </Col>
        <Col xs={24} lg={3}>
          {renderResetButton()}
        </Col>
      </Row>
    );
  };

  const renderBottomToolbar = () => {
    return (
      <>
        <Row justify="space-between" align="bottom" gutter={[8, 8]}>
          <Col xs={24} lg={{ span: 8, push: 16 }}>
            {renderToScannerInput()}
          </Col>
        </Row>
        <Divider />
        <Row justify="center" align="bottom" gutter={[8, 8]}>
          <Col xs={24} lg={8}>
            {renderConfirmButton()}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Space style={{ width: "100%" }} direction="vertical" size="large">
      {renderTopToolbar()}
      {renderWarning()}
      <ErrorAlert error={error} />
      <QuantityTable
        currentLocation={fromLocationCode}
        pagination={buildPagination()}
        locationItems={locationItemsData?.items}
      />
      {renderBottomToolbar()}
    </Space>
  );
};

export default BatchMoveLocationView;
