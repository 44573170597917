import styled from "styled-components";
import { CheckCircleFilled as CheckCircleFilledAntd } from "@ant-design/icons";
import { MIXINS, COLORS } from "../../../../../styles";

export const Container = styled.div`
  width: 100%;
  ${MIXINS.includeBoxShadow()}
  border-radius: 4px;
  overflow: hidden;
`;

export const EmptyContainer = styled.div`
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RatesWrap = styled.div`
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 40px;
  max-height: 550px;
  overflow: hidden auto;
`;

interface RateOptionProps {
  selected: boolean;
}

export const RateOption = styled.div<RateOptionProps>`
  display: flex;
  align-items: center;
  width: 100%;
  border: ${({ selected }) => (selected ? `2px solid ${COLORS.GREEN}` : "")};
  border-radius: 6px;
  padding: 10px 0;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &:after {
    ${({ selected }) =>
      selected
        ? `
    content: "SELECTED";
    position: absolute;
    top: -10px;
    right: -4px;
    color: ${COLORS.WHITE};
    background: ${COLORS.GREEN};
    padding: 2px 10px 2px 20px;
    font-size: 10px;
    font-weight: 600;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);`
        : ""}
  }
`;

export const Circle = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${COLORS.GREY_MID};
  position: relative;
  border-radius: 100%;
  transition: all 0.2 ease-in;
  margin: 0 8px;

  &:hover {
    border: 1px solid ${COLORS.BLUE};
    cursor: pointer;
  }

  &::after {
    transition: all 0.2 ease-in;
    content: "";
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 100%;
    background: ${COLORS.WHITE};
  }
`;

export const CheckCircleFilled = styled(CheckCircleFilledAntd)`
  & svg {
    fill: ${COLORS.GREEN};
    width: 20px;
    height: 20px;
    margin: 0 8px;
  }
`;

export const RateImageWrap = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100px;
  min-width: 100px;
`;

export const RateImage = styled.img`
  max-width: 100px;
  max-height: 50px;
`;

export const RateInfo = styled.div`
  margin-left: 10px;
  width: 150px;
`;

export const RateName = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

export const RatePriceDaysWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const RatePrice = styled.div`
  border-right: 1px solid ${COLORS.GREY_MID};
  padding-right: 10px;
  color: ${COLORS.GREY};
`;

export const RateDays = styled.div`
  padding-left: 10px;
  color: ${COLORS.GREY};
`;

export const RecommendedShippingWrap = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${COLORS.ORANGE};
  padding: 24px;
  & strong {
    color: ${COLORS.CHARCOAL};
  }
`;

export const RecommendedShipping = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SignatureRateIconWrap = styled.div`
  background-color: ${COLORS.ORANGE};
  width: 30px;
  min-width: 30px;
  border-radius: 50%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;
