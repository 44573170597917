// Types
import { Common } from "@secondcloset/types";
import { Button, Table } from "antd";
import React, { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
// API
import { fetchOrganizationDetails } from "../../../../../api/fulfillment/organization";

// Helpers
import formatPhoneNumber from "../../../../../lib/formatPhoneNumber";
// Recoil
import {
  asnCreateFlowLoadingOrganizations,
  asnCreateFlowOrganizations,
  asnCreateFlowSelectedOrganization,
} from "../../../../../recoil/asnCreateFlow/atoms";
// Components
import NullSafeDate from "../../../../../components/Table/NullSafeDate";
//Styles
import * as S from "./styles";

type Organization = Common.Organization;

const AsnClientTable: React.FC = () => {
  const setSelectedOrganization = useSetRecoilState(
    asnCreateFlowSelectedOrganization
  );
  const loadingOrganizations = useRecoilValue(
    asnCreateFlowLoadingOrganizations
  );
  const organizations = useRecoilValue(asnCreateFlowOrganizations);
  const [loading, setLoading] = useState(false);

  const renderSelectOrganizationButton = (organization: Organization) => (
    <Button
      type="primary"
      onClick={async () => {
        setLoading(true);
        const organizationDetails = await fetchOrganizationDetails(
          organization.id
        );
        setSelectedOrganization(organizationDetails);
        setLoading(false);
      }}
      loading={loading}
    >
      Select
    </Button>
  );

  const renderDate = (date: string) => <NullSafeDate date={date} />;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (number: string) => formatPhoneNumber(number),
    },
    {
      title: "Date Created",
      dataIndex: "created_at",
      key: "created_at",
      render: renderDate,
    },
    {
      title: "",
      dataIndex: "",
      key: "select",
      render: (_: undefined, organization: Organization) =>
        renderSelectOrganizationButton(organization),
    },
  ];

  return (
    <S.TableWrapper>
      <Table
        loading={loadingOrganizations}
        dataSource={organizations}
        columns={columns}
        pagination={false}
        indentSize={4}
        size="middle"
      />
    </S.TableWrapper>
  );
};

export default AsnClientTable;
