// TODO: move into a common file?

import { clone } from "lodash-es";

export const updateIdsListWithNewId = (
  originalListOfIds: string[],
  incomingId: string
) => {
  const newListOfIds = clone(originalListOfIds);
  newListOfIds.push(incomingId);
  return newListOfIds;
};

export const findAndRemoveIdFromList = (
  originalListOfIds: string[],
  selectedId: string
) => {
  const newListOfIds = clone(originalListOfIds);
  const position = newListOfIds?.findIndex((id) => id === selectedId);
  newListOfIds.splice(position, 1);
  return newListOfIds;
};

export const isPdfDownloading = (
  picksheetId: string,
  pdfIdsBeingDownloaded: string[]
) => {
  return pdfIdsBeingDownloaded?.includes(picksheetId);
};
