import styled from "styled-components";
import { COLORS, LAYOUT } from "../../../styles";
export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - (${LAYOUT.HEADER_HEIGHT} + ${LAYOUT.FOOTER_HEIGHT}));
`;

export const Message = styled.div`
  color: ${COLORS.CHARCOAL};
  font-size: 20px;
`;

export const Instruction = styled.div`
  color: ${COLORS.BLUE};
  font-size: 20px;
  margin: 10px;
  font-weight: 500;
  margin-bottom: 40px;
`;

export const ModalContainer = styled.div``;

export const ModalTitle = styled.div`
  margin: 20px 0;
  text-align: center;
`;

export const Label = styled.div`
  margin-top: 40px;
  margin-bottom: 10px;
  color: ${COLORS.CHARCOAL};
  font-size: 12px;
  font-weight: 600;
`;

export const ScannerInput = styled.input`
  position: fixed;
  top: -100px;
`;
