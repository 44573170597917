import { atom } from "recoil";

//interface
import { Fulfillment, Logistics, Warehouse } from "@secondcloset/types";
import { SelectShipmentCarrierTypes } from "@secondcloset/web-components";

// types
type Order = Fulfillment.Order;
type Shipment = Fulfillment.Shipment;
type ShippingRate = Logistics.ShippingRate;
type Package = Fulfillment.Package;
type ShippingMethod = SelectShipmentCarrierTypes.RecommendedShippingMethod;
type Supply = Warehouse.Supply;

export enum Step {
  selectShipmentMethod = "selectShipmentMethod",
  selectPackageType = "selectPackageType",
  selectShipmentItem = "selectShipmentItem",
  selectCarrier = "selectCarrier",
}

export const packingFlowStepState = atom<Step>({
  key: "packingFlowStepState",
  default: Step.selectShipmentMethod,
});

export const packingFlowOrderState = atom<Order | undefined>({
  key: "packingFlowOrderState",
  default: undefined,
});
export interface PackageDimensions {
  length: number;
  width: number;
  height: number;
  [index: string]: number;
}
export const INITIAL_DIMENSIONS = { length: 0, width: 0, height: 0 };

export const currentPackageItemIndexState = atom<number>({
  key: "currentPackageItemIndexState",
  default: 0,
});

export enum PackageType {
  box = "box",
  standard_pallet = "standard_pallet",
  double_pallet = "double_pallet",
}

export interface PackageContainer {
  length: number;
  width: number;
  height: number;
  displayName?: string;
  boxSupplyId?: string;
  packageType?: PackageType;
  isCustomDimensions: boolean;
  boxWeight?: number;
  boxWeightUnit?: Warehouse.WeightUnitType;
  insuranceValue?: string;
  insuranceCurrency?: string;
}

export const packingFlowPackageContainerState = atom<PackageContainer[]>({
  key: "packingFlowPackageContainerState",
  default: [
    {
      length: 0,
      width: 0,
      height: 0,
      isCustomDimensions: false,
    },
  ],
});

export interface PackageWeight {
  weight: number;
  manuallyEdited: boolean;
}

export const packingFlowPackageWeightState = atom<PackageWeight[]>({
  key: "packingFlowPackageWeightState",
  default: [{ weight: 0, manuallyEdited: false }],
});

export interface PackageInsurance {
  insuranceValue: number;
  insuranceCurrency: string;
}

export const packingFlowPackageInsuranceState = atom<PackageInsurance[]>({
  key: "packingFlowPackageInsuranceState",
  default: [],
});

export interface PackageOrderItemIDs {
  orderItemIDs: string[];
}

export const packingFlowPackageOrderItemIDsState = atom<PackageOrderItemIDs[]>({
  key: "packingFlowPackageOrderItemIDsState",
  default: [{ orderItemIDs: [] }],
});

export const packingFlowShippingRateState = atom<ShippingRate | undefined>({
  key: "packingFlowShippingRateState",
  default: undefined,
});

export const packingFlowShipmentState = atom<Shipment | undefined>({
  key: "packingFlowShipmentState",
  default: undefined,
});

export const packingFlowCurrentPackageState = atom<Package | undefined>({
  key: "packingFlowCurrentPackageState",
  default: undefined,
});

export const packingFlowShippingMethodState = atom<ShippingMethod | undefined>({
  key: "packingFlowShippingMethodState",
  default: undefined,
});

export const packingFlowPackageTypeState = atom<PackageType>({
  key: "packingFlowPackageTypeState",
  default: PackageType.box,
});

export const packingFlowShowPalletTypeModalState = atom<boolean>({
  key: "packingFlowShowPalletTypeModalState",
  default: false,
});

export const packingFlowScannedItemCode = atom<string>({
  key: "packingFlowScannedItemCode",
  default: "",
});

/** LOT NUMBER TRACKING */
export type LotTrackingList = {
  [productID: string]: {
    lotNumber: string;
    serialNumber: string;
    name: string;
  }[];
};

type ShipmentProductTrackingList = {
  [packageIndex: number]: { lotTrackingList: LotTrackingList };
};

export const shipmentProductTrackingListState =
  atom<ShipmentProductTrackingList>({
    key: "ShipmentProductTrackingListState",
    default: {},
  });

export const lotNumberTrackingFlowEnabledState = atom({
  key: "lotNumberTrackingFlowEnabledState",
  default: false,
});

export type SupplyListItem = Supply & {
  quantity: number;
  code?: string;
};

export const shipmentSuppliesState = atom<SupplyListItem[][]>({
  key: "shipmentSuppliesState",
  default: [],
});

export type SupplyStockQuantity = {
  [supplyId: string]: number;
};

export const supplyUsedQuantityState = atom<SupplyStockQuantity>({
  key: "supplyUsedQuantityState",
  default: {},
});

export const scannerModeEnabledState = atom({
  key: "scannerModeEnabledState",
  default: false,
});

export const isUserConfirmedPoBoxAddressState = atom({
  key: "isUserConfirmedPoBoxAddressState",
  default: false,
});
