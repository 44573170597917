import React from "react";
import { useRecoilValue } from "recoil";
import { Button, InputNumber } from "antd";

import {
  currentPackageItemIndexState,
  PackageType,
  packingFlowPackageTypeState,
  packingFlowPackageContainerState,
  PackageContainer,
} from "../../../../../../../recoil/packingFlow/atoms";
import { useEditPackage } from "../../../../../../../recoil/packingFlow/helpers";

import * as S from "./styles";

interface Props {
  isBox: boolean;
}

const DimensionInputs: React.FC<Props> = ({ isBox }) => {
  const packageContainers = useRecoilValue(packingFlowPackageContainerState);
  const currentPackageItemIndex = useRecoilValue(currentPackageItemIndexState);
  const packageType = useRecoilValue(packingFlowPackageTypeState);
  const currentPackageContainer = packageContainers[currentPackageItemIndex];
  const { replaceCurrentContainerWith } = useEditPackage();

  const handleChange = (target: string, value: string | number | undefined) => {
    const newContainer = { ...currentPackageContainer };
    newContainer[target] = +(value as string) || 0;
    replaceCurrentContainerWith(newContainer);
  };

  const handleOnClickBack = () => {
    const newContainer: PackageContainer = {
      length: 0,
      width: 0,
      height: 0,
      isCustomDimensions: false,
    };
    replaceCurrentContainerWith(newContainer);
  };

  const buildInputNumber = (options: {
    property: string;
    max?: number;
    autoFocus?: boolean;
  }) => {
    const { property, max, autoFocus } = options;
    return (
      <S.InputWrapper>
        <S.LabelText>{`${property.toUpperCase()} (IN)`}</S.LabelText>
        <InputNumber
          key={property + currentPackageItemIndex}
          value={currentPackageContainer[property]}
          onChange={(value) => handleChange(property, value)}
          onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
            event.target.select();
          }}
          min={0}
          max={max}
          autoFocus={!!autoFocus}
        />
      </S.InputWrapper>
    );
  };

  const maxHeight =
    packageType === PackageType.standard_pallet ||
    packageType === PackageType.double_pallet
      ? 54
      : undefined;

  const renderBackButton = () => {
    return (
      <Button type="link" style={{ padding: 0 }} onClick={handleOnClickBack}>
        Cancel
      </Button>
    );
  };

  return (
    <S.DimensionsWrapper>
      {buildInputNumber({ property: "length", autoFocus: true })}
      {buildInputNumber({ property: "width" })}
      {buildInputNumber({ property: "height", max: maxHeight })}
      {isBox ? renderBackButton() : ""}
    </S.DimensionsWrapper>
  );
};

export default DimensionInputs;
