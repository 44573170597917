import React from "react";
import { Popover, Table, TablePaginationConfig } from "antd";
import { Warehouse } from "@secondcloset/types";

// components
import { PaginationInfo } from "../../../api/warehouse/commonInterfaces";
import ItemCountFooter from "../../../components/Table/ItemCountFooter";
import NullSafeText from "../../../components/Table/NullSafeText";

// styles
import * as S from "./styles";
import { buildTablePagination } from "../../../components/Pagination/helper";

type ShipmentProductsTracking = Warehouse.ShipmentProductsTracking;

interface Props {
  shipmentProductsTracking: ShipmentProductsTracking[];
  paginationInfo?: PaginationInfo;
  loading: boolean;
  onPageChange: (page: number, newPageSize?: number) => any;
}

const ShipmentProductsTrackingTable: React.FC<Props> = ({
  shipmentProductsTracking,
  paginationInfo,
  loading,
  onPageChange,
}) => {
  const renderText = (value: string) => {
    return <NullSafeText value={value} />;
  };

  const renderLink = (orderID: string) => {
    return (
      <S.Link
        onClick={() =>
          window.open(
            `${process.env.REACT_APP_SIERRA_DOMAIN}/fulfillment/orders/${orderID}`
          )
        }
      >
        {orderID.slice(0, 4)}..
      </S.Link>
    );
  };

  const renderProduct = (_: any, record: ShipmentProductsTracking) => {
    const { product_name, product_sku, product_upc } = record;

    const content = (
      <S.ProductWrap>
        <S.Description>SKU: {product_sku || "-"}</S.Description>
        <S.Description>UPC: {product_upc || "-"}</S.Description>
      </S.ProductWrap>
    );

    return (
      <Popover content={content} trigger="hover">
        <S.Name>{product_name || "-"}</S.Name>
      </Popover>
    );
  };

  const buildTableColumns = () => {
    return [
      {
        title: "Order",
        dataIndex: "external_order_id",
        key: "external_order_id",
        render: renderLink,
      },
      {
        title: "Product",
        dataIndex: "product_sku",
        key: "product",
        render: renderProduct,
      },
      {
        title: "Lot Number",
        dataIndex: "lot_number",
        key: "lot_number",
        render: renderText,
      },
      {
        title: "Serial Number",
        dataIndex: "serial_number",
        key: "serial_number",
        render: renderText,
      },
      {
        title: "Organization",
        dataIndex: "organization",
        key: "organization",
        render: (org: any) => renderText(org.name),
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
      },
    ];
  };

  const buildPagination = (): TablePaginationConfig => {
    if (!paginationInfo) return {};

    return buildTablePagination({
      paginationInfo,
      showQuickJumper: true,
      showSizeChanger: true,
      onChange: onPageChange,
    });
  };

  const renderTableFooter = () => (
    <ItemCountFooter count={paginationInfo?.totalItems || 0} />
  );

  return (
    <Table
      bordered
      size="small"
      scroll={{ x: "max-content" }}
      rowKey="id"
      footer={renderTableFooter}
      loading={loading}
      columns={buildTableColumns()}
      pagination={buildPagination()}
      dataSource={shipmentProductsTracking}
    />
  );
};

export default ShipmentProductsTrackingTable;
