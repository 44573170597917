import { CheckCircleFilled } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";

import {
  custodyTransferFlowPalletPackageState,
  custodyTransferFlowScannedInputState,
} from "../../../recoil/custodyTransfer/atoms";

import PageContainer from "../../../components/PageContainer";

import { COLORS } from "../../../styles";
import * as S from "../TransferCustodyScannerPage/styles";

const TransferCustodySuccessPage: React.FC = () => {
  const history = useHistory();
  const ctPackages = useRecoilValue(custodyTransferFlowPalletPackageState);
  const scannedInputValue = useRecoilValue(
    custodyTransferFlowScannedInputState
  );
  const shippingPackage = ctPackages?.carrier_transfer_packages?.[0];

  useEffect(() => {
    if (!scannedInputValue) {
      history.replace("transfer-custody-scanner");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderScanAgainButton = () => (
    <Button
      size="large"
      onClick={() => history.push("/transfer-custody-scanner")}
    >
      Scan Again
    </Button>
  );

  return (
    <PageContainer withPadding title="Transfer Custody">
      <S.ContentContainer align="center" direction="vertical">
        <S.Card>
          <S.CardContentContainer align="center" direction="vertical">
            <S.SuccessTitle level={2}>
              {ctPackages?.id || shippingPackage?.tracking_number}
            </S.SuccessTitle>
            <S.SuccessTitle level={4}>
              has been handed over to <u>{shippingPackage?.carrier_name}</u>{" "}
              carrier
            </S.SuccessTitle>
            <CheckCircleFilled
              style={{ fontSize: 80, color: COLORS.GREEN, margin: 40 }}
            />
            {renderScanAgainButton()}
          </S.CardContentContainer>
        </S.Card>
      </S.ContentContainer>
    </PageContainer>
  );
};

export default TransferCustodySuccessPage;
