import { Button, Col, InputNumber, Table, Typography } from "antd";
import React from "react";
import ProductIdentifiersField from "../../../../components/Table/ProductIdentifiersField/ProductIdentifiersField";
import { ScanCart } from "../BatchMoveItemsView";
import * as S from "./styles";

interface Props {
  total: number;
  cart: ScanCart;
  onItemQuantityChange: (itemId: string, quantity: number) => void;
}

const QuantityTable: React.FC<Props> = ({
  cart,
  onItemQuantityChange,
  total,
}) => {
  const renderTableHeader = () => {
    return (
      <S.TableHeader>
        <Col>
          <Typography.Text strong type="secondary">
            Scanned Items
          </Typography.Text>
        </Col>
        <Col>
          <Typography.Text strong type="secondary">
            Total Item Count:{" "}
          </Typography.Text>
          <Typography.Text strong>{total}</Typography.Text>
        </Col>
      </S.TableHeader>
    );
  };

  const renderQuantityCell = ({ item, type }) => {
    return (
      <Col span={5}>
        <InputNumber
          disabled={type !== "product"}
          inputMode="numeric"
          value={cart[item.id]?.quantity || 0}
          onChange={(v: number) => {
            onItemQuantityChange(item.id, v);
          }}
          onFocus={(e) => e.target.select()}
        />
      </Col>
    );
  };

  const renderItemActions = ({ item }) => {
    return (
      <Button
        type="link"
        danger
        onClick={() => onItemQuantityChange(item.id, 0)}
      >
        Remove
      </Button>
    );
  };
  const renderItemDetail = ({ item }) => {
    return (
      <ProductIdentifiersField
        sku={item?.sku}
        upc={item?.upc}
        name={item?.name}
        code={item?.code}
        customer={item?.customer_name}
        productId={item?.id}
      />
    );
  };

  const renderOrderNumber = ({ item }) => {
    return item?.order_number || "-";
  };

  const getTableColumns = () => {
    const hasManualItems = !!Object.values(cart).find(
      (i: any) => i?.item?.code
    );

    const columns = [
      {
        key: "id",
        title: "Item",
        width: 300,
        render: renderItemDetail,
      },
      {
        key: "quantity",
        title: "Quantity",
        width: 120,
        render: renderQuantityCell,
      },
      {
        title: "Action",
        width: 120,
        render: renderItemActions,
      },
    ];

    if (hasManualItems) {
      columns.splice(2, 0, {
        key: "order_number",
        title: "Order Number",
        width: 200,
        render: renderOrderNumber,
      });
    }

    return columns;
  };

  return (
    <Table
      size="small"
      title={renderTableHeader}
      scroll={{ x: "max-content" }}
      bordered
      pagination={false}
      columns={getTableColumns()}
      dataSource={Object.values(cart)}
    />
  );
};

export default QuantityTable;
