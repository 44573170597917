import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Space,
  Table,
  TablePaginationConfig,
} from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import { FixedType } from "rc-table/lib/interface";

import ManualItemsDetailsNavigationLink from "../../../components/Table/ManualItemsDetailsNavigationLink";
import PageContainer from "../../../components/PageContainer";
import ItemCountFooter from "../../../components/Table/ItemCountFooter";
import NullSafeDate from "../../../components/Table/NullSafeDate";
import NullSafeText from "../../../components/Table/NullSafeText";
import WithLabel from "../../../components/WithLabel";

import { MANUAL_ITEM_LABEL, MANUAL_ITEM_TYPE_OPTIONS } from "../helpers";
import { fetchManualItems } from "../../../api/warehouse";
import useUrlState from "@ahooksjs/use-url-state";
import { buildTablePagination } from "../../../components/Pagination/helper";

type ManualItem = Warehouse.ManualItem;
type LocationItem = Warehouse.LocationItem;

const ManualItemIndexPage: React.FC = () => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [{ page, perPage, q, type }, setUrlState] = useUrlState(
    {
      page: 1,
      perPage: 10,
      q: "",
      type: undefined,
    },
    { navigateMode: "replace" }
  );

  const paginatedItems = useQuery(
    ["fetchManualItems", q, page, perPage, type],
    () => {
      return fetchManualItems({
        limit: perPage,
        q,
        type,
        page,
      });
    },
    { onError: (error: string) => setError(error) }
  );

  const getManualItems = useCallback((): ManualItem[] => {
    return paginatedItems.data?.items || [];
  }, [paginatedItems]);

  const renderManualItemsDetailsLink = (item: ManualItem) => {
    return <ManualItemsDetailsNavigationLink code={item?.code} />;
  };
  const renderText = (value?: string) => <NullSafeText value={value} />;
  const renderDate = (date: string) => <NullSafeDate date={date} />;

  const renderLocationLink = (locationItems: LocationItem[]) => {
    if (!locationItems || locationItems.length == 0) return "-";

    const size = locationItems.length - 1;
    const code = locationItems[0].location_code;

    return (
      <>
        <Link to={`/locations/${code}`}>{code}</Link>
        {size > 0 && <span>{` +${size}`}</span>}
      </>
    );
  };

  const renderTableColumns = () => {
    return [
      {
        title: "Code",
        width: 100,
        render: renderManualItemsDetailsLink,
        fixed: "left" as FixedType,
      },
      {
        title: "Location",
        dataIndex: "location_items",
        width: 100,
        render: renderLocationLink,
      },
      {
        title: "Organization",
        dataIndex: ["organization", "name"],
        width: 100,
        render: renderText,
      },
      {
        title: "Customer",
        dataIndex: "customer_name",
        width: 100,
        render: renderText,
      },
      {
        title: "Order #",
        dataIndex: "order_number",
        width: 100,
        render: renderText,
      },
      {
        title: "Notes",
        dataIndex: "note",
        width: 120,
        render: renderText,
      },
      {
        title: "Type",
        dataIndex: "type",
        width: 120,
        render: (type: string) => MANUAL_ITEM_LABEL[type],
      },
      {
        title: "Date Created",
        dataIndex: "created_at",
        width: 130,
        render: renderDate,
      },
    ];
  };

  const getPaginationConfig = (): TablePaginationConfig => {
    const paginationInfo = paginatedItems.data?.meta;
    return buildTablePagination({
      paginationInfo,
      onChange: (p: number, newPageSize?: number) => {
        if (newPageSize && newPageSize !== +perPage) {
          setUrlState({ page: 1, perPage: newPageSize });
        } else {
          setUrlState({ page: p });
        }
      },
      showQuickJumper: true,
      showSizeChanger: true,
    });
  };

  const onSearch = (value: string) => {
    setUrlState({ q: value.trim(), page: 1 });
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.value.trim()) setUrlState({ q: "", page: 1 });
  };

  const renderSearchInput = () => {
    return (
      <WithLabel name="Code, Order, Organization, Customer">
        <Input.Search
          size="large"
          allowClear
          defaultValue={q}
          loading={paginatedItems.isLoading}
          placeholder="Search code, order #, organization, customer"
          autoCapitalize="off"
          onSearch={onSearch}
          onChange={onChange}
        />
      </WithLabel>
    );
  };

  const itemTypeOptions = MANUAL_ITEM_TYPE_OPTIONS.map((e) => {
    return (
      <Select.Option key={e?.key} value={e?.value}>
        {e?.label}
      </Select.Option>
    );
  });

  const onItemTypeChange = (value) => {
    if (value.trim()) {
      setUrlState({ type: value.trim(), page: 1 });
    } else {
      setUrlState({ type: undefined, page: 1 });
    }
  };

  const renderTypeFilter = () => {
    return (
      <WithLabel name="Item Type">
        <Select
          size="large"
          placeholder="Item Type"
          defaultValue={""}
          onChange={onItemTypeChange}
        >
          <Select.Option key={0} value={""}>
            All
          </Select.Option>
          {itemTypeOptions}
        </Select>
      </WithLabel>
    );
  };

  const renderCreateButton = () => {
    return (
      <Button
        size="large"
        type="primary"
        block
        icon={<PlusOutlined />}
        onClick={() => history.push("/manual-items/create")}
      >
        Create
      </Button>
    );
  };

  const renderCSVImportButton = () => {
    return (
      <Button
        size="large"
        icon={<UploadOutlined />}
        block
        onClick={() => history.push("/manual-items/csv-upload")}
      >
        Import CSV
      </Button>
    );
  };

  const renderSearchGroup = () => {
    return (
      <Row justify="start" gutter={[8, 8]}>
        <Col xs={24} lg={8}>
          {renderTypeFilter()}
        </Col>
        <Col xs={24} lg={14}>
          {renderSearchInput()}
        </Col>
      </Row>
    );
  };

  const renderButtonGroup = () => {
    return (
      <Row justify="end" gutter={[8, 16]}>
        <Col xs={24} lg={8} xl={7}>
          {renderCSVImportButton()}
        </Col>
        <Col xs={24} lg={7} xl={6}>
          {renderCreateButton()}
        </Col>
      </Row>
    );
  };

  const renderToolbar = () => {
    return (
      <Row justify="space-between" align="bottom" gutter={[8, 16]}>
        <Col xs={24} lg={12}>
          {renderSearchGroup()}
        </Col>
        <Col xs={24} lg={12}>
          {renderButtonGroup()}
        </Col>
      </Row>
    );
  };

  const renderTableFooter = () => (
    <ItemCountFooter count={paginatedItems.data?.meta.totalItems || 0} />
  );

  return (
    <PageContainer title="Manual Items" withHeader withFooter withPadding>
      <Space size="large" direction="vertical" style={{ width: "100%" }}>
        {renderToolbar()}
        <ErrorAlert error={error} />
        <Table
          size="small"
          scroll={{ x: "max-content" }}
          footer={renderTableFooter}
          rowKey="id"
          bordered
          loading={paginatedItems.isLoading}
          columns={renderTableColumns()}
          pagination={getPaginationConfig()}
          dataSource={getManualItems()}
        />
      </Space>
    </PageContainer>
  );
};

export default ManualItemIndexPage;
