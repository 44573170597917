import { DeleteOutlined, DownOutlined, PlusOutlined } from "@ant-design/icons";
// Components
import {
  Button,
  Dropdown,
  InputNumber,
  Menu,
  notification,
  Popconfirm,
  Row,
} from "antd";
import React, { useEffect } from "react";
// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentPackageItemIndexState,
  PackageContainer,
  PackageType,
  packingFlowPackageContainerState,
  packingFlowPackageOrderItemIDsState,
  packingFlowPackageTypeState,
  packingFlowPackageWeightState,
  shipmentSuppliesState,
} from "../../../../../recoil/packingFlow/atoms";
import {
  useEditPackage,
  useUpdatePackageItems,
} from "../../../../../recoil/packingFlow/helpers";
import PackageContainerDisplayOrCustomInput from "./PackageContainerDisplayOrCustomInput";
import DimensionInputs from "./DimensionInputs/BoxSelector";
import {
  useCalculatePackageInsurance,
  useCalculatePackageWeight,
} from "./helpers";
import PalletTypeModal from "./PalletTypeModal";
// Styles
import * as S from "./styles";

const AddPackageItem: React.FC = () => {
  const packageContainers = useRecoilValue(packingFlowPackageContainerState);
  const packageOrderItemIDs = useRecoilValue(
    packingFlowPackageOrderItemIDsState
  );
  const [currentPackageItemIndex, setCurrentPackageItemIndex] = useRecoilState(
    currentPackageItemIndexState
  );
  const shipmentSupplies = useRecoilValue(shipmentSuppliesState);

  const packageType = useRecoilValue(packingFlowPackageTypeState);
  const {
    replaceCurrentPackageWeightWith,
    replaceCurrentPackageInsuranceWith,
    addPackage,
    deletePackage,
  } = useEditPackage();
  const { calculateWeight } = useCalculatePackageWeight();
  const { calculateInsurance } = useCalculatePackageInsurance();
  const [packageWeights] = useRecoilState(packingFlowPackageWeightState);
  const { calculateUnpackedTableItems } = useUpdatePackageItems();
  const toBeAddedItems = calculateUnpackedTableItems();
  const currentPackageOrderItemIDs =
    packageOrderItemIDs[currentPackageItemIndex];
  const currentPackageContainer = packageContainers[currentPackageItemIndex];
  const currentPackageSupplies = shipmentSupplies[currentPackageItemIndex];
  const currentPackageWeight = packageWeights[currentPackageItemIndex];
  const isBox = packageType === PackageType.box;

  useEffect(() => {
    replaceCurrentPackageInsuranceWith({
      insuranceCurrency: "CAD",
      insuranceValue: +calculateInsurance(),
    });

    if (!currentPackageWeight.manuallyEdited) {
      const packageWeight = calculateWeight();
      replaceCurrentPackageWeightWith({
        ...currentPackageWeight,
        weight: +packageWeight,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPackageOrderItemIDs,
    currentPackageContainer,
    currentPackageSupplies,
  ]);

  const handleAddPackage = () => {
    if (
      (isBox && packageContainers.length < 35) ||
      (!isBox && packageContainers.length < 6)
    ) {
      addPackage();
    } else {
      notification.error({
        message: "Error",
        description: `Max ${isBox ? "box" : "pallet"} limit reached`,
      });
    }
  };

  const handleDeletePackage = () => {
    deletePackage();
  };

  const renderPackageDropdown = () => {
    const menu = (
      <Menu>
        {packageContainers.map((_: PackageContainer, index: number) => (
          <Menu.Item
            key={index}
            onClick={() => {
              setCurrentPackageItemIndex(index);
            }}
          >
            <S.MenuItemText bold={index === currentPackageItemIndex}>
              {`${isBox ? "Package" : "Pallet"} ${index + 1}`}
            </S.MenuItemText>
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <S.DropdownWrapper>
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          overlayStyle={{ maxHeight: "300px", overflow: "auto" }}
        >
          <S.DropdownSelectedText>
            {`${isBox ? "Package" : "Pallet"} ${currentPackageItemIndex + 1} `}
            <DownOutlined />
          </S.DropdownSelectedText>
        </Dropdown>
      </S.DropdownWrapper>
    );
  };

  const renderChangePalletTypeButton = () => {
    if (
      packageType === PackageType.standard_pallet ||
      packageType === PackageType.double_pallet
    ) {
      return (
        <S.Column flex={1} justifyContent="flex-end">
          <PalletTypeModal />
        </S.Column>
      );
    }
    return null;
  };

  const renderDimension = () => {
    return <DimensionInputs isBox={isBox} />;
  };

  return (
    <S.Container>
      <S.Row key={currentPackageItemIndex + "package-dimensions"}>
        <S.Column flex={1}>
          {renderPackageDropdown()}
          <Button
            type="primary"
            ghost
            disabled={!toBeAddedItems?.length}
            onClick={handleAddPackage}
            icon={<PlusOutlined />}
          />
        </S.Column>

        {renderChangePalletTypeButton()}

        <S.Column flex={1} justifyContent="flex-end">
          <Popconfirm
            disabled={packageContainers?.length === 1}
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={handleDeletePackage}
          >
            <Button
              danger
              disabled={packageContainers?.length === 1}
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </S.Column>
      </S.Row>

      <S.Row>
        <Row justify="space-between" align="bottom" style={{ width: "100%" }}>
          <div>
            {currentPackageContainer?.isCustomDimensions || !isBox ? (
              renderDimension()
            ) : (
              <PackageContainerDisplayOrCustomInput />
            )}
          </div>
          <S.InputWrapper>
            <S.LabelText>WEIGHT (LB)</S.LabelText>
            <InputNumber
              value={currentPackageWeight.weight}
              onChange={(value: string | number | undefined) => {
                const newWeight = {
                  weight: +(value as string) || 0,
                  manuallyEdited: true,
                };
                replaceCurrentPackageWeightWith(newWeight);
              }}
              onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                event.target.select();
              }}
              min={0}
            />
          </S.InputWrapper>
        </Row>
      </S.Row>
    </S.Container>
  );
};

export default AddPackageItem;
