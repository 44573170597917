import { FireOutlined, FlagOutlined, TagOutlined } from "@ant-design/icons";
import { Card, Col, Row, Skeleton, Statistic } from "antd";
import { isInteger } from "lodash";
import React from "react";

import { PicksheetStats } from "../../../api/warehouse/picksheet";
import { COLORS } from "../../../styles";

interface CardProps {
  value?: any;
  loading?: boolean;
}

const ReadyToFulfillCount: React.FC<CardProps> = ({ value, loading }) => (
  <Card>
    <Skeleton paragraph={{ rows: 1 }} loading={loading}>
      <Statistic
        title="Ready To Fulfill"
        value={value}
        prefix={<TagOutlined />}
        valueStyle={{ color: COLORS.BLUE }}
      />
    </Skeleton>
  </Card>
);

const ProcessingOrderCount: React.FC<CardProps> = ({ value, loading }) => (
  <Card>
    <Skeleton paragraph={{ rows: 1 }} loading={loading}>
      <Statistic
        title="Processing Orders"
        value={loading ? <Skeleton /> : value}
        prefix={<FireOutlined />}
        valueStyle={{ color: "orange" }}
      />
    </Skeleton>
  </Card>
);

const FulfilledShipmentCount: React.FC<CardProps> = ({ value, loading }) => (
  <Card>
    <Skeleton paragraph={{ rows: 1 }} loading={loading}>
      <Statistic
        title="Today's Fulfilled"
        value={loading ? <Skeleton /> : value}
        prefix={<FlagOutlined />}
        valueStyle={{ color: "green" }}
      />
    </Skeleton>{" "}
  </Card>
);

interface MetricDashboardProps {
  data?: PicksheetStats;
  loading?: boolean;
  noOrdersFound?: boolean;
}

const MetricDashboard: React.FC<MetricDashboardProps> = (props) => {
  const readyToFulfillCount = props.noOrdersFound
    ? 0
    : props?.data?.ready_to_fulfill_count;
  const processingItemsCount = props.noOrdersFound
    ? 0
    : props?.data?.processing_items_count;
  const shipmentsFulfilledToday = props.noOrdersFound
    ? 0
    : props?.data?.shipments_fulfilled_today;

  return (
    <div>
      <Row gutter={[8, 16]}>
        <Col xs={24} lg={8}>
          <ReadyToFulfillCount
            value={isInteger(readyToFulfillCount) ? readyToFulfillCount : "N/A"}
            loading={props.loading}
          />
        </Col>
        <Col xs={24} lg={8}>
          <ProcessingOrderCount
            value={
              isInteger(processingItemsCount) ? processingItemsCount : "N/A"
            }
            loading={props.loading}
          />
        </Col>
        <Col xs={24} lg={8}>
          <FulfilledShipmentCount
            value={
              isInteger(shipmentsFulfilledToday)
                ? shipmentsFulfilledToday
                : "N/A"
            }
            loading={props.loading}
          />
        </Col>
      </Row>
    </div>
  );
};

export default MetricDashboard;
