import { Fulfillment } from "@secondcloset/types";
import { CreateReturnBodyItems } from "../../../../api/warehouse";

type Shipment = Fulfillment.Shipment;
type ShipmentItem = Fulfillment.ShipmentItem;
type Order = Fulfillment.Order;
type ExternalCarrierShippingMethod = Fulfillment.ExternalCarrierShippingMethod;
type ExternalCarrierShippingLabel = Fulfillment.ExternalCarrierShippingLabel;

export const findShipmentByTrackingNumber = (
  shipments: Shipment[],
  tracking: string
): { shipment?: Shipment; label: ExternalCarrierShippingLabel } => {
  let foundLabel;
  const shipment = shipments?.find((eachShipment: Shipment) => {
    const shippingMethod =
      eachShipment?.shipping_method as ExternalCarrierShippingMethod;
    const labels = shippingMethod?.shipping_labels;
    const matchedLabel = labels?.find((eachLabel) => {
      return eachLabel?.tracking_number === tracking;
    });
    if (matchedLabel) {
      foundLabel = matchedLabel;
    }
    return matchedLabel;
  });
  return { shipment, label: foundLabel };
};

export const checkCompletedShipmentItems = (foundShipment): ShipmentItem[] => {
  return foundShipment?.shipment_items?.filter(
    (shipment) => shipment?.shipment_actions_status?.delivery?.completed
  );
};

export const checkIsReverseLogistics = (foundShipment): boolean => {
  return !!foundShipment?.shipment_items?.find(
    (shipment) =>
      shipment?.shipment_actions_status?.reverse_logistics?.completed
  );
};

export const findShipmentItemsByOrderId = (
  foundShipment: Shipment,
  order?: Order
): CreateReturnBodyItems[] => {
  const orderItems = order?.items || [];
  const orderItemProductIdMap = orderItems.reduce((acc, cv) => {
    const orderItemID = cv?.id || "";
    const productID = cv?.product?.id || "";
    if (orderItemID && productID) acc[orderItemID] = productID;
    return acc;
  }, {});

  const completedShipmentItems = checkCompletedShipmentItems(foundShipment);

  return completedShipmentItems?.map((shipmentItem) => {
    const orderItemID = shipmentItem?.order_item_id || "";
    return {
      name: shipmentItem?.description,
      external_product_id: orderItemProductIdMap[orderItemID] || "",
      [shipmentItem?.item_identifier_type]: shipmentItem?.item_identifier_value,
    };
  });
};
