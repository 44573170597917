import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";

import { notifyNewVersion } from "./lib/notifyNewVersion";
import sentryInit, { ErrorBoundary } from "./lib/sentry";
import * as serviceWorker from "./serviceWorker";
import App from "./App";

import "./styles/normalize.css";
import "antd/dist/antd.less";

// initializing sentry before react
sentryInit();

const queryClient = new QueryClient();

ReactDOM.render(
  <CookiesProvider>
    <RecoilRoot>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </ErrorBoundary>
    </RecoilRoot>
  </CookiesProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA & https://tinyurl.com/y254qn9b
serviceWorker.register({
  onUpdate: notifyNewVersion,
});
