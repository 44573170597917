import styled from "styled-components";

export const Container = styled.div``;

export const ToggleWrap = styled.div`
  grid-column: 1;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 5px;
`;

export const ToggleText = styled.div`
  margin-right: 10px;
`;
