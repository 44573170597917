import { useRecoilValue, useResetRecoilState } from "recoil";
import { userPermissionsState, userState } from "./atoms";

export const useResetUser = () => {
  const resetUserState = useResetRecoilState(userState);
  const resetUserPermissionsState = useResetRecoilState(userPermissionsState);

  const resetUser = () => {
    resetUserState();
    resetUserPermissionsState();
  };
  return resetUser;
};

export const useValidateSpecialProjectPermissions = () => {
  const specialProjectsRead = "read_november_special_projects";
  const specialProjectsWrite = "write_november_special_projects";
  const userPermissions = useRecoilValue(userPermissionsState);
  const canReadSpecialProjects = () => {
    const hasPermission = userPermissions?.find(
      (p) => p === specialProjectsRead
    );
    return hasPermission !== undefined;
  };

  const canWriteSpecialProjects = () => {
    const hasPermission = userPermissions?.find(
      (p) => p === specialProjectsWrite
    );
    return hasPermission !== undefined;
  };
  return { canWriteSpecialProjects, canReadSpecialProjects };
};
