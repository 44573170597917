import { Fulfillment, Warehouse } from "@secondcloset/types";
type ShipmentType = Fulfillment.Shipment;
type SecondClosetShippingMethod = Fulfillment.SecondClosetShippingMethod;
type ExternalCarrierShippingMethod = Fulfillment.ExternalCarrierShippingMethod;
type DimensionUnitType = Warehouse.DimensionUnitType;
type WeightUnitType = Warehouse.WeightUnitType;

export default class Shipment {
  static hasMatchingShipmentItems = (
    s1: ShipmentType,
    s2: ShipmentType
  ): boolean => {
    const getShipmentItemIDs = (s: ShipmentType): string[] => {
      const shipmentItems = s?.shipment_items || [];
      return shipmentItems.map((i) => i.id);
    };
    const s1ItemIDs = getShipmentItemIDs(s1);
    const s2ItemIDs = getShipmentItemIDs(s2);
    const isSameLength = s1ItemIDs.length === s2ItemIDs.length;
    const isSameIDs = s1ItemIDs.every((id) => s2ItemIDs.includes(id));
    return isSameLength && isSameIDs;
  };

  static hasMatchingOrderID = (
    s1: ShipmentType | null,
    s2: ShipmentType | null
  ): boolean => {
    const getOrderID = (s?: ShipmentType | null): string => {
      return s?.external_order_id || "";
    };
    const s1OrderID = getOrderID(s1);
    const s2OrderID = getOrderID(s2);
    return s1OrderID === s2OrderID;
  };

  static isCustomerBooking = (shipment: ShipmentType): boolean => {
    const isAppointment = shipment?.shipping_method_type === "appointment";

    if (isAppointment) {
      const shippingMethod =
        shipment.shipping_method as SecondClosetShippingMethod;
      const isCancelled = shipment?.shipping_method?.status === "cancelled";
      const shippingDate = shippingMethod?.date;
      return !shippingDate && !isCancelled;
    }

    return false;
  };

  static getShippingLabelURLs = (shipment: ShipmentType): string[] => {
    const shipmentMethod =
      shipment.shipping_method as ExternalCarrierShippingMethod;
    return shipmentMethod?.shipping_labels
      ?.map((sl) => sl.label_download || "")
      ?.reduce<string[]>((acc, url: string) => {
        if (url && url.length > 0) acc.push(url);
        return acc;
      }, []);
  };

  static isShipmentDone = (shipment: ShipmentType): boolean => {
    const packagedItems = shipment?.packages.flatMap(
      (i) => i.shipment_item_ids
    );
    const isAllItemspacked = shipment?.shipment_items.every((item) =>
      packagedItems?.includes(item.id)
    );
    const isStatusCompleted = shipment.shipping_method.status === "completed";

    // untracked shipments can be marked as completed but has no package
    return isAllItemspacked || isStatusCompleted;
  };

  static isShipmentCancelled = (shipment: ShipmentType): boolean =>
    shipment.shipping_method.status === "cancelled" ||
    shipment.shipping_method.status === "shipment_cancelled";

  static convertWeightToPounds = (
    value: number | undefined,
    unit: WeightUnitType | undefined
  ): number => {
    if (!value) return 0;
    switch (unit) {
      case "kg":
        return value * 2.2046;
      case "oz":
        return value * 0.0625;
      case "g":
        return value * 0.0022046;
      default:
        return value;
    }
  };

  static convertLengthToInches = (
    value: number | undefined,
    unit: DimensionUnitType | undefined
  ): number => {
    if (!value) return 0;
    switch (unit) {
      case "ft":
        return value * 12;
      case "cm":
        return value * 0.393701;
      case "m":
        return value * 39.3701;
      default:
        return value;
    }
  };
}
