import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { Button, Col, Input, Row, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";

// api
import { fetchLocationTemplates } from "../../../api/warehouse";

// components
import PageContainer from "../../../components/PageContainer";
import LocationTemplatesTable from "./LocationTemplatesTable";

// hooks
import useUrlState from "@ahooksjs/use-url-state";
type LocationTemplate = Warehouse.LocationTemplate;

const LocationTemplateIndexPage: React.FC = () => {
  const [{ search, page, perPage }, setUrlState] = useUrlState(
    {
      search: undefined,
      page: 1,
      perPage: 10,
    },
    { navigateMode: "replace" }
  );
  const history = useHistory();
  const [error, setError] = useState("");

  const paginatedLocationTemplates = useQuery(
    ["fetchLocationTemplates", search, page, perPage],
    () => {
      setError("");
      return fetchLocationTemplates({
        search,
        page,
        limit: perPage,
      });
    },
    {
      onError: (e: string) => setError(e),
    }
  );

  const getLocationTemplates = useCallback((): LocationTemplate[] => {
    return paginatedLocationTemplates.data?.items || [];
  }, [paginatedLocationTemplates]);

  const renderTemplateNameSearchBar = () => {
    return (
      <Input.Search
        size="large"
        allowClear
        placeholder="Search for template name"
        defaultValue={search}
        onSearch={(s) => setUrlState({ search: s, page: 1 })}
        autoCapitalize="off"
      />
    );
  };

  const renderHeaderButtons = () => {
    return (
      <Button
        size="large"
        type="primary"
        icon={<PlusOutlined />}
        block
        onClick={() => history.push("/location-templates/create")}
      >
        Create
      </Button>
    );
  };

  const renderToolbar = () => {
    return (
      <Row justify="space-between" gutter={[8, 16]}>
        <Col xs={24} lg={8}>
          {renderTemplateNameSearchBar()}
        </Col>
        <Col xs={24} lg={3}>
          {renderHeaderButtons()}
        </Col>
      </Row>
    );
  };

  return (
    <PageContainer title="Location Templates" withPadding>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        {renderToolbar()}
        <ErrorAlert error={error} />
        <LocationTemplatesTable
          locationTemplates={getLocationTemplates()}
          paginationInfo={paginatedLocationTemplates.data?.meta}
          loading={paginatedLocationTemplates.isLoading}
          onPageChange={(p: number, newPageSize?: number) => {
            if (newPageSize && newPageSize !== +perPage) {
              setUrlState({ page: 1, perPage: newPageSize });
            } else {
              setUrlState({ page: p });
            }
          }}
        />
      </Space>
    </PageContainer>
  );
};

export default LocationTemplateIndexPage;
