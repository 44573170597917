import styled from "styled-components";
import { COLORS } from "../../../../../styles";

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

export const NumberCircle = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLORS.BLUE};
  color: white;
  border-radius: 100%;
`;

export const Title = styled.div`
  margin-left: 10px;
  font-weight: 600;
  font-size: 18px;
`;
