import styled from "styled-components";
import { COLORS, LAYOUT } from "../../styles";
import { Form, Button, Typography } from "antd";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  h1 {
    margin-top: 20px;
    font-weight: 400;
  }
`;

export const AppName = styled.h1`
  color: ${COLORS.BLUE};
  display: flex;
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
  align-items: center;
  line-height: 1.5;
  justify-content: center;
`;

export const FormContainer = styled(Form)`
  max-width: 350px;
  width: 100%;
`;

export const LoginButton = styled(Button)`
  width: 100%;
`;

export const LoginForm = styled.div`
  margin: auto;
  display: flex;
  max-width: 350px;
  align-items: center;
  flex-direction: column;
  justfy-content: center;
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    width: 100vw;
    height: 100vh;
  }
`;
