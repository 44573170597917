import { Descriptions } from "antd";
import React from "react";
import { Fulfillment } from "@secondcloset/types";

interface Props {
  order?: Fulfillment.Order;
}
const SelectedOrderInfo: React.FC<Props> = ({ order }) => {
  if (!order) return null;
  return (
    <Descriptions bordered size="small">
      <Descriptions.Item label="Organization" span={3}>
        {order?.organization?.name}
      </Descriptions.Item>
      <Descriptions.Item label="Internal Order ID" span={3}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_SIERRA_DOMAIN}/fulfillment/orders/${order?.id}`}
        >
          {order?.order_id || "-"}
        </a>
      </Descriptions.Item>
      <Descriptions.Item label="External Order #" span={3}>
        {order?.external_order_number}
      </Descriptions.Item>
      <Descriptions.Item label="End Customer" span={3}>
        {order?.customer?.name}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default SelectedOrderInfo;
