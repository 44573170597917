import useSound from "use-sound";
import failedSound from "../assets/failed.mp3";
import strongYeaSound from "../assets/strongYea.mp3";
import successSound from "../assets/success.mp3";
import yeaSound from "../assets/yea.mp3";

export const useSoundEffect = () => {
  const [playYeaSound] = useSound(yeaSound);
  const [playStrongYeaSound] = useSound(strongYeaSound);
  const [playSuccessSound] = useSound(successSound);
  const [playFailedSound] = useSound(failedSound);

  return {
    playYeaSound,
    playStrongYeaSound,
    playSuccessSound,
    playFailedSound,
  };
};
