import useUrlState from "@ahooksjs/use-url-state";
import { FacilityCode } from "@secondcloset/fulfillment-utils";
import { PlusOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import { Button, Col, Input, Row, Space, Switch, Table, Tag } from "antd";
import { FixedType } from "rc-table/lib/interface";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";

// API
import { fetchCarrierTransferPallets } from "../../api/warehouse/carrierTransferPallets";

// Hooks and Helpers
import useFacility from "../../hooks/useFacility";
import { getHandedOverTagColor, getHandedOverTagText } from "./helper";

// Components
import FacilityDropdown from "../../components/FacilityDropdown";
import PageContainer from "../../components/PageContainer";
import { buildTablePagination } from "../../components/Pagination/helper";
import ItemCountFooter from "../../components/Table/ItemCountFooter";
import NullSafeDate from "../../components/Table/NullSafeDate";
import NullSafeLink from "../../components/Table/NullSafeLink";
import NullSafeText from "../../components/Table/NullSafeText";
import WithLabel from "../../components/WithLabel";

type CarrierTransferPallet = Warehouse.CarrierTransferPallet;

const CarrierTransferPalletPage = () => {
  const history = useHistory();
  const cachedFacility = useFacility().facility;
  const [{ page, perPage, facility, showHandedOver, q }, setUrlState] =
    useUrlState(
      {
        q: undefined,
        page: 1,
        perPage: 10,
        facility: cachedFacility,
        showHandedOver: "false",
      },
      { navigateMode: "replace" }
    );
  const [error, setError] = useState("");
  const { setFacility } = useFacility(setUrlState, setError);

  const { data: pallets, isLoading } = useQuery(
    ["pallets", q, page, perPage, showHandedOver, facility],
    () => {
      setError("");
      return fetchCarrierTransferPallets({
        q,
        page,
        limit: perPage,
        handed_over: showHandedOver === "true" ? undefined : false,
        facility: cachedFacility as FacilityCode,
      });
    },
    { retry: false, onError: (e: string) => setError(e) }
  );

  const renderHandedOverTag = (pallet: CarrierTransferPallet) => {
    return (
      <Tag color={getHandedOverTagColor(pallet)}>
        {getHandedOverTagText(pallet)}
      </Tag>
    );
  };

  const tableColumns = [
    {
      title: "Pallet ID",
      render: (id: string) => (
        <NullSafeLink href={`/carrier-transfer-pallets/${id}`} value={id} />
      ),
      dataIndex: "id",
      fixed: "left" as FixedType,
    },
    {
      title: "Status",
      width: 200,
      render: (_: any, record: CarrierTransferPallet) =>
        renderHandedOverTag(record),
    },
    {
      title: "Facility",
      width: 150,
      render: (facility: string) => <NullSafeText value={facility} />,
      dataIndex: "facility",
    },
    {
      title: "Created At",
      width: 200,
      render: (date: string) => <NullSafeDate date={date} />,
      dataIndex: "created_at",
    },
  ];

  const buildPagination = () => {
    const paginationConfig = {
      paginationInfo: pallets?.meta,
      showQuickJumper: true,
      showSizeChanger: true,
      onChange: (p: number, newPageSize?: number) => {
        if (newPageSize && newPageSize !== +perPage) {
          setUrlState({ page: 1, perPage: newPageSize });
        } else {
          setUrlState({ page: p });
        }
      },
    };

    return buildTablePagination(paginationConfig);
  };

  const renderPalletIDSearchBar = () => (
    <WithLabel name="Pallet ID">
      <Input.Search
        size="large"
        loading={isLoading}
        allowClear
        enterButton
        defaultValue={q || ""}
        placeholder="e.g. CTP-Q5YSKZBM"
        autoCapitalize="off"
        onSearch={(value: string) => {
          setUrlState({ q: value?.trim() });
        }}
      />
    </WithLabel>
  );

  const renderRequestPalletIDButton = () => (
    <Button
      size="large"
      type="primary"
      icon={<PlusOutlined />}
      onClick={() =>
        history.push({
          pathname: "/request-pallet-ids",
          search:
            "?" +
            new URLSearchParams({
              source: "carrier_transfer_pallet",
            }).toString(),
        })
      }
      block
    >
      Request Pallet IDs
    </Button>
  );

  const renderTableFooter = () => (
    <ItemCountFooter count={pallets?.meta?.totalItems || 0} />
  );

  const renderToolbar = () => (
    <Row justify="space-between" align="bottom" gutter={[8, 16]}>
      <Col xs={24} lg={16}>
        <Space align="end">
          {renderPalletIDSearchBar()}
          <FacilityDropdown
            selectedFacility={facility}
            onSelect={setFacility}
          />
        </Space>
      </Col>
      <Col xs={24} lg={5} xl={4}>
        {renderRequestPalletIDButton()}
      </Col>
    </Row>
  );

  const renderShowHandedOverToggle = () => {
    return (
      <Space>
        <div> Show Handed Over</div>
        <Switch
          checked={showHandedOver === "true"}
          onChange={(checked) =>
            setUrlState({
              page: "1",
              q: "",
              showHandedOver: checked ? "true" : "false",
            })
          }
        />
      </Space>
    );
  };

  return (
    <PageContainer withPadding title="Carrier Transfer Pallets">
      <Space size="large" direction="vertical" style={{ width: "100%" }}>
        {renderToolbar()}
        <ErrorAlert error={error} />
        {renderShowHandedOverToggle()}
        <Table
          size="small"
          scroll={{ x: "max-content" }}
          rowKey="id"
          footer={renderTableFooter}
          loading={isLoading}
          columns={tableColumns}
          bordered
          pagination={buildPagination()}
          dataSource={pallets?.items as CarrierTransferPallet[] | undefined}
        />
      </Space>
    </PageContainer>
  );
};

export default CarrierTransferPalletPage;
