import styled, { css } from "styled-components";

import { COLORS } from "../../../styles";

import { Props } from "./SquareButton";

export const Button = styled.div<Partial<Props>>`
  color: ${(props) => (props.disabled ? COLORS.GREY : COLORS.CHARCOAL)};
  width: 100%;
  height: 7.25rem;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 1rem;
  background: ${COLORS.GREY_LIGHT};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        cursor: pointer;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      }
    `}

  @media(min-width: 577px) {
    width: 8.25rem;
    height: 8.25rem;
    margin-bottom: 3rem;
  }
  @media (min-width: 769px) {
    width: 8.75rem;
    height: 8.75rem;
    margin-bottom: 3rem;
  }
  @media (min-width: 993px) {
    width: 9.75rem;
    height: 9.75rem;
    margin-bottom: 1rem;
  }
`;

export const ButtonText = styled.div`
  font-size: 15px;
  text-align: center;
  font-weight: 600;
`;
