import { Warehouse } from "@secondcloset/types";
import { Tag } from "antd";
import { startCase, toLower } from "lodash-es";
import React from "react";
import { statusTagColorConfig } from "../helpers";

type ReturnStatus = Warehouse.ReturnStatus;

interface Props {
  status?: string;
}

const StatusTag: React.FC<Props> = ({ status }) => {
  if (!status) return <span>-</span>;
  return (
    <Tag
      style={{ borderRadius: 25 }}
      color={statusTagColorConfig?.[status as ReturnStatus] || "processing"}
    >
      {startCase(toLower(status))}
    </Tag>
  );
};

export default StatusTag;
