import React, { useState } from "react";
import { useHistory } from "react-router";
import { useMutation } from "react-query";
import { startCase } from "lodash-es";
import {
  Input,
  InputNumber,
  Select,
  Button,
  Form,
  Row,
  Col,
  Card,
  PageHeader,
  Space,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";

// components
import PageContainer from "../../../components/PageContainer";
import OrganizationSearchBar from "./OrganizationSearchBar";

// api + interface
import { createSupply } from "../../../api/warehouse/supplies";
import { supplyTypes, dimensionUnitTypes, weightUnitTypes } from "./helpers";
import { formatSupplyCodeForm } from "../SupplyDetailsPage/SupplyDetailsTable/helpers";

type Organization = Warehouse.Organization;

const { Option } = Select;

export interface SelectedOrganization {
  id: string;
  name: string;
}

const CreateSupplyPage: React.FC = () => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [organization, setOrganization] = useState<Organization>();
  const [form] = Form.useForm();

  const { mutate: create, isLoading: creating } = useMutation(createSupply, {
    onSuccess: () => history.push(`/supplies`),
    onError: setError,
  });

  const renderOptions = (list: readonly string[]) => {
    return list.map((item) => (
      <Option key={item} value={item}>
        {item}
      </Option>
    ));
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }

    const body = formatSupplyCodeForm(form.getFieldsValue());
    body.organization_id = organization?.id;
    create(body);
  };

  const getRules = (name: string) => {
    return [
      {
        required: true,
        message: `${name} is required`,
      },
    ];
  };

  const renderOrganization = () => {
    return (
      <Form.Item label="Organization" required>
        <OrganizationSearchBar
          organization={organization}
          setOrganization={setOrganization}
          onError={(error: string) => setError(error)}
        />
      </Form.Item>
    );
  };

  const renderName = () => {
    return (
      <Form.Item name="name" label="Name" rules={getRules("Name")}>
        <Input size="large" placeholder="name" allowClear />
      </Form.Item>
    );
  };

  const renderType = () => {
    return (
      <Form.Item name="type" label="Type" rules={getRules("Type")}>
        <Select size="large">{renderOptions(supplyTypes)}</Select>
      </Form.Item>
    );
  };

  const renderCode = () => {
    return (
      <Form.Item
        name="code"
        label="Scannable Code"
        rules={[{ whitespace: true }]}
      >
        <Input
          size="large"
          allowClear
          placeholder="Scan code"
          autoCapitalize="off"
        />
      </Form.Item>
    );
  };
  const renderInputNumber = (name: string, requiredIfBox: boolean) => {
    return (
      <Form.Item
        name={name}
        label={startCase(name)}
        dependencies={["type"]}
        rules={[
          ({ getFieldValue }) => ({
            required: requiredIfBox && getFieldValue("type") === "BOX",
            message: `${startCase(name)} is required`,
          }),
        ]}
      >
        <InputNumber size="large" placeholder={name} min={0} />
      </Form.Item>
    );
  };

  const renderDimensionUnit = () => {
    return (
      <Form.Item
        name="dimension_unit"
        label="Dimension Unit"
        dependencies={["length", "width", "height"]}
        rules={[
          ({ getFieldValue }) => ({
            required:
              getFieldValue("length") ||
              getFieldValue("width") ||
              getFieldValue("height"),
            message: "Dimension unit is required",
          }),
        ]}
      >
        <Select size="large">{renderOptions(dimensionUnitTypes)}</Select>
      </Form.Item>
    );
  };

  const renderWeightUnit = () => {
    return (
      <Form.Item
        name="weight_unit"
        label="Weight Unit"
        dependencies={["weight"]}
        rules={[
          ({ getFieldValue }) => ({
            required: getFieldValue("weight"),
            message: "Weight unit is required",
          }),
        ]}
      >
        <Select size="large">{renderOptions(weightUnitTypes)}</Select>
      </Form.Item>
    );
  };

  return (
    <PageContainer withPadding>
      <PageHeader title="Create Supply" onBack={() => window.history.back()} />
      <ErrorAlert error={error} />
      <Form form={form} layout="vertical" onChange={() => setError("")}>
        <Space direction="vertical" style={{ width: "100%" }} size="large">
          <Card bordered>
            {renderOrganization()}
            <Row gutter={[24, 24]}>
              <Col xs={24} lg={12}>
                {renderName()}
                {renderType()}
                {renderCode()}
              </Col>
              <Col xs={24} lg={12}>
                <Row gutter={[8, 16]}>
                  <Col xs={12} lg={4}>
                    {renderInputNumber("length", true)}
                  </Col>
                  <Col xs={12} lg={4}>
                    {renderInputNumber("width", true)}
                  </Col>
                  <Col xs={12} lg={4}>
                    {renderInputNumber("height", true)}
                  </Col>
                  <Col xs={12} lg={12}>
                    {renderDimensionUnit()}
                  </Col>
                </Row>
                <Row gutter={[8, 16]}>
                  <Col xs={12} lg={12}>
                    {renderInputNumber("weight", false)}
                  </Col>
                  <Col xs={12} lg={12}>
                    {renderWeightUnit()}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
          <Row justify="center" align="middle" gutter={[8, 8]}>
            <Col xs={24} lg={3}>
              <Button
                size="large"
                type="primary"
                block
                icon={<PlusOutlined />}
                onClick={handleSubmit}
                loading={creating}
              >
                Create
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
    </PageContainer>
  );
};

export default CreateSupplyPage;
