import React, { useEffect, useState } from "react";

// components
import { InputNumber, Input, Form } from "antd";

// styles
import * as S from "./styles";

interface Props {
  name: string;
  onNameChange: (n: string) => void;
  setFormat: (format: string) => void;
}

const CreateLocationTemplateForm: React.FC<Props> = ({
  name,
  onNameChange,
  setFormat,
}) => {
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    const format = labels.join("-");
    setFormat(format);
  }, [labels, setFormat]);

  const onLabelChange = (index: number, value: string) => {
    const newLabels = [...labels];
    newLabels[index] = value;
    setLabels(newLabels);
  };

  const buildInputLabels = () => {
    return labels.map((label: string, index: number) => {
      return (
        <Form.Item
          key={`label-${index}`}
          hasFeedback
          validateStatus={label ? "success" : undefined}
        >
          <Input
            size="large"
            allowClear
            value={label}
            placeholder="label"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const label = e.target.value
                .trim()
                .replace(
                  /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                  ""
                );
              onLabelChange(index, label);
            }}
            autoCapitalize="off"
          />
        </Form.Item>
      );
    });
  };

  const onNumberOfLabelsChange = (labelCount: number) => {
    const different = labelCount - labels.length;
    if (!different) return;

    if (different > 0) {
      const newLabels = [...labels, ...Array(different).fill("")];
      setLabels(newLabels);
    } else {
      const newLabels = labels.slice(0, different);
      setLabels(newLabels);
    }
  };

  const formItemLayout = {
    wrapperCol: {
      sm: { span: 10 },
    },
  };

  return (
    <S.Container>
      <Form {...formItemLayout} layout="vertical">
        <Form.Item
          label="How many labels will you need for this template?"
          hasFeedback
          validateStatus={labels.length > 0 ? "success" : undefined}
        >
          <InputNumber
            size="large"
            min={0}
            max={10}
            style={{ width: "100%" }}
            onChange={(v?: string | number) => {
              const value = v ? +v : 0;
              onNumberOfLabelsChange(value > 10 ? 10 : value);
            }}
            value={labels.length}
          />
        </Form.Item>

        <Form.Item
          label="Template name"
          hasFeedback
          validateStatus={name ? "success" : undefined}
        >
          <Input
            size="large"
            allowClear
            autoCapitalize="off"
            placeholder="Template name"
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const text = e.target.value.replace(
                /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                ""
              );
              onNameChange(text);
            }}
          />
        </Form.Item>
        <Form.Item label="Please add a name for each label">
          <S.LabelsSectionDescription>
            Note that the first section will always need to be the facility
          </S.LabelsSectionDescription>
        </Form.Item>
        <Form.Item>
          <Input
            size="large"
            allowClear
            autoCapitalize="off"
            value="FACILITY"
            disabled
          />
        </Form.Item>
        {buildInputLabels()}
      </Form>
    </S.Container>
  );
};

export default CreateLocationTemplateForm;
