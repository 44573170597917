import { Warehouse } from "@secondcloset/types";
import queryString from "query-string";
import { getAPIFromDomain } from "../../lib/api";
// Interfaces
import { PaginatedData, PaginationOptions } from "./commonInterfaces";

type Return = Warehouse.Return;
type ReturnItem = Warehouse.ReturnItem;
type ReturnType = Warehouse.ReturnType;
type ReturnPhoto = Warehouse.ReturnPhoto;
type ReturnAddress = Warehouse.ReturnAddress;

export interface UpdatedReturnPhoto {
  items: ReturnPhoto[];
}

export interface ReturnPhotoResponse {
  added: UpdatedReturnPhoto;
  deleted?: number;
}

interface ReturnQuery {
  search?: string;
  with_organization?: boolean;
}

export type CreateReturnBodyItems = {
  name: string;
  external_product_id: string;
  sku?: string;
  upc?: string;
  condition?: string;
  condition_notes?: string;
  reason?: string;
  reason_notes?: string;
};

interface CreateReturnBody {
  carrier: string;
  shipping_from?: ReturnAddress;
  tracking_url?: string;
  tracking_number: string;
  organization_id: string;
  external_order_number: string;
  type: ReturnType;
  items: CreateReturnBodyItems[];
}

export const fetchReturns = async (
  query: PaginationOptions & ReturnQuery
): Promise<PaginatedData<Return>> => {
  const api = getAPIFromDomain("november");
  const url = `/returns?${queryString.stringify(query || {})}`;
  const response = await api.get(url);
  return response?.data as PaginatedData<Return>;
};

export const fetchReturnById = async (id: string): Promise<Return> => {
  const api = getAPIFromDomain("november");
  const url = `/returns/${id}`;
  const response = await api.get(url);
  return response?.data as Return;
};

export const createReturn = async (body: CreateReturnBody): Promise<Return> => {
  const api = getAPIFromDomain("november");
  const url = "/returns";
  const response = await api.post(url, body);
  return response?.data as Return;
};

export type UpdateReturnBody = {
  status?: string;
  internal_notes?: string;
  reason?: string; // RTS
  reason_notes?: string; // RTS
  photos?: string[];
  return_items?: ReturnItem[];
};

export const updateReturn = async (
  id: string,
  body: UpdateReturnBody
): Promise<Return> => {
  const api = getAPIFromDomain("november");
  const url = `/integrations/update-return/${id}`;
  const response = await api.patch(url, body);
  return response?.data as Return;
};

export type UpdateReturnPhotosBody = {
  add?: string[];
  remove?: string[];
};

export const addRemoveReturnPhotos = async (
  returnId: string,
  body: UpdateReturnPhotosBody
): Promise<ReturnPhotoResponse> => {
  const api = getAPIFromDomain("november");
  const url = `/return-photos/${returnId}`;
  const response = await api.patch(url, body);
  return response?.data;
};
