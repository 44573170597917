import { Warehouse } from "@secondcloset/types";
import { FacilityCode } from "@secondcloset/fulfillment-utils";
type Supply = Warehouse.Supply;

export const getStockFromFacility = (
  supply: Supply,
  facility?: FacilityCode
): number => {
  if (!facility) return 0;
  const { supply_stock } = supply;
  const stock =
    supply_stock && supply_stock.find((stock) => stock.facility === facility);

  return (stock && stock.quantity) || 0;
};
