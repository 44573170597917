import React from "react";

// styles
import {
  BoldText,
  Title,
  InfoText,
  HoverTipIcon,
  AttributeText,
} from "./styles";

// components
import { Button, Card, Col, Collapse, Descriptions, Popover, Row } from "antd";

interface Field {
  title: string;
  value: string;
  description: string;
  isRequired?: boolean;
}

interface FAQ {
  question: string;
  answer: string;
}

interface Props {
  onDownloadClick?: () => any;
  title?: string;
  infoText?: string;
  fields?: Field[];
  faqs?: FAQ[];
  hideIntro?: boolean;
}

const CsvInstructions: React.FC<Props> = ({
  onDownloadClick,
  title,
  infoText,
  fields,
  faqs,
  hideIntro = false,
}) => {
  const renderIntro = () => {
    return (
      <Card bordered>
        <Title>{title}</Title>
        <InfoText>{infoText}</InfoText>
        <Button type="primary" ghost onClick={onDownloadClick}>
          Download CSV Template
        </Button>
      </Card>
    );
  };

  const renderTableRow = (
    title: string,
    value: string,
    description: string,
    isRequired?: boolean
  ) => {
    return (
      <>
        <Descriptions.Item
          label={
            <Popover
              overlayStyle={{ maxWidth: "280px" }}
              placement="topLeft"
              content={<div>{description}</div>}
              title={title}
            >
              <AttributeText strong isRequired={isRequired}>
                {title + " "}
              </AttributeText>
              <HoverTipIcon />
            </Popover>
          }
        >
          {value}
        </Descriptions.Item>
      </>
    );
  };

  const renderRequiredFieldsTable = () => {
    return (
      <Descriptions column={1} bordered size="small" colon={false}>
        {fields?.map((field: Field) => {
          return renderTableRow(
            field?.title || "",
            field?.value || "",
            field?.description || "",
            field?.isRequired || false
          );
        })}
      </Descriptions>
    );
  };

  const renderRequiredFieldsContent = () => {
    return (
      <>
        <InfoText>
          The following are details on entering field values properly. No dashes
          or brackets. Required fields are marked with red asterisks.
        </InfoText>
        {renderRequiredFieldsTable()}
      </>
    );
  };

  const renderFaq = () => {
    return faqs?.map((faq: FAQ) => (
      <>
        <BoldText>{faq?.question || ""}</BoldText>
        <InfoText>{faq?.answer || ""}</InfoText>
      </>
    ));
  };

  const renderInstructionCards = () => {
    return (
      <Collapse defaultActiveKey={[1]} accordion>
        <Collapse.Panel header="CSV Format Guide" key={1}>
          {fields ? renderRequiredFieldsContent() : "Not Available"}
        </Collapse.Panel>
        <Collapse.Panel header="FAQ" key={2}>
          {faqs ? renderFaq() : "Not Available"}
        </Collapse.Panel>
      </Collapse>
    );
  };

  return (
    <Row gutter={[8, 16]}>
      <Col xs={24}>{!hideIntro && renderIntro()}</Col>
      <Col xs={24}>{renderInstructionCards()}</Col>
    </Row>
  );
};

export default CsvInstructions;
