import { Button, Timeline } from "antd";
import React from "react";
import { useHistory } from "react-router";
import * as S from "./styles";
import { Fulfillment } from "@secondcloset/types";
type ASN = Fulfillment.ASN;

interface Props {
  asn?: ASN;
  onMarkAsArrivedClick: () => any;
  onHoldClick: () => any;
}

const WarehouseActions: React.FC<Props> = ({
  asn,
  onMarkAsArrivedClick,
  onHoldClick,
}) => {
  const history = useHistory();

  const asnStatus = asn?.status;
  const renderMarkAsArrivedButton = () => {
    const isDisabled = asnStatus !== "awaiting";
    return (
      <Button
        type="primary"
        disabled={isDisabled}
        onClick={onMarkAsArrivedClick}
      >
        Mark as Arrived
      </Button>
    );
  };

  const renderProcessASNButton = () => {
    const isDisabled = asnStatus !== "arrived";
    return (
      <Button
        type="primary"
        disabled={isDisabled}
        onClick={() => history.push(`/asn/${asn?.id}/process`)}
      >
        Process ASN
      </Button>
    );
  };

  const renderPlaceOnHoldButton = () => {
    const isDisabled = asnStatus !== "arrived" && asnStatus !== "on_hold";
    return (
      <Button type="primary" disabled={isDisabled} onClick={onHoldClick}>
        {asnStatus === "on_hold" ? "Resume" : "Place on Hold"}
      </Button>
    );
  };

  return (
    <S.Container>
      <S.Title>Warehouse Actions</S.Title>
      <Timeline>
        <Timeline.Item>{renderMarkAsArrivedButton()}</Timeline.Item>
        <Timeline.Item>{renderProcessASNButton()}</Timeline.Item>
        <Timeline.Item>{renderPlaceOnHoldButton()}</Timeline.Item>
      </Timeline>
    </S.Container>
  );
};

export default WarehouseActions;
