import {
  BaseProductItem,
  VirtualKitItem,
} from "@secondcloset/fulfillment-utils";

export const constructTrackingInputs = (
  item: BaseProductItem | VirtualKitItem,
  quantity: number
) => {
  const isBaseProduct = item.isBase;
  if (isBaseProduct) {
    const trackingInputs = new Array(quantity).fill(0).map(() => ({
      lotNumber: "",
      serialNumber: "",
      name: item.name,
    }));
    return { [item.id]: trackingInputs };
  }

  //virtual kit
  const trackingInputs = item.base_products.reduce((acc, baseProduct) => {
    const numOfInputs = (baseProduct.qty / item.qty) * quantity;
    acc[baseProduct.id] = new Array(numOfInputs).fill(0).map(() => ({
      lotNumber: "",
      serialNumber: "",
      name: baseProduct.name,
    }));
    return acc;
  }, {});
  return trackingInputs;
};
