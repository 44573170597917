import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect, useLocation } from "react-router";
import { useRecoilValue } from "recoil";
import { userState } from "../recoil/user/atoms";

interface Props {
  component: any;
  exact?: boolean;
  path?: string;
}

const PrivateRoute: React.FC<Props> = (props) => {
  const user = useRecoilValue(userState);
  const location = useLocation();
  const { component: Component, ...rest } = props;
  const authenticated = !!user;
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default PrivateRoute;
