import { atom } from "recoil";
import { Common } from "@secondcloset/types";

type Organization = Common.Organization;

export const asnCreateFlowSelectedOrganization = atom<Organization | undefined>(
  {
    key: "asnCreateFlowSelectedOrganization",
    default: undefined,
  }
);

export const asnCreateFlowOrganizations = atom<Organization[]>({
  key: "asnCreateFlowOrganizations",
  default: [],
});

export const asnCreateFlowLoadingOrganizations = atom<boolean>({
  key: "asnCreateFlowLoadingOrganizations",
  default: false,
});
