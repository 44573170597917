import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import {
  Card,
  Col,
  Descriptions,
  Input,
  PageHeader,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import {
  addRemoveReturnPhotos,
  fetchReturnById,
  updateReturn,
  updateReturnItem,
  UpdateReturnPhotosBody,
  UpdateReturnItemBody,
  UpdateReturnItemCondition,
  batchUpdateReturnItem,
} from "../../../api/warehouse";

import WithLabel from "../../../components/WithLabel";
import AddPhotos from "../../../components/AddPhotos";
import PageContainer from "../../../components/PageContainer";
import ProcessReturnActionButtons from "../../../components/ProcessReturnActionButtons";
import ReturnItemsTable from "../../../components/ReturnItemsTable";

import {
  isAtEndOfFlow,
  mapPhotosToAntdType,
  validateReturnFieldsConditions,
} from "../ProcessRegularReturn/helpers";
import {
  ProductCondition,
  ReturnStatus,
  RtsCondition,
} from "../ReturnsIndexPage/helpers";
import Organization from "../../../lib/organization";

type Return = Warehouse.Return;
type ReturnItem = Warehouse.ReturnItem;
type ReturnPhoto = Warehouse.ReturnPhoto;

const { Option } = Select;
const { TextArea } = Input;

const ProcessRegularReturn: React.FC = () => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [internalNotes, setInternalNotes] = useState("");
  const [rtsReason, setRTSReason] = useState("");
  const [rtsReasonNotes, setRTSReasonNotes] = useState("");
  const [isUpdatingReturnItem, setIsUpdatingReturnItem] = useState(false);
  const [photos, setPhotos] = useState<ReturnPhoto[]>([]);
  const [returnItemsCondition, setReturnItemsCondition] = useState<
    ReturnItem[]
  >([]);
  const { returnId } = useParams<{ returnId: string }>();

  const {
    data: returnDetails,
    isLoading: isDetailsLoading,
    refetch: refetchReturn,
  } = useQuery(["fetchReturnById", returnId], () => fetchReturnById(returnId), {
    onSuccess: (data: Return) => {
      if (isAtEndOfFlow(data?.status || "")) {
        history.push(`/returns/${returnId}`);
      } else {
        setPhotos(data?.photos || []);
        setInternalNotes(data?.internal_notes as string);
        setRTSReason(data?.reason as string);
        setRTSReasonNotes(data?.reason_notes as string);
        setReturnItemsCondition(data?.return_items as ReturnItem[]);
      }
    },
    onError: setError,
  });

  const { mutate: updateProcessWithFlag, isLoading: isProcessUpdating } =
    useMutation(
      (status: string) =>
        updateReturn(returnId, {
          internal_notes: internalNotes,
          status,
          reason: rtsReason,
          reason_notes: rtsReasonNotes,
        }),
      {
        onSuccess: () => history.push(`/returns/${returnId}`),
        onError: setError,
      }
    );

  const { mutate: doUpdateReturnItem } = useMutation(
    (body: UpdateReturnItemBody) => updateReturnItem(body),
    {
      onSuccess: () => {
        setIsUpdatingReturnItem(false);
      },
      onError: setError,
    }
  );

  const { mutate: doBatchUpdateReturnItem } = useMutation(
    (body: UpdateReturnItemBody[]) => batchUpdateReturnItem(body),
    {
      onSuccess: () => {
        refetchReturn({ cancelRefetch: true });
        setIsUpdatingReturnItem(false);
      },
      onError: setError,
    }
  );

  const { mutate: markAsCompleted, isLoading: isMarkAsCompleteLoading } =
    useMutation(
      () =>
        updateReturn(returnId, {
          internal_notes: internalNotes,
          status: ReturnStatus.COMPLETED,
          reason: rtsReason,
          reason_notes: rtsReasonNotes,
        }),
      {
        onSuccess: () => history.push(`/returns/${returnId}`),
        onError: setError,
      }
    );
  const { mutate: markAsNeedsReview, isLoading: isMarkAsNeedsReviewLoading } =
    useMutation(
      () =>
        updateReturn(returnId, {
          internal_notes: internalNotes,
          status: ReturnStatus.NEEDS_REVIEW,
          reason: rtsReason,
          reason_notes: rtsReasonNotes,
        }),
      {
        onSuccess: () => history.push(`/returns/${returnId}`),
        onError: setError,
      }
    );

  const { mutate: doAddRemoveReturnPhotos, isLoading: isPhotoLoading } =
    useMutation(
      (body: UpdateReturnPhotosBody) => addRemoveReturnPhotos(returnId, body),
      {
        onSuccess: (data) => {
          setError("");
          const newPhoto = data?.added?.items;
          if (!newPhoto?.length) {
            refetchReturn({ cancelRefetch: true });
          } else {
            setPhotos([...photos, ...newPhoto]);
          }
        },
        onError: setError,
      }
    );

  const handleUpdateReturnItem = (
    returnItemId: string,
    body: UpdateReturnItemCondition
  ) => {
    doUpdateReturnItem({ id: returnItemId, ...body });
  };

  const handleCompleteReturn = () => {
    const orgName = returnDetails?.organization?.name as string;
    const isVessi = Organization.isVessi({ organizationName: orgName });
    const { isValid, error: errorMsg } = validateReturnFieldsConditions(
      orgName,
      returnItemsCondition,
      false
    );
    if (
      !rtsReason?.trim() ||
      (rtsReason?.toLowerCase() === "other" && !rtsReasonNotes?.trim())
    ) {
      setError(
        "RTS Reason is required. RTS Reason notes are required if RTS is 'Other'. "
      );
    } else if (isValid) {
      if (isVessi) {
        markAsNeedsReview();
      } else {
        markAsCompleted();
      }
    } else {
      setError(errorMsg || "");
    }
  };

  const handleBatchUpdateReturnItems = (
    returnItems: UpdateReturnItemBody[]
  ) => {
    setIsUpdatingReturnItem(true);
    doBatchUpdateReturnItem(returnItems || []);
  };
  const handleMenuClick = (status: string) => {
    if (status === ReturnStatus.ON_HOLD && !internalNotes?.trim()) {
      return setError("Please add an internal notes for on hold reason");
    }
    updateProcessWithFlag(status);
  };

  const handleAddPhotos = (base64String: string) => {
    doAddRemoveReturnPhotos({ add: [base64String] });
  };

  const handleRemovePhotos = (file: UploadFile) => {
    doAddRemoveReturnPhotos({ remove: [file?.uid] });
  };

  const renderButtons = () => (
    <ProcessReturnActionButtons
      returnDetails={returnDetails}
      handleCompleteReturn={handleCompleteReturn}
      handleMenuClick={handleMenuClick}
      isUpdating={
        isMarkAsCompleteLoading ||
        isProcessUpdating ||
        isMarkAsNeedsReviewLoading
      }
      isUpdatingReturnItem={isUpdatingReturnItem}
    />
  );

  const renderMainInfo = () => {
    return (
      <Descriptions bordered column={{ xs: 1, lg: 2 }}>
        <Descriptions.Item label="RMA">
          <Typography.Text copyable>{returnDetails?.rma}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="External Order #" span={2}>
          <Typography.Text copyable={!!returnDetails?.external_order_number}>
            {returnDetails?.external_order_number || "Not Available"}
          </Typography.Text>
        </Descriptions.Item>
      </Descriptions>
    );
  };

  const renderItemsTable = () => {
    return (
      <ReturnItemsTable
        productCondition={ProductCondition}
        returnItemsCondition={returnItemsCondition}
        setReturnItemsCondition={setReturnItemsCondition}
        updateReturnItem={handleUpdateReturnItem}
        setIsUpdatingReturnItem={setIsUpdatingReturnItem}
        handleBatchUpdateReturnItems={handleBatchUpdateReturnItems}
        isUpdatingReturnItem={isUpdatingReturnItem}
      />
    );
  };

  const renderPhotos = () => {
    return (
      <AddPhotos
        onUploadPhoto={handleAddPhotos}
        onRemovePhoto={handleRemovePhotos}
        photos={mapPhotosToAntdType(photos) || []}
        isPhotoLoading={isPhotoLoading}
        setError={setError}
      />
    );
  };

  const renderReason = () => {
    return (
      <WithLabel name="Select a RTS reason">
        <Select
          allowClear
          size="large"
          value={rtsReason}
          placeholder="Select a condition"
          style={{ width: "100%" }}
          onChange={setRTSReason}
        >
          {Object.keys(RtsCondition)?.map((condition) => (
            <Option key={condition} value={condition}>
              {RtsCondition?.[condition]}
            </Option>
          ))}
        </Select>
      </WithLabel>
    );
  };

  const renderReasonNotes = () => {
    return (
      <WithLabel name="RTS Reason notes">
        <TextArea
          value={rtsReasonNotes}
          rows={5}
          onChange={(e) => {
            setRTSReasonNotes(e?.target?.value);
          }}
        />
      </WithLabel>
    );
  };

  const renderInternalNotes = () => {
    return (
      <WithLabel name="Internal notes">
        <TextArea
          value={internalNotes}
          rows={5}
          onChange={(e) => {
            setError("");
            setInternalNotes(e?.target?.value);
          }}
        />
      </WithLabel>
    );
  };

  const renderReturnReason = () => {
    return (
      <Row gutter={[40, 24]}>
        <Col xs={24} lg={12}>
          <Row gutter={[8, 16]}>
            <Col xs={24}>{renderReason()}</Col>
            <Col xs={24}>{renderReasonNotes()}</Col>
          </Row>
        </Col>
        <Col xs={24} lg={12}>
          {renderInternalNotes()}
        </Col>
      </Row>
    );
  };

  const renderToolbar = () => {
    return (
      <Row justify="space-between" gutter={[8, 16]}>
        <Col xs={24} lg={12}>
          <Typography.Title>{returnDetails?.rma}</Typography.Title>
        </Col>
        <Col xs={24} lg={12}>
          {renderButtons()}
        </Col>
      </Row>
    );
  };

  return (
    <PageContainer withPadding>
      <Spin spinning={isDetailsLoading}>
        <PageHeader
          title="Return Detail"
          onBack={() => window.history.back()}
        />
        <Space direction="vertical" style={{ width: "100%" }}>
          <ErrorAlert error={error} />
          {renderToolbar()}
          {renderMainInfo()}
          <Card bordered>{renderReturnReason()}</Card>
          <Card bordered>{renderPhotos()}</Card>
          {renderItemsTable()}
        </Space>
      </Spin>
    </PageContainer>
  );
};

export default ProcessRegularReturn;
