import React, { useCallback, useEffect, useState } from "react";

// components
import { Button, Checkbox, notification, Space, Spin, Steps } from "antd";
import {
  PackageSummary,
  UploadBillOfLading,
} from "@secondcloset/web-components";

// hooks and utils
import { useMutation } from "react-query";
import { uploadBOL } from "../../../api/fulfillment";
import { debounce, get } from "lodash-es";
import { DownloadOutlined } from "@ant-design/icons";

import { FreightSummaryContainer } from "./styles";

// recoil
import { packingFlowShipmentState } from "../../../recoil/packingFlow/atoms";
import { useRecoilState } from "recoil";

enum UploadBOLSteps {
  pack_all_items,
  create_bol,
  upload_bol,
  print_bol,
}

interface Props {
  enableActions: boolean;
  setEnableActions: (value: boolean) => unknown;
}

const FREIGHTCOM_URL = "https://app.freightcom.com";

const { Step } = Steps;
const FreightShipmentSummary: React.FC<Props> = ({
  enableActions,
  setEnableActions,
}) => {
  const [shipment, dispatchSetShipment] = useRecoilState(
    packingFlowShipmentState
  );
  const [currentStep, setCurrentStep] = useState(UploadBOLSteps.create_bol);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [bolNumber, setBolNumber] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const { mutate, ...shipmentDetails } = useMutation(uploadBOL);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onUploadBOL = useCallback(debounce(mutate, 500), []);

  useEffect(() => {
    if (!enableActions) window.open(FREIGHTCOM_URL, "_blank");
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentStep !== UploadBOLSteps.print_bol) return;
    if (!uploadedFile || !bolNumber) setCurrentStep(UploadBOLSteps.upload_bol);
    //eslint-disable-next-line
  }, [currentStep, uploadedFile, bolNumber]);

  const handleUploadBOL = () => {
    if (!shipment || !uploadedFile || !bolNumber) return;
    onUploadBOL(
      {
        shipmentID: shipment.id,
        body: {
          bol_file: uploadedFile,
          label_id: bolNumber,
          tracking_number: trackingNumber,
        },
      },
      {
        onSuccess: (shipment) => {
          dispatchSetShipment(shipment);
        },
        onError: (error) => {
          setEnableActions(false);
          setCurrentStep(UploadBOLSteps.upload_bol);
          notification.error({
            message: "Error",
            description: error as string,
          });
        },
      }
    );
  };

  const handleNextButtonClick = (nextStep: UploadBOLSteps) => {
    if (currentStep === UploadBOLSteps.upload_bol) handleUploadBOL();
    setCurrentStep(nextStep);
  };

  const buildNextButton = (
    activeStep: UploadBOLSteps,
    nextStep: UploadBOLSteps,
    isDisabled?: boolean
  ) => {
    if (currentStep !== activeStep) return;
    return (
      <Button
        type="primary"
        disabled={isDisabled}
        onClick={() => handleNextButtonClick(nextStep)}
      >
        Next
      </Button>
    );
  };

  const renderCreateBOLStep = () => {
    return (
      <Space direction="vertical">
        <p>
          You will be redirected to&nbsp;
          <a href={FREIGHTCOM_URL} target="_blank" rel="noopener noreferrer">
            Freightcom
          </a>
          , please create a BOL then click next.
        </p>
        {buildNextButton(UploadBOLSteps.create_bol, UploadBOLSteps.upload_bol)}
      </Space>
    );
  };

  const renderUploadBOLStep = () => {
    const isDisabled = !bolNumber || !uploadedFile;
    return (
      <Space direction="vertical">
        Attach BOL created from Freightcom on the right then click next to
        upload.
        {buildNextButton(
          UploadBOLSteps.upload_bol,
          UploadBOLSteps.print_bol,
          isDisabled
        )}
      </Space>
    );
  };

  const renderApplyBOLStep = () => {
    const isDisabled = currentStep !== UploadBOLSteps.print_bol;
    const shippingLabel = get(shipment, "shipping_method.shipping_labels[0]");
    return (
      <Space direction="vertical">
        If you have not already done so, print BOL and apply the label to the
        pallet. Then click &quot;Mark as Shipped&quot; to continue.
        <Button
          size="small"
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => window.open(shippingLabel?.label_download, "_blank")}
          disabled={isDisabled}
        >
          Print Bill Of Lading
        </Button>
        <Checkbox
          checked={enableActions}
          onChange={(e) => setEnableActions(e.target.checked)}
          disabled={isDisabled}
        >
          I have printed and applied the bill of lading
        </Checkbox>
      </Space>
    );
  };

  const renderSteps = () => (
    <Steps direction="vertical" current={currentStep}>
      <Step title="All items successfully packed" />
      <Step
        title="Create Bill Of Lading (BOL)"
        description={renderCreateBOLStep()}
      />
      <Step
        title="Upload Bill Of Lading (BOL)"
        description={renderUploadBOLStep()}
      />
      <Step
        title="Apply Bill Of Lading (BOL)"
        description={renderApplyBOLStep()}
      />
    </Steps>
  );

  const renderUploadBOL = () => {
    const isPrintStep = currentStep === UploadBOLSteps.print_bol;
    return (
      <div className={isPrintStep ? "ant-spin-blur" : ""}>
        <UploadBillOfLading
          uploadedFile={uploadedFile}
          bolNumber={bolNumber}
          trackingNumber={trackingNumber}
          setUploadedFile={setUploadedFile}
          setBolNumber={setBolNumber}
          setTrackingNumber={setTrackingNumber}
        />
      </div>
    );
  };

  return (
    <Spin
      spinning={shipmentDetails.isLoading}
      tip="Uploading bill of lading..."
    >
      <FreightSummaryContainer>
        {renderSteps()}
        {currentStep === UploadBOLSteps.create_bol ? (
          <PackageSummary packages={shipment?.packages ?? []} />
        ) : (
          renderUploadBOL()
        )}
      </FreightSummaryContainer>
    </Spin>
  );
};

export default FreightShipmentSummary;
