import React, { FC } from "react";

// Assets
import standardPalletSVG from "../../../../../../assets/standard_pallet.svg";
import doublePalletSVG from "../../../../../../assets/double_pallet.svg";

// Components
import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import { EditOutlined } from "@ant-design/icons";
import * as S from "./styles";
import * as P from "../../../SelectPackageTypeStep/styles";

// Helpers
import { capitalize, startCase } from "lodash-es";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import {
  packingFlowShowPalletTypeModalState,
  PackageType,
  packingFlowPackageContainerState,
  currentPackageItemIndexState,
} from "../../../../../../recoil/packingFlow/atoms";
import {
  useEditPackage,
  defaultPackage,
} from "../../../../../../recoil/packingFlow/helpers";

interface PackageTypeOption {
  value: PackageType;
  image: string;
  description: string;
}

const PACKAGE_TYPE_OPTIONS: PackageTypeOption[] = [
  {
    value: PackageType.standard_pallet,
    image: standardPalletSVG,
    description: `Packing items on a 48" x 40" standard pallet.`,
  },
  {
    value: PackageType.double_pallet,
    image: doublePalletSVG,
    description: `Packing items on a 96” x 48” double pallet.`,
  },
];

const PalletTypeModal: FC = () => {
  const [showPalletTypeModal, setShowPalletTypeModal] = useRecoilState(
    packingFlowShowPalletTypeModalState
  );
  const packageContainers = useRecoilValue(packingFlowPackageContainerState);
  const currentPackageItemIndex = useRecoilValue(currentPackageItemIndexState);
  const currentPackageContainer = packageContainers[currentPackageItemIndex];
  const { replaceCurrentContainerWith } = useEditPackage();
  const currentPackageType = currentPackageContainer.packageType;

  const getWarningText = () => {
    if (currentPackageType === PackageType.standard_pallet) {
      return "A maximum of 6 pallets is allowed per shipment.";
    }
    if (currentPackageType === PackageType.double_pallet) {
      return "A maximum of 6 pallets is allowed per shipment.";
    }
  };

  const renderOption = (option: PackageTypeOption) => {
    const { value, image, description } = option;
    const isSelected = currentPackageType === value;
    return (
      <P.CardWrapper key={value}>
        <P.Card
          isSelected={isSelected}
          onClick={() => {
            replaceCurrentContainerWith({
              ...defaultPackage[value],
              packageType: value,
              isCustomDimensions: false,
            });
          }}
        >
          <P.Icon src={image} alt="Option Image" />
          <P.CardTitle level={4}>{startCase(value)}</P.CardTitle>
          <P.Text>{description}</P.Text>
        </P.Card>
        {isSelected && getWarningText() ? (
          <P.InfoBanner message={getWarningText()} type="warning" />
        ) : null}
      </P.CardWrapper>
    );
  };

  const closeModal = () => {
    setShowPalletTypeModal(false);
  };

  const footer = (
    <S.ModalFooter>
      <Button type="primary" onClick={closeModal}>
        Submit
      </Button>
    </S.ModalFooter>
  );

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setShowPalletTypeModal(true);
        }}
        icon={<EditOutlined />}
      >
        {capitalize(
          currentPackageType?.replace("pallet", "").replace("_", " ")
        )}
      </Button>
      <Modal
        title="Pallet Type"
        visible={showPalletTypeModal}
        onOk={closeModal}
        onCancel={closeModal}
        footer={footer}
        width="fit-content"
      >
        <S.OptionsWrapper>
          {PACKAGE_TYPE_OPTIONS.map((option) => renderOption(option))}
        </S.OptionsWrapper>
      </Modal>
    </>
  );
};

export default PalletTypeModal;
