import { Warehouse } from "@secondcloset/types";
import queryString from "query-string";
import { getAPIFromDomain } from "../../lib/api";
// Interfaces
import { PaginatedData, PaginationOptions } from "./commonInterfaces";

type SupplyStock = Warehouse.SupplyStock;

interface SupplyStockQuery {
  supply_id: string;
  facility?: string;
}
export interface UpdateSupplyStockBody {
  facility: string;
  change_by: number;
}

export const fetchSupplyStock = async (
  query: PaginationOptions & SupplyStockQuery
): Promise<PaginatedData<SupplyStock>> => {
  const api = getAPIFromDomain("november");
  const url = `/supply-stock?${queryString.stringify(query || {})}`;
  const response = await api.get(url);
  return response.data as PaginatedData<SupplyStock>;
};

export const updateSupplyStock = async ({
  supplyId,
  body,
}: {
  supplyId: string;
  body: UpdateSupplyStockBody[];
}): Promise<SupplyStock> => {
  const api = getAPIFromDomain("november");
  const url = `/supply-stock/${supplyId}`;
  const response = await api.patch(url, { items: body });
  return response.data as SupplyStock;
};
