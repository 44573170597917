import styled from "styled-components";
import colors from "../../../../../../styles/colors";

export const CreateButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin: 0;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelText = styled.div`
  font-size: 14px;
  font-weight: 300;
`;

export const PrimaryText = styled.div`
  color: ${colors.CHARCOAL};
`;
