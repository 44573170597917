import React, { useEffect, useState } from "react";
import { Space, Spin, Typography, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { UploadFile, UploadFileStatus } from "antd/lib/upload/interface";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";

import { getBase64 } from "./helpers";

export type PhotoUploadFile = {
  uid: string;
  name: string;
  status: UploadFileStatus;
  preview?: string;
  url: string;
};

interface Props {
  photos?: PhotoUploadFile[];
  onUploadPhoto: (value: string) => void;
  onRemovePhoto: (file: UploadFile<any>) => void;
  isPhotoLoading: boolean;
  setError?: (error: string) => void;
}

const AddPhotos: React.FC<Props> = ({
  photos,
  onUploadPhoto,
  onRemovePhoto,
  isPhotoLoading,
  setError,
}) => {
  const [fileList, setFileList] = useState<PhotoUploadFile[]>([]);
  const [isConverting, setIsConverting] = useState(false);
  const MAX_FILE_SIZE_IN_MB = 0.85;

  useEffect(() => {
    photos && setFileList(photos);
  }, [photos]);

  const onBeforeUpload = () => {
    return false;
  };

  async function convertHEIC(imageFile: File) {
    const convertedFile = await heic2any({
      blob: imageFile,
      toType: "image/jpeg",
    });

    return convertedFile;
  }

  async function compressImage(imageFile: File) {
    const options = {
      maxSizeMB: MAX_FILE_SIZE_IN_MB,
    };

    return await imageCompression(imageFile as File, options);
  }

  const handleUpload = async (file: File) => {
    const compressedFile = await compressImage(file);
    const compressedBinaryStr = await getBase64(compressedFile);
    compressedBinaryStr && onUploadPhoto(compressedBinaryStr as string);
  };

  const handleChange = async ({ file }) => {
    if (file.status === "removed") return;
    if (!["image/jpeg", "image/png"]?.includes(file?.type)) {
      try {
        setIsConverting(true);
        const imageFile = await convertHEIC(file);
        return await handleUpload(imageFile as File);
      } catch (error: any) {
        return (
          setError &&
          setError(error.message || "Error: File type not supported.")
        );
      } finally {
        setIsConverting(false);
      }
    }

    try {
      return handleUpload(file);
    } catch (error) {
      return (
        setError &&
        setError((error as string) || "Error: Could not upload file.")
      );
    }
  };

  const renderUploadButton = () => (
    <Space direction="vertical" style={{ width: "100%" }} align="center">
      <PlusOutlined />
      <Typography.Text>Upload</Typography.Text>
    </Space>
  );

  const renderSpinner = () => (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
  );

  return (
    <Space direction="horizontal" align="center" style={{ marginTop: 8 }}>
      <Upload
        listType="picture-card"
        fileList={fileList}
        beforeUpload={onBeforeUpload}
        onChange={handleChange}
        onRemove={onRemovePhoto}
        disabled={isPhotoLoading || isConverting}
      >
        {isPhotoLoading || isConverting
          ? renderSpinner()
          : renderUploadButton()}
      </Upload>
    </Space>
  );
};

export default AddPhotos;
