import { Logistics, Fulfillment } from "@secondcloset/types";
import OrderLib from "../../../../../lib/order";
//api
import { ExternalCarriers } from "../../../../../api/fulfillment";
import { useRecoilValue } from "recoil";
import {
  getIsAcidLeague,
  getIsOddPieces,
  getIsVessi,
} from "../../../../../recoil/packingFlow/selectors";
import { isUserConfirmedPoBoxAddressState } from "../../../../../recoil/packingFlow/atoms";

type Order = Fulfillment.Order;
export type RateMap = {
  [carrierID: string]: Logistics.ShippingRate[];
};

export const initializeRateMap = (rates: Logistics.ShippingRate[]): RateMap => {
  return rates.reduce((acc, cv) => {
    const carrierID = cv?.carrier?.id;
    if (!carrierID) return acc;
    if (!acc[carrierID]) acc[carrierID] = [];
    acc[carrierID].push(cv);
    return acc;
  }, {} as RateMap);
};

/**
 * When there are two accounts for the same carrier, we want to show SC logo
 * on the onces that is not a client_account
 */
export const checkIsSecondClosetLogo = (
  rate: Logistics.ShippingRate,
  rateMap: RateMap
) => {
  const isClientAccount = !!rate?.client_account;
  const carrierID = rate?.carrier?.id;
  const sameCarrierRates = rateMap[carrierID] || [];
  const hasBothAccounts =
    sameCarrierRates.find((r) => !!r.client_account) &&
    sameCarrierRates.find((r) => !r.client_account);

  return !isClientAccount && hasBothAccounts;
};

export const getProviderScopeFromRatesVersion = (
  ratesVersion: number,
  isSignatureRate: boolean
): ExternalCarriers[] => {
  switch (ratesVersion) {
    case 1:
      return isSignatureRate
        ? ["shipengine"]
        : ["easypost", "shipengine", "boxknight"];
    case 2:
      return isSignatureRate ? ["freightcom"] : ["freightcom", "boxknight"];
    default:
      return ["freightcom", "easypost", "boxknight"];
  }
};

interface CarrierSetting {
  packingOrder?: Order;
  isVessi: boolean;
  isOddPieces: boolean;
  isProduction: boolean;
  isUserConfirmedPoBoxAddress: boolean;
  isV1AcidLeague: boolean;
}

const getVessiCarrierMapping = (packingOrder?: Order) => {
  const shippingService = packingOrder?.shipping_service?.toLowerCase() || "";
  const isUSExpress = shippingService.includes(
    "USA Express Shipping".toLowerCase()
  );
  const isUSGround = shippingService.includes(
    "USA Ground Shipping".toLowerCase()
  );
  const isCAGround = shippingService.includes(
    "CA Ground Shipping".toLowerCase()
  );
  const isCAExpress = shippingService.includes(
    "CA Express Shipping".toLowerCase()
  );
  const isPurolator = shippingService.includes("Purolator".toLowerCase());

  const hasMatchingShippingService =
    !!isUSGround ||
    !!isUSExpress ||
    !!isCAExpress ||
    !!isCAGround ||
    !!isPurolator;

  return {
    isUSExpress,
    isUSGround,
    isCAGround,
    isCAExpress,
    isPurolator,
    hasMatchingShippingService,
  };
};

const getOddPiecesCarrierMapping = (packingOrder?: Order) => {
  const shippingAddressCountry = (packingOrder?.address?.country || "").trim();
  const isUSA = shippingAddressCountry === "United States";
  const isCanada = shippingAddressCountry === "Canada";
  const hasMatchingAddressCountry = !!isUSA || !!isCanada;
  return {
    isUSA,
    isCanada,
    hasMatchingAddressCountry,
  };
};

const getCarrierIDScoping = (params: CarrierSetting): string[] => {
  const {
    packingOrder,
    isVessi,
    isOddPieces,
    isProduction,
    isUserConfirmedPoBoxAddress,
    isV1AcidLeague,
  } = params;
  const shouldScope = isVessi || isV1AcidLeague || isOddPieces;
  if (!packingOrder) return [];
  if (!isProduction || !shouldScope) return [];
  const isPoBox =
    OrderLib.isOrderAddressPoBox(packingOrder) || isUserConfirmedPoBoxAddress;

  if (isVessi) {
    //Vessi mappings
    const {
      isUSExpress,
      isUSGround,
      isCAGround,
      isCAExpress,
      isPurolator,
      hasMatchingShippingService,
    } = getVessiCarrierMapping(packingOrder);
    if (isPoBox) return ["se-720248"]; // use canada post
    if (isUSExpress || isCAGround || isCAExpress || isPurolator) {
      return ["se-687156"];
    }
    if (isUSGround) return ["se-687188"];
    if (!hasMatchingShippingService) return ["no-matching-account"];
  }

  if (isOddPieces) {
    if (isPoBox) {
      // PoBoxes => canada post/USPS
      return [
        "6300",
        "6301",
        "6302",
        "6203",
        "6204",
        "6205",
        "6206",
        "6207",
        "6208",
        "6209",
        "6310",
        "6311",
        "6312",
        "6313",
        "6314",
        "6315",
        "6316",
        "6317",
        "6318",
        "6319",
      ];
    }
    const { isUSA, isCanada, hasMatchingAddressCountry } =
      getOddPiecesCarrierMapping(packingOrder);
    if (isUSA) return ["se-1195152"];
    if (isCanada) return ["se-1195125"];
    if (!hasMatchingAddressCountry) return ["no-matching-account"];
  }

  if (isV1AcidLeague) return ["se-1144778"];

  return [];
};

const getCarrierNamePreferenceScoping = (params: CarrierSetting): string[] => {
  // further scoping for Vessi show only 1 specific option with the fedex account
  const { packingOrder, isVessi, isProduction } = params;
  if (!packingOrder) return [];
  if (!isProduction || !isVessi) return [];
  const { isUSGround } = getVessiCarrierMapping(packingOrder);

  //mappings
  if (isUSGround) return ["fedex international ground"];
  return [];
};

const getShippingServiceText = (params: CarrierSetting): string => {
  const {
    packingOrder,
    isUserConfirmedPoBoxAddress,
    isVessi,
    isV1AcidLeague,
    isOddPieces,
  } = params;

  const isPoBox =
    OrderLib.isOrderAddressPoBox(packingOrder) || isUserConfirmedPoBoxAddress;
  if (isVessi) {
    const {
      isUSExpress,
      isUSGround,
      isCAGround,
      isCAExpress,
      isPurolator,
      hasMatchingShippingService,
    } = getVessiCarrierMapping(packingOrder);
    if (isPoBox) {
      return isPurolator
        ? "Canada Post 1-2 days"
        : "Canada Post, pick the cheapest";
    }
    if (!hasMatchingShippingService) return '"NO SHIPPING SERVICE"';
    if (isPurolator) return "FedEx Express 1-2 days";
    if (isUSGround) return "Pick the FedEx Ground option";
    if (isCAGround || isCAExpress || isUSExpress) {
      return "FedEx, pick the cheapest";
    }

    return "NO MATCHING SERVICE";
  }

  if (isOddPieces && isPoBox) {
    return "USPS, pick the cheapest";
  }
  if (isV1AcidLeague || isOddPieces) {
    return "FedEx, pick the cheapest";
  }

  return packingOrder?.shipping_service || "";
};

export const useCarrierCoping = (props: {
  packingOrder?: Order;
  ratesVersion?: number;
}) => {
  const { packingOrder, ratesVersion } = props;
  const apiUrl = process.env.REACT_APP_API || "";
  const isProduction = apiUrl.includes(".secondcloset.com");
  const isVessi = useRecoilValue(getIsVessi);
  const isAcidLeague = useRecoilValue(getIsAcidLeague);
  const isOddPieces = useRecoilValue(getIsOddPieces);
  const isUserConfirmedPoBoxAddress = useRecoilValue(
    isUserConfirmedPoBoxAddressState
  );

  const isV1AcidLeague = isAcidLeague && ratesVersion === 1;
  const params: CarrierSetting = {
    packingOrder,
    isProduction,
    isVessi,
    isOddPieces,
    isV1AcidLeague,
    isUserConfirmedPoBoxAddress,
  };

  return {
    carrierIDScoping: getCarrierIDScoping(params),
    carrierNamePreferenceScoping: getCarrierNamePreferenceScoping(params),
    shippingServiceText: getShippingServiceText(params),
  };
};

interface GetFilteredRatesParams {
  rates: Logistics.ShippingRate[];
  ratesVersion: number;
  isSignatureRate: boolean;
  carrierIDScoping: string[];
  carrierNamePreferenceScoping: string[];
}

export const getFilteredRates = (params: GetFilteredRatesParams) => {
  const {
    rates,
    ratesVersion,
    isSignatureRate,
    carrierIDScoping,
    carrierNamePreferenceScoping,
  } = params;

  const ratesWithScopedCarrierID =
    rates?.filter((rate) => {
      const isMatchingProviderScope = getProviderScopeFromRatesVersion(
        ratesVersion,
        isSignatureRate
      ).includes(rate.external_platform as ExternalCarriers);
      const isMatchingCarrierIDScope =
        carrierIDScoping.length > 0
          ? carrierIDScoping.includes(rate?.carrier?.external_id || "")
          : true;
      return isMatchingProviderScope && isMatchingCarrierIDScope;
    }) || [];

  const ratesWithScopedName = ratesWithScopedCarrierID.filter((r) => {
    const carrierServiceType = r?.service_type?.toLowerCase();
    return carrierNamePreferenceScoping?.find((name) =>
      carrierServiceType.includes(name)
    );
  });

  const filteredRates = ratesWithScopedName?.length
    ? ratesWithScopedName
    : ratesWithScopedCarrierID;

  return filteredRates.sort((a, b) => {
    if (a.external_platform === "swyft") return -1;
    if (b.external_platform === "swyft") return 1;
    if (a.price < b.price) return -1;
    if (a.price > b.price) return 1;
    if (a.carrier.carrier_code < b.carrier.carrier_code) return -1;
    if (a.carrier.carrier_code > b.carrier.carrier_code) return 1;
    return 0;
  });
};
