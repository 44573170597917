import { Fulfillment } from "@secondcloset/types";
import { getAPIFromDomain } from "../../lib/api";
import qs from "query-string";
type Product = Fulfillment.Product;

interface ProductIndexQuery {
  q: string; // sku or upc
  organization_id?: string;
}

export const fetchProductIndex = async (
  query: ProductIndexQuery
): Promise<Product[]> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/products?${qs.stringify(query)}`;
  const response = await api.get(url);
  return response.data as Product[];
};

export const fetchProductDetails = async (
  productID: string
): Promise<Product> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/products/${productID}`;
  const response = await api.get(url);
  return response.data as Product;
};
