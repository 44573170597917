import styled from "styled-components";
import { Button, Layout, Space } from "antd";

import { COLORS, DEVICE, LAYOUT } from "../../styles";

export const Header = styled(Layout.Header)`
  width: 100%;
  height: ${LAYOUT.HEADER_HEIGHT};
  padding: 0 0.5rem;
  z-index: 1000;
  display: flex;
  position: fixed;
  align-items: center;
  border-bottom: 1px solid ${COLORS.GREY_LIGHT};
  justify-content: space-evenly;
  background-color: ${COLORS.WHITE};
`;

export const Brand = styled(Space)`
  line-height: 1;

  @media ${DEVICE.md} {
    display: none;
  }
`;

export const Menu = styled(Space)`
  flex: 1;
  margin: 0 0.5rem;
`;

export const Profile = styled.div`
  line-height: 1;
`;

export const ProfileButton = styled(Button)`
  height: auto;
`;

export const NameTag = styled(Space)`
  margin-left: 0.5rem;
`;
