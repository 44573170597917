import { TagsOutlined } from "@ant-design/icons";
import { Fulfillment } from "@secondcloset/types";
import { Popover, Space, Tag, Typography } from "antd";
import React from "react";
import WithLabel from "../../../../../../components/WithLabel";
type Product = Fulfillment.Product;

interface Props {
  selectedProduct?: Product;
  onClose: () => void;
}

const SelectedProduct: React.FC<Props> = ({ selectedProduct, onClose }) => {
  if (!selectedProduct) return null;
  const { name, sku, upc } = selectedProduct;

  const content = (
    <Space direction="vertical">
      <Typography.Text type="secondary">SKU: {sku || "-"}</Typography.Text>
      {upc && (
        <Typography.Text type="secondary">UPC: {upc || "-"}</Typography.Text>
      )}
    </Space>
  );

  return (
    <WithLabel name="Selected Product">
      <div>
        <Popover content={content} title={name} placement="bottom">
          <Tag
            style={{ fontSize: "1rem", padding: "0.6rem" }}
            color="blue"
            closable
            onClose={onClose}
            icon={<TagsOutlined />}
          >
            {name}
          </Tag>
        </Popover>
      </div>
    </WithLabel>
  );
};

export default SelectedProduct;
