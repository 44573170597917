import { COLORS } from "./index";

const includeScrollBar = (): string => {
  const trackColor = COLORS.GREY_LIGHT;
  const thumbColor = COLORS.GREY_MID;
  const borderRadius = "10px";
  const thickness = "15px";

  return `
    &::-webkit-scrollbar {
      display: block;
      width: ${thickness};
      height: ${thickness};
    }
    &::-webkit-scrollbar-track {
      background: ${trackColor};
      border-radius: ${borderRadius};
    }
    &::-webkit-scrollbar-thumb {
      background: ${thumbColor};
      &:hover {
        background: ${COLORS.GREY};
      }
      border-radius: ${borderRadius};
    }
  `;
};

const includeBoxShadow = (): string => {
  const top = "0px";
  const left = "2px";
  const shadowBlur = "4px";
  const shadowColor = "rgba(0,0,0,0.3)";

  return `
    -webkit-box-shadow:${top} ${left} ${shadowBlur} ${shadowColor};
    -moz-box-shadow:${top} ${left} ${shadowBlur} ${shadowColor};
    box-shadow:${top} ${left} ${shadowBlur} ${shadowColor};
  `;
};

export { includeScrollBar, includeBoxShadow };
