import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 0 0;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 24px;
`;

export const SubHeader = styled.div`
  font-size: 20px;
`;
