import { Facility, FacilityCode } from "@secondcloset/fulfillment-utils";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import {
  Alert,
  Button,
  notification,
  Popconfirm,
  Space,
  Spin,
  Table,
  TablePaginationConfig,
  Tabs,
  Tooltip,
} from "antd";
import React, { useCallback, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { FixedType } from "rc-table/lib/interface";

// API
import {
  deleteFacilityOrganization,
  fetchFacilityOrganizations,
} from "../../../api/warehouse";
import {
  fetchPicksheetStats,
  generatePicksheetRequest,
} from "../../../api/warehouse/picksheet";

// Components
import PageContainer from "../../../components/PageContainer";
import NullSafeLink from "../../../components/Table/NullSafeLink";
import NullSafeText from "../../../components/Table/NullSafeText";
import OrganizationSearchBar from "./OrganizationSearchBar";

// Helpers
import {
  FacilityOrganizationWithStats,
  formatOrganizationsWithPicksheetStat,
  updateIdsListWithNewId,
  findAndRemoveIdFromList,
  MAX_PICKSHEETS_PER_REQUEST,
} from "./helpers";
import { DeleteOutlined } from "@ant-design/icons";
import { buildTablePagination } from "../../../components/Pagination/helper";
import useFacility from "../../../hooks/useFacility";
import { validateFacilityCode } from "../../../lib/validateFacilityCode";
import useUrlState from "@ahooksjs/use-url-state";

type FacilityOrganization = Warehouse.FacilityOrganization;
type PicksheetStats = Warehouse.PicksheetStats;

const { TabPane } = Tabs;

const PrintPicksheetsPage: React.FC = () => {
  const facilities = Facility.getFulfillmentFacilityList();
  const defaultFacility = facilities?.[0]?.code;
  const cachedFacility = useFacility().facility;
  const [error, setError] = useState("");
  const [orgsGeneratingPicksheets, setOrgsGeneratingPicksheets] = useState<
    string[]
  >([]);
  const [orgsBeingRemoved, setOrgsBeingRemoved] = useState<string[]>([]);

  const [{ organization, page, perPage, facility }, setUrlState] = useUrlState(
    {
      organization: undefined,
      page: 1,
      perPage: 10,
      facility: validateFacilityCode(cachedFacility) || defaultFacility,
    },
    { navigateMode: "replace" }
  );
  const { setFacility } = useFacility(setUrlState, setError);

  const paginatedFacilityOrganizations = useQuery(
    ["fetchFacilityOrganizations", organization, facility, page, perPage],
    () => {
      return fetchFacilityOrganizations({
        page,
        limit: perPage,
        facility: cachedFacility as FacilityCode,
        organization_name: organization,
        with_organization: true,
      });
    },
    {
      enabled: !!facility,
      onError: (error: string) => setError(error),
    }
  );

  const {
    refetch: refetchFacilityOrganizations,
    isLoading: isFetchingFacilityOrganizations,
  } = paginatedFacilityOrganizations;

  const paginatedPicksheetStats = useQuery(
    ["fetchPicksheetStats", facility, page, perPage],
    () => {
      return fetchPicksheetStats({
        limit: perPage,
        facility,
        page,
      });
    },
    { enabled: !!facility, onError: (error: string) => setError(error) }
  );

  const {
    refetch: refetchPicksheetStats,
    isLoading: isFetchingPicksheetStats,
  } = paginatedPicksheetStats;

  const { mutate: deleteFacilityOrg } = useMutation(
    (id: string) => deleteFacilityOrganization(id),
    {
      onMutate: (facilityOrgId: string) => {
        setError("");
        const newOrgsBeingRemoved = updateIdsListWithNewId(
          orgsBeingRemoved,
          facilityOrgId
        );
        setOrgsBeingRemoved(newOrgsBeingRemoved);
      },
      onError: (error: string, selectedFacilityOrgId: string) => {
        const newOrgsBeingRemoved = findAndRemoveIdFromList(
          orgsBeingRemoved,
          selectedFacilityOrgId
        );
        setOrgsBeingRemoved(newOrgsBeingRemoved);
        setError(error);
      },
      onSuccess: (_, deletedOrgId: string) => {
        const newOrgsBeingRemoved = findAndRemoveIdFromList(
          orgsBeingRemoved,
          deletedOrgId
        );
        setOrgsBeingRemoved(newOrgsBeingRemoved);
        handlePageRefresh();
      },
    }
  );

  const getPicksheetStats = useCallback((): PicksheetStats[] => {
    return paginatedPicksheetStats?.data?.data || [];
  }, [paginatedPicksheetStats]);

  const getFacilityOrganizations =
    useCallback((): FacilityOrganizationWithStats[] => {
      const tableData = formatOrganizationsWithPicksheetStat(
        paginatedFacilityOrganizations?.data?.items as FacilityOrganization[],
        getPicksheetStats() as PicksheetStats[]
      );
      return tableData || [];
    }, [paginatedFacilityOrganizations, getPicksheetStats]);

  const handlePageRefresh = () => {
    refetchFacilityOrganizations({
      cancelRefetch: true,
      throwOnError: true,
    });
    refetchPicksheetStats({ cancelRefetch: true, throwOnError: true });
  };

  const { mutate: createPicksheetRequest } = useMutation(
    (orgId: string) =>
      generatePicksheetRequest({
        organization_id: orgId,
        facility: facility as FacilityCode,
        filters: {
          allow_partial: "false",
          number_of_orders: `${MAX_PICKSHEETS_PER_REQUEST}`,
        },
      }),
    {
      onMutate: (orgId) => {
        const newOrgsGeneratingPicksheets = updateIdsListWithNewId(
          orgsGeneratingPicksheets,
          orgId
        );
        setOrgsGeneratingPicksheets(newOrgsGeneratingPicksheets);
        setError("");
      },
      onError: (e: string, selectedOrgId: string) => {
        setError(e);
        const newOrgsGeneratingPicksheets = findAndRemoveIdFromList(
          orgsGeneratingPicksheets,
          selectedOrgId
        );
        setOrgsGeneratingPicksheets(newOrgsGeneratingPicksheets);
      },
      onSuccess: (_, selectedOrgId: string) => {
        const newOrgsGeneratingPicksheets = findAndRemoveIdFromList(
          orgsGeneratingPicksheets,
          selectedOrgId
        );
        setOrgsGeneratingPicksheets(newOrgsGeneratingPicksheets);
        notification.success({
          message: `Pick sheet print request queued for processing`,
        });
        handlePageRefresh();
      },
    }
  );

  const handleTabChange = (selectedFacility: string) => {
    setError("");
    setUrlState({ organization: "", page: 1, facility: selectedFacility });
    setFacility(selectedFacility);
  };

  const handleRemoveFacilityOrganization = (id: string) => {
    deleteFacilityOrg(id);
  };

  const renderNullSafeLink = (record: FacilityOrganizationWithStats) => {
    return (
      <NullSafeLink
        href={`/picksheet-requests/${record?.organization_id}?facility=${facility}`}
        value={record?.organization?.name}
      />
    );
  };

  const renderNullSafeNumber = (count: number | null) => (
    <NullSafeText value={`${count || 0}`} />
  );

  const isOrgGeneratingPicksheet = (orgId) => {
    return orgsGeneratingPicksheets?.includes(orgId);
  };
  const isOrgBeingRemoved = (orgId) => {
    return orgsBeingRemoved?.includes(orgId);
  };

  const renderGeneratePicksheetsButton = (
    count: number,
    record: FacilityOrganizationWithStats
  ) => {
    const { organization_id } = record;
    const isButtonDisabled = count === 0 || isOrgBeingRemoved(record?.id);
    return (
      <Tooltip
        title={
          isButtonDisabled
            ? "Cannot generate pick sheet if there are no orders to fulfill, or if we are still printing a pick sheet."
            : null
        }
      >
        <Button
          onClick={() => createPicksheetRequest(organization_id || "")}
          type="primary"
          name={`Generate up to ${MAX_PICKSHEETS_PER_REQUEST} pick sheets`}
          disabled={isButtonDisabled}
          loading={isOrgGeneratingPicksheet(organization_id)}
        >
          Generate pick sheets
        </Button>
      </Tooltip>
    );
  };

  const renderDeleteOrganizationButton = (
    id: string,
    record: FacilityOrganizationWithStats
  ) => {
    const { organization_id } = record;
    return (
      <Popconfirm
        title="This will remove the organization from printing pick sheets. Are you sure?"
        okText="Yes"
        cancelText="No"
        disabled={isOrgGeneratingPicksheet(organization_id)}
        onConfirm={() => handleRemoveFacilityOrganization(id)}
      >
        <Button
          danger
          name="Delete"
          disabled={isOrgGeneratingPicksheet(organization_id)}
          loading={isOrgBeingRemoved(record?.id)}
          icon={<DeleteOutlined />}
        >
          Remove from list
        </Button>
      </Popconfirm>
    );
  };

  const tableColumns = [
    {
      key: "organization_id",
      title: "Organization",
      dataIndex: "organization_id",
      width: "20%",
      render: (orgId, record: FacilityOrganizationWithStats) =>
        renderNullSafeLink(record),
      fixed: "left" as FixedType,
    },
    {
      key: "ready_to_fulfill_count",
      title: "# Orders Ready to Fulfill",
      dataIndex: ["stats", "ready_to_fulfill_count"],
      width: "20%",
      render: (count) => renderNullSafeNumber(count),
    },
    {
      key: "processing_items_count",
      title: "# Orders In Processing",
      dataIndex: ["stats", "processing_items_count"],
      width: "20%",
      render: (count) => renderNullSafeNumber(count),
    },
    {
      key: "generate_pick_sheets_button",
      title: " ",
      dataIndex: ["stats", "ready_to_fulfill_count"],
      render: (count: number, record: FacilityOrganizationWithStats) =>
        renderGeneratePicksheetsButton(count, record),
    },
    {
      key: "delete_organization_button",
      title: " ",
      dataIndex: "id",
      render: (id: string, record: FacilityOrganizationWithStats) =>
        renderDeleteOrganizationButton(id, record),
    },
  ];

  const buildPagination = (): TablePaginationConfig => {
    const paginationInfo = paginatedFacilityOrganizations.data?.meta;

    return buildTablePagination({
      paginationInfo,
      showQuickJumper: true,
      showSizeChanger: true,
      onChange: (p: number, newPageSize?: number) => {
        if (newPageSize && newPageSize !== +perPage) {
          setUrlState({ page: 1, perPage: newPageSize });
        } else {
          setUrlState({ page: p });
        }
      },
    });
  };

  const renderFacilitiesTable = () => (
    <Table
      size="small"
      scroll={{ x: "max-content" }}
      rowKey="id"
      columns={tableColumns}
      pagination={buildPagination()}
      dataSource={getFacilityOrganizations()}
    />
  );

  const renderTabPanes = () => {
    const enabledFacilities =
      facilities?.filter((rawFacility) => rawFacility.fulfillmentEnabled) || [];
    return enabledFacilities.map((facility) => (
      <TabPane tab={facility.name} key={facility.code}>
        <OrganizationSearchBar
          facility={facility?.code}
          refetch={handlePageRefresh}
        />
        <Space size="large" direction="vertical" style={{ width: "100%" }}>
          <Alert
            message={`Clicking generate will generate up to ${MAX_PICKSHEETS_PER_REQUEST} pick sheets for the  merchant.`}
            type="info"
          />
          {renderFacilitiesTable()}
        </Space>
      </TabPane>
    ));
  };

  return (
    <PageContainer
      withPadding
      withFooter
      withHeader
      title="Pick Sheet Printing"
    >
      <Spin
        spinning={
          !!isFetchingFacilityOrganizations || !!isFetchingPicksheetStats
        }
      >
        <ErrorAlert error={error} />
        <Tabs
          onTabClick={handleTabChange}
          activeKey={facility}
          size="large"
          style={{ marginBottom: 32 }}
        >
          {renderTabPanes()}
        </Tabs>
      </Spin>
    </PageContainer>
  );
};

export default PrintPicksheetsPage;
