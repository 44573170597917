import React from "react";

// components
import { Button, Popover } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import * as S from "./styles";

interface Props {
  onUploadFile: (file: any) => any;
  isLoading: boolean;
}

const LocationCsvUploadButton: React.FC<Props> = ({
  onUploadFile,
  isLoading,
}) => {
  const downloadTemplateContent = () => {
    const onClick = (e) => {
      const header = [["code", "location_template_id", "facility", "type"], []];
      const csvContent = header.map((e) => e.join(",")).join("\n");
      const prefix = "data:text/csv;charset=utf-8,";
      const encodedUri = prefix + encodeURIComponent(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "location_upload.csv");
      document.body.appendChild(link); // Required for FF
      link.click();
      e.stopPropagation();
    };
    return (
      <S.TemplateButton onClick={onClick}>
        Download CSV template
      </S.TemplateButton>
    );
  };

  return (
    <S.Upload
      accept=".csv"
      action={(file) => onUploadFile(file)}
      disabled={isLoading}
      showUploadList={false}
      customRequest={() => {}}
    >
      <Popover content={downloadTemplateContent()}>
        <Button
          size="large"
          icon={<UploadOutlined />}
          block
          loading={isLoading}
        >
          Import CSV
        </Button>
      </Popover>
    </S.Upload>
  );
};

export default LocationCsvUploadButton;
