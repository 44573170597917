import { Button, Col, InputNumber, Table, Typography } from "antd";
import React from "react";

import SupplyIdentifiersField from "../../../../components/Table/SupplyIdentifiersField";
import { SupplyCart } from "../SupplyScanner";
import * as S from "./styles";

interface Props {
  total: number;
  loading?: boolean;
  cart: SupplyCart;
  onItemQuantityChange: (itemId: string, quantity: number) => void;
  preventEdit?: boolean;
}

const SupplyQuantityTable: React.FC<Props> = ({
  cart,
  loading,
  onItemQuantityChange,
  total,
  preventEdit,
}) => {
  const renderTableHeader = () => {
    return (
      <S.TableHeader>
        <Col>
          <Typography.Text strong type="secondary">
            Scanned Items
          </Typography.Text>
        </Col>
        <Col>
          <Typography.Text strong type="secondary">
            Total Item Count:{" "}
          </Typography.Text>
          <Typography.Text strong>{total}</Typography.Text>
        </Col>
      </S.TableHeader>
    );
  };

  const renderQuantityCell = ({ item }) => {
    if (preventEdit) return <span>{cart[item.id]?.quantity}</span>;
    return (
      <Col span={5}>
        <InputNumber
          min={1}
          inputMode="numeric"
          value={cart[item.id]?.quantity || 0}
          onChange={(v: number) => {
            onItemQuantityChange(item.id, v);
          }}
          onFocus={(e) => e.target.select()}
        />
      </Col>
    );
  };

  const renderItemActions = ({ item }) => {
    return (
      <Button
        type="link"
        danger
        onClick={() => onItemQuantityChange(item.id, 0)}
      >
        Remove
      </Button>
    );
  };
  const renderItemDetail = ({ item }) => {
    return (
      <SupplyIdentifiersField
        name={item?.name}
        code={item?.code}
        supplyId={item?.id}
      />
    );
  };

  const getTableColumns = () => {
    const columns = [
      {
        key: "id",
        title: "Item",
        width: 300,
        render: renderItemDetail,
      },
      {
        key: "quantity",
        title: "Quantity",
        width: 120,
        render: renderQuantityCell,
      },
    ];

    if (!preventEdit) {
      columns.push({
        key: "action",
        title: "Action",
        width: 120,
        render: renderItemActions,
      });
    }

    return columns;
  };

  return (
    <Table
      size="small"
      title={renderTableHeader}
      scroll={{ x: "max-content" }}
      loading={loading}
      bordered
      pagination={false}
      columns={getTableColumns()}
      dataSource={Object.values(cart)}
    />
  );
};

export default SupplyQuantityTable;
