import React, { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import ScannerInput from "../../../../components/ScannerInput";
import { ErrorAlert } from "@secondcloset/web-components";
import { createPallet } from "../../../../api/warehouse";
import { useMutation } from "react-query";
import { notification, Spin } from "antd";

interface Props {
  location: string;
  isVisible: boolean;
  onCancel: () => void;
  onSubmit: (palletId: string) => void;
}

const ScanPalletModal: React.FC<Props> = ({
  location,
  isVisible,
  onCancel,
  onSubmit,
}) => {
  const [palletId, setPalletId] = useState("");
  const [error, setError] = useState("");

  const { mutate: attachPalletId, isLoading: loading } = useMutation(
    (palletId: string) =>
      createPallet({ location_code: location }, { pallet_id: palletId }),
    {
      onError: setError,
      onSuccess: () => {
        notification.success({
          message: `Items succcessfully attached to the pallet ${palletId}`,
        });
        onSubmit(palletId);
        reset();
      },
    }
  );

  const reset = () => {
    setPalletId("");
    setError("");
  };

  return (
    <Modal
      title="Attach Pallet ID"
      visible={isVisible}
      onCancel={() => {
        reset();
        onCancel();
      }}
      onOk={() => attachPalletId(palletId)}
      destroyOnClose
      closable
    >
      <Spin spinning={loading}>
        <ErrorAlert error={error} />
        <div style={{ padding: 30, width: 300 }}>
          <ScannerInput
            label="Scan Pallet ID"
            value={palletId}
            onChange={setPalletId}
            autoFocus
          />
        </div>
      </Spin>
    </Modal>
  );
};

export default ScanPalletModal;
