import { Layout } from "antd";
import styled from "styled-components";
import { COLORS, LAYOUT } from "../../styles";

export const Footer = styled(Layout.Footer)`
  color: ${COLORS.GREY};
  height: ${LAYOUT.FOOTER_HEIGHT};
  padding: 0;
  display: flex;
  font-size: 10px;
  background: ${COLORS.WHITE};
  border-top: 1px solid ${COLORS.GREY_LIGHT};
  align-items: center;
  justify-content: center;
`;
