import React from "react";

// assets
import {
  CalendarOutlined,
  UnorderedListOutlined,
  LeftOutlined,
  UndoOutlined,
} from "@ant-design/icons";

// styles
import * as S from "./styles";

// components
import { Divider, Popconfirm, Button } from "antd";

// helpers
import { getStatusTagColor } from "@secondcloset/web-components/dist/components/fulfillment/ASNDetails/helpers";
import { startCase } from "lodash-es";

// types
import { Fulfillment } from "@secondcloset/types";

interface Props {
  disableReset: boolean;
  asnDetails?: Fulfillment.ASN;
  resetLoading: boolean;
  onViewTimelineClick?: () => void;
  onResetClick: () => void;
  onAsnDetailsClick: () => void;
}

const Card: React.FC<Props> = ({
  asnDetails,
  resetLoading,
  onViewTimelineClick,
  onResetClick,
  onAsnDetailsClick,
  disableReset,
}) => {
  const status = asnDetails?.status;

  const renderHeader = () => {
    return (
      <S.ButtonWrapper>
        <Button type="link" icon={<LeftOutlined />} onClick={onAsnDetailsClick}>
          ASN Details
        </Button>
      </S.ButtonWrapper>
    );
  };

  const renderCardHeader = () => {
    const asnNumber = asnDetails?.number;

    return (
      <S.CardSection>
        <S.HeaderSection>
          <S.FlexContainer>
            <S.ASNNumber>{asnNumber || "-"}</S.ASNNumber>
            <S.Tag color={getStatusTagColor(status)}>{startCase(status)}</S.Tag>
            <S.TimelineButtonWrapper>
              <Button type="link" onClick={onViewTimelineClick}>
                <UnorderedListOutlined /> View Timeline
              </Button>
            </S.TimelineButtonWrapper>
          </S.FlexContainer>
          <Popconfirm
            title="All product records will be cleared out. Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={onResetClick}
            disabled={status === "draft" || disableReset}
          >
            <Button
              danger
              type="primary"
              icon={<UndoOutlined />}
              disabled={status === "draft" || disableReset}
              loading={resetLoading}
            >
              Reset
            </Button>
          </Popconfirm>
        </S.HeaderSection>
      </S.CardSection>
    );
  };

  const renderCardShipmentDate = () => {
    return (
      <S.CardSection>
        <S.FlexContainer>
          <S.ShipmentWrap>
            <S.Label>Shipment Arrival Date</S.Label>
            <S.ArrivalDate>
              {asnDetails?.actual_arrival_date || "-"}
              <CalendarOutlined />
            </S.ArrivalDate>
          </S.ShipmentWrap>
        </S.FlexContainer>
      </S.CardSection>
    );
  };

  return (
    <S.Container>
      {renderHeader()}
      <S.CardWrapper>
        {renderCardHeader()}
        <Divider style={{ margin: 0 }} />
        {renderCardShipmentDate()}
      </S.CardWrapper>
    </S.Container>
  );
};

export default Card;
