import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  overflow: auto;
`;
export const DetailsWrap = styled.div`
  flex: 1;
  max-width: 75%;
`;
export const WarehouseActionWrap = styled.div`
  flex: 0.3;
  margin-left: 50px;
`;
