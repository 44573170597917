import React from "react";

// components
import PageContainer from "../../../components/PageContainer";
import { Result, Button } from "antd";

// hooks
import { useParams, useHistory } from "react-router";

// styles
import * as S from "./styles";

const CreateLocationSuccessPage: React.FC = () => {
  const params = useParams<{ locationCode: string }>();
  const history = useHistory();
  return (
    <PageContainer withHeader withFooter>
      <S.Container>
        <Result
          status="success"
          title="Successfully Created Location"
          subTitle={`Location: ${params.locationCode} has been saved to the database`}
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => history.push("/locations")}
            >
              Back to Locations
            </Button>,
            <Button key="buy" onClick={() => history.push("/locations/create")}>
              Create Another Location
            </Button>,
          ]}
        />
      </S.Container>
    </PageContainer>
  );
};

export default CreateLocationSuccessPage;
