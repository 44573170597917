import React from "react";

import { Container, Cube } from "./styles";

const AnimatedBackground: React.FC = () => {
  return (
    <Container>
      <Cube />
      <Cube />
      <Cube />
      <Cube />
      <Cube />
      <Cube />
    </Container>
  );
};

export default AnimatedBackground;
