import { SelectShipmentCarrierTypes } from "@secondcloset/web-components";
import { getAPIFromDomain } from "../../lib/api";

export const fetchRecommendedShippingMethod = async (
  orderItemIds: string[]
): Promise<SelectShipmentCarrierTypes.RecommendedShippingMethod> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/shipments/recommended_method`;
  const body = {
    order_item_ids: orderItemIds,
  };

  const response = await api.post(url, body);
  return response.data.recommended_shipping_method;
};
