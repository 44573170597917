import styled from "styled-components";

export const Container = styled.div`
  margin-top: 80px;
`;

export const SpinContainer = styled.div`
  display: flex;
  margin-top: 200px;
  justify-content: center;
  align-items: center;
`;

export const CenteredText = styled.span`
  padding-left: 30px;
`;
