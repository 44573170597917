import React from "react";

interface Props {
  value?: string;
}

const NullSafeText: React.FC<Props> = ({ value }) => (
  <span>{value || "-"}</span>
);

export default NullSafeText;
