// credit: https://codepen.io/BjornRombaut/pen/mOLGgX
import styled, { keyframes } from "styled-components";
import { COLORS } from "../../../styles";

const moveCube = keyframes`
  0% {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);   
    opacity: 1;
  }
  100% {
    transform: scale(20) rotate(960deg) translate(-50%, -50%); 
    opacity: 0;
  } 
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Cube = styled.div`
  position: absolute;
  top: 80vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  border: 1px solid ${COLORS.BLUE};
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  animation: ${moveCube} 8s ease-in forwards infinite;
  background: ${COLORS.BLUE};

  &:nth-child(2) {
    animation-delay: 1s;
    left: 25vw;
    top: 40vh;
  }

  &:nth-child(3) {
    animation-delay: 2s;
    left: 75vw;
    top: 50vh;
  }

  &:nth-child(4) {
    animation-delay: 3s;
    left: 90vw;
    top: 10vh;
  }

  &:nth-child(5) {
    animation-delay: 4s;
    left: 10vw;
    top: 85vh;
  }

  &:nth-child(6) {
    animation-delay: 5s;
    left: 50vw;
    top: 10vh;
  }
`;
