import { Common } from "@secondcloset/types";
type OrganizationType = Common.Organization;

const SC_ORG_IDS = {
  staging: "4164859e-043a-11ec-be89-bb79721ee49f",
  staging2: "d4f6920e-27a6-11ec-b8a1-0b9fb0b86248",
  staging3: "30dc199a-2847-11ec-a799-ab5705393f71",
  staging4: "87ed4be4-2e07-11ec-bae1-97b34d7825c5",
  demo: "1643ab44-204b-11ea-b32e-2fa88082c5b4",
  production: "c05f05c6-38c2-11ea-8042-9f66826ac6ab",
};

export default class Organization {
  static getSecondClosetOrgID = (): string => {
    const baseURL = process.env.REACT_APP_API;
    if (baseURL?.includes("staging")) {
      return SC_ORG_IDS.staging;
    } else if (baseURL?.includes("preprod")) {
      return SC_ORG_IDS.demo;
    } else if (baseURL?.includes("secondcloset")) {
      return SC_ORG_IDS.production;
    } else return SC_ORG_IDS.staging;
  };

  static isHamuq = (options: {
    organizationName?: string;
    organization?: OrganizationType;
  }): boolean => {
    return this.isOrg(options, "hamuq");
  };

  static isEndy = (options: {
    organizationName?: string;
    organization?: OrganizationType;
  }): boolean => {
    return this.isOrg(options, "endy");
  };

  static isVessi = (options: {
    organizationName?: string;
    organization?: OrganizationType;
  }): boolean => {
    return this.isOrg(options, "vessi");
  };

  static isAcidLeague = (options: {
    organizationName?: string;
    organization?: OrganizationType;
  }): boolean => {
    return this.isOrg(options, "acid league");
  };

  static isOddPieces = (options: {
    organizationName?: string;
    organization?: OrganizationType;
  }): boolean => {
    return this.isOrg(options, "odd pieces");
  };

  private static isOrg = (
    options: {
      organizationName?: string;
      organization?: OrganizationType;
    },
    targetName: string
  ): boolean => {
    const { organization, organizationName } = options;
    const orgName = organizationName || organization?.name || "";
    return orgName.toLowerCase()?.includes(targetName.toLowerCase());
  };
}
