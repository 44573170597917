import React, { CSSProperties } from "react";
import { Typography } from "antd";

import * as S from "./styles";

interface Props {
  name: string;
  style?: CSSProperties;
  direction?: "vertical" | "horizontal";
}

const WithLabel: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <S.Container style={props?.style} className={props.direction}>
      <Typography.Text className="label">{props?.name}</Typography.Text>
      {React.Children.only(props.children)}
    </S.Container>
  );
};

WithLabel.defaultProps = {
  direction: "vertical",
};

export default WithLabel;
