import { LoadingOutlined } from "@ant-design/icons";
import { Fulfillment, Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import {
  Button,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useMutation } from "react-query";

// API
import {
  fetchOrderIndexV2,
  fetchOrderDetails,
} from "../../../../api/fulfillment";
import { createReturn } from "../../../../api/warehouse";

//Helpers
import {
  findShipmentByTrackingNumber,
  findShipmentItemsByOrderId,
  checkIsReverseLogistics,
} from "./helpers";

type Order = Fulfillment.Order;
type Shipment = Fulfillment.Shipment;

interface Props {
  rtsModalVisible: boolean;
  setRtsModalVisible: (val: boolean) => void;
  refetchReturns: () => void;
}

const CreateReturnModal: React.FC<Props> = ({
  rtsModalVisible,
  setRtsModalVisible,
  refetchReturns,
}) => {
  const [error, setError] = useState<string | string[]>("");
  const [rtsTrackingNumber, setRtsTrackingNumber] = useState("");
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  const {
    mutate: searchOrders,
    isLoading: isSearchingForOrder,
    isSuccess: isSearchSuccess,
    reset: resetSearch,
  } = useMutation(fetchOrderIndexV2, {
    onSuccess: (data) => {
      const response = data?.data?.data;
      if (response) {
        setOrders(response);
      } else {
        setError("Orders not found");
      }
    },
    onError: (e: Error) => setError(e?.message as string),
  });

  const { mutateAsync: searchOrderById } = useMutation(fetchOrderDetails, {
    onSuccess: (response) => {
      if (response) {
        onFoundOrderSuccess(response as Order);
      } else {
        setError("Order not found");
      }
    },
    onError: (e: Error) => setError(e?.message as string),
  });

  const { mutate: createRTS, isLoading: isRTSCreateLoading } = useMutation(
    createReturn,
    {
      onSuccess: () => {
        refetchReturns();
        setRtsTrackingNumber("");
        setRtsModalVisible(false);
        setError("");
        setOrders([]);
        resetSearch();
      },
      onError: (e) => setError(e as string),
    }
  );

  const searchRtsOrder = () => {
    // Look for the original order in external DB
    setSelectedOrder(null);
    searchOrders({
      tracking_number: rtsTrackingNumber,
    });
  };

  const validateReturn = (items, shipment?: Shipment) => {
    if (!items?.length) {
      const isReverseLogistics = checkIsReverseLogistics(shipment);
      if (isReverseLogistics) {
        setError("Cannot create a return from reverse logistics package.");
        return false;
      }
    }
    return true;
  };

  const onFoundOrderSuccess = (foundOrder: Order) => {
    const foundShipmentAndLabel = findShipmentByTrackingNumber(
      foundOrder?.shipments || [],
      rtsTrackingNumber
    );

    const items = findShipmentItemsByOrderId(
      foundShipmentAndLabel?.shipment as Shipment,
      foundOrder as Order
    );

    const isReturnBodyValid = validateReturn(
      items,
      foundShipmentAndLabel.shipment
    );
    if (!isReturnBodyValid) return;

    createRTS({
      carrier: foundShipmentAndLabel?.shipment?.carrier?.name ?? "",
      tracking_url: foundShipmentAndLabel?.label?.tracking_url,
      shipping_from: {
        name: foundOrder?.address?.contact_name ?? "",
        phone_number: foundOrder?.address?.phone_number ?? "",
        address_1: foundOrder?.address?.address ?? "",
        address_2: foundOrder?.address?.apartment_number ?? "",
        country: foundOrder?.address?.country ?? "",
        city: foundOrder?.address?.city ?? "",
        province: foundOrder?.address?.province ?? "",
        postal_code: foundOrder?.address?.postal_code ?? "",
      },
      external_order_number: foundOrder?.external_order_number ?? "",
      type: "RTS" as Warehouse.ReturnType,
      tracking_number: rtsTrackingNumber,
      organization_id: foundOrder?.organization.id ?? "",
      items,
    });
  };

  const createRTSReturn = () => {
    if (!selectedOrder?.id) return;

    // fetch order details by ID
    searchOrderById(selectedOrder.id);
  };

  const handleCancel = () => {
    resetSearch();
    setOrders([]);
    setSelectedOrder(null);
    setRtsTrackingNumber("");
    setRtsModalVisible(false);
    setError("");
  };

  const renderScanner = () => (
    <Space direction="vertical" style={{ width: "100%", marginBottom: 20 }}>
      <Typography.Text strong>
        Scan the package tracking number:
      </Typography.Text>
      <Input.Search
        allowClear
        disabled={isSearchingForOrder}
        enterButton="Search"
        size="large"
        onPressEnter={searchRtsOrder}
        onChange={(e) => {
          setRtsTrackingNumber(e?.target?.value?.trim());
        }}
        onSearch={searchRtsOrder}
      />
    </Space>
  );

  const renderSpinner = () => (
    <Row justify="center">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </Row>
  );

  const renderOrders = () => (
    <Space direction="vertical">
      <Typography.Text strong>
        Orders found with this tracking number:
      </Typography.Text>
      {orders?.length === 0 && "None found"}
      <Radio.Group
        buttonStyle="solid"
        onChange={(e) => {
          setSelectedOrder(e?.target?.value);
        }}
      >
        {orders?.map((each: Order) => (
          <Radio key={each?.id} value={each as Order}>
            <Typography.Text>#{each?.external_order_number}</Typography.Text>{" "}
            <Typography.Text type="secondary">
              - {each?.organization.name}
            </Typography.Text>
          </Radio>
        ))}
      </Radio.Group>
    </Space>
  );

  const renderModalFooter = () => [
    <Button key="cancel" onClick={handleCancel}>
      Cancel
    </Button>,
    <Button
      key="submit"
      type="primary"
      onClick={createRTSReturn}
      disabled={!selectedOrder || isRTSCreateLoading || isSearchingForOrder}
    >
      Create RMA
    </Button>,
  ];

  return (
    <Modal
      title="Add RTS Order"
      visible={rtsModalVisible}
      destroyOnClose
      width={690}
      onCancel={handleCancel}
      okButtonProps={{
        disabled:
          !rtsTrackingNumber || isRTSCreateLoading || isSearchingForOrder,
      }}
      footer={renderModalFooter()}
    >
      <ErrorAlert error={error} />
      {renderScanner()}
      {(isSearchingForOrder || isRTSCreateLoading) && renderSpinner()}
      {isSearchSuccess && renderOrders()}
    </Modal>
  );
};

export default CreateReturnModal;
