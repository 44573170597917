import styled from "styled-components";
import { COLORS } from "../../../../../styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  margin: 0 24px 0 0;
  min-height: 32.5px;
`;

interface MenuItemTextProps {
  bold?: boolean;
}

export const MenuItemText = styled.div<MenuItemTextProps>`
  font-weight: ${(props) => (props.bold ? 600 : 400)};
  color: ${COLORS.BLUE};
`;

export const DropdownSelectedText = styled.div`
  color: ${COLORS.BLUE};
  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  position: relative;

  &:not(:last-child) {
    margin: 0 0 8px 0;
  }
`;

interface ColumnProps {
  flex?: number;
  justifyContent?: string;
}

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex: ${(props) => (props.flex ? props.flex : "")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "initial"};
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90px;

  &:not(:last-child) {
    margin: 0 16px 0 0;
  }
`;

export const LabelText = styled.div`
  font-size: 14px;
  font-weight: 300;
`;
