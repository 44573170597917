import React from "react";
import { Link } from "react-router-dom";

interface Props {
  code?: string;
}

const ManualItemsDetailsNavigationLink: React.FC<Props> = ({ code }) => {
  return code ? (
    <Link to={`/manual-items/${code}`}>{code}</Link>
  ) : (
    <span>-</span>
  );
};
export default ManualItemsDetailsNavigationLink;
