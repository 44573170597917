import styled from "styled-components";

import { COLORS } from "../../../styles";

export const Label = styled.div`
  margin: 10px 0;

  &::before {
    content: "*";
    color: ${COLORS.RED};
    font-size: 10px;
    margin-right: 5px;
  }
`;
