import styled from "styled-components";
import { DEVICE } from "../../../styles";

export const Container = styled.div``;

export const HeaderButtonsWrap = styled.div`
  margin-top: -65px;
  width: 100%;
  justify-content: flex-end;
  display: flex;
  .ant-btn {
    margin-left: 10px;
  }

  @media ${DEVICE.md} {
    margin-top: 0px;
    justify-content: flex-start;
    .ant-btn {
      margin-right: 10px;
      margin-left: 0px;
    }
  }
`;

export const AlertBoxWrap = styled.div`
  margin-top: 20px;
`;

export const FullLocationCode = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 40px;
`;
