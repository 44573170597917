import React, { useState } from "react";
import { useMutation } from "react-query";
import { CheckOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Col, Divider, notification, Row, Space } from "antd";
import { ErrorAlert } from "@secondcloset/web-components";
import { Warehouse } from "@secondcloset/types";

import {
  batchUpdateLocation,
  fetchItemByIdentifier,
  LookupResult,
} from "../../../api/warehouse";
import { useSoundEffect } from "../../../hooks/useSoundEffect";
import ScannerInput from "../../../components/ScannerInput";
import WithLabel from "../../../components/WithLabel";
import QuantityTable from "./QuantityTable";
import { removeWhitespace } from "../../../lib/removeWhitespace";

type Product = Warehouse.Product;
type ManualItem = Warehouse.ManualItem;

export interface ScanCart {
  [itemID: string]: {
    key: string;
    type: string;
    item: ScanItem;
    quantity: number;
  };
}

export type ScanItem = ManualItem | Product;

interface Props {
  setContainerLoading: (loading: boolean) => void;
}

const BatchMoveItemsPage: React.FC<Props> = () => {
  const { playSuccessSound } = useSoundEffect();
  const [cart, setCart] = useState<ScanCart>({});
  const [idScanned, setIdScanned] = useState("");
  const [toLocation, setToLocation] = useState("");
  const [fromLocation, setFromLocation] = useState("");
  const [error, setError] = useState("");

  const { mutate: moveItems, isLoading } = useMutation(batchUpdateLocation, {
    onSuccess: () => {
      notification.success({ message: "Item relocation successful" });
      setError("");
      resetAllInputs();
      playSuccessSound();
    },
    onError: (e: string) => setError(e),
  });

  const { mutate: lookupItem, isLoading: isLooking } = useMutation(
    (identifier: string) => fetchItemByIdentifier(identifier?.trim()),
    {
      onSuccess: (result: LookupResult) => {
        addNewItem(result);
        setIdScanned("");
        setError("");
      },
      onError: (error: string) => {
        setError(error);
      },
    }
  );

  const addNewItem = (result: LookupResult) => {
    const item = result.item;
    const type = result.type;
    setCart({
      ...cart,
      [item.id]: {
        key: item.id,
        type,
        item,
        quantity: 1,
      },
    });
  };

  const resetAllInputs = () => {
    setCart({});
    setIdScanned("");
    setToLocation("");
    setFromLocation("");
    setError("");
  };

  const getTotalItemQuantity = (): number => {
    return Object.values(cart).reduce((acc, cv) => acc + cv.quantity, 0);
  };

  const onIdScanInputChanged = (itemId: string) => {
    setIdScanned(itemId);
  };

  const onEnterScannedIdInput = (identifier: string) => {
    // SKU, UPC, Manual Item code, etc...
    const itemId = identifier.trim();
    if (!itemId.trim()) return;

    const itemFound = Object.values(cart).find((cartItem) => {
      const product = cartItem.item as Product;
      const manualItem = cartItem.item as ManualItem;

      if (product.sku || product.upc)
        return itemId === product.sku || itemId === product.upc;
      if (manualItem.code) return itemId === manualItem.code;
    });

    if (!itemFound) return lookupItem(identifier);
    if (itemFound.type === "product") {
      itemFound.quantity++;
      setCart({ ...cart, [itemFound.item.id]: itemFound });
      setError("");
    } else {
      setError(`Cannot add more than one "${identifier}"`);
    }
    setIdScanned("");
  };

  const onItemQuantityChange = (identifier: string, quantity: number) => {
    const currentCart = { ...cart };
    if (quantity > 0) {
      const currentItem = currentCart[identifier];
      currentItem.quantity = quantity;
    } else {
      delete currentCart[identifier];
    }
    setCart(currentCart);
    setError("");
  };

  const onClickConfirm = () => {
    const hasNoItemsInCart = getTotalItemQuantity() === 0;
    if (hasNoItemsInCart) return setError("Please scan items");
    if (!toLocation)
      return setError(
        'The "To location" cannot be empty, please scan/type a valid location code.'
      );

    const items = Object.entries(cart).reduce((acc, [key, cartItem]) => {
      const mapping = { quantity: cartItem.quantity };
      const fieldName =
        cartItem.type === "product" ? "product_id" : "manual_item_id";
      mapping[fieldName] = key;

      acc.push(mapping);
      return acc;
    }, [] as any);

    setError("");
    moveItems({
      to_location_code: removeWhitespace(toLocation).toUpperCase(),
      from_location_code: removeWhitespace(fromLocation).toUpperCase(),
      items,
    });
  };

  const renderItemScannerInput = () => {
    return (
      <WithLabel name="Scan Item">
        <ScannerInput
          value={idScanned}
          onEnter={onEnterScannedIdInput}
          onChange={onIdScanInputChanged}
          autoFocus
        />
      </WithLabel>
    );
  };

  const renderFromScannerInput = () => {
    return (
      <WithLabel name="From Location (Optional)">
        <ScannerInput
          allowClear
          value={fromLocation}
          onChange={(l) => setFromLocation(l.trim())}
        />
      </WithLabel>
    );
  };

  const renderToScannerInput = () => {
    return (
      <WithLabel name="To Location">
        <ScannerInput
          allowClear
          value={toLocation}
          onChange={(l) => setToLocation(l.trim())}
        />
      </WithLabel>
    );
  };

  const renderResetButton = () => {
    return (
      <Button
        type="primary"
        size="large"
        icon={<UndoOutlined />}
        block
        danger
        ghost
        disabled={getTotalItemQuantity() === 0}
        onClick={() => resetAllInputs()}
      >
        Clear all
      </Button>
    );
  };

  const renderConfirmButton = () => {
    return (
      <Button
        block
        icon={<CheckOutlined />}
        type="primary"
        size="large"
        disabled={!Object.keys(cart).length || !toLocation}
        onClick={() => onClickConfirm()}
        loading={isLoading || isLooking}
      >
        Confirm
      </Button>
    );
  };

  const renderTopToolbar = () => {
    return (
      <Row justify="space-between" align="bottom" gutter={[8, 8]}>
        <Col xs={24} lg={16}>
          {renderItemScannerInput()}
        </Col>
        <Col xs={24} lg={3}>
          {renderResetButton()}
        </Col>
      </Row>
    );
  };

  const renderBottomToolbar = () => {
    return (
      <>
        <Row justify="space-between" align="bottom" gutter={[8, 8]}>
          <Col xs={24} lg={8}>
            {renderFromScannerInput()}
          </Col>
          <Col xs={24} lg={8}>
            {renderToScannerInput()}
          </Col>
        </Row>
        <Divider />
        <Row justify="center" align="bottom" gutter={[8, 8]}>
          <Col xs={24} lg={8}>
            {renderConfirmButton()}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Space style={{ width: "100%" }} direction="vertical" size="large">
      {renderTopToolbar()}
      <ErrorAlert error={error} />
      <QuantityTable
        cart={cart}
        total={getTotalItemQuantity()}
        onItemQuantityChange={onItemQuantityChange}
      />
      {renderBottomToolbar()}
    </Space>
  );
};

export default BatchMoveItemsPage;
