import React, { useContext, useState } from "react";
import moment from "moment";
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";

import WithLabel from "../../components/WithLabel";

import Barcode from "../../lib/barcode";
import { PACKAGE_STATUS_OPTIONS } from "./helper";
import { COLORS } from "../../styles";

interface SearchProps {
  form?: FormInstance;
  loading?: boolean;
  onClear?: () => void;
  onSearch?: (val: any) => void;
  showAdvanced?: boolean;
  toggleAdvanced?: () => void;
}

const SearchBarContext = React.createContext<SearchProps>({});

const CourierSearchField: React.FC = () => {
  const searchBarContext = useContext(SearchBarContext);

  return (
    <WithLabel name="Courier">
      <Form.Item name="courier" style={{ marginBottom: 0 }}>
        <Input
          size="large"
          disabled={searchBarContext.loading}
          placeholder="e.g. FedEx"
          autoCapitalize="off"
        />
      </Form.Item>
    </WithLabel>
  );
};

const TrackingNumSearchField: React.FC = () => {
  const searchBarContext = useContext(SearchBarContext);

  const onChange = (text: string) => {
    const cPost = Barcode.Decoder.decodeCPBarcode(text);
    const fedEx = Barcode.Decoder.decodeFedExBarcode(text);
    if (!cPost && !fedEx) return;

    searchBarContext.form?.setFieldsValue({
      trackingNumber: fedEx || cPost || text,
    });
  };

  return (
    <WithLabel name="Tracking Number">
      <Form.Item name="trackingNumber" style={{ marginBottom: 0 }}>
        <AutoComplete
          size="large"
          disabled={searchBarContext.loading}
          onChange={onChange}
          placeholder="e.g. D431934100000000632001"
        />
      </Form.Item>
    </WithLabel>
  );
};

const DateRangeSelector: React.FC = () => {
  const searchBarContext = useContext(SearchBarContext);

  return (
    <WithLabel name="Handover Dates">
      <Form.Item name={"dateRange"} style={{ marginBottom: 0 }}>
        <DatePicker.RangePicker
          size="large"
          onChange={() => {
            searchBarContext?.onSearch?.(
              searchBarContext.form?.getFieldsValue()
            );
          }}
          disabled={searchBarContext.loading}
          disabledDate={(current) => current && current > moment().endOf("day")}
          popupStyle={{ width: "auto !important" }}
        />
      </Form.Item>
    </WithLabel>
  );
};

const StatusSelector: React.FC = () => {
  const searchBarContext = useContext(SearchBarContext);

  return (
    <WithLabel name="Status">
      <Form.Item name="status" style={{ marginBottom: 0 }}>
        <Select
          size="large"
          onSelect={() => {
            searchBarContext?.onSearch?.(
              searchBarContext.form?.getFieldsValue()
            );
          }}
          loading={searchBarContext.loading}
          disabled={searchBarContext.loading}
          placeholder="Status"
        >
          <Select.Option key={0} value={"all"}>
            All
          </Select.Option>
          {PACKAGE_STATUS_OPTIONS.map((e) => (
            <Select.Option key={e.key} value={e.value}>
              {e.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </WithLabel>
  );
};

const AdvancedSearchFilter: React.FC = () => (
  <div
    style={{
      padding: 12,
      borderRadius: 6,
      border: `1px solid ${COLORS.PANEL_BORDER}`,
      backgroundColor: COLORS.PANEL_BG,
    }}
  >
    <Row align="bottom" gutter={[8, 16]}>
      <Col xs={24} lg={4}>
        <StatusSelector />
      </Col>
      <Col xs={24} lg={11}>
        <DateRangeSelector />
      </Col>
    </Row>
  </div>
);

const DefaultSearchFilter: React.FC = () => {
  const searchBarContext = useContext(SearchBarContext);

  return (
    <Row justify="start" align="bottom" gutter={[8, 16]}>
      <Col xs={24} lg={7}>
        <CourierSearchField />
      </Col>
      <Col xs={24} lg={8}>
        <TrackingNumSearchField />
      </Col>
      <Col xs={24} lg={3}>
        <Button
          type="primary"
          size="large"
          icon={<SearchOutlined />}
          block
          loading={searchBarContext.loading}
          htmlType="submit"
        >
          Search
        </Button>
      </Col>
      <Col xs={24} lg={3}>
        <Button
          size="large"
          type={searchBarContext.showAdvanced ? "primary" : "default"}
          icon={<FilterOutlined />}
          block
          ghost={searchBarContext.showAdvanced}
          loading={searchBarContext.loading}
          disabled={searchBarContext.loading}
          onClick={() => searchBarContext?.toggleAdvanced?.()}
        >
          Filter
        </Button>
      </Col>
      <Col xs={24} lg={3}>
        <Button
          size="large"
          block
          loading={searchBarContext.loading}
          disabled={searchBarContext.loading}
          onClick={() => searchBarContext?.onClear?.()}
        >
          Clear
        </Button>
      </Col>
    </Row>
  );
};

const SearchBar: React.FC<SearchProps> = (props) => {
  const [showAdvancedFilter, setShowAdvancedFilter] = useState<boolean>(true);

  return (
    <SearchBarContext.Provider
      value={{
        form: props.form,
        loading: props.loading,
        onClear: props.onClear,
        onSearch: props.onSearch,
        showAdvanced: showAdvancedFilter,
        toggleAdvanced: () => setShowAdvancedFilter(!showAdvancedFilter),
      }}
    >
      <Form form={props.form} onFinish={props.onSearch}>
        <Space size="large" direction="vertical" style={{ width: "100%" }}>
          <DefaultSearchFilter />
          {showAdvancedFilter && <AdvancedSearchFilter />}
        </Space>
      </Form>
    </SearchBarContext.Provider>
  );
};

export default SearchBar;
