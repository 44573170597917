// components
import React from "react";
import { Table, TablePaginationConfig } from "antd";
import { FixedType } from "rc-table/lib/interface";

import NullSafeLink from "../../../../components/Table/NullSafeLink";
import ItemCountFooter from "../../../../components/Table/ItemCountFooter";
import ProductIdentifiersField from "../../../../components/Table/ProductIdentifiersField";

// types
import {
  LocationItemByLocationSku,
  PaginationInfo,
} from "../../../../api/warehouse/commonInterfaces";
import { getLocationTypeLabel } from "../helper";
import { buildTablePagination } from "../../../../components/Pagination/helper";

interface Props {
  paginationInfo?: PaginationInfo;
  onPageChange?: (page: number, newPageSize?: number) => void;
  locationItems: LocationItemByLocationSku[];
  loading: boolean;
}

const ItemLocationsTable: React.FC<Props> = ({
  paginationInfo,
  onPageChange,
  locationItems,
  loading,
}) => {
  const renderCode = (code: string) => {
    return <NullSafeLink href={`/locations/${code}`} value={code} />;
  };

  const renderItemDetail = (item) => {
    return (
      <ProductIdentifiersField
        sku={item.detail?.sku}
        upc={item.detail?.upc}
        name={item.detail?.name}
        code={item.detail?.code}
        customer={item.detail?.customer_name}
        productId={item.detail?.product_id}
      />
    );
  };

  const buildTableColumns = () => {
    return [
      {
        key: "id",
        title: "Item",
        width: 300,
        render: renderItemDetail,
        fixed: "left" as FixedType,
      },
      {
        key: "location_code",
        title: "Location",
        width: 200,
        dataIndex: "location_code",
        render: renderCode,
      },
      {
        key: "facility",
        title: "Facility",
        width: 100,
        dataIndex: "facility",
        render: (value: string) => value?.toUpperCase(),
      },
      {
        key: "location_type",
        title: "Type",
        width: 130,
        dataIndex: "location_type",
        render: (value: string) => getLocationTypeLabel(value),
      },
      {
        key: "num_of_items",
        title: "Qty",
        width: 120,
        dataIndex: "num_of_items",
      },
    ];
  };

  const buildPagination = (): TablePaginationConfig => {
    return buildTablePagination({
      paginationInfo,
      onChange: onPageChange,
      showQuickJumper: true,
      showSizeChanger: true,
    });
  };

  const renderTableFooter = () => (
    <ItemCountFooter count={paginationInfo?.totalItems || 0} />
  );

  return (
    <Table
      size="small"
      scroll={{ x: "max-content" }}
      rowKey={(r: any, index) => {
        return `${r.location_code}-${r.identifier}-${index}`;
      }}
      loading={loading}
      bordered
      footer={renderTableFooter}
      columns={buildTableColumns()}
      dataSource={locationItems}
      pagination={buildPagination()}
    />
  );
};

export default ItemLocationsTable;
