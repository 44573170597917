import styled from "styled-components";

export const FilterGroup = styled.div`
  padding: 1rem;
  border-radius: 6px;
  background-color: #fafafa;

  .ant-btn,
  .ant-picker,
  .ant-select {
    width: 100%;
  }
`;
