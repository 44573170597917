import { Tag as AntdTag } from "antd";
import styled from "styled-components";
import colors from "../../../../styles/colors";

export const Container = styled.div`
  margin-bottom: 25px;
`;

export const CardWrapper = styled.div`
  background: ${colors.WHITE};
  border: 1px solid ${colors.GREY_LIGHT};
  border-radius: 5px;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  margin: 1em 0;
  .ant-btn-link {
    padding: 0;
  }
`;

export const HeaderSection = styled(FlexContainer)`
  margin: 20px 25px;
  justify-content: space-between;
  .ant-btn-link {
    padding: 0;
  }
`;

export const CardSection = styled.div`
  width: 100%;
`;

export const ASNNumber = styled.div`
  font-weight: 600;
  font-size: 20px;
  margin-right: 10px;
`;

export const Tag = styled(AntdTag)`
  margin: 0 20px;
`;

export const ShipmentWrap = styled.div`
  margin: 20px 25px;
  flex-direction: column;
  justify-content: center;
`;

export const Label = styled.div`
  font-size: 0.7rem;
  color: ${colors.GREY};
  font-weight: 500;
  margin-bottom: 10px;
`;

export const ArrivalDate = styled.div`
  margin-right: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  width: 200px;
  border: 1px solid ${colors.GREY_LIGHT};
`;

export const TimelineButtonWrapper = styled.div`
  .ant-btn {
    font-size: 12px;
    font-weight: 500;
  }
`;
