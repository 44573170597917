import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { useQuery } from "react-query";
import { Alert, Button, Input, Spin } from "antd";
import { SelectShipmentCarrierTypes } from "@secondcloset/web-components";

// API
import {
  fetchOrderDetails,
  fetchShipmentDetails,
} from "../../../api/fulfillment";
import Modal from "../../../components/Modal";

//components
import {
  packingFlowOrderState,
  packingFlowShipmentState,
  packingFlowShippingMethodState,
  packingFlowStepState,
  shipmentProductTrackingListState,
  Step,
} from "../../../recoil/packingFlow/atoms";
import { useResetPackingFlow } from "../../../recoil/packingFlow/helpers";

//styles
import {
  Container,
  Instruction,
  Label,
  Message,
  ModalTitle,
  ScannerInput,
} from "./styles";

const { RecommendedShippingMethod } = SelectShipmentCarrierTypes;

const ScanShipmentPage: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { shipment_id } = useParams<{
    shipment_id?: string;
  }>();
  const [shipmentID, setShipmentID] = useState(shipment_id || "");
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatchReset = useResetPackingFlow();
  const dispatchResetTrackingList = useResetRecoilState(
    shipmentProductTrackingListState
  );
  const dispatchSetStep = useSetRecoilState(packingFlowStepState);
  const dispatchSetShippingMethod = useSetRecoilState(
    packingFlowShippingMethodState
  );
  const [packingOrder, dispatchSetPackingOrder] = useRecoilState(
    packingFlowOrderState
  );
  const [packingShipment, dispatchSetShipment] = useRecoilState(
    packingFlowShipmentState
  );

  const history = useHistory();
  const [orderID, setOrderID] = useState("");
  const [willLookupShipment, setWillLookupShipment] = useState(!!shipmentID);
  const shipmentDetails = useQuery(
    "shipment",
    () => fetchShipmentDetails(shipmentID),
    {
      enabled: willLookupShipment,
      retry: false,
      cacheTime: 0,
      onSuccess: (shipment) => {
        dispatchSetShipment(shipment);
        setOrderID(shipment?.external_order_id || "");
      },
    }
  );
  const orderDetails = useQuery("order", () => fetchOrderDetails(orderID), {
    enabled: !!orderID,
    onSuccess: async (order) => {
      /**
       * Thanks to Joseph's solution:
       * This is making sure the setState is done
       * before navigating away
       */
      return await new Promise((resolve) =>
        resolve(dispatchSetPackingOrder(order))
      ).then(() => history.push("/pack-shipment"));
    },
  });

  const error = useMemo(() => {
    type ErrorType = string | { message: string } | undefined;
    const e = (shipmentDetails.error || orderDetails.error) as ErrorType;
    if (!e) return "";
    return typeof e === "string" ? e : e.message.replace("Error: ", "");
  }, [shipmentDetails.error, orderDetails.error]);

  const isFetching = shipmentDetails.isFetching || orderDetails.isFetching;

  useEffect(() => {
    dispatchReset();
    dispatchResetTrackingList();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) return;

    if (packingShipment?.shipping_method_type !== "untracked_shipment") {
      const freightFlag = packingShipment?.freight;
      if (typeof freightFlag !== "boolean") {
        // when freight flag is null/undefined then shipment method is undecided
        dispatchSetStep(Step.selectShipmentMethod);
      } else {
        const { freight, parcel } = RecommendedShippingMethod;
        dispatchSetShippingMethod(freightFlag ? freight : parcel);
        dispatchSetStep(
          freightFlag ? Step.selectPackageType : Step.selectShipmentItem
        );
      }
    }
    //eslint-disable-next-line
  }, [packingShipment, packingOrder]);

  const focusInput = () => {
    inputRef.current?.focus();
  };

  const renderModal = () => {
    return (
      <Modal
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          focusInput();
        }}
        title="MANUAL INPUT"
        onOk={() => {
          setIsModalVisible(false);
          setWillLookupShipment(true);
        }}
        isDoneButtonDisabled={!shipmentID}
      >
        <ModalTitle>Enter a Shipment ID to proceed.</ModalTitle>
        <Label>SHIPMENT ID</Label>
        <Input
          value={shipmentID}
          placeholder="XXXXXXXXXX"
          maxLength={10}
          size="large"
          prefix="SHID-"
          onChange={(e) => {
            setWillLookupShipment(false);
            setShipmentID(e.target.value.trim());
          }}
          autoFocus
          onPressEnter={() => {
            setIsModalVisible(false);
            setWillLookupShipment(true);
          }}
          autoCapitalize="off"
        />
      </Modal>
    );
  };

  const onScan = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const scannedShipmentID = target ? target.value.trim() : "";
    if (e.key.toLowerCase() === "enter") {
      setShipmentID(scannedShipmentID);
      setWillLookupShipment(true);
      if (target) target.value = ""; // resets scanner value
    }
  };

  const renderScannerInput = () => {
    return (
      <ScannerInput
        ref={inputRef}
        onKeyUp={onScan}
        onBlur={() => {
          if (!isModalVisible) focusInput();
        }}
        autoFocus
      />
    );
  };

  return (
    <Container>
      {renderScannerInput()}
      {error && (
        <Alert
          style={{ marginBottom: 20 }}
          message="Error"
          description={error}
          type="error"
          showIcon
        />
      )}
      <Message>SCAN THE PICK SHEET QR CODE</Message>
      <Instruction>
        {isFetching ? <Spin /> : "SCANNER LISTENING..."}
      </Instruction>
      <Button
        type="primary"
        size="large"
        onClick={() => setIsModalVisible(true)}
      >
        MANUAL INPUT
      </Button>
      {renderModal()}
    </Container>
  );
};

export default ScanShipmentPage;
