import { atom } from "recoil";
import { Common } from "@secondcloset/types";
type User = Common.User;

export const userState = atom<User | null>({
  key: "userState",
  default: null,
});

export const userPermissionsState = atom<string[] | null>({
  key: "userPermissionsState",
  default: null,
});
