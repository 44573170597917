import React, { useState } from "react";
import { useHistory } from "react-router";
import { useMutation } from "react-query";
import {
  Input,
  Form,
  FormInstance,
  Button,
  Row,
  Col,
  PageHeader,
  Card,
  DatePicker,
  Divider,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { CheckOutlined, UndoOutlined } from "@ant-design/icons";
import { ErrorAlert } from "@secondcloset/web-components";
import { Facility } from "@secondcloset/fulfillment-utils";
import { debounce, startCase } from "lodash-es";
import { Common, Fulfillment } from "@secondcloset/types";

// API
import { SupplyCart } from "../SupplyScanner/SupplyScanner";
import { fetchOrganizations } from "../../../api/fulfillment/organization";
import { createSpecialProject } from "../../../api/warehouse";
import { fetchASNIndex } from "../../../api/fulfillment";

// Hooks and helpers
import {
  permissionsEditWarning,
  SpecialProjectTypes,
  validateDates,
} from "../helpers";
import { useValidateSpecialProjectPermissions } from "../../../recoil/user/helpers";

// Components
import PageContainer from "../../../components/PageContainer";
import SupplyScanner from "../SupplyScanner";
import WithLabel from "../../../components/WithLabel";

type Organization = Common.Organization;
type ASN = Fulfillment.ASN;

const FacilitySelector: React.FC = () => (
  <Form.Item
    name="facility"
    label="Facility"
    rules={[{ required: true, message: "Facility is required!" }]}
  >
    <Select size="large" placeholder={"Select Facility"}>
      {Facility.getFulfillmentFacilityList().map((f) => (
        <Select.Option value={f.code} key={f.code}>
          {f.code?.trim().toUpperCase()}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);

const StartDateSelector: React.FC = () => (
  <Form.Item
    name="startDate"
    label="Start date"
    rules={[{ required: true, message: "Start date cannot be empty" }]}
  >
    <DatePicker
      showTime={{ showHour: true, showMinute: true }}
      format="YYYY-MM-DD HH:mm"
      style={{ width: "100%" }}
    />
  </Form.Item>
);

const EndDateSelector: React.FC = () => (
  <Form.Item
    name="endDate"
    label="End date"
    rules={[{ required: true, message: "End date cannot be empty" }]}
  >
    <DatePicker
      showTime={{ showHour: true, showMinute: true }}
      format="YYYY-MM-DD HH:mm"
      style={{ width: "100%" }}
    />
  </Form.Item>
);

const ASNSelector: React.FC<{
  onError: (e: any) => void;
}> = ({ onError }) => {
  const [results, setResults] = useState<ASN[]>([]);
  const { mutate: searchASNs, isLoading: isSearchingASN } = useMutation(
    fetchASNIndex,
    {
      onError: onError,
      onSuccess: (data) => {
        setResults(
          data.map((asn) => ({ id: asn.id, number: asn.number })) as ASN[]
        );
      },
    }
  );

  const handleASNSearch = (id: string) => searchASNs({ q: id?.trim() });
  const debounceHandleASNSearch = debounce(handleASNSearch, 500);

  return (
    <WithLabel name="Reference # (ASN)">
      {
        <Form.Item name="referenceNumber">
          <Select
            size="large"
            onSearch={debounceHandleASNSearch}
            showSearch
            allowClear
            placeholder="Search ASN"
            filterOption={false}
            suffixIcon={isSearchingASN && <Spin size="small" />}
          >
            {results.map((asn) => (
              <Select.Option key={asn.id} value={asn.number}>
                {asn.number}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      }
    </WithLabel>
  );
};

const StaffInput: React.FC = () => (
  <Form.Item
    name="assignee"
    label="Assignee(s)"
    rules={[{ required: true, message: "Assignee(s) cannot be empty" }]}
  >
    <Select mode="tags" tokenSeparators={[","]} open={false} />
  </Form.Item>
);
const DetailInput: React.FC = () => {
  return (
    <Form.Item name="details" label="Details">
      <Input.TextArea size="large" allowClear autoSize={{ minRows: 5 }} />
    </Form.Item>
  );
};

const TypeInput: React.FC = () => (
  <Form.Item
    name="type"
    label="Type"
    rules={[{ required: true, message: "Type cannot be empty" }]}
  >
    <Select size="large" placeholder={"Select Type"}>
      {SpecialProjectTypes.map((s) => (
        <Select.Option value={s} key={s}>
          {startCase(s?.trim().toLowerCase())}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);

const SubmitButton: React.FC<{
  isEnabled: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}> = (props) => (
  <Form.Item>
    <Tooltip
      title={!props.isEnabled ? permissionsEditWarning : null}
      trigger={["click", "hover"]}
    >
      <Button
        size="large"
        htmlType="button"
        onClick={props.onClick}
        type="primary"
        block
        icon={<CheckOutlined />}
        disabled={!props.isEnabled}
      >
        Create
      </Button>
    </Tooltip>
  </Form.Item>
);

const ResetButton: React.FC<{
  onClick?: React.MouseEventHandler<HTMLElement>;
}> = (props) => (
  <Form.Item>
    <Button
      size="large"
      icon={<UndoOutlined />}
      block
      htmlType="button"
      onClick={props.onClick}
    >
      Reset All
    </Button>
  </Form.Item>
);

const OrganizationSelector: React.FC<{
  onSelect: (organization: Organization) => void;
  onError: (e: any) => void;
}> = (props) => {
  const [results, setResults] = useState<Organization[]>([]);
  const {
    mutate: searchOrganizations,
    isLoading: isSearching,
    isSuccess,
  } = useMutation(fetchOrganizations, {
    onError: props.onError,
    onSuccess: (data) => {
      setResults(
        data.map((o) => ({ id: o.id, name: o.name })) as Organization[]
      );
    },
  });

  const handleOrgSearch = (id: string) => searchOrganizations(id?.trim());
  const debounceHandleOrgSearch = debounce(handleOrgSearch, 500);

  return (
    <Form.Item
      name="organization"
      label="Organization"
      rules={[{ required: true, message: "Organization is required" }]}
    >
      <Select
        size="large"
        loading={isSearching}
        onSearch={debounceHandleOrgSearch}
        onSelect={(id: string) => {
          const organization = results.find((o) => o.id === id);
          if (organization) props.onSelect(organization);
        }}
        showArrow
        showSearch
        allowClear
        placeholder="Search organization"
        filterOption={false}
        notFoundContent={isSuccess ? "No organization found!" : null}
      >
        {results.map((o) => (
          <Select.Option key={o.id} value={o.id}>
            {o.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const SpecialProjectCreatePage: React.FC = () => {
  const history = useHistory();
  const [form]: FormInstance[] = Form.useForm();
  const { canWriteSpecialProjects } = useValidateSpecialProjectPermissions();
  const canWrite = canWriteSpecialProjects();
  const [error, setError] = useState<string>("");
  const [organization, setOrganization] = useState<Organization>();
  const [cart, setCart] = useState<SupplyCart>({});

  const { mutate: createProject, isLoading: isCreating } = useMutation(
    createSpecialProject,
    {
      onError: setError,
      onSuccess: (project: any) => {
        setError("");
        history.push({
          pathname: `/special-projects/create/success`,
          state: project,
        });
      },
    }
  );

  const onSelectOrg = (org: Organization) => setOrganization(org);
  const showError = (errorInfo: any) => setError(errorInfo?.message);
  const resetForm = () => {
    form.resetFields();
    setError("");
  };
  const submitForm = async () => {
    try {
      await form.validateFields();
    } catch (e: any) {
      return;
    }

    setError("");

    const startDate = form.getFieldValue("startDate");
    const endDate = form.getFieldValue("endDate");
    const { isValid: isDateValid, error } = validateDates(startDate, endDate);
    if (!isDateValid) return setError(error);

    createProject({
      organization_id: organization?.id,
      user_names: form.getFieldValue("assignee")?.join(", "),
      start_time: startDate?.toISOString(),
      end_time: endDate?.toISOString(),
      facility: form.getFieldValue("facility"),
      details: form.getFieldValue("details"),
      type: form.getFieldValue("type"),
      reference_number: form.getFieldValue("referenceNumber"),
      supplies: Object.values(cart).map((c) => {
        return {
          supply_id: c.supplyId,
          quantity: c.quantity,
        };
      }),
    });
  };

  return (
    <PageContainer withPadding loading={isCreating}>
      <PageHeader
        title="Create Special Project"
        onBack={() => window.history.back()}
      />
      <ErrorAlert error={error} />
      <Form
        form={form}
        size="large"
        layout="vertical"
        onFinishFailed={showError}
      >
        <Row justify="center" gutter={[16, 24]}>
          <Col xs={24} lg={16}>
            <Row justify="center" align="middle" gutter={[8, 16]}>
              <Col xs={24}>
                <Card>
                  <Row justify="center" align="middle">
                    <Col xs={24}>
                      <OrganizationSelector
                        onSelect={onSelectOrg}
                        onError={setError}
                      />
                    </Col>
                    <Col xs={24}>
                      <ASNSelector onError={setError} />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24}>
                <Card>
                  <Row justify="center" align="middle">
                    <Col xs={24}>
                      <StaffInput />
                    </Col>
                    <Col xs={24}>
                      <DetailInput />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24}>
                <Card>
                  <Row justify="center" align="middle">
                    <Col xs={24}>
                      <SupplyScanner
                        setError={setError}
                        cart={cart}
                        setCart={setCart}
                        isEditing={true}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={8}>
            <Card>
              <Row justify="center" align="middle">
                <Col xs={24}>
                  <FacilitySelector />
                </Col>
                <Col xs={24}>
                  <TypeInput />
                </Col>
                <Col xs={24}>
                  <StartDateSelector />
                </Col>
                <Col xs={24}>
                  <EndDateSelector />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Divider />
        <Row gutter={8} justify="center" align="middle">
          <Col xs={24} lg={3}>
            <ResetButton onClick={resetForm} />
          </Col>
          <Col xs={24} lg={3}>
            <SubmitButton onClick={submitForm} isEnabled={canWrite} />
          </Col>
        </Row>
      </Form>
    </PageContainer>
  );
};

export default SpecialProjectCreatePage;
