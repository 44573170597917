import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Contents = styled.div`
  display: flex;
`;
export const LeftWrapper = styled.div`
  flex: 1;
`;
export const RightWrapper = styled.div`
  flex: 0.25;
  margin-left: 40px;
  margin-right: 10px;
`;

export const ButtonWrap = styled.div`
  margin: auto;
  margin-top: 40px;
`;
