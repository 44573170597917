import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { Button, Card, Col, notification, PageHeader, Row, Space } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { ErrorAlert, FileUpload } from "@secondcloset/web-components";
import { Warehouse } from "@secondcloset/types";

// Components
import PageContainer from "../../../components/PageContainer";
import CsvInstructions from "../../../components/CsvInstructions";

// Api
import {
  downloadCsvTemplate,
  uploadManualItemsCsvFile,
} from "../../../api/warehouse";

// Styles
import * as S from "./styles";

type ManualItem = Warehouse.ManualItem;

const ManualItemCsvUploadPage: React.FC = () => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [file, setFile] = useState<File>();

  const { mutate: downloadTemplate } = useMutation(downloadCsvTemplate, {
    onError: setError,
  });

  const { mutate: uploadCsv, isLoading: isUploading } = useMutation(
    uploadManualItemsCsvFile,
    {
      onError: (error: AxiosError) => setError(error.response?.data?.message),
      onSuccess: (data: ManualItem[]) => {
        notification.success({ message: "Successfully uploaded manual items" });
        localStorage.setItem("uploadedItems", JSON.stringify(data));
        history.push("/manual-items/csv-upload/success");
      },
    }
  );

  useEffect(() => setError(""), [file]);

  const manualItemFields = [
    {
      title: "type",
      value: "ikea_pallet|structube_pallet|rove_pallet|storage|other",
      description:
        "Type of the item (need to include NOTE if the type is 'other')",
      isRequired: true,
    },
    {
      title: "customer_name",
      value: "E.g. John Doe",
      description: "Name of the customer",
      isRequired: true,
    },
    {
      title: "order_number",
      value: "E.g. FO-FM3QHJY8",
      description: "Order number",
    },
    {
      title: "note",
      value: "String",
      description:
        "Any information regarding the item can be included in the notes field. This field is required if the selected type is 'other'.",
    },
    {
      title: "organization_id",
      value: "UUID",
      description:
        "Organization ID of the organization that this item belongs to",
    },
  ];

  const renderUploadButton = () => {
    return (
      <Button
        size="large"
        type="primary"
        icon={<UploadOutlined />}
        loading={isUploading}
        disabled={!file}
        onClick={() => file && uploadCsv(file)}
      >
        Upload
      </Button>
    );
  };

  return (
    <PageContainer withPadding>
      <PageHeader title="Manual Items" onBack={() => window.history.back()} />
      <Space direction="vertical" style={{ width: "100%" }}>
        <ErrorAlert error={error} />
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <CsvInstructions
              title="Import manual items via CSV"
              infoText="If you wish to upload multiple manual items at once, please download our
        template, add your item details, and upload the completed file."
              fields={manualItemFields}
              onDownloadClick={downloadTemplate}
            />
          </Col>
          <Col xs={24} lg={12}>
            <Card bordered>
              <S.UploadPanel>
                <FileUpload
                  file={file}
                  setFile={setFile}
                  acceptFileType=".csv"
                />
                <S.UploadButton>{renderUploadButton()}</S.UploadButton>
              </S.UploadPanel>
            </Card>
          </Col>
        </Row>
      </Space>
    </PageContainer>
  );
};

export default ManualItemCsvUploadPage;
