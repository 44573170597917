import { Warehouse } from "@secondcloset/types";
import { sortBy } from "lodash-es";

type OrganizationConfig = Warehouse.OrganizationConfig;

export const ReturnType = {
  RTS: "RTS",
  REGULAR: "REGULAR",
};

export const ReturnStatus = {
  READY_TO_PROCESS: "READY_TO_PROCESS",
  NEEDS_REVIEW: "NEEDS_REVIEW",
  ARRIVED: "ARRIVED",
  PROCESSING: "PROCESSING",
  REQUESTED: "REQUESTED",
  ON_HOLD: "ON_HOLD",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
  TO_BE_RESHIPPED: "TO_BE_RESHIPPED",
};

export const ProductCondition = {
  resellable: "Resellable",
  unsellable: "Cannot return to inventory",
  other: "Other",
};

export const RtsCondition = {
  refused: "Refused",
  multiple_attempts: "Multiple Attempts",
  address: "Address incorrect",
  unable_to_contract: "Unable to contact",
  carrier: "Wrong carrier",
  unknown: "No reason provided",
  other: "Other",
};

export const mapConfigLabelsToUI = {
  OPENED_GOOD_CONDITION_INSTRUCTION: {
    title: "Opened, but item in good condition",
    position: 1,
  },
  GOOD_CONDITION_DAMAGED_PACKAGING_INSTRUCTION: {
    title: "Packaging damaged, item in good condition",
    position: 2,
  },
  DAMAGED_CONDITION_INSTRUCTION: { title: "Item damaged", position: 3 },
} as Record<string, Record<string, any>>;

export const sortConfigsByType = (
  orgConfigs: OrganizationConfig[],
  activeOrgConfigs: Record<string, any>
) => {
  const sortedConfigs = orgConfigs?.map((config) => {
    return {
      ...config,
      position: activeOrgConfigs?.[config?.key as string]?.position || null,
    };
  });
  return sortedConfigs;
};

export const getFilterConfigsByActiveConfigs = (
  orgConfigs: OrganizationConfig[],
  activeOrgConfigs: Record<string, unknown>
) => {
  const labels = Object.keys(activeOrgConfigs);
  const sortedConfigs = sortConfigsByType(orgConfigs, activeOrgConfigs);
  const activeInstructions = sortedConfigs?.filter((config) =>
    labels?.includes(config?.key)
  );
  const sortedActiveInstructions = sortBy(activeInstructions, "position");
  return sortedActiveInstructions;
};

export const getSpecifiedConfig = (
  orgConfigs: OrganizationConfig[],
  key: string
) => {
  return orgConfigs?.find(
    (config: OrganizationConfig) => (config?.key as string) === key
  ) as OrganizationConfig;
};
