import { Card, Col, Row, Skeleton, Statistic } from "antd";
import React from "react";

interface Props {
  loading: boolean;
  lastUpdateAt?: string;
  lastUpdateBy?: string;
}

const LastAuditDashboard: React.FC<Props> = ({
  loading,
  lastUpdateAt,
  lastUpdateBy,
}) => {
  if (loading) return <Skeleton active />;

  return (
    <Card>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={12} lg={12}>
          <Statistic
            title="Last audit at"
            value={lastUpdateAt || ""}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col xs={24} md={12} lg={12}>
          <Statistic
            title="Last audit by"
            value={lastUpdateBy || ""}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default LastAuditDashboard;
