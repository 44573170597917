import { Common, Warehouse } from "@secondcloset/types";
import { PicksheetOrganization } from "@secondcloset/types/src/warehouse";
import queryString from "query-string";
import { downloadLabel, getAPIFromDomain } from "../../lib/api";
import {
  PaginatedData,
  PaginationInfo,
  PaginationOptions,
} from "./commonInterfaces";

type FacilityCode = Common.FacilityCode;
type PicksheetRequest = Warehouse.PicksheetRequest;
type PicksheetFailedReasons = Warehouse.PicksheetFailedReasons;

interface PicksheetOrganizationsQuery {
  organization_id?: string;
  facility: FacilityCode;
  include_partial?: string;
  product_id?: string;
  date_from?: string;
  date_to?: string;
}

export interface LocalPaginatedData<T> {
  data: T[];
  meta: PaginationInfo;
}

export interface PicksheetStats {
  organization?: PicksheetOrganization;
  ready_to_fulfill_count: number;
  processing_items_count: number;
  shipments_fulfilled_today: number;
}

export type PicksheetRequestFilters = {
  number_of_orders: string;
  allow_partial: string;
  product_id?: string;
  sku?: string;
  upc?: string;
  date_from?: string;
  date_to?: string;
};

export const fetchPicksheetStats = async (
  query: PaginationOptions & PicksheetOrganizationsQuery
): Promise<LocalPaginatedData<PicksheetStats>> => {
  const api = getAPIFromDomain("fulfillment", 2);
  const url = `/orders/stats${query ? "?" + queryString.stringify(query) : ""}`;
  const response = await api.get(url);
  return response.data as LocalPaginatedData<PicksheetStats>;
};

interface FetchPicksheetFailedReasonsQuery {
  picksheet_request_id: string;
}

export const fetchPicksheetFailedReasons = async (
  query: PaginationOptions & FetchPicksheetFailedReasonsQuery
): Promise<PaginatedData<PicksheetFailedReasons>> => {
  const { picksheet_request_id, ...rest } = query;
  const api = getAPIFromDomain("november");
  const url = `/picksheet-requests/${picksheet_request_id}/failed${
    rest ? "?" + queryString.stringify(rest) : ""
  }`;
  const response = await api.get(url);
  return response.data as PaginatedData<PicksheetFailedReasons>;
};

interface FetchOrganizationPicksheetsQuery {
  with_organization?: boolean;
  organization_id?: string;
  facility?: FacilityCode;
}

export const fetchPicksheetRequests = async (
  query: PaginationOptions & FetchOrganizationPicksheetsQuery
): Promise<PaginatedData<PicksheetRequest>> => {
  const api = getAPIFromDomain("november");
  const url = `/picksheet-requests?${queryString.stringify(query || {})}`;
  const response = await api.get(url);

  return response.data as PaginatedData<PicksheetRequest>;
};

interface CreatePicksheetRequest {
  organization_id: string;
  facility: FacilityCode;
  filters: PicksheetRequestFilters;
}

export const generatePicksheetRequest = async (
  body: CreatePicksheetRequest
): Promise<PicksheetRequest> => {
  const api = getAPIFromDomain("november");
  const url = `/picksheet-requests`;
  const response = await api.post(url, body);

  return response.data as PicksheetRequest;
};

export const generatePicksheetRequestPdf = async (
  picksheetRequestId: string
) => {
  const res = await downloadLabel(
    `/picksheets/generate-pdf`,
    {
      picksheet_request_id: picksheetRequestId,
    },
    "november"
  );
  if (res.data) {
    const url = window.URL.createObjectURL(
      new Blob([res.data], { type: "application/pdf" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${picksheetRequestId}.pdf`);
    link.dispatchEvent(
      new MouseEvent(`click`, {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
  }
  return res;
};
