import React from "react";
import { Table, Col, Typography, Row, TablePaginationConfig } from "antd";
import { Warehouse } from "@secondcloset/types";

import ProductIdentifiersField from "../../../../components/Table/ProductIdentifiersField";

interface Props {
  palletId?: string;
  location?: string;
  pagination?: TablePaginationConfig;
  locationItems?: Warehouse.LocationItem[];
}

const QuantityTable: React.FC<Props> = ({
  palletId,
  location,
  pagination,
  locationItems,
}) => {
  const renderItemDetail = (item) => {
    const sku = item?.product?.sku;
    const upc = item?.product?.upc;
    const name = item?.product?.name;
    const productId = item?.product?.id;
    const manualItemCode = item?.manual_item?.code;
    const customer = item?.manual_item?.customer_name;
    return (
      <ProductIdentifiersField
        sku={sku}
        upc={upc}
        name={name}
        code={manualItemCode}
        customer={customer}
        productId={productId}
      />
    );
  };

  const renderTableHeader = () => {
    return (
      <Row align="middle" gutter={[16, 8]}>
        <Col>
          <Typography.Text strong type="secondary">
            Scanned Pallet ID:
          </Typography.Text>
          <Typography.Text strong> {palletId || "Not Scanned"}</Typography.Text>
        </Col>
        <Col flex="auto">
          <Typography.Text strong type="secondary">
            Pallet Location:
          </Typography.Text>
          <Typography.Text strong> {location}</Typography.Text>
        </Col>
      </Row>
    );
  };

  const tableColumns = [
    {
      key: "id",
      title: "Item",
      width: 300,
      render: renderItemDetail,
    },
    {
      key: "quantity",
      title: "Quantity",
      width: 120,
      dataIndex: ["quantity"],
    },
  ];

  return (
    <Table
      size="small"
      title={renderTableHeader}
      scroll={{ x: "max-content" }}
      bordered
      rowKey="id"
      columns={tableColumns}
      dataSource={locationItems}
      pagination={pagination}
    />
  );
};

QuantityTable.defaultProps = {
  location: "N/A",
  palletId: "Not Scanned",
};

export default QuantityTable;
