import styled from "styled-components";
import { COLORS } from "../../../styles";
export const Link = styled.div`
  color: ${COLORS.BLUE};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ProductWrap = styled.div``;
export const Name = styled.div``;
export const Description = styled.div`
  font-size: 11px;
  color: ${COLORS.GREY};
`;
