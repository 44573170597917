import moment from "moment";

export const PACKAGE_STATUS = {
  AT_FACILITY: "at_facility",
  WITH_CARRIER: "with_carrier",
};

export const PACKAGE_STATUS_LABEL = {
  at_facility: "At Facility",
  with_carrier: "With Carrier",
};

export const PACKAGE_STATUS_COLOR = {
  at_facility: "gold",
  with_carrier: "green",
};

export const PACKAGE_STATUS_OPTIONS = [
  {
    key: PACKAGE_STATUS.AT_FACILITY,
    value: PACKAGE_STATUS.AT_FACILITY,
    label: PACKAGE_STATUS_LABEL[PACKAGE_STATUS.AT_FACILITY],
  },
  {
    key: PACKAGE_STATUS.WITH_CARRIER,
    value: PACKAGE_STATUS.WITH_CARRIER,
    label: PACKAGE_STATUS_LABEL[PACKAGE_STATUS.WITH_CARRIER],
  },
];

export function getDateString(dateString) {
  return dateString ? moment(dateString) : null;
}
