import React, { useState } from "react";
import { debounce } from "lodash-es";
import { Warehouse } from "@secondcloset/types";
import {
  Col,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";

import NullSafeText from "../Table/NullSafeText";
import ProductIdentifiersField from "../Table/ProductIdentifiersField";
import {
  UpdateReturnItemBody,
  UpdateReturnItemCondition,
} from "../../api/warehouse";

type ReturnItem = Warehouse.ReturnItem;

interface Props {
  productCondition: Record<string, string>;
  returnItemsCondition: ReturnItem[];
  setReturnItemsCondition: (items: ReturnItem[]) => void;
  handleBatchUpdateReturnItems?: (items: UpdateReturnItemBody[]) => void;
  setIsUpdatingReturnItem: (val: boolean) => void;
  isUpdatingReturnItem?: boolean;
  updateReturnItem: (id: string, updates: UpdateReturnItemCondition) => void;
}

const { Option } = Select;

const ReturnItemsTable: React.FC<Props> = ({
  productCondition,
  returnItemsCondition,
  setReturnItemsCondition,
  updateReturnItem,
  setIsUpdatingReturnItem,
  handleBatchUpdateReturnItems,
  isUpdatingReturnItem,
}) => {
  const [allItemsResellable, setAllItemsResellable] = useState(false);
  const sortedReturnItemsCondition = [...returnItemsCondition]?.sort(
    (a: ReturnItem, b: ReturnItem) => {
      return a?.id?.localeCompare(b?.id);
    }
  );
  const handleConditionNotes = (returnItemId: string, notes: string) => {
    const updatedItems = [...returnItemsCondition];
    const position = returnItemsCondition?.findIndex(
      (returnItem) => returnItemId === returnItem?.id
    );
    const updatedItemCondition = {
      ...returnItemsCondition[position],
      condition_notes: notes,
    };
    updatedItems?.splice(position, 1, updatedItemCondition);
    setReturnItemsCondition(updatedItems);
    updateReturnItem(returnItemId, { condition_notes: notes });
  };

  const selectCondition = (returnItemId: string, value: string) => {
    const updatedItems = [...returnItemsCondition];
    const position = returnItemsCondition?.findIndex(
      (returnItem) => returnItemId === returnItem?.id
    );
    const updatedItemCondition = {
      ...returnItemsCondition[position],
      condition: value,
    };
    updatedItems?.splice(position, 1, updatedItemCondition);
    setReturnItemsCondition(updatedItems);
    updateReturnItem(returnItemId, { condition: value });
  };

  const debounceHandleConditionNotes = debounce(handleConditionNotes, 1500);

  const renderText = (text: string) => {
    return <NullSafeText value={text} />;
  };

  const renderDropdown = (condition: string, returnItem: ReturnItem) => {
    return (
      <Select
        disabled={allItemsResellable}
        allowClear
        size="large"
        defaultValue={condition}
        value={condition}
        placeholder="Select a condition"
        style={{ width: "100%" }}
        onChange={(val) => selectCondition(returnItem?.id, val)}
      >
        {Object.keys(productCondition)?.map((conditionKey) => {
          return (
            <Option key={conditionKey} value={conditionKey}>
              {productCondition[conditionKey]}
            </Option>
          );
        })}
      </Select>
    );
  };

  const renderInput = (conditionNotes: string, returnItem: ReturnItem) => {
    return (
      <Input
        size="large"
        disabled={allItemsResellable}
        defaultValue={conditionNotes}
        onChange={(e) => {
          setIsUpdatingReturnItem(true);
          debounceHandleConditionNotes(returnItem?.id, e?.target?.value);
        }}
        autoCapitalize="off"
      />
    );
  };

  const renderIdentifier = (item: ReturnItem) => {
    const sku = item?.sku;
    const upc = item?.upc;
    const name = item?.name;
    return <ProductIdentifiersField sku={sku} upc={upc} name={name} />;
  };

  const itemsTableColumn = [
    {
      title: "Identifier",
      key: "id",
      dataIndex: "id",
      width: 250,
      render: (id: string, item: ReturnItem) => renderIdentifier(item),
    },
    {
      title: "Product name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: renderText,
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
      width: 350,
      render: renderDropdown,
    },
    {
      title: "Condition Details",
      dataIndex: "condition_notes",
      key: "condition_notes",
      width: 350,
      render: (conditionNotes: string, item: ReturnItem) =>
        renderInput(conditionNotes, item),
    },
  ];

  const handleToggle = (toggleActive: boolean) => {
    setAllItemsResellable(toggleActive);
    if (toggleActive) {
      const mappedResellableItems = returnItemsCondition?.map((item) => ({
        id: item?.id,
        condition: "resellable",
        condition_notes: "",
      }));
      handleBatchUpdateReturnItems &&
        handleBatchUpdateReturnItems(mappedResellableItems);
    }
  };

  const renderTableHeader = () => {
    return (
      <Row justify="space-between" align="middle">
        <Col>
          <Typography.Text strong>Package Contents</Typography.Text>
        </Col>
        <Col>
          <Space>
            <Typography.Text type="secondary">
              Mark entire RTS package as resellable
            </Typography.Text>
            <Switch
              defaultChecked={allItemsResellable}
              disabled={!handleBatchUpdateReturnItems || isUpdatingReturnItem}
              onChange={handleToggle}
            />
          </Space>
        </Col>
      </Row>
    );
  };

  return (
    <Table
      size="small"
      title={renderTableHeader}
      rowKey="id"
      scroll={{ x: "max-content" }}
      style={{ width: "100%" }}
      bordered
      columns={itemsTableColumn}
      pagination={false}
      dataSource={sortedReturnItemsCondition}
    />
  );
};

export default ReturnItemsTable;
