import React from "react";
import { debounce } from "lodash-es";
import { useHistory } from "react-router";
import { CheckboxDropdown } from "@secondcloset/web-components";
import { Button, DatePicker, Input, Select, Space, Row, Col } from "antd";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";

import WithLabel from "../../../../components/WithLabel";

import {
  ExceptionOptionKey,
  StatusOptionKey,
  EXCEPTION_OPTIONS,
  STATUS_OPTIONS,
  WAREHOUSE_OPTIONS,
} from "./helpers";

import * as S from "./styles";

interface Props {
  setSearchedASN: (v: string) => void;
  setSearchedSKU: (v: string) => void;
  setSearchedOrg: (v: string) => void;
  setArrivalDate: (v: string) => void;
  isFilterVisible: boolean;
  onToggleFilterVisible: () => void;
  facility: string;
  setFacility: (v: string) => void;
  exceptionKeys: ExceptionOptionKey[];
  setExceptionKeys: (v: ExceptionOptionKey[]) => void;
  statusKeys: StatusOptionKey[];
  setStatusKeys: (v: StatusOptionKey[]) => void;
}

const TableFilters: React.FC<Props> = ({
  setSearchedASN,
  setSearchedSKU,
  setSearchedOrg,
  setArrivalDate,
  isFilterVisible,
  onToggleFilterVisible,
  facility,
  setFacility,
  exceptionKeys,
  setExceptionKeys,
  statusKeys,
  setStatusKeys,
}) => {
  const history = useHistory();

  const renderCreateButton = () => (
    <Button
      size="large"
      type="primary"
      icon={<PlusOutlined />}
      block
      onClick={() => history.push("/asn/select-client")}
    >
      Create ASN
    </Button>
  );

  const renderSearchSection = () => (
    <Row justify="start" align="bottom" gutter={[8, 16]}>
      <Col xs={24} lg={7}>
        <WithLabel name="ASN, Tracking, Container #">
          <Input.Search
            size="large"
            placeholder="Enter ASN, Tracking, Container #"
            onSearch={setSearchedASN}
            onChange={debounce((e) => setSearchedASN(e.target.value), 500)}
            allowClear
          />
        </WithLabel>
      </Col>
      <Col xs={24} lg={6}>
        <WithLabel name="SKU">
          <Input.Search
            size="large"
            placeholder="Enter Product SKU"
            onSearch={setSearchedSKU}
            onChange={debounce((e) => setSearchedSKU(e.target.value), 500)}
            allowClear
          />
        </WithLabel>
      </Col>
      <Col xs={24} lg={6}>
        <WithLabel name="Organization">
          <Input.Search
            size="large"
            placeholder="Enter organization name"
            onSearch={setSearchedOrg}
            onChange={debounce((e) => setSearchedOrg(e.target.value), 500)}
            allowClear
          />
        </WithLabel>
      </Col>
      <Col xs={24} lg={4} xl={3}>
        <Button
          size="large"
          icon={<FilterOutlined />}
          type={isFilterVisible ? "primary" : "default"}
          ghost={isFilterVisible}
          block
          onClick={onToggleFilterVisible}
        >
          Filters
        </Button>
      </Col>
    </Row>
  );

  const buildFiltersSection = () => (
    <S.FilterGroup>
      <Row align="bottom" justify="space-between" gutter={[8, 16]}>
        <Col xs={24} lg={6}>
          <WithLabel name="ASN Status">
            <CheckboxDropdown
              options={STATUS_OPTIONS}
              defaultText="All"
              selectedOptionKeys={statusKeys}
              setSelectedOptionKeys={(keys) =>
                setStatusKeys(keys as StatusOptionKey[])
              }
            />
          </WithLabel>
        </Col>
        <Col xs={24} lg={6}>
          <WithLabel name="Exceptions">
            <CheckboxDropdown
              options={EXCEPTION_OPTIONS}
              defaultText="All"
              selectedOptionKeys={exceptionKeys}
              setSelectedOptionKeys={(keys) =>
                setExceptionKeys(keys as ExceptionOptionKey[])
              }
            />
          </WithLabel>
        </Col>
        <Col xs={24} lg={6}>
          <WithLabel name="Warehouse">
            <Select
              options={[
                { key: "all", value: "all", label: "All" },
                ...WAREHOUSE_OPTIONS,
              ]}
              value={facility}
              style={{ width: "100%" }}
              onChange={setFacility}
            />
          </WithLabel>
        </Col>
        <Col xs={24} lg={6}>
          <WithLabel name="Arrival Date">
            <DatePicker
              style={{ width: "100%" }}
              onChange={(_, dateString: string) => setArrivalDate(dateString)}
            />
          </WithLabel>
        </Col>
      </Row>
    </S.FilterGroup>
  );

  return (
    <Space style={{ width: "100%" }} direction="vertical" size="large">
      <Row align="bottom" justify="space-between" gutter={[8, 16]}>
        <Col
          xs={{ span: 24, order: 1 }}
          lg={{ span: 20, order: 1 }}
          xl={{ span: 21, order: 1 }}
        >
          {renderSearchSection()}
        </Col>
        <Col
          xs={{ span: 24, order: 3 }}
          lg={{ span: 4, order: 2 }}
          xl={{ span: 3, order: 2 }}
        >
          {renderCreateButton()}
        </Col>
        {isFilterVisible && (
          <Col xs={{ span: 24, order: 2 }} lg={{ span: 24, order: 3 }}>
            {buildFiltersSection()}
          </Col>
        )}
      </Row>
    </Space>
  );
};

export default TableFilters;
