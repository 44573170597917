import { Fulfillment } from "@secondcloset/types";
import { reduce } from "lodash-es";

export const matchProductByIdentifier = (
  identifier: string | string[],
  items
) => {
  const product = items.find(
    (item) => item.product_sku === identifier || item.product_upc === identifier
  );
  return product;
};

export const getAsnScanBody = (
  identifier: string | string[],
  operation: string,
  asn?: Fulfillment.ASN
) => {
  const param: any = { product_identifier: identifier };
  let action;
  let quantity;

  const product = matchProductByIdentifier(identifier, asn?.items_attributes);
  switch (operation) {
    case "scanOne":
      param["change_received_by"] = 1;
      quantity = 1;
      action = "receive";
      break;
    case "scanDamagedOne":
      param["change_damaged_by"] = 1;
      quantity = 1;
      action = "set_damage";
      break;
    case "reduceReceivedOne":
      param["change_received_by"] = -1;
      quantity = -1;
      action = "receive";
      break;
    case "reduceDamagedOne":
      param["change_damaged_by"] = -1;
      quantity = -1;
      action = "set_damage";
      break;
    default:
      throw new Error("Unknown operation");
  }

  return {
    external_body: [param],
    product_name: product?.product_name,
    product_sku: product?.product_sku,
    product_upc: product?.product_upc,
    external_product_id: product?.product_id,
    external_asn_number: asn?.number,
    action,
    quantity,
    asnID: asn?.id,
  };
};

export const getAsnSubmitBody = (values: {
  identifier: string;
  isReceive: boolean;
  quantity: number;
  asn?: Fulfillment.ASN;
}) => {
  const { identifier, isReceive, quantity, asn } = values;
  const product = matchProductByIdentifier(identifier, asn?.items_attributes);
  return {
    external_body: [
      {
        product_identifier: identifier,
        change_received_by: isReceive ? quantity : undefined,
        change_damaged_by: isReceive ? undefined : quantity,
      },
    ],
    external_product_id: product?.product_id,
    product_name: product?.product_name,
    product_sku: product?.product_sku,
    product_upc: product?.product_upc,
    quantity,
    action: isReceive ? "receive" : "set_damage",
    external_asn_number: asn?.number,
    asnID: asn?.id,
  };
};

export const getAsnCompleteBody = (asn?: Fulfillment.ASN) => {
  return {
    body: {
      external_asn_number: asn?.number,
      external_body: { asn: { status: "completed" } },
      action: "complete",
      external_asn_id: asn?.id,
    },
  };
};

export const getAsnResetBody = (asn?: Fulfillment.ASN) => {
  const items_attributes = asn?.items_attributes?.map(
    (item: Fulfillment.ASNItem) => ({
      id: item.id,
      received_quantity: 0,
      damaged_quantity: 0,
    })
  );
  return {
    body: {
      external_asn_number: asn?.number,
      action: "reset",
      external_body: { asn: { items_attributes } },
      external_asn_id: asn?.id,
    },
    options: { with_response: true },
  };
};

export const transformItemQuantity = (items) => {
  return reduce(items, (sum, number) => {
    return sum + number;
  });
};

export const bringItemToFront = (arr: any[], idx: number) => {
  arr.unshift(arr.splice(idx, 1)[0]);
  return arr;
};
