import React, { ReactNode } from "react";

// API
import { fetchRecommendedShippingMethod } from "../../../../api/fulfillment";

// Assets
import yellowFreight from "../../../../assets/yellow_freight.svg";
import yellowParcel from "../../../../assets/yellow_parcel.svg";

// Hooks
import { useQuery } from "react-query";

// Utils
import { isEmpty } from "lodash-es";

// Components
import * as S from "./styles";
import { Spin, Typography } from "antd";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import {
  packingFlowShipmentState,
  packingFlowShippingMethodState,
} from "../../../../recoil/packingFlow/atoms";

// Types
import { SelectShipmentCarrierTypes } from "@secondcloset/web-components";

type ShippingMethodType = SelectShipmentCarrierTypes.RecommendedShippingMethod;
const ShippingMethod = SelectShipmentCarrierTypes.RecommendedShippingMethod;
interface ShipmentMethodOption {
  shipping_method: ShippingMethodType;
  title: string;
  image: string;
  description: ReactNode;
}

const SHIPMENT_OPTIONS: ShipmentMethodOption[] = [
  {
    shipping_method: ShippingMethod.parcel,
    title: "Parcel",
    image: yellowParcel,
    description: (
      <S.Text>
        Used for an order consisting of <S.Text strong>small </S.Text>items.
      </S.Text>
    ),
  },
  {
    shipping_method: ShippingMethod.freight,
    title: "3rd Party Freight",
    image: yellowFreight,
    description: (
      <S.Text>
        Used for an order consisting of <S.Text strong>large</S.Text> items and
        shipping address is <S.Text strong>outside</S.Text> Bolt Logistics
        service zone.
      </S.Text>
    ),
  },
];
const { Title } = Typography;

const SelectShipmentMethodStep: React.FC = () => {
  const shipment = useRecoilValue(packingFlowShipmentState);
  const [shippingMethod, dispatchSetShippingMethod] = useRecoilState(
    packingFlowShippingMethodState
  );

  const orderItemIds =
    shipment?.shipment_items?.map((i) => i.order_item_id) || [];

  const recommendedShippingMethod = useQuery(
    ["recommendedShippingMethod", orderItemIds],
    () => fetchRecommendedShippingMethod(orderItemIds),
    {
      enabled: !isEmpty(orderItemIds) && !shippingMethod,
      onSuccess: (shippingMethod: ShippingMethodType) => {
        dispatchSetShippingMethod(shippingMethod);
      },
    }
  );

  const checkIsMethodRecommended = (shippingMethod: ShippingMethodType) => {
    const recommendedMethod = recommendedShippingMethod.data;
    if (recommendedMethod === ShippingMethod.sc_last_mile) return false;
    return shippingMethod === recommendedMethod;
  };

  const renderOptionCard = (option: ShipmentMethodOption) => {
    const { shipping_method, title, image, description } = option;
    const isRecommended = checkIsMethodRecommended(shipping_method);
    return (
      <S.Card
        key={shipping_method}
        isSelected={shippingMethod === shipping_method}
        onClick={() => dispatchSetShippingMethod(shipping_method)}
      >
        <S.BadgeContainer isRecommended={isRecommended}>
          <S.RecommendedBadge>Recommended</S.RecommendedBadge>
        </S.BadgeContainer>
        <S.Icon src={image} alt="Option Image" />
        <S.CardTitle level={4}>{title}</S.CardTitle>
        {description}
      </S.Card>
    );
  };

  return (
    <Spin tip="loading..." spinning={recommendedShippingMethod.isLoading}>
      <S.Container>
        <Title level={3}>SELECT SHIPMENT METHOD</Title>
        <S.InfoText>
          By default, Bolt Logistics recommends a shipping method based on item
          size and address. However, if the customer selected a specific method,
          you may change the selection below.
        </S.InfoText>
        <S.CardContainer>
          {SHIPMENT_OPTIONS.map((o) => renderOptionCard(o))}
        </S.CardContainer>
      </S.Container>
    </Spin>
  );
};

export default SelectShipmentMethodStep;
