import React from "react";

// API
import { fetchOrganizations } from "../../../../../api/fulfillment/organization";

// Components
import { Input, notification } from "antd";
import { useSetRecoilState, useRecoilState } from "recoil";

// Helpers
import { debounce } from "lodash-es";

// Recoil
import {
  asnCreateFlowOrganizations,
  asnCreateFlowLoadingOrganizations,
} from "../../../../../recoil/asnCreateFlow/atoms";
import { useMutation } from "react-query";

const AsnSelectClientSearchbar: React.FC = () => {
  const setOrganizations = useSetRecoilState(asnCreateFlowOrganizations);
  const [loadingOrganizations, setLoadingOrganizations] = useRecoilState(
    asnCreateFlowLoadingOrganizations
  );
  const { mutate: getOrganizations } = useMutation(fetchOrganizations, {
    onSuccess: (organizations) => {
      setOrganizations(organizations);
    },
    onError: (error) => {
      notification.error({ message: "Error", description: error as string });
    },
  });

  const handleSearch = async (search: string) => {
    setLoadingOrganizations(true);
    await getOrganizations(search);
    setLoadingOrganizations(false);
  };

  const debouncedSearch = debounce(handleSearch, 800);

  return (
    <Input.Search
      allowClear
      size="large"
      placeholder="Search for Organization"
      loading={loadingOrganizations}
      onSearch={(search) => {
        debouncedSearch.cancel();
        handleSearch(search);
      }}
      onChange={(e) => {
        debouncedSearch(e.target.value);
      }}
      style={{ maxWidth: "320px" }}
    />
  );
};

export default AsnSelectClientSearchbar;
