import styled from "styled-components";
import { DEVICE } from "../../../styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AlertBoxWrap = styled.div`
  margin-top: 20px;
`;

export const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

export const HeaderButtonsWrap = styled.div`
  margin-top: -65px;
  width: 100%;
  justify-content: flex-end;
  display: flex;
  .ant-btn {
    margin-left: 10px;
  }

  @media ${DEVICE.md} {
    margin-top: 0px;
    justify-content: flex-start;
    .ant-btn {
      margin-right: 10px;
      margin-left: 0px;
    }
  }
`;
