import React, { useState, useEffect } from "react";

// Components
import { Modal, Button, Alert, Switch, Space } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import CarrierList from "./CarrierList";
import NumberedTitle from "./NumberedTitle";
import ManualCarrierModal from "./ManualCarrierModal";
import FrieightCarrierList from "./FrieghtCarrierList";
import OrderLib from "../../../../lib/order";

// Styles
import * as S from "./styles";

// Recoil
import {
  packingFlowShipmentState,
  packingFlowShippingMethodState,
  packingFlowPackageTypeState,
  PackageType,
  packingFlowOrderState,
} from "../../../../recoil/packingFlow/atoms";
import { useRecoilValue } from "recoil";
import PackageDetails from "./PackageDetails";

// Types
import { Common } from "@secondcloset/types";
import { last } from "lodash-es";
import { getDefaultCarrierListVersion } from "./helper";
import {
  getIsOddPieces,
  getIsVessi,
} from "../../../../recoil/packingFlow/selectors";

type File = Common.File;

const SelectCarrierStep: React.FC = () => {
  const packingOrder = useRecoilValue(packingFlowOrderState);
  const isVessi = useRecoilValue(getIsVessi);
  const isOddPieces = useRecoilValue(getIsOddPieces);
  const shipment = useRecoilValue(packingFlowShipmentState);
  const shippingMethod = useRecoilValue(packingFlowShippingMethodState);

  const [showCarrierModalVisible, setShowCarrierModalVisible] = useState(false);
  const [ratesVersion, setRatesVersion] = useState<1 | 2>(
    getDefaultCarrierListVersion({
      fulfilledFrom: packingOrder?.fulfilled_from || "yyz3",
      isVessi,
    })
  );
  const [isSignatureRate, setIsSignatureRate] = useState(
    !!packingOrder?.signature_required
  );
  const isParcel = shippingMethod === "parcel";
  const packageType = useRecoilValue(packingFlowPackageTypeState);
  const isBox = packageType === PackageType.box;
  const alternativeRate = () => (ratesVersion === 1 ? 2 : 1);
  const isPoBox = OrderLib.isOrderAddressPoBox(packingOrder);

  useEffect(() => {
    if (
      shipment?.carrier?.external_platform === "easypost" ||
      shipment?.carrier?.external_platform === "shipengine"
    ) {
      setRatesVersion(1);
    }
    if (shipment?.carrier?.external_platform === "freightcom") {
      setRatesVersion(2);
    }

    if (isOddPieces && isPoBox) {
      setRatesVersion(2);
    }
    // eslint-disable-next-line
  }, [shipment?.carrier?.external_platform]);

  const [isPoBoxWarningVisible, setIsPoBoxWarningVisible] = useState(isPoBox);

  const handleToggleRates = () => {
    const updatedRatesVersion = alternativeRate();
    setRatesVersion(updatedRatesVersion);
  };

  const renderRatesToggle = () => {
    if (isVessi) return null;
    if (shipment?.carrier?.external_platform) return null;
    return (
      <S.ToggleRatesWrapper>
        <S.ToggleRates onClick={handleToggleRates}>
          Show v{alternativeRate()} rates
        </S.ToggleRates>
      </S.ToggleRatesWrapper>
    );
  };

  const renderSignatureToggle = () => {
    if (shipment?.carrier?.external_platform) return null;
    return (
      <S.ToggleRatesWrapper>
        <Space>
          <span>Signature Required</span>
          <Switch
            checked={isSignatureRate}
            onClick={(value) => setIsSignatureRate(value)}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Space>
      </S.ToggleRatesWrapper>
    );
  };

  const renderManualCarrierLink = () => {
    return (
      <S.LinkButton>
        <Button type="link" onClick={() => setShowCarrierModalVisible(true)}>
          Don&apos;t see the desired carrier?
        </Button>
      </S.LinkButton>
    );
  };

  const renderManualCarrierModal = () => {
    return (
      <ManualCarrierModal
        setModalVisible={setShowCarrierModalVisible}
        visible={showCarrierModalVisible}
      />
    );
  };

  const renderParcelCarrierList = () => {
    return (
      <S.StepWrap>
        <S.Row>
          <NumberedTitle number={2} text="Select Carrier and Service" />
          <Space size={45}>
            {renderSignatureToggle()}
            {renderRatesToggle()}
          </Space>
        </S.Row>
        <CarrierList
          ratesVersion={ratesVersion}
          isSignatureRate={isSignatureRate}
        />
        {renderManualCarrierLink()}
      </S.StepWrap>
    );
  };

  const renderFreightCarrierList = () => {
    return (
      <S.StepWrap>
        <S.Row>
          <NumberedTitle number={2} text="Select Freight service" />
        </S.Row>
        <FrieightCarrierList />
        {renderManualCarrierLink()}
      </S.StepWrap>
    );
  };

  const renderPackageReview = () => {
    return (
      <S.StepWrap>
        <NumberedTitle
          number={1}
          text={isBox ? "Review Packages" : "Review Pallets"}
        />
        <PackageDetails />
      </S.StepWrap>
    );
  };

  const renderPoBoxWarningModal = () => {
    return (
      <Modal
        title="P.O. Box"
        visible={isPoBoxWarningVisible}
        onCancel={() => setIsPoBoxWarningVisible(false)}
        maskClosable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => setIsPoBoxWarningVisible(false)}
          >
            OK
          </Button>,
        ]}
      >
        <div>It looks like address is a P.O. Box.</div>
        <div>
          Make sure that <S.Bold>Canada Post</S.Bold> is selected as the
          carrier.
        </div>
      </Modal>
    );
  };

  const getFileName = (file: File) => {
    const fileName = last(file.url.split("/")) || "";
    const parts = fileName.split("_");
    const fileExtensionParts = parts[parts.length - 1].split(".");
    const fileExtension = fileExtensionParts[fileExtensionParts.length - 1];
    parts[parts.length - 1] = `.${fileExtension}`;
    const name = parts.join("");
    return name;
  };

  const buildAttachments = () => {
    if ((packingOrder?.files?.length || 0) === 0) return null;

    const descriptions =
      packingOrder?.files.map((f) => {
        const name = getFileName(f);
        const url = f?.url;
        return (
          <S.FileLink href={url} key={url}>
            {name}
          </S.FileLink>
        );
      }) || [];

    return (
      <S.AttachmentWrap>
        <Alert
          showIcon
          type="info"
          message="Order Attachments"
          description={descriptions}
        />
      </S.AttachmentWrap>
    );
  };

  return (
    <S.Container>
      {buildAttachments()}
      <S.ContentWrap style={{ gridTemplateColumns: "minmax(auto, 290px) 2fr" }}>
        {renderPackageReview()}
        {isParcel ? renderParcelCarrierList() : renderFreightCarrierList()}
        {renderPoBoxWarningModal()}
        {renderManualCarrierModal()}
      </S.ContentWrap>
    </S.Container>
  );
};

export default SelectCarrierStep;
