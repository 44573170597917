import { Fulfillment } from "@secondcloset/types";
import { Col, Modal, Row, Typography } from "antd";
// interface
import { ModalProps } from "antd/lib/modal";
import React, { Fragment } from "react";

type Shipment = Fulfillment.Shipment;
type ExternalCarrierShippingMethod = Fulfillment.ExternalCarrierShippingMethod;
type Section = {
  heading: string;
  items: { label: string; value?: string | number }[];
};
interface ShipmentDetailsModalProps extends ModalProps {
  shipment: Shipment;
}

const { Text, Title } = Typography;

const ShipmentDetailsModal: React.FC<ShipmentDetailsModalProps> = ({
  shipment,
  ...rest
}) => {
  const shippingMethod =
    shipment.shipping_method as ExternalCarrierShippingMethod;
  const { ship_from_address: shipFrom, ship_to_address: shipTo } =
    shippingMethod;
  const shipFromAddress = `${shipFrom.address} ${shipFrom.city} ${shipFrom.province} ${shipFrom.postal_code} ${shipFrom.country}`;
  const shipToAddress = `${shipTo.address} ${shipTo.city} ${shipTo.province} ${shipTo.postal_code} ${shipTo.country}`;
  const notes =
    typeof shipment?.notes === "string"
      ? shipment?.notes
      : shipment?.notes?.map((note) => note.text).join(", ");

  const sections = [
    {
      heading: "Shipment Details",
      items: [
        { label: "Shipment ID", value: shipment.shipment_number },
        { label: "Carrier", value: shipment.carrier?.name },
        { label: "Packages", value: shipment.packages.length },
      ],
    },
    {
      heading: "Ship From",
      items: [
        { label: "Contact name", value: shipFrom.contact_name },
        { label: "Phone number", value: shipFrom.phone_number },
        { label: "Address", value: shipFromAddress },
      ],
    },
    {
      heading: "Ship To",
      items: [
        { label: "Contact name", value: shipTo.contact_name },
        { label: "Phone number", value: shipTo.phone_number },
        { label: "Address", value: shipToAddress },
      ],
    },
  ];

  const renderSection = ({ heading, items }: Section) => {
    return (
      <Row style={{ margin: "1em 0" }} key={heading}>
        <Col span={24}>
          <Title level={5}>{heading}</Title>
        </Col>
        {items.map(({ label, value }, index) => {
          return (
            <Fragment key={`${label}-${index}`}>
              <Col span={8}>
                <Text type="secondary">{label}</Text>
              </Col>
              <Col span={16}>
                <Text strong>{value || "N/A"}</Text>
              </Col>
            </Fragment>
          );
        })}
      </Row>
    );
  };

  return (
    <Modal {...rest}>
      {sections.map((s) => renderSection(s))}
      <Row style={{ margin: "1em 0" }}>
        <Col span={24}>
          <Text type="secondary">Notes</Text>
        </Col>
        <Col span={24}>
          <Text strong>{notes || "No Notes"}</Text>
        </Col>
      </Row>
    </Modal>
  );
};

export default ShipmentDetailsModal;
