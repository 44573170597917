import { Button, Row, Space } from "antd";
import React from "react";
import { useRecoilValue } from "recoil";

// Hooks
import {
  currentPackageItemIndexState,
  packingFlowPackageContainerState,
} from "../../../../../../recoil/packingFlow/atoms";
import {
  defaultPackage,
  useEditPackage,
} from "../../../../../../recoil/packingFlow/helpers";

// Styles
import * as S from "./styles";

const PackageContainerDisplayOrCustomInput: React.FC = () => {
  const { replaceCurrentContainerWith } = useEditPackage();
  const toggleToCustomInput = () => {
    const boxToAdd = { ...defaultPackage["box"], isCustomDimensions: true };
    replaceCurrentContainerWith(boxToAdd);
  };
  const packageContainers = useRecoilValue(packingFlowPackageContainerState);
  const currentPackageItemIndex = useRecoilValue(currentPackageItemIndexState);
  const currentPackageContainer = packageContainers[currentPackageItemIndex];

  const renderCustomButton = () => (
    <Button
      type="primary"
      onClick={toggleToCustomInput}
      style={{ marginRight: 50 }}
    >
      Custom
    </Button>
  );

  const renderTitle = () => {
    const { boxSupplyId } = currentPackageContainer;
    const instruction = boxSupplyId
      ? "SELECTED PACKAGING"
      : "PLEASE SCAN A PACKAGING";
    return (
      <Row align="middle">
        <S.LabelText style={!boxSupplyId ? { paddingBottom: 5 } : {}}>
          {instruction}
        </S.LabelText>
      </Row>
    );
  };

  const renderSelectedBox = () => {
    const { displayName, boxSupplyId } = currentPackageContainer;
    if (!boxSupplyId) return null;
    return (
      <Space>
        <S.PrimaryText>{displayName}</S.PrimaryText>
      </Space>
    );
  };
  return (
    <Space size="large" align="end">
      <S.Container>
        {renderTitle()}
        {renderSelectedBox()}
      </S.Container>
      {renderCustomButton()}
    </Space>
  );
};

export default PackageContainerDisplayOrCustomInput;
