import { atom } from "recoil";
import { Warehouse } from "@secondcloset/types";

type CarrierTransferPallet = Warehouse.CarrierTransferPallet;

export const custodyTransferFlowPalletPackageState =
  atom<CarrierTransferPallet>({
    key: "custodyTransferFlowPalletPackageState",
    default: {
      id: "",
      carrier_transfer_packages: [],
      created_at: "",
      facility: null,
      handed_over: false,
    },
  });

export const custodyTransferFlowScannedInputState = atom<string>({
  key: "custodyTransferFlowScannedInputState",
  default: "",
});
