import React from "react";
import { Spin } from "antd";

//styles
import { Container, PageTitle } from "./styles";
import { CSSProperties } from "styled-components";

export interface Props {
  title?: string;
  withHeader?: boolean;
  withFooter?: boolean;
  withPadding?: boolean;
  backButton?: React.ReactNode;
  children: React.ReactNode;
  loading?: boolean;
  styles?: CSSProperties;
}

const PageContainer: React.FC<Props> = ({ loading, ...props }) => {
  return (
    <Spin tip="Loading..." spinning={!!loading}>
      <Container {...props}>
        {props.backButton}
        {props.title && <PageTitle>{props.title}</PageTitle>}
        {props.children}
      </Container>
    </Spin>
  );
};

export default PageContainer;
