import React from "react";
import { Table } from "antd";
import { Warehouse } from "@secondcloset/types";
import { FixedType } from "rc-table/lib/interface";

// Components
import { AuditCart } from "../InventoryAuditPage";
import { QuantityStatus } from "./QuantityStatus";
import StatusCell from "./StatusCell";
import QuantityInput from "./QuantityInput";
import NullSafeText from "../../../components/Table/NullSafeText";
import ItemCountFooter from "../../../components/Table/ItemCountFooter";
import ProductIdentifiersField from "../../../components/Table/ProductIdentifiersField";

type LocationItem = Warehouse.LocationItem;

interface Props {
  cart: AuditCart;
  setCart: (c: AuditCart) => void;
  locationItems: LocationItem[];
  loading: boolean;
}

const LocationItemsTable: React.FC<Props> = ({
  locationItems,
  loading,
  cart,
  setCart,
}) => {
  const calculateQtyStatus = (item, cart) => {
    if (!item || !cart[item.id]) return QuantityStatus.NO_CHANGE;

    const diff = item.quantity - cart[item.id].quantity;
    if (diff < 0) return QuantityStatus.INCREASED;
    if (diff > 0) return QuantityStatus.DECREASED;
    return QuantityStatus.NO_CHANGE;
  };

  const renderNullSafeText = (v: string) => <NullSafeText value={v} />;

  const renderItemDetail = (product, record) => {
    const sku = product?.sku;
    const upc = product?.upc;
    const name = product?.name;
    const productId = product?.id;
    const customer = record?.manual_item?.customer_name;
    const code = record?.manual_item?.code;
    return (
      <ProductIdentifiersField
        sku={sku}
        upc={upc}
        name={name}
        code={code}
        customer={customer}
        productId={productId}
      />
    );
  };

  const renderInputCell = (item: LocationItem, singleItem?: boolean) => {
    const updateValues = (value: number) => {
      const newCart = { ...cart };
      newCart[item.id] = {
        location_item_id: item.id,
        quantity: value,
      };
      setCart(newCart);
    };

    return (
      <QuantityInput
        min={0}
        max={singleItem ? 1 : Number.MAX_SAFE_INTEGER}
        value={cart[item.id] ? cart[item.id]?.quantity : item?.quantity}
        onChange={updateValues}
      />
    );
  };

  const tableColumns = [
    {
      key: "id",
      title: "Item",
      width: 300,
      dataIndex: "product",
      render: renderItemDetail,
      fixed: "left" as FixedType,
    },
    {
      key: "pallet_id",
      title: "Pallet",
      width: 180,
      dataIndex: "pallet_id",
      render: renderNullSafeText,
    },
    {
      key: "quantity",
      title: "Qty",
      width: 120,
      dataIndex: "quantity",
      render: renderNullSafeText,
    },
    {
      key: "new-quantity",
      title: "New Quantity",
      width: 150,
      render: (item) => renderInputCell(item, !!item?.manual_item_id),
    },
  ];

  const mergedColumns = tableColumns.map((col) => {
    return {
      ...col,
      onCell: (item: LocationItem) => ({
        item,
        title: col.title,
        status: calculateQtyStatus(item, cart),
      }),
    };
  });

  const renderTableFooter = () => (
    <ItemCountFooter count={locationItems?.length || 0} />
  );

  return (
    <Table
      size="small"
      scroll={{ x: "max-content" }}
      rowKey={(r) => r.id}
      footer={renderTableFooter}
      loading={loading}
      columns={mergedColumns}
      components={{ body: { cell: StatusCell } }}
      dataSource={locationItems}
      pagination={false}
      bordered
    />
  );
};

export default LocationItemsTable;
