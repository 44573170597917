import React from "react";

// Components
import * as S from "./styles";
import { Button } from "antd";
import { OrganizationDetailsCard } from "@secondcloset/web-components";

// Hooks
import { useHistory } from "react-router";

// Recoil
import { useRecoilValue } from "recoil";
import { asnCreateFlowSelectedOrganization } from "../../../../../recoil/asnCreateFlow/atoms";

const AsnOrganizationDetails: React.FC = () => {
  const selectedOrganization = useRecoilValue(
    asnCreateFlowSelectedOrganization
  );
  const history = useHistory();

  return (
    <S.Wrapper>
      <OrganizationDetailsCard organization={selectedOrganization} />
      <S.ButtonWrapper>
        <Button
          type="primary"
          onClick={() => {
            history.push("/asn/create");
          }}
        >
          Next
        </Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default AsnOrganizationDetails;
