import styled from "styled-components";
import { COLORS } from "../../../../styles";
import { Typography } from "antd";
const { Text: AntdText, Title } = Typography;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
`;

export const Text = styled(AntdText)`
  font-size: 14px;
`;

export const InfoText = styled(Text)`
  margin: 30px 0 0;
  max-width: 800px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 50px 20px 80px;
`;

export const Card = styled.div<{ isSelected?: boolean }>`
  display: inline-block;
  position: relative;
  height: 260px;
  width: 260px;
  border-radius: 10px;
  margin: 0 40px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  border: ${({ isSelected }) =>
    isSelected
      ? `3px solid ${COLORS.YELLOW}`
      : `1px solid ${COLORS.GREY_LIGHT}`};
  background-color: ${COLORS.WHITE};
`;

interface BadgeProps {
  isRecommended?: boolean;
}

export const BadgeContainer = styled.div<BadgeProps>`
  display: ${({ isRecommended }) => (isRecommended ? "block" : "none")};
  width: 140px;
  position: absolute;
  top: -12px;
  right: -3px;
  overflow: hidden;
  height: 20px;

  font-size: 12px;
  color: ${COLORS.WHITE};

  &:before {
    content: "";
    display: block;
    top: 0;
    left: 0;
    background-color: ${COLORS.YELLOW};
    width: 160px;
    height: 160px;
    position: absolute;
    top: -113px;
    transform: rotate(45deg);
  }
`;

export const RecommendedBadge = styled.div<{ isRecommended?: boolean }>`
  position: relative;
  font-size: 11px;
  color: ${COLORS.WHITE};
  margin-left: 20px;
  line-height: 1.8;
`;

export const CardTitle = styled(Title)`
  margin: 10px auto 70px;
  height: 40px;
`;

export const Icon = styled.img`
  height: 100%;
  margin: 15px auto;
  height: 50px;
  max-width: 80px;
  overflow: hidden;
  object-fit: contain;
`;
