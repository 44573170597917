import { Common } from "@secondcloset/types";
import queryString from "query-string";
import { getAPIFromDomain } from "../../lib/api";

export const fetchOrganizationDetails = async (
  organizationID: string
): Promise<Common.Organization> => {
  const api = getAPIFromDomain("accounts");
  const url = `/organizations/${organizationID}`;
  const res = await api.get(url);
  return res.data.organization;
};

export const fetchOrganizations = async (
  search: string,
  options?: { page?: string; perPage?: string }
): Promise<Common.Organization[]> => {
  const { page, perPage } = options || {};
  const query = { q: search, page, per_page: perPage };
  const api = getAPIFromDomain("accounts");
  const url = `/organizations?${queryString.stringify(query)}`;
  const res = await api.get(url);
  return res.data;
};
