import React from "react";
import { Table } from "antd";
import { Warehouse } from "@secondcloset/types";

// types
import { PaginationInfo } from "../../../../api/warehouse/commonInterfaces";
import { buildTablePagination } from "../../../../components/Pagination/helper";

type LocationTemplate = Warehouse.LocationTemplate;

interface Props {
  locationTemplates: LocationTemplate[];
  paginationInfo?: PaginationInfo;
  loading: boolean;
  onPageChange: (page: number, newPageSize?: number) => any;
}

const LocationTemplatesTable: React.FC<Props> = ({
  locationTemplates,
  paginationInfo,
  loading,
  onPageChange,
}) => {
  const buildTableColumns = () => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Format",
        dataIndex: "format",
        key: "format",
      },
      {
        title: "DB ID",
        dataIndex: "id",
        key: "id",
      },
    ];
  };

  const buildPagination = () => {
    return buildTablePagination({
      paginationInfo,
      showQuickJumper: true,
      showSizeChanger: true,
      onChange: onPageChange,
    });
  };

  return (
    <Table
      bordered
      size="small"
      scroll={{ x: "max-content" }}
      dataSource={locationTemplates}
      columns={buildTableColumns()}
      rowKey={(r) => r.id}
      pagination={buildPagination()}
      loading={loading}
    />
  );
};

export default LocationTemplatesTable;
