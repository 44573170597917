import { Common } from "@secondcloset/types";
import queryString from "query-string";
import { getAPIFromDomain } from "../../lib/api";

type FacilityCode = Common.FacilityCode;
type ServiceArea = Common.ServiceArea;
type Facility = Common.Facility;

export const fetchFacilityIndex = async (): Promise<Facility[]> => {
  const api = getAPIFromDomain("warehouse");
  const res = await api.get("/facilities");
  return res.data as Facility[];
};

export const fetchFacilities = async (query: {
  name?: FacilityCode;
  service_area?: ServiceArea;
}): Promise<Facility[]> => {
  const api = getAPIFromDomain("warehouse");
  const url = `/facilities?${queryString.stringify(query)}`;
  const res = await api.get(url);
  return res.data;
};
