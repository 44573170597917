import styled, { CSSProperties } from "styled-components";

export const Wrapper = styled.div<{
  justifyContent: CSSProperties["justifyContent"];
}>`
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
  margin: 1em 0;
`;

export const Title = styled.div`
  font-size: 25px;
`;
