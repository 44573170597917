import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 64px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 32px 0 0;
`;
