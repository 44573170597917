import { QuestionCircleOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import {
  Button,
  Card,
  Col,
  Descriptions,
  PageHeader,
  Popconfirm,
  Popover,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

// API
import { fetchReturnById, updateReturn } from "../../../api/warehouse";

// Helpers
import { canProcessReturn, canMarkAsArrive } from "./helpers";
import { ReturnStatus, RtsCondition } from "../ReturnsIndexPage/helpers";

// Components
import Photos from "./Photos";
import ReturnItemsTable from "./ReturnItemsTable";
import StatusTag from "./StatusTag";
import WithLabel from "../../../components/WithLabel";
import PageContainer from "../../../components/PageContainer";
import NullSafeDate from "../../../components/Table/NullSafeDate";

type ReturnStatus = Warehouse.ReturnStatus;

const ReturnDetailsPage: React.FC = () => {
  const history = useHistory();
  const [error, setError] = useState("");
  const { returnId } = useParams<{ returnId: string }>();

  const {
    data: returnDetails,
    isLoading: isDetailsLoading,
    refetch: refetchReturns,
  } = useQuery(["fetchReturnById", returnId], () => fetchReturnById(returnId), {
    onError: setError,
    onSuccess: (data) => {
      if (data?.status === ReturnStatus.PROCESSING)
        history.push(
          `/returns/process-${
            data?.type?.toUpperCase() === "RTS" ? "rts" : "regular"
          }/${returnId}`
        );
    },
  });

  const { mutate: markAsArrived, isLoading: isMarkAsArriveLoading } =
    useMutation(
      () => updateReturn(returnId, { status: ReturnStatus.ARRIVED }),
      {
        onSuccess: () => {
          setError("");
          refetchReturns({ cancelRefetch: true, throwOnError: true });
        },
        onError: setError,
      }
    );

  const { mutate: markAsProcessing, isLoading: isMarkAsProcessingLoading } =
    useMutation(
      () => updateReturn(returnId, { status: ReturnStatus.PROCESSING }),
      {
        onSuccess: () =>
          history.push(
            `/returns/process-${
              returnDetails?.type === "RTS" ? "rts" : "regular"
            }/${returnId}`
          ),
        onError: setError,
      }
    );

  const renderButtons = () => (
    <Row justify="end" gutter={[8, 8]}>
      <Col xs={24} lg={8} xl={7}>
        <Button
          size="large"
          type="primary"
          title="Process Return"
          block
          loading={isMarkAsProcessingLoading || isMarkAsArriveLoading}
          disabled={!canProcessReturn(returnDetails?.status)}
          onClick={() => markAsProcessing()}
        >
          Process Return
        </Button>
      </Col>
      <Col xs={24} lg={8} xl={7}>
        <Popconfirm
          title="Are you sure？"
          okText="Yes"
          cancelText="No"
          disabled={!canMarkAsArrive(returnDetails?.status)}
          onConfirm={() => markAsArrived()}
        >
          <Button
            size="large"
            title="Mark as Arrived"
            block
            loading={isMarkAsProcessingLoading || isMarkAsArriveLoading}
            disabled={!canMarkAsArrive(returnDetails?.status)}
          >
            Mark as Arrived
          </Button>
        </Popconfirm>
      </Col>
    </Row>
  );

  const renderReturnDetails = () => (
    <Descriptions bordered size="small" column={{ xs: 1, lg: 2 }}>
      <Descriptions.Item label="Status">
        <StatusTag status={returnDetails?.status} />
        {returnDetails?.status?.toLowerCase() === "needs_review" && (
          <Popover
            placement="bottom"
            content="We need a response from the merchant before processing this return."
          >
            <QuestionCircleOutlined />
          </Popover>
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Requested At" span={2}>
        <NullSafeDate date={returnDetails?.created_at} />
      </Descriptions.Item>
      <Descriptions.Item label="RMA">{returnDetails?.rma}</Descriptions.Item>
      <Descriptions.Item label="Type" span={2}>
        {returnDetails?.type}
      </Descriptions.Item>
      <Descriptions.Item label="Organization">
        {returnDetails?.organization?.name || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Customer Name" span={2}>
        {returnDetails?.shipping_from?.name || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="External Order #" span={2}>
        <Typography.Text copyable={!!returnDetails?.external_order_number}>
          {returnDetails?.external_order_number || "Not Available"}
        </Typography.Text>
      </Descriptions.Item>
      <Descriptions.Item label="Original Tracking #" span={2}>
        <Typography.Text copyable={!!returnDetails?.tracking_number}>
          {returnDetails?.tracking_number || "Not Available"}
        </Typography.Text>
      </Descriptions.Item>
      {returnDetails?.type === "RTS" && (
        <Descriptions.Item label="Reship External Order #" span={2}>
          {returnDetails?.reship_external_order_number || "-"}
        </Descriptions.Item>
      )}
      <Descriptions.Item label="Return Carrier" span={2}>
        {returnDetails?.carrier || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Merchant Note" span={2}>
        {returnDetails?.external_notes || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Internal Note (Bolt)" span={2}>
        {returnDetails?.internal_notes || "-"}
      </Descriptions.Item>
    </Descriptions>
  );

  const renderItemsTable = () => (
    <ReturnItemsTable returnDetails={returnDetails} />
  );

  const renderPhotos = () => <Photos returnDetails={returnDetails} />;

  const renderRTSReason = () => (
    <WithLabel name="RTS Reason*">
      <Typography.Text>
        {RtsCondition?.[returnDetails?.reason as string] || "-"}
      </Typography.Text>
    </WithLabel>
  );

  const renderRTSReasonNotes = () => (
    <WithLabel name="RTS Reason Notes*">
      <Typography.Text>{returnDetails?.reason_notes || "-"}</Typography.Text>
    </WithLabel>
  );

  const renderRTSMeta = () => {
    return (
      <Card>
        <Row gutter={[8, 8]}>
          <Col xs={24} lg={12}>
            {renderRTSReason()}
          </Col>
          <Col xs={24} lg={12}>
            {renderRTSReasonNotes()}
          </Col>
        </Row>
      </Card>
    );
  };

  const renderToolbar = () => {
    return (
      <Row justify="space-between" gutter={[8, 16]}>
        <Col xs={24} lg={12}>
          <Typography.Title>{returnDetails?.rma}</Typography.Title>
        </Col>
        <Col xs={24} lg={12}>
          {renderButtons()}
        </Col>
      </Row>
    );
  };

  return (
    <PageContainer withPadding>
      <Spin spinning={isDetailsLoading}>
        <PageHeader title="RMA Detail" onBack={() => window.history.back()} />
        <Space direction="vertical" style={{ width: "100%" }}>
          <ErrorAlert error={error} />
          {renderToolbar()}
          {renderReturnDetails()}
          {returnDetails?.type === "RTS" && renderRTSMeta()}
          {renderPhotos()}
          {renderItemsTable()}
        </Space>
      </Spin>
    </PageContainer>
  );
};

export default ReturnDetailsPage;
