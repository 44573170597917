import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { Button, Col, Input, PageHeader, Row, Space, Typography } from "antd";
import { SwapOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";

// hooks
import useUrlState from "@ahooksjs/use-url-state";

// api
import { fetchLocationItems } from "../../../api/warehouse";

// components
import WithLabel from "../../../components/WithLabel";
import PageContainer from "../../../components/PageContainer";
import LocationItemsTable from "./LocationItemsTable";

type LocationItem = Warehouse.LocationItem;

const LocationDetailsPage: React.FC = () => {
  const params = useParams<{ code: string }>();
  const [{ page, itemSearch }, setUrlState] = useUrlState(
    {
      itemSearch: undefined,
      page: 1,
    },
    { navigateMode: "replace" }
  );
  const history = useHistory();
  const [error, setError] = useState("");

  const paginatedLocationItems = useQuery(
    ["fetchLocations", itemSearch, page],
    () => {
      setError("");
      return fetchLocationItems({
        location_code: params.code,
        identifier: itemSearch,
        page,
      });
    },
    { onError: (e: string) => setError(e) }
  );

  const getLocationItems = useCallback((): LocationItem[] => {
    return paginatedLocationItems.data?.items || [];
  }, [paginatedLocationItems]);

  const renderSkuSearchBar = () => {
    return (
      <WithLabel name="Item">
        <Input.Search
          size="large"
          allowClear
          onSearch={(search: string) =>
            setUrlState({ itemSearch: search, page: 1 })
          }
          placeholder="Search by SKU, UPC, or Manual Item"
          defaultValue={itemSearch}
          autoCapitalize="off"
        />
      </WithLabel>
    );
  };

  const renderMoveButton = () => {
    return (
      <Button
        size="large"
        type="primary"
        icon={<SwapOutlined />}
        block
        onClick={() => history.push("/batch-move-items")}
      >
        Move Items
      </Button>
    );
  };

  const renderAuditButton = () => {
    return (
      <Button
        size="large"
        icon={<ZoomInOutlined />}
        block
        onClick={() =>
          history.push(`/inventory-audit?location_code=${params?.code}`)
        }
      >
        Audit Items
      </Button>
    );
  };

  const renderButtonGroups = () => {
    return (
      <Row justify="end" gutter={[8, 16]}>
        <Col xs={24} lg={8} xl={7}>
          {renderAuditButton()}
        </Col>
        <Col xs={24} lg={8} xl={7}>
          {renderMoveButton()}
        </Col>
      </Row>
    );
  };

  const renderToolbar = () => {
    return (
      <Row align="bottom" justify="space-between" gutter={[8, 16]}>
        <Col xs={24} lg={12}>
          {renderSkuSearchBar()}
        </Col>
        <Col xs={24} lg={12}>
          {renderButtonGroups()}
        </Col>
      </Row>
    );
  };

  return (
    <PageContainer withPadding>
      <PageHeader
        title="Location Detail"
        onBack={() => window.history.back()}
      />
      <Typography.Title>{params.code || "-"}</Typography.Title>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <ErrorAlert error={error} />
        {renderToolbar()}
        <LocationItemsTable
          locationItems={getLocationItems()}
          paginationInfo={paginatedLocationItems.data?.meta}
          loading={paginatedLocationItems.isLoading}
          onPageChange={(p: number) => setUrlState({ page: p })}
        />
      </Space>
    </PageContainer>
  );
};

export default LocationDetailsPage;
