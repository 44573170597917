import React from "react";
import { useQuery } from "react-query";
import { startCase } from "lodash-es";
import { useLocation, useHistory } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import { Drawer, Button, Badge, Space, Avatar, Typography } from "antd";
import { HomeOutlined, LogoutOutlined, SyncOutlined } from "@ant-design/icons";

//lib
import Cookie from "../../lib/cookie";
import { checkForUpdate } from "../../lib/notifyNewVersion";
import { userState } from "../../recoil/user/atoms";
import {
  menuExpandedState,
  menuTitleState,
} from "../../recoil/menuControls/atoms";

//styles
import * as S from "./styles";

//assets
import { version } from "../../../package.json";
import Heindrik from "../../assets/heindrik.jpeg";
import SpaceRoadster from "../../assets/space-roadster.jpeg";
import { useResetUser } from "../../recoil/user/helpers";

const AppHeader: React.FC = () => {
  const title = useRecoilValue(menuTitleState);
  const [isMenuExpanded, dispatchMenuExpanded] =
    useRecoilState(menuExpandedState);
  const user = useRecoilValue(userState);
  const dispatchResetUser = useResetUser();
  const location = useLocation();
  const history = useHistory();
  const hasUpdate = useQuery("update", () => checkForUpdate(true)).data;

  if (location.pathname.includes("login")) return null;

  const handleSignOut = () => {
    Cookie.removeCredential();
    dispatchResetUser();
    history.push("/login");
    dispatchMenuExpanded(false);
  };

  const renderAppBrand = () => {
    return (
      <S.Brand direction="vertical" size={-6} align="center">
        <Typography.Title level={4}>{title}</Typography.Title>
        <Typography.Text type="secondary">{version}</Typography.Text>
      </S.Brand>
    );
  };

  const renderUserProfile = () => (
    <S.Profile>
      <Space size={0} align="center">
        <Avatar
          size="large"
          src={
            user?.email === "anthony@secondcloset.com"
              ? SpaceRoadster
              : Heindrik
          }
        />
        <S.NameTag direction="vertical" align="start" size={6}>
          <Typography.Text>
            {startCase(user?.first_name)} {startCase(user?.last_name)}
          </Typography.Text>
          <Typography.Text type="secondary">
            {user?.subrole?.toUpperCase()}
          </Typography.Text>
        </S.NameTag>
      </Space>
    </S.Profile>
  );

  const renderDrawer = () => (
    <Drawer
      closable={false}
      visible={isMenuExpanded}
      onClose={() => dispatchMenuExpanded(false)}
      title={renderUserProfile()}
      placement="right"
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Button
          type="text"
          size="large"
          icon={<HomeOutlined />}
          block
          style={{ textAlign: "start" }}
          onClick={() => {
            history.push("/");
            dispatchMenuExpanded(false);
          }}
        >
          HOME
        </Button>
        <Button
          type="text"
          size="large"
          icon={<LogoutOutlined />}
          block
          style={{ textAlign: "start" }}
          danger
          onClick={handleSignOut}
        >
          SIGN OUT
        </Button>
      </Space>
    </Drawer>
  );

  const renderHomeButton = () => (
    <Button
      size="large"
      icon={<HomeOutlined />}
      onClick={() => history.push("/")}
    ></Button>
  );

  const renderUpdateButton = () => (
    <Badge count={hasUpdate ? 1 : 0} offset={[-5, 5]}>
      <Button
        size="large"
        type={hasUpdate ? "primary" : "default"}
        icon={<SyncOutlined />}
        onClick={() => checkForUpdate()}
      ></Button>
    </Badge>
  );

  const renderProfileButton = () => (
    <S.ProfileButton type="text" onClick={() => dispatchMenuExpanded(true)}>
      {renderUserProfile()}
    </S.ProfileButton>
  );

  const renderLeftMenu = () => (
    <S.Menu style={{ justifyContent: "flex-start" }}>
      {renderHomeButton()}
      {hasUpdate && renderUpdateButton()}
    </S.Menu>
  );

  const renderRightMenu = () => (
    <S.Menu style={{ justifyContent: "flex-end" }}>
      {user && renderProfileButton()}
    </S.Menu>
  );

  return (
    <>
      <S.Header>
        {renderLeftMenu()}
        {renderAppBrand()}
        {renderRightMenu()}
      </S.Header>
      {renderDrawer()}
    </>
  );
};

export default AppHeader;
