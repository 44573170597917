// Helpers
import { Order } from "@secondcloset/fulfillment-utils";
import { Fulfillment, Warehouse } from "@secondcloset/types";
import { cloneDeep, groupBy } from "lodash-es";
import { useMutation } from "react-query";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";

import { fetchSupplyByCode } from "../../api/warehouse";
import { ShipmentProductsTrackingCreateItem } from "../../api/warehouse/integrations";

import Organization from "../../lib/organization";
import replaceItemAtIndex from "../../lib/replaceItemAtIndex";
import Shipment from "../../lib/shipment";
import * as Supplies from "../../lib/supplies";

import {
  currentPackageItemIndexState,
  isUserConfirmedPoBoxAddressState,
  LotTrackingList,
  PackageContainer,
  PackageInsurance,
  PackageOrderItemIDs,
  PackageType,
  PackageWeight,
  packingFlowCurrentPackageState,
  packingFlowOrderState,
  packingFlowPackageContainerState,
  packingFlowPackageInsuranceState,
  packingFlowPackageOrderItemIDsState,
  packingFlowPackageTypeState,
  packingFlowPackageWeightState,
  packingFlowScannedItemCode,
  packingFlowShipmentState,
  packingFlowShippingMethodState,
  packingFlowShippingRateState,
  packingFlowStepState,
  shipmentProductTrackingListState,
  shipmentSuppliesState,
  SupplyListItem,
  supplyUsedQuantityState,
} from "./atoms";

import { checkSupplyIsBox } from "../../pages/PackingFlow/PackShipmentPage/SelectItemStep/AddPackageItem/helpers";

const { convertLengthToInches } = Shipment;

type Supply = Warehouse.Supply;

export const useResetPackingFlow = (): ((options?: {
  excludeStep?: boolean;
}) => void) => {
  const resetStep = useResetRecoilState(packingFlowStepState);
  const resetOrder = useResetRecoilState(packingFlowOrderState);
  const resetPackingItemIDs = useResetRecoilState(
    packingFlowPackageOrderItemIDsState
  );
  const resetCurrentPackageItemIndex = useResetRecoilState(
    currentPackageItemIndexState
  );
  const resetShippingRate = useResetRecoilState(packingFlowShippingRateState);
  const resetShipment = useResetRecoilState(packingFlowShipmentState);
  const resetCurrentPackage = useResetRecoilState(
    packingFlowCurrentPackageState
  );
  const resetShippingMethod = useResetRecoilState(
    packingFlowShippingMethodState
  );
  const resetPackageType = useResetRecoilState(packingFlowPackageTypeState);
  const resetPackageContainers = useResetRecoilState(
    packingFlowPackageContainerState
  );
  const resetPackageOrderItemIDs = useResetRecoilState(
    packingFlowPackageOrderItemIDsState
  );
  const resetPackageWeights = useResetRecoilState(
    packingFlowPackageWeightState
  );
  const resetSupplyUsedQuantity = useResetRecoilState(supplyUsedQuantityState);
  const resetShipmentSuppliesState = useResetRecoilState(shipmentSuppliesState);
  const resetIsUserConfirmedPoBoxAddressState = useResetRecoilState(
    isUserConfirmedPoBoxAddressState
  );

  return (options) => {
    if (!options?.excludeStep) {
      resetStep();
      resetOrder();
      resetShipment();
    }
    resetPackageType();
    resetPackingItemIDs();
    resetShippingRate();
    resetCurrentPackage();
    resetShippingMethod();
    resetPackageContainers();
    resetPackageOrderItemIDs();
    resetPackageWeights();
    resetCurrentPackageItemIndex();
    resetSupplyUsedQuantity();
    resetShipmentSuppliesState();
    resetIsUserConfirmedPoBoxAddressState();
  };
};

interface DefaultPackage {
  length: number;
  width: number;
  height: number;
  packageType: PackageType;
}

export const defaultPackage: {
  box: DefaultPackage;
  standard_pallet: DefaultPackage;
  double_pallet: DefaultPackage;
} = {
  box: {
    length: 0,
    width: 0,
    height: 0,
    packageType: PackageType.box,
  },
  standard_pallet: {
    length: 48,
    width: 40,
    height: 0,
    packageType: PackageType.standard_pallet,
  },
  double_pallet: {
    length: 96,
    width: 48,
    height: 0,
    packageType: PackageType.double_pallet,
  },
};

export const usePresetPackageWeights = (): ((
  packageType: PackageType
) => void) => {
  const [packageContainers, setPackageContainers] = useRecoilState(
    packingFlowPackageContainerState
  );

  const presetPackageWeights = (packageType: PackageType) => {
    const updatedPackageContainers = packageContainers.map((container) => {
      const newPackageItem = cloneDeep(container);
      Object.entries(defaultPackage[packageType]).forEach(([key, value]) => {
        newPackageItem[key] = value;
      });
      return newPackageItem;
    });

    setPackageContainers(updatedPackageContainers);
  };

  return presetPackageWeights;
};

export const useUpdateShipmentProductTrackingList = () => {
  const [shipmentProductTrackingList, setShipmentProductTrackingList] =
    useRecoilState(shipmentProductTrackingListState);

  const addLotTrackingListToPackage = (
    packageIndex: number,
    lotTrackingList: LotTrackingList
  ) => {
    const newShipmentProductTrackingList = cloneDeep(
      shipmentProductTrackingList
    );

    if (!newShipmentProductTrackingList[packageIndex]) {
      newShipmentProductTrackingList[packageIndex] = { lotTrackingList: {} };
    }

    const currentList =
      newShipmentProductTrackingList[packageIndex].lotTrackingList;

    const newList = Object.entries(lotTrackingList).reduce((acc, cv) => {
      const [productID, newTrackings] = cv;
      if (!acc[productID]) acc[productID] = newTrackings;
      else acc[productID] = [...acc[productID], ...newTrackings];
      return acc;
    }, currentList);

    newShipmentProductTrackingList[packageIndex].lotTrackingList = newList;

    setShipmentProductTrackingList(newShipmentProductTrackingList);
  };

  const removeLotTrackingFromPackage = (
    packageIndex: number,
    productID: string
  ) => {
    const newShipmentProductTrackingList = cloneDeep(
      shipmentProductTrackingList
    );

    const targetTrackingList = newShipmentProductTrackingList[packageIndex];
    if (!targetTrackingList) return;
    const packageTracking = targetTrackingList.lotTrackingList;
    delete packageTracking[productID];

    setShipmentProductTrackingList(newShipmentProductTrackingList);
  };

  const removeLotTrackingForFullPackage = (packageIndex: number) => {
    const newShipmentProductTrackingList = cloneDeep(
      shipmentProductTrackingList
    );

    delete newShipmentProductTrackingList[packageIndex];
    setShipmentProductTrackingList(newShipmentProductTrackingList);
  };

  return {
    addLotTrackingListToPackage,
    removeLotTrackingFromPackage,
    removeLotTrackingForFullPackage,
  };
};

export const useReshapeShipmentProductTrackingList = () => {
  const trackingList = useRecoilValue(shipmentProductTrackingListState);

  const packingOrder = useRecoilValue(packingFlowOrderState);

  const getGroupedTrackingList = () => {
    const combinedTracking = Object.values(trackingList).reduce((acc, cv) => {
      const { lotTrackingList } = cv;
      Object.entries(lotTrackingList).map(([productID, trackings]) => {
        if (!acc[productID]) acc[productID] = [];
        acc[productID] = [...acc[productID], ...trackings];
      });
      return acc;
    }, {});

    return Object.entries(combinedTracking).reduce((acc, cv) => {
      const [productID, trackings] = cv;
      acc[productID] = groupBy(
        trackings as any,
        (t) => `${t.lotNumber}-${t.serialNumber}`
      );
      return acc;
    }, {});
  };

  const getGroupedTrackingListWithQuantity = (): {
    name: string;
    lotNumber: string;
    productID: string;
    serialNumber: string;
    quantity: number;
  }[] => {
    const groupedTrackingList = getGroupedTrackingList();
    const groupedList = Object.entries(groupedTrackingList).flatMap(
      ([productID, groupedTracking]) => {
        return Object.values(groupedTracking as any).map((t: any) => {
          const { name, lotNumber, serialNumber } = t[0];
          const quantity = t.length;
          return { name, lotNumber, serialNumber, quantity, productID };
        });
      }
    );
    return groupedList.filter((t) => t.lotNumber || t.serialNumber);
  };

  const getCreateShipmentProductTrackingBody =
    (): ShipmentProductsTrackingCreateItem[] => {
      const productMap: { [index: string]: Fulfillment.Product } =
        packingOrder?.items?.reduce((acc, cv) => {
          const product = cv.product || {};
          const productID = product.id;
          if (!acc[productID]) acc[productID] = product;
          return acc;
        }, {}) || {};

      const groupedTrackingListWithQuantity =
        getGroupedTrackingListWithQuantity();

      return groupedTrackingListWithQuantity.map((i) => {
        const product = productMap[i.productID];
        return {
          external_product_id: product?.id || "",
          lot_number: i.lotNumber,
          serial_number: i.serialNumber,
          product_name: product?.name || "",
          product_sku: product?.sku || "",
          product_upc: product?.upc || "",
          quantity: i.quantity || 0,
        };
      });
    };

  return {
    getGroupedTrackingListWithQuantity,
    getCreateShipmentProductTrackingBody,
  };
};

export const useUpdatePackageSupplies = () => {
  const [shipmentSupplies, setShipmentSupplies] = useRecoilState(
    shipmentSuppliesState
  );
  const [supplyUsedQuantity, setSupplyUsedQuantity] = useRecoilState(
    supplyUsedQuantityState
  );
  const currentPackageItemIndex = useRecoilValue(currentPackageItemIndexState);

  const handleUpdateUsedQuantity = (supplies: SupplyListItem[]) => {
    const newUsedQuantity = { ...supplyUsedQuantity };
    supplies?.forEach((supply) => {
      newUsedQuantity[supply.id] = newUsedQuantity[supply.id] - supply.quantity;
    });
    setSupplyUsedQuantity(newUsedQuantity);
  };

  const handleAddSupplies = () => {
    const updatedShipmentSupplies = cloneDeep(shipmentSupplies);
    updatedShipmentSupplies.push([]);
    setShipmentSupplies(updatedShipmentSupplies);
  };

  const handleDeleteSupplies = () => {
    const updatedShipmentSupplies = cloneDeep(shipmentSupplies);
    const supplyToBeDeleted = updatedShipmentSupplies[currentPackageItemIndex];
    handleUpdateUsedQuantity(supplyToBeDeleted);
    updatedShipmentSupplies?.splice(currentPackageItemIndex, 1);
    setShipmentSupplies(updatedShipmentSupplies);
  };

  return { handleDeleteSupplies, handleAddSupplies };
};

export const useEditPackage = () => {
  const [packageOrderItemIDs, setPackageOrderItemIDs] = useRecoilState(
    packingFlowPackageOrderItemIDsState
  );
  const [packageContainers, setPackageContainers] = useRecoilState(
    packingFlowPackageContainerState
  );
  const [packageInsurance, setPackageInsurance] = useRecoilState(
    packingFlowPackageInsuranceState
  );
  const [packageWeights, setPackageWeights] = useRecoilState(
    packingFlowPackageWeightState
  );
  const packageType = useRecoilValue(packingFlowPackageTypeState);
  const [currentPackageItemIndex, setCurrentPackageItemIndex] = useRecoilState(
    currentPackageItemIndexState
  );
  const { handleAddSupplies, handleDeleteSupplies } =
    useUpdatePackageSupplies();
  const { removeLotTrackingForFullPackage } =
    useUpdateShipmentProductTrackingList();

  const updatePackageOrderItemIDs = (orderItemIDs: string[]) => {
    const newPackageOrderItemIDs = packageOrderItemIDs.reduce(
      (acc, cv, index) => {
        if (index !== currentPackageItemIndex) acc.push(cv);
        else acc.push({ orderItemIDs });
        return acc;
      },
      [] as PackageOrderItemIDs[]
    );
    setPackageOrderItemIDs(newPackageOrderItemIDs);
  };

  const replaceCurrentContainerWith = (container: PackageContainer) => {
    const newContainers = replaceItemAtIndex(
      packageContainers,
      currentPackageItemIndex,
      container
    );
    setPackageContainers(newContainers);
  };

  const replaceCurrentPackageInsuranceWith = (insurance: PackageInsurance) => {
    const newInsurances = replaceItemAtIndex(
      packageInsurance,
      currentPackageItemIndex,
      insurance
    );
    setPackageInsurance(newInsurances);
  };

  const replaceCurrentPackageWeightWith = (weight: PackageWeight) => {
    const newWeights = replaceItemAtIndex(
      packageWeights,
      currentPackageItemIndex,
      weight
    );
    setPackageWeights(newWeights);
  };

  const resetCurrentContainer = () => {
    const defaultContainerState = {
      length: 0,
      width: 0,
      height: 0,
      isCustomDimensions: false,
    };
    const newContainers = replaceItemAtIndex(
      packageContainers,
      currentPackageItemIndex,
      defaultContainerState
    );
    setPackageContainers(newContainers);
  };

  const addPackage = () => {
    const updatedPackageContainers = [
      ...packageContainers,
      defaultPackage[packageType],
    ] as PackageContainer[];
    setPackageContainers(updatedPackageContainers);

    const updatedOrderItemIDs = [...packageOrderItemIDs, { orderItemIDs: [] }];
    setPackageOrderItemIDs(updatedOrderItemIDs);

    const updatedWeights = [
      ...packageWeights,
      { weight: 0, manuallyEdited: false },
    ] as PackageWeight[];
    setPackageWeights(updatedWeights);
    setCurrentPackageItemIndex(updatedPackageContainers.length - 1);
    handleAddSupplies();
  };

  const deletePackage = () => {
    const { updatedContainers, updatedOrderItemIDs, updatedWeights } =
      packageContainers.reduce(
        (acc, cv, index) => {
          if (index !== currentPackageItemIndex) {
            acc.updatedContainers.push(cv);
            acc.updatedOrderItemIDs.push(packageOrderItemIDs[index]);
            acc.updatedWeights.push(packageWeights[index]);
          }
          return acc;
        },
        {
          updatedContainers: [] as PackageContainer[],
          updatedOrderItemIDs: [] as PackageOrderItemIDs[],
          updatedWeights: [] as PackageWeight[],
        }
      );

    if (currentPackageItemIndex !== 0) {
      setCurrentPackageItemIndex(currentPackageItemIndex - 1);
      removeLotTrackingForFullPackage(currentPackageItemIndex);
    }
    handleDeleteSupplies();
    setPackageContainers(updatedContainers);
    setPackageOrderItemIDs(updatedOrderItemIDs);
    setPackageWeights(updatedWeights);
  };

  return {
    updatePackageOrderItemIDs,
    replaceCurrentContainerWith,
    replaceCurrentPackageWeightWith,
    replaceCurrentPackageInsuranceWith,
    addPackage,
    deletePackage,
    resetCurrentContainer,
  };
};

export const useUpdatePackageItems = () => {
  const shipment = useRecoilValue(packingFlowShipmentState);
  const packingOrder = useRecoilValue(packingFlowOrderState);
  const packageOrderItemIDs = useRecoilValue(
    packingFlowPackageOrderItemIDsState
  );
  const currentPackageItemIndex = useRecoilValue(currentPackageItemIndexState);

  const getAllPackageOrderItemsIDs = () => {
    return packageOrderItemIDs?.flatMap((pckg) => pckg?.orderItemIDs) ?? [];
  };

  const calculateUnpackedOrderItems = () => {
    if (!shipment) return [];
    const packedItemIds = getAllPackageOrderItemsIDs();
    const orderItems = packingOrder?.items || [];
    return orderItems.filter(
      (orderItem) => !packedItemIds.includes(orderItem.id)
    );
  };

  const calculateShippableOrderItemIds = () => {
    const shippedShipmentItemIds =
      shipment?.packages.flatMap((pkg) => pkg.shipment_item_ids) || [];

    const shippableShipmentItems = shipment?.shipment_items.filter(
      (shipmentItem) => {
        return !shippedShipmentItemIds.includes(shipmentItem.id);
      }
    );

    return packingOrder?.items.reduce((acc, item) => {
      const shippableItem = shippableShipmentItems?.find(
        (shipmentItem) =>
          shipmentItem.order_item_id === item.id ||
          shipmentItem.shipment_item_group?.external_group_id === item.id
      );
      if (shippableItem) {
        acc.push(item.id);
      }
      return acc;
    }, [] as string[]);
  };

  const calculateShippableOrderItems = () => {
    const shippableOrderItemIds = calculateShippableOrderItemIds();
    const unpackedOrderItems = calculateUnpackedOrderItems();
    const allPackageOrderItemsIDs = getAllPackageOrderItemsIDs();
    return unpackedOrderItems.filter((i) => {
      return (
        !allPackageOrderItemsIDs.includes(i.id) &&
        shippableOrderItemIds?.includes(i.id)
      );
    });
  };

  const getShippableItems = () => {
    const shippableOrderItemIds = calculateShippableOrderItemIds();
    const unpackedOrderItems = calculateUnpackedOrderItems();
    const allPackageOrderItemsIDs = getAllPackageOrderItemsIDs();
    return unpackedOrderItems.filter((i) => {
      return (
        !allPackageOrderItemsIDs.includes(i.id) &&
        shippableOrderItemIds?.includes(i.id)
      );
    });
  };

  const calculateUnpackedTableItems = () => {
    const items = getShippableItems();
    const virtualKitItems = Order.getVirtualKitItems(items);
    const baseProductItems = Order.getBaseProductItems(items);

    const kit = Order.constructVirtualKitItems(
      virtualKitItems,
      baseProductItems
    );
    const base = Order.constructBaseProductItems(baseProductItems, true);
    const kits = Object.values(kit).map((item) => ({
      ...item,
      base_products: item.base_products?.map((base) => ({
        ...base,
        isPartOfKit: true,
      })),
    }));
    return [...kits, ...Object.values(base)].filter(
      (item) => item.orderItemIDs.length > 0
    );
  };

  const calculateExpandedRowKeys = () => {
    const items = getShippableItems();
    const virtualKitItems = Order.getVirtualKitItems(items);
    const baseProductItems = Order.getBaseProductItems(items);
    const kit = Order.constructVirtualKitItems(
      virtualKitItems,
      baseProductItems
    );
    return Object.keys(kit);
  };

  const calculatePackedTableItems = () => {
    const currentPackageOrderItemIDs =
      packageOrderItemIDs[currentPackageItemIndex]?.orderItemIDs || [];
    const removableOrderItems =
      packingOrder?.items.filter((orderItem) =>
        currentPackageOrderItemIDs.includes(orderItem.id)
      ) || [];
    return Object.values(Order.constructBaseProductItems(removableOrderItems));
  };

  return {
    getShippableItems,
    getAllPackageOrderItemsIDs,
    calculateShippableOrderItems,
    calculateUnpackedOrderItems,
    calculateShippableOrderItemIds,
    calculateUnpackedTableItems,
    calculatePackedTableItems,
    calculateExpandedRowKeys,
  };
};

export const useBoxPackaging = () => {
  const packageOrderItemIDs = useRecoilValue(
    packingFlowPackageOrderItemIDsState
  );
  const currentPackageIndex = useRecoilValue(currentPackageItemIndexState);
  const currentPackageItemIndex = useRecoilValue(currentPackageItemIndexState);
  const scannedItemCode = useRecoilValue(packingFlowScannedItemCode);
  const packingOrder = useRecoilValue(packingFlowOrderState);
  const packageContainers = useRecoilValue(packingFlowPackageContainerState);
  const shipment = useRecoilValue(packingFlowShipmentState);
  const { replaceCurrentContainerWith } = useEditPackage();
  const currentPackageContainer = packageContainers[currentPackageItemIndex];
  const [supplyUsedQuantity, setSupplyUsedQuantity] = useRecoilState(
    supplyUsedQuantityState
  );
  const [shipmentSupplies, setShipmentSupplies] = useRecoilState(
    shipmentSuppliesState
  );
  const packageType = useRecoilValue(packingFlowPackageTypeState);

  const getAvailableStock = (supply: Supply) => {
    return Supplies.getStockFromFacility(supply, packingOrder?.fulfilled_from);
  };

  const getCurrentlyAvailableStock = (supply: Supply) => {
    const availableStock = getAvailableStock(supply);
    const currentlyAvailableStock =
      supplyUsedQuantity?.[supply?.id] === undefined
        ? availableStock
        : supplyUsedQuantity?.[supply?.id];
    return currentlyAvailableStock;
  };

  const checkStockLevels = (supply: Supply) => {
    const availableStock = getAvailableStock(supply);
    const isBox = checkSupplyIsBox(supply);
    if (isBox) {
      const existingCount = packageContainers?.filter(
        (_) => _?.boxSupplyId === supply.id
      ).length;
      const hasStock = availableStock - existingCount > 0;
      return hasStock;
      //  Check stock levels for box
    }
    // Check stock levels for supplies
    const currentlyAvailableStock = getCurrentlyAvailableStock(supply);
    // Check if at least 1 exists since we are adding 1 at a time
    const hasStock = currentlyAvailableStock > 0;
    return hasStock;
  };

  const getDimensionString = (box: Supply) => {
    const { length, width, height, dimension_unit } = box;
    let result = `${length}${dimension_unit}`;
    if (width) {
      result += ` x ${width}${dimension_unit}`;
      if (height) {
        result += ` x ${height}${dimension_unit}`;
      }
    }
    return result;
  };

  const formatSupplyToBox = (box: Supply) => {
    const dimensions = getDimensionString(box);
    const { length, width, height, dimension_unit, weight, weight_unit } = box;
    const formattedBox = {
      length: convertLengthToInches(length, dimension_unit),
      width: convertLengthToInches(width, dimension_unit),
      height: convertLengthToInches(height, dimension_unit),
      displayName: `${box.name} ( ${dimensions} )`,
      boxSupplyId: box.id,
      packageType: PackageType.box,
      isCustomDimensions: false,
      boxWeight: weight,
      boxWeightUnit: weight_unit,
    };
    return formattedBox;
  };

  const handleAddSupplyToShipmentSupplies = (supply: Supply) => {
    const currentSupplies: SupplyListItem[] =
      shipmentSupplies[`${currentPackageIndex}`] || [];
    const newSupplies = cloneDeep(currentSupplies);
    const foundSupplyItemIndex = newSupplies?.findIndex(
      (s) => s.id === supply.id
    );
    if (foundSupplyItemIndex < 0) {
      newSupplies.push({
        ...supply,
        quantity: 1,
      });
    } else {
      const foundSupplyItem = newSupplies[foundSupplyItemIndex];
      foundSupplyItem.quantity += 1;
      newSupplies.splice(foundSupplyItemIndex, 1, foundSupplyItem);
    }
    const newShipmentSupplies = cloneDeep(shipmentSupplies);
    newShipmentSupplies.splice(currentPackageIndex, 1, newSupplies);
    setShipmentSupplies(newShipmentSupplies);

    const currentlyAvailableStock = getCurrentlyAvailableStock(supply);
    const newStockValue = currentlyAvailableStock - 1;

    setSupplyUsedQuantity({ [supply?.id]: newStockValue });
  };

  const boxExists =
    currentPackageContainer?.boxSupplyId ||
    currentPackageContainer?.isCustomDimensions;

  const handleFoundSupply = (supply: Supply) => {
    // If supply type is box and we already have a box added to the current package, block the flow
    const isSupplyABox = checkSupplyIsBox(supply);
    if (isSupplyABox && boxExists) {
      throw new Error(`Only one box can be added per package.`);
    }

    // If freight pallet flow, no box can be scanned
    if (isSupplyABox && packageType !== PackageType.box) {
      throw new Error(`Cannot scan a box in freight pallet flow.`);
    }

    // Check is stock exists for supply
    const hasStock = checkStockLevels(supply);
    if (!hasStock) {
      throw new Error(`Insufficient stock for this supply.`);
    }

    // If the supply is not a box, we add to supplies array
    if (!isSupplyABox) {
      return handleAddSupplyToShipmentSupplies(supply);
    }

    // If supply is a box that is not added to the table, we add the box as the current packaging container
    if (isSupplyABox && !boxExists) {
      const boxToAdd = formatSupplyToBox(supply);
      return replaceCurrentContainerWith(boxToAdd);
    }
  };

  const { mutateAsync: getSupplyByCode, isLoading: getSupplyByCodeLoading } =
    useMutation(
      () => {
        return fetchSupplyByCode({
          code: scannedItemCode?.trim(),
          with_stock: true,
          organization_ids: [
            shipment?.organization_id || "",
            Organization.getSecondClosetOrgID(),
          ],
        });
      },
      {
        onSuccess: async (supply: Warehouse.Supply) =>
          handleFoundSupply(supply),
      }
    );

  const checkIsAddedItemsTableEmpty = () => {
    const currentSupplies: SupplyListItem[] =
      shipmentSupplies[`${currentPackageIndex}`] || [];
    const currentPackageOrderItemIDs =
      packageOrderItemIDs[currentPackageItemIndex]?.orderItemIDs || [];

    const noPackageItemsAdded = !currentPackageOrderItemIDs?.length;
    const noPackagingAdded =
      !currentPackageContainer?.boxSupplyId &&
      !currentPackageContainer?.isCustomDimensions;
    const noSuppliesAdded = !currentSupplies.length;

    return noPackageItemsAdded && noPackagingAdded && noSuppliesAdded;
  };

  return {
    getSupplyByCode,
    getSupplyByCodeLoading,
    checkIsAddedItemsTableEmpty,
  };
};
