import { Warehouse } from "@secondcloset/types";
import queryString from "query-string";
import { getAPIFromDomain } from "../../lib/api";
// interface
import { PaginatedData, PaginationOptions } from "./commonInterfaces";

type LocationTemplate = Warehouse.LocationTemplate;

interface LocationTemplateQuery {
  name?: string;
  search?: string;
}

export const fetchLocationTemplates = async (
  query?: PaginationOptions & LocationTemplateQuery
): Promise<PaginatedData<LocationTemplate>> => {
  const api = getAPIFromDomain("november");
  const url = `/location-templates?${queryString.stringify(query || {})}`;
  const respond = await api.get(url);
  return respond.data as PaginatedData<LocationTemplate>;
};

export const createLocationTemplate = async (body: {
  format: string;
  name: string;
}): Promise<LocationTemplate> => {
  const api = getAPIFromDomain("november");
  const url = "/location-templates";
  const respond = await api.post(url, body);
  return respond.data as LocationTemplate;
};
