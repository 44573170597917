import { Warehouse } from "@secondcloset/types";
type OrganizationConfig = Warehouse.OrganizationConfig;

export const isEnabledScanItemsConfig = (
  configs?: OrganizationConfig[]
): boolean => {
  if (!configs) return false;
  const scanItemsConfig = configs.find((c) => c.key === "ENABLE_SCAN_ITEMS");
  return scanItemsConfig?.value === "true";
};
