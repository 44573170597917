import useUrlState from "@ahooksjs/use-url-state";
import { PlusOutlined } from "@ant-design/icons";
import { Fulfillment } from "@secondcloset/types";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  InputNumber,
  notification,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import {
  fetchProductDetails,
  fetchProductIndex,
} from "../../../../api/fulfillment/product";

import { MAX_PICKSHEETS_PER_REQUEST } from "../../PrintPicksheetPage/helpers";
import { PicksheetRequestOptions } from "../interfaces";

import WithLabel from "../../../../components/WithLabel";
import ProductLookup from "./ProductLookup";
import SelectedProduct from "./ProductLookup/SelectedProduct";
import moment, { Moment } from "moment";

type Product = Fulfillment.Product;

const AllowPartialCheckbox: React.FC<any> = (props) => (
  <WithLabel name="Partial Fulfillment">
    <Checkbox
      style={{ fontSize: "1rem", padding: "0.5rem 0" }}
      onChange={props.onChange}
      disabled={props.loading}
      checked={props.checked}
    >
      Allow Partial
    </Checkbox>
  </WithLabel>
);

const OrderLimitInput: React.FC<any> = (props) => (
  <WithLabel name={`# of Orders (max. ${MAX_PICKSHEETS_PER_REQUEST})`}>
    <InputNumber
      min={1}
      max={MAX_PICKSHEETS_PER_REQUEST}
      step={1}
      size="large"
      value={props.value}
      style={{ width: "100%" }}
      disabled={props.loading}
      onChange={props.onChange}
    />
  </WithLabel>
);

const DateRangeSelector: React.FC<{
  value?: any;
  onChange?: (dates: any, dateString: [string, string]) => void;
}> = (props) => (
  <WithLabel name="Filter by Time Range">
    <DatePicker.RangePicker
      size="large"
      value={props?.value}
      format="MMM D YYYY, h:mm A"
      ranges={{
        "Before 1PM Today": [null, moment().set({ hour: 12, minute: 59 })],
        Today: [moment().startOf("day"), moment().endOf("day")],
        Yesterday: [
          moment().subtract(1, "days").startOf("day"),
          moment().subtract(1, "days").endOf("day"),
        ],
      }}
      showTime={{
        format: "h:mm a",
        showSecond: false,
        defaultValue: [
          moment(moment().hour(), "h:mm a"),
          moment(moment().hour(), "h:mm a"),
        ],
      }}
      onChange={props?.onChange}
      separator
      allowClear
      allowEmpty={[true, true]}
      use12Hours
      minuteStep={10}
      popupStyle={{ width: "auto !important" }}
      placeholder={["From", "To"]}
      disabledDate={(date: Moment) => date.isAfter(Date.now())}
      renderExtraFooter={() => (
        <Alert
          type="warning"
          style={{ margin: "8px 0", textAlign: "center" }}
          showIcon
          message="Please click OK to proceed"
        />
      )}
      hideDisabledOptions
    />
  </WithLabel>
);

const ProductFilterAlert: React.FC<{
  product: Product;
}> = (props) => (
  <Alert
    type="info"
    showIcon
    message={`Pick sheets will be generated for orders with only ${props?.product?.name} and no other products`}
  />
);

interface Props {
  loading?: boolean;
  isButtonDisabled: boolean;
  onProductChange: () => void;
  onGenerateButtonClick: (options: PicksheetRequestOptions) => void;
  organizationId: string;
}

const GeneratePicksheetFilter: React.FC<Props> = ({
  loading,
  isButtonDisabled,
  onProductChange,
  onGenerateButtonClick,
  organizationId,
}) => {
  const [productSearch, setProductSearch] = useState("");
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [urlState, setUrlState] = useUrlState({
    orderLimit: MAX_PICKSHEETS_PER_REQUEST,
    product_id: undefined,
    allowPartial: false,
    dateFrom: undefined,
    dateTo: undefined,
  });

  const { data: productList, isLoading } = useQuery(
    [productSearch],
    () =>
      fetchProductIndex({ q: productSearch, organization_id: organizationId }),
    {
      enabled: !!productSearch.trim(),
      onError: (error: string) =>
        notification.error({
          message: "Error",
          description: error,
        }),
    }
  );

  const { mutate: getProductDetails, isLoading: fetchingProduct } = useMutation(
    fetchProductDetails,
    {
      onSuccess: setSelectedProduct,
      onError: (error: string) =>
        notification.error({
          message: "Error",
          description: error,
        }),
    }
  );

  useEffect(() => {
    if (urlState.product_id) {
      getProductDetails(urlState.product_id);
    }
    // eslint-disable-next-line
  }, []);

  const getDateRangeFromUrl = () => {
    if (urlState.dateFrom && urlState.dateTo)
      return [moment(urlState.dateFrom), moment(urlState.dateTo)];
    if (urlState.dateTo) return [null, moment(urlState.dateTo)];
    if (urlState.dateFrom) return [moment(urlState.dateFrom), null];
    return [null, null];
  };

  const onButtonClick = () => {
    const { orderLimit, allowPartial, dateFrom, dateTo } = urlState;
    onGenerateButtonClick({
      orderLimit,
      dateTo,
      dateFrom,
      allowPartial: allowPartial === "true",
      productId: selectedProduct?.id,
      upc: selectedProduct?.upc,
      sku: selectedProduct?.sku,
    });
  };

  const onSelectProduct = (id: string) => {
    const foundProduct = productList?.find((p) => p.id === id);
    setSelectedProduct(foundProduct);
    setUrlState({ ...urlState, product_id: foundProduct?.id });
    onProductChange();
  };

  const onDeselectProduct = () => {
    setUrlState({
      ...urlState,
      product_id: undefined,
    });
    setSelectedProduct(undefined);
    onProductChange();
  };

  const onDateRangeChanged = (dates: [Moment, Moment]) => {
    setUrlState({
      ...urlState,
      dateTo: dates && dates[1] ? dates[1].toISOString() : undefined,
      dateFrom: dates && dates[0] ? dates[0].toISOString() : undefined,
    });
  };

  return (
    <Card>
      <Space direction="vertical" style={{ width: "100%" }}>
        {selectedProduct && <ProductFilterAlert product={selectedProduct} />}
        <Typography.Text>
          Choose options below to customize pick sheet requests
        </Typography.Text>
        <Row align="bottom" gutter={[24, 32]} justify="space-between">
          <Col xs={24} lg={18}>
            <Row align="bottom" gutter={[24, 16]}>
              <Col xs={24} lg={6}>
                <AllowPartialCheckbox
                  loading={loading}
                  onChange={(e) => {
                    setUrlState({
                      ...urlState,
                      allowPartial: e.target.checked,
                    });
                  }}
                  checked={urlState.allowPartial === "true"}
                />
              </Col>
              <Col xs={24} lg={6}>
                <OrderLimitInput
                  value={urlState.orderLimit}
                  loading={loading}
                  onChange={(v) =>
                    setUrlState({ ...urlState, orderLimit: v || 1 })
                  }
                />
              </Col>
              <Col xs={24} lg={12}>
                <DateRangeSelector
                  value={getDateRangeFromUrl()}
                  onChange={onDateRangeChanged}
                />
              </Col>
              <Col xs={24}>
                <ProductLookup
                  onSelectProduct={onSelectProduct}
                  selectedProduct={selectedProduct}
                  productList={productList || []}
                  setSearch={setProductSearch}
                  isLoading={isLoading || fetchingProduct}
                />
                <SelectedProduct
                  onClose={onDeselectProduct}
                  selectedProduct={selectedProduct}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={6} flex={2}>
            <Tooltip
              placement="bottom"
              title={
                isButtonDisabled
                  ? "Cannot generate pick sheet if there are no orders to fulfill, or if we are still printing a pick sheet."
                  : null
              }
            >
              <Button
                type="primary"
                size="large"
                block
                disabled={isButtonDisabled}
                onClick={onButtonClick}
              >
                <PlusOutlined />
                Generate
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

export default GeneratePicksheetFilter;
