import React, { useState } from "react";
import { notification } from "antd";

// hooks
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

// helpers
import { serialize } from "object-to-formdata";
import { LINKS } from "../AsnIndexPage/helpers";
import { handleError } from "../../../lib/formatErrorHandler";

// api
import {
  fetchASNDetails,
  updateASN,
  deleteASN,
  fetchASNLabel,
  fetchASNProcessLogs,
} from "../../../api/fulfillment/asn";
import { fetchOrganizationDetails } from "../../../api/fulfillment/organization";
import { fetchFacilityIndex } from "../../../api/fulfillment/facility";

// components
import { ASNDetails } from "@secondcloset/web-components";
import UploadBolModal from "./UploadBolModal";
import ConfirmArrivalModal from "./ConfirmArrivalModal";

// styles
import * as S from "./styles";
import PageContainer from "../../../components/PageContainer";
import WarehouseActions from "./WarehouseActions";

// recoil
import { asnCreateFlowSelectedOrganization } from "../../../recoil/asnCreateFlow/atoms";
import { useSetRecoilState } from "recoil";

// types
import { Fulfillment } from "@secondcloset/types";

const ASNDetailsPage: React.FC = () => {
  const history = useHistory();
  const { asnID } = useParams<{ asnID: string }>();
  const [isBolModalVisible, setIsBolModalVisible] = useState(false);
  const [isArrivalModalVisible, setIsArrivalModalVisible] = useState(false);
  const facilityIndex = useQuery(["facilityIndex"], fetchFacilityIndex);
  const setSelectedOrganization = useSetRecoilState(
    asnCreateFlowSelectedOrganization
  );
  const queryClient = useQueryClient();

  const redirectTo = (url: string) => history.push(url);
  const goToAsnIndex = () => redirectTo(`${LINKS.asnIndex}`);
  if (!asnID) goToAsnIndex();

  const asnDetails = useQuery(
    ["asnDetails", asnID],
    () =>
      fetchASNDetails({
        asnID,
        query: { with_items: true, with_timeline: true },
      }),
    {
      enabled: !!asnID,
    }
  );

  const {
    mutate: getAsnLogs,
    data: asnLogs,
    isLoading: loadingAsnLogs,
  } = useMutation(fetchASNProcessLogs, {
    onError: (error) => handleError(error as string | Error),
  });

  const asn = asnDetails?.data;
  const organizationID = (asnDetails?.data as any)?.organization_id;

  const organizationDetails = useQuery(
    ["organizationDetails", organizationID],
    () => fetchOrganizationDetails(organizationID),
    {
      enabled: !!organizationID,
    }
  );

  const { mutate: doUpdateAsnDetails, isLoading: isAsnUpdating } = useMutation(
    updateASN,
    {
      onError: (error) => handleError(error as string | Error),
      onSettled: () => queryClient.invalidateQueries("asnDetails"),
    }
  );

  const { mutate: doDeleteASN, isLoading: isAsnDeleting } = useMutation(
    deleteASN,
    {
      onSuccess: () => {
        notification.success({ message: "ASN deletion successful" });
        goToAsnIndex();
      },
      onError: (error) => handleError(error as string | Error),
    }
  );

  const { mutate: doDownloadLabel, isLoading: isDownloadingLabel } =
    useMutation(() => fetchASNLabel(asnID, asn?.number || "ASN_LABEL"), {
      onError: (error) => handleError(error as string | Error),
    });

  const loading =
    asnDetails.isLoading ||
    facilityIndex.isLoading ||
    organizationDetails.isLoading ||
    isAsnUpdating ||
    isAsnDeleting ||
    isDownloadingLabel;

  const onBolSubmit = (file: File) => {
    const asnOptions = {
      asn: { bols_attributes: [{ file }] },
    };
    const body = serialize(asnOptions);
    doUpdateAsnDetails({ body, asnID, options: { with_response: false } });
    setIsBolModalVisible(false);
  };

  const onConfirmArrival = (boxes: string | null, pallets: string | null) => {
    const asn: Partial<Fulfillment.ASN> = { status: "arrived" };
    if (boxes) asn["box_received_quantity"] = parseInt(boxes);
    if (pallets) asn["pallet_received_quantity"] = parseInt(pallets);
    doUpdateAsnDetails({ body: asn, asnID, options: { with_response: false } });
    setIsArrivalModalVisible(false);
  };

  const onHoldClick = () => {
    const asnStatus = asn?.status;
    if (asnStatus === "arrived") {
      const body = serialize({
        asn: { status: "on_hold" },
      });
      doUpdateAsnDetails({ body, asnID, options: { with_response: false } });
    } else {
      history.push(`${LINKS.asnIndex}/${asnID}/process`);
    }
  };
  return (
    <PageContainer withHeader withPadding withFooter>
      <S.Container>
        <S.DetailsWrap>
          <ASNDetails
            key={asn?.id}
            asnDetails={asn}
            isLoading={loading}
            facilities={facilityIndex.data}
            organizationDetails={organizationDetails.data}
            isArrivalDateDisabled={true}
            onDeleteASN={() => doDeleteASN(asnID)}
            onUpdateASN={(body: { [key: string]: any }) =>
              doUpdateAsnDetails({
                body,
                asnID,
                options: { with_response: false },
              })
            }
            onContinueASNClick={() => {
              setSelectedOrganization(organizationDetails.data);
              redirectTo(`${LINKS.asnCreate}/${asnID}`);
            }}
            onUploadBolClick={() => setIsBolModalVisible(true)}
            onAsnIndexClick={goToAsnIndex}
            onDownloadAsnLabel={() => doDownloadLabel()}
            logs={asnLogs?.items}
            loadingLogs={loadingAsnLogs}
            logsPagination={asnLogs?.meta}
            onPageClick={(pageNumber: number) =>
              getAsnLogs({ external_asn_id: asn?.id, page: `${pageNumber}` })
            }
            asnProcessLogVisible={true}
          />
        </S.DetailsWrap>
        <S.WarehouseActionWrap>
          <WarehouseActions
            asn={asn}
            onMarkAsArrivedClick={() => setIsArrivalModalVisible(true)}
            onHoldClick={onHoldClick}
          />
        </S.WarehouseActionWrap>
        <UploadBolModal
          isVisible={isBolModalVisible}
          onClose={() => setIsBolModalVisible(false)}
          onSubmit={onBolSubmit}
          isLoading={loading}
        />
        <ConfirmArrivalModal
          asn={asn}
          isVisible={isArrivalModalVisible}
          onClose={() => setIsArrivalModalVisible(false)}
          onSubmit={onConfirmArrival}
        />
      </S.Container>
    </PageContainer>
  );
};

export default ASNDetailsPage;
