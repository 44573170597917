import useUrlState from "@ahooksjs/use-url-state";
import { BarsOutlined, PlusOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";
import { Alert, Button, Col, Input, notification, Row, Space } from "antd";
import React, { useCallback, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";
import { FacilityCode } from "@secondcloset/fulfillment-utils";

// API
import { fetchLocations, uploadLocationsCsvFile } from "../../../api/warehouse";

// Hooks and helpers
import useFacility from "../../../hooks/useFacility";

// Components
import FacilityDropdown from "../../../components/FacilityDropdown";
import PageContainer from "../../../components/PageContainer";
import WithLabel from "../../../components/WithLabel";
import LocationCsvUploadButton from "./LocationCsvUploadButton";
import LocationTable from "./LocationsTable";

type Location = Warehouse.Location;

const LocationIndexPage: React.FC = () => {
  const cachedFacility = useFacility().facility;
  const [error, setError] = useState<string | string[]>("");
  const [{ locationCodeSearch, page, perPage, facility }, setUrlState] =
    useUrlState(
      {
        locationCodeSearch: undefined,
        page: 1,
        perPage: 10,
        facility: cachedFacility,
      },
      { navigateMode: "replace" }
    );

  const paginatedLocations = useQuery(
    ["fetchLocations", locationCodeSearch, facility, page, perPage],
    () => {
      return fetchLocations({
        code: locationCodeSearch,
        facility: cachedFacility as FacilityCode,
        page,
        limit: perPage,
      });
    },
    {
      onError: (e: string) => setError(e),
      onSuccess: () => setError(""),
    }
  );
  const { refetch: locationsRefetching } = paginatedLocations;
  const mutation = useMutation(
    (file: File) => {
      setError("");
      return uploadLocationsCsvFile(file);
    },
    {
      onError: (e: any) => {
        const errorMsg = e?.response?.data?.message || "Error uploading CSV";
        setError(errorMsg);
      },
      onSuccess: () => {
        setError("");
        notification.success({ message: "Location CSV upload successful" });
        locationsRefetching({ cancelRefetch: true, throwOnError: true });
      },
    }
  );

  const { setFacility } = useFacility(setUrlState, setError);
  const history = useHistory();

  const getLocations = useCallback((): Location[] => {
    return paginatedLocations.data?.items || [];
  }, [paginatedLocations]);

  const renderLocationCodeSearchBar = () => {
    return (
      <WithLabel name="Location">
        <Input.Search
          size="large"
          allowClear
          placeholder="Search for location code"
          defaultValue={locationCodeSearch}
          onSearch={(locationCodeSearch) => {
            setUrlState({ locationCodeSearch, page: 1 });
            setError("");
          }}
          loading={paginatedLocations.isLoading}
          autoCapitalize="off"
        />
      </WithLabel>
    );
  };

  const renderBrowseTemplateButton = () => {
    return (
      <Button
        size="large"
        icon={<BarsOutlined />}
        block
        onClick={() => history.push("/location-templates")}
      >
        Template
      </Button>
    );
  };

  const renderCSVImportButton = () => {
    return (
      <LocationCsvUploadButton
        isLoading={mutation.isLoading}
        onUploadFile={(file) => mutation.mutate(file)}
      />
    );
  };

  const renderCreateButton = () => {
    return (
      <Button
        size="large"
        type="primary"
        icon={<PlusOutlined />}
        block
        onClick={() => history.push("/locations/create")}
      >
        Create
      </Button>
    );
  };

  const renderSearchGroup = () => {
    return (
      <Row justify="start" gutter={[8, 8]}>
        <Col xs={24} lg={8}>
          <FacilityDropdown
            selectedFacility={facility}
            onSelect={setFacility}
          />
        </Col>
        <Col xs={24} lg={16}>
          {renderLocationCodeSearchBar()}
        </Col>
      </Row>
    );
  };

  const renderButtonGroup = () => {
    return (
      <Row justify="end" gutter={[8, 16]}>
        <Col xs={24} lg={7}>
          {renderBrowseTemplateButton()}
        </Col>
        <Col xs={24} lg={7}>
          {renderCSVImportButton()}
        </Col>
        <Col xs={24} lg={6}>
          {renderCreateButton()}
        </Col>
      </Row>
    );
  };

  const renderToolbar = () => {
    return (
      <Row justify="space-between" align="bottom" gutter={[8, 16]}>
        <Col xs={24} lg={12}>
          {renderSearchGroup()}
        </Col>
        <Col xs={24} lg={12}>
          {renderButtonGroup()}
        </Col>
      </Row>
    );
  };

  const renderErrorLines = () => {
    if (Array.isArray(error)) {
      return error.map((e, i) => <div key={i}>{e}</div>);
    }
    return error;
  };

  const renderAlertBox = () => {
    if (!error) return null;
    return (
      <Alert
        message="Error"
        description={renderErrorLines()}
        type="error"
        showIcon
      />
    );
  };

  return (
    <PageContainer title="Locations" withFooter withHeader withPadding>
      <Space style={{ width: "100%" }} direction="vertical" size="large">
        {renderAlertBox()}
        {renderToolbar()}
        <LocationTable
          locations={getLocations()}
          paginationInfo={paginatedLocations.data?.meta}
          loading={paginatedLocations.isLoading}
          onPageChange={(p: number, newPageSize?: number) => {
            if (newPageSize && newPageSize !== +perPage) {
              setUrlState({ page: 1, perPage: newPageSize });
            } else {
              setUrlState({ page: p });
            }
          }}
        />
      </Space>
    </PageContainer>
  );
};

export default LocationIndexPage;
