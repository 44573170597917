import { getAPIFromDomain, getPDF } from "../../lib/api";
import { stringify, StringifyOptions } from "query-string";
import { Fulfillment, Warehouse } from "@secondcloset/types";
import {
  PaginatedData,
  PaginationOptions,
} from "../warehouse/commonInterfaces";

type ASNProcessLog = Warehouse.ASNProcessLog;

type ASNResponse = {
  external_result: Fulfillment.ASN;
  asn_process_log: ASNProcessLog;
};

type ASNUpdate = {
  external_body: any;
  action: string;
  external_asn_number?: string;
  external_asn_id?: string;
};
export const createASN = async (body: unknown): Promise<Fulfillment.ASN> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/asns`;
  const res = await api.post(url, body);
  return res.data;
};

export const updateASN = async ({
  body,
  asnID,
  options,
}: {
  body: unknown;
  asnID: string;
  options?: Record<string, unknown>;
}): Promise<Fulfillment.ASN> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/asns/${asnID}${options ? "?" + stringify(options) : ""}`;
  const res = await api.put(url, body);
  return res.data;
};

export const deleteASN = async (asnID: string): Promise<void> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/asns/${asnID}`;
  await api.delete(url);
};

export const fetchASNDetails = async (options: {
  asnID: string;
  query?: Record<string, unknown>;
}): Promise<Fulfillment.ASN> => {
  const { asnID, query } = options;
  const api = getAPIFromDomain("fulfillment");
  const url = `/asns/${asnID}${query ? "?" + stringify(query) : ""}`;
  const res = await api.get(url);
  return res.data;
};

export const fetchASNIndex = async (
  query: Record<string, unknown>
): Promise<Fulfillment.ASN[]> => {
  const api = getAPIFromDomain("fulfillment");
  const options: StringifyOptions = { arrayFormat: "bracket" };
  const url = `/asns${query ? "?" + stringify(query, options) : ""}`;
  const res = await api.get(url);
  return res.data;
};

export const fetchASNLabel = async (
  asnID: string,
  fileName: string
): Promise<void> => {
  const res = await getPDF(`/asns/${asnID}/label.pdf`, "fulfillment");
  const blob = new Blob([res.data], { type: "application/pdf" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.pdf`);
  link.dispatchEvent(
    new MouseEvent(`click`, {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );
};

interface UpdateASNItemQuantity {
  product_identifier: string;
  change_received_by?: number;
  change_damaged_by?: number;
}

export const updateASNItemQuantity = async (param: {
  external_body: UpdateASNItemQuantity[];
  external_asn_number?: string;
  asnID?: string;
  action: string;
  quantity: number;
  product_name?: string;
  product_sku?: string;
  product_upc?: string;
  external_product_id?: string;
}): Promise<ASNResponse> => {
  const { external_body, asnID, ...rest } = param;
  const api = getAPIFromDomain("november");
  const url = `/integrations/update-asn-item-quantity/${asnID}`;
  const external = { items_quantity: external_body };
  const body = {
    external_body: external,
    ...rest,
  };
  const res = await api.put(url, body);
  return res.data as ASNResponse;
};

export const updateASNWithLog = async (param: {
  body: ASNUpdate;
  options?: Record<string, unknown>;
}): Promise<ASNResponse> => {
  const { options, body } = param;
  const api = getAPIFromDomain("november");
  const url = `/integrations/update-asn${
    options ? "?" + stringify(options) : ""
  }`;
  const res = await api.put(url, body);
  return res.data as ASNResponse;
};

export interface ProcessLogQuery {
  external_asn_id?: string;
  external_asn_number?: string;
  user_email?: string;
}

export const fetchASNProcessLogs = async (
  query: PaginationOptions & ProcessLogQuery
): Promise<PaginatedData<ASNProcessLog>> => {
  const api = getAPIFromDomain("november");
  const url = `/asn-process-logs?${stringify(query || {})}`;
  const response = await api.get(url);
  return response.data as PaginatedData<ASNProcessLog>;
};
