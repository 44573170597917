import moment from "moment";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { Fulfillment, Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import {
  Button,
  Card,
  Col,
  Input,
  PageHeader,
  Row,
  Space,
  Form,
  Typography,
  Select,
  Popconfirm,
} from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";

import WithLabel from "../../../components/WithLabel";
import OrderAndCustomerSearchBar from "../../../components/OrderAndCustomerSearchBar";
import PageContainer from "../../../components/PageContainer";

import {
  fetchManualItem,
  updateManualItem,
  deleteManualItem,
} from "../../../api/warehouse";
import {
  getManualItemLabelUrl,
  MANUAL_ITEM_TYPE,
  MANUAL_ITEM_TYPE_OPTIONS,
} from "../helpers";
import { cloneDeep } from "lodash";

type ManualItem = Warehouse.ManualItem;
type ManualItemType = Warehouse.ManualItemType;
type Order = Fulfillment.Order;

const ManualItemDetailsPage: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [error, setError] = useState<string>("");
  const [order, setOrder] = useState<Order>();
  const [storageCustomerName, setStorageCustomerName] = useState<string>();
  const [record, setRecord] = useState<ManualItem>();
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isSearching, setSearching] = useState<boolean>(false);
  const { manualItemCode } = useParams<Record<string, string>>();
  const isStorage = record?.type === MANUAL_ITEM_TYPE.STORAGE;

  const { isLoading: isFetching } = useQuery(
    "manualItem",
    () => fetchManualItem(manualItemCode),
    {
      onError: (e: any) => setError(e),
      onSuccess: (item: ManualItem) => setRecord(item),
    }
  );

  const { mutate: updateItem, isLoading: isUpdating } = useMutation(
    updateManualItem,
    {
      onError: () => setError(`Error occurred while updating ${record?.code}`),
      onSuccess: (item: ManualItem) => {
        setError("");
        setRecord(item);
        setEditing(false);
        setOrder(undefined);
        setStorageCustomerName(undefined);
      },
    }
  );

  const { mutate: deleteItem, isLoading: isDeleting } = useMutation(
    (id: string) => deleteManualItem(id),
    {
      onError: () => setError(`Error occurred while deleting ${record?.code}`),
      onSuccess: () => {
        setError("");
        history.push("/manual-items");
      },
    }
  );

  const manualItemLabelLink = () => {
    return record ? getManualItemLabelUrl(record?.id) : undefined;
  };

  const startEditing = () => {
    if (!isEditing && record) {
      setError("");
      setEditing(!isEditing);
      form.setFieldsValue({
        type: record.type,
        note: record.note,
        order_number: record.order_number,
        customer_name: record.customer_name,
      });
    }
  };

  const cancelEditing = () => {
    if (isEditing) setEditing(!isEditing);
    setOrder(undefined);
    setError("");
  };

  const deleteRecord = async () => {
    if (!isEditing && record?.id) await deleteItem(record?.id);
  };

  const saveRecord = async (values: Record<string, any>) => {
    if (values.type === MANUAL_ITEM_TYPE.OTHER && !values.note?.trim()) {
      return setError("Note is required for Other type");
    }

    if (isEditing) {
      const isItemModified =
        record?.type !== values.type || record?.note !== values.note;
      const orderModified =
        order &&
        (record?.order_number !== order?.external_order_number ||
          record?.customer_name !== order?.customer.name ||
          record?.organization?.name !== order?.organization.name);

      const isCustomerModified =
        storageCustomerName && record?.customer_name !== storageCustomerName;

      if (isItemModified || orderModified || isCustomerModified) {
        // if any modification made update item
        await updateItem({
          id: record?.id || "",
          body: {
            order_number:
              order?.external_order_number || record?.order_number || undefined,
            customer_name:
              order?.customer.name.trim() ||
              storageCustomerName ||
              record?.customer_name,
            organization_id:
              order?.organization.id || record?.organization?.id || undefined,
            type: values.type as ManualItemType,
            note: values.note?.trim() || undefined,
          },
        });
      } else {
        // otherwise close the editing mode
        setOrder(undefined);
        setStorageCustomerName(undefined);
        setEditing(false);
      }
    }
  };

  const handleSubmitError = (e) => {
    setError(e.message);
  };

  const renderLabelButton = () => {
    return (
      <Button
        size="large"
        target="_blank"
        icon={<DownloadOutlined />}
        href={manualItemLabelLink()}
        block
      >
        Label
      </Button>
    );
  };

  const renderEditButton = () => {
    return (
      <Button
        size="large"
        icon={<EditOutlined />}
        block
        onClick={() => startEditing()}
      >
        Edit
      </Button>
    );
  };

  const renderDeleteButton = () => {
    return (
      <Popconfirm
        title={`Delete "${record?.code}"?`}
        placement="bottom"
        onConfirm={() => deleteRecord()}
      >
        <Button
          size="large"
          type="primary"
          disabled={isSearching || isFetching}
          icon={<DeleteOutlined />}
          block
          danger
        >
          Delete
        </Button>
      </Popconfirm>
    );
  };

  const renderSaveButton = () => {
    return (
      <Button
        size="large"
        type="primary"
        disabled={isSearching || isFetching}
        loading={isUpdating}
        icon={<SaveOutlined />}
        block
        onClick={() => saveRecord(form.getFieldsValue())}
      >
        Save
      </Button>
    );
  };

  const renderCancelButton = () => {
    return (
      <Button
        size="large"
        icon={<CloseOutlined />}
        block
        disabled={isSearching || isFetching}
        loading={isUpdating}
        onClick={() => cancelEditing()}
      >
        Cancel
      </Button>
    );
  };

  const renderViewerToolbar = () => {
    return (
      <Row justify="end" gutter={[8, 8]}>
        <Col xs={24} lg={6} xl={5}>
          {renderEditButton()}
        </Col>
        <Col xs={24} lg={6} xl={5}>
          {renderLabelButton()}
        </Col>
        <Col xs={24} lg={6} xl={5}>
          {renderDeleteButton()}
        </Col>
      </Row>
    );
  };

  const renderEditorToolbar = () => {
    return (
      <Row justify="end" gutter={[8, 8]}>
        <Col xs={24} lg={6} xl={5}>
          {renderCancelButton()}
        </Col>
        <Col xs={24} lg={6} xl={5}>
          {renderSaveButton()}
        </Col>
      </Row>
    );
  };

  const renderTextField = (label: string, value: any, copyable = false) => {
    return (
      <WithLabel name={label}>
        <Typography.Text copyable={copyable}>{value || "-"}</Typography.Text>
      </WithLabel>
    );
  };

  const renderDateField = (label: string, value) => {
    return (
      <WithLabel name={label}>
        <Typography.Text>{value || "N/A"}</Typography.Text>
      </WithLabel>
    );
  };

  const renderItemMeta = () => {
    return (
      <Row gutter={[8, 16]}>
        <Col xs={24} lg={8}>
          {renderDateField(
            "Created at",
            moment(record?.created_at)?.format("lll")
          )}
        </Col>
        <Col xs={24} lg={8}>
          {renderDateField(
            "Updated at",
            moment(record?.updated_at)?.format("lll")
          )}
        </Col>
      </Row>
    );
  };

  const renderItemHeader = () => {
    return (
      <Row justify="space-between" align="top" gutter={[8, 16]}>
        <Col xs={24} lg={12}>
          <Typography.Title ellipsis>{record?.code}</Typography.Title>
        </Col>
        <Col xs={24} lg={12}>
          {isEditing ? renderEditorToolbar() : renderViewerToolbar()}
        </Col>
      </Row>
    );
  };

  const renderItemType = () => {
    const displayType = MANUAL_ITEM_TYPE_OPTIONS.find(
      (e) => e.value === record?.type
    )?.label;

    const currentRecordTypeIsStorage =
      record?.type === MANUAL_ITEM_TYPE.STORAGE;

    const options = cloneDeep(MANUAL_ITEM_TYPE_OPTIONS).map((o) => {
      // we cannot toggle from storage to fulfillment order and vice versa
      o.disabled =
        (o.key === MANUAL_ITEM_TYPE.STORAGE) !== currentRecordTypeIsStorage;
      return o;
    });

    return isEditing ? (
      <WithLabel name="Item Type">
        <Form.Item
          name={"type"}
          rules={[{ required: true, message: "Item Type is required!" }]}
        >
          <Select
            size="large"
            value={record?.type}
            options={options}
            disabled={isStorage}
          />
        </Form.Item>
      </WithLabel>
    ) : (
      renderTextField("Item Type", displayType)
    );
  };

  const renderItemNote = () => {
    if (!record?.note && !isEditing) return null;

    return isEditing ? (
      <WithLabel name="Item Note">
        <Form.Item
          name={"note"}
          rules={[{ required: true, message: `Note is required!` }]}
        >
          <Input.TextArea size="large" value={record?.note} />
        </Form.Item>
      </WithLabel>
    ) : (
      renderTextField("Item Note", record?.note)
    );
  };

  const renderExternalOrderEditor = () => {
    // Business flow, block update customer name
    if (isStorage) return null;
    return (
      <WithLabel name={isStorage ? "Customer Name" : "External Order"}>
        <Row justify="center" align="middle" gutter={[8, 24]}>
          <Col xs={24}>
            <OrderAndCustomerSearchBar
              size="large"
              onLoad={setSearching}
              onError={setError}
              onSelect={(value) => {
                if (isStorage) setStorageCustomerName(value);
                else setOrder(value);
              }}
              type={record?.type}
            />
          </Col>
        </Row>
      </WithLabel>
    );
  };

  const renderExternalOrderDetailCard = () => {
    return (
      <Card bordered>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          {isEditing && (
            <Row align="top" justify="center" gutter={[8, 24]}>
              <Col xs={24}>{renderExternalOrderEditor()}</Col>
            </Row>
          )}
          {!order && (
            <Row align="middle" gutter={[8, 24]}>
              <Col xs={24} lg={8}>
                {renderTextField(
                  "External Order #",
                  record?.order_number,
                  true
                )}
              </Col>
              <Col xs={24} lg={8}>
                {renderTextField("Organization", record?.organization?.name)}
              </Col>
              <Col xs={24} lg={8}>
                {renderTextField("Customer Name", record?.customer_name)}
              </Col>
            </Row>
          )}
        </Space>
      </Card>
    );
  };

  return (
    <PageContainer withPadding loading={isFetching || isUpdating || isDeleting}>
      <PageHeader title="Item Detail" onBack={() => window.history.back()} />
      <Form
        form={form}
        component={false}
        onFinish={saveRecord}
        onFinishFailed={handleSubmitError}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <ErrorAlert error={error} />
          {renderItemHeader()}
          {renderExternalOrderDetailCard()}
          <Card bordered>
            <Row align="top" justify="start" gutter={[8, 16]}>
              <Col xs={24}>{renderItemType()}</Col>
              <Col xs={24}>{renderItemNote()}</Col>
            </Row>
          </Card>
          <Card bordered>{renderItemMeta()}</Card>
        </Space>
      </Form>
    </PageContainer>
  );
};

export default ManualItemDetailsPage;
