import { FacilityCode } from "@secondcloset/fulfillment-utils";
import { Warehouse } from "@secondcloset/types";
import queryString from "query-string";
import { getAPIFromDomain } from "../../lib/api";
import { validateFacilityCode } from "../../lib/validateFacilityCode";
import {
  AuditItem,
  LocationItemByLocationSku,
  PaginatedData,
  PaginationOptions,
  RemoveItemsFromPalletBody,
} from "./commonInterfaces";

type LocationType = Warehouse.LocationType;
type LocationItem = Warehouse.LocationItem;
type PalletType = Warehouse.PalletType;

interface LocationItemQuery {
  facility?: FacilityCode;
  location_type?: LocationType;
  location_code?: string;
  identifier?: string;
  pallet_id?: string;
  sku?: string;
  sort_direction?: string;
  sort_field?: string;
}

interface MovePalletToLocationBody {
  // MOVE INTO LIB
  to_location_code: string;
  pallet_id: string;
}

const basePath = "location-items";

export const fetchLocationItems = async (
  query?: PaginationOptions & LocationItemQuery
): Promise<PaginatedData<LocationItem>> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}?${queryString.stringify(query || {})}`;
  const respond = await api.get(url);
  return respond.data as PaginatedData<LocationItem>;
};

export const lookupLocationItems = async (
  query?: PaginationOptions & LocationItemQuery
): Promise<PaginatedData<LocationItemByLocationSku>> => {
  const api = getAPIFromDomain("november");
  const formattedQuery = {
    ...query,
    facility: validateFacilityCode(query?.facility),
  };

  const url = `/${basePath}/search?${queryString.stringify(
    formattedQuery || {}
  )}`;
  const respond = await api.get(url);
  return respond.data as PaginatedData<LocationItemByLocationSku>;
};

export interface ScannedItem {
  product_id?: string;
  manual_item_id?: string;
  quantity: number;
}

interface BatchMoveItemsToLocationBody {
  items: ScannedItem[];
  from_location_code?: string;
  to_location_code: string;
}

export const batchUpdateLocation = async (
  body: BatchMoveItemsToLocationBody
): Promise<LocationItem[]> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/batch/location`;
  const respond = await api.put(url, body);
  return respond.data as LocationItem[];
};

interface LocationTransferBody {
  from_location_code: string;
  to_location_code: string;
}

export const locationTransfer = async (
  body: LocationTransferBody
): Promise<LocationItem[]> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/location-transfer`;
  const respond = await api.put(url, body);
  return respond.data as LocationItem[];
};

export const auditItems = async (
  items: AuditItem[]
): Promise<LocationItem[]> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/audit-items`;
  const response = await api.post(url, {
    items,
  });
  return response.data as LocationItem[];
};

export const auditItemsWithNoChange = async (
  locationCode: string
): Promise<LocationItem[]> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/confirm-audit`;
  const response = await api.post(url, {
    location_code: locationCode,
  });
  return response.data as LocationItem[];
};

export const movePalletToLocation = async (
  body: MovePalletToLocationBody
): Promise<LocationItem[]> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/move-pallet-to-location`;
  const respond = await api.post(url, body);
  return respond.data as LocationItem[];
};

interface CreatePalletBody {
  location_code: string;
  type?: PalletType;
}

interface CreatePalletOption {
  pallet_id: string;
}

export const createPallet = async (
  body: CreatePalletBody,
  query?: CreatePalletOption
): Promise<LocationItem[]> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/create-pallet?${queryString.stringify(
    query || {}
  )}`;
  const response = await api.post(url, body);
  return response.data as LocationItem[];
};

export const deletePalletWithLocationItems = async (
  palletId: string
): Promise<void> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/delete-pallet/${palletId}`;
  await api.delete(url);
};

export const removeItemsFromPallet = async (
  body: RemoveItemsFromPalletBody
): Promise<LocationItem[]> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/remove-items-from-pallet`;
  const response = await api.post(url, body);
  return response.data as LocationItem[];
};
