import moment from "moment";

export const SpecialProjectTypes = [
  "RECEIVING_SUPPORT",
  "INSPECTIONS",
  "KITTING",
  "PRODUCT_ADJUSTMENT",
  "LABELLING",
  "INVENTORY_COUNT",
  "OTHER",
] as const;

export const validateDates = (
  startDate: string,
  endDate: string
): { isValid: boolean; error: string } => {
  const start = moment(startDate);
  const end = moment(endDate);
  if (start && end && start > end) {
    return {
      isValid: false,
      error: "End date cannot be earlier than start date",
    };
  }
  if (start && end && end.subtract(1, "days") > start) {
    return {
      isValid: false,
      error: "Start time and end time can have maximum 24 hours difference",
    };
  }
  return { isValid: true, error: "" };
};

export const permissionsEditWarning =
  "You do not have permission to create/update special projects.";

export const permissionsReadWarning =
  "You do not have permission to view special projects.";
