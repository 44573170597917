import React from "react";
import { Select } from "antd";
import { Warehouse } from "@secondcloset/types";

import { getLocationTypeOptions } from "../helper";

type LocationType = Warehouse.LocationType;
interface Props {
  value?: any;
  disabled?: boolean;
  onSelect?: (type: string) => void;
}

const defaultAll = "";

const LocationTypeSelector = ({
  value,
  disabled,
  onSelect: selectCallback,
}: Props) => {
  const handleOnSelectValue = (type: string) => {
    const value = type.trim();
    if (selectCallback) selectCallback(value as LocationType);
  };

  return (
    <Select
      size="large"
      value={value || defaultAll}
      onSelect={handleOnSelectValue}
      disabled={disabled}
    >
      <Select.Option value={defaultAll} key={undefined}>
        All
      </Select.Option>
      {getLocationTypeOptions().map((option: any) => (
        <Select.Option value={option.value} key={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default LocationTypeSelector;
