import React from "react";
import { Spin } from "antd";

//styles
import { Container } from "./styles";

interface Props {
  text?: string;
}

const PageLoader: React.FC<Props> = (props) => {
  return (
    <Container>
      <Spin tip={props.text || "loading..."} />
    </Container>
  );
};

export default PageLoader;
