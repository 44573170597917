import styled from "styled-components";
import { CheckCircleFilled as CheckCircleFilledAntd } from "@ant-design/icons";
import { MIXINS, COLORS } from "../../../../../styles";

export const Container = styled.div`
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
`;

export const RatesWrap = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  max-height: 550px;
  overflow: hidden auto;
`;

interface RateOptionProps {
  selected: boolean;
}

export const RateOption = styled.div<RateOptionProps>`
  display: flex;
  min-height: 100px;
  flex-direction: row;
  align-items: center;
  min-width: 48.777%;
  max-width: 48.777%;
  border: ${({ selected }) =>
    selected ? `2px solid ${COLORS.GREEN}` : `2px solid #EEEEEE`};
  border-radius: 6px;
  padding: 10px 16px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &:after {
    ${({ selected }) =>
      selected
        ? `
    content: "SELECTED";
    position: absolute;
    top: -2px;
    right: -2px;
    border-top-right-radius: 6px;
    color: ${COLORS.WHITE};
    background: ${COLORS.GREEN};
    padding: 2px 10px 2px 20px;
    font-size: 10px;
    font-weight: 600;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);`
        : ""}
  }

  &:nth-child(even) {
    margin-right: 0;
  }
`;

export const Circle = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${COLORS.GREY_MID};
  position: relative;
  border-radius: 100%;
  transition: all 0.2 ease-in;
  margin-right: 8px;

  &:hover {
    border: 1px solid ${COLORS.BLUE};
    cursor: pointer;
  }

  &::after {
    transition: all 0.2 ease-in;
    content: "";
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 100%;
    background: ${COLORS.WHITE};
  }
`;

export const CheckCircleFilled = styled(CheckCircleFilledAntd)`
  & svg {
    fill: ${COLORS.GREEN};
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;

export const RateCol = styled.div`
  color: ${COLORS.GREY}
  display: flex;
  flex: 1;
  align-items: center;
`;

export const RateImage = styled.img`
  max-width: 100px;
  max-height: 50px;
`;

export const RateBody = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

interface RateNameProps {
  disabled?: boolean;
}

export const RateName = styled.div<RateNameProps>`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${(props) => (props.disabled ? COLORS.GREY : "unset")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "unset")};
`;

export const RateTextLarge = styled.p`
  font-size: 1.75rem;
  text-align: right;
  margin: 0;
  padding: 0;
  line-height: 1.7;
`;

export const RateTextSmall = styled.span`
  color: ${COLORS.GREY};
`;
