import styled from "styled-components";
import { COLORS, DEVICE, MIXINS } from "../../../../styles";
import { EnvironmentFilled as EnvironmentFilledAntd } from "@ant-design/icons";

export const Container = styled.div`
  margin: 0 0 40px 0;
`;

export const TableFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
`;

export const ItemCount = styled.div`
  color: ${COLORS.GREY};
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
`;

export const ListTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
`;

export const IconWrap = styled.span``;

export const EnvironmentFilled = styled(EnvironmentFilledAntd)`
  svg {
    fill: ${COLORS.BLUE};
  }
  margin-right: 10px;
`;

export const EmptyContainer = styled.div`
  ${MIXINS.includeBoxShadow()}
  height: calc(100% - 58px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.GREY};
  font-size: 16px;
  padding: 8px 0;
`;

export const ShipmentMethodWrapper = styled.div`
  justify-self: start;
  margin-bottom: 10px;
`;

export const MaxPackageCountWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 5px;
`;

export const MaxPackageCountText = styled.div`
  font-weight: 600;
`;

export const LotTrackingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
