import React from "react";
import { Table, Typography } from "antd";
import { Warehouse } from "@secondcloset/types";
// API
import NullSafeText from "../../../../components/Table/NullSafeText";
import ProductIdentifiersField from "../../../../components/Table/ProductIdentifiersField";
import { ProductCondition, ReturnType } from "../../ReturnsIndexPage/helpers";

type Return = Warehouse.Return;
type ReturnItem = Warehouse.ReturnItem;

interface Props {
  returnDetails?: Return;
}

const ReturnItemsTable: React.FC<Props> = ({ returnDetails }) => {
  const isRegular = returnDetails?.type === ReturnType.REGULAR;
  const renderText = (text: string) => {
    return <NullSafeText value={text} />;
  };

  const renderIdentifier = (item: ReturnItem) => {
    const sku = item?.sku;
    const upc = item?.upc;
    const name = item?.name;
    return <ProductIdentifiersField sku={sku} upc={upc} name={name} />;
  };

  const itemsTableColumn = [
    {
      title: "Identifier",
      key: "id",
      dataIndex: "id",
      width: 250,
      render: (id: string, item: ReturnItem) => renderIdentifier(item),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: renderText,
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
      width: 150,
      render: (condition?: string) =>
        renderText(ProductCondition?.[condition || ""] || ""),
    },
    {
      title: "Condition Details",
      dataIndex: "condition_notes",
      key: "condition_notes",
      width: 200,
      render: renderText,
    },
  ];

  const reasons = [
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      width: 150,
      render: renderText,
    },
    {
      title: "Reason notes",
      dataIndex: "reason_notes",
      key: "reason_notes",
      width: 200,
      render: renderText,
    },
  ];
  if (isRegular) itemsTableColumn?.splice(2, 0, ...reasons);

  return (
    <Table
      size="small"
      rowKey="id"
      bordered
      title={() => <Typography.Text strong>Package Contents</Typography.Text>}
      scroll={{ x: "max-content" }}
      columns={itemsTableColumn}
      pagination={false}
      dataSource={returnDetails?.return_items}
    />
  );
};

export default ReturnItemsTable;
