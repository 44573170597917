import React from "react";
import { Col, Row, Typography } from "antd";

interface Props {
  count: number;
}

const ItemCountFooter: React.FC<Props> = (props) => (
  <Row align="middle">
    <Col>
      <Typography.Text strong type="secondary">
        <Typography.Text strong>{props.count}</Typography.Text>
        {` ${props.count === 1 ? "result" : "results"} in total`}
      </Typography.Text>
    </Col>
  </Row>
);

export default ItemCountFooter;
