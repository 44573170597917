import React from "react";
import { Select } from "antd";
import { Facility, FacilityCode } from "@secondcloset/fulfillment-utils";

interface Props {
  value?: FacilityCode;
  disabled?: boolean;
  onSelect?: (facility: FacilityCode) => void;
}

const defaultAll = "All";
const facilityList = Facility.getFulfillmentFacilityList();

const FacilitySelector = ({
  value,
  disabled,
  onSelect: selectCallback,
}: Props) => {
  const handleOnSelectValue = (facility: string) => {
    const value = facility.trim();
    if (selectCallback) selectCallback(value as FacilityCode);
  };

  return (
    <Select
      size="large"
      value={value || defaultAll}
      onSelect={handleOnSelectValue}
      disabled={disabled}
      style={{ minWidth: 100 }}
    >
      <Select.Option value={defaultAll} key={undefined}>
        All
      </Select.Option>
      {facilityList.map((facility) => (
        <Select.Option value={facility.code} key={facility.code}>
          {facility.code?.trim().toUpperCase()}
        </Select.Option>
      ))}
    </Select>
  );
};

export default FacilitySelector;
