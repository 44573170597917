import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { ErrorAlert } from "@secondcloset/web-components";
import { CheckOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Col, notification, Space, Row, Divider } from "antd";

import ScannerInput from "../../../components/ScannerInput";
import QuantityTable from "./QuantityTable/QuantityTable";
import WithLabel from "../../../components/WithLabel";

import {
  fetchLocationItems,
  movePalletToLocation,
} from "../../../api/warehouse";
import useUrlState from "@ahooksjs/use-url-state";
import { useSoundEffect } from "../../../hooks/useSoundEffect";
import { buildTablePagination } from "../../../components/Pagination/helper";
import { removeWhitespace } from "../../../lib/removeWhitespace";

interface Props {
  setContainerLoading: (loading: boolean) => void;
}

const BatchMovePalletsView: React.FC<Props> = ({ setContainerLoading }) => {
  const { playSuccessSound } = useSoundEffect();
  const [toLocation, setToLocation] = useState("");
  const [palletId, setPalletId] = useState("");
  const [error, setError] = useState("");
  const [{ page, perPage }, setUrlState] = useUrlState(
    {
      page: 1,
      perPage: 10,
    },
    { navigateMode: "replace" }
  );
  const { mutate: movePalletsToLocation, isLoading } = useMutation(
    movePalletToLocation,
    {
      onSettled: () => {
        setContainerLoading(false);
      },
      onSuccess: (data) => {
        const newLocation = data?.[0]?.location_code;
        notification.success({
          message: `Successfully moved pallet to ${newLocation}`,
        });
        setError("");
        setPalletId("");
        resetAllValues();
        playSuccessSound();
      },
      onError: (e: string) => setError(e),
    }
  );
  const {
    mutate: getLocationItems,
    data: locationItemsData,
    reset: resetLocationItemsData,
  } = useMutation(
    () => fetchLocationItems({ pallet_id: palletId, page: page.toString() }),
    {
      onSettled: () => {
        setContainerLoading(false);
      },
      onSuccess: () => {
        setError("");
      },
      onError: (error: string) => {
        setError(error);
      },
    }
  );

  useEffect(() => {
    if (palletId) getLocationItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const resetAllValues = () => {
    setUrlState({ page: 1 });
    resetLocationItemsData();
    setPalletId("");
    setToLocation("");
    setError("");
  };

  const onPalletIdInputChanged = (palletId: string) => {
    const id = palletId?.trim();
    setPalletId(id);
  };

  const onPalletIdInputEntered = (palletId: string) => {
    const id = palletId?.trim();
    if (!id) return;
    setContainerLoading(true);
    setUrlState({ page: 1 });
    getLocationItems();
  };

  const onClickConfirm = () => {
    if (!palletId) return setError("Please scan a pallet");
    if (!locationItemsData?.items?.length)
      return setError("No items on this pallet");
    if (!toLocation)
      return setError(
        'The "To location" cannot be empty, please scan/type a valid location code.'
      );

    setError("");
    setContainerLoading(true);
    movePalletsToLocation({
      to_location_code: removeWhitespace(toLocation).toUpperCase(),
      pallet_id: palletId,
    });
  };

  const buildPagination = () => {
    const paginationConfig = {
      paginationInfo: locationItemsData?.meta,
      onChange: (p: number, newPageSize?: number) => {
        if (newPageSize && newPageSize !== +perPage) {
          setUrlState({ page: 1, perPage: newPageSize });
        } else {
          setUrlState({ page: p });
        }
      },
    };
    return buildTablePagination(paginationConfig);
  };

  const renderPalletScannerInput = () => {
    return (
      <WithLabel name="Scan Pallet">
        <ScannerInput
          value={palletId}
          onEnter={onPalletIdInputEntered}
          onChange={onPalletIdInputChanged}
          autoFocus
          allowClear
        />
      </WithLabel>
    );
  };

  const renderToScannerInput = () => {
    return (
      <WithLabel name="To Location">
        <ScannerInput
          allowClear
          value={toLocation}
          onChange={(l) => setToLocation(l.trim())}
        />
      </WithLabel>
    );
  };

  const renderResetButton = () => {
    return (
      <Button
        type="primary"
        size="large"
        icon={<UndoOutlined />}
        block
        ghost
        danger
        disabled={!locationItemsData?.items?.length || !toLocation}
        onClick={() => resetAllValues()}
      >
        Clear all
      </Button>
    );
  };

  const renderConfirmButton = () => {
    return (
      <Button
        block
        type="primary"
        size="large"
        icon={<CheckOutlined />}
        disabled={!palletId || !toLocation}
        onClick={() => onClickConfirm()}
        loading={isLoading}
      >
        Confirm
      </Button>
    );
  };

  const renderTopToolbar = () => {
    return (
      <Row justify="space-between" align="bottom" gutter={[8, 8]}>
        <Col xs={24} lg={16}>
          {renderPalletScannerInput()}
        </Col>
        <Col xs={24} lg={3}>
          {renderResetButton()}
        </Col>
      </Row>
    );
  };

  const renderBottomToolbar = () => {
    return (
      <>
        <Row justify="space-between" align="bottom" gutter={[8, 8]}>
          <Col xs={24} lg={{ span: 8, push: 16 }}>
            {renderToScannerInput()}
          </Col>
        </Row>
        <Divider />
        <Row justify="center" align="bottom" gutter={[8, 8]}>
          <Col xs={24} lg={8}>
            {renderConfirmButton()}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Space style={{ width: "100%" }} direction="vertical" size="large">
      {renderTopToolbar()}
      <ErrorAlert error={error} />
      <QuantityTable
        palletId={palletId}
        location={locationItemsData?.items[0]?.location_code}
        pagination={buildPagination()}
        locationItems={locationItemsData?.items}
      />
      {renderBottomToolbar()}
    </Space>
  );
};

export default BatchMovePalletsView;
