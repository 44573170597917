import React from "react";

// Components
import * as S from "./styles";
import AsnClientTable from "./AsnClientTable";
import AsnSelectClientSearchbar from "./AsnSelectClientSearchbar";
import AsnOrganizationDetails from "./AsnOrganizationDetails";

// Recoil
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  asnCreateFlowSelectedOrganization,
  asnCreateFlowOrganizations,
} from "../../../../recoil/asnCreateFlow/atoms";
import { Button } from "antd";

const AsnCreateSelectClient: React.FC = () => {
  const selectedOrganization = useRecoilValue(
    asnCreateFlowSelectedOrganization
  );
  const resetSelectedOrganization = useResetRecoilState(
    asnCreateFlowSelectedOrganization
  );
  const resetOrganizations = useResetRecoilState(asnCreateFlowOrganizations);

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.SubHeader>
          {selectedOrganization ? selectedOrganization.name : "Select Client"}
        </S.SubHeader>
        {selectedOrganization ? (
          <Button
            type="default"
            onClick={() => {
              resetSelectedOrganization();
              resetOrganizations();
            }}
          >
            Reset
          </Button>
        ) : null}
      </S.HeaderWrapper>
      {selectedOrganization ? null : <AsnSelectClientSearchbar />}
      {selectedOrganization ? <AsnOrganizationDetails /> : <AsnClientTable />}
    </S.Wrapper>
  );
};

export default AsnCreateSelectClient;
