import React, { useState } from "react";
import { useQuery } from "react-query";
import { Col, Input, Row, Space } from "antd";
import { ErrorAlert } from "@secondcloset/web-components";

import { fetchShipmentProductsTracking } from "../../api/warehouse/shipmentProductsTracking";
import useUrlState from "@ahooksjs/use-url-state";

import WithLabel from "../../components/WithLabel";
import PageContainer from "../../components/PageContainer";
import ShipmentProductsTrackingTable from "./ShipmentProductsTrackingTable/ShipmentProductsTrackingTable";

const ShipmentProductsTrackingPage = () => {
  const [searchCount, setSearchCount] = useState(0);
  const [error, setError] = useState("");
  const [{ page, perPage, lotNumber, serialNumber }, setUrlState] = useUrlState(
    {
      page: 1,
      perPage: 10,
      lotNumber: undefined,
      serialNumber: undefined,
    },
    { navigateMode: "replace" }
  );

  const { data: shipmentProductsTracking, isLoading } = useQuery(
    ["fetchShipmentProductsTracking", searchCount, page, perPage],
    () => {
      return fetchShipmentProductsTracking({
        lot_number: lotNumber?.trim(),
        serial_number: serialNumber?.trim(),
        page,
        limit: perPage,
      });
    },
    { onError: (e: string) => setError(e) }
  );

  const renderLotNumberSearchBox = () => {
    return (
      <WithLabel name="Lot Number">
        <Input.Search
          allowClear
          size="large"
          value={lotNumber}
          loading={!!isLoading}
          placeholder={"Enter lot number"}
          autoCapitalize="off"
          onSearch={() => setSearchCount((c) => c + 1)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setUrlState({ lotNumber: e.target?.value.trim() });
          }}
        />
      </WithLabel>
    );
  };

  const renderSerialNumberSearchBox = () => {
    return (
      <WithLabel name="Serial Number">
        <Input.Search
          allowClear
          size="large"
          value={serialNumber}
          loading={!!isLoading}
          placeholder={"Enter serial number"}
          autoCapitalize="off"
          onSearch={() => setSearchCount((c) => c + 1)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setUrlState({ serialNumber: e.target?.value.trim() });
          }}
        />
      </WithLabel>
    );
  };

  const renderToolbar = () => {
    return (
      <Row align="bottom" gutter={[8, 16]}>
        <Col xs={24} lg={8}>
          {renderLotNumberSearchBox()}
        </Col>
        <Col xs={24} lg={8}>
          {renderSerialNumberSearchBox()}
        </Col>
      </Row>
    );
  };

  return (
    <PageContainer withPadding title="Shipment Products Tracking">
      <Space style={{ width: "100%" }} size="large" direction="vertical">
        {renderToolbar()}
        <ErrorAlert error={error} />
        <ShipmentProductsTrackingTable
          shipmentProductsTracking={shipmentProductsTracking?.items || []}
          paginationInfo={shipmentProductsTracking?.meta}
          loading={isLoading}
          onPageChange={(p: number, newPageSize?: number) => {
            if (newPageSize && newPageSize !== +perPage) {
              setUrlState({ page: 1, perPage: newPageSize });
            } else {
              setUrlState({ page: p });
            }
          }}
        />
      </Space>
    </PageContainer>
  );
};

export default ShipmentProductsTrackingPage;
