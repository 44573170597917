import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useMutation } from "react-query";
import {
  Popconfirm,
  Button,
  Alert,
  Card,
  PageHeader,
  Space,
  Row,
  Col,
} from "antd";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Common, Warehouse } from "@secondcloset/types";

// components
import PageContainer from "../../../components/PageContainer";
import LocationTemplateLookup from "./LocationTemplateLookup";
import SelectedLocationTemplateTable from "./SelectedLocationTemplateTable";
import StandardOptions from "./StandardOptions";
import LocationCodeCells from "./LocationCodeCells";

import { createLocation, LocationType } from "../../../api/warehouse/location";
import LocationTypeSelect from "./LocationTypeSelect";

// styles
import * as S from "./styles";

type LocationTemplate = Warehouse.LocationTemplate;
type Location = Warehouse.Location;
type FacilityCode = Common.FacilityCode;

const CreateLocationPage: React.FC = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<LocationTemplate>();
  const [facility, setFacility] = useState<FacilityCode>();
  const [locationCode, setLocationCode] = useState("");
  const [type, setType] = useState<LocationType>();
  const [error, setError] = useState("");
  const history = useHistory();
  const mutation = useMutation(
    () => {
      return createLocation({
        code: getFullLocationCode(),
        facility: facility || "yyz3",
        location_template_id: selectedTemplate?.id || "",
        type: type as LocationType,
      });
    },
    {
      onError: (e: string) => setError(e),
      onSuccess: (location: Location) => {
        history.push(`/locations/create/success/${location.code}`);
      },
    }
  );

  const getFullLocationCode = () => {
    const code = locationCode.split("-").filter(Boolean).join("-");
    if (!facility) return "";
    return `${facility}-${code}`.toUpperCase();
  };

  useEffect(() => {
    setError("");
  }, [selectedTemplate, facility, locationCode]);

  const onSaveClick = () => {
    if (!selectedTemplate) return setError("Please select a template");
    if (!facility) return setError("Please select facility");
    const numOfFilledCells = getFullLocationCode().split("-").length;
    const expectedNumberOfFilledCells =
      selectedTemplate.format.split("-").length;
    if (numOfFilledCells < expectedNumberOfFilledCells) {
      return setError("Please fill in all location code cells");
    }

    mutation.mutate();
  };

  const renderHeaderButtons = () => {
    const isFormEmpty = !facility && !selectedTemplate && !locationCode;
    return (
      <Row justify="center" gutter={[8, 16]}>
        <Col xs={24} lg={4}>
          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            disabled={isFormEmpty}
            onConfirm={() => history.push("/locations")}
          >
            <Button
              size="large"
              icon={<CloseOutlined />}
              block
              onClick={() => (isFormEmpty ? history.push("/locations") : null)}
            >
              Cancel
            </Button>
          </Popconfirm>
        </Col>
        <Col xs={24} lg={4}>
          <Button
            size="large"
            type="primary"
            block
            icon={<SaveOutlined />}
            loading={mutation.isLoading}
            onClick={onSaveClick}
          >
            Save
          </Button>
        </Col>
      </Row>
    );
  };

  const buildAlertBox = () => {
    if (!error) return null;
    return (
      <S.AlertBoxWrap>
        <Alert
          message="Validation Error"
          description={error}
          type="error"
          showIcon
        />
      </S.AlertBoxWrap>
    );
  };

  return (
    <PageContainer withPadding>
      <PageHeader
        title="Create Location"
        onBack={() => window.history.back()}
      />
      {buildAlertBox()}
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <Card bordered>
          <Space direction="vertical" style={{ width: "100%" }} size="large">
            <Row gutter={[8, 32]}>
              <Col xs={24}>
                <LocationTemplateLookup
                  setSelectedTemplate={setSelectedTemplate}
                />
              </Col>
              <Col xs={24} lg={12}>
                <StandardOptions
                  selectedFacility={facility}
                  onSelectFacility={setFacility}
                />
              </Col>
              <Col xs={24} lg={12}>
                <LocationTypeSelect
                  selectedType={type}
                  onSelectType={setType}
                />
              </Col>
              <Col xs={24}>
                <SelectedLocationTemplateTable
                  locationTemplate={selectedTemplate}
                />
              </Col>
            </Row>
            <Row>
              <LocationCodeCells
                format={selectedTemplate?.format}
                setLocationCode={setLocationCode}
              />
            </Row>
            <S.FullLocationCode>{getFullLocationCode()}</S.FullLocationCode>
          </Space>
        </Card>
        {renderHeaderButtons()}
      </Space>
    </PageContainer>
  );
};

export default CreateLocationPage;
