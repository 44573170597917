import styled from "styled-components";

export const DimensionsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90px;

  &:not(:last-child) {
    margin: 0 16px 0 0;
  }
`;

export const LabelText = styled.div`
  font-size: 14px;
  font-weight: 300;
`;
