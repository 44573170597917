import React from "react";

//components
import { Alert, Button } from "antd";

//styles
import { Container } from "./styles";
import { CSSProperties } from "styled-components";

interface Props {
  type: "success" | "info" | "warning" | "error";
  message?: string;
  buttonText?: string;
  onClickButton?: () => unknown;
  onClose?: () => unknown;
  style?: CSSProperties;
}

const BannerWithButton: React.FC<Props> = (props) => {
  if (!props.message) return null;

  const renderButton = () => {
    return (
      <Button onClick={props.onClickButton} style={{ margin: "0 10px 0 50px" }}>
        {props.buttonText}
      </Button>
    );
  };

  return (
    <Container>
      <Alert
        type={props.type}
        message={props.message}
        banner
        closable={true}
        style={props.style}
        action={props.onClickButton ? renderButton() : ""}
        onClose={props.onClose}
      />
    </Container>
  );
};

export default BannerWithButton;
