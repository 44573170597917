import React, { useCallback } from "react";

import { Fulfillment } from "@secondcloset/types";
import { Select, Space } from "antd";
import WithLabel from "../../../../../components/WithLabel";
import { debounce } from "lodash";
import Text from "antd/lib/typography/Text";
import { LoadingOutlined } from "@ant-design/icons";

type Product = Fulfillment.Product;

interface Props {
  setSearch: (v: string) => void;
  onSelectProduct: (id: string) => void;
  selectedProduct?: Product;
  productList: Product[];
  isLoading: boolean;
}

const ProductLookup: React.FC<Props> = ({
  productList,
  onSelectProduct,
  selectedProduct,
  setSearch,
  isLoading,
}) => {
  if (selectedProduct) return null;
  const buildOptions = () => {
    return productList.map((p) => {
      return (
        <Select.Option key={p.id} value={p.id}>
          <Space>
            <Text>{p.name}</Text>
            <Text type="secondary">
              {p.sku}
              {p.upc && ","} {p.upc}
            </Text>
          </Space>
        </Select.Option>
      );
    });
  };

  // eslint-disable-next-line
  const debouncedChangeHandler = useCallback(debounce(setSearch, 500), []);

  return (
    <WithLabel name={"Product Filter"}>
      <Select
        size="large"
        showSearch
        style={{ width: "100%" }}
        placeholder="SKU/UPC"
        optionFilterProp="children"
        onSelect={onSelectProduct}
        onSearch={debouncedChangeHandler}
        defaultActiveFirstOption={false}
        filterOption={false}
        notFoundContent={isLoading ? "Searching..." : undefined}
        suffixIcon={isLoading ? <LoadingOutlined /> : undefined}
        dropdownMatchSelectWidth={false}
      >
        {buildOptions()}
      </Select>
    </WithLabel>
  );
};

export default ProductLookup;
