import { Common, Fulfillment, Warehouse } from "@secondcloset/types";
import { getAPIFromDomain } from "../../lib/api";
export interface ShipmentProductsTrackingCreateItem {
  external_product_id: string;
  lot_number?: string;
  serial_number?: string;
  product_sku?: string;
  product_upc: string;
  quantity: number;
}
interface CompletePackingFlowBody {
  external_body: any;
  product_tracking_items: ShipmentProductsTrackingCreateItem[];
  external_shipment_id: string;
  external_order_id: string;
  external_order_number: string;
  external_shipment_number: string;
  organization_id: string;
  supplies?: PackingSupply[];
}

export interface PackingSupply {
  supply_id: string;
  quantity: number;
  facility: Common.FacilityCode;
}

export interface CompletePackingFlowReturnBody {
  product_trackings: Warehouse.ShipmentProductsTracking[];
  external_result: Fulfillment.Shipment; // shipment returned from rails
}

export const completePackingFlow = async (
  shipmentID: string,
  body: CompletePackingFlowBody
): Promise<CompletePackingFlowReturnBody> => {
  const api = getAPIFromDomain("november");
  const url = `/integrations/complete-packing-flow/${shipmentID}`;
  const respond = await api.post(url, body);
  return respond.data as CompletePackingFlowReturnBody;
};
