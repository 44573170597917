import React, { useState } from "react";
import { useHistory } from "react-router";
import { useMutation } from "react-query";
import { Result, Button, Row, Col } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";

import PageContainer from "../../../components/PageContainer";

import { generateManualItemLabels } from "../../../api/warehouse";

import * as S from "./styles";

type ManualItem = Warehouse.ManualItem;

const ManualItemCsvUploadSuccessPage: React.FC = () => {
  const history = useHistory();
  const [error, setError] = useState("");
  const items = JSON.parse(localStorage.getItem("uploadedItems") || "{}");
  const itemIds = items?.data.map((item: ManualItem) => item.id) || [];

  const { mutate: downloadLabels, isLoading } = useMutation(
    generateManualItemLabels,
    {
      onError: setError,
    }
  );

  const renderResultExtras = () => {
    return (
      <Row gutter={[8, 16]}>
        <Col xs={24}>
          <Button
            key="home"
            size="large"
            block
            onClick={() => history.replace("/manual-items")}
          >
            Back to Manual Items
          </Button>
        </Col>
        <Col xs={24}>
          <Button
            key="upload"
            size="large"
            block
            onClick={() => history.push("/manual-items/csv-upload")}
          >
            Upload Another
          </Button>
        </Col>
        <Col xs={24}>
          <Button
            key="label"
            size="large"
            block
            type="primary"
            onClick={() => downloadLabels(itemIds)}
            icon={<DownloadOutlined />}
          >
            Download Labels
          </Button>
        </Col>
      </Row>
    );
  };

  const renderResult = () => {
    return (
      <Result
        status="success"
        title="Manual Items created"
        subTitle={`${items?.data?.length} items have been added`}
        extra={renderResultExtras()}
      />
    );
  };

  return (
    <PageContainer loading={isLoading}>
      <ErrorAlert error={error} />
      <S.Container>{renderResult()}</S.Container>
    </PageContainer>
  );
};

export default ManualItemCsvUploadSuccessPage;
