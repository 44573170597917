import { TablePaginationConfig } from "antd";
import { PaginationInfo } from "../../api/warehouse/commonInterfaces";

export const calculatePaginationTotal = (paginationInfo?: PaginationInfo) => {
  if (!paginationInfo) return 0;
  const shouldShowManualPagination = !paginationInfo.itemCount;
  const manualTotal =
    (paginationInfo.itemsPerPage || 10) * (paginationInfo.currentPage || 1);
  return shouldShowManualPagination ? manualTotal : paginationInfo.totalItems;
};

export const buildTablePagination = ({
  paginationInfo = undefined,
  onShowSizeChange = () => {},
  showQuickJumper = false,
  showSizeChanger = false,
  onChange = () => {},
}: {
  paginationInfo?: PaginationInfo;
  onShowSizeChange?: (current: number, pageSize: number) => void;
  showQuickJumper?: boolean;
  showSizeChanger?: boolean;
  onChange?: (page: number, pageSize?: number) => void;
}): TablePaginationConfig => {
  if (!paginationInfo) return {};
  const { currentPage, itemsPerPage } = paginationInfo;
  return {
    current: currentPage,
    pageSize: itemsPerPage,
    showQuickJumper: showQuickJumper,
    showSizeChanger: showSizeChanger,
    onChange: onChange,
    onShowSizeChange: onShowSizeChange,
    total: calculatePaginationTotal(paginationInfo),
  };
};
