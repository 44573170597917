import React, { useEffect, useMemo, useState } from "react";
import { isEmpty } from "lodash-es";
import { useMutation } from "react-query";

//api
import { fetchShippingRates } from "../../../../../api/fulfillment";

//interface
import { Logistics } from "@secondcloset/types";

//components
import { Empty, Spin, notification, Space, Tooltip, Tag, Checkbox } from "antd";
import Text from "antd/lib/typography/Text";
import ScLogo from "../../../../../assets/sc-logo.png";

//styles
import {
  Container,
  EmptyContainer,
  RatesWrap,
  RateOption,
  Circle,
  RateImageWrap,
  RateImage,
  RateInfo,
  RateName,
  RatePrice,
  RatePriceDaysWrap,
  RateDays,
  CheckCircleFilled,
  RecommendedShippingWrap,
  RecommendedShipping,
  SignatureRateIconWrap,
} from "./styles";
import {
  getIsAcidLeague,
  getIsEndy,
  getIsHamuq,
  getIsOddPieces,
  getIsVessi,
} from "../../../../../recoil/packingFlow/selectors";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  packingFlowOrderState,
  packingFlowShipmentState,
  packingFlowShippingRateState,
  packingFlowPackageContainerState,
  packingFlowPackageWeightState,
  isUserConfirmedPoBoxAddressState,
  packingFlowPackageInsuranceState,
} from "../../../../../recoil/packingFlow/atoms";
import {
  checkIsSecondClosetLogo,
  getProviderScopeFromRatesVersion,
  useCarrierCoping,
  initializeRateMap,
  RateMap,
  getFilteredRates,
} from "./helpers";
import { FormOutlined } from "@ant-design/icons";

type ShippingRate = Logistics.ShippingRate;

interface Props {
  ratesVersion: 1 | 2;
  isSignatureRate: boolean;
}

const CarrierList: React.FC<Props> = ({ ratesVersion, isSignatureRate }) => {
  const isEndy = useRecoilValue(getIsEndy);
  const isHamuq = useRecoilValue(getIsHamuq);
  const isVessi = useRecoilValue(getIsVessi);
  const isOddPieces = useRecoilValue(getIsOddPieces);
  const isAcidLeague = useRecoilValue(getIsAcidLeague);
  const shipment = useRecoilValue(packingFlowShipmentState);
  const packingOrder = useRecoilValue(packingFlowOrderState);
  const [isUserConfirmedPosBoxAddress, setIsUserConfirmedPosBoxAddress] =
    useRecoilState(isUserConfirmedPoBoxAddressState);
  const packageContainers = useRecoilValue(packingFlowPackageContainerState);
  const packageWeights = useRecoilValue(packingFlowPackageWeightState);
  const packageInsurance = useRecoilValue(packingFlowPackageInsuranceState);
  const [shippingRate, setShippingRate] = useRecoilState(
    packingFlowShippingRateState
  );
  const [rateMap, setRateMap] = useState<RateMap>({});
  const {
    carrierIDScoping,
    shippingServiceText,
    carrierNamePreferenceScoping,
  } = useCarrierCoping({ packingOrder, ratesVersion });
  const { mutate: onFetchShippingRates, ...shippingRates } = useMutation(
    fetchShippingRates,
    {
      onSuccess: (data) => {
        const newRateMap = initializeRateMap(data.rates);
        setRateMap(newRateMap);
      },
    }
  );
  const error = shippingRates.error || shippingRates.data?.errors.join(", ");

  const formatedPackages = useMemo(
    () =>
      packageContainers.map((container, index) => {
        const insuranceValue = packageInsurance[index]?.insuranceValue;
        const insuranceCurrency = packageInsurance[index].insuranceCurrency;
        const hasInsurance = insuranceValue && insuranceValue > 0;

        return {
          weight_value: packageWeights[index].weight as number,
          weight_unit: "lb",
          length_value: container.length as number,
          length_unit: "in",
          height_value: container.height as number,
          height_unit: "in",
          width_value: container.width as number,
          width_unit: "in",
          insurance_value: hasInsurance ? insuranceValue.toFixed(2) : undefined,
          insurance_currency: hasInsurance ? insuranceCurrency : undefined,
        };
      }),
    [packageContainers, packageWeights, packageInsurance]
  );

  useEffect(() => {
    if (shipment?.id) {
      onFetchShippingRates({
        shipmentID: shipment.id,
        body: {
          packages: formatedPackages,
          include_default_carriers: !isEndy && !isHamuq,
          provider_scope: getProviderScopeFromRatesVersion(
            ratesVersion,
            isSignatureRate
          ),
          signature_rates: isSignatureRate,
        },
      });
    }

    return () => {
      shippingRates.reset();
      setShippingRate(undefined);
    };
    //eslint-disable-next-line
  }, [ratesVersion, isSignatureRate]);

  useEffect(() => {
    if (error) {
      notification.error({ message: "Error", description: error as string });
    }
  }, [error]);

  const renderRate = (rate: ShippingRate) => {
    const img = rate.carrier?.logo?.url || "";
    const serviceType = rate.service_type;
    const price = +rate.price;
    const deliveryDates = rate.delivery_days || "-";
    const isSelected = shippingRate === rate;
    const isSecondClosetLogo = checkIsSecondClosetLogo(rate, rateMap);

    return (
      <RateOption
        key={rate.rate_id}
        onClick={() => {
          setShippingRate(rate);
        }}
        selected={isSelected}
      >
        {isSelected ? <CheckCircleFilled /> : <Circle />}
        <RateImageWrap>
          <RateImage src={isSecondClosetLogo ? ScLogo : img} />
        </RateImageWrap>
        <RateInfo>
          <RateName>{serviceType.toUpperCase()}</RateName>
          <RatePriceDaysWrap>
            <RatePrice>${price ? price.toFixed(2) : "-"}</RatePrice>
            <RateDays>{`${deliveryDates} ${
              deliveryDates === 1 ? "day" : "days"
            }`}</RateDays>
          </RatePriceDaysWrap>
        </RateInfo>
        {rate?.signature_rate ? (
          <Tooltip title="Signature Rate" placement="right">
            <SignatureRateIconWrap>
              <FormOutlined />
            </SignatureRateIconWrap>
          </Tooltip>
        ) : null}
      </RateOption>
    );
  };

  const renderRates = (rates: Logistics.ShippingRate[]) => {
    const filteredRates = getFilteredRates({
      rates,
      ratesVersion,
      isSignatureRate,
      carrierIDScoping,
      carrierNamePreferenceScoping,
    });

    return filteredRates.map((rate) => renderRate(rate));
  };

  const getRecommendedShipping = () => {
    if (shippingServiceText) return shippingServiceText;

    // Sort by cheapest, then fastest
    const lowestRate = shippingRates.data?.rates?.sort((a, b) => {
      if (a.price < b.price) return -1;
      if (a.price > b.price) return 1;
      if (a.delivery_days < b.delivery_days) return -1;
      if (a.delivery_days > b.delivery_days) return 1;
      return 0;
    });
    if (lowestRate && lowestRate.length > 0) return lowestRate[0];
    return null;
  };

  const buildRecommendedShippingRateName = (
    shippingRate: Logistics.ShippingRate
  ) => {
    return `${
      ["Swyft", "Canpar"].includes(shippingRate?.carrier?.name)
        ? shippingRate?.carrier?.name
        : ""
    } ${shippingRate?.service_type}`;
  };

  const renderScopedCarrierIDs = () => {
    if (!isVessi && !isAcidLeague && !isOddPieces) return null;
    if (carrierIDScoping.length === 0) return null;
    const message = `Carrier Accounts: ${carrierIDScoping?.join(", ")}`;
    return <Tag style={{ marginLeft: 10 }}>{message}</Tag>;
  };

  const renderVessiPoBoxToggle = () => {
    if (!isVessi) return null;
    return (
      <Checkbox
        checked={isUserConfirmedPosBoxAddress}
        onChange={() => {
          setIsUserConfirmedPosBoxAddress(!isUserConfirmedPosBoxAddress);
        }}
      >
        is PO Box
      </Checkbox>
    );
  };

  const renderRecommendedBanner = () => {
    const recommendedShipping = getRecommendedShipping();
    if (!recommendedShipping) return null;
    return (
      <RecommendedShippingWrap>
        <Space size="large">
          <Text strong>RECOMMENDED</Text>
          <RecommendedShipping>
            <Text>
              {typeof recommendedShipping === "string"
                ? recommendedShipping
                : buildRecommendedShippingRateName(recommendedShipping)}
              {renderScopedCarrierIDs()}
            </Text>
          </RecommendedShipping>
        </Space>
        {renderVessiPoBoxToggle()}
      </RecommendedShippingWrap>
    );
  };

  const renderShippingRates = () => {
    const rates = shippingRates.data?.rates || [];
    if (isEmpty(rates)) {
      return (
        <EmptyContainer>
          <Empty description="Please enter box weight" />
        </EmptyContainer>
      );
    }
    return <RatesWrap>{renderRates(rates)}</RatesWrap>;
  };

  return (
    <Container>
      <Spin spinning={shippingRates.isLoading}>
        {renderRecommendedBanner()}
        {renderShippingRates()}
      </Spin>
    </Container>
  );
};

export default CarrierList;
