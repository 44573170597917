import { atom } from "recoil";

export const menuExpandedState = atom<boolean>({
  key: "menuExpendedState",
  default: false,
});

export const menuTitleState = atom<string>({
  key: "menuTitleState",
  default: "",
});
