import styled from "styled-components";
import { COLORS } from "../../../../styles";

export const Label = styled.div`
  color: ${COLORS.GREY};
  font-size: 12px;
`;
export const Value = styled.div`
  font-size: 12px;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Status = styled.span<{ status: string }>`
  color: ${({ status }) => {
    switch (status) {
      case "COMPLETED":
        return COLORS.GREEN;
      case "IN_PROGRESS":
        return COLORS.ORANGE;
      default:
        return COLORS.GREY;
    }
  }};
`;
