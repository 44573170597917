import { Warehouse } from "@secondcloset/types";
import queryString from "query-string";
import { getAPIFromDomain } from "../../lib/api";
import { formatShipmentPackages } from "../../pages/TransferCustodyPage/TransferCustodyScannerPage/helpers";
import { PaginatedData, PaginationOptions } from "./commonInterfaces";
type CarrierTransferPackage = Warehouse.CarrierTransferPackage;

const basePath = "carrier-transfer-packages";

interface CTPackageQuery {
  tracking_number: string;
}

export interface TransferCustodyQuery {
  input: string;
}

export const fetchCarrierTransferPackages = async (
  query: PaginationOptions & Partial<CarrierTransferPackage> & any
): Promise<PaginatedData<CarrierTransferPackage>> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}?${queryString.stringify(query || {})}`;
  const response = await api.get(url);
  return response.data as PaginatedData<CarrierTransferPackage>;
};

export const fetchCarrierTransferPackageByTrackingNumber = async (
  query: CTPackageQuery
) => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/package-by-query?${queryString.stringify(
    query || {}
  )}`;
  const response = await api.get(url);
  return response.data;
};

export const createCarrierTransferPackage = async (
  body: Partial<CarrierTransferPackage>
): Promise<CarrierTransferPackage> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}`;
  const response = await api.post(url, body);
  return response.data as CarrierTransferPackage;
};

export const updateCarrierTransferPackage = async (
  id: string,
  body: Partial<CarrierTransferPackage>
): Promise<CarrierTransferPackage> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/${id}`;
  const response = await api.put(url, body);
  return response.data as CarrierTransferPackage;
};

export const deleteCarrierTransferPackage = async (
  id: string
): Promise<void> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/${id}`;
  await api.delete(url);
};

export const fetchShipmentPackageById = async (
  id: string
): Promise<CarrierTransferPackage[]> => {
  const api = getAPIFromDomain("logistics");
  const url = `/shipment_packages/${id}`;
  const response = await api.get(url);
  return formatShipmentPackages(response?.data);
};

export const searchForShipmentPackage = async (
  query: CTPackageQuery
): Promise<CarrierTransferPackage[] | undefined> => {
  const api = getAPIFromDomain("logistics");
  const url = `/shipment_packages?${queryString.stringify(query || {})}`;
  const response = await api.get(url);
  const foundShipmentPackageId = response?.data?.[0]?.id;
  if (foundShipmentPackageId) {
    return await fetchShipmentPackageById(foundShipmentPackageId);
  }
  return;
};

export const createShippingPackage = async (
  body: Partial<CarrierTransferPackage>
): Promise<CarrierTransferPackage> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}`;
  const response = await api.post(url, body);
  return response.data as CarrierTransferPackage;
};

export const handoverPalletPackages = async (
  palletId: string
): Promise<CarrierTransferPackage[]> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/transfer-packages-by-pallet-id/${palletId}`;
  const response = await api.patch(url);
  return response.data as CarrierTransferPackage[];
};
