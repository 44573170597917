import React from "react";
import { InputNumber, InputNumberProps } from "antd";

const QuantityInput: React.FC<InputNumberProps<number>> = (props) => {
  return (
    <InputNumber
      min={0}
      max={props.max}
      size={props.size}
      value={props.value}
      onChange={props.onChange}
      onPressEnter={props.onPressEnter}
    />
  );
};

QuantityInput.defaultProps = {
  size: "large",
};

export default QuantityInput;
