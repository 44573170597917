import React, { useEffect, useMemo, FC } from "react";
import { startCase } from "lodash-es";

// assets
import boxSVG from "../../../../assets/box.svg";
import standardPalletSVG from "../../../../assets/standard_pallet.svg";
import doublePalletSVG from "../../../../assets/double_pallet.svg";

// styles
import * as S from "./styles";

// components
import { Typography } from "antd";

// hooks & util
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  PackageType,
  packingFlowPackageTypeState,
  packingFlowShipmentState,
  packingFlowShippingMethodState,
  packingFlowStepState,
  Step,
} from "../../../../recoil/packingFlow/atoms";
import { usePresetPackageWeights } from "../../../../recoil/packingFlow/helpers";

const { Title } = Typography;
interface PackageTypeOption {
  value: PackageType;
  image: string;
  description: string;
}

const PACKAGE_TYPE_OPTIONS: PackageTypeOption[] = [
  {
    value: PackageType.box,
    image: boxSVG,
    description: "Order items do not require a pallet for shipment.",
  },
  {
    value: PackageType.standard_pallet,
    image: standardPalletSVG,
    description: `Packing items on a 48" x 40" standard pallet.`,
  },
  {
    value: PackageType.double_pallet,
    image: doublePalletSVG,
    description: `Packing items on a 96” x 48” double pallet.`,
  },
];

const SelectPackageTypeStep: FC = () => {
  const dispatchSetStep = useSetRecoilState(packingFlowStepState);
  const shippingMethod = useRecoilValue(packingFlowShippingMethodState);
  const shipment = useRecoilValue(packingFlowShipmentState);
  const [packageType, dispatchSetPackageType] = useRecoilState(
    packingFlowPackageTypeState
  );
  const presetPackageWeights = usePresetPackageWeights();
  const { box, standard_pallet } = PackageType;
  const packages = shipment?.packages || [];
  const hasBox = packages.some((p) => p.package_type === box);

  const packageTypeOptions = useMemo(() => {
    if (packages.length === 0) return PACKAGE_TYPE_OPTIONS;
    else return PACKAGE_TYPE_OPTIONS.filter((o) => o.value !== box);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [packages]);

  useEffect(() => {
    if (shippingMethod === "freight") dispatchSetPackageType(standard_pallet);
    if (hasBox) {
      dispatchSetPackageType(box);
      dispatchSetStep(Step.selectShipmentItem);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [hasBox]);

  useEffect(() => {
    presetPackageWeights(packageType);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [packageType]);

  const renderOption = (option: PackageTypeOption) => {
    const { value, image, description } = option;
    const isSelected = packageType === value;
    const warningText =
      packageType !== PackageType.box
        ? "A maximum of 6 pallets is allowed per shipment."
        : "A maximum of 35 boxes or 600 cubic feet is allowed per shipment.";
    return (
      <S.CardWrapper key={value}>
        <S.Card
          isSelected={isSelected}
          onClick={() => {
            dispatchSetPackageType(value);
          }}
        >
          <S.Icon src={image} alt="Option Image" />
          <S.CardTitle level={4}>{startCase(value)}</S.CardTitle>
          <S.Text>{description}</S.Text>
        </S.Card>
        {isSelected && warningText ? (
          <S.InfoBanner message={warningText} type="info" />
        ) : null}
      </S.CardWrapper>
    );
  };

  return (
    <S.Container>
      <Title level={3}>SELECT PACKAGE TYPE</Title>
      <S.CardContainer>
        {packageTypeOptions?.map((o) => renderOption(o))}
      </S.CardContainer>
    </S.Container>
  );
};

export default SelectPackageTypeStep;
