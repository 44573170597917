import { Warehouse } from "@secondcloset/types";
import { UploadFileStatus } from "antd/lib/upload/interface";
import Organization from "../../../lib/organization";
import { ReturnStatus } from "../ReturnsIndexPage/helpers";

type ReturnPhoto = Warehouse.ReturnPhoto;
type ReturnItem = Warehouse.ReturnItem;

export const mapPhotosToAntdType = (photos: ReturnPhoto[]) => {
  return photos?.map((photo) => ({
    uid: photo?.id,
    name: photo?.id,
    status: "success" as UploadFileStatus,
    url: photo?.photo_link,
  }));
};

export const validateReturnFieldsConditions = (
  orgName: string,
  items: ReturnItem[],
  isRegular: boolean,
  photos?: ReturnPhoto[]
): { isValid: boolean; error?: string } => {
  const isVessi = Organization.isVessi({ organizationName: orgName });

  for (let i = 0; i < items.length; i++) {
    const { condition, condition_notes } = items[i];
    if (!condition)
      return { isValid: false, error: "Please select condition of each item" };
    if (isVessi && isRegular && !condition_notes)
      return {
        isValid: false,
        error: "Every condition details field needs to be filled out",
      };
    if (condition?.toLowerCase() === "other" && !condition_notes)
      return {
        isValid: false,
        error: "Please provide condition notes for OTHER condition",
      };
  }
  if (isRegular && photos?.length === 0)
    return { isValid: false, error: "Please add photos of the return" };

  return { isValid: true };
};

export enum OrganizationConfigKey {
  ENABLE_SCAN_ITEMS = "ENABLE_SCAN_ITEMS",
  OPENED_GOOD_CONDITION_SHIP_TO_ADDRESS = "OPENED_GOOD_CONDITION_SHIP_TO_ADDRESS",
  SHIP_TO_ME = "SHIP_TO_ME",
  OPENED_GOOD_CONDITION_INSTRUCTION = "OPENED_GOOD_CONDITION_INSTRUCTION",
  DAMAGED_CONDITION_INSTRUCTION = "DAMAGED_CONDITION_INSTRUCTION",
  GOOD_CONDITION_DAMAGED_PACKAGING_INSTRUCTION = "GOOD_CONDITION_DAMAGED_PACKAGING_INSTRUCTION",
  RTS_INSTRUCTION = "RTS_INSTRUCTION",
}
export const isAtEndOfFlow = (status: string) =>
  status === ReturnStatus.COMPLETED ||
  status === ReturnStatus.TO_BE_RESHIPPED ||
  status === ReturnStatus.CANCELLED;
