import styled from "styled-components";
import { COLORS } from "../../../../styles";
import { Typography, Alert as AntdAlert } from "antd";
const { Text: AntdText, Title } = Typography;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
`;

export const Text = styled(AntdText)`
  font-size: 14px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 50px 20px;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 360px;
`;

export const InfoBanner = styled(AntdAlert)`
  width: 260px;
  margin: 20px auto;
  text-align: left;
`;

export const Card = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 260px;
  width: 260px;
  border-radius: 10px;
  margin: 0 40px;
  padding: 1em;
  text-align: center;
  cursor: pointer;
  border: ${({ isSelected }) =>
    isSelected ? `3px solid ${COLORS.BLUE}` : `1px solid ${COLORS.GREY_LIGHT}`};
  background-color: ${COLORS.WHITE};
`;

export const CardTitle = styled(Title)`
  margin: 10px auto 70px;
  height: 40px;
`;

export const Icon = styled.img`
  height: 100%;
  margin: 0 auto 15px;
  height: 50px;
  max-width: 80px;
  overflow: hidden;
  object-fit: contain;
`;
