import {
  weightUnitTypes,
  dimensionUnitTypes,
} from "../../CreateSupplyPage/helpers";

export const editableFields = [
  { key: "width", label: "Width", requiredType: ["BOX"] },
  { key: "height", label: "Height", requiredType: ["BOX"] },
  { key: "length", label: "Length", requiredType: ["BOX"] },
  { key: "weight", label: "Weight" },
];

export const hideInDisplayViewFields = [
  { key: "weight_unit", label: "Weight Unit" },
  { key: "dimension_unit", label: "Dimension Unit" },
];

export const unitsOptions = {
  weight_unit: weightUnitTypes,
  dimension_unit: dimensionUnitTypes,
};

export const unitsOptionsDependencies = {
  weight_unit: ["weight"],
  dimension_unit: ["length", "width", "height"],
};

export const unitType = {
  weight: "weight_unit",
  width: "dimension_unit",
  height: "dimension_unit",
  length: "dimension_unit",
};

export const formatSupplyCodeForm = (form) => {
  const newForm = { ...form };
  newForm.code = newForm.code ? newForm.code.trim() : null;
  return newForm;
};
