import React, { FC, useCallback, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { Button, Spin, Table, Typography } from "antd";
import { ErrorAlert } from "@secondcloset/web-components";
import { FixedType } from "rc-table/lib/interface";

import NullSafeDate from "../../../../components/Table/NullSafeDate";

import {
  fetchSupplyTransactions,
  SupplyTransaction,
} from "../../../../api/warehouse";
import { formatNumberWithComma } from "../../../../lib/formatNumberWithComma";
import { getTextColor } from "./helpers";

import * as S from "./styles";
import useUrlState from "@ahooksjs/use-url-state";
import { buildTablePagination } from "../../../../components/Pagination/helper";

const { Text } = Typography;

interface Props {
  stockUpdateCount: number;
}

const SupplyTransactionsTable: FC<Props> = ({ stockUpdateCount }) => {
  const history = useHistory();
  const { supplyId } = useParams<{ supplyId: string }>();
  const [error, setError] = useState("");
  const [{ page, perPage }, setUrlState] = useUrlState(
    {
      page: 1,
      perPage: 10,
    },
    { navigateMode: "replace" }
  );

  const paginatedTransactions = useQuery(
    ["fetchSupplyTransactions", supplyId, page, perPage, stockUpdateCount],
    () =>
      fetchSupplyTransactions({
        supply_id: supplyId,
        page: `${page}`,
        limit: perPage,
        sort_field: "created_at",
        sort_direction: "DESC",
      }),
    {
      onError: (err: string) => {
        setError(err);
      },
    }
  );
  const { isLoading } = paginatedTransactions;
  const getTransactionsData = useCallback((): SupplyTransaction[] => {
    return paginatedTransactions.data?.items || [];
  }, [paginatedTransactions]);

  const renderBoldText = (text) => {
    return <Text strong>{text}</Text>;
  };

  const renderOrderLink = (orderId) => {
    if (!orderId) return <S.CenteredText>-</S.CenteredText>;
    return (
      <Button
        target="_blank"
        type="link"
        href={`${process.env.REACT_APP_SIERRA_DOMAIN}/fulfillment/orders/${orderId}`}
      >
        ...{orderId.substr(orderId.length - 4)}
      </Button>
    );
  };

  const renderSpecialProjectLink = (projectId) => {
    if (!projectId) return <S.CenteredText>-</S.CenteredText>;
    return (
      <Button
        target="_blank"
        type="link"
        style={{ padding: 0 }}
        onClick={() => history.push(`/special-projects/${projectId}`)}
      >
        {projectId}
      </Button>
    );
  };

  const renderDate = (date: string) => <NullSafeDate date={date} />;

  const renderDelta = (change) => {
    return (
      <Text type={getTextColor(change)}>
        {formatNumberWithComma(`${change}`)}
      </Text>
    );
  };

  const buildTableColumns = () => {
    const columns = [
      {
        key: "facility",
        title: "Facility",
        dataIndex: "facility",
        render: renderBoldText,
        fixed: "left" as FixedType,
      },
      {
        key: "delta",
        title: "Delta",
        dataIndex: "delta",
        render: renderDelta,
        fixed: "left" as FixedType,
      },
      {
        key: "order",
        title: "Order",
        dataIndex: "external_order_id",
        render: renderOrderLink,
      },
      {
        key: "special_project_id",
        title: "Special Project",
        dataIndex: "special_project_id",
        render: renderSpecialProjectLink,
      },
      {
        key: "user_email",
        title: "User Email",
        dataIndex: "user_email",
      },
      {
        key: "created_at",
        title: "Created At",
        dataIndex: "created_at",
        render: renderDate,
      },
    ];
    return columns;
  };

  const buildPagination = () => {
    const paginationInfo = paginatedTransactions.data?.meta;
    if (!paginationInfo) return {};
    return buildTablePagination({
      paginationInfo,
      onChange: (p: number, newPageSize?: number) => {
        if (newPageSize && newPageSize !== +perPage) {
          setUrlState({ page: 1, perPage: newPageSize });
        } else {
          setUrlState({ page: p });
        }
      },
      showQuickJumper: true,
      showSizeChanger: true,
    });
  };

  return (
    <Spin spinning={isLoading}>
      <ErrorAlert error={error} />
      <Table
        size="small"
        title={() => <Typography.Text strong>ACTIVITY</Typography.Text>}
        rowKey="id"
        scroll={{ x: "max-content" }}
        bordered
        columns={buildTableColumns()}
        dataSource={getTransactionsData()}
        pagination={buildPagination()}
      />
    </Spin>
  );
};

export default SupplyTransactionsTable;
