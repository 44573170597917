import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ProfilerProps } from "@sentry/react/dist/profiler";
import { CaptureContext } from "@sentry/types/esm/scope";
import { version } from "../../../package.json";
import { isLocalhost } from "../../serviceWorker";

const isStaging = process.env.REACT_APP_API?.includes("staging");

// sentry configuration
const sentryInit = (): void => {
  if (!isLocalhost)
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: version,
      integrations: [new Integrations.BrowserTracing()],
      environment: isStaging ? "staging" : "production",
      tracesSampleRate: isStaging ? 1 : 0.25,
      normalizeDepth: isStaging ? 3 : 6,
    });
};

export const withProfiler = (
  component: React.FC,
  options?: ProfilerProps
): React.FC => {
  if (isStaging) return component;
  return Sentry.withProfiler(component, options); // profiling only in production
};

export const report = (error: Error, context?: CaptureContext): string => {
  if (typeof error === "string") {
    return Sentry.captureMessage(error, {
      level: Sentry.Severity.Error,
      ...context,
    });
  } else {
    return Sentry.captureException(error, context);
  }
};

export default sentryInit;
export { default as ErrorBoundary } from "./ErrorBoundary";
