import styled from "styled-components";
import { Space, Button as AntdButton } from "antd";
import { COLORS } from "../../../../styles";

export const Container = styled(Space)`
  width: 100%;
  padding: 0 20px;
`;

export const HeaderTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

export const Card = styled.div`
  color: ${COLORS.GREY};
  font-size: 11px;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GREY_LIGHT};
  border-radius: 5px;
  padding: 10px;

  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1.2rem;
  justify-content: center;
  align-items: center;
`;

export const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.2rem;
  align-items: center;
`;

export const Label = styled.div``;

export const Button = styled(AntdButton)`
  display: block;
  margin: 30px auto 0;
`;
