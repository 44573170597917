import { Fulfillment } from "@secondcloset/types";
import { stringify } from "query-string";
import { getAPIFromDomain } from "../../lib/api";

type Order = Fulfillment.Order;

type OrderIndexQuery = {
  q: string;
  page: number;
  per_page: number;
  shipment_tags: { values: string[]; condition: string };
};

export const fetchOrderIndex = async (
  query: OrderIndexQuery
): Promise<Order[]> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/orders/index?page=${query.page}`;
  const response = await api.post(url, query);
  return response.data as Fulfillment.Order[];
};

export const fetchOrderDetails = async (
  orderID: string
): Promise<Fulfillment.Order> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/orders/${orderID}`;
  const response = await api.get(url);
  return response.data as Fulfillment.Order;
};

export const orderLookup = async (q: string): Promise<Fulfillment.Order[]> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/orders/index`;
  const body = { q };
  const response = await api.post(url, body);
  return response.data as Fulfillment.Order[];
};

interface V2OrderQuery {
  q?: string;
  organization_id?: string;
  tracking_number?: string;
  external_order_number?: string;
  direction?: string;
  field?: string;
  per_page?: number;
  page?: number;
}

export const fetchOrderIndexV2 = async (query?: V2OrderQuery) => {
  const api = getAPIFromDomain("fulfillment", 2);
  const url = `/orders${query ? "?" + stringify(query) : ""}`;
  const response = await api.get(url);
  return response;
};
