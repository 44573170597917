import React, { useState } from "react";
import { useHistory } from "react-router";
import { useMutation, useQuery } from "react-query";
import { Button, Col, Divider, Popconfirm, Row, Space } from "antd";
import { CheckOutlined, UndoOutlined } from "@ant-design/icons";
import { ErrorAlert } from "@secondcloset/web-components";

// Components
import BannerWithButton from "../../../components/BannerWithButton";
import PageContainer from "../../../components/PageContainer";
import ScannerInput from "../../../components/ScannerInput";
import WithLabel from "../../../components/WithLabel";
import ItemsTable from "./ItemsTable";

// Types
import { Product } from "../../../api/warehouse/products";

// Api
import { createPallet, fetchLocationItems } from "../../../api/warehouse";

export type ProductWithQuantity = Product & {
  quantity: number;
};

const CreatePalletWithItemsPage: React.FC = () => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [scanLocationInput, setScanLocationInput] = useState("");
  const [location, setLocation] = useState("");
  const [palletId, setPalletId] = useState("");
  const [attachedPalletId, setAttachedPalletId] = useState("");

  const resetStates = () => {
    setScanLocationInput("");
    setPalletId("");
    setLocation("");
    setError("");
  };

  const { data: locationItems, isLoading: loadingItems } = useQuery(
    ["getLocationItems", location],
    () =>
      fetchLocationItems({ location_code: location, page: "1", limit: "100" }),
    {
      onSuccess: (result) => {
        if (!result?.items.length) {
          setError(`No items found at location ${location}`);
          setAttachedPalletId("");
          setLocation("");
        }
      },
      onError: setError,
    }
  );

  const { mutate: groupItemsToPallet, isLoading: groupingItems } = useMutation(
    () => createPallet({ location_code: location }, { pallet_id: palletId }),
    {
      onSuccess: () => {
        setAttachedPalletId(palletId);
        resetStates();
      },
      onError: setError,
    }
  );

  const renderSuccessBanner = () => {
    return (
      <BannerWithButton
        type="info"
        message={`Items successfully grouped to pallet: ${attachedPalletId}`}
        buttonText="Go to pallet"
        onClickButton={() => history.push(`/pallets/${attachedPalletId}`)}
        onClose={() => setAttachedPalletId("")}
      />
    );
  };

  const renderResetPopup = () => {
    return (
      <Popconfirm
        title="Are you sure?"
        okText="Yes"
        cancelText="No"
        disabled={!palletId}
        onConfirm={resetStates}
      >
        <Button
          icon={<UndoOutlined />}
          block
          ghost
          danger
          type="primary"
          size="large"
          disabled={!palletId}
        >
          Reset
        </Button>
      </Popconfirm>
    );
  };

  const handleScanLocation = (location: string) => {
    setLocation(location.trim());
    setScanLocationInput("");
    setError("");
  };

  const renderScanLocationInput = () => {
    return (
      <WithLabel name="Scan Location">
        <ScannerInput
          value={scanLocationInput}
          autoFocus
          onEnter={handleScanLocation}
          onChange={setScanLocationInput}
        />
      </WithLabel>
    );
  };

  const renderTable = () => {
    return (
      <ItemsTable
        items={location ? locationItems?.items : []}
        loading={loadingItems}
        location={location}
        showTableHeader
      />
    );
  };

  const renderPalletIDInput = () => {
    return (
      <WithLabel name="To Pallet ID">
        <ScannerInput
          value={palletId}
          onChange={(val) => setPalletId(val.trim())}
          rightAligned
        />
      </WithLabel>
    );
  };

  const renderConfirmButton = () => {
    return (
      <Button
        size="large"
        type="primary"
        block
        icon={<CheckOutlined />}
        loading={groupingItems}
        disabled={!palletId}
        onClick={() => groupItemsToPallet()}
      >
        Confirm
      </Button>
    );
  };

  const renderTopToolbar = () => {
    return (
      <Row justify="space-between" align="bottom" gutter={[8, 8]}>
        <Col xs={24} lg={16}>
          {renderScanLocationInput()}
        </Col>
        <Col xs={24} lg={3}>
          {renderResetPopup()}
        </Col>
      </Row>
    );
  };

  const renderBottomToolbar = () => {
    return (
      <>
        <Row justify="space-between" align="bottom" gutter={[8, 8]}>
          <Col xs={24} lg={{ span: 8, push: 16 }}>
            {renderPalletIDInput()}
          </Col>
        </Row>
        <Divider />
        <Row justify="center" align="bottom" gutter={[8, 8]}>
          <Col xs={24} lg={4}>
            {renderConfirmButton()}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <PageContainer
      title="Group Items to Pallet"
      withHeader
      withFooter
      withPadding
    >
      <Space style={{ width: "100%" }} direction="vertical" size="large">
        <ErrorAlert error={error} />
        {attachedPalletId ? renderSuccessBanner() : ""}
        {renderTopToolbar()}
        {renderTable()}
        {renderBottomToolbar()}
      </Space>
    </PageContainer>
  );
};

export default CreatePalletWithItemsPage;
