import React, { useState } from "react";

// components
import { Alert, Button, DatePicker, Empty, Select } from "antd";
import PageContainer from "../../components/PageContainer";

// hooks
import { useMutation } from "react-query";

// styles
import * as S from "./styles";

// helpers
import moment from "moment";
import { dateToMoment, momentToDate } from "./helpers";
import { startCase } from "lodash-es";
import { getManifest } from "../../api/logistics/manifest";

enum CarrierCodes {
  "canadaPost" = "canada_post",
}

const ManifestGenerationPage: React.FC = () => {
  const [error, setError] = useState("");
  const [date, setDate] = useState<string>(momentToDate(moment()));
  const [carrierCode, setCarrierCode] = useState<CarrierCodes>(
    CarrierCodes.canadaPost
  );
  const {
    mutate,
    isLoading,
    data: manifest,
  } = useMutation(
    () => {
      setError("");
      return getManifest({ date, carrier_code: carrierCode });
    },
    {
      onError: (err: string) => {
        setError(err);
      },
      onSuccess: (manifest) => {
        if (!manifest) setError("No Manifest found");
      },
    }
  );

  const renderDateSelect = () => {
    return (
      <S.DateWrap>
        <S.SectionTitle>Please Select a Date:</S.SectionTitle>
        <DatePicker
          format="ll"
          value={dateToMoment(date)}
          onChange={(m: any) => {
            setDate(momentToDate(m || moment()));
          }}
        />
      </S.DateWrap>
    );
  };

  const renderCarrierCodeSelect = () => {
    const options = Object.values(CarrierCodes).map((code) => {
      return (
        <Select.Option key={code} value={code}>
          {startCase(code)}
        </Select.Option>
      );
    });
    return (
      <S.CarrierCodeWrap>
        <S.SectionTitle>Please Select a Carrier:</S.SectionTitle>
        <Select
          disabled
          value={carrierCode}
          onSelect={(v: CarrierCodes) => {
            setCarrierCode(v);
          }}
        >
          {options}
        </Select>
      </S.CarrierCodeWrap>
    );
  };

  const renderSearchButton = () => {
    return (
      <S.SearchButtonWrap>
        <Button
          type="primary"
          onClick={() => {
            mutate();
          }}
          loading={isLoading}
        >
          Search
        </Button>
      </S.SearchButtonWrap>
    );
  };

  const renderHeader = () => {
    return (
      <S.HeaderWrap>
        {renderDateSelect()}
        {renderCarrierCodeSelect()}
        {renderSearchButton()}
      </S.HeaderWrap>
    );
  };

  const renderManifest = () => {
    const url = manifest?.url;
    if (!url) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    return (
      <S.ManifestWrap>
        <S.SectionTitle>URL:</S.SectionTitle>
        <S.ManifestURL href={url} target="_blank">
          {url}
        </S.ManifestURL>
      </S.ManifestWrap>
    );
  };

  const buildAlert = () => {
    if (!error) return null;
    return (
      <S.AlertBoxWrap>
        <Alert message="Error" description={error} type="error" showIcon />
      </S.AlertBoxWrap>
    );
  };

  return (
    <PageContainer
      withHeader
      withFooter
      withPadding
      title="Manifest Generation"
    >
      <S.Container>
        {buildAlert()}
        {renderHeader()}
        {renderManifest()}
      </S.Container>
    </PageContainer>
  );
};

export default ManifestGenerationPage;
