import { getAPIFromDomain } from "../../lib/api";
import queryString from "query-string";
import { Warehouse } from "@secondcloset/types";

// Interfaces
import { PaginatedData, PaginationOptions } from "./commonInterfaces";

type LocationActivityLog = Warehouse.LocationActivityLog;
type PackerActivityLog = Warehouse.PackerActivityLog;

interface ActivityQuery {
  search?: string;
  sort_field: string;
  sort_direction: "ASC" | "DESC";
  start_date?: string;
  end_date?: string;
  from_location_code?: string;
  to_location_code?: string;
  actions?: string[];
}

export const fetchLocationActivityLogs = async (
  query?: PaginationOptions & ActivityQuery
): Promise<PaginatedData<LocationActivityLog>> => {
  const api = getAPIFromDomain("november");
  const url = `/location-activity-logs?${queryString.stringify(query || {})}`;
  const respond = await api.get(url);
  return respond.data as PaginatedData<LocationActivityLog>;
};

export const fetchPackerActivityLogs = async (
  query?: PaginationOptions & ActivityQuery
): Promise<PaginatedData<PackerActivityLog>> => {
  const api = getAPIFromDomain("november");
  const url = `/packer-activity-logs?${queryString.stringify(query || {})}`;
  const respond = await api.get(url);
  return respond.data as PaginatedData<PackerActivityLog>;
};
