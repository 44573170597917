//interface
import { Common } from "@secondcloset/types";
import { getAPIFromDomain } from "../lib/api";

type User = Common.User;

export const fetchUserDetails = async (userID: string): Promise<User> => {
  const api = getAPIFromDomain();
  const url = `/users/${userID}`;
  const response = await api.get(url);
  return response.data.user as User;
};
