import React from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { List, PageHeader, Switch, Typography } from "antd";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";

import PageContainer from "../../../components/PageContainer";

import {
  fetchOrganizationConfigKeys,
  fetchOrganizationConfigs,
  fetchOrganizationDetails,
  updateOrganizationConfig,
  UpdateOrganizationConfigBody,
} from "../../../api/warehouse/organizations";

import { getConfigValue, getReadableConfigKey } from "./helpers";

type OrganizationConfigKey = Warehouse.OrganizationConfigKey;

const OrganizationDetailsPage = () => {
  const { organizationID } = useParams<{ organizationID?: string }>();

  const {
    data: configKeys,
    isLoading: loadingConfigKeys,
    error: fetchOrganizationKeysError,
  } = useQuery("fetchOrganizationConfigKeys", fetchOrganizationConfigKeys);

  const {
    data: organization,
    isLoading: loadingOrganization,
    error: fetchOrganizationError,
  } = useQuery(["fetchOrganizationDetails"], () => {
    return fetchOrganizationDetails(organizationID || "");
  });

  const {
    data: organizationConfigs,
    isLoading: loadingConfig,
    refetch: refetchConfigs,
    error: fetchOrganizationConfigsError,
  } = useQuery(
    ["fetchOrganizationConfigs", organization, organizationID],
    () => {
      return fetchOrganizationConfigs(organizationID || "");
    },
    { enabled: !!organization }
  );

  const {
    mutate: onUpdateConfig,
    isLoading: updatingConfig,
    error: onUpdateConfigError,
  } = useMutation(
    (body: UpdateOrganizationConfigBody) => {
      return updateOrganizationConfig(body);
    },
    {
      onSuccess: () => {
        refetchConfigs();
      },
    }
  );

  const loading =
    loadingConfig || loadingConfigKeys || loadingOrganization || updatingConfig;

  const renderConfig = (key: OrganizationConfigKey) => {
    const foundConfig = organizationConfigs?.find((c) => c?.key === key);
    const configValue = foundConfig?.value || "";
    const { dataType, value } = getConfigValue(key, configValue);
    if (dataType === "boolean") {
      return (
        <List.Item
          extra={
            <Switch
              checked={value}
              onChange={(checked: boolean) => {
                onUpdateConfig({
                  key,
                  value: checked ? "true" : "false",
                  organization_id: organizationID || "",
                });
              }}
            ></Switch>
          }
        >
          {getReadableConfigKey(key)}
        </List.Item>
      );
    }
  };

  const renderConfigs = () => {
    return <List bordered dataSource={configKeys} renderItem={renderConfig} />;
  };

  const renderError = () => {
    const error =
      fetchOrganizationConfigsError ||
      fetchOrganizationError ||
      fetchOrganizationKeysError ||
      onUpdateConfigError;

    return <ErrorAlert error={error as string} />;
  };

  return (
    <PageContainer withPadding loading={loading}>
      <PageHeader
        title="Organization Detail"
        onBack={() => window.history.back()}
      />
      <Typography.Title>{organization?.name || "Loading..."}</Typography.Title>
      {renderError()}
      {renderConfigs()}
    </PageContainer>
  );
};

export default OrganizationDetailsPage;
