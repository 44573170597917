//interface
import axios from "axios";
import { Common } from "@secondcloset/types";
import { getAPIFromDomain, getHeaderTemplate } from "../lib/api";

type Credential = Common.Credential;

type LoginProp = {
  email: string;
  password: string;
};

export type CurrentUser = {
  permissions: string[];
  roles: string[];
  user: string[];
};

export const login = async ({
  email,
  password,
}: LoginProp): Promise<Partial<Credential>> => {
  const api = getAPIFromDomain();
  const url = "/tokens";
  const body = { user: { password, email } };
  const response = await api.post(url, body);
  return response.data;
};

export const getCurrentUser = async (
  token: string
): Promise<{ data: CurrentUser }> => {
  const header = getHeaderTemplate(token);
  const url = `${process.env.REACT_APP_API}/api/v1/current_user`;
  return axios.get(url, header);
};
