import React, { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import {
  Button,
  Col,
  Input,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { ErrorAlert } from "@secondcloset/web-components";
import { Warehouse } from "@secondcloset/types";

import WithLabel from "../../../components/WithLabel";
import PageContainer from "../../../components/PageContainer";
import NullSafeText from "../../../components/Table/NullSafeText";
import ItemCountFooter from "../../../components/Table/ItemCountFooter";
import ScannerInput from "../../../components/ScannerInput";

import {
  PACKAGE_STATUS_COLOR,
  PACKAGE_STATUS_LABEL,
} from "../../CarrierTransferPackagesPage/helper";
import {
  deleteCarrierTransferPackage,
  fetchCarrierTransferPalletsById,
  createCarrierTransferPackage,
  generatePalletLabel,
} from "../../../api/warehouse";
import { PrinterOutlined } from "@ant-design/icons";
import { getHandedOverTagColor, getHandedOverTagText } from "../helper";

type CarrierTransferPackage = Warehouse.CarrierTransferPackage;

const CarrierTransferPalletDetailPage = () => {
  const inputRef = useRef<Input | null>(null);
  const params = useParams<Record<string, string>>();
  const [error, setError] = useState("");
  const palletId = params["palletId"];

  const {
    data: carrierTransferPallet,
    isLoading: isLoadingPallet,
    refetch: loadPackages,
  } = useQuery(
    ["carrierTransferPallet", params],
    () => {
      return fetchCarrierTransferPalletsById(palletId, {});
    },
    { retry: false, onError: (e: string) => setError(e) }
  );

  const { mutate: printLabel, isLoading: isPrintingLabel } = useMutation(
    generatePalletLabel,
    { onError: (e: string) => setError(e) }
  );

  const { mutate: removePackage, isLoading: isRemoving } = useMutation(
    deleteCarrierTransferPackage,
    {
      onSuccess: async () => await loadPackages(),
      onError: (e: string) => setError(e),
    }
  );

  const { mutate: onScanTrackingNumber, isLoading: isAddingPackage } =
    useMutation(
      (trackingNumber: string) =>
        createCarrierTransferPackage({
          tracking_number: trackingNumber,
          status: "at_facility",
          carrier_transfer_pallet_id: palletId,
        }),
      {
        onError: setError,
        onSuccess: () => {
          setError("");
          loadPackages();
        },
        onSettled: () => {
          inputRef.current?.setValue("");
          setTimeout(() => {
            inputRef.current?.focus();
          }, 300);
        },
      }
    );

  const tableColumns = [
    {
      title: "Tracking #",
      width: 280,
      render: (number: string) => <NullSafeText value={number} />,
      dataIndex: "tracking_number",
    },
    {
      title: "Status",
      width: 120,
      render: (status: string) => (
        <Tag color={PACKAGE_STATUS_COLOR[status]}>
          {PACKAGE_STATUS_LABEL[status]}
        </Tag>
      ),
      dataIndex: "status",
    },
    {
      title: "Carrier",
      width: "120",
      dataIndex: "carrier_name",
    },
    {
      title: "Action",
      width: 120,
      render: (item: CarrierTransferPackage) => (
        <Popconfirm
          title="Are you sure to remove this item?"
          onConfirm={async () => await removePackage(item.id)}
          disabled={!!carrierTransferPallet?.handed_over}
        >
          <Button
            type="link"
            danger
            block
            disabled={!!carrierTransferPallet?.handed_over}
          >
            Remove
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const renderTrackingNumSearchBar = () => (
    <WithLabel name="Scan tracking number to add packages">
      <ScannerInput
        disabled={!!carrierTransferPallet?.handed_over}
        scannerRef={inputRef}
        allowClear
        autoFocus
        size="large"
        loading={isAddingPackage || isLoadingPallet}
        onEnter={(value: string) => {
          onScanTrackingNumber(value.trim());
        }}
      />
    </WithLabel>
  );

  const renderTableFooter = () => (
    <ItemCountFooter
      count={carrierTransferPallet?.carrier_transfer_packages?.length || 0}
    />
  );

  const renderHandedOverTag = () => {
    return (
      <Tag color={getHandedOverTagColor(carrierTransferPallet)}>
        {getHandedOverTagText(carrierTransferPallet)}
      </Tag>
    );
  };

  const renderPageHeader = () => (
    <>
      <PageHeader
        title={"Transfer Pallet Detail"}
        onBack={() => window.history.back()}
      />
      <Space>
        <Typography.Title ellipsis>
          {carrierTransferPallet?.id || "CTP-123"}
        </Typography.Title>
        {renderHandedOverTag()}
      </Space>
    </>
  );

  const renderPrintLabelButton = () => {
    return (
      <Button
        size="large"
        title="Print Label"
        block
        type="primary"
        icon={<PrinterOutlined />}
        onClick={() => printLabel(palletId)}
        loading={isPrintingLabel}
        disabled={isPrintingLabel}
      >
        Label
      </Button>
    );
  };

  const renderToolbar = () => (
    <Row justify="space-between" gutter={[8, 16]} align="bottom">
      <Col xs={24} lg={12}>
        {renderTrackingNumSearchBar()}
      </Col>
      <Col xs={24} lg={10} xl={3}>
        {renderPrintLabelButton()}
      </Col>
    </Row>
  );

  return (
    <PageContainer withPadding>
      {renderPageHeader()}
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <ErrorAlert error={error} />
        <Typography.Title level={4}>
          Facility: {carrierTransferPallet?.facility || "-"}
        </Typography.Title>
        {renderToolbar()}
        <Table
          size="small"
          rowKey="id"
          footer={renderTableFooter}
          scroll={{ x: "max-content" }}
          bordered
          columns={tableColumns}
          loading={isLoadingPallet || isRemoving}
          dataSource={carrierTransferPallet?.carrier_transfer_packages}
        />
      </Space>
    </PageContainer>
  );
};

export default CarrierTransferPalletDetailPage;
