import useUrlState from "@ahooksjs/use-url-state";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Input, Row, Select, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";

// api
import { lookupLocationItems } from "../../../api/warehouse";

// Hooks and helpers
import useFacility from "../../../hooks/useFacility";
import { getJsonParseArray } from "./helper";

// components
import WithLabel from "../../../components/WithLabel";
import FacilityDropdown from "../../../components/FacilityDropdown";
import PageContainer from "../../../components/PageContainer";
import ItemLocationsTable from "./ItemLocationsTable";
import LocationTypeSelector from "./LocationTypeSelector";

import * as S from "./styles";

const ItemLookupPage: React.FC = () => {
  const cachedFacility = useFacility().facility;
  const [
    { facility, locationType, page, perPage, itemSearch, locationSearch },
    setUrlState,
  ] = useUrlState(
    {
      facility: cachedFacility,
      locationType: undefined,
      page: 1,
      perPage: 10,
      itemSearch: undefined,
      locationSearch: undefined,
    },
    { navigateMode: "replace" }
  );

  const [error, setError] = useState("");
  const skuSearchRef = useRef<Input>(null);
  const locationSearchRef = useRef<Input>(null);
  const { setFacility } = useFacility(setUrlState, setError);

  const {
    mutate: searchLocationItems,
    data: result,
    isLoading,
    reset,
  } = useMutation(lookupLocationItems, {
    onError: (e: string) => setError(e),
  });

  useEffect(() => {
    const identifier = getJsonParseArray(itemSearch);
    const locationCode = getJsonParseArray(locationSearch);
    const invalid = identifier.filter((v) => v.length < 3);
    if (invalid.length) {
      return setError(
        `Minimum 3 characters required to search items: "${invalid.join(", ")}"`
      );
    }
    setError("");
    const payload = {
      page: (page || 1).toString(),
      limit: (perPage || 10).toString(),
    };
    if (facility) {
      payload["facility"] = facility;
    }
    if (identifier.length > 0) payload["identifier"] = identifier?.join(",");
    if (locationType) payload["location_type"] = locationType;
    if (locationCode.length > 0)
      payload["location_code"] = locationCode?.join(",");
    searchLocationItems(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, itemSearch, facility, locationType, locationSearch]);

  const renderResetButton = () => {
    return (
      <Button
        size="large"
        icon={<UndoOutlined />}
        block
        onClick={() => {
          setUrlState({
            page: 1,
            itemSearch: undefined,
            locationSearch: undefined,
          });
          setError("");
          skuSearchRef?.current?.focus();
          reset();
        }}
      >
        Clear
      </Button>
    );
  };

  const renderLocationTypeDropdown = () => (
    <WithLabel name="Location Type">
      <LocationTypeSelector
        disabled={isLoading}
        value={locationType}
        onSelect={(locationType) => {
          if (locationType) {
            setUrlState({ locationType, page: 1 });
          } else {
            setUrlState({ locationType: undefined, page: 1 });
          }
          setError("");
        }}
      />
    </WithLabel>
  );

  const renderSkuSearchBar = () => {
    return (
      <WithLabel name="Product/Item">
        <Select
          ref={skuSearchRef}
          mode="tags"
          size="large"
          open={false}
          showSearch
          allowClear
          suffixIcon={<SearchOutlined />}
          value={getJsonParseArray(itemSearch)}
          placeholder="Search SKU, UPC, and MI-code"
          defaultValue={[]}
          notFoundContent={null}
          onChange={(value: string[]) => {
            const itemSearchList = value.map((v) => v?.trim());
            setError("");
            setUrlState({
              page: 1,
              itemSearch: JSON.stringify(itemSearchList),
            });
          }}
          maxTagCount="responsive"
        />
      </WithLabel>
    );
  };

  const renderLocationSearchBar = () => {
    return (
      <WithLabel name="Location">
        <Select
          ref={locationSearchRef}
          mode="tags"
          size="large"
          open={false}
          showSearch
          allowClear
          suffixIcon={<SearchOutlined />}
          value={getJsonParseArray(locationSearch)}
          placeholder="Search Location"
          defaultValue={[]}
          notFoundContent={null}
          onChange={(value: string[]) => {
            const locationSearchList = value.map((v) => v?.trim());
            setError("");
            setUrlState({
              page: 1,
              locationSearch: JSON.stringify(locationSearchList),
            });
          }}
          maxTagCount="responsive"
        />
      </WithLabel>
    );
  };

  const renderToolbar = () => {
    return (
      <Row align="bottom" justify="space-between" gutter={[8, 16]}>
        <Col xs={24} lg={6}>
          <Row align="bottom" gutter={[8, 16]}>
            <Col xs={12} lg={10}>
              <FacilityDropdown
                selectedFacility={facility}
                onSelect={setFacility}
              />
            </Col>
            <Col xs={12} lg={14}>
              {renderLocationTypeDropdown()}
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={18}>
          <Row align="bottom" justify="end" gutter={[8, 16]}>
            <Col xs={24} lg={10}>
              {renderSkuSearchBar()}
            </Col>
            <Col xs={24} lg={10}>
              {renderLocationSearchBar()}
            </Col>
            <Col xs={24} lg={3}>
              {renderResetButton()}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const buildAlertBox = () => {
    if (!error) return null;
    return (
      <S.AlertBoxWrap>
        <Alert message="Error" description={error} type="error" showIcon />
      </S.AlertBoxWrap>
    );
  };

  return (
    <PageContainer title="Item Lookup" withFooter withHeader withPadding>
      <Space style={{ width: "100%" }} direction="vertical" size="large">
        {renderToolbar()}
        {buildAlertBox()}
        <ItemLocationsTable
          locationItems={result?.items || []}
          paginationInfo={result?.meta}
          loading={isLoading}
          onPageChange={(p: number, newPageSize?: number) => {
            if (newPageSize && newPageSize !== +perPage) {
              setUrlState({ page: 1, perPage: newPageSize });
            } else {
              setUrlState({ page: p });
            }
          }}
        />
      </Space>
    </PageContainer>
  );
};

export default ItemLookupPage;
