import React, { FC, useState } from "react";
import { PageHeader, Space } from "antd";

import PageContainer from "../../../components/PageContainer";
import SupplyDetailsTable from "./SupplyDetailsTable";
import SupplyStockTable from "./SupplyStockTable";
import SupplyTransactionsTable from "./SupplyTransactionsTable";

const SupplyDetailsPage: FC = () => {
  const [stockUpdateCount, setStockUpdateCount] = useState(0);

  return (
    <PageContainer withPadding>
      <PageHeader title="Supply Detail" onBack={() => window.history.back()} />
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <SupplyDetailsTable />
        <SupplyStockTable
          onUpdateSuccess={() => setStockUpdateCount((c) => c + 1)}
        />
        <SupplyTransactionsTable stockUpdateCount={stockUpdateCount} />
      </Space>
    </PageContainer>
  );
};

export default SupplyDetailsPage;
