import styled from "styled-components";

export const ModalButtons = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
`;

export const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
`;
