import { getAPIFromDomain } from "../../lib/api";

interface GetManifestQueryBody {
  date?: string;
  carrier_code?: string;
}

interface ReturnedManifest {
  url: string;
}

export const getManifest = async (
  manifestQueryBody: GetManifestQueryBody
): Promise<ReturnedManifest> => {
  const api = getAPIFromDomain("logistics");
  const url = "/external_carrier_batches/manifest";
  const respond = await api.post(url, manifestQueryBody);
  return respond.data as ReturnedManifest;
};
