import React, { useState } from "react";

// API
import {
  createASN,
  updateASN,
  fetchASNDetails,
  fetchASNLabel,
} from "../../../api/fulfillment/asn";
import { fetchFacilities } from "../../../api/fulfillment/facility";
import { fetchOrganizationDetails } from "../../../api/fulfillment/organization";
import {
  fetchPackagingLevels,
  FetchPackagingLevelsQuery,
} from "../../../api/fulfillment/packagingLevels";

// Components
import {
  ASNCreate,
  ASNCreateContext,
  useASNCreateStore,
} from "@secondcloset/web-components";
import * as S from "./styles";

// Helpers
import { LINKS } from "../AsnIndexPage/helpers";
import { handleError } from "../../../lib/formatErrorHandler";

// Hooks
import { useHistory, useParams } from "react-router";
import { useMutation, QueryClient } from "react-query";

// Recoil
import { useRecoilValue, useResetRecoilState } from "recoil";
import { asnCreateFlowSelectedOrganization } from "../../../recoil/asnCreateFlow/atoms";

// Types
import { Common, Fulfillment } from "@secondcloset/types";

const AsnCreatePage: React.FC = () => {
  const history = useHistory();
  const { asnID } = useParams<{ asnID?: string }>();
  const [asnNumber, setAsnNumber] = useState("");
  const store = useASNCreateStore({ asnID });
  const queryClient = new QueryClient();
  const { mutateAsync: doCreateASN } = useMutation(createASN, {
    onError: (error) => handleError(error as string | Error),
  });
  const { mutateAsync: doUpdateASN } = useMutation(updateASN, {
    onError: (error) => handleError(error as string | Error),
  });
  const selectedOrganization = useRecoilValue(
    asnCreateFlowSelectedOrganization
  );
  const resetSelectedOrganization = useResetRecoilState(
    asnCreateFlowSelectedOrganization
  );

  const handleDownloadASNLabels = async () => {
    if (!asnID) return;
    return await queryClient
      .fetchQuery(["asnLabel", asnID], () =>
        fetchASNLabel(asnID, asnNumber || "ASN_Label")
      )
      .catch(handleError);
  };

  const handleFetchOrgDetails = async () => {
    if (!selectedOrganization?.id) return;
    return await queryClient
      .fetchQuery(["organizationDetails", selectedOrganization.id], () =>
        fetchOrganizationDetails(selectedOrganization.id)
      )
      .catch(handleError);
  };

  const handleFetchFacilityDetails = async (
    facilityCode?: Common.FacilityCode
  ) => {
    return await queryClient
      .fetchQuery(["facilityDetails"], () =>
        fetchFacilities({ name: facilityCode })
      )
      .then((f) => f?.find(({ name }) => name === facilityCode))
      .catch(handleError);
  };

  const handleFetchPackagingLevels = async (query: Record<string, unknown>) => {
    return await queryClient
      .fetchQuery(["packingLevels", query], () =>
        fetchPackagingLevels({
          ...query,
          organization_id: selectedOrganization?.id,
        } as unknown as FetchPackagingLevelsQuery)
      )
      .catch(handleError);
  };

  const handleFetchASNDetails = async (asnID: string) => {
    const query = { with_items: true };
    return await queryClient
      .fetchQuery(["packingLevels", asnID], () =>
        fetchASNDetails({ asnID, query })
      )
      .then((asn) => {
        setAsnNumber(asn.number);
        return asn;
      })
      .catch(handleError);
  };

  const handleSaveASN = async (body: unknown, asnID?: string) => {
    return await (asnID
      ? doUpdateASN({ body, asnID, options: { with_response: true } })
      : doCreateASN(body)
    ).then((asn: Fulfillment.ASN) => {
      if (!asn) return;
      setAsnNumber(asn.number);
      if (asn.status === "draft")
        history.replace(`${LINKS.asnCreate}/${asn.id}`);
      else history.push(`${LINKS.asnIndex}/${asn.id}`);
      return asn;
    });
  };

  const props = {
    showScanModeSwitch: true,
    organizationID: selectedOrganization?.id as string,
    onCloseClick: () => {
      resetSelectedOrganization();
      history.push(LINKS.asnIndex);
    },
    receivingGuideURL: LINKS.receivingGuide,
    createProductURL: `https://sierra.secondcloset.com/organizations/${selectedOrganization?.id}/products/create`,
    containerLearnMoreURL: LINKS.containerLearnMore,
    allowCreateProduct: false,
    onDownloadASNLabelsClick: handleDownloadASNLabels,
    fetchOrganizationDetails: handleFetchOrgDetails,
    fetchFacilityDetails: handleFetchFacilityDetails,
    fetchPackagingLevels: handleFetchPackagingLevels,
    fetchASNDetails: handleFetchASNDetails,
    onCreateASN: handleSaveASN,
    onUpdateASN: handleSaveASN,
  };

  return (
    <ASNCreateContext.Provider value={{ store, ...props }}>
      <S.Wrapper>
        <ASNCreate />
      </S.Wrapper>
    </ASNCreateContext.Provider>
  );
};

export default AsnCreatePage;
