import React, { useState } from "react";
import { useQuery } from "react-query";
import { Col, Input, Row, Space } from "antd";
import { ErrorAlert } from "@secondcloset/web-components";

import useUrlState from "@ahooksjs/use-url-state";
import { fetchOrganizations } from "../../../api/fulfillment/organization";

import PageContainer from "../../../components/PageContainer";
import WithLabel from "../../../components/WithLabel";
import OrganizationsTable from "./OrganizationsTable";

const OrganizationIndexPage = () => {
  const [error, setError] = useState<string | string[]>("");
  const [{ organizationSearch, page, perPage }, setUrlState] = useUrlState(
    {
      organizationSearch: undefined,
      page: 1,
      perPage: 10,
    },
    { navigateMode: "replace" }
  );

  const { data: organizations, isLoading } = useQuery(
    ["organizations", organizationSearch, page, perPage],
    () => {
      return fetchOrganizations(organizationSearch, {
        page,
        perPage,
      });
    },
    { onError: (e: string) => setError(e) }
  );

  const renderOrganizationSearchBar = () => {
    return (
      <WithLabel name="Organization">
        <Input.Search
          size="large"
          allowClear
          placeholder="Enter organization name"
          defaultValue={organizationSearch}
          autoFocus
          onSearch={(v) => {
            setUrlState({ organizationSearch: v, page: 1 });
            setError("");
          }}
          loading={isLoading}
          autoCapitalize="off"
        />
      </WithLabel>
    );
  };

  const renderToolbar = () => {
    return (
      <Row gutter={[8, 8]}>
        <Col xs={24} lg={8}>
          {renderOrganizationSearchBar()}
        </Col>
      </Row>
    );
  };

  return (
    <PageContainer title="Organizations" withPadding withHeader withFooter>
      <Space style={{ width: "100%" }} size="large" direction="vertical">
        <ErrorAlert error={error} />
        {renderToolbar()}
        <OrganizationsTable
          organizations={organizations || []}
          loading={isLoading}
          page={page}
          perPage={perPage}
          onPageChange={(p: number, newPageSize?: number) => {
            if (newPageSize && newPageSize !== +perPage) {
              setUrlState({ page: 1, perPage: newPageSize });
            } else {
              setUrlState({ page: p });
            }
          }}
        />
      </Space>
    </PageContainer>
  );
};

export default OrganizationIndexPage;
