import React from "react";
import { Space, Typography } from "antd";

import * as S from "./styles";
import { Link } from "react-router-dom";

interface Props {
  sku?: string;
  name?: string;
  customer?: string;
  upc?: string;
  code?: string;
  productId?: string;
}

const { Title } = Typography;

const ProductIdentifiersField: React.FC<Props> = ({
  sku,
  name,
  upc,
  code,
  customer,
  productId,
}) => {
  const renderLabelValue = (label: string, value?: string) => {
    if (!value) return null;
    return (
      <Space>
        <S.Label>{label}:</S.Label>
        <S.Value>{value}</S.Value>
      </Space>
    );
  };

  const renderProductName = () => {
    if (productId) {
      return (
        <S.ItemLabel>
          <Link to={`/products/${productId}`}>{name}</Link>
        </S.ItemLabel>
      );
    }
    return <Title level={5}>{name}</Title>;
  };

  const renderManualItemCode = () => {
    return (
      <S.ItemLabel>
        <Link to={`/manual-items/${code}`}>{code}</Link>
      </S.ItemLabel>
    );
  };

  return (
    <Space direction="vertical" size={0}>
      {name && renderProductName()}
      {code && renderManualItemCode()}
      {renderLabelValue("SKU", sku)}
      {renderLabelValue("UPC", upc)}
      {renderLabelValue("Customer", customer)}
    </Space>
  );
};
export default ProductIdentifiersField;
