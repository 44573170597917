import { PageNotFound } from "@secondcloset/web-components";
import LoginPage from "../pages/LoginPage";
import HomePage from "../pages/HomePage";

// Packing Flow
import ScanShipmentPage from "../pages/PackingFlow/ScanShipmentPage";
import PackShipmentPage from "../pages/PackingFlow/PackShipmentPage";
import PackingSuccessPage from "../pages/PackingFlow/PackingSuccessPage";

/*-- Shipment Tracking --*/
import ShipmentProductsTrackingPage from "../pages/ShipmentProductsTrackingPage";

/*-- Organization --*/
import OrganizationIndexPage from "../pages/organizations/OrganizationIndexPage";
import OrganizationDetailsPage from "../pages/organizations/OrganizationDetailsPage";

/*-- Locations --*/
import LocationIndexPage from "../pages/Locations/LocationIndexPage";
import LocationDetailsPage from "../pages/Locations/LocationDetailsPage";
import LocationTemplateIndexPage from "../pages/Locations/LocationTemplateIndexPage";
import CreateLocationTemplatePage from "../pages/Locations/CreateLocationTemplatePage";
import CreateLocationTemplateSuccessPage from "../pages/Locations/CreateLocationTemplateSuccessPage";
import CreateLocationPage from "../pages/Locations/CreateLocationPage";
import CreateLocationSuccessPage from "../pages/Locations/CreateLocationSuccessPage";

/*-- Lookup --*/
import ItemLookupPage from "../pages/ItemLookup/ItemLookupPage";

/*-- Activities --*/
import ActivitiesIndexPage from "../pages/Activities/ActivitiesIndexPage";

/*-- Manifest --*/
import ManifestGenerationPage from "../pages/ManifestGenerationPage";

/*-- Inventory Audit --*/
import InventoryAuditPage from "../pages/InventoryAuditPage";

/*-- Item Relocation --*/
import BatchMoveItemsPage from "../pages/BatchMoveItemsPage";
import BatchMoveSuccessPage from "../pages/BatchMoveItemsPage/BatchMoveSuccessPage";

/*-- Supply --*/
import SuppliesIndexPage from "../pages/Supplies/SuppliesIndexPage";
import SupplyDetailsPage from "../pages/Supplies/SupplyDetailsPage";
import CreateSupplyPage from "../pages/Supplies/CreateSupplyPage";

/*-- Manual Item --*/
import ManualItemIndexPage from "../pages/ManualItems/ManualItemIndexPage";
import ManualItemCreatePage from "../pages/ManualItems/ManualItemCreatePage";
import ManualItemDetailsPage from "../pages/ManualItems/ManualItemDetailsPage";
import ManualItemCreateSuccessPage from "../pages/ManualItems/ManualItemCreateSuccessPage";
import ManualItemCsvUploadPage from "../pages/ManualItems/ManualItemCsvUploadPage";
import ManualItemCsvUploadSuccessPage from "../pages/ManualItems/ManualItemCsvUploadSuccessPage";

// Pallets
import PalletsIndexPage from "../pages/Pallets/PalletsIndexPage";
import RequestPalletIDsPage from "../pages/RequestPalletIDsPage";
import PalletDetailsPage from "../pages/Pallets/PalletDetailsPage";
import GroupItemsToPalletPage from "../pages/Pallets/GroupItemsToPalletPage";

// ASN
import AsnIndexPage from "../pages/ASN/AsnIndexPage";
import AsnDetailsPage from "../pages/ASN/AsnDetailsPage";
import AsnSelectClientPage from "../pages/ASN/AsnSelectClientPage";
import AsnCreatePage from "../pages/ASN/AsnCreatePage";
import AsnProcessPage from "../pages/ASN/AsnProcessPage";

// Returns
import ReturnsIndexPage from "../pages/Returns/ReturnsIndexPage";
import ReturnDetailsPage from "../pages/Returns/ReturnDetailsPage";
import ProcessRegularReturn from "../pages/Returns/ProcessRegularReturn";
import ProcessRTS from "../pages/Returns/ProcessRTS";

// Products
import ProductsIndexPage from "../pages/Products/ProductsIndexPage";
import ProductDetailsPage from "../pages/Products/ProductDetailsPage";

// Carrier Transfer Packages
import CarrierTransferPalletPage from "../pages/CarrierTransferPallets/CarrierTransferPalletPage";
import CarrierTransferPackagesPage from "../pages/CarrierTransferPackagesPage";
import CarrierTransferPalletDetailPage from "../pages/CarrierTransferPallets/CarrierTransferPalletDetailPage";
import TransferCustodyScannerPage from "../pages/TransferCustodyPage/TransferCustodyScannerPage";
import TransferCustodyTablePage from "../pages/TransferCustodyPage/TransferCustodyTablePage";
import TransferCustodySuccessPage from "../pages/TransferCustodyPage/TransferCustodySuccessPage";

// Picksheets
import PrintPicksheetsPage from "../pages/PicksheetPage/PrintPicksheetPage";
import PicksheetRequestsPage from "../pages/PicksheetPage/PicksheetRequestsPage";

// Special Project
import SpecialProjectCreatePage from "../pages/SpecialProject/CreatePage";
import SpecialProjectDetailPage from "../pages/SpecialProject/DetailPage";
import SpecialProjectCreateSuccessPage from "../pages/SpecialProject/CreateSuccessPage";
import SpecialProjectIndexPage from "../pages/SpecialProject/IndexPage";

export interface RouteSetting {
  key: string;
  name: string;
  path: string;
  component: React.FC;
  isPrivate: boolean;
}

const routes: RouteSetting[] = [
  {
    key: "login",
    name: "Login",
    path: "/login",
    component: LoginPage,
    isPrivate: false,
  },
  {
    key: "home",
    name: "home",
    path: "/",
    component: HomePage,
    isPrivate: true,
  },
  {
    key: "scanShipmentPage",
    name: "scan shipment page",
    path: "/packing-scan-shipment/:shipment_id?",
    component: ScanShipmentPage,
    isPrivate: true,
  },
  {
    key: "packShipmentPage",
    name: "pack shipment page",
    path: "/pack-shipment",
    component: PackShipmentPage,
    isPrivate: true,
  },
  {
    key: "PackingSuccessPage",
    name: "packing success page",
    path: "/pack-shipment/success",
    component: PackingSuccessPage,
    isPrivate: true,
  },
  {
    key: "Location Index Page",
    name: "Location",
    path: "/locations",
    component: LocationIndexPage,
    isPrivate: true,
  },
  {
    key: "Create Location Page",
    name: "Create Location",
    path: "/locations/create",
    component: CreateLocationPage,
    isPrivate: true,
  },
  {
    key: "Create Location Success Page",
    name: "Create Location Success",
    path: "/locations/create/success/:locationCode",
    component: CreateLocationSuccessPage,
    isPrivate: true,
  },
  {
    key: "Location Details Page",
    name: "Location Details",
    path: "/locations/:code",
    component: LocationDetailsPage,
    isPrivate: true,
  },
  {
    key: "Location Templates Index Page",
    name: "Location Templates",
    path: "/location-templates",
    component: LocationTemplateIndexPage,
    isPrivate: true,
  },
  {
    key: "Create Location Template Page",
    name: "Create Location Template",
    path: "/location-templates/create",
    component: CreateLocationTemplatePage,
    isPrivate: true,
  },
  {
    key: "Create Location Template Success Page",
    name: "Create Location Template Success",
    path: "/location-templates/create/success/:templateName",
    component: CreateLocationTemplateSuccessPage,
    isPrivate: true,
  },
  {
    key: "Item Lookup Page",
    name: "Item Lookup",
    path: "/item-lookup",
    component: ItemLookupPage,
    isPrivate: true,
  },
  {
    key: "Batch Move Items Page",
    name: "Batch Move Items",
    path: "/batch-move-items",
    component: BatchMoveItemsPage,
    isPrivate: true,
  },
  {
    key: "Batch Move Items Success Page",
    name: "Batch Move Items Success",
    path: "/batch-move-items/success/:locationCode",
    component: BatchMoveSuccessPage,
    isPrivate: true,
  },
  {
    key: "Activities Index Page",
    name: "Activities",
    path: "/activities",
    component: ActivitiesIndexPage,
    isPrivate: true,
  },
  {
    key: "Manifest Generation Page",
    name: "Manifest Generation",
    path: "/manifest-generation",
    component: ManifestGenerationPage,
    isPrivate: true,
  },
  {
    key: "ASN Index Page",
    name: "ASN Index",
    path: "/asn",
    component: AsnIndexPage,
    isPrivate: true,
  },
  {
    key: "ASN Select Client Page",
    name: "ASN Select Client",
    path: "/asn/select-client",
    component: AsnSelectClientPage,
    isPrivate: true,
  },
  {
    key: "ASN Create Page",
    name: "ASN Create",
    path: "/asn/create/:asnID?",
    component: AsnCreatePage,
    isPrivate: true,
  },
  {
    key: "ASN Details Page",
    name: "ASN Details",
    path: "/asn/:asnID",
    component: AsnDetailsPage,
    isPrivate: true,
  },
  {
    key: "ASN Process Page",
    name: "ASN Process",
    path: "/asn/:asnID/process",
    component: AsnProcessPage,
    isPrivate: true,
  },
  {
    key: "Inventory Audit Page",
    name: "Inventory Audit",
    path: "/inventory-audit",
    component: InventoryAuditPage,
    isPrivate: true,
  },
  {
    key: "Supplies Page",
    name: "Supplies",
    path: "/supplies",
    component: SuppliesIndexPage,
    isPrivate: true,
  },
  {
    key: "Create Supply Page",
    name: "Create Supply",
    path: "/supplies/create",
    component: CreateSupplyPage,
    isPrivate: true,
  },
  {
    key: "Supply Details Page",
    name: "Supply Details",
    path: "/supplies/:supplyId",
    component: SupplyDetailsPage,
    isPrivate: true,
  },
  {
    key: "Shipment Products Tracking Page",
    name: "Shipment Products Tracking",
    path: "/shipment-products-tracking",
    component: ShipmentProductsTrackingPage,
    isPrivate: true,
  },
  {
    key: "Pallets Page",
    name: "Pallets",
    path: "/pallets",
    component: PalletsIndexPage,
    isPrivate: true,
  },
  {
    key: "Request Pallet IDs Page",
    name: "Request Pallet IDs",
    path: "/request-pallet-ids",
    component: RequestPalletIDsPage,
    isPrivate: true,
  },
  {
    key: "Pallet Details Page",
    name: "Pallet Details Page",
    path: "/pallets/:palletId",
    component: PalletDetailsPage,
    isPrivate: true,
  },
  {
    key: "Group Items to Pallet Page",
    name: "Group Items to Pallet",
    path: "/group-items-to-pallet",
    component: GroupItemsToPalletPage,
    isPrivate: true,
  },
  {
    key: "Manual Item Index Page",
    name: "Manual Item Index",
    path: "/manual-items",
    component: ManualItemIndexPage,
    isPrivate: true,
  },
  {
    key: "Manual Item Create Page",
    name: "Manual Item Create",
    path: "/manual-items/create",
    component: ManualItemCreatePage,
    isPrivate: true,
  },
  {
    key: "Manual Item CSV Upload Page",
    name: "Manual Item CSV Upload",
    path: "/manual-items/csv-upload",
    component: ManualItemCsvUploadPage,
    isPrivate: true,
  },
  {
    key: "Manual Item CSV Upload Success Page",
    name: "Manual Item CSV Upload Success",
    path: "/manual-items/csv-upload/success",
    component: ManualItemCsvUploadSuccessPage,
    isPrivate: true,
  },
  {
    key: "Manual Item Details Page",
    name: "Manual Item Details",
    path: "/manual-items/:manualItemCode",
    component: ManualItemDetailsPage,
    isPrivate: true,
  },
  {
    key: "Manual Item Create Success Page",
    name: "Manual Item Create Success",
    path: "/manual-items/create/success/:manualItemCode",
    component: ManualItemCreateSuccessPage,
    isPrivate: true,
  },
  {
    key: "Organization Index Page",
    name: "Organization Index",
    path: "/organizations",
    component: OrganizationIndexPage,
    isPrivate: true,
  },
  {
    key: "Organization Details Page",
    name: "Organization Details",
    path: "/organizations/:organizationID",
    component: OrganizationDetailsPage,
    isPrivate: true,
  },
  {
    key: "Returns Page",
    name: "Returns",
    path: "/returns",
    component: ReturnsIndexPage,
    isPrivate: true,
  },
  {
    key: "Return Details Page",
    name: "Return Details",
    path: "/returns/:returnId",
    component: ReturnDetailsPage,
    isPrivate: true,
  },
  {
    key: "Process Regular Return",
    name: "Process Regular Return",
    path: "/returns/process-regular/:returnId",
    component: ProcessRegularReturn,
    isPrivate: true,
  },
  {
    key: "Process RTS Return",
    name: "Process RTS Return",
    path: "/returns/process-rts/:returnId",
    component: ProcessRTS,
    isPrivate: true,
  },
  {
    key: "PageNotFound",
    name: "page not found",
    path: "*",
    component: PageNotFound,
    isPrivate: false,
  },
  {
    key: "Products Index Page",
    name: "Products",
    path: "/products",
    component: ProductsIndexPage,
    isPrivate: true,
  },
  {
    key: "Product Details Page",
    name: "Products",
    path: "/products/:productId",
    component: ProductDetailsPage,
    isPrivate: true,
  },
  {
    key: "Carrier Transfer Pallet Index Page",
    name: "Carrier Transfer Pallets",
    path: "/carrier-transfer-pallets",
    component: CarrierTransferPalletPage,
    isPrivate: true,
  },
  {
    key: "Carrier Transfer Package Index Page",
    name: "Carrier Transfer Packages",
    path: "/carrier-transfer-packages",
    component: CarrierTransferPackagesPage,
    isPrivate: true,
  },
  {
    key: "Carrier Transfer Pallet Detail",
    name: "Carrier Transfer Pallet Detail",
    path: "/carrier-transfer-pallets/:palletId",
    component: CarrierTransferPalletDetailPage,
    isPrivate: true,
  },
  {
    key: "Transfer Custody Scanner Page",
    name: "Transfer Custody",
    path: "/transfer-custody-scanner",
    component: TransferCustodyScannerPage,
    isPrivate: true,
  },
  {
    key: "Transfer Custody Items Table",
    name: "Transfer Custody Items Table",
    path: "/transfer-custody-table",
    component: TransferCustodyTablePage,
    isPrivate: true,
  },
  {
    key: "Transfer Custody Success Page",
    name: "Transfer Custody Success Page",
    path: "/transfer-custody-success",
    component: TransferCustodySuccessPage,
    isPrivate: true,
  },
  {
    key: "Print Pick Sheets",
    name: "Pick Sheets",
    path: "/pick-sheets",
    component: PrintPicksheetsPage,
    isPrivate: true,
  },
  {
    key: "Pick Sheet Requests Page",
    name: "Pick Sheet Requests Page",
    path: "/picksheet-requests/:orgId",
    component: PicksheetRequestsPage,
    isPrivate: true,
  },
  {
    key: "Special Project Create Page",
    name: "Special Project Create Page",
    path: "/special-projects/create",
    component: SpecialProjectCreatePage,
    isPrivate: true,
  },
  {
    key: "Special Project Create Success Page",
    name: "Special Project Create Success Page",
    path: "/special-projects/create/success",
    component: SpecialProjectCreateSuccessPage,
    isPrivate: true,
  },
  {
    key: "Special Project Index Page",
    name: "Special Project Index",
    path: "/special-projects",
    component: SpecialProjectIndexPage,
    isPrivate: true,
  },
  {
    key: "Special Project Detail Page",
    name: "Special Project Detail Page",
    path: "/special-projects/:projectId",
    component: SpecialProjectDetailPage,
    isPrivate: true,
  },
];

export default routes;
