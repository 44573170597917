import styled from "styled-components";
import { COLORS } from "../../../../../styles";

export const Container = styled.div`
  max-height: 400px;
  overflow: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.GREY_LIGHT};
  border-radius: 2px;
  &:not(:last-child) {
    margin: 0 0 16px;
  }
`;

export const Row = styled.div`
  padding: 8px;
  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.GREY_LIGHT};
  }
`;

interface TextProps {
  fontSize?: number;
  fontWeight?: number;
}

export const Text = styled.div<TextProps>`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "initial")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "initial")};
`;
