import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { Table, Input, TablePaginationConfig, Row, Col, Space } from "antd";
import { ErrorAlert } from "@secondcloset/web-components";
import { Warehouse } from "@secondcloset/types/";
import { FixedType } from "rc-table/lib/interface";

//API
import { fetchProducts, Product } from "../../../api/warehouse";

// Hooks
import useUrlState from "@ahooksjs/use-url-state";

// Components
import PageContainer from "../../../components/PageContainer";
import ItemCountFooter from "../../../components/Table/ItemCountFooter";
import NullSafeLink from "../../../components/Table/NullSafeLink";
import NullSafeText from "../../../components/Table/NullSafeText";
import WithLabel from "../../../components/WithLabel";
import { buildTablePagination } from "../../../components/Pagination/helper";

type Organization = Warehouse.Organization;

const ProductsIndexPage: React.FC = () => {
  const [error, setError] = useState("");
  const [{ page, perPage, search }, setUrlState] = useUrlState(
    {
      page: 1,
      perPage: 10,
      search: undefined,
    },
    { navigateMode: "replace" }
  );

  const paginatedProducts = useQuery(
    ["fetchProducts", search, page, perPage],
    () =>
      fetchProducts({
        search,
        page,
        limit: perPage,
      }),
    {
      onError: (error: string) => setError(error),
    }
  );

  const getProducts = useCallback((): Product[] => {
    return paginatedProducts.data?.items || [];
  }, [paginatedProducts]);

  const renderLink = (text, record) => {
    const productId = record.id;
    return <NullSafeLink href={`/products/${productId}`} value={text} />;
  };

  const renderText = (text: string) => {
    return <NullSafeText value={text} />;
  };

  const buildTableColumns = () => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        width: 100,
        render: (text, record) => renderLink(text, record),
        fixed: "left" as FixedType,
      },
      {
        title: "SKU",
        dataIndex: "sku",
        width: 100,
        render: renderText,
      },
      {
        title: "UPC",
        dataIndex: "upc",
        width: 100,
        render: renderText,
      },
      {
        title: "Organization",
        dataIndex: "organization",
        width: 100,
        render: (org: Organization) => renderText(org?.name),
      },
    ];
  };

  const buildPagination = (): TablePaginationConfig => {
    const paginationInfo = paginatedProducts.data?.meta;
    return buildTablePagination({
      paginationInfo,
      showQuickJumper: true,
      showSizeChanger: true,
      onChange: (p: number, newPageSize?: number) => {
        if (newPageSize && newPageSize !== +perPage) {
          setUrlState({ page: 1, perPage: newPageSize });
        } else {
          setUrlState({ page: p });
        }
      },
    });
  };

  const renderSearchBar = () => {
    return (
      <WithLabel name="Product">
        <Input.Search
          size="large"
          allowClear
          autoCapitalize="off"
          placeholder="Enter name, UPC, or SKU"
          onSearch={(id: string) => {
            setUrlState({ search: id?.trim(), page: 1 });
          }}
          onChange={(e) => {
            if (!e.target.value.trim()) {
              setUrlState({ search: "", page: 1 });
            }
          }}
          loading={paginatedProducts.isLoading}
        />
      </WithLabel>
    );
  };

  const renderToolbar = () => {
    return (
      <Row align="bottom" justify="space-between" gutter={[8, 16]}>
        <Col xs={24} lg={8}>
          {renderSearchBar()}
        </Col>
      </Row>
    );
  };

  const renderTableFooter = () => (
    <ItemCountFooter count={paginatedProducts.data?.meta.totalItems || 0} />
  );

  return (
    <PageContainer withHeader withFooter withPadding title="Products">
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <ErrorAlert error={error} />
        {renderToolbar()}
        <Table
          size="small"
          bordered
          rowKey="id"
          footer={renderTableFooter}
          scroll={{ x: "max-content" }}
          loading={paginatedProducts.isLoading}
          pagination={buildPagination()}
          columns={buildTableColumns()}
          dataSource={getProducts()}
        />
      </Space>
    </PageContainer>
  );
};

export default ProductsIndexPage;
