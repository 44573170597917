import styled, { css } from "styled-components";

import { Props } from "./PageContainer";

import { LAYOUT, COLORS, MIXINS } from "../../styles";

export const Container = styled.div<Partial<Props>>`
  margin: auto;
  position: relative;
  max-width: 1200px;
  margin-bottom: 70px;
  ${MIXINS.includeScrollBar()};

  ${(props) =>
    props.withPadding &&
    css`
      padding-left: ${LAYOUT.SITE_PADDING};
      padding-right: ${LAYOUT.SITE_PADDING};
    `}
`;

export const PageTitle = styled.div`
  font-size: 40px;
  padding: 20px 0;
  font-weight: 600;
  color: ${COLORS.CHARCOAL};
`;
