import { Warehouse } from "@secondcloset/types";
import { startCase } from "lodash-es";
type OrganizationConfigKey = Warehouse.OrganizationConfigKey;

interface ReturnValue {
  dataType: string;
  value: any;
}

export const getConfigValue = (
  key: OrganizationConfigKey,
  value: string
): ReturnValue => {
  switch (key) {
    case "ENABLE_SCAN_ITEMS":
      return {
        dataType: "boolean",
        value: value.toLowerCase() === "true",
      };
    default: {
      try {
        return {
          dataType: "object",
          value: JSON.parse(value),
        };
      } catch (_) {
        return {
          dataType: "unknown",
          value: null,
        };
      }
    }
  }
};

export const getReadableConfigKey = (key: OrganizationConfigKey) => {
  switch (key) {
    case "ENABLE_SCAN_ITEMS":
      return "Enable Scan Items";
    default:
      return startCase(key);
  }
};
