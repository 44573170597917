import React, { useState } from "react";
import { useMutation } from "react-query";
import { Button, Col, Popconfirm, Row, Space, Typography } from "antd";
import { UndoOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";

import WithLabel from "../../../components/WithLabel";
import ScannerInput from "../../../components/ScannerInput";
import SupplyQuantityTable from "./SupplyQuantityTable";
import { fetchSupplyByCode } from "../../../api/warehouse";

type Supply = Warehouse.Supply;

interface Props {
  setError: (e: string) => void;
  cart: SupplyCart;
  setCart: (cart: SupplyCart) => void;
  isEditing?: boolean;
}

export interface SupplyCart {
  [itemID: string]: {
    supplyId: string;
    code: string;
    item: Supply;
    quantity: number;
  };
}

const SupplyScanner: React.FC<Props> = ({
  setError,
  cart,
  setCart,
  isEditing,
}) => {
  const [idScanned, setIdScanned] = useState("");

  const { mutate: getSupplyByCode, isLoading: loadingSupplies } = useMutation(
    (code: string) => fetchSupplyByCode({ code, active: true }),
    {
      onSuccess: (supply: Supply) => {
        setIdScanned("");
        setCart({
          ...cart,
          [supply.id]: {
            supplyId: supply.id,
            code: supply.code,
            item: supply,
            quantity: 1,
          },
        });
        setError("");
      },
      onError: setError,
    }
  );

  const resetAllInputs = () => {
    setCart({});
    setIdScanned("");
  };

  const onEnterScannedIdInput = (code: string) => {
    if (code === "") return;

    const itemFound = Object.values(cart).find(
      (cartItem) => cartItem.code === code
    );
    if (!itemFound) return getSupplyByCode(code);

    setCart({
      ...cart,
      [itemFound.supplyId]: {
        ...cart[itemFound.supplyId],
        quantity: itemFound.quantity + 1,
      },
    });
    setError("");
    setIdScanned("");
  };

  const getTotalItemQuantity = (): number => {
    return Object.values(cart).reduce((acc, cv) => acc + cv.quantity, 0);
  };

  const onItemQuantityChange = (identifier: string, quantity: number) => {
    const currentCart = { ...cart };
    const currentItem = currentCart[identifier];
    currentItem.quantity = quantity;
    if (quantity === 0) delete currentCart[identifier];

    setCart(currentCart);
  };

  const renderTopToolbar = () => {
    if (!isEditing) return <Typography.Text>Supplies</Typography.Text>;
    return (
      <Row justify="space-between" align="bottom" gutter={[8, 8]}>
        <Col xs={24} lg={16}>
          {renderItemScannerInput()}
        </Col>
        <Col xs={24} lg={5}>
          {renderResetButton()}
        </Col>
      </Row>
    );
  };

  const renderItemScannerInput = () => {
    return (
      <WithLabel name="Scan Supply Code">
        <ScannerInput
          value={idScanned}
          onEnter={onEnterScannedIdInput}
          onChange={(id: string) => setIdScanned(id)}
        />
      </WithLabel>
    );
  };

  const renderResetButton = () => {
    return (
      <Popconfirm
        title="You will be clearing all supplies in the list. Are you sure?"
        onConfirm={() => resetAllInputs()}
      >
        <Button
          type="primary"
          size="large"
          icon={<UndoOutlined />}
          block
          danger
          ghost
          disabled={getTotalItemQuantity() === 0}
        >
          Clear
        </Button>
      </Popconfirm>
    );
  };

  return (
    <Space style={{ width: "100%" }} direction="vertical" size="middle">
      {renderTopToolbar()}
      <SupplyQuantityTable
        cart={cart}
        loading={loadingSupplies}
        total={getTotalItemQuantity()}
        onItemQuantityChange={onItemQuantityChange}
        preventEdit={!isEditing}
      />
    </Space>
  );
};

export default SupplyScanner;
