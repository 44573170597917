import { Space } from "antd";
import React from "react";
import NullSafeDate from "../NullSafeDate";

import * as S from "./styles";

interface Props {
  dates: { title: string; date: string }[];
}

const MultiDatesField: React.FC<Props> = ({ dates }) => {
  return (
    <Space direction="vertical" size={0}>
      {dates &&
        dates.length &&
        dates.map((d) => {
          return (
            <Space key={d.title + d.date}>
              <S.Label>{d.title}:</S.Label>
              <S.Value>
                <NullSafeDate date={d.date} />
              </S.Value>
            </Space>
          );
        })}
    </Space>
  );
};

export default MultiDatesField;
