import React from "react";
import { Descriptions } from "antd";
import { Warehouse } from "@secondcloset/types";

type LocationTemplate = Warehouse.LocationTemplate;

interface Props {
  locationTemplate?: LocationTemplate;
}

const SelectedLocationTemplateTable: React.FC<Props> = ({
  locationTemplate,
}) => {
  const { name, id, format } = locationTemplate || {};
  return (
    <Descriptions bordered layout="vertical" size="small">
      <Descriptions.Item label="Name">{name || "-"}</Descriptions.Item>
      <Descriptions.Item label="Template Format">
        {format || "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Database ID">{id || "-"}</Descriptions.Item>
    </Descriptions>
  );
};

export default SelectedLocationTemplateTable;
