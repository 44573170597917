import { SorterResult } from "antd/lib/table/interface";

const sortDirectionMap = {
  ascend: "ASC",
  descend: "DESC",
};

export const getSortOptions = (sort: SorterResult<any>) => {
  const sortOrder = sort.order ? sortDirectionMap[sort.order] : null;
  return { field: sort.field, order: sortOrder };
};
