import styled from "styled-components";
import { HEADER_TEXT_FONT_SIZE, FONT_SIZE } from "../../../styles/typography";
import colors from "../../../styles/colors";
import { Space } from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const FlexRowContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SuccessIcons = styled.div`
  display: block;
  padding-top: 20px;
`;

export const CarrierLogo = styled.img`
  max-width: 150px;
  max-height: 50px;
  margin: 30px 5px;
`;

export const GreenCircle = styled.div`
  background: ${colors.GREEN};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 50px;
  height: 50px;
  margin: 2em 0;
`;

export const ButtonContainer = styled.div`
  margin: 10px 5px 60px;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const InfoContainer = styled(Container)`
  flex: 1;
  justify-content: center;
  max-width: 500px;
`;

export const Info = styled.div`
  text-align: center;
  font-size: ${FONT_SIZE + 4};
`;

export const Link = styled.a`
  text-align: center;
  color: ${colors.BLUE};
  cursor: pointer;
  margin: 20px;
`;

export const SuccessMessage = styled(BoldText)`
  font-size: ${HEADER_TEXT_FONT_SIZE}px;
  margin-bottom: 35px;
  margin-top: 10px;
`;

export const FreightSummaryContainer = styled(Space)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 3em 0;
`;
