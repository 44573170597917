// Libs
import { Fulfillment } from "@secondcloset/types";
import queryString from "query-string";

// Interfaces
import { getAPIFromDomain } from "../../lib/api";

export interface FetchPackagingLevelsQuery {
  q: string;
  organization_id?: string;
}

export const fetchPackagingLevels = async (
  query: FetchPackagingLevelsQuery
): Promise<Fulfillment.PackagingLevel[]> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/product_packaging_levels?${queryString.stringify(query)}`;
  const res = await api.get(url);
  return res.data;
};
