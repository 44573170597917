import React, { FC, useEffect, useState } from "react";
import { Modal, Form, Select, Input, Spin } from "antd";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { ErrorAlert } from "@secondcloset/web-components";

import {
  packingFlowCurrentPackageState,
  packingFlowPackageContainerState,
  packingFlowPackageOrderItemIDsState,
  packingFlowPackageTypeState,
  packingFlowPackageWeightState,
  packingFlowShipmentState,
} from "../../../../../recoil/packingFlow/atoms";

// API
import { createPackage } from "../../../../../api/fulfillment";

// Libs
import PackageCreate from "../../../../../lib/logistics/packageCreate";

const { Option } = Select;
const MANUAL_CARRIERS = [{ value: "FRNTRR", name: "Frontier" }];

export interface Props {
  visible: boolean;
  setModalVisible: (v: boolean) => void;
}

const ManualCarrierModal: FC<Props> = ({ visible, setModalVisible }) => {
  const [error, setError] = useState("");
  const history = useHistory();
  const dispatchSetCurrentPackage = useSetRecoilState(
    packingFlowCurrentPackageState
  );
  const { mutateAsync: onCreatePackage, ...shipmentDetails } =
    useMutation(createPackage);
  const [shipment, dispatchSetShipment] = useRecoilState(
    packingFlowShipmentState
  );
  const [packageType] = useRecoilState(packingFlowPackageTypeState);
  const packageContainers = useRecoilValue(packingFlowPackageContainerState);
  const orderItemIDs = useRecoilValue(packingFlowPackageOrderItemIDsState);
  const packageWeights = useRecoilValue(packingFlowPackageWeightState);
  const [form] = Form.useForm();

  useEffect(() => {
    if (shipmentDetails.error) {
      const error = shipmentDetails.error as string;
      setError(error);
    } else {
      setError("");
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [shipmentDetails.error]);

  const handleCreatePackage = async () => {
    if (!shipment) return null;
    const carrierInfo = form.getFieldsValue();
    const body = PackageCreate.getCreatePackageManualCarrierBody({
      packageContainers,
      orderItemIDs,
      packageWeights,
      packageType,
      shipment,
      carrierInfo,
    });

    const newShipment = await onCreatePackage({
      shipmentID: shipment.id,
      body,
    });

    if (newShipment) {
      const oldPackagesIDs = shipment.packages.map((p) => p.id);
      const newPackage = newShipment.packages.find(
        (p) => !oldPackagesIDs.includes(p.id)
      );
      setModalVisible(false);
      dispatchSetCurrentPackage(newPackage);
      dispatchSetShipment(newShipment);
      history.push("/pack-shipment/success");
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const formItemLayout = {
    labelCol: {
      span: 9,
    },
  };

  const renderManualCarrierOptions = () => {
    return (
      <Select>
        {MANUAL_CARRIERS.map(({ name, value }) => (
          <Option key={name} value={value}>
            {name}
          </Option>
        ))}
      </Select>
    );
  };

  return (
    <Modal
      title="Carrier details"
      visible={visible}
      onOk={form.submit}
      onCancel={handleCancel}
      cancelButtonProps={{ disabled: shipmentDetails.isLoading }}
      okButtonProps={{ disabled: shipmentDetails.isLoading }}
    >
      <Spin spinning={shipmentDetails.isLoading}>
        <ErrorAlert error={error} />
        <Form
          {...formItemLayout}
          onFinish={handleCreatePackage}
          labelAlign="right"
          layout="horizontal"
          form={form}
          initialValues={{ carrier: MANUAL_CARRIERS[0].value }} // eslint-disable-next-line
          validateMessages={{ required: "${label} is required!" }}
        >
          <Form.Item
            name="carrier"
            label="Carrier"
            rules={[{ required: true }]}
          >
            {renderManualCarrierOptions()}
          </Form.Item>
          <Form.Item
            name="trackingNo"
            label="Tracking Number"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ManualCarrierModal;
