import React, { PropsWithChildren } from "react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { ErrorBoundaryProps } from "@sentry/react/dist/errorboundary";
import { ErrorBoundaryFallback } from "@secondcloset/web-components";

const ErrorBoundary: React.FC<PropsWithChildren<ErrorBoundaryProps>> = (
  props
) => (
  <SentryErrorBoundary
    fallback={(e) => <ErrorBoundaryFallback {...e} />}
    {...props}
  >
    {props.children}
  </SentryErrorBoundary>
);

export default ErrorBoundary;
