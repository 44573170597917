import { Common } from "@secondcloset/types";
type FacilityCode = Common.FacilityCode;

export const getDefaultCarrierListVersion = (options: {
  fulfilledFrom: FacilityCode;
  isVessi: boolean;
}) => {
  const { fulfilledFrom, isVessi } = options;
  if (isVessi) return 1;
  const v2Facilities = ["yyz4", "yyz3"];
  return v2Facilities.some((f) => fulfilledFrom === f) ? 2 : 1;
};
