import { Card as CardAntd, Space as SpaceAntd, Typography } from "antd";
import styled from "styled-components";
import { COLORS } from "../../../styles";

const { Title, Text } = Typography;

export const Card = styled(CardAntd)`
  width: 800px;
  max-width: calc(100vw - 20px);
  max-height: 70vh;
  height: 500px;
  border-color: ${COLORS.GREY_MID};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled(SpaceAntd)`
  width: 100%;
  margin-top: 20px;
`;

export const StatusContainer = styled.div`
  margin-bottom: 60px;
  margin-top: 20p;
`;

export const CardContentContainer = styled(SpaceAntd)`
  display: flex;
  align-self: center;
  flex-wrap: wrap;
`;

export const ErrorContainer = styled.div`
  width: 800px;
  max-width: calc(100vw - 20px);
`;

export const SuccessTitle = styled(Title)`
  line-break: anywhere;
  max-width: calc(100vw - 30px);
  text-align: center;
`;

export const Label = styled(Text)`
  font-size: 16px;
  color: ${COLORS.GREY};
`;
