export enum BlockType {
  mainFunctions = "Main Functions",
  inventoryManagement = "Inventory Management",
  shipping = "Shipping",
  administratorFunctions = "Admin Functions",
}

export interface Block {
  name: string;
  path: string;
  disabled: boolean;
  requiredAccess?: boolean;
  type: BlockType;
}

export const BLOCKS: Block[] = [
  {
    name: "PACK",
    path: "/packing-scan-shipment",
    disabled: false,
    type: BlockType.mainFunctions,
  },
  {
    name: "ITEM LOOKUP",
    path: "/item-lookup",
    disabled: false,
    requiredAccess: true,
    type: BlockType.inventoryManagement,
  },
  {
    name: "LOCATIONS",
    path: "/locations",
    disabled: false,
    requiredAccess: true,
    type: BlockType.administratorFunctions,
  },
  {
    name: "ACTIVITIES",
    path: "/activities",
    disabled: false,
    requiredAccess: true,
    type: BlockType.administratorFunctions,
  },
  {
    name: "MANIFEST GENERATION",
    path: "/manifest-generation",
    disabled: false,
    type: BlockType.administratorFunctions,
  },
  {
    name: "ASN",
    path: "/asn",
    disabled: false,
    type: BlockType.mainFunctions,
  },
  {
    name: "SUPPLIES",
    path: "/supplies",
    disabled: false,
    requiredAccess: true,
    type: BlockType.administratorFunctions,
  },
  {
    name: "BATCH MOVE ITEMS",
    path: "/batch-move-items",
    disabled: false,
    requiredAccess: true,
    type: BlockType.inventoryManagement,
  },
  {
    name: "INVENTORY AUDIT",
    path: "/inventory-audit",
    disabled: false,
    requiredAccess: true,
    type: BlockType.inventoryManagement,
  },
  {
    name: "SHIPMENT PRODUCTS TRACKING",
    path: "/shipment-products-tracking",
    disabled: false,
    type: BlockType.administratorFunctions,
  },
  {
    name: "PALLETS",
    path: "/pallets",
    disabled: false,
    type: BlockType.administratorFunctions,
  },
  {
    name: "GROUP ITEMS TO PALLET",
    path: "/group-items-to-pallet",
    disabled: false,
    type: BlockType.inventoryManagement,
  },
  {
    name: "MANUAL ITEMS",
    path: "/manual-items",
    disabled: false,
    type: BlockType.inventoryManagement,
  },
  {
    name: "ORGANIZATIONS",
    path: "/organizations",
    disabled: false,
    type: BlockType.administratorFunctions,
  },
  {
    name: "RETURNS",
    path: "/returns",
    disabled: false,
    type: BlockType.administratorFunctions,
  },
  {
    name: "PRODUCTS",
    path: "/products",
    disabled: false,
    type: BlockType.administratorFunctions,
  },
  {
    name: "CARRIER TRANSFER PALLETS",
    path: "/carrier-transfer-pallets",
    disabled: false,
    type: BlockType.shipping,
  },
  {
    name: "CARRIER TRANSFER PACKAGES",
    path: "/carrier-transfer-packages",
    disabled: false,
    type: BlockType.shipping,
  },
  {
    name: "TRANSFER CUSTODY",
    path: "/transfer-custody-scanner",
    disabled: false,
    requiredAccess: true,
    type: BlockType.shipping,
  },
  {
    name: "PRINT PICK SHEETS",
    disabled: false,
    path: "/pick-sheets",
    type: BlockType.mainFunctions,
  },
  {
    name: "SPECIAL PROJECTS",
    disabled: false,
    path: "/special-projects",
    type: BlockType.administratorFunctions,
  },
];
