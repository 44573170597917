import React from "react";

// Components
import * as S from "./styles";
import { Space, Button } from "antd";

// Hooks
import { useHistory } from "react-router";
import { useResetRecoilState } from "recoil";
import {
  asnCreateFlowSelectedOrganization,
  asnCreateFlowOrganizations,
} from "../../../../recoil/asnCreateFlow/atoms";

const AsnCreateHeader: React.FC = () => {
  const resetSelectedOrganization = useResetRecoilState(
    asnCreateFlowSelectedOrganization
  );
  const resetOrganizations = useResetRecoilState(asnCreateFlowOrganizations);
  const history = useHistory();

  return (
    <S.Wrapper justifyContent="space-between">
      <Space size="large">
        <S.Title>Advanced Shipping Notice (ASN) - Create</S.Title>
      </Space>
      <Space size="large">
        <Button
          danger
          type="primary"
          onClick={() => {
            history.goBack();
            resetSelectedOrganization();
            resetOrganizations();
          }}
        >
          Back
        </Button>
      </Space>
    </S.Wrapper>
  );
};

export default AsnCreateHeader;
