import { Warehouse } from "@secondcloset/types";
import { getAPIFromDomain } from "../../lib/api";

type ReturnItem = Warehouse.ReturnItem;

export type UpdateReturnItemCondition = {
  condition?: string;
  condition_notes?: string;
};

export type UpdateReturnItemBody = {
  id: string;
  condition?: string;
  condition_notes?: string;
};

export const updateReturnItem = async (
  body: UpdateReturnItemBody
): Promise<ReturnItem> => {
  const { id, ...rest } = body;
  const api = getAPIFromDomain("november");
  const url = `/return-items/${id}`;
  const response = await api.patch(url, rest);
  return response.data as ReturnItem;
};

export const batchUpdateReturnItem = async (
  body: UpdateReturnItemBody[]
): Promise<ReturnItem[]> => {
  const api = getAPIFromDomain("november");
  const url = `/return-items/batch-update`;
  const response = await api.patch(url, body);
  return response.data as ReturnItem[];
};
