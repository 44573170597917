import styled from "styled-components";
import { Space } from "antd";
import { COLORS } from "../../../../styles";

export const Container = styled(Space)`
  width: 100%;
  padding: 0 20px;
`;

export const HeaderTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
`;

export const YellowButton = styled.div<{ isDisabled?: boolean }>`
  color: ${({ isDisabled }) => (isDisabled ? COLORS.GREY : COLORS.BLACK)};
  background-color: ${({ isDisabled }) =>
    isDisabled ? COLORS.GREY_LIGHT : COLORS.YELLOW};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  width: 100px;
  padding: 5px 15px;
  margin: auto;
  text-align: center;
  border-radius: 3px;
`;
