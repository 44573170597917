import queryString from "query-string";
import { Common, Warehouse } from "@secondcloset/types";
import { getAPIFromDomain } from "../../lib/api";
import {
  PaginatedData,
  PaginationOptions,
} from "../warehouse/commonInterfaces";

type FacilityCode = Common.FacilityCode;
type SpecialProject = Warehouse.SpecialProject;
type SpecialProjectLog = Warehouse.SpecialProjectLog;
type SpecialProjectType = Warehouse.SpecialProjectType;

export const getSpecialProject = async (
  id: string
): Promise<SpecialProject> => {
  const api = getAPIFromDomain("november");
  const respond = await api.get(`/special-projects/${id}`);
  return respond.data as SpecialProject;
};

interface Query {
  q?: string;
  type?: SpecialProjectType;
  from_date?: string;
  to_date?: string;
  facility?: FacilityCode;
}

interface CreateBodyParams {
  supplies?: { supply_id: string; quantity: number }[];
}

export const createSpecialProject = async (
  body: Partial<SpecialProject> & CreateBodyParams
): Promise<SpecialProject> => {
  const api = getAPIFromDomain("november");
  const respond = await api.post("/special-projects", body);
  return respond.data as SpecialProject;
};

export const fetchSpecialProjects = async (
  query: PaginationOptions & Query
): Promise<PaginatedData<SpecialProject>> => {
  const api = getAPIFromDomain("november");
  const url = `/special-projects?${queryString.stringify(query || {})}`;
  const response = await api.get(url);
  return response.data as PaginatedData<SpecialProject>;
};

export const updateSpecialProject = async (
  payload: Partial<SpecialProject> & CreateBodyParams
): Promise<SpecialProject> => {
  const { id, ...body } = payload;
  const api = getAPIFromDomain("november");
  const url = `/special-projects/${id}`;
  const response = await api.patch(url, body);
  return response.data as SpecialProject;
};

export const fetchSpecialProjectLogsByProjectId = async (
  projectId: string
): Promise<SpecialProjectLog[]> => {
  const api = getAPIFromDomain("november");
  const url = `/special-project-logs/${projectId}`;
  const response = await api.get(url);
  return response.data as SpecialProjectLog[];
};
