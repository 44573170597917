import React from "react";

// components
import PageContainer from "../../../components/PageContainer";
import { Result, Button } from "antd";

// hooks
import { useParams, useHistory } from "react-router";

// styles
import * as S from "./styles";

const CreateLocationTemplateSuccessPage: React.FC = () => {
  const params = useParams<{ templateName: string }>();
  const history = useHistory();

  return (
    <PageContainer withHeader withFooter>
      <S.Container>
        <Result
          status="success"
          title="Successfully Created Location Template"
          subTitle={`${params.templateName} has been saved.`}
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => history.push("/location-templates")}
            >
              Back to Location Templates
            </Button>,
            <Button
              key="buy"
              onClick={() => history.push("/location-templates/create")}
            >
              Create Another Location Template
            </Button>,
          ]}
        />
      </S.Container>
    </PageContainer>
  );
};

export default CreateLocationTemplateSuccessPage;
