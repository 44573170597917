import Cookie from "../lib/cookie";

function useFacility(
  setUrlState?: ({ facility, page }) => void,
  setError?: (errorMessage: string) => void
) {
  const facility = Cookie.getAppSettings()?.facility || "All";

  const setFacility = (facility) => {
    Cookie.setAppSettings({ facility });
    setUrlState && setUrlState({ facility, page: 1 });
    setError && setError("");
  };

  return { facility, setFacility };
}

export default useFacility;
