import { getAPIFromDomain } from "../../lib/api";
import ShipmentLib from "../../lib/shipment";
import { serialize } from "object-to-formdata";

//interface
import { Fulfillment, Logistics } from "@secondcloset/types";
import {
  PackagedItemCreateBody,
  CarrierDataCreateBody,
} from "../../lib/logistics/packageCreate";

type Shipment = Fulfillment.Shipment;
type ShippingRate = Logistics.ShippingRate;
interface Dimensions {
  weight_value: number;
  weight_unit: string;
  length_value: number;
  length_unit: string;
  height_value: number;
  height_unit: string;
  width_value: number;
  width_unit: string;
}

export type ExternalCarriers =
  | "freightcom"
  | "frontier"
  | "shipengine"
  | "easypost"
  | "swyft"
  | "boxknight";

interface ShippingRatesBody {
  packages: Dimensions[];
  include_default_carriers: boolean;
  provider_scope: ExternalCarriers[];
  signature_rates?: boolean;
}
interface CreatePackageBody {
  carrier_data?: CarrierDataCreateBody;
  packages: PackagedItemCreateBody[];
  rate?: Partial<ShippingRate>;
}

interface UploadBOLBody {
  bol_file: File;
  label_id: string;
  tracking_number?: string;
}

interface UpdateShipmentStatusBody {
  shipment_items: { id: string; completed_actions: string[] }[];
}

export const fetchShipmentDetails = async (id: string): Promise<Shipment> => {
  // shipment id will either be uuid or in SHID format (SHID-XXXXXXXXXX)
  const isSHID = id.toUpperCase().startsWith("SHID-") || id.length === 10;
  const shid = isSHID
    ? (id.length === 10 ? `SHID-${id}` : id).toUpperCase()
    : id;

  //shipment lookup with shid is in logistics domain not fulfillment
  const api = getAPIFromDomain(isSHID ? "logistics" : "fulfillment");
  const url = isSHID
    ? `/shipments?shipment_numbers[]=${shid}`
    : `/shipments/${id}`;

  const response = await api.get(url);
  const shipment = isSHID ? response.data[0] : response.data;

  const shipmentID = shipment?.shipment_number || id;
  const throwError = (suffix: string) => {
    throw new Error(`This shipment (${shipmentID}) was ${suffix}`);
  };

  //check if shipment is empty, done or cancelled
  if (!shipment) return throwError("not found");
  if (ShipmentLib.isShipmentCancelled(shipment)) return throwError("cancelled");
  if (ShipmentLib.isShipmentDone(shipment)) return throwError("completed");

  return shipment;
};

export const fetchShippingRates = async ({
  shipmentID,
  body,
}: {
  shipmentID: string;
  body: ShippingRatesBody;
}): Promise<{
  rates: ShippingRate[];
  errors: string[];
}> => {
  const api = getAPIFromDomain("logistics");
  const url = `/shipments/${shipmentID}/rates`;
  const { data: shippingRates } = await api.post(url, body);
  return shippingRates;
};

export const createPackage = async ({
  shipmentID,
  body,
}: {
  shipmentID: string;
  body: CreatePackageBody;
}): Promise<Shipment | null> => {
  const api = getAPIFromDomain("logistics");
  const url = `/shipments/${shipmentID}/packages`;
  const { data: shipment } = await api.post(url, body);
  return shipment as Shipment;
};

export const uploadBOL = async ({
  shipmentID,
  body,
}: {
  shipmentID: string;
  body: UploadBOLBody;
}): Promise<Shipment> => {
  const api = getAPIFromDomain("logistics");
  const url = `/shipments/${shipmentID}`;
  const { data: shipment } = await api.put(url, serialize(body));
  return shipment;
};

export const updateShipmentStatus = async ({
  shipmentID,
  status,
  body,
}: {
  shipmentID: string;
  status: Shipment["shipping_method"]["status"];
  body: UpdateShipmentStatusBody;
}): Promise<Shipment> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/shipments/${shipmentID}/${status}`;
  const { data: shipment } = await api.put(url, body);
  return shipment;
};

export const updateShipment = async ({
  shipmentID,
  body,
}: {
  shipmentID: string;
  body: unknown;
}): Promise<Shipment> => {
  const api = getAPIFromDomain("logistics");
  const url = `/shipments/${shipmentID}`;
  const { data: shipment } = await api.put(url, body);
  return shipment;
};
