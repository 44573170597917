import { Warehouse } from "@secondcloset/types";
import queryString from "query-string";
import { getAPIFromDomain } from "../../lib/api";
// Interfaces
import { PaginatedData, PaginationOptions } from "./commonInterfaces";

type ShipmentProductsTracking = Warehouse.ShipmentProductsTracking;

interface ShipmentProductsTrackingQuery {
  lot_number?: string;
  serial_number?: string;
  organization_name?: string;
  external_shipment_id?: string;
  external_order_id?: string;
}

export const fetchShipmentProductsTracking = async (
  query: PaginationOptions & ShipmentProductsTrackingQuery
): Promise<PaginatedData<ShipmentProductsTracking>> => {
  const api = getAPIFromDomain("november");
  const url = `/shipment-products-trackings?${queryString.stringify(
    query || {}
  )}`;
  const response = await api.get(url);
  return response.data as PaginatedData<ShipmentProductsTracking>;
};
