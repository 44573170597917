import React from "react";
import { Col, Row, Table, Typography } from "antd";
import { Warehouse } from "@secondcloset/types";

import ProductIdentifiersField from "../../../../components/Table/ProductIdentifiersField";

import * as S from "./styles";

type Pagination = {
  current?: number;
  total?: number;
  pageSize?: number;
  onChange?: (page: number, pageSize?: number) => void;
};
interface Props {
  pagination?: Pagination;
  locationItems?: Warehouse.LocationItem[];
  currentLocation?: string;
}

const QuantityTable: React.FC<Props> = ({
  locationItems,
  pagination,
  currentLocation,
}) => {
  const renderItemDetail = (item) => {
    const sku = item?.product?.sku;
    const upc = item?.product?.upc;
    const name = item?.product?.name;
    const productId = item?.product?.id;
    const manualItemCode = item?.manual_item?.code;
    const customer = item?.manual_item?.customer_name;
    return (
      <ProductIdentifiersField
        sku={sku}
        upc={upc}
        name={name}
        code={manualItemCode}
        customer={customer}
        productId={productId}
      />
    );
  };

  const renderPalletID = (item) => {
    const palletID = item?.pallet_id;
    return <S.PalletID isRed={!!palletID}>{palletID || "-"}</S.PalletID>;
  };

  const tableColumns = [
    {
      key: "id",
      title: "Item",
      width: 300,
      render: renderItemDetail,
    },
    {
      key: "palletID",
      title: "Pallet ID",
      width: 120,
      render: renderPalletID,
    },
    {
      key: "quantity",
      title: "Quantity",
      width: 120,
      dataIndex: ["quantity"],
    },
  ];

  const renderTableHeader = () => {
    return (
      <Row justify="space-between" align="middle">
        <Col xs={24} lg={8}>
          <Typography.Text strong type="secondary">
            Scanned Location:
          </Typography.Text>
          <Typography.Text strong>
            {" "}
            {currentLocation || "Not Scanned"}
          </Typography.Text>
        </Col>
      </Row>
    );
  };

  return (
    <Table
      size="small"
      title={renderTableHeader}
      rowKey="id"
      bordered
      scroll={{ x: "max-content" }}
      columns={tableColumns}
      dataSource={locationItems}
      pagination={pagination}
    />
  );
};

export default QuantityTable;
