import React from "react";
import { message, Typography, Space } from "antd";

const updateServiceWorker = (serviceWorker: ServiceWorker | null): void => {
  if (!serviceWorker) return window.location.reload();
  serviceWorker.postMessage({ type: "SKIP_WAITING" });
  serviceWorker.addEventListener("statechange", (e) => {
    const serviceWorker = e.target as ServiceWorker;
    if (serviceWorker?.state === "activated") {
      window.location.reload();
    }
  });
};

export const checkForUpdate = async (checkMode = false): Promise<boolean> => {
  const noUpdateMsg = {
    duration: 3,
    content: (
      <Space>
        <Typography.Text>
          {"You've already got the latest version of Providence!"}
        </Typography.Text>
      </Space>
    ),
  };
  const registration = await navigator?.serviceWorker?.getRegistration();

  // no update pending; check swr for updates
  if (registration && !registration.waiting && !checkMode)
    await registration.update();

  // update is pending for installation
  if (registration?.waiting) {
    if (!checkMode) await updateServiceWorker(registration.waiting);
    return true;
  }

  if (!checkMode) message.info(noUpdateMsg);
  return false;
};

export const notifyNewVersion = (): void => {
  message.info({
    key: "new-version",
    duration: 5,
    content: (
      <Space>
        <Typography.Text>
          There is a new version of Providence! Please click the update button.
        </Typography.Text>
      </Space>
    ),
  });
};
