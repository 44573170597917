import styled from "styled-components";
import { COLORS } from "../../../../styles";

export const Container = styled.div`
  margin-top: 20px;
`;

export const LabelsSectionDescription = styled.div`
  color: ${COLORS.GREY};
`;
