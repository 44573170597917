import styled from "styled-components";

import { COLORS } from "../../../../styles";

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface Props {
  isRed: boolean;
}
export const PalletID = styled.div<Props>`
  color: ${(props) => (props.isRed ? COLORS.RED : "unset")};
`;
