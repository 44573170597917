//interface
import { Logistics } from "@secondcloset/types";
//components
import { notification, Spin } from "antd";
import React, { useEffect, useMemo } from "react";
import { useMutation } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
//api
import {
  ExternalCarriers,
  fetchShippingRates,
} from "../../../../../api/fulfillment";
import {
  packingFlowPackageContainerState,
  packingFlowPackageWeightState,
  packingFlowShipmentState,
  packingFlowShippingRateState,
} from "../../../../../recoil/packingFlow/atoms";
import {
  getIsEndy,
  getIsHamuq,
} from "../../../../../recoil/packingFlow/selectors";
//styles
import {
  CheckCircleFilled,
  Circle,
  Container,
  RateBody,
  RateCol,
  RateName,
  RateOption,
  RatesWrap,
  RateTextLarge,
  RateTextSmall,
} from "./styles";

type ShippingRate = Logistics.ShippingRate;

const FrieightCarrierList: React.FC = () => {
  const shipment = useRecoilValue(packingFlowShipmentState);
  const packageContainers = useRecoilValue(packingFlowPackageContainerState);
  const packageWeights = useRecoilValue(packingFlowPackageWeightState);
  const isEndy = useRecoilValue(getIsEndy);
  const isHamuq = useRecoilValue(getIsHamuq);
  const includeDefaultCarrier = !(isEndy || isHamuq);

  const [shippingRate, setShippingRate] = useRecoilState(
    packingFlowShippingRateState
  );
  const { mutate: onFetchShippingRates, ...shippingRates } =
    useMutation(fetchShippingRates);

  const error = shippingRates.error || shippingRates.data?.errors.join(", ");

  const getProviderScope = (): ExternalCarriers[] => ["frontier"];

  const formatedPackages = useMemo(
    () =>
      packageContainers.map((container, index) => ({
        weight_value: packageWeights[index].weight as number,
        weight_unit: "lb",
        length_value: container.length as number,
        length_unit: "in",
        height_value: container.height as number,
        height_unit: "in",
        width_value: container.width as number,
        width_unit: "in",
      })),
    [packageContainers, packageWeights]
  );

  useEffect(() => {
    if (shipment?.id) {
      onFetchShippingRates({
        shipmentID: shipment.id,
        body: {
          packages: formatedPackages,
          include_default_carriers: includeDefaultCarrier,
          provider_scope: getProviderScope(),
        },
      });
    }

    return () => {
      shippingRates.reset();
      setShippingRate(undefined);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) {
      notification.error({ message: "Error", description: error as string });
    }
  }, [error]);

  const renderManualFreightOption = () => {
    const isSelected = shippingRate === undefined;

    return (
      <RateOption
        key={"manual-frieghtcom"}
        onClick={() => setShippingRate(undefined)}
        selected={isSelected}
      >
        {isSelected ? <CheckCircleFilled /> : <Circle />}
        <RateBody>
          <RateCol>
            <RateName>{"MANUAL - Freightcom"}</RateName>
            <RateTextSmall>Manual freight service</RateTextSmall>
          </RateCol>
        </RateBody>
      </RateOption>
    );
  };

  const renderNoRate = () => {
    return (
      <RateOption key="no-rate" onClick={() => {}} selected={false}>
        <RateBody>
          <RateCol>
            <RateName disabled>No Rates Found</RateName>
          </RateCol>
        </RateBody>
      </RateOption>
    );
  };

  const renderRate = (rate: ShippingRate) => {
    const serviceType = rate.service_type?.toUpperCase();
    const price = +rate.price?.toFixed(2);
    const isSelected = shippingRate === rate;
    const deliveryDates = rate.delivery_days || "-";

    return (
      <RateOption
        key={rate.rate_id}
        onClick={() => setShippingRate(rate)}
        selected={isSelected}
      >
        {isSelected ? <CheckCircleFilled /> : <Circle />}
        <RateBody>
          <RateCol>
            <RateName>{serviceType}</RateName>
            <RateTextSmall>{`${deliveryDates} day${
              deliveryDates === 1 ? "" : "s"
            }`}</RateTextSmall>
          </RateCol>
          <RateCol>
            <RateTextLarge>${price ? price : "-"}</RateTextLarge>
          </RateCol>
        </RateBody>
      </RateOption>
    );
  };

  const renderRates = (rates: Logistics.ShippingRate[]) => {
    if (!rates?.length) return renderNoRate();
    return rates
      .filter((rate) =>
        getProviderScope().includes(rate.external_platform as ExternalCarriers)
      )
      .sort((a, b) => {
        if (a.carrier.carrier_code < b.carrier.carrier_code) return -1;
        if (a.carrier.carrier_code > b.carrier.carrier_code) return 1;
        return 0;
      })
      .map((rate) => renderRate(rate));
  };

  const renderShippingRates = () => {
    return (
      <RatesWrap>
        {renderManualFreightOption()}
        {renderRates(rates)}
      </RatesWrap>
    );
  };

  const rates = shippingRates.data?.rates || [];

  return (
    <Container>
      <Spin spinning={shippingRates.isLoading}>{renderShippingRates()}</Spin>
    </Container>
  );
};

export default FrieightCarrierList;
