import { Warehouse } from "@secondcloset/types";
import queryString from "query-string";
import { getAPIFromDomain } from "../../lib/api";
// Interfaces
import { PaginatedData, PaginationOptions } from "./commonInterfaces";

type Supply = Warehouse.Supply;

interface SupplyQuery {
  id?: string;
  search?: string;
  active?: boolean;
  type?: string;
  code?: string;
  name?: string;
  organization_name?: string;
  organization_ids?: string[];
  with_stock?: boolean;
}

interface CreateSupplyBody {
  name: string;
  type: string;
  weight?: number;
  height?: number;
  length?: number;
  width?: number;
  weight_unit?: string;
  dimension_unit?: string;
  organization_id: string;
  code?: string;
}

export const fetchSupplies = async (
  query: PaginationOptions & SupplyQuery
): Promise<PaginatedData<Supply>> => {
  const api = getAPIFromDomain("november");
  const url = `/supplies?${queryString.stringify(query || {})}`;
  const response = await api.get(url);
  return response.data as PaginatedData<Supply>;
};

export const fetchSupplyById = async (id: string): Promise<Supply> => {
  const api = getAPIFromDomain("november");
  const url = `/supplies/${id}`;
  const response = await api.get(url);
  return response.data as Supply;
};

export const fetchSupplyByCode = async (
  query: SupplyQuery
): Promise<Supply> => {
  const api = getAPIFromDomain("november");
  const url = `/supplies/single?${queryString.stringify(query || {})}`;
  const response = await api.get(url);
  return response.data as Supply;
};
export interface UpdateSupplyBody {
  id: string;
  name?: string;
  active?: boolean;
  height?: number | null;
  weight?: number | null;
  width?: number | null;
  length?: number | null;
  dimension_unit?: string | null;
  weight_unit?: string | null;
  code?: string | null;
  type?: string | null;
}

export const updateSupply = async (body: UpdateSupplyBody): Promise<Supply> => {
  const { id } = body;
  const api = getAPIFromDomain("november");
  const url = `/supplies/${id}`;
  const response = await api.patch(url, body);
  return response.data as Supply;
};

export const deleteSupply = async (id: string): Promise<void> => {
  const api = getAPIFromDomain("november");
  const url = `/supplies/${id}`;
  await api.delete(url);
};

export const createSupply = async (body: CreateSupplyBody): Promise<Supply> => {
  const api = getAPIFromDomain("november");
  const url = "/supplies";
  const response = await api.post(url, body);
  return response.data as Supply;
};
