import React, { FC } from "react";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

interface Props {
  doDeleteSupply: () => void;
  isSupplyDeleting: boolean;
}
const DeleteSupplyButton: FC<Props> = ({
  doDeleteSupply,
  isSupplyDeleting,
}) => {
  return (
    <Popconfirm
      disabled={isSupplyDeleting}
      placement="bottom"
      title="Are you sure you want to delete this supply?"
      onConfirm={doDeleteSupply}
      okText="Delete"
      cancelText="No"
    >
      <Button loading={isSupplyDeleting} danger icon={<DeleteOutlined />}>
        Delete
      </Button>
    </Popconfirm>
  );
};

export default DeleteSupplyButton;
