import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import {
  Col,
  Row,
  Space,
  DatePicker,
  Input,
  Button,
  Select,
  Tooltip,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { Warehouse } from "@secondcloset/types";
import { startCase } from "lodash-es";

// Hooks and helpers
import useUrlState from "@ahooksjs/use-url-state";
import { getUTCEndDate, getUTCStartDate } from "../../../lib/convertToUTC";
import {
  permissionsEditWarning,
  permissionsReadWarning,
  SpecialProjectTypes,
} from "../helpers";
import { useValidateSpecialProjectPermissions } from "../../../recoil/user/helpers";

// Components
import { ErrorAlert } from "@secondcloset/web-components";
import PageContainer from "../../../components/PageContainer";
import WithLabel from "../../../components/WithLabel";
import FacilitySelector from "../../../components/FacilitySelector";
import SpecialProjectsTable from "./SpecialProjectsTable";

// API
import { fetchSpecialProjects } from "../../../api/warehouse/specialProjects";

type SpecialProject = Warehouse.SpecialProject;
const { RangePicker } = DatePicker;

const SpecialProjectIndexPage: React.FC = () => {
  const [
    { page, perPage, q, facility, type, startDate, endDate },
    setUrlState,
  ] = useUrlState(
    {
      page: 1,
      perPage: 10,
      facility: "All",
      type: "All",
      q: undefined,
      startDate: undefined,
      endDate: undefined,
    },
    { navigateMode: "replace" }
  );
  const history = useHistory();
  const { canWriteSpecialProjects, canReadSpecialProjects } =
    useValidateSpecialProjectPermissions();
  const canRead = canReadSpecialProjects();
  const canWrite = canWriteSpecialProjects();
  const [error, setError] = useState("");

  useEffect(() => {
    setError(canRead ? "" : permissionsReadWarning);
  }, [canRead]);

  const paginatedItems = useQuery(
    [
      "fetchSpecialProjects",
      q,
      page,
      perPage,
      facility,
      type,
      startDate,
      endDate,
    ],
    () => {
      return fetchSpecialProjects({
        page,
        limit: perPage,
        q,
        type: type === "All" ? undefined : type,
        facility: facility === "All" ? undefined : facility,
        from_date: getUTCStartDate(startDate),
        to_date: getUTCEndDate(endDate),
      });
    },
    { enabled: canRead, onError: setError, onSuccess: () => setError("") }
  );

  const getSpecialProjects = useCallback((): SpecialProject[] => {
    return paginatedItems.data?.items || [];
  }, [paginatedItems]);

  const renderToolbar = () => {
    return (
      <Row justify="space-between" align="bottom" gutter={[8, 16]}>
        <Col xs={24} lg={14}>
          {renderSearchGroup()}
        </Col>
        <Col xs={24} lg={7}>
          {renderDatePicker()}
        </Col>
        <Col xs={24} lg={3}>
          {renderCreateButton()}
        </Col>
      </Row>
    );
  };

  const renderSearchGroup = () => {
    return (
      <Row justify="start" gutter={[8, 16]}>
        <Col xs={24} lg={6}>
          {renderFacilitySelector()}
        </Col>
        <Col xs={24} lg={6}>
          {renderTypeSelector()}
        </Col>
        <Col xs={24} lg={12}>
          {renderSearchBar()}
        </Col>
      </Row>
    );
  };

  const renderFacilitySelector = () => {
    return (
      <WithLabel name="Facility">
        <FacilitySelector
          value={facility}
          onSelect={(f) => setUrlState({ facility: f, page: 1 })}
        />
      </WithLabel>
    );
  };

  const renderTypeSelector = () => {
    return (
      <WithLabel name="Type">
        <Select
          size="large"
          value={type || ""}
          onSelect={(t) => setUrlState({ type: t, page: 1 })}
        >
          <Select.Option value={"All"} key={undefined}>
            All
          </Select.Option>
          {SpecialProjectTypes.map((s) => (
            <Select.Option value={s} key={s}>
              {startCase(s?.trim().toLowerCase())}
            </Select.Option>
          ))}
        </Select>
      </WithLabel>
    );
  };

  const renderSearchBar = () => {
    return (
      <WithLabel name="Search">
        <Input.Search
          size="large"
          placeholder="Search for project details, assignee or organization"
          allowClear
          loading={paginatedItems.isLoading}
          defaultValue={q}
          autoCapitalize="off"
          onSearch={(value: string) => {
            setUrlState({ q: value.trim(), page: 1 });
          }}
          onChange={(e) => {
            if (!e.target?.value.trim()) setUrlState({ q: "", page: 1 });
          }}
        />
      </WithLabel>
    );
  };

  const handleSelectDates = (_, dates: string[]) => {
    setUrlState({
      startDate: dates[0],
      endDate: dates[1],
    });
  };

  const renderDatePicker = () => {
    return (
      <WithLabel name="Date Range">
        <RangePicker
          size="large"
          onChange={handleSelectDates}
          value={
            startDate &&
            endDate && [
              moment(startDate, "YYYY-MM-DD"),
              moment(endDate, "YYYY-MM-DD"),
            ]
          }
        />
      </WithLabel>
    );
  };

  const renderCreateButton = () => {
    return (
      <Tooltip
        title={!canWrite ? permissionsEditWarning : null}
        trigger={["click", "hover"]}
      >
        <Button
          size="large"
          type="primary"
          block
          icon={<PlusOutlined />}
          onClick={() => history.push("/special-projects/create")}
          disabled={!canWrite}
        >
          Create
        </Button>
      </Tooltip>
    );
  };

  return (
    <PageContainer title="Special Projects" withHeader withFooter withPadding>
      <Space size="large" direction="vertical" style={{ width: "100%" }}>
        {renderToolbar()}
        <ErrorAlert error={error} />
        <SpecialProjectsTable
          projects={getSpecialProjects()}
          paginationInfo={paginatedItems?.data?.meta}
          isLoading={paginatedItems?.isLoading}
          onPageChange={(p: number, newPageSize?: number) => {
            if (newPageSize && newPageSize !== +perPage) {
              setUrlState({ page: 1, perPage: newPageSize });
            } else {
              setUrlState({ page: p });
            }
          }}
        />
      </Space>
    </PageContainer>
  );
};

export default SpecialProjectIndexPage;
