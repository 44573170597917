import React, { useRef, useEffect } from "react";
import { Spin } from "antd";
import * as S from "./styles";
import colors from "../../../../styles/colors";

// types
import { Fulfillment } from "@secondcloset/types";
import { QuantityMap } from "../AsnProcessPage";
type ASNItem = Fulfillment.ASNItem;

interface Props {
  products: ASNItem[];
  damaged: QuantityMap;
  received: QuantityMap;
  isLoading?: boolean;
  animate: boolean;
  setAnimate: (v: boolean) => void;
}

const ProductTable: React.FC<Props> = ({
  products,
  damaged,
  received,
  isLoading,
  animate,
  setAnimate,
}) => {
  const tableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    tableRef?.current?.scrollTo(0, 0);
  }, [animate]);

  const buildTableHeader = () => {
    return (
      <S.TableRow>
        <S.TableCell isHeader flex={2}>
          Product Name
        </S.TableCell>
        <S.TableCell isHeader flex={2}>
          Identifier
        </S.TableCell>
        <S.TableCell isHeader flex={0.5}>
          Lot #
        </S.TableCell>
        <S.TableCell isHeader isRightAligned flex={0.5}>
          Expected
        </S.TableCell>
        <S.TableCell isHeader isRightAligned flex={0.5}>
          Damaged
        </S.TableCell>
        <S.TableCell isHeader isRightAligned flex={0.5}>
          Received
        </S.TableCell>
      </S.TableRow>
    );
  };

  const renderRow = (product: ASNItem, index: number) => {
    const { id, product_name, product_sku, lot_number, expected_quantity } =
      product;

    const firstRowProps = {
      animate,
      onAnimationEnd: () => setAnimate(false),
    };
    const props = index === 0 ? firstRowProps : {};

    return (
      <S.TableRow key={id} {...props}>
        <S.TableCell flex={2}>{product_name || "-"}</S.TableCell>
        <S.TableCell flex={2} style={{ whiteSpace: "normal" }}>
          {product_sku || "-"}
        </S.TableCell>
        <S.TableCell flex={0.5}>{lot_number || "-"}</S.TableCell>
        <S.TableCell isStat isRightAligned flex={0.5}>
          {expected_quantity || "-"}
        </S.TableCell>
        <S.TableCell
          style={{ color: colors.RED }}
          isStat
          isRightAligned
          flex={0.5}
        >
          {damaged[product_sku] || "-"}
        </S.TableCell>
        <S.TableCell
          style={{ color: colors.GREEN }}
          isStat
          isRightAligned
          flex={0.5}
        >
          {received[product_sku] || "-"}
        </S.TableCell>
      </S.TableRow>
    );
  };

  return (
    <S.Container>
      <S.TableTitle>Product Summary</S.TableTitle>
      <Spin spinning={isLoading}>
        <S.TableContent ref={tableRef}>
          {buildTableHeader()}
          {products.map((p, idx) => renderRow(p, idx))}
        </S.TableContent>
      </Spin>
    </S.Container>
  );
};

export default ProductTable;
