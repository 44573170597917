import queryString from "query-string";
import { getAPIFromDomain } from "../../lib/api";
import { PaginatedData, PaginationOptions } from "./commonInterfaces";

export interface SupplyTransactionQuery {
  supply_id?: string;
  search?: string;
  sort_direction?: string;
  sort_field?: string;
}

export interface SupplyTransaction {
  id: string;
  supply_id: string;
  external_order_id?: string;
  created_at: string;
  user_email: string;
}

export const fetchSupplyTransactions = async (
  query: PaginationOptions & SupplyTransactionQuery
): Promise<PaginatedData<SupplyTransaction>> => {
  const api = getAPIFromDomain("november");
  const url = `/supply-transactions?${queryString.stringify(query || {})}`;
  const response = await api.get(url);
  return response.data as PaginatedData<SupplyTransaction>;
};
