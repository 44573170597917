import { Typography } from "antd";
import styled from "styled-components";
import { COLORS } from "../../../styles";

export const Subheader = styled(Typography.Text)`
  font-size: 22px;
  line-height: 10px;
  font-weight: 500;
`;

export const SubheaderBold = styled(Typography.Text)`
  font-size: 22px;
  line-height: 10px;
  font-weight: 600;
`;

export const Label = styled(Typography.Text)`
  font-size: 16px;
  font-weight: 500;
  color: ${COLORS.GREY};
`;

export const Sublabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.GREY};
  max-width: 200px;
`;

export const Subtext = styled.p`
  font-size: 14px;
  font-weight: bold;
  max-width: 200px;
`;

export const InlineSubtext = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 12px;
  max-width: 200px;
`;

export const LabelValue = styled(Typography.Text)`
  font-size: 22px;
  font-weight: 600;
`;

export const DetailsContainer = styled.div``;
