import {
  BaseProductItem,
  VirtualKitItem,
} from "@secondcloset/fulfillment-utils";
import { Warehouse } from "@secondcloset/types";
import { Space, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";

// Hooks
import {
  scannerModeEnabledState,
  SupplyListItem,
} from "../../../../../recoil/packingFlow/atoms";
import { useUpdatePackageItems } from "../../../../../recoil/packingFlow/helpers";
import { checkSupplyIsPackage } from "../AddPackageItem/helpers";

// Components
import AlertBanner from "../../../../../components/AlertBanner";

// Styles
import * as S from "./styles";

type Supply = Warehouse.Supply;
export interface TableBaseProductItem extends BaseProductItem {
  isPartOfKit?: boolean;
}

export type TableItem = TableBaseProductItem & SupplyListItem;

interface Props {
  dataSource: TableBaseProductItem[] | TableItem[] | VirtualKitItem[];
  onActionButtonClick: (item: TableBaseProductItem | VirtualKitItem) => void;
  onRemoveSupplyClick?: (item: SupplyListItem) => void;
  actionButtonText: "ADD" | "REMOVE";
  onErrorModalClose: () => any;
}

const ItemTable: React.FC<Props> = ({
  dataSource,
  onActionButtonClick,
  actionButtonText,
  onErrorModalClose,
  onRemoveSupplyClick,
}) => {
  const scannerModeEnabled = useRecoilValue(scannerModeEnabledState);
  const expandedRowKeys = useRef<string[]>();
  const [modalVisible, setModalVisible] = useState(false);
  const { calculateExpandedRowKeys } = useUpdatePackageItems();

  // MOVE TO RECOIL STATE

  useEffect(() => {
    const keys = calculateExpandedRowKeys();
    expandedRowKeys.current = keys;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const renderItemSkuUpc = (
    field: string,
    record: TableBaseProductItem | VirtualKitItem
  ) => {
    const isPartOfKit = record?.isPartOfKit;
    const value = record[field];
    return (
      <Space>
        {isPartOfKit && <S.EnterOutlined />}
        <Text>{value || "-"}</Text>
      </Space>
    );
  };

  const renderItemActionButton = (
    record: TableBaseProductItem | VirtualKitItem
  ) => {
    const renderColoredButton = () => {
      if (actionButtonText === "ADD") {
        return (
          <S.ItemRowAddButton
            type="ADD"
            onClick={() => onActionButtonClick(record)}
          />
        );
      }
      return (
        <S.ItemRowRemoveButton onClick={() => onActionButtonClick(record)} />
      );
    };

    return record.qty > 0 && renderColoredButton();
  };

  const renderSupplyActionButton = (supply: SupplyListItem) => {
    return (
      <S.ItemRowRemoveButton
        onClick={() => onRemoveSupplyClick && onRemoveSupplyClick(supply)}
      />
    );
  };

  const renderActionButton = (
    _: any,
    record: VirtualKitItem | TableBaseProductItem | SupplyListItem
  ) => {
    const isSupply = checkSupplyIsPackage(record as Supply);

    if (isSupply) {
      return renderSupplyActionButton(record as SupplyListItem);
    }
    return renderItemActionButton(
      record as TableBaseProductItem | VirtualKitItem
    );
  };

  const renderName = (record) => {
    const isSupply = checkSupplyIsPackage(record);
    const supplyName = record?.displayName || record?.name || "custom box";
    const itemName = record?.name;
    return <Text>{isSupply ? supplyName : itemName}</Text>;
  };

  const buildColumns = () => {
    const columns = [
      {
        title: "SKU",
        dataIndex: "sku",
        key: "sku",
        render: (_, record) => renderItemSkuUpc("sku", record),
      },
      {
        title: "UPC",
        dataIndex: "upc",
        key: "upc",
        render: (_, record) => renderItemSkuUpc("upc", record),
      },
      {
        title: "NAME",
        dataIndex: "name",
        key: "name",
        render: (_, record) => renderName(record),
      },
      {
        title: "TYPE",
        dataIndex: "packageType",
        key: "packageType",
        render(_, record) {
          return (
            <Text>
              {record?.packageType?.toUpperCase() ||
                record?.type?.toUpperCase() ||
                "ITEM"}
            </Text>
          );
        },
      },
      {
        title: "QTY",
        dataIndex: "qty",
        key: "qty",
        render(_, record) {
          return (
            <Text style={{ fontWeight: 800 }}>
              {record?.qty || record?.quantity}
            </Text>
          );
        },
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        render: renderActionButton,
      },
    ];

    if (actionButtonText === "ADD" && scannerModeEnabled) {
      columns.splice(columns.length - 1);
    }

    return columns;
  };

  const onModalClose = () => {
    onErrorModalClose();
    setModalVisible(false);
  };

  const renderWarningModal = () => {
    return (
      <Modal visible={modalVisible} onCancel={onModalClose} onOk={onModalClose}>
        <AlertBanner errorMsg="Item not found! Seems like you scanned the wrong item." />
      </Modal>
    );
  };

  const getRowKey = (r: any) => r?.id || r?.boxSupplyId || r?.packageType;
  return (
    <S.TableWrap>
      <Table
        dataSource={dataSource}
        columns={buildColumns()}
        pagination={false}
        rowKey={getRowKey}
        childrenColumnName="base_products"
        defaultExpandAllRows={true}
        expandedRowKeys={expandedRowKeys.current}
        expandIconColumnIndex={-1}
        indentSize={4}
        size="middle"
      />
      {renderWarningModal()}
    </S.TableWrap>
  );
};

export default ItemTable;
