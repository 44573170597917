import { Warehouse } from "@secondcloset/types";
import { Table, TablePaginationConfig } from "antd";
import React from "react";
import { FixedType } from "rc-table/lib/interface";

// Components
import { PaginationInfo } from "../../../../api/warehouse/commonInterfaces";
import { buildTablePagination } from "../../../../components/Pagination/helper";
import ItemCountFooter from "../../../../components/Table/ItemCountFooter";
import NullSafeLink from "../../../../components/Table/NullSafeLink";
import NullSafeText from "../../../../components/Table/NullSafeText";

type Location = Warehouse.Location;

interface Props {
  locations: Location[];
  paginationInfo?: PaginationInfo;
  loading: boolean;
  onPageChange: (page: number, newPageSize?: number) => any;
}

const LocationsTable: React.FC<Props> = ({
  locations,
  paginationInfo,
  loading,
  onPageChange,
}) => {
  const renderCode = (code: string) => {
    return <NullSafeLink href={`/locations/${code}`} value={code} />;
  };
  const renderText = (locationTemplateFormat: string) => {
    return <NullSafeText value={locationTemplateFormat} />;
  };

  const buildTableColumns = () => {
    return [
      {
        key: "code",
        title: "Code",
        width: 200,
        dataIndex: "code",
        render: renderCode,
        fixed: "left" as FixedType,
      },
      {
        key: "facility",
        title: "Facility",
        width: 120,
        dataIndex: "facility",
      },
      {
        key: "type",
        title: "Type",
        width: 200,
        dataIndex: "type",
      },
      {
        key: "location_template",
        title: "Template Format",
        width: 300,
        render: renderText,
        dataIndex: ["location_template", "format"],
      },
    ];
  };

  const buildPagination = (): TablePaginationConfig => {
    return buildTablePagination({
      paginationInfo,
      showQuickJumper: true,
      showSizeChanger: true,
      onChange: onPageChange,
    });
  };

  const renderTableFooter = () => (
    <ItemCountFooter count={paginationInfo?.totalItems || 0} />
  );

  return (
    <Table
      bordered
      size="small"
      scroll={{ x: "max-content" }}
      footer={renderTableFooter}
      dataSource={locations}
      columns={buildTableColumns()}
      rowKey={(r) => r.code}
      pagination={buildPagination()}
      loading={loading}
    />
  );
};

export default LocationsTable;
