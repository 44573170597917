import React, { FC, useCallback, useState } from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { UndoOutlined } from "@ant-design/icons";
import { Input, DatePicker, Space, Row, Col, Button, Tabs } from "antd";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import { SorterResult } from "antd/lib/table/interface";

// API
import {
  fetchPackerActivityLogs,
  fetchLocationActivityLogs,
} from "../../../api/warehouse";

// Hooks and helpers
import useUrlState from "@ahooksjs/use-url-state";
import { getSortOptions } from "./helpers";
import { getUTCEndDate, getUTCStartDate } from "../../../lib/convertToUTC";

// Components
import WithLabel from "../../../components/WithLabel";
import PageContainer from "../../../components/PageContainer";
import LocationActivityLogsTable from "./LocationActivityLogsTable";
import PackerActivityLogsTable from "./PackerActivityLogsTable";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
type LocationActivityLog = Warehouse.LocationActivityLog;
type PackerActivityLog = Warehouse.PackerActivityLog;

const ActivitiesIndexPage: FC = () => {
  const [error, setError] = useState("");

  const [
    { page, perPage, sortField, sortDirection, search, startDate, endDate },
    setUrlState,
  ] = useUrlState(
    {
      page: 1,
      perPage: 10,
      sortField: "created_at",
      sortDirection: "DESC",
      search: undefined,
      startDate: undefined,
      endDate: undefined,
    },
    { navigateMode: "replace" }
  );

  const [input, setInput] = useState("");
  const [placeholder, setPlaceholder] = useState(
    "Search for Location, SKU, UPC, Manual Item, or Email"
  );

  const resetFilters = () => {
    setInput("");
    setUrlState({
      search: undefined,
      startDate: undefined,
      endDate: undefined,
      page: 1,
    });
  };

  const paginatedLocationLogs = useQuery(
    [
      "fetchLocationActivityLogs",
      search,
      page,
      sortField,
      sortDirection,
      perPage,
      startDate,
      endDate,
    ],
    () =>
      fetchLocationActivityLogs({
        search,
        page,
        sort_field: sortField || undefined,
        sort_direction: sortDirection || undefined,
        limit: perPage,
        start_date: getUTCStartDate(startDate),
        end_date: getUTCEndDate(endDate),
      }),
    { onError: (error: string) => setError(error) }
  );

  const getLocationLogs = useCallback((): LocationActivityLog[] => {
    return paginatedLocationLogs.data?.items || [];
  }, [paginatedLocationLogs]);

  const paginatedPackerLogs = useQuery(
    [
      "fetchPackerLogs",
      search,
      page,
      sortField,
      sortDirection,
      perPage,
      startDate,
      endDate,
    ],
    () =>
      fetchPackerActivityLogs({
        search,
        page,
        sort_field: sortField || undefined,
        sort_direction: sortDirection || undefined,
        limit: perPage,
        start_date: getUTCStartDate(startDate),
        end_date: getUTCEndDate(endDate),
      }),
    { onError: (error: string) => setError(error) }
  );
  const getPackerLogs = useCallback((): PackerActivityLog[] => {
    return paginatedPackerLogs.data?.items || [];
  }, [paginatedPackerLogs]);

  const handleSelectDates = (_, dates: string[]) => {
    setUrlState({
      startDate: dates[0],
      endDate: dates[1],
    });
  };

  const renderClearButton = () => (
    <Button
      size="large"
      icon={<UndoOutlined />}
      block
      onClick={() => {
        resetFilters();
        setError("");
      }}
    >
      Clear
    </Button>
  );

  const renderDateRangeFilter = () => (
    <WithLabel name="Date Range">
      <RangePicker
        size="large"
        onChange={handleSelectDates}
        value={
          startDate &&
          endDate && [
            moment(startDate, "YYYY-MM-DD"),
            moment(endDate, "YYYY-MM-DD"),
          ]
        }
      />
    </WithLabel>
  );

  const handleSortChange = (sort: SorterResult<any>) => {
    const { field, order } = getSortOptions(sort);
    setUrlState({
      sortField: field,
      sortDirection: order,
      page: 1,
    });
  };

  const renderSearchInput = () => {
    return (
      <WithLabel name="Keyword">
        <Input.Search
          size="large"
          allowClear
          defaultValue={search}
          value={input}
          onChange={(e) => setInput(e?.target?.value)}
          placeholder={placeholder}
          onSearch={(search: string) => {
            setUrlState({ search: search.trim(), page: 1 });
          }}
          loading={
            paginatedLocationLogs.isLoading || paginatedPackerLogs.isLoading
          }
          autoCapitalize="off"
        />
      </WithLabel>
    );
  };

  const renderToolbar = () => {
    return (
      <Row justify="space-between" align="bottom" gutter={[8, 8]}>
        <Col xs={24} lg={15}>
          {renderSearchInput()}
        </Col>
        <Col xs={24} lg={6}>
          {renderDateRangeFilter()}
        </Col>
        <Col xs={24} lg={3}>
          {renderClearButton()}
        </Col>
      </Row>
    );
  };

  const renderLocationActivityLogsTable = () => {
    return (
      <LocationActivityLogsTable
        logs={getLocationLogs()}
        paginationInfo={paginatedLocationLogs.data?.meta}
        loading={paginatedLocationLogs.isLoading}
        onPageChange={(p: number, newPageSize) => {
          if (newPageSize && newPageSize !== +perPage) {
            setUrlState({ page: 1, perPage: newPageSize });
          } else {
            setUrlState({ page: p });
          }
        }}
        onSortChange={handleSortChange}
      />
    );
  };

  const renderPackerActivityLogsTable = () => {
    return (
      <PackerActivityLogsTable
        logs={getPackerLogs()}
        paginationInfo={paginatedPackerLogs.data?.meta}
        loading={paginatedPackerLogs.isLoading}
        onPageChange={(p: number, newPageSize) => {
          if (newPageSize && newPageSize !== +perPage) {
            setUrlState({ page: 1, perPage: newPageSize });
          } else {
            setUrlState({ page: p });
          }
        }}
        onSortChange={handleSortChange}
      />
    );
  };

  const switchPlaceholder = (key: string) => {
    let msg: string;
    switch (key) {
      case "1":
      default:
        msg = "Search for Location, SKU, UPC, Manual Item, or Email";
        break;
      case "2":
        msg = "Search for Shipment ID, External Order Number or User Email";
        break;
    }
    setPlaceholder(msg);
  };

  return (
    <PageContainer title="Activities" withFooter withHeader withPadding>
      <Space style={{ width: "100%" }} direction="vertical" size="large">
        <ErrorAlert error={error} />
        {renderToolbar()}
        <Tabs
          defaultActiveKey="1"
          onTabClick={switchPlaceholder}
          onChange={resetFilters}
        >
          <TabPane tab="Location Activity Logs" key="1">
            {renderLocationActivityLogsTable()}
          </TabPane>
          <TabPane tab="Packer Activity Logs" key="2">
            {renderPackerActivityLogsTable()}
          </TabPane>
        </Tabs>
      </Space>
    </PageContainer>
  );
};

export default ActivitiesIndexPage;
