import { Warehouse } from "@secondcloset/types";
import axios from "axios";
import queryString from "query-string";
import {
  downloadLabel,
  getAPIFromDomain,
  getHeaderTemplate,
} from "../../lib/api";
import Cookie from "../../lib/cookie";
import { PaginatedData, PaginationOptions } from "./commonInterfaces";

type ManualItem = Warehouse.ManualItem;

export interface StorageCustomer {
  first_name: string;
  last_name: string;
  user_code: string;
}
interface Query {
  q: string;
}

const basePath = "manual-items";

export const fetchManualItems = async (
  query: PaginationOptions & Partial<ManualItem> & Partial<Query>
): Promise<PaginatedData<ManualItem>> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}?${queryString.stringify(query || {})}`;
  const response = await api.get(url);
  return response.data as PaginatedData<ManualItem>;
};

export const createManualItem = async (
  body: Partial<ManualItem>
): Promise<ManualItem> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}`;
  const respond = await api.post(url, body);
  return respond.data as ManualItem;
};

export const updateManualItem = async (param: {
  id: string;
  body: Partial<ManualItem>;
}): Promise<ManualItem> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/${param.id}`;
  const respond = await api.put(url, param.body);
  return respond.data as ManualItem;
};

export const deleteManualItem = async (id: string): Promise<void> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/${id}`;
  await api.delete(url);
};

export const fetchManualItem = async (code: string): Promise<ManualItem> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/lookup-by-code/${code}`;
  const respond = await api.get(url);
  return respond.data as ManualItem;
};

export const downloadCsvTemplate = async (): Promise<void> => {
  const api = getAPIFromDomain("november");
  const res = await api.get(`/${basePath}/csv-template`);
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `manual-items-template.csv`);
  link.dispatchEvent(
    new MouseEvent(`click`, {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );
};

export const uploadManualItemsCsvFile = async (
  file: File
): Promise<ManualItem[]> => {
  const url = `${process.env.REACT_APP_WAREHOUSE_API}/manual-items/csv-upload`;
  const header = getHeaderTemplate(Cookie.getUserToken());
  header.headers["Content-Type"] = "multipart/form-data";
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);
  return axios.post(url, bodyFormData, header);
};

export const generateManualItemLabels = async (ids: string[]) => {
  const res = await downloadLabel(`/${basePath}/labels`, { ids }, "november");
  const url = window.URL.createObjectURL(
    new Blob([res.data], { type: "application/pdf" })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `manual-items-${Date.now()}.pdf`);
  link.dispatchEvent(
    new MouseEvent(`click`, {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );
};

export const storageCustomerLookup = async (name: string) => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/lookup-storage-customer/${name}`;
  const respond = await api.get(url);
  return respond.data as StorageCustomer[];
};
