import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ButtonRow = styled.div`
  display: flex;
  align-self: flex-end;
  width: 100%;
  justify-content: space-evenly;
  margin: 30px 0;
  padding: 0 0 80px;
`;

export const SpinWrapper = styled.div`
  display: flex;
  height: 300px;
  max-height: 100%;
  justify-content: center;
  align-items: center;
`;
