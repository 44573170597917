import { SearchOutlined } from "@ant-design/icons";
import { ErrorAlert } from "@secondcloset/web-components";
import { Button, Input, Space, Spin, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import PageContainer from "../../../components/PageContainer";
import ScannerInput from "../../../components/ScannerInput";
import {
  custodyTransferFlowPalletPackageState,
  custodyTransferFlowScannedInputState,
} from "../../../recoil/custodyTransfer/atoms";
import { COLORS } from "../../../styles";
import { fetchPalletIdOrPackageTrackingNumber } from "./helpers";
import * as S from "./styles";
import { Warehouse } from "@secondcloset/types";

type CarrierTransferPallet = Warehouse.CarrierTransferPallet;

const { Title, Text } = Typography;

const TransferCustodyScannerPage: React.FC = () => {
  const history = useHistory();
  const setToBeTransferredPackages = useSetRecoilState(
    custodyTransferFlowPalletPackageState
  );
  const setScannedValue = useSetRecoilState(
    custodyTransferFlowScannedInputState
  );
  const resetToBeTransferredPackages = useResetRecoilState(
    custodyTransferFlowPalletPackageState
  );
  const resetScannedValue = useResetRecoilState(
    custodyTransferFlowScannedInputState
  );
  const [palletIdOrPackageTrackingNumber, setPalletIdOrPackageTrackingNumber] =
    useState("");
  const [error, setError] = useState("");
  const scannerRef = useRef<Input>(null);

  useEffect(() => {
    resetToBeTransferredPackages();
    resetScannedValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLookupSuccess = (data: CarrierTransferPallet) => {
    const noPalletOrPackageFound =
      !data?.carrier_transfer_packages?.length && !data?.id;
    if (noPalletOrPackageFound) {
      return setError("No shipment pallet or package found.");
    } else if (data.carrier_transfer_packages) {
      for (const p of data.carrier_transfer_packages) {
        if (p.status === "with_carrier")
          return setError(
            `Package with tracking #${p.tracking_number} is already with carrier`
          );
      }
    }

    setToBeTransferredPackages(data);
    history.push("/transfer-custody-table");
  };

  const { mutate: onFetchPalletIdOrPackageTrackingNumber, isLoading } =
    useMutation(
      "fetchPalletIdOrPackageTrackingNumber",
      () =>
        fetchPalletIdOrPackageTrackingNumber({
          input: palletIdOrPackageTrackingNumber?.trim(),
        }),
      {
        onSuccess: (data: CarrierTransferPallet) => {
          handleLookupSuccess(data);
        },
        onError: setError,
      }
    );

  const onSearch = () => {
    const input = palletIdOrPackageTrackingNumber?.trim();
    if (!palletIdOrPackageTrackingNumber || isLoading || !input) {
      return;
    }
    setScannedValue(input);
    onFetchPalletIdOrPackageTrackingNumber();
  };

  const renderSearchButton = () => {
    return (
      <Button
        disabled={isLoading}
        size="large"
        type="primary"
        onClick={onSearch}
        icon={<SearchOutlined />}
      >
        {" "}
        Search
      </Button>
    );
  };

  const renderScannerInput = () => {
    return (
      <ScannerInput
        scannerRef={scannerRef}
        size="large"
        allowClear
        autoFocus
        onEnter={onSearch}
        onChange={setPalletIdOrPackageTrackingNumber}
        containerStyles={{
          width: 500,
          maxWidth: "calc(100vw - 40px)",
        }}
      />
    );
  };

  return (
    <PageContainer withPadding title="Transfer Custody">
      <S.ContentContainer align="center" direction="vertical">
        <S.ErrorContainer>
          <ErrorAlert error={error} />
        </S.ErrorContainer>
        <S.Card>
          <S.CardContentContainer align="center" direction="vertical">
            <Title level={3} style={{ textAlign: "center" }}>
              Scan a Pallet ID or Package Tracking #
            </Title>
            <S.StatusContainer>
              <Space size="large" direction="vertical" align="center">
                {isLoading ? (
                  <Spin
                    size="large"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  />
                ) : (
                  <Text style={{ fontSize: 20, color: COLORS.GREY }}>
                    SCANNER LISTENING...
                  </Text>
                )}
                {renderScannerInput()}
              </Space>
            </S.StatusContainer>
            {renderSearchButton()}
          </S.CardContentContainer>
        </S.Card>
      </S.ContentContainer>
    </PageContainer>
  );
};

export default TransferCustodyScannerPage;
