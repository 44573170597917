import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import { Button, notification, Row, Space, Col } from "antd";
import { CheckOutlined, UndoOutlined } from "@ant-design/icons";
import { ErrorAlert } from "@secondcloset/web-components";

import {
  auditItems,
  fetchLocationItems,
  auditItemsWithNoChange,
} from "../../api/warehouse/locationItem";
import { AuditItem } from "../../api/warehouse/commonInterfaces";

import LocationItemsTable from "./LocationItemsTable";
import PageContainer from "../../components/PageContainer";
import ScannerInput from "../../components/ScannerInput";
import WithLabel from "../../components/WithLabel";
import LastAuditDashboard from "./LastAuditDashboard";
import { fetchLocationActivityLogs } from "../../api/warehouse";
import moment from "moment";

export interface AuditCart {
  [locationItemID: string]: AuditItem;
}

const InventoryAuditPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const codeOnUrl = queryString.parse(location.search)?.location_code as string;
  const [cart, setCart] = useState<AuditCart>({});
  const [scannedLocation, setScannedLocation] = useState(codeOnUrl || "");
  const [error, setError] = useState("");
  const [fetchLogEnable, setFetchLogEnable] = useState(false);

  const {
    data: latestLogs,
    isLoading: loadingLatestLogs,
    refetch: refetchLatestLog,
    remove,
  } = useQuery(
    ["latestLocationLog", fetchLogEnable],
    () => {
      return fetchLocationActivityLogs({
        page: "1",
        limit: "1",
        to_location_code: scannedLocation,
        sort_field: "created_at",
        sort_direction: "DESC",
        actions: ["AUDIT", "AUDIT_CONFIRM"],
      });
    },
    {
      enabled: !!scannedLocation && fetchLogEnable,
      onError: (e) => setError(e as string),
    }
  );

  const {
    mutate,
    data: locationItems,
    isLoading,
    reset,
  } = useMutation(
    () => {
      return fetchLocationItems({
        location_code: scannedLocation,
        page: "1",
        limit: "1000",
      });
    },
    {
      onSuccess: (data) => {
        const items = data?.items || [];
        if (items.length === 0) {
          notification.warn({ message: "No items found in this location" });
        }
        setError("");
        setCart({});
        refetchLatestLog();
      },
      onError: (e: string) => setError(e),
    }
  );

  const { mutate: onAuditItems, isLoading: loadingUpdate } = useMutation(
    () => {
      const items = Object.values(cart);
      return auditItems(items);
    },
    {
      onSuccess: () => {
        notification.success({ message: "Update Success!" });
        setError("");
        mutate();
        refetchLatestLog();
      },
      onError: (e: string) => setError(e),
    }
  );

  const { mutate: onConfirmNoChange, isLoading: loadingConfirmUpdate } =
    useMutation(
      () => {
        return auditItemsWithNoChange(scannedLocation);
      },
      {
        onSuccess: () => {
          notification.success({ message: "Update Success!" });
          setError("");
          mutate();
        },
        onError: (e: string) => setError(e),
      }
    );

  useEffect(() => {
    if (scannedLocation) {
      mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickReset = () => {
    setCart({});
    setScannedLocation("");
    remove();
    reset();
  };

  const onEnterLocation = () => {
    mutate();
    setError("");
    setFetchLogEnable(true);
  };

  const renderScannerInput = () => {
    return (
      <WithLabel name="Scan Location">
        <ScannerInput
          size="large"
          allowClear
          value={scannedLocation}
          onChange={(v) => {
            setScannedLocation(v);
            setFetchLogEnable(false);
            remove();
            reset();
          }}
          onEnter={onEnterLocation}
        />
      </WithLabel>
    );
  };

  const renderResetButton = () => {
    return (
      <Button
        size="large"
        block
        ghost
        danger
        type="primary"
        onClick={onClickReset}
        disabled={!scannedLocation}
        icon={<UndoOutlined />}
      >
        Reset
      </Button>
    );
  };

  const getLocationItems = () => {
    return locationItems?.items?.filter((i) => i.quantity !== null) || [];
  };

  const renderTable = () => {
    return (
      <LocationItemsTable
        cart={cart}
        setCart={setCart}
        locationItems={getLocationItems()}
        loading={isLoading}
      />
    );
  };

  const checkIfChanges = () => {
    const locationItemsMap =
      locationItems?.items?.reduce((acc, cv) => {
        acc[cv.id] = cv.quantity;
        return acc;
      }, {} as any) || {};

    const hasChanges = Object.entries(cart).some(([id, value]) => {
      return locationItemsMap[id] !== value.quantity;
    });

    return hasChanges;
  };

  const renderUpdateButton = () => {
    const hasChanges = checkIfChanges();
    const buttonText = hasChanges ? "Update" : "Confirm no changes";
    const isDisabled = !scannedLocation;

    return (
      <Button
        size="large"
        type="primary"
        icon={<CheckOutlined />}
        block
        disabled={isDisabled}
        onClick={() => (hasChanges ? onAuditItems() : onConfirmNoChange())}
        loading={loadingUpdate || loadingConfirmUpdate}
      >
        {buttonText}
      </Button>
    );
  };

  const renderTopToolbar = () => {
    return (
      <Row justify="space-between" align="bottom" gutter={[8, 8]}>
        <Col xs={24} lg={16}>
          {renderScannerInput()}
        </Col>
        <Col xs={24} lg={3}>
          {renderResetButton()}
        </Col>
      </Row>
    );
  };

  const renderBottomToolbar = () => {
    return (
      <Row justify="space-around" align="middle">
        <Col xs={24} lg={6}>
          {renderUpdateButton()}
        </Col>
      </Row>
    );
  };

  const renderDashboard = () => {
    const matchingLog = latestLogs?.items?.[0];
    if (!matchingLog) return null;

    const lastUpdateAt = matchingLog?.created_at;
    const lastUpdateBy = matchingLog?.user_email;
    return (
      <LastAuditDashboard
        loading={loadingLatestLogs}
        lastUpdateAt={moment(lastUpdateAt).format("LLL")}
        lastUpdateBy={lastUpdateBy}
      />
    );
  };

  return (
    <PageContainer title="Inventory Audit" withPadding withHeader withFooter>
      <Space style={{ width: "100%" }} direction="vertical" size="large">
        <Button
          type="primary"
          onClick={() => history.push("/batch-move-items")}
        >
          Go to Batch Move
        </Button>
        <ErrorAlert error={error} />
        {renderTopToolbar()}
        {renderDashboard()}
        {renderTable()}
        {renderBottomToolbar()}
      </Space>
    </PageContainer>
  );
};

export default InventoryAuditPage;
