const locationTypeMap = {
  SHELF: "Shelf",
  CART: "Cart",
  RACKING: "Rack",
  PALLET_ZONE: "Pallet Zone",
  PACKING_STATION: "Pack Station",
};

export const getLocationTypeLabel = (key) => {
  return locationTypeMap[key];
};

export const getLocationTypeOptions = () => {
  return Object.keys(locationTypeMap).map((k) => {
    return { value: k, label: locationTypeMap[k] };
  });
};

export const getJsonParseArray = (urlString: string): string[] => {
  try {
    return JSON.parse(urlString);
  } catch (_) {
    return [];
  }
};
