import React, { useEffect, useState, useMemo } from "react";
import { ErrorAlert } from "@secondcloset/web-components";
import { SelectShipmentCarrierTypes } from "@secondcloset/web-components";
import { Button, notification, Spin } from "antd";
import { useHistory } from "react-router";
import { useMutation } from "react-query";

// API
import { updateShipment } from "../../../api/fulfillment";

// Components
import PageContainer from "../../../components/PageContainer";
import OrderInfoSection from "../../../components/OrderInfoSection";

// Libs
import PackageCreate from "../../../lib/logistics/packageCreate";
import SelectCarrierStep from "./SelectCarrierStep";
import SelectItemStep from "./SelectItemStep";
import SelectPackageTypeStep from "./SelectPackageTypeStep";
import SelectShipmentMethodStep from "./SelectShipmentMethodStep";

// Styles
import { ButtonRow, Container, SpinWrapper } from "./styles";

// Recoil
import {
  PackageType,
  packingFlowCurrentPackageState,
  packingFlowOrderState,
  packingFlowPackageTypeState,
  packingFlowShipmentState,
  packingFlowShippingMethodState,
  packingFlowShippingRateState,
  packingFlowStepState,
  Step,
  shipmentSuppliesState,
  packingFlowPackageContainerState,
  packingFlowPackageOrderItemIDsState,
  packingFlowPackageWeightState,
} from "../../../recoil/packingFlow/atoms";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import {
  usePresetPackageWeights,
  useReshapeShipmentProductTrackingList,
} from "../../../recoil/packingFlow/helpers";
import { getIsEndy } from "../../../recoil/packingFlow/selectors";

// Types
import {
  completePackingFlow,
  PackingSupply,
} from "../../../api/warehouse/integrations";

const { RecommendedShippingMethod } = SelectShipmentCarrierTypes;
const {
  selectShipmentMethod,
  selectPackageType,
  selectShipmentItem,
  selectCarrier,
} = Step;

const PackShipmentPage: React.FC = () => {
  const [error, setError] = useState("");
  const dispatchSetCurrentPackage = useSetRecoilState(
    packingFlowCurrentPackageState
  );
  const packingOrder = useRecoilValue(packingFlowOrderState);
  const [step, dispatchSetStep] = useRecoilState(packingFlowStepState);
  const [shipment, dispatchSetShipment] = useRecoilState(
    packingFlowShipmentState
  );
  const [shippingMethod, dispatchSetShippingMethod] = useRecoilState(
    packingFlowShippingMethodState
  );
  const [shippingRate, dispatchSetShippingRate] = useRecoilState(
    packingFlowShippingRateState
  );
  const [packageType, dispatchSetPackageType] = useRecoilState(
    packingFlowPackageTypeState
  );
  const resetShipmentSuppliesState = useResetRecoilState(shipmentSuppliesState);
  const packageContainers = useRecoilValue(packingFlowPackageContainerState);
  const orderItemIDs = useRecoilValue(packingFlowPackageOrderItemIDsState);
  const packageWeights = useRecoilValue(packingFlowPackageWeightState);
  const shipmentSupplies = useRecoilValue(shipmentSuppliesState);
  const history = useHistory();
  const [isGeneratingLabel, setIsGeneratingLabel] = useState(false);
  const presetPackageWeights = usePresetPackageWeights();
  const { getCreateShipmentProductTrackingBody } =
    useReshapeShipmentProductTrackingList();
  const isEndy = useRecoilValue(getIsEndy);

  const { mutate: onCreatePackage, ...shipmentDetails } = useMutation(
    () => {
      const createPackageBody = shipment
        ? PackageCreate.getCreatePackageBody({
            packageContainers,
            orderItemIDs,
            packageWeights,
            shippingRate,
            packageType,
            shipment,
            externalOrderNumber: isEndy
              ? packingOrder?.external_order_number
              : undefined,
          })
        : ({} as any);
      const shipmentID = shipment?.id || "";
      const productTrackingItems = getCreateShipmentProductTrackingBody();
      const supplyQuantities = shipmentSupplies.flat().reduce(
        (acc, supply) => ({
          ...acc,
          [supply.id]: (acc[supply.id] || 0) + supply.quantity,
        }),
        {}
      );
      const supplies = Object.keys(supplyQuantities).map((supplyId) => {
        return {
          supply_id: supplyId,
          quantity: supplyQuantities[supplyId],
          facility: packingOrder?.fulfilled_from || "unknown",
        };
      });
      const boxes = packageContainers.reduce((acc, container) => {
        if (container?.boxSupplyId)
          acc.push({
            supply_id: container.boxSupplyId,
            quantity: 1,
            facility: packingOrder?.fulfilled_from || "unknown",
          });
        return acc;
      }, [] as PackingSupply[]);
      setIsGeneratingLabel(true);
      return completePackingFlow(shipmentID || "", {
        external_body: createPackageBody,
        product_tracking_items: productTrackingItems,
        external_shipment_id: shipmentID || "",
        external_order_id: packingOrder?.id || "",
        external_order_number: packingOrder?.external_order_number || "",
        external_shipment_number: shipment?.shipment_number || "",
        organization_id: packingOrder?.organization?.id || "",
        supplies: [...supplies, ...boxes],
      });
    },
    {
      onError: (e: string) => setError(e),
      onSettled: () => setIsGeneratingLabel(false),
      onSuccess: ({ external_result: newShipment }) => {
        if (newShipment) {
          const oldPackagesIDs = shipment?.packages?.map((p) => p.id) || [];
          const newPackage = newShipment.packages.find(
            (p) => !oldPackagesIDs.includes(p.id)
          );
          resetShipmentSuppliesState();
          dispatchSetCurrentPackage(newPackage);
          dispatchSetShipment(newShipment);
          history.push("/pack-shipment/success");
        }
        setError("");
      },
    }
  );
  const { mutateAsync: onUpdateShipment } = useMutation(updateShipment);
  const isFreight = shippingMethod === RecommendedShippingMethod.freight;
  const allDimensionsFilled = useMemo(
    () =>
      packageContainers.find((container, index) => {
        const packageWeight = packageWeights[index].weight;
        return (
          !container.length ||
          !container.width ||
          !container.height ||
          !packageWeight
        );
      }) === undefined,
    [packageContainers, packageWeights]
  );
  useEffect(() => {
    if (shipmentDetails.error) {
      setError(`Error: ${shipmentDetails.error as string}`);
      dispatchSetShippingRate(undefined);
    } else {
      setError("");
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [shipmentDetails.error]);

  if (!packingOrder) history.push("/");

  const updateShipmentInfo = (body: unknown) => {
    return onUpdateShipment(
      { shipmentID: shipment?.id || "", body },
      {
        onSuccess: (updatedShipment) => {
          dispatchSetShipment(updatedShipment);
        },
        onError: (error) => {
          setError(`Error Updating Shipment: ${error as string}`);
        },
      }
    );
  };

  const handleNextButtonClick = () => {
    setError("");
    switch (step) {
      case selectShipmentMethod:
        const { freight, parcel } = RecommendedShippingMethod;
        const { box, standard_pallet } = PackageType;
        return updateShipmentInfo({ freight: isFreight }).then(() => {
          if (!isFreight) presetPackageWeights(box);
          dispatchSetShippingMethod(isFreight ? freight : parcel);
          dispatchSetPackageType(isFreight ? standard_pallet : box);
          dispatchSetStep(isFreight ? selectPackageType : selectShipmentItem);
        });
      case selectPackageType:
        if (packageType === PackageType.standard_pallet)
          presetPackageWeights(PackageType.standard_pallet);
        if (packageType === PackageType.double_pallet)
          presetPackageWeights(PackageType.double_pallet);
        return dispatchSetStep(selectShipmentItem);
      case selectShipmentItem:
        if (allDimensionsFilled) return dispatchSetStep(selectCarrier);
        else
          return notification.warning({
            message: "Please enter box dimensions",
          });
      case selectCarrier:
        return onCreatePackage();
      default:
        throw new Error("Unhandled step");
    }
  };

  const nextButtonText = useMemo(() => {
    if (step === selectShipmentItem) {
      if (isFreight) {
        return `Review ${
          packageType === PackageType.box ? "Packages" : "Pallets"
        }`;
      }
      return "Select Carrier";
    }
    if (step === selectCarrier) {
      if (isFreight) return "Finish Packing";
      return "Generate Label";
    } else {
      return "Next";
    }
  }, [step, isFreight]);

  const isNextButtonDisabled = useMemo(() => {
    // A shipping method must be selected
    if (step === selectShipmentMethod) return !shippingMethod;
    if (step === selectShipmentItem) {
      // All items must be packed, all packages/pallets must have dimensions
      if (!allDimensionsFilled) return true;

      const emptyPackagesAdded = orderItemIDs.find(
        (pkg) => !pkg?.orderItemIDs?.length
      );
      if (emptyPackagesAdded) return true;

      const shippedShipmentItemIds =
        shipment?.packages.flatMap((pkg) => pkg.shipment_item_ids) || [];
      return (
        orderItemIDs.flatMap((pkg) => pkg.orderItemIDs).length +
          shippedShipmentItemIds.length !==
        shipment?.shipment_items.length
      );
    }
    if (step === selectCarrier) {
      // If freight, confirm dimensions and skip shippingRate check
      if (isFreight) return !allDimensionsFilled;
      // If parcel, ensure shippingRate is selected
      return !shippingRate;
    } else return false;
  }, [
    orderItemIDs,
    step,
    shipment,
    allDimensionsFilled,
    isFreight,
    shippingRate,
    shippingMethod,
  ]);

  // diff data state results in diff first steps
  const getDefaultFirstPackingStep = () => {
    const isShipmentUntracked =
      shipment?.shipping_method_type === "untracked_shipment";
    if (isShipmentUntracked || step === selectShipmentMethod)
      return selectShipmentMethod;
    else {
      if (isFreight) return selectPackageType;
      else return selectShipmentItem;
    }
  };

  const backButtonText = useMemo(() => {
    const defaultFirstStep = getDefaultFirstPackingStep();
    if (step === defaultFirstStep) return "Back to Scanner";
    else return "Back";
  }, [step]);

  const goBackToScanPage = () => history.push("/packing-scan-shipment");

  const handleBackButtonClick = () => {
    const defaultFirstStep = getDefaultFirstPackingStep();
    if (step === defaultFirstStep) goBackToScanPage();
    switch (step) {
      case selectShipmentMethod:
        return goBackToScanPage();
      case selectPackageType:
        return dispatchSetStep(selectShipmentMethod);
      case selectShipmentItem:
        if (isFreight) return dispatchSetStep(selectPackageType);
        else return dispatchSetStep(selectShipmentMethod);
      case selectCarrier:
        return dispatchSetStep(selectShipmentItem);
      default:
        throw new Error("Unhandled step");
    }
  };

  const renderStep = (step: Step) => {
    switch (step) {
      case selectShipmentItem:
        return <SelectItemStep />;
      case selectCarrier:
        return <SelectCarrierStep />;
      case selectPackageType:
        return <SelectPackageTypeStep />;
      case selectShipmentMethod:
        return <SelectShipmentMethodStep />;
      default:
        return goBackToScanPage();
    }
  };

  const buildOrderInfoSection = () => {
    const shouldHideOrderInfo =
      step === selectPackageType || step === selectShipmentMethod;
    if (shouldHideOrderInfo) return;
    return <OrderInfoSection />;
  };

  return (
    <PageContainer
      withHeader
      withPadding
      loading={shipmentDetails.isLoading && !isGeneratingLabel}
    >
      <Container>
        <ErrorAlert error={error} />
        {buildOrderInfoSection()}
        {isGeneratingLabel ? (
          <SpinWrapper>
            <Spin
              tip={`Generating ${isFreight ? "package" : "shipping label"}...`}
            />
          </SpinWrapper>
        ) : (
          renderStep(step)
        )}
        <ButtonRow>
          <Button
            onClick={handleBackButtonClick}
            style={{ width: 200 }}
            size="large"
          >
            {backButtonText}
          </Button>

          <Button
            type="primary"
            onClick={handleNextButtonClick}
            style={{ width: 200 }}
            loading={shipmentDetails.isLoading}
            disabled={isNextButtonDisabled}
            size="large"
          >
            {nextButtonText}
          </Button>
        </ButtonRow>
      </Container>
    </PageContainer>
  );
};

export default PackShipmentPage;
