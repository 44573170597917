import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { Common, Warehouse, Fulfillment } from "@secondcloset/types";
import { Facility } from "@secondcloset/fulfillment-utils";
import { ErrorAlert } from "@secondcloset/web-components";
import {
  Button,
  Card,
  Col,
  Input,
  PageHeader,
  Row,
  Space,
  Form,
  Typography,
  Select,
  DatePicker,
  Tooltip,
  Spin,
} from "antd";
import { CloseOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { debounce, startCase, isEmpty } from "lodash-es";

import WithLabel from "../../../components/WithLabel";
import PageContainer from "../../../components/PageContainer";
import NullSafeDate from "../../../components/Table/NullSafeDate";
import SupplyScanner from "../SupplyScanner";

// API
import { fetchOrganizations } from "../../../api/fulfillment/organization";
import {
  fetchSpecialProjectLogsByProjectId,
  getSpecialProject,
  updateSpecialProject,
} from "../../../api/warehouse";
import { fetchASNIndex } from "../../../api/fulfillment";

// Hooks and helpers
import {
  permissionsEditWarning,
  permissionsReadWarning,
  SpecialProjectTypes,
  validateDates,
} from "../helpers";
import { useValidateSpecialProjectPermissions } from "../../../recoil/user/helpers";

// Components
import { SupplyCart } from "../SupplyScanner/SupplyScanner";

type SpecialProject = Warehouse.SpecialProject;
type SpecialProjectSupply = Warehouse.SpecialProjectSupply;
type SpecialProjectLog = Warehouse.SpecialProjectLog;
type Organization = Common.Organization;
type ASN = Fulfillment.ASN;

interface EditableField {
  value?: any;
  isEditing?: boolean;
  disabled?: boolean;
}

const ASNSelector: React.FC<
  EditableField & {
    onError: (e: any) => void;
  }
> = ({ value, isEditing, onError }) => {
  const [results, setResults] = useState<ASN[]>([]);
  const { mutate: searchASNs, isLoading: isSearchingASN } = useMutation(
    fetchASNIndex,
    {
      onError: onError,
      onSuccess: (data) => {
        setResults(
          data.map((asn) => ({ id: asn.id, number: asn.number })) as ASN[]
        );
      },
    }
  );

  const handleASNSearch = (id: string) => searchASNs({ q: id?.trim() });
  const debounceHandleASNSearch = debounce(handleASNSearch, 500);

  return (
    <WithLabel name="Reference # (ASN)">
      {isEditing ? (
        <Form.Item name="referenceNumber">
          <Select
            size="large"
            onSearch={debounceHandleASNSearch}
            showSearch
            allowClear
            placeholder="Search ASN"
            filterOption={false}
            suffixIcon={isSearchingASN && <Spin size="small" />}
          >
            {results.map((asn) => (
              <Select.Option key={asn.id} value={asn.number}>
                {asn.number}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        <Typography>{value || "N/A"}</Typography>
      )}
    </WithLabel>
  );
};

const StaffInput: React.FC<EditableField> = ({ value, isEditing }) => (
  <WithLabel name="Assignee(s)">
    {isEditing ? (
      <Form.Item
        name="assignee"
        rules={[{ required: true, message: "Assignee(s) cannot be empty" }]}
      >
        <Select
          mode="tags"
          tokenSeparators={[","]}
          open={false}
          value={value?.split(",")}
        />
      </Form.Item>
    ) : (
      <Typography>{value || "N/A"}</Typography>
    )}
  </WithLabel>
);

const DetailInput: React.FC<EditableField> = ({ value, isEditing }) => (
  <WithLabel name="Details">
    {isEditing ? (
      <Form.Item name="details">
        <Input.TextArea size="large" allowClear autoSize={{ minRows: 5 }} />
      </Form.Item>
    ) : (
      <Typography>{value || "N/A"}</Typography>
    )}
  </WithLabel>
);

const TypeInput: React.FC<EditableField> = ({ value, isEditing }) => (
  <WithLabel name="Type">
    {isEditing ? (
      <Form.Item
        name="type"
        rules={[{ required: true, message: "Type is required!" }]}
      >
        <Select size="large" placeholder={"Select Type"}>
          {SpecialProjectTypes.map((s) => (
            <Select.Option value={s} key={s}>
              {startCase(s?.trim().toLowerCase())}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ) : (
      <Typography>{startCase(value?.trim().toLowerCase()) || "N/A"}</Typography>
    )}
  </WithLabel>
);

const FacilitySelector: React.FC<EditableField> = ({
  value,
  isEditing,
  disabled,
}) => {
  const selector = () => (
    <Form.Item
      name="facility"
      rules={[{ required: true, message: "Facility is required!" }]}
    >
      <Select size="large" placeholder={"Select Facility"} disabled={disabled}>
        {Facility.getFulfillmentFacilityList().map((f) => (
          <Select.Option value={f.code} key={f.code}>
            {f.code?.trim().toUpperCase()}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  const renderSelector = () => {
    if (disabled) {
      return (
        <Tooltip title="Supplies need to be cleared before changing the facility">
          {selector()}
        </Tooltip>
      );
    } else {
      return selector();
    }
  };

  return (
    <WithLabel name="Facility">
      {isEditing ? (
        renderSelector()
      ) : (
        <Typography>{value?.toUpperCase() || "N/A"}</Typography>
      )}
    </WithLabel>
  );
};

const StartDateSelector: React.FC<EditableField> = ({ value, isEditing }) => (
  <WithLabel name="Start date">
    {isEditing ? (
      <Form.Item
        name="startDate"
        rules={[{ required: true, message: "Start date cannot be empty" }]}
      >
        <DatePicker
          showTime={{ showHour: true, showMinute: true }}
          format="YYYY-MM-DD HH:mm"
          style={{ width: "100%" }}
        />
      </Form.Item>
    ) : (
      <NullSafeDate date={value} />
    )}
  </WithLabel>
);

const EndDateSelector: React.FC<EditableField> = ({ value, isEditing }) => (
  <WithLabel name="End date">
    {isEditing ? (
      <Form.Item
        name="endDate"
        rules={[{ required: true, message: "End date cannot be empty" }]}
      >
        <DatePicker
          showTime={{ showHour: true, showMinute: true }}
          format="YYYY-MM-DD HH:mm"
          style={{ width: "100%" }}
        />
      </Form.Item>
    ) : (
      <NullSafeDate date={value} />
    )}
  </WithLabel>
);

const OrganizationSelector: React.FC<
  EditableField & {
    onError: (e: any) => void;
    onSelect?: (organization: Organization) => void;
  }
> = ({ value, isEditing, onSelect, onError }) => {
  const [results, setResults] = useState<Organization[]>([]);
  const { mutate: searchOrganizations, isLoading: isSearchingOrg } =
    useMutation(fetchOrganizations, {
      onError: onError,
      onSuccess: (data) => {
        setResults(
          data.map((o) => ({ id: o.id, name: o.name })) as Organization[]
        );
      },
    });

  const handleOrgSearch = (id: string) => searchOrganizations(id?.trim());
  const debounceHandleOrgSearch = debounce(handleOrgSearch, 500);

  return (
    <WithLabel name="Organization">
      {isEditing ? (
        <Form.Item
          name="organization"
          rules={[{ required: true, message: "Organization is required" }]}
        >
          <Select
            size="large"
            onSearch={debounceHandleOrgSearch}
            onSelect={(id: string) => {
              const organization = results.find((o) => o.id === id);
              if (organization) onSelect?.(organization);
            }}
            showArrow
            showSearch
            allowClear
            placeholder="Search organization"
            filterOption={false}
            suffixIcon={isSearchingOrg && <Spin size="small" />}
          >
            {results.map((o) => (
              <Select.Option key={o.id} value={o.id}>
                {o.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        <Typography>{value || "N/A"}</Typography>
      )}
    </WithLabel>
  );
};

const EditButton: React.FC<{
  isEnabled: boolean;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}> = (props) => (
  <Tooltip
    title={!props.isEnabled ? permissionsEditWarning : null}
    trigger={["click", "hover"]}
  >
    <Button
      size="large"
      icon={<EditOutlined />}
      block
      onClick={props.onClick}
      loading={props.loading}
      disabled={!props.isEnabled}
    >
      Edit
    </Button>
  </Tooltip>
);

const SaveButton: React.FC<{
  isEnabled: boolean;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}> = (props) => (
  <Form.Item>
    <Tooltip
      title={!props.isEnabled ? permissionsEditWarning : null}
      trigger={["click", "hover"]}
    >
      <Button
        size="large"
        type="primary"
        loading={props.loading}
        icon={<SaveOutlined />}
        block
        onClick={props.onClick}
        disabled={!props.isEnabled}
      >
        Save
      </Button>
    </Tooltip>
  </Form.Item>
);

const CancelButton: React.FC<{
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}> = (props) => (
  <Button
    size="large"
    icon={<CloseOutlined />}
    block
    loading={props.loading}
    onClick={props.onClick}
  >
    Cancel
  </Button>
);

const DetailPage: React.FC = () => {
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const { canWriteSpecialProjects, canReadSpecialProjects } =
    useValidateSpecialProjectPermissions();
  const canWrite = canWriteSpecialProjects();
  const canRead = canReadSpecialProjects();
  const [createdBy, setCreatedBy] = useState<string>("");
  const [form] = Form.useForm();
  const [error, setError] = useState<string>("");
  const [project, setProject] = useState<SpecialProject>();
  const [organization, setOrganization] = useState<Organization>();
  const [isEditing, setEditing] = useState<boolean>(false);
  const [cart, setCart] = useState<SupplyCart>({});

  useEffect(() => {
    setError(canRead ? "" : permissionsReadWarning);
  }, [canRead]);

  const initializeSupplies = (project: SpecialProject) => {
    if (!project?.special_project_supplies?.length) return;

    const suppliesMap = project.special_project_supplies.reduce(
      (acc, s: SpecialProjectSupply) => {
        acc[s.supply_id] = {
          supplyId: s.supply_id,
          code: s.supply?.code,
          item: s.supply,
          quantity: s.quantity,
        };
        return acc;
      },
      {}
    );
    setCart(suppliesMap);
  };

  const { isLoading: isFetching } = useQuery(
    "specialProject",
    () => getSpecialProject(projectId),
    {
      enabled: canRead,
      onError: setError,
      onSuccess: (project: SpecialProject) => {
        setProject(project);
        initializeSupplies(project);
      },
    }
  );
  const { isLoading: isUpdating, mutate: updateProject } = useMutation(
    updateSpecialProject,
    {
      onError: setError,
      onSuccess: (project: SpecialProject) => {
        setProject(project);
        setEditing(false);
      },
    }
  );

  const { isLoading: isFetchingLogs } = useQuery(
    "specialProjectLogs",
    () => fetchSpecialProjectLogsByProjectId(projectId),
    {
      enabled: !!project && !createdBy && canRead,
      onSuccess: (logs: SpecialProjectLog[]) => {
        if (!logs.length) return;
        setCreatedBy(logs[0]?.user_email);
      },
    }
  );

  useEffect(() => {
    if (!isEditing) return;

    form.setFieldsValue({
      organization: project?.organization?.name,
      assignee: project?.user_names?.split(",")?.map((e) => e?.trim()),
      startDate: project?.start_time ? moment(project.start_time) : undefined,
      endDate: project?.end_time ? moment(project.end_time) : undefined,
      facility: project?.facility,
      type: project?.type,
      details: project?.details,
      referenceNumber: project?.reference_number,
    });
  }, [isEditing, form, project]);

  const showError = (error: any) => setError(error?.message);
  const onSelectOrg = (org: Organization) => setOrganization(org);
  const closeEditor = () => {
    setEditing(false);
    setOrganization(undefined);
  };
  const onSubmitForm = async (values: Record<string, any>) => {
    try {
      setError("");
      await form.validateFields();
    } catch (e: any) {
      return;
    }

    const startDate = form.getFieldValue("startDate");
    const endDate = form.getFieldValue("endDate");
    const { isValid: isDateValid, error } = validateDates(startDate, endDate);
    if (!isDateValid) return setError(error);

    await updateProject({
      id: project?.id,
      organization_id: organization?.id || project?.organization_id,
      user_names: values.assignee?.map((e) => e?.trim()).join(", "),
      start_time: values.startDate?.toISOString(),
      end_time: values.endDate?.toISOString(),
      facility: values.facility,
      details: values.details?.trim() || null,
      type: values.type,
      reference_number: values.referenceNumber?.trim() || null,
      supplies: Object.values(cart).map((c) => {
        return {
          supply_id: c.supplyId,
          quantity: c.quantity,
        };
      }),
    });
  };

  const viewerButtonGroup = (
    <Row justify="end" gutter={[8, 8]}>
      <Col xs={24} lg={6} xl={5}>
        <EditButton
          onClick={() => setEditing(true)}
          loading={isFetching}
          isEnabled={canWrite}
        />
      </Col>
    </Row>
  );

  const editorButtonGroup = (
    <Row justify="end" gutter={[8, 8]}>
      <Col xs={24} lg={6} xl={5}>
        <CancelButton onClick={closeEditor} loading={isUpdating} />
      </Col>
      <Col xs={24} lg={6} xl={5}>
        <SaveButton
          onClick={() => onSubmitForm(form.getFieldsValue())}
          loading={isUpdating}
          isEnabled={canWrite}
        />
      </Col>
    </Row>
  );

  const isLoading = isFetching || isUpdating || isFetchingLogs;
  const shouldShowSpinner = isLoading || !project;

  return (
    <PageContainer withPadding loading={shouldShowSpinner && canRead}>
      <PageHeader
        title="Project Detail"
        onBack={() => history.push("/special-projects")}
      />
      <Form
        size="large"
        form={form}
        layout="vertical"
        onFinishFailed={showError}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <ErrorAlert error={error} />
          <Row justify="space-between" align="top" gutter={[8, 16]}>
            <Col xs={24} lg={12}>
              <Typography.Title ellipsis>
                Project ID: {project?.id || "N/A"}
              </Typography.Title>
            </Col>
            <Col xs={24} lg={12}>
              {isEditing ? editorButtonGroup : viewerButtonGroup}
            </Col>
          </Row>
          <Row align="top" justify="start" gutter={[8, 16]}>
            <Col xs={24} lg={16}>
              <Row justify="center" align="middle" gutter={[8, 16]}>
                <Col xs={24}>
                  <Card>
                    <Row justify="center" align="middle" gutter={[8, 16]}>
                      <Col xs={24}>
                        <WithLabel name="ID">
                          <Typography.Paragraph
                            style={{ margin: 0 }}
                            copyable={!!project?.id}
                          >
                            {project?.id || "N/A"}
                          </Typography.Paragraph>
                        </WithLabel>
                      </Col>
                      <Col xs={24}>
                        <OrganizationSelector
                          value={project?.organization?.name}
                          onError={setError}
                          onSelect={onSelectOrg}
                          isEditing={isEditing}
                        />
                      </Col>
                      <Col xs={24}>
                        <ASNSelector
                          value={project?.reference_number}
                          onError={setError}
                          isEditing={isEditing}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={24}>
                  <Card>
                    <Row justify="center" align="middle" gutter={[8, 16]}>
                      <Col xs={24}>
                        <StaffInput
                          value={project?.user_names}
                          isEditing={isEditing}
                        />
                      </Col>
                      <Col xs={24}>
                        <DetailInput
                          value={project?.details}
                          isEditing={isEditing}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={24}>
                  <Card>
                    <Row justify="center" align="middle">
                      <Col xs={24}>
                        <SupplyScanner
                          setError={setError}
                          cart={cart}
                          setCart={setCart}
                          isEditing={isEditing}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={8}>
              <Card>
                <Row justify="center" align="middle" gutter={[8, 16]}>
                  <Col xs={24}>
                    <FacilitySelector
                      value={project?.facility}
                      isEditing={isEditing}
                      disabled={!isEmpty(cart)}
                    />
                  </Col>
                  <Col xs={24}>
                    <TypeInput value={project?.type} isEditing={isEditing} />
                  </Col>
                  <Col xs={24}>
                    <StartDateSelector
                      value={project?.start_time}
                      isEditing={isEditing}
                    />
                  </Col>
                  <Col xs={24}>
                    <EndDateSelector
                      value={project?.end_time}
                      isEditing={isEditing}
                    />
                  </Col>
                  <Col xs={24}>
                    <WithLabel name="Created By">
                      <Typography>{createdBy || "N/A"}</Typography>
                    </WithLabel>
                  </Col>
                  <Col xs={24}>
                    <WithLabel name="Created At">
                      <NullSafeDate date={project?.created_at} />
                    </WithLabel>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Space>
      </Form>
    </PageContainer>
  );
};

export default DetailPage;
