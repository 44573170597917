import React from "react";
import { Descriptions } from "antd";

interface Props {
  name?: string;
}
const SelectedCustomerInfo: React.FC<Props> = ({ name }) => {
  if (!name) return null;
  return (
    <Descriptions bordered size="small">
      <Descriptions.Item label="Organization" span={3}>
        -
      </Descriptions.Item>
      <Descriptions.Item label="Internal Order ID" span={3}>
        -
      </Descriptions.Item>
      <Descriptions.Item label="External Order #" span={3}>
        -
      </Descriptions.Item>
      <Descriptions.Item label="End Customer" span={3}>
        {name}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default SelectedCustomerInfo;
