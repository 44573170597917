import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";
import { Col, Row } from "antd";

import Cookie from "../../lib/cookie";
import SquareButton from "./SquareButton";
import PageContainer from "../../components/PageContainer";
import { userState } from "../../recoil/user/atoms";
import { Block, BLOCKS, BlockType } from "./helpers";

// styled components
import * as S from "./styles";

const HomePage: React.FC = () => {
  const history = useHistory();
  const isEnabled = true;
  const user = useRecoilValue(userState);
  const [currentTabKey, setCurrentTabKey] = useState<string>(
    BlockType.mainFunctions
  );

  useEffect(() => {
    const defaultTab =
      Cookie.getDefaultTab(user?.email || "default") || BlockType.mainFunctions;
    setCurrentTabKey(defaultTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabChange = (k) => {
    setCurrentTabKey(k);
    Cookie.setDefaultTab(user?.email || "default", k);
  };

  const groupBlocksByType = () => {
    const initialValue = Object.values(BlockType).reduce((acc, cv) => {
      acc[cv] = [];
      return acc;
    }, {} as { [key in BlockType]: Block[] });

    return Object.values(BLOCKS).reduce((acc, cv) => {
      const type = cv.type;
      initialValue[type].push(cv);
      return acc;
    }, initialValue);
  };

  const renderSquareButton = (b) => {
    return (
      <Col
        xs={24}
        sm={6}
        lg={4}
        xl={4}
        key={b.name}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <SquareButton
          text={b.name}
          onClick={() => history.push(b.path)}
          disabled={b.disabled || (b.requiredAccess && !isEnabled)}
        />
      </Col>
    );
  };

  const renderTab = (title: string, blocks: Block[]) => {
    return (
      <S.TabPane tab={title} key={title}>
        <Row align="middle">{blocks.map(renderSquareButton)}</Row>
      </S.TabPane>
    );
  };

  const renderTabs = () => {
    const blocksByType = groupBlocksByType();
    return (
      <S.Tabs size="large" onChange={onTabChange} activeKey={currentTabKey}>
        {Object.entries(blocksByType).map(([title, blocks]) =>
          renderTab(title, blocks)
        )}
      </S.Tabs>
    );
  };

  return <PageContainer withHeader>{renderTabs()}</PageContainer>;
};

export default HomePage;
