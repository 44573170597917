import React from "react";
import { Button, Menu, Dropdown, Row, Col } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";

import { ReturnStatus } from "../../pages/Returns/ReturnsIndexPage/helpers";

interface Props {
  returnDetails?: Warehouse.Return;
  handleCompleteReturn: () => void;
  handleMenuClick: (key: string) => void;
  isUpdating: boolean;
  isUpdatingReturnItem: boolean;
}

const ProcessReturnActionButtons: React.FC<Props> = ({
  returnDetails,
  handleCompleteReturn,
  handleMenuClick,
  isUpdating,
  isUpdatingReturnItem,
}) => {
  const disableCompleteButtonStatuses = [
    ReturnStatus.REQUESTED,
    ReturnStatus.ON_HOLD,
    ReturnStatus.READY_TO_PROCESS,
    ReturnStatus.COMPLETED,
    ReturnStatus.TO_BE_RESHIPPED,
  ].includes(returnDetails?.status as Warehouse.ReturnStatus);

  const disableOnHoldButtonStatuses = [
    ReturnStatus.REQUESTED,
    ReturnStatus.ON_HOLD,
    ReturnStatus.COMPLETED,
    ReturnStatus.TO_BE_RESHIPPED,
  ].includes(returnDetails?.status as Warehouse.ReturnStatus);

  const renderMenu = () => (
    <Menu onClick={(e) => handleMenuClick(e?.key as string)}>
      <Menu.Item key={ReturnStatus.ON_HOLD} icon={<UserOutlined />}>
        Put on Hold
      </Menu.Item>
    </Menu>
  );

  return (
    <Row justify="end" gutter={[8, 8]}>
      <Col xs={24} lg={7}>
        <Button
          size="large"
          type="primary"
          block
          disabled={disableCompleteButtonStatuses || isUpdatingReturnItem}
          onClick={handleCompleteReturn}
          loading={isUpdating}
        >
          Complete Return
        </Button>
      </Col>
      <Col xs={24} lg={7}>
        <Dropdown overlay={renderMenu()}>
          <Button
            size="large"
            block
            disabled={disableOnHoldButtonStatuses || isUpdatingReturnItem}
            loading={isUpdating}
          >
            Other Actions <DownOutlined />
          </Button>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default ProcessReturnActionButtons;
