import { Warehouse } from "@secondcloset/types";
import { clone } from "lodash-es";

type FacilityOrganization = Warehouse.FacilityOrganization;
type PicksheetStats = Warehouse.PicksheetStats;

type FacilityOrganizationStats = {
  stats: Partial<PicksheetStats>;
};

export type FacilityOrganizationWithStats = FacilityOrganization &
  FacilityOrganizationStats;

export const formatOrganizationsWithPicksheetStat = (
  facilityOrgs: FacilityOrganization[],
  orgPicksheetStats: PicksheetStats[]
) => {
  const clonedFacilityOrgs = clone(facilityOrgs) || [];
  const orgsMappedWithStats = clonedFacilityOrgs.map((facilityOrg) => {
    const matchedStats = (orgPicksheetStats?.find((stat) => {
      return stat?.organization?.id === facilityOrg.organization_id;
    }) || {}) as PicksheetStats;
    return {
      ...facilityOrg,
      stats:
        {
          processing_items_count: matchedStats?.processing_items_count || 0,
          ready_to_fulfill_count: matchedStats?.ready_to_fulfill_count || 0,
        } || {},
    };
  });
  return orgsMappedWithStats;
};

export const updateIdsListWithNewId = (
  originalListOfIds: string[],
  incomingId: string
) => {
  const newListOfIds = clone(originalListOfIds);
  newListOfIds.push(incomingId);
  return newListOfIds;
};

export const findAndRemoveIdFromList = (
  originalListOfIds: string[],
  selectedId: string
) => {
  const newListOfIds = clone(originalListOfIds);
  const position = newListOfIds?.findIndex((id) => id === selectedId);
  newListOfIds.splice(position, 1);
  return newListOfIds;
};

export const MAX_PICKSHEETS_PER_REQUEST = 50;
