export const calculateUpdatedRecords = (
  quantity: number,
  locationItemId: string,
  quantityRecords
) => {
  const updatedQuantityRecords = [...quantityRecords];
  const recordPosition = quantityRecords.findIndex(
    (record) => record.location_item_id === locationItemId
  );
  if (recordPosition >= 0) {
    const currentQuantityRecord = quantityRecords[recordPosition];
    const updatedQuantityRecord = {
      ...currentQuantityRecord,
      quantity,
    };
    updatedQuantityRecords.splice(recordPosition, 1, updatedQuantityRecord);
  } else {
    updatedQuantityRecords.push({
      quantity,
      location_item_id: locationItemId,
    });
  }
  return updatedQuantityRecords;
};
