import { Warehouse } from "@secondcloset/types";
import queryString from "query-string";
import { PaginatedData, PaginationOptions } from "./commonInterfaces";
import { downloadLabel, getAPIFromDomain, getPDF } from "../../lib/api";
import { PalletRequestSource } from "../../pages/RequestPalletIDsPage/helpers";
import { FacilityCode } from "@secondcloset/fulfillment-utils";
import { validateFacilityCode } from "../../lib/validateFacilityCode";

type Pallet = Warehouse.Pallet;
type PalletType = Warehouse.PalletType;
type PalletRequestLog = Warehouse.PalletRequestLog;
type PalletRequestStatus = Warehouse.PalletRequestStatus;

interface PalletQuery {
  id?: string;
  search?: string;
  facility?: FacilityCode;
}

interface PalletRequestLogQuery {
  source?: PalletRequestSource;
  status?: PalletRequestStatus;
  user_email?: string;
}

export const fetchPallets = async (
  query: PaginationOptions & PalletQuery
): Promise<PaginatedData<Pallet>> => {
  const api = getAPIFromDomain("november");
  const formattedQuery = {
    ...query,
    facility: validateFacilityCode(query?.facility),
  };
  const url = `/pallets?${queryString.stringify(formattedQuery || {})}`;
  const response = await api.get(url);
  return response.data as PaginatedData<Pallet>;
};

export const fetchPalletRequestLogs = async (
  query: PaginationOptions & PalletRequestLogQuery
): Promise<PaginatedData<PalletRequestLog>> => {
  const api = getAPIFromDomain("november");
  const url = `/pallet-request-logs?${queryString.stringify(query || {})}`;
  const response = await api.get(url);
  return response.data as PaginatedData<PalletRequestLog>;
};

export interface CreatePalletRequestBody {
  type?: PalletType;
  source: PalletRequestSource;
  count: number;
  facility: FacilityCode;
}

export const createPalletRequest = async (
  body: CreatePalletRequestBody
): Promise<PalletRequestLog> => {
  const api = getAPIFromDomain("november");
  const url = "/pallet-request-logs";
  const response = await api.post(url, body);
  return response.data as PalletRequestLog;
};

export const generatePalletLabel = async (palletID: string) => {
  try {
    const res = await getPDF(`/pallets/label-pdf/${palletID}`, "november");
    const url = window.URL.createObjectURL(res.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${palletID}.pdf`);
    link.dispatchEvent(
      new MouseEvent(`click`, {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
  } catch (e: any) {
    throw e.message;
  }
};

export const batchGeneratePalletLabel = async (id: string) => {
  const res = await downloadLabel(
    `/pallet-request-logs/batch-generate-pdf/${id}`,
    [],
    "november"
  );
  const url = window.URL.createObjectURL(
    new Blob([res.data], { type: "application/pdf" })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `pallet-${Date.now()}.pdf`);
  link.dispatchEvent(
    new MouseEvent(`click`, {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );
};
