import React from "react";
import { Space, Typography } from "antd";

import * as S from "./styles";
import { Link } from "react-router-dom";

interface Props {
  name?: string;
  code?: string;
  supplyId?: string;
}

const { Title } = Typography;

const SupplyIdentifiersField: React.FC<Props> = ({ name, code, supplyId }) => {
  const renderLabelValue = (label: string, value?: string) => {
    if (!value) return null;
    return (
      <Space>
        <S.Label>{label}:</S.Label>
        <S.Value>{value}</S.Value>
      </Space>
    );
  };

  const renderName = () => {
    if (supplyId) {
      return (
        <S.ItemLabel>
          <Link to={`/supplies/${supplyId}`}>{name}</Link>
        </S.ItemLabel>
      );
    }
    return <Title level={5}>{name}</Title>;
  };

  return (
    <Space direction="vertical" size={0}>
      {name && renderName()}
      {renderLabelValue("Code", code)}
    </Space>
  );
};
export default SupplyIdentifiersField;
