export enum PicksheetStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

export const ALLOWED_FILTERS = [
  "allow_partial",
  "sku",
  "upc",
  "number_of_orders",
  "date_from",
  "date_to",
];
