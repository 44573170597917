import { Warehouse } from "@secondcloset/types";
import { ReturnStatus } from "../ReturnsIndexPage/helpers";

type ReturnStatus = Warehouse.ReturnStatus;
type ReturnStatusMap = { [status in ReturnStatus]: string };

export const canProcessReturn = (status?: string) => {
  return [
    ReturnStatus.ON_HOLD,
    ReturnStatus.ARRIVED,
    ReturnStatus.NEEDS_REVIEW,
    ReturnStatus.READY_TO_PROCESS,
  ]?.includes(status || "");
};

export const canMarkAsArrive = (status?: string) => {
  return [ReturnStatus.REQUESTED]?.includes(status || "");
};

export const statusTagColorConfig: ReturnStatusMap = {
  READY_TO_PROCESS: "processing",
  NEEDS_REVIEW: "error",
  ARRIVED: "processing",
  PROCESSING: "warning",
  REQUESTED: "error",
  ON_HOLD: "error",
  TO_BE_RESHIPPED: "success",
  COMPLETED: "success",
  CANCELLED: "warning",
};
