import React, { FC } from "react";

// Styles
import * as S from "./styles";

// Recoil
import { useRecoilValue } from "recoil";
import {
  packingFlowPackageTypeState,
  PackageType,
  packingFlowPackageContainerState,
  packingFlowPackageWeightState,
} from "../../../../../recoil/packingFlow/atoms";

const PackageDetails: FC = () => {
  const packageContainers = useRecoilValue(packingFlowPackageContainerState);
  const packageWeights = useRecoilValue(packingFlowPackageWeightState);
  const packageType = useRecoilValue(packingFlowPackageTypeState);

  return (
    <S.Container>
      {packageContainers.map((container, index) => {
        const { length, width, height } = container;
        const weight = packageWeights[index].weight;
        return (
          <S.Wrapper key={index}>
            <S.Row>
              <S.Text fontSize={14} fontWeight={600}>
                {`${packageType === PackageType.box ? "Package" : "Pallet"}`}{" "}
                {index + 1}
              </S.Text>
            </S.Row>
            <S.Row>
              <S.Text
                fontSize={14}
                fontWeight={400}
              >{`${length}"L x ${width}"W x ${height}"H - ${weight}lb`}</S.Text>
            </S.Row>
          </S.Wrapper>
        );
      })}
    </S.Container>
  );
};

export default PackageDetails;
