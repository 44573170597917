import axios from "axios";
import { Common, Warehouse } from "@secondcloset/types";

import { getAPIFromDomain, getHeaderTemplate } from "../../lib/api";
import queryString from "query-string";
import Cookie from "../../lib/cookie";

// Interfaces
import { PaginatedData, PaginationOptions } from "./commonInterfaces";
import { validateFacilityCode } from "../../lib/validateFacilityCode";

type FacilityCode = Common.FacilityCode;
type Location = Warehouse.Location;

export type LocationType =
  | "SHELF"
  | "PACKING_STATION"
  | "RACKING"
  | "CART"
  | "PALLET_ZONE";

interface LocationQuery {
  facility?: FacilityCode;
  type?: LocationType;
  code?: string;
}

export const fetchLocations = async (
  query?: PaginationOptions & LocationQuery
): Promise<PaginatedData<Location>> => {
  const formattedQuery = {
    ...query,
    facility: validateFacilityCode(query?.facility),
  };
  const api = getAPIFromDomain("november");
  const url = `/locations?${queryString.stringify(formattedQuery || {})}`;
  const respond = await api.get(url);
  return respond.data as PaginatedData<Location>;
};

interface LocationCreateBody {
  facility: FacilityCode;
  code: string;
  location_template_id: string;
  type: LocationType;
}

export const createLocation = async (
  body: LocationCreateBody
): Promise<Location> => {
  const api = getAPIFromDomain("november");
  const url = "/locations";
  const respond = await api.post(url, body);
  return respond.data as Location;
};

export const uploadLocationsCsvFile = async (
  file: File
): Promise<Location[]> => {
  const url = `${process.env.REACT_APP_WAREHOUSE_API}/locations/csv-upload`;
  const header = getHeaderTemplate(Cookie.getUserToken());
  header.headers["Content-Type"] = "multipart/form-data";
  const bodyFormData = new FormData();
  bodyFormData.append("csvFile", file);
  return axios.post(url, bodyFormData, header);
};
