import moment from "moment";
import React from "react";

interface Props {
  date?: string;
}

const NullSafeDate: React.FC<Props> = ({ date }) => {
  return <span>{date ? moment(date).format("lll") : "-"}</span>;
};
export default NullSafeDate;
