import React, { useState, useRef } from "react";
import { Modal, Button, Radio, Input } from "antd";
import * as S from "./styles";
import { BarcodeOutlined } from "@ant-design/icons";
import { COLORS } from "../../../../styles";

interface Props {
  title: string;
  visible: boolean;
  hasSwitch?: boolean;
  switchOptions?: string[];
  onClose: () => void;
  onSubmit: (value: string, option?: string) => void;
}

const ScanModal: React.FC<Props> = ({
  title,
  visible,
  hasSwitch,
  switchOptions,
  onClose,
  onSubmit,
}) => {
  const [option, setOption] = useState("");
  const inputRef = useRef<Input | null>(null);
  const defaultOption = hasSwitch && switchOptions ? switchOptions[0] : "";

  const handleOnSubmit = (): void => {
    onSubmit(inputRef?.current?.state?.value, option || defaultOption);
    setOption("");
  };

  const renderSwitch = () => {
    if (!switchOptions) return;

    const handleChange = (e: any) => {
      setOption(e.target.value);
    };

    return (
      <S.SwitchWrap>
        <Radio.Group
          defaultValue={defaultOption}
          buttonStyle="solid"
          onChange={handleChange}
        >
          {switchOptions.map((op) => (
            <Radio.Button key={op} value={op}>
              {op}
            </Radio.Button>
          ))}
        </Radio.Group>
      </S.SwitchWrap>
    );
  };

  return (
    <Modal destroyOnClose visible={visible} onCancel={onClose} footer={null}>
      <S.Title>{title}</S.Title>
      <S.InputWrap>
        {hasSwitch ? renderSwitch() : ""}
        <S.Input
          allowClear
          autoFocus
          ref={inputRef}
          placeholder="Scan item code"
          onPressEnter={handleOnSubmit}
          suffix={<BarcodeOutlined style={{ color: COLORS.GREY }} />}
        />
        <Button type="primary" onClick={handleOnSubmit}>
          Submit
        </Button>
      </S.InputWrap>
    </Modal>
  );
};

export default ScanModal;
