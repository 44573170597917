// Hooks
import useUrlState from "@ahooksjs/use-url-state";
import { PlusOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import {
  Button,
  Col,
  Input,
  Row,
  Space,
  Table,
  TablePaginationConfig,
} from "antd";
import { FixedType } from "rc-table/lib/interface";
import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { FacilityCode } from "@secondcloset/fulfillment-utils";

// API
import { fetchPallets } from "../../../api/warehouse";

// Hooks and helpers
import useFacility from "../../../hooks/useFacility";

// Components
import PageContainer from "../../../components/PageContainer";
import { buildTablePagination } from "../../../components/Pagination/helper";
import ItemCountFooter from "../../../components/Table/ItemCountFooter";
import NullSafeDate from "../../../components/Table/NullSafeDate";
import NullSafeLink from "../../../components/Table/NullSafeLink";
import NullSafeText from "../../../components/Table/NullSafeText";
import WithLabel from "../../../components/WithLabel";
import FacilityDropdown from "../../../components/FacilityDropdown";

type LocationItem = Warehouse.LocationItem;
type Pallet = Warehouse.Pallet;

const PalletsIndexPage: React.FC = () => {
  const cachedFacility = useFacility().facility;
  const history = useHistory();
  const [error, setError] = useState("");
  const [{ page, perPage, search, facility }, setUrlState] = useUrlState(
    {
      page: 1,
      perPage: 10,
      search: undefined,
      facility: cachedFacility,
    },
    { navigateMode: "replace" }
  );
  const { setFacility } = useFacility(setUrlState, setError);

  const paginatedPallets = useQuery(
    ["fetchPallets", search, page, perPage, facility],
    () =>
      fetchPallets({
        search,
        page,
        limit: perPage,
        facility: cachedFacility as FacilityCode,
      }),
    {
      onError: (error: string) => setError(error),
    }
  );

  const getPallets = useCallback((): Pallet[] => {
    return paginatedPallets.data?.items || [];
  }, [paginatedPallets]);

  const renderText = (locationCode?: string) => {
    return <NullSafeText value={locationCode} />;
  };
  const renderLink = (palletId: string) => {
    return <NullSafeLink href={`/pallets/${palletId}`} value={palletId} />;
  };
  const renderDate = (date: string) => {
    return <NullSafeDate date={date} />;
  };

  const buildTableColumns = () => {
    return [
      {
        title: "Pallet ID",
        dataIndex: "id",
        width: "30%",
        render: renderLink,
        fixed: "left" as FixedType,
      },
      {
        title: "Location",
        dataIndex: "location_items",
        render: (locationItems: LocationItem[]) =>
          renderText(locationItems?.[0]?.location_code),
        width: "30%",
      },
      {
        title: "Facility",
        width: 200,
        render: (facility: string) => <NullSafeText value={facility} />,
        dataIndex: "facility",
      },
      {
        title: "Type",
        dataIndex: "type",
        width: "15%",
      },
      {
        title: "Date Created",
        dataIndex: "created_at",
        width: "25%",
        render: renderDate,
      },
    ];
  };

  const buildPagination = (): TablePaginationConfig => {
    const paginationInfo = paginatedPallets.data?.meta;

    return buildTablePagination({
      paginationInfo,
      showQuickJumper: true,
      showSizeChanger: true,
      onChange: (p: number, newPageSize?: number) => {
        if (newPageSize && newPageSize !== +perPage) {
          setUrlState({ page: 1, perPage: newPageSize });
        } else {
          setUrlState({ page: p });
        }
      },
    });
  };

  const renderSearch = () => {
    return (
      <WithLabel name="Pallet">
        <Input.Search
          size="large"
          allowClear
          placeholder="Search for a Pallet ID..."
          onSearch={(id: string) => {
            setUrlState({ search: id?.trim(), page: 1 });
          }}
          onChange={(e) => {
            if (!e.target.value.trim()) {
              setUrlState({ search: "", page: 1 });
            }
          }}
          loading={paginatedPallets.isLoading}
          autoCapitalize="off"
        />
      </WithLabel>
    );
  };

  const renderRequestPalletId = () => (
    <Button
      size="large"
      title="Request Pallet IDs"
      type="primary"
      block
      onClick={() =>
        history.push({
          pathname: "/request-pallet-ids",
          search: "?" + new URLSearchParams({ source: "pallet" }).toString(),
        })
      }
      icon={<PlusOutlined />}
    >
      Request Pallet IDs
    </Button>
  );

  const renderToolbar = () => {
    return (
      <Row justify="space-between" align="bottom" gutter={[8, 8]}>
        <Col xs={24} lg={16}>
          <Space align="end">
            {renderSearch()}
            <FacilityDropdown
              selectedFacility={facility}
              onSelect={setFacility}
            />
          </Space>
        </Col>
        <Col xs={24} lg={5} xl={4}>
          {renderRequestPalletId()}
        </Col>
      </Row>
    );
  };

  const renderTableFooter = () => (
    <ItemCountFooter count={paginatedPallets.data?.meta.totalItems || 0} />
  );

  return (
    <PageContainer withHeader withFooter withPadding title="Pallets">
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        {renderToolbar()}
        <ErrorAlert error={error} />
        <Table
          size="small"
          bordered
          rowKey="id"
          scroll={{ x: "max-content" }}
          footer={renderTableFooter}
          loading={paginatedPallets.isLoading}
          pagination={buildPagination()}
          columns={buildTableColumns()}
          dataSource={getPallets()}
        />
      </Space>
    </PageContainer>
  );
};

export default PalletsIndexPage;
