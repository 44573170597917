import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import {
  Button,
  Alert,
  Col,
  Input,
  Row,
  Table,
  Space,
  TablePaginationConfig,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import { FixedType } from "rc-table/lib/interface";

// API
import { fetchReturns } from "../../../api/warehouse";
// Components
import PageContainer from "../../../components/PageContainer";
import ItemCountFooter from "../../../components/Table/ItemCountFooter";
import NullSafeDate from "../../../components/Table/NullSafeDate";
import NullSafeLink from "../../../components/Table/NullSafeLink";
import NullSafeText from "../../../components/Table/NullSafeText";
import WithLabel from "../../../components/WithLabel";
import StatusTag from "../ReturnDetailsPage/StatusTag";
import CreateReturnModal from "./CreateReturnModal";
// Hooks
import useUrlState from "@ahooksjs/use-url-state";
import { buildTablePagination } from "../../../components/Pagination/helper";

type Return = Warehouse.Return;

const ReturnsIndexPage: React.FC = () => {
  const [rtsModalVisible, setRtsModalVisible] = useState(false);
  const [error, setError] = useState<string | string[]>("");
  const [{ page, perPage, search }, setUrlState] = useUrlState(
    {
      page: 1,
      perPage: 10,
      search: undefined,
    },
    { navigateMode: "replace" }
  );

  const paginatedReturns = useQuery(
    ["fetchReturns", search, page, perPage],
    () =>
      fetchReturns({
        search,
        with_organization: true,
        page,
        limit: perPage,
      }),
    {
      onError: (error: string) => setError(error),
    }
  );

  const getReturns = useCallback((): Return[] => {
    return paginatedReturns?.data?.items || [];
  }, [paginatedReturns]);

  const { refetch: refetchReturns, isLoading: isReturnsLoading } =
    paginatedReturns;

  const renderLink = (record: Return) => (
    <NullSafeLink href={`/returns/${record?.id}`} value={record?.rma} />
  );
  const renderText = (text: string) => <NullSafeText value={text} />;
  const renderDate = (date: string) => <NullSafeDate date={date} />;
  const renderStatusTag = (status: string) => <StatusTag status={status} />;

  const buildPagination = (): TablePaginationConfig => {
    const paginationInfo = paginatedReturns?.data?.meta;
    return buildTablePagination({
      paginationInfo,
      showQuickJumper: true,
      showSizeChanger: true,
      onChange: (p: number, newPageSize?: number) => {
        if (newPageSize && newPageSize !== +perPage) {
          setUrlState({ page: 1, perPage: newPageSize });
        } else {
          setUrlState({ page: p });
        }
      },
    });
  };

  const buildTableColumns = [
    {
      title: "RMA",
      dataIndex: "rma",
      render: (rma: string, record: Return) => renderLink(record),
      fixed: "left" as FixedType,
    },
    {
      title: "External Order #",
      dataIndex: "external_order_number",
      render: renderText,
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 100,
      render: renderText,
    },
    {
      title: "Carrier",
      dataIndex: "carrier",
      render: renderText,
    },
    {
      title: "Tracking #",
      dataIndex: "tracking_number",
      render: renderText,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      render: renderStatusTag,
    },
    {
      title: "Customer Name",
      dataIndex: ["shipping_from", "name"],
      render: renderText,
    },
    {
      title: "Organization",
      dataIndex: ["organization", "name"],
      render: renderText,
    },
    {
      title: "Date Created",
      dataIndex: "created_at",
      render: renderDate,
    },
  ];

  const handleAddRtsOrder = () => {
    setRtsModalVisible(true);
  };

  const renderSearch = () => {
    return (
      <WithLabel name="Keyword">
        <Input.Search
          size="large"
          allowClear
          loading={paginatedReturns?.isLoading}
          autoCapitalize="off"
          placeholder="RMA, tracking number, order number, or customer name"
          onSearch={(value: string) => {
            setUrlState({ search: value?.trim(), page: 1 });
          }}
        />
      </WithLabel>
    );
  };

  const renderAddRTSButton = () => (
    <Button
      size="large"
      type="primary"
      block
      icon={<PlusOutlined />}
      onClick={handleAddRtsOrder}
    >
      Add RTS Order
    </Button>
  );

  const renderToolbar = () => {
    return (
      <Row align="bottom" justify="space-between" gutter={[8, 16]}>
        <Col xs={24} lg={8}>
          {renderSearch()}
        </Col>
        <Col xs={24} lg={5} xl={4}>
          {renderAddRTSButton()}
        </Col>
      </Row>
    );
  };

  const renderModal = () => {
    return (
      <CreateReturnModal
        rtsModalVisible={rtsModalVisible}
        setRtsModalVisible={setRtsModalVisible}
        refetchReturns={refetchReturns}
      />
    );
  };

  const renderTableFooter = () => (
    <ItemCountFooter count={paginatedReturns.data?.meta.totalItems || 0} />
  );

  return (
    <PageContainer withPadding title="Returns" loading={isReturnsLoading}>
      <Space size="large" direction="vertical" style={{ width: "100%" }}>
        <ErrorAlert error={error} />
        <Alert
          type="info"
          message="Information"
          description="Returns digitization is currently only available for Vessi"
          showIcon
        />
        {renderToolbar()}
        <Table
          size="small"
          bordered
          rowKey="id"
          footer={renderTableFooter}
          scroll={{ x: "max-content" }}
          loading={paginatedReturns?.isLoading}
          pagination={buildPagination()}
          columns={buildTableColumns}
          dataSource={getReturns()}
        />
        {renderModal()}
      </Space>
    </PageContainer>
  );
};

export default ReturnsIndexPage;
