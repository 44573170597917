import { Logistics, Fulfillment } from "@secondcloset/types";
import {
  PackageContainer,
  PackageOrderItemIDs,
  PackageType,
  PackageWeight,
} from "../../recoil/packingFlow/atoms";
import { ExternalCarriers } from "../../api/fulfillment";
type ShippingRate = Logistics.ShippingRate;

export interface CreatePackageOptions {
  packageContainers: PackageContainer[];
  orderItemIDs: PackageOrderItemIDs[];
  packageWeights: PackageWeight[];
  shippingRate?: ShippingRate;
  packageType: PackageType;
  shipment: Fulfillment.Shipment;
  externalOrderNumber?: string;
}

export interface CreatePackageWithManualCarrierOptions {
  packageContainers: PackageContainer[];
  orderItemIDs: PackageOrderItemIDs[];
  packageWeights: PackageWeight[];
  packageType: PackageType;
  shipment: Fulfillment.Shipment;
  carrierInfo: {
    carrier: string;
    trackingNo: string;
  };
}

interface CreatePackageRate {
  external_platform?: ExternalCarriers;
  rate_id?: string;
  shipment_id?: string;
}

export interface PackagedItemCreateBody {
  package_type: PackageType;
  shipment_item_ids: string[];
  weight_value: number;
  weight_unit: string;
  length_value: number;
  length_unit: string;
  height_value: number;
  height_unit: string;
  width_value: number;
  width_unit: string;
}
export interface CarrierDataCreateBody {
  carrier_code: string;
  carrier_name: string;
  tracking_number: string;
  tracking_url: string;
}

export default class PackageCreate {
  static getPackages = (
    packageContainers: PackageContainer[],
    orderItemIDs: PackageOrderItemIDs[],
    packageWeights: PackageWeight[],
    packageType: PackageType,
    shipment: Fulfillment.Shipment
  ): PackagedItemCreateBody[] => {
    return packageContainers.map((container: PackageContainer, index) => {
      const { length, width, height } = container;
      const weight = packageWeights[index].weight;
      const orderItemIds = orderItemIDs[index].orderItemIDs;

      const shipmentItemIds = orderItemIds.reduce((acc, orderItemId) => {
        const shipmentItem = shipment.shipment_items.find(
          (shipment) => shipment.order_item_id === orderItemId
        );
        if (shipmentItem) acc.push(shipmentItem.id);
        return acc;
      }, [] as string[]);

      return {
        package_type: packageType,
        shipment_item_ids: shipmentItemIds,
        weight_value: weight as number,
        weight_unit: "lbs",
        length_value: length as number,
        length_unit: "inches",
        height_value: height as number,
        height_unit: "inches",
        width_value: width as number,
        width_unit: "inches",
      };
    });
  };

  static getCreatePackageBody = ({
    packageContainers,
    orderItemIDs,
    packageWeights,
    shippingRate,
    packageType,
    shipment,
    externalOrderNumber,
  }: CreatePackageOptions): {
    rate: CreatePackageRate | undefined;
    packages: PackagedItemCreateBody[];
    external_order_number?: string | undefined;
  } => {
    const packages = PackageCreate.getPackages(
      packageContainers,
      orderItemIDs,
      packageWeights,
      packageType,
      shipment
    );

    const body = {
      rate: shippingRate
        ? {
            external_platform:
              shippingRate?.external_platform as ExternalCarriers,
            rate_id: shippingRate?.rate_id,
            shipment_id: shippingRate?.shipment_id as string,
          }
        : undefined,
      packages,
      external_order_number: externalOrderNumber,
    };

    return body;
  };

  static getCreatePackageManualCarrierBody = ({
    packageContainers,
    orderItemIDs,
    packageWeights,
    packageType,
    shipment,
    carrierInfo,
  }: CreatePackageWithManualCarrierOptions): {
    packages: PackagedItemCreateBody[];
  } => {
    const trackingNumber = carrierInfo?.trackingNo;
    const trackingUrl = `https://www.frontierscs.com/business-shipment-tracking/?track=${trackingNumber}`;
    const carrier_data = {
      carrier_code: carrierInfo?.carrier,
      tracking_number: trackingNumber,
      tracking_url: trackingUrl,
      label_id: trackingNumber,
      label_download: trackingUrl,
    };

    const packages = PackageCreate.getPackages(
      packageContainers,
      orderItemIDs,
      packageWeights,
      packageType,
      shipment
    );

    const body = {
      packages,
      carrier_data,
    };

    return body;
  };
}
