export class Decoder {
  // Canada Post labels have 16 characters on the label,
  // but the scanned version has 28 characters.
  //
  // e.g. Barcode Value 2CBC010200841000000112303003
  // 2CBC010 | 2008410000001123 | 03003
  //           Tracking Number
  //
  // Byte 1 Service level –Expedited 2
  // Bytes 2-4 postal code alpha characters CBC
  // Bytes 5-7 postal code numerics 010
  // Bytes 8-14 our acct# 2008410
  // Bytes 15-22 1-up counter number 00000112
  // Byte 23 Check digit for bytes 8-22 3
  // Bytes 24-25 Addt’l opt return to sender 03
  // Byte 26 Signature req/proof of age 0
  // Byte 27-28 Special handling leave at door 03
  //
  // @see https://support.techdinamics.com/support/solutions/articles/223813-canada-post-barcode-value
  //
  // @param [String] value CanadaPost barcode string
  // @return [String, nil] Parsed tracking number
  //
  public static decodeCPBarcode(barcodeVal: string) {
    try {
      const value = barcodeVal.trim();
      const buffs = Buffer.from(value);
      if (!value || buffs.byteLength !== 28) return null;

      return buffs.slice(7, 7 + 16).toString();
    } catch (e) {
      return null;
    }
  }

  // Parse tracking number from FedEx label barcode value
  // IMPORTANT: This is a temporary implementation
  //
  // e.g. Barcode Value 1044966812840404684900285050138830
  //      Actual Tracking # 285050138830
  //
  // @param [String] value FedEx barcode string
  // @return [String, nil] Parsed tracking number
  //
  public static decodeFedExBarcode(barcodeVal: string) {
    try {
      const value = barcodeVal.trim();
      const buffs = Buffer.from(value);
      if (!value || buffs.byteLength !== 34) return null;

      return buffs.slice(22, 22 + 12).toString();
    } catch (e) {
      return null;
    }
  }
}

export default { Decoder };
