import React from "react";

//components
import { Alert } from "antd";

//styles
import { Container } from "./styles";

interface Props {
  errorMsg?: string;
}

const AlertBanner: React.FC<Props> = (props) => {
  if (!props.errorMsg) return null;
  return (
    <Container>
      <Alert type="error" message={props.errorMsg} banner closable={true} />
    </Container>
  );
};

export default AlertBanner;
