import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { ErrorAlert } from "@secondcloset/web-components";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  PageHeader,
  Row,
  Space,
  Typography,
} from "antd";
import { CloseOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";

import { fetchProductById, updateProduct } from "../../../api/warehouse";

// Components
import PageContainer from "../../../components/PageContainer";
import WithLabel from "../../../components/WithLabel";

const ProductDetailsPage: React.FC = () => {
  const [form] = Form.useForm();
  const { productId } = useParams<{ productId: string }>();
  const [error, setError] = useState("");
  const [isEditView, setIsEditView] = useState(false);

  const {
    data: productDetails,
    isLoading: isDetailsLoading,
    refetch: refetchProduct,
  } = useQuery(
    ["fetchProductById", productId],
    () => fetchProductById(productId),
    {
      onError: setError,
    }
  );

  const { mutate: onUpdateProduct, isLoading: isProductUpdating } = useMutation(
    updateProduct,
    {
      onMutate: () => {
        setError("");
      },
      onSuccess: () => {
        setError("");
        refetchProduct({ cancelRefetch: true, throwOnError: true });
      },
      onError: setError,
    }
  );

  useEffect(() => {
    const defaultValues = {
      name: productDetails?.name || null,
      sku: productDetails?.sku || null,
      upc: productDetails?.upc || null,
    };
    if (isEditView) form.setFieldsValue(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isEditView]);

  const handleEdit = () => {
    setIsEditView(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setError("");
    setIsEditView(false);
  };

  const handleSave = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }
    const formValues = form.getFieldsValue();
    onUpdateProduct({
      id: productDetails?.id || "",
      body: {
        name: formValues?.name?.trim(),
        sku: formValues?.sku?.trim(),
        upc: formValues?.upc?.trim(),
      },
    });
    setIsEditView(false);
  };

  const renderSaveButton = () => {
    return (
      <Button
        size="large"
        type="primary"
        icon={<SaveOutlined />}
        title="Save Product"
        block
        onClick={handleSave}
        disabled={isDetailsLoading || isProductUpdating}
      >
        Save
      </Button>
    );
  };

  const renderCancelButton = () => {
    return (
      <Button
        size="large"
        icon={<CloseOutlined />}
        title="Cancel Product"
        block
        onClick={handleCancel}
        disabled={isDetailsLoading || isProductUpdating}
      >
        Cancel
      </Button>
    );
  };

  const renderEditorButtonGroup = () => (
    <Row justify="end" gutter={[8, 8]}>
      <Col xs={24} lg={6} xl={5}>
        {renderCancelButton()}
      </Col>
      <Col xs={24} lg={6} xl={5}>
        {renderSaveButton()}
      </Col>
    </Row>
  );

  const renderEditButton = () => {
    return (
      <Button
        size="large"
        icon={<EditOutlined />}
        title="Edit Product"
        block
        onClick={handleEdit}
        disabled={isDetailsLoading || isProductUpdating}
      >
        Edit
      </Button>
    );
  };

  const renderViewerButtonGroup = () => {
    return (
      <Row justify="end" gutter={[8, 8]}>
        <Col xs={24} lg={5} xl={4}>
          {renderEditButton()}
        </Col>
      </Row>
    );
  };

  const renderProductNameEdit = () => (
    <Form.Item
      name="name"
      rules={[
        ({ getFieldValue }) => {
          const productName = getFieldValue("name");
          return {
            required: true,
            transform: () => productName?.trim(),
            message: "Product Name is required",
          };
        },
      ]}
      style={{ margin: 0 }}
    >
      <Input size="large" placeholder="Product Name" />
    </Form.Item>
  );

  const renderToolbar = () => {
    return (
      <Row justify="space-between" align="top" gutter={[8, 16]}>
        <Col xs={24} lg={12}>
          {isEditView ? (
            renderProductNameEdit()
          ) : (
            <Typography.Title ellipsis>{productDetails?.name}</Typography.Title>
          )}
        </Col>
        <Col xs={24} lg={12}>
          {isEditView ? renderEditorButtonGroup() : renderViewerButtonGroup()}
        </Col>
      </Row>
    );
  };

  const renderOrganization = () => (
    <WithLabel name="Organization">
      <Typography.Text>{productDetails?.organization?.name}</Typography.Text>
    </WithLabel>
  );

  const renderSkuEdit = () => (
    <Form.Item
      name="sku"
      dependencies={["upc"]}
      rules={[
        ({ getFieldValue }) => {
          const productUpc = getFieldValue("upc")?.trim();
          const productSku = getFieldValue("sku")?.trim();
          return {
            required: !productUpc,
            transform: () => productSku,
            message: "Product SKU or UPC is required",
          };
        },
      ]}
    >
      <Input placeholder="Product SKU" />
    </Form.Item>
  );

  const renderSkuDisplay = () => (
    <Typography.Text copyable={!!productDetails?.sku}>
      {productDetails?.sku || "-"}
    </Typography.Text>
  );

  const renderSkuField = () =>
    isEditView ? renderSkuEdit() : renderSkuDisplay();

  const renderSku = () => {
    return <WithLabel name="SKU">{renderSkuField()}</WithLabel>;
  };

  const renderUpcEdit = () => (
    <Form.Item
      name="upc"
      dependencies={["sku"]}
      rules={[
        ({ getFieldValue }) => {
          const productSku = getFieldValue("sku")?.trim();
          const productUpc = getFieldValue("upc")?.trim();
          return {
            required: !productSku,
            transform: () => productUpc,
            message: "Product SKU or UPC is required",
          };
        },
      ]}
    >
      <Input placeholder="Product UPC" />
    </Form.Item>
  );

  const renderUpcDisplay = () => (
    <Typography.Text copyable={!!productDetails?.upc}>
      {productDetails?.upc || "-"}
    </Typography.Text>
  );

  const renderUpcField = () =>
    isEditView ? renderUpcEdit() : renderUpcDisplay();

  const renderUpc = () => <WithLabel name="UPC">{renderUpcField()}</WithLabel>;

  return (
    <PageContainer withPadding loading={isDetailsLoading || isProductUpdating}>
      <PageHeader title="Product Detail" onBack={() => window.history.back()} />
      <Form form={form} layout="vertical">
        <Space direction="vertical" style={{ width: "100%" }}>
          <ErrorAlert error={error} />
          {renderToolbar()}
          <Card bordered>
            <Row justify="space-between" gutter={[8, 16]}>
              <Col xs={24} lg={8}>
                {renderOrganization()}
              </Col>
              <Col xs={24} lg={8}>
                {renderSku()}
              </Col>
              <Col xs={24} lg={8}>
                {renderUpc()}
              </Col>
            </Row>
          </Card>
        </Space>
      </Form>
    </PageContainer>
  );
};

export default ProductDetailsPage;
