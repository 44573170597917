import React, { useState } from "react";
import { FileDoneOutlined } from "@ant-design/icons";
import { Button, notification, Popconfirm } from "antd";
import { Fulfillment } from "@secondcloset/types";
import { ErrorAlert, TimelineDrawer } from "@secondcloset/web-components";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";

// API
import {
  fetchASNDetails,
  updateASNItemQuantity,
  updateASNWithLog,
} from "../../../api/fulfillment/asn";
import PageContainer from "../../../components/PageContainer";
import { useSoundEffect } from "../../../hooks/useSoundEffect";

// helpers
import { LINKS } from "../AsnIndexPage/helpers";
import {
  transformItemQuantity,
  getAsnCompleteBody,
  getAsnResetBody,
  getAsnScanBody,
  getAsnSubmitBody,
  bringItemToFront,
} from "./helpers";

// components
import Card from "./Card";
import BatchUpdateModal from "./BatchUpdateModal";
import ProductTable from "./ProductTable";
import Scanner from "./Scanner";

// styles
import * as S from "./styles";

type ASNItem = Fulfillment.ASNItem;
export type ScanStateType = "idle" | "success" | "error";

export interface QuantityMap {
  [sku: string]: number;
}

const AsnProcessPage: React.FC = () => {
  const history = useHistory();
  const { asnID } = useParams<{ asnID: string }>();
  const { playSuccessSound, playFailedSound } = useSoundEffect();
  const [isTimelineVisible, setIsTimelineVisible] = useState(false);
  const [isBatchUpdateModalVisible, setIsBatchUpdateModalVisible] =
    useState(false);
  const [scanState, setScanState] = useState<ScanStateType>("idle");
  const [scannedItem, setScannedItem] = useState("");
  const [error, setError] = useState("");
  const [damagedQuantityMap, setDamagedQuantityMap] = useState<QuantityMap>({});
  const [receivedQuantityMap, setReceivedQuantityMap] = useState<QuantityMap>(
    {}
  );
  const [products, setProducts] = useState<ASNItem[]>([]);
  const [animate, setAnimate] = useState(false);

  const redirectTo = (url: string) => history.push(url);
  const goToAsnIndex = () => redirectTo(`${LINKS.asnIndex}`);
  if (!asnID) goToAsnIndex();

  const { data: asn, isLoading: isAsnLoading } = useQuery(
    ["asnDetails", asnID],
    () =>
      fetchASNDetails({
        asnID,
        query: { with_items: true, with_timeline: true },
      }),
    {
      enabled: !!asnID,
      onSuccess: (data) => {
        updateLocalQuantity(data.items_attributes);
        if (!products.length) setProducts(data.items_attributes);
      },
      onError: (err: string) => {
        setError(err);
        goToAsnIndex();
      },
    }
  );

  const update = useMutation(updateASNWithLog, {
    onSuccess: () => history.push(`/asn/${asnID}`),
    onError: (e: string) => notification.error({ message: e }),
  });

  const resetQuantity = useMutation(updateASNWithLog, {
    onSuccess: (data) => {
      setError("");
      updateLocalQuantity(data?.external_result?.items_attributes);
      playSuccessSound();
      setScanState("idle");
    },
    onError: (error: string) => {
      setError(error);
      playFailedSound();
    },
  });

  const updateQuantity = useMutation(updateASNItemQuantity, {
    onSuccess: (data) => {
      setError("");
      updateLocalQuantity(data?.external_result?.items_attributes);
      playSuccessSound();
      setScanState("success");
      setIsBatchUpdateModalVisible(false);
      setAnimate(true);
    },
    onError: (error: string) => {
      setError(error);
      playFailedSound();
      setScanState("error");
    },
  });

  const updateLocalQuantity = (items: ASNItem[]) => {
    const damagedMap: QuantityMap = {};
    const receivedMap: QuantityMap = {};
    items?.forEach((item: ASNItem) => {
      damagedMap[item.product_sku] = item.damaged_quantity || 0;
      receivedMap[item.product_sku] = item.received_quantity || 0;
    });
    setDamagedQuantityMap(damagedMap);
    setReceivedQuantityMap(receivedMap);
  };

  const onReset = () => {
    resetQuantity.mutate(getAsnResetBody(asn));
  };

  const onScan = (identifier?: string, operation?: string) => {
    if (!identifier || !operation) return;
    updateQuantity.mutate(getAsnScanBody(identifier, operation, asn));
    setScannedItem(identifier);

    const idx = products.findIndex(
      (i) => i.product_sku === identifier || i.product_upc === identifier
    );
    if (idx > 0) setProducts(bringItemToFront(products, idx));
  };

  const renderBatchUpdateModal = () => {
    return (
      <BatchUpdateModal
        error={error}
        resetError={() => setError("")}
        visible={isBatchUpdateModalVisible}
        onClose={() => setIsBatchUpdateModalVisible(false)}
        onSubmit={(identifier, quantity, isReceive) => {
          updateQuantity.mutate(
            getAsnSubmitBody({ identifier, isReceive, quantity, asn })
          );
        }}
        isLoading={update.isLoading}
      />
    );
  };

  const onComplete = () => {
    update.mutate(getAsnCompleteBody(asn));
  };

  return (
    <PageContainer withHeader withFooter withPadding loading={isAsnLoading}>
      <S.Container>
        <S.Contents>
          <S.LeftWrapper>
            <Card
              asnDetails={asn}
              onViewTimelineClick={() => setIsTimelineVisible(true)}
              onResetClick={onReset}
              resetLoading={resetQuantity.isLoading}
              disableReset={
                transformItemQuantity(receivedQuantityMap) === 0 &&
                transformItemQuantity(damagedQuantityMap) === 0
              }
              onAsnDetailsClick={() => redirectTo(`${LINKS.asnIndex}/${asnID}`)}
            />
            {error ? <ErrorAlert error={error} /> : ""}
            <ProductTable
              products={products}
              damaged={damagedQuantityMap}
              received={receivedQuantityMap}
              isLoading={isAsnLoading}
              animate={animate}
              setAnimate={setAnimate}
            />
          </S.LeftWrapper>
          <S.RightWrapper>
            <Scanner
              state={scanState}
              status={asn?.status}
              scannedItem={scannedItem}
              onScan={onScan}
              onBatchClick={() => setIsBatchUpdateModalVisible(true)}
              loading={updateQuantity.isLoading}
            />
          </S.RightWrapper>
        </S.Contents>

        <Popconfirm
          title="Are you sure you want to complete inspection? There is no going back after confirming."
          okText="Yes"
          cancelText="No"
          onConfirm={onComplete}
        >
          <S.ButtonWrap>
            <Button type="primary" icon={<FileDoneOutlined />}>
              Complete Inspection
            </Button>
          </S.ButtonWrap>
        </Popconfirm>
      </S.Container>
      <TimelineDrawer
        visible={isTimelineVisible}
        onClose={() => setIsTimelineVisible(false)}
        timeline={asn?.timeline}
      />
      {renderBatchUpdateModal()}
    </PageContainer>
  );
};

export default AsnProcessPage;
