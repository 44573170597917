import React, { FC, useState } from "react";
import { useParams } from "react-router";
import { useMutation, useQuery } from "react-query";
import {
  Button,
  Col,
  InputNumber,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import { Common } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import { getFulfillmentFacilityList } from "@secondcloset/fulfillment-utils/dist/lib/Facility";
import { fetchSupplyStock, updateSupplyStock } from "../../../../api/warehouse";

type FacilityCode = Common.FacilityCode;
type StockUpdateCart = {
  [key in FacilityCode]: number;
};

interface Props {
  onUpdateSuccess: () => void;
}

const SupplyStockTable: FC<Props> = ({ onUpdateSuccess }) => {
  const [stockUpdateCart, setStockUpdateCart] = useState<
    StockUpdateCart | Record<string, number>
  >();
  const [error, setError] = useState("");
  const [isInEditStockView, setIsInEditStockView] = useState(false);
  const { supplyId } = useParams<{ supplyId: string }>();

  const allSupplyStock = useQuery(
    ["fetchStock", supplyId],
    () =>
      fetchSupplyStock({
        supply_id: supplyId,
        page: "1",
        limit: "10000",
      }),
    {
      onError: (err: string) => {
        setError(err);
      },
      onSuccess: onUpdateSuccess,
    }
  );
  const { isFetching: isStockLoading, refetch: refetchStock } = allSupplyStock;

  const { mutate: onUpdateStock, isLoading: isUpdateLoading } = useMutation(
    updateSupplyStock,
    {
      onError: (errorMsg) => {
        setError(errorMsg as string);
      },
      onSuccess: () => {
        setIsInEditStockView(false);
        refetchStock({ cancelRefetch: true, throwOnError: true });
      },
    }
  );

  const handleCancel = () => {
    setError("");
    setIsInEditStockView(false);
    setStockUpdateCart({});
  };

  const handleSubmit = () => {
    if (!stockUpdateCart || !Object.entries(stockUpdateCart).length) {
      return setError("No changes were made.");
    }
    const body = Object.entries(stockUpdateCart).map(
      ([facility, changeBy]) => ({
        change_by: changeBy,
        facility,
      })
    );
    setError("");
    setStockUpdateCart({});
    onUpdateStock({ body, supplyId });
  };

  const handleQuantityChange = (
    changeBy: number,
    facilityCode: FacilityCode
  ) => {
    const newCart = { ...stockUpdateCart };
    newCart[facilityCode] = changeBy === null ? 0 : changeBy;
    setStockUpdateCart(newCart);
  };

  const renderChangeByEdit = (facility: FacilityCode) => {
    const currentValue =
      stockUpdateCart?.[facility] === undefined ? 0 : stockUpdateCart[facility];
    return (
      <InputNumber
        value={currentValue}
        onChange={(val) => handleQuantityChange(val, facility)}
      />
    );
  };

  const renderFacilityStock = (facility: FacilityCode) => {
    const currentStock = allSupplyStock.data?.items?.find(
      (i) => i.facility === facility
    );
    return <span>{currentStock?.quantity || "0"}</span>;
  };

  const buildTableColumns = () => {
    const columns = [
      {
        key: "facility",
        title: "Facility",
        dataIndex: "code",
      },
      {
        key: "quantity",
        title: "Stock Quantity",
        dataIndex: "code",
        render: renderFacilityStock,
      },
    ];

    if (isInEditStockView) {
      columns.push({
        key: "change_by",
        title: "Change By",
        dataIndex: "code",
        render: renderChangeByEdit,
      });
    }

    return columns;
  };

  const renderButtonsView = () => {
    return (
      <Row justify="end">
        <Space size="middle">
          <Button
            type="link"
            disabled={isStockLoading || isUpdateLoading}
            onClick={() => {
              setError("");
              setIsInEditStockView(true);
            }}
          >
            Edit
          </Button>
        </Space>
      </Row>
    );
  };

  const renderButtonsEdit = () => {
    return (
      <Row justify="end">
        <Col>
          <Button
            danger
            type="link"
            disabled={isStockLoading || isUpdateLoading}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            type="link"
            disabled={isStockLoading || isUpdateLoading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Col>
      </Row>
    );
  };

  const renderStockTrackingHeading = () => {
    return (
      <Row justify="space-between" align="middle">
        <Col>
          <Typography.Text strong>STOCK TRACKING</Typography.Text>
        </Col>
        <Col>
          {isInEditStockView ? renderButtonsEdit() : renderButtonsView()}
        </Col>
      </Row>
    );
  };

  return (
    <Spin spinning={isStockLoading || isUpdateLoading}>
      <ErrorAlert error={error} />
      <Table
        size="small"
        title={renderStockTrackingHeading}
        rowKey="code"
        scroll={{ x: "max-content" }}
        bordered
        columns={buildTableColumns()}
        dataSource={getFulfillmentFacilityList()}
        pagination={false}
      />
    </Spin>
  );
};

export default SupplyStockTable;
