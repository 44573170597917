import { notification, Space, Switch } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { fetchOrganizationConfigs } from "../../../../../api/warehouse/organizations";
import {
  lotNumberTrackingFlowEnabledState,
  scannerModeEnabledState,
} from "../../../../../recoil/packingFlow/atoms";
import { isEnabledScanItemsConfig } from "./helpers";
import * as S from "./styles";

interface Props {
  organizationID: string;
}

const FeatureToggles: React.FC<Props> = ({ organizationID }) => {
  const [lotNumberTrackingFlowEnabled, setLotNumberTrackingFlowEnabled] =
    useRecoilState(lotNumberTrackingFlowEnabledState);
  const [scannerModeEnabled, setScannerModeEnabled] = useRecoilState(
    scannerModeEnabledState
  );
  const { data: organizationConfigs } = useQuery(
    ["fetchOrganizationConfig"],
    () => {
      return fetchOrganizationConfigs(organizationID);
    },
    {
      enabled: !!organizationID,
      onSuccess: (configs) => {
        const scanItemsModeEnabled = isEnabledScanItemsConfig(configs);
        setScannerModeEnabled(scanItemsModeEnabled);
      },
      onError: (e: string) => {
        notification.error({ message: e });
        setScannerModeEnabled(false);
      },
      refetchOnWindowFocus: false,
    }
  );

  const buildScannerModeToggle = () => {
    const scanItemsModeEnabled = isEnabledScanItemsConfig(organizationConfigs);
    if (!scanItemsModeEnabled) return null;
    return (
      <S.ToggleWrap>
        <S.ToggleText>Scan Items Mode:</S.ToggleText>
        <Switch
          checked={scannerModeEnabled}
          onChange={(v) => setScannerModeEnabled(v)}
        />
      </S.ToggleWrap>
    );
  };

  const buildLotTrackingFlowToggle = () => {
    return (
      <S.ToggleWrap>
        <S.ToggleText>Track Lot numbers:</S.ToggleText>
        <Switch
          checked={lotNumberTrackingFlowEnabled}
          onChange={(v) => setLotNumberTrackingFlowEnabled(v)}
        />
      </S.ToggleWrap>
    );
  };

  return (
    <S.Container>
      <Space>
        {buildLotTrackingFlowToggle()}
        {buildScannerModeToggle()}
      </Space>
    </S.Container>
  );
};

export default FeatureToggles;
