import React, { useState } from "react";
import { useMutation } from "react-query";
import { Select, Button } from "antd";

import * as S from "./styles";

import { fetchOrganizations } from "../../../../api/fulfillment/organization";
import { Common } from "@secondcloset/types";
import { SelectedOrganization } from "../CreateSupplyPage";

const { Option } = Select;
export const OUR_COMPANY_ORG_NAME = "Second Closet";

interface Props {
  organization?: SelectedOrganization;
  setOrganization: (org?: SelectedOrganization) => void;
  onError: (error: string) => void;
}

const OrganizationSearchBar: React.FC<Props> = ({
  organization,
  setOrganization,
  onError,
}) => {
  const [organizations, setOrganizations] = useState<SelectedOrganization[]>(
    []
  );
  const [open, setOpen] = useState(false);
  const [typing, setTyping] = useState(false);

  const listOrganizations = (data: Common.Organization[]) => {
    const parsed: SelectedOrganization[] = data.map((org) => ({
      id: org.id,
      name: org.name,
    }));
    setOrganizations(parsed);
    setOpen(true);
  };

  const { mutate: searchOrganizations, isLoading: loadingOrganizations } =
    useMutation(fetchOrganizations, {
      onSuccess: (data: Common.Organization[]) => {
        if (!data.length) {
          onError("No organization found");
          return;
        }
        const foundOrg = data?.find((o) => o.name === OUR_COMPANY_ORG_NAME);
        if (foundOrg) {
          setOrganization({ id: foundOrg.id, name: foundOrg.name });
          return;
        }
        listOrganizations(data);
      },
      onError,
    });

  const handleSearch = (e) => {
    if (e.target.value && e.key === "Enter") {
      searchOrganizations(e.target.value);
    }
  };

  const handleSelect = (orgId: string) => {
    const orgFound = organizations.find((_) => _.id === orgId);
    if (!orgFound) return;
    setOrganization(orgFound);
  };

  const renderOrganization = () => {
    if (!organization) {
      return (
        <Select
          size="large"
          showSearch
          open={open}
          onBlur={() => setOpen(false)}
          loading={loadingOrganizations}
          placeholder={typing ? "" : "Search organization"}
          defaultActiveFirstOption={false}
          showArrow={true}
          filterOption={false}
          onInputKeyDown={handleSearch}
          onSearch={(value) => setTyping(value !== "")}
          onSelect={handleSelect}
          notFoundContent={null}
        >
          {organizations?.map((_) => (
            <Option key={_.id} value={_.id}>
              {_.name}
            </Option>
          ))}
        </Select>
      );
    } else {
      return (
        <S.OrganizationWrapper>
          <S.Label>{organization.name}</S.Label>
          <Button size="large" onClick={() => setOrganization(undefined)}>
            Change
          </Button>
        </S.OrganizationWrapper>
      );
    }
  };
  const renderSelectSC = () => {
    return (
      <S.ButtonWrapper>
        <Button
          type="link"
          loading={loadingOrganizations}
          onClick={() => searchOrganizations(OUR_COMPANY_ORG_NAME)}
          style={{ padding: 0 }}
        >
          for Bolt Logistics
        </Button>
      </S.ButtonWrapper>
    );
  };
  return (
    <div>
      {renderOrganization()}
      {organization ? null : renderSelectSC()}
    </div>
  );
};

export default OrganizationSearchBar;
