import styled from "styled-components";

interface ContainerProps {
  rightAligned?: boolean;
}

export const Container = styled.div<ContainerProps>`
  text-align: ${(props) => (props.rightAligned ? "right" : "left")};
`;

export const ScanInputLabel = styled.label`
  margin-bottom: 5px;
  font-weight: 600;
`;
