import { Facility } from "@secondcloset/fulfillment-utils";

export const STATUS_OPTIONS = [
  { key: "draft", value: "draft", label: "Draft" },
  { key: "awaiting", value: "awaiting", label: "Awaiting" },
  { key: "arrived", value: "arrived", label: "Arrived" },
  { key: "completed", value: "completed", label: "Completed" },
  { key: "on_hold", value: "on_hold", label: "On Hold" },
];

export const EXCEPTION_OPTIONS = [
  { key: "missing_date", value: "missing_date", label: "Missing Date" },
  { key: "overdue", value: "overdue", label: "Overdue" },
  { key: "under_received", value: "under_received", label: "Under Received" },
  { key: "over_received", value: "over_received", label: "Over Received" },
];

export const WAREHOUSE_OPTIONS = Facility.getFulfillmentFacilityList().map(
  (f) => ({
    key: f.code,
    value: f.code,
    label: f.name,
  })
);

const NOTION_DOCUMENT_LINK =
  "https://www.notion.so/secondcloset/Warehouse-Receiving-Guide";

export const LINKS = {
  wroIndex: "/warehouse-receiving-orders",
  asnIndex: "/asn",
  asnCreate: "/asn/create",
  receivingGuide: `${NOTION_DOCUMENT_LINK}-50bc6f469bc747628ae71d82c4005f2d`,
  whenToCreateASN: `${NOTION_DOCUMENT_LINK}-50bc6f469bc747628ae71d82c4005f2d#1fbddf2a5cf84c1db03d337178d2e351`,
  containerLearnMore: `${NOTION_DOCUMENT_LINK}-50bc6f469bc747628ae71d82c4005f2d#b8fd92fe73b44f4aa1c4f72c0d93dcab`,
};

export const ASN_RELEASE_DATE = "2021-03-01";
