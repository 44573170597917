import Title from "antd/lib/typography/Title";
import styled from "styled-components";

import { COLORS } from "../../../styles";

export const Label = styled.div`
  color: ${COLORS.GREY};
  font-size: 12px;
`;
export const Value = styled.div`
  font-size: 12px;
`;

export const ItemLabel = styled.div`
  color: ${COLORS.BLUE};
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;

  &:hover {
    text-decoration: underline;
  }
`;
