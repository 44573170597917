import { Common, Warehouse } from "@secondcloset/types";
import queryString from "query-string";
import { getAPIFromDomain } from "../../lib/api";
import { validateFacilityCode } from "../../lib/validateFacilityCode";
// Interfaces
import { PaginatedData, PaginationOptions } from "./commonInterfaces";

type FacilityCode = Common.FacilityCode;
type FacilityOrganization = Warehouse.FacilityOrganization;
type Organization = Warehouse.Organization;
type OrganizationConfig = Warehouse.OrganizationConfig;
type OrganizationConfigKey = Warehouse.OrganizationConfigKey;

export const fetchOrganizations = async (
  query?: PaginationOptions & { q?: string }
): Promise<PaginatedData<Organization>> => {
  const api = getAPIFromDomain("november");
  const url = `/organizations?${queryString.stringify(query || {})}`;
  const respond = await api.get(url);
  return respond.data as PaginatedData<Organization>;
};

export const fetchOrganizationDetails = async (
  organizationID: string
): Promise<Organization> => {
  const api = getAPIFromDomain("november");
  const url = `/organizations/${organizationID}`;
  const respond = await api.get(url);
  return respond.data as Organization;
};

export const fetchOrganizationConfigs = async (
  organizationID: string
): Promise<OrganizationConfig[]> => {
  const api = getAPIFromDomain("november");
  const url = `/organization-configs?organization_id=${organizationID}`;
  const respond = await api.get(url);
  return respond.data as OrganizationConfig[];
};

export const fetchOrganizationConfigKeys = async (): Promise<
  OrganizationConfigKey[]
> => {
  const api = getAPIFromDomain("november");
  const url = `/organization-configs/keys`;
  const respond = await api.get(url);
  return respond.data as OrganizationConfigKey[];
};

export interface UpdateOrganizationConfigBody {
  key: OrganizationConfigKey;
  value: string;
  organization_id: string;
}

export const updateOrganizationConfig = async (
  body: UpdateOrganizationConfigBody
): Promise<OrganizationConfig> => {
  const api = getAPIFromDomain("november");
  const url = `/organization-configs`;
  const respond = await api.post(url, body);
  return respond.data as OrganizationConfig;
};

export interface BatchCreateOrganizationBody {
  facility: FacilityCode;
  organization_id: string;
}

export const batchFetchOrCreateOrganizations = async (
  body: BatchCreateOrganizationBody[]
): Promise<Organization[]> => {
  const api = getAPIFromDomain("november");
  const url = `/facility-organizations/batch-create`;
  const respond = await api.post(url, { items: body });
  return respond.data as Organization[];
};

export interface FetchFacilityOrganizationsQuery {
  facility?: FacilityCode;
  organization_id?: string;
  organization_name?: string;
  with_organization?: boolean;
}

export const fetchFacilityOrganizations = async (
  query: PaginationOptions & FetchFacilityOrganizationsQuery
): Promise<PaginatedData<FacilityOrganization>> => {
  const api = getAPIFromDomain("november");
  const formattedQuery = {
    ...query,
    facility: validateFacilityCode(query?.facility),
  };
  const url = `/facility-organizations?${queryString.stringify(
    formattedQuery || {}
  )}`;
  const respond = await api.get(url);
  return respond.data as PaginatedData<FacilityOrganization>;
};

export const deleteFacilityOrganization = async (id: string): Promise<void> => {
  const api = getAPIFromDomain("november");
  const url = `/facility-organizations/${id}`;
  await api.delete(url);
};
