import React, { useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { Result, Button, Row, Col } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import PageContainer from "../../../components/PageContainer";

import { fetchManualItem } from "../../../api/warehouse";
import { MANUAL_ITEM_TYPE_OPTIONS, getManualItemLabelUrl } from "../helpers";

import * as S from "./styles";
import { Link } from "react-router-dom";

const ManualItemCreateSuccessPage: React.FC = () => {
  const history = useHistory();
  const [showResult, setShowResult] = useState<any>(false);
  const { manualItemCode } = useParams<{ manualItemCode: string }>();

  const { data: manualItem, isLoading } = useQuery(
    "manualItem",
    () => fetchManualItem(manualItemCode),
    {
      onError: () => history.push("/manual-items/404"),
      onSuccess: () => setShowResult(true),
    }
  );

  const getItemTypeLabel = (type?: string) => {
    return MANUAL_ITEM_TYPE_OPTIONS.find((e) => e.key === type)?.label;
  };

  const renderResultExtras = () => {
    return (
      <Row gutter={[8, 16]}>
        <Col xs={24}>
          <Button
            key="home"
            size="large"
            block
            onClick={() => history.replace("/manual-items")}
          >
            Back to Manual Items
          </Button>
        </Col>
        <Col xs={24}>
          <Button
            key="create"
            size="large"
            block
            onClick={() => history.push("/manual-items/create")}
          >
            Create another
          </Button>
        </Col>
        <Col xs={24}>
          <Button
            key="label"
            size="large"
            type="primary"
            block
            target="_blank"
            icon={<DownloadOutlined />}
            href={manualItem?.id && getManualItemLabelUrl(manualItem.id)}
          >
            Download Label
          </Button>
        </Col>
      </Row>
    );
  };

  const renderResult = () => {
    const subTitle = (
      <div>
        {getItemTypeLabel(manualItem?.type)}{" "}
        <Link to={`/manual-items/${manualItem?.code}`}>{manualItem?.code}</Link>{" "}
        has been created
      </div>
    );
    return (
      <Result
        status="success"
        title="Manual Item created!"
        subTitle={subTitle}
        extra={renderResultExtras()}
      />
    );
  };

  return (
    <PageContainer loading={isLoading}>
      <S.Container>{showResult && renderResult()}</S.Container>
    </PageContainer>
  );
};

export default ManualItemCreateSuccessPage;
