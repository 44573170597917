import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Result, Button, Row, Col } from "antd";
import {
  ArrowLeftOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import PageContainer from "../../../components/PageContainer";

const ViewButton = (props) => (
  <Button
    key="view"
    size="large"
    type="primary"
    block
    icon={<QuestionCircleOutlined />}
    onClick={props.onClick}
  >
    View project
  </Button>
);

const CreateButton = (props) => (
  <Button
    key="create"
    size="large"
    block
    icon={<PlusOutlined />}
    onClick={props.onClick}
  >
    Create another
  </Button>
);

const IndexButton = (props) => (
  <Button
    key="home"
    size="large"
    block
    icon={<ArrowLeftOutlined />}
    onClick={props.onClick}
  >
    Go back to Special Projects
  </Button>
);

const SpecialProjectCreateSuccessPage: React.FC = () => {
  const history = useHistory();
  const [project] = useState<any>(history.location.state);

  useEffect(() => {
    if (!project) history.push("/special-projects/create");
  }, [project, history]);

  const handleViewDetail = () =>
    history.push(`/special-projects/${project?.id}`);
  const handleCreateProject = () => history.push("/special-projects/create");
  const handleGoToProjectIndex = () => history.replace("/special-projects");

  const resultSubTitle = (
    <div>
      {project?.id ? (
        <Link to={`/special-projects/${project?.id}`}>{`${project?.id}`}</Link>
      ) : (
        "Unknown"
      )}{" "}
      has been created
    </div>
  );
  const resultExtras = (
    <Row gutter={[8, 16]} align="middle" justify="center">
      <Col xs={24}>
        <ViewButton onClick={handleViewDetail} />
      </Col>
      <Col xs={24}>
        <CreateButton onClick={handleCreateProject} />
      </Col>
      <Col xs={24}>
        <IndexButton onClick={handleGoToProjectIndex} />
      </Col>
    </Row>
  );

  return (
    <PageContainer>
      <div style={{ display: "flex", height: "100%", paddingTop: "5rem" }}>
        <Result
          status="success"
          title="Special Project created!"
          subTitle={resultSubTitle}
          extra={resultExtras}
          style={{ margin: "auto" }}
        />
      </div>
    </PageContainer>
  );
};

export default SpecialProjectCreateSuccessPage;
