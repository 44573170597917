import { APIDomain, APIVersion, setupAPI } from "@secondcloset/api-utils";
import { getBaseURL as getBasePath } from "@secondcloset/api-utils/dist/helpers";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Cookie from "./cookie";

interface HeaderTemplateProps {
  headers: {
    Accept: string;
    "Content-Type": string;
    Authorization?: string;
  };
}

const { getAPIFromDomain: getAPI } = setupAPI({
  baseURL: String(process.env.REACT_APP_API),
  getToken: Cookie.getUserToken,
});

export const getAPIFromDomain = (domain?: APIDomain, version?: APIVersion) => {
  const instance = getAPI(domain, version);
  if (domain === "november")
    instance.defaults.baseURL = process.env.REACT_APP_WAREHOUSE_API;
  return instance;
};

const getBaseUrlByDomain = (domain?: APIDomain) => {
  if (domain === "bfapi") return process.env.REACT_APP_BFAPI;
  if (domain === "november") return process.env.REACT_APP_WAREHOUSE_API;
  return process.env.REACT_APP_API;
};

export const getHeaderTemplate = (token: string): HeaderTemplateProps => {
  const header: HeaderTemplateProps = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  if (token) header.headers.Authorization = "Bearer ".concat(token);
  return header;
};

const getPDFHeader = (): AxiosRequestConfig => {
  const USER_TOKEN = Cookie.getUserToken();
  return {
    headers: {
      "Content-Type": "application/pdf",
      Authorization: "Bearer ".concat(USER_TOKEN),
    },
    responseType: "blob",
  } as AxiosRequestConfig;
};

export const downloadLabel = async (
  path: string,
  body: any,
  domain?: APIDomain
): Promise<AxiosResponse> => {
  const config = getPDFHeader();
  config.headers["Content-Type"] = "application/json"; // override to /json since request is sending json body on post method
  const baseURL = getBaseUrlByDomain(domain) as string;
  return axios
    .post(getBasePath(baseURL, domain) + path, body, config)
    .catch((e) => {
      throw typeof e === "string" ? e : e.message;
    });
};

export const getPDF = async (
  path: string,
  domain?: APIDomain
): Promise<AxiosResponse> => {
  const header = getPDFHeader();
  const baseURL = getBaseUrlByDomain(domain) as string;
  return axios.get(getBasePath(baseURL, domain) + path, header);
};
