import React, { useState } from "react";
import { ASNTable, ErrorAlert } from "@secondcloset/web-components";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { Space } from "antd";

// helpers
import { fetchASNIndex } from "../../../api/fulfillment/asn";
import PageContainer from "../../../components/PageContainer";
import useUrlState from "@ahooksjs/use-url-state";
import TableFilters from "./TableFilters";
import { ExceptionOptionKey, StatusOptionKey } from "./TableFilters/helpers";
import * as S from "./styles";

const AsnIndexPage: React.FC = () => {
  const history = useHistory();
  const [error, setError] = useState<string | string[]>("");
  const [{ page, perPage, showFilter }, setUrlState] = useUrlState(
    {
      page: 1,
      perPage: 10,
      showFilter: "true",
    },
    { navigateMode: "replace" }
  );
  const [searchedASN, setSearchedASN] = useState("");
  const [searchedSKU, setSearchedSKU] = useState("");
  const [searchedOrg, setSearchedOrg] = useState("");
  const [statusKeys, setStatusKeys] = useState<StatusOptionKey[]>([]);
  const [exceptionKeys, setExceptionKeys] = useState<ExceptionOptionKey[]>([]);
  const [facility, setFacility] = useState("all");
  const [arrivalDate, setArrivalDate] = useState("");
  const asns = useQuery(
    [
      "fetchASNs",
      page,
      perPage,
      searchedASN,
      searchedSKU,
      searchedOrg,
      statusKeys,
      exceptionKeys,
      facility,
      arrivalDate,
    ],
    () => {
      return fetchASNIndex({
        per_page: perPage,
        page: page || 1,
        ...(searchedASN && { q: searchedASN.trim() }),
        ...(searchedSKU && { sku: searchedSKU.trim() }),
        ...(searchedOrg && { organization_name: searchedOrg.trim() }),
        ...(statusKeys.length && { status: statusKeys }),
        ...(exceptionKeys.length && { exception: exceptionKeys }),
        ...(facility && facility !== "all" && { facility }),
        ...(arrivalDate && { arrival_date: arrivalDate }),
        requires: ["organization_name"],
      });
    },
    { onError: (e: string) => setError(e) }
  );

  const getASNs = () => {
    return asns.data || [];
  };

  const renderASNLink = (displayText: string, asnID: string) => {
    return (
      <S.OrgLink onClick={() => history.push(`/asn/${asnID}`)}>
        {displayText || "-"}
      </S.OrgLink>
    );
  };

  const renderOrgLink = (orgName: string, orgID: string) => {
    const sierraOrgUrl = `${process.env.REACT_APP_SIERRA_DOMAIN}/organizations/${orgID}`;
    return (
      <S.OrgLink onClick={() => window.open(sierraOrgUrl)}>{orgName}</S.OrgLink>
    );
  };

  return (
    <PageContainer
      title="Advanced Shipping Notice (ASN)"
      withPadding
      withFooter
      withHeader
    >
      <Space style={{ width: "100%" }} direction="vertical" size="large">
        <ErrorAlert error={error} />
        <TableFilters
          setSearchedASN={setSearchedASN}
          setSearchedSKU={setSearchedSKU}
          setSearchedOrg={setSearchedOrg}
          setArrivalDate={setArrivalDate}
          isFilterVisible={showFilter === "true"}
          onToggleFilterVisible={() =>
            setUrlState({
              showFilter: showFilter === "true" ? "false" : "true",
            })
          }
          facility={facility}
          setFacility={setFacility}
          exceptionKeys={exceptionKeys}
          setExceptionKeys={setExceptionKeys}
          statusKeys={statusKeys}
          setStatusKeys={setStatusKeys}
        />
        <ASNTable
          ASNList={getASNs()}
          page={page}
          pageSize={perPage}
          setPage={(p) => {
            if (+page !== p) {
              setUrlState({ page: p });
            }
          }}
          setPageSize={(newPageSize: number) => {
            if (newPageSize !== +perPage) {
              setUrlState({ perPage: newPageSize, page: 1 });
            }
          }}
          renderASNLinkComponent={renderASNLink}
          isLoading={asns.isLoading}
          showOrganizationColumn
          renderOrgLinkComponent={renderOrgLink}
          isAdmin
        />
      </Space>
    </PageContainer>
  );
};

export default AsnIndexPage;
