import { Warehouse } from "@secondcloset/types";
import queryString from "query-string";
import { getAPIFromDomain } from "../../lib/api";
import {
  PaginatedData,
  PaginationOptions,
} from "../warehouse/commonInterfaces";

type Organization = Warehouse.Organization;
export interface Product {
  id: string;
  sku?: string;
  upc?: string;
  name: string;
  organization_id: string;
  organization?: Organization;
}
interface ProductQuery {
  organization_id?: string;
  search?: string;
}
export interface UpdateProductBody {
  name?: string;
  sku?: string;
  upc?: string;
}

export const fetchProductById = async (id: string): Promise<Product> => {
  const api = getAPIFromDomain("november");
  const url = `/products/${id}`;
  const response = await api.get(url);
  return response.data as Product;
};

export const fetchProducts = async (
  query: PaginationOptions & ProductQuery
): Promise<PaginatedData<Product>> => {
  const api = getAPIFromDomain("november");
  const url = `/products?${queryString.stringify(query || {})}`;
  const response = await api.get(url);
  return response.data as PaginatedData<Product>;
};

export const updateProduct = async ({
  id,
  body,
}: {
  id: string;
  body: UpdateProductBody;
}): Promise<Product> => {
  const api = getAPIFromDomain("november");
  const url = `/products/${id}`;
  const response = await api.patch(url, body);
  return response.data as Product;
};
