import React, { useCallback, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Input,
  InputNumber,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  TablePaginationConfig,
  Typography,
} from "antd";
import { Warehouse } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";

// API
import {
  deletePalletWithLocationItems,
  fetchLocationItems,
  generatePalletLabel,
  removeItemsFromPallet,
} from "../../../api/warehouse";

// Components
import ProductIdentifiersField from "../../../components/Table/ProductIdentifiersField";
import ItemCountFooter from "../../../components/Table/ItemCountFooter";
import PageContainer from "../../../components/PageContainer";
import NullSafeText from "../../../components/Table/NullSafeText";
import WithLabel from "../../../components/WithLabel";
import useUrlState from "@ahooksjs/use-url-state";
import { QuantityItem } from "../../../api/warehouse/commonInterfaces";
import { calculateUpdatedRecords } from "./helpers";
import { buildTablePagination } from "../../../components/Pagination/helper";

const PalletDetailsPage: React.FC = () => {
  const history = useHistory();
  const [isInEditView, setIsInEditView] = useState(false);
  const [error, setError] = useState("");
  const [locationCode, setLocationCode] = useState("");
  const [quantityRecords, setQuantityRecords] = useState<QuantityItem[]>([]);
  const [initialQuantityRecords, setInitialQuantityRecords] = useState<
    QuantityItem[]
  >([]);
  const [{ search, page, perPage }, setUrlState] = useUrlState(
    {
      search: undefined,
      page: 1,
      perPage: 10,
    },
    { navigateMode: "replace" }
  );
  const { palletId } = useParams<{ palletId: string }>();
  const { mutate: printLabel, isLoading: isPrintingLabel } = useMutation(
    generatePalletLabel,
    { onError: (e: string) => setError(e) }
  );

  const { mutate: doRemoveItemsFromPallet, isLoading: isRemovingItems } =
    useMutation(removeItemsFromPallet, {
      onSuccess: (data: Warehouse.LocationItem[]) => {
        notification.success({ message: "Items successfully updated" });
        if (!data?.length) {
          history.goBack();
        } else {
          setUrlState({ search: undefined, page: 1 });
          paginatedLocationItems.refetch({
            cancelRefetch: true,
            throwOnError: true,
          });
          setIsInEditView(false);
        }
      },
      onError: (err: string) => {
        setError(err);
        setIsInEditView(false);
      },
    });
  const { mutate: doDeletePallet, isLoading: isPalletDeleting } = useMutation(
    deletePalletWithLocationItems,
    {
      onSuccess: () => {
        notification.success({ message: "Pallet successfully deleted" });
        history.goBack();
      },
      onError: (err: string) => {
        setError(err);
      },
    }
  );

  const handleSetInitialQuantityRecords = (
    locationItems: Warehouse.LocationItem[]
  ) => {
    const records = locationItems.map((item) => {
      return { location_item_id: item.id, quantity: item.quantity };
    });
    setInitialQuantityRecords(records);
    setQuantityRecords(records);
  };

  const paginatedLocationItems = useQuery(
    ["getLocationItems", search, page, perPage],
    () =>
      fetchLocationItems({
        pallet_id: palletId,
        sku: search || undefined,
        page: page,
        limit: perPage,
        sort_field: "created_at",
        sort_direction: "DESC",
      }),
    {
      onSuccess: (data) => {
        handleSetInitialQuantityRecords(data?.items);
        setLocationCode(data?.items?.[0]?.location_code);
        setError("");
      },
      onError: (error: string) => {
        setError(error);
      },
    }
  );

  const getLocationItems = useCallback((): Warehouse.LocationItem[] => {
    return paginatedLocationItems.data?.items || [];
  }, [paginatedLocationItems]);

  const handleDeletePallet = () => {
    doDeletePallet(palletId);
  };

  const handleOnCancel = () => {
    setQuantityRecords([]);
    setError("");
    setIsInEditView(false);
  };

  const handleOnSave = () => {
    const newQuantities = checkIfNewQuantities();
    if (newQuantities)
      doRemoveItemsFromPallet({
        pallet_id: palletId,
        items: quantityRecords,
      });
    else return handleOnCancel();
  };

  const checkIfNewQuantities = () => {
    for (let i = 0; i < initialQuantityRecords.length; i++) {
      if (initialQuantityRecords[i].quantity !== quantityRecords[i].quantity)
        return true;
    }
    return false;
  };

  const handleOnRemoveItems = () => {
    if (paginatedLocationItems?.data && !quantityRecords.length) {
      handleSetInitialQuantityRecords(paginatedLocationItems.data.items);
    }
    setIsInEditView(true);
  };

  const handleUpdateQuantity = (quantity: number, locationItemId: string) => {
    const newRecords = calculateUpdatedRecords(
      quantity,
      locationItemId,
      quantityRecords
    );
    setQuantityRecords(newRecords);
  };

  const renderText = (v: string) => {
    return <NullSafeText value={v} />;
  };
  const renderEditInput = (locationItemId: string, records) => {
    const quantityRecordFound = quantityRecords.find(
      (record) => record.location_item_id === locationItemId
    );
    return (
      <InputNumber
        value={quantityRecordFound?.quantity}
        min={0}
        max={records?.quantity}
        defaultValue={records?.quantity}
        onChange={(quantity: number) => {
          handleUpdateQuantity(quantity, locationItemId);
        }}
      />
    );
  };

  const renderItemDetail = (item) => {
    const sku = item?.product?.sku;
    const upc = item?.product?.upc;
    const name = item?.product?.name;
    const productId = item?.product?.id;
    const customer = item?.manual_item?.customer_name;
    const code = item?.manual_item?.code;
    return (
      <ProductIdentifiersField
        sku={sku}
        upc={upc}
        name={name}
        code={code}
        customer={customer}
        productId={productId}
      />
    );
  };

  const renderOrganization = (item) => {
    const organization = item?.product?.organization?.name;
    return <NullSafeText value={organization} />;
  };

  const buildTableColumns = () => {
    const columns = [
      {
        title: "Item",
        key: "id",
        render: renderItemDetail,
      },
      {
        title: "Organization",
        key: "customer",
        render: renderOrganization,
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        render: renderText,
      },
    ];
    const editViewColumns = [
      ...columns,
      {
        title: "New Quantity",
        dataIndex: "id",
        render: renderEditInput,
      },
    ];
    return isInEditView ? editViewColumns : columns;
  };

  const buildPagination = (): TablePaginationConfig => {
    const paginationInfo = paginatedLocationItems?.data?.meta;

    return buildTablePagination({
      paginationInfo,
      onChange: (p: number, newPageSize?: number) => {
        if (newPageSize && newPageSize !== +perPage) {
          setUrlState({ page: 1, perPage: newPageSize });
        } else {
          setUrlState({ page: p });
        }
      },
      showSizeChanger: true,
      showQuickJumper: true,
    });
  };

  const renderSearch = () => {
    return (
      <WithLabel name="Product SKU">
        <Input.Search
          size="large"
          allowClear
          placeholder="Scan a SKU"
          onSearch={(search: string) => {
            setUrlState({ search, page: 1 });
          }}
          onChange={(e) => {
            if (!e.target.value.trim())
              setUrlState({ search: undefined, page: 1 });
          }}
          loading={paginatedLocationItems.isLoading}
          autoCapitalize="off"
          enterButton
        />
      </WithLabel>
    );
  };

  const renderSaveButton = () => {
    return (
      <Button
        size="large"
        title="Save"
        block
        type="primary"
        onClick={handleOnSave}
        icon={<EditOutlined />}
      >
        Save
      </Button>
    );
  };
  const renderCancelButton = () => {
    return (
      <Button
        size="large"
        title="Cancel"
        danger
        block
        onClick={handleOnCancel}
        icon={<DeleteOutlined />}
      >
        Cancel
      </Button>
    );
  };

  const renderEditorButtonGroup = () => (
    <Row gutter={[8, 8]} justify="end" align="middle">
      <Col xs={24} lg={8}>
        {renderCancelButton()}
      </Col>
      <Col xs={24} lg={8}>
        {renderSaveButton()}
      </Col>
    </Row>
  );

  const renderDeleteButton = () => {
    return (
      <Popconfirm
        disabled={isPalletDeleting}
        placement="bottom"
        title="Are you sure you want to delete this pallet?"
        onConfirm={handleDeletePallet}
        okText="Delete"
        cancelText="No"
      >
        <Button
          size="large"
          title="Delete"
          block
          type="primary"
          danger
          icon={<DeleteOutlined />}
        >
          Delete
        </Button>
      </Popconfirm>
    );
  };

  const renderPrintLabelButton = () => {
    return (
      <Button
        size="large"
        title="Print Label"
        block
        type="primary"
        icon={<PrinterOutlined />}
        onClick={() => printLabel(palletId)}
        loading={isPrintingLabel}
        disabled={isPrintingLabel}
      >
        Label
      </Button>
    );
  };

  const renderEditItemsButton = () => {
    return (
      <Button
        size="large"
        title="Edit Items"
        block
        onClick={handleOnRemoveItems}
        icon={<EditOutlined />}
        disabled={!paginatedLocationItems?.data?.items?.length}
      >
        Edit Items
      </Button>
    );
  };

  const renderViewerButtonGroup = () => (
    <Row gutter={[8, 8]} justify="end" align="middle">
      <Col xs={24} lg={10} xl={9}>
        {renderEditItemsButton()}
      </Col>
      <Col xs={24} lg={10} xl={7}>
        {renderDeleteButton()}
      </Col>
      <Col xs={24} lg={10} xl={8}>
        {renderPrintLabelButton()}
      </Col>
    </Row>
  );

  const renderZeroQuantityAlertBanner = () => {
    const allItemsQuantityChange = quantityRecords.reduce(
      (a, b) => a + b.quantity,
      0
    );
    if (!isInEditView || allItemsQuantityChange !== 0) return;
    return (
      <Alert
        style={{ marginBottom: 20 }}
        showIcon
        type="warning"
        message="Removing all items from pallet will also delete the pallet ID"
      />
    );
  };

  const renderIncreaseQuantityInfoBanner = () => {
    if (isInEditView)
      return (
        <Alert
          message="Quantity cannot be increased above the existing amount."
          type="info"
        />
      );
  };

  const isPageLoading =
    isRemovingItems || isPalletDeleting || paginatedLocationItems.isLoading;

  const renderPageHeader = () => {
    return (
      <>
        <PageHeader
          title="Pallet Detail"
          onBack={() => window.history.back()}
        />

        <Typography.Title>{palletId || "-"}</Typography.Title>
        <Typography.Title level={4} type="secondary">
          Current location:{" "}
          {locationCode ? (
            <Link to={`/locations/${locationCode}`}>{locationCode}</Link>
          ) : (
            <Typography.Text>Unavailable</Typography.Text>
          )}
        </Typography.Title>
      </>
    );
  };

  const renderToolbar = () => {
    return (
      <Row align="bottom" justify="space-between" gutter={[8, 16]}>
        <Col xs={24} lg={12}>
          {renderSearch()}
        </Col>
        <Col xs={24} lg={8}>
          {isInEditView ? renderEditorButtonGroup() : renderViewerButtonGroup()}
        </Col>
      </Row>
    );
  };

  const renderTableFooter = () => (
    <ItemCountFooter
      count={paginatedLocationItems.data?.meta.totalItems || 0}
    />
  );

  return (
    <PageContainer withPadding>
      <Spin spinning={isPageLoading}>
        {renderPageHeader()}
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <ErrorAlert error={error} />
          {renderToolbar()}
          {renderIncreaseQuantityInfoBanner()}
          {renderZeroQuantityAlertBanner()}
          <Table
            size="small"
            bordered
            rowKey="id"
            loading={paginatedLocationItems.isLoading}
            scroll={{ x: "max-contents" }}
            footer={renderTableFooter}
            pagination={buildPagination()}
            columns={buildTableColumns()}
            dataSource={getLocationItems()}
          />
        </Space>
      </Spin>
    </PageContainer>
  );
};
export default PalletDetailsPage;
