import { Cookies as ReactCookie } from "react-cookie";
import {
  credentialCookieName,
  appSettingsCookieName,
} from "../config/cookieName";

//interface
import { Common } from "@secondcloset/types";
type ServiceArea = Common.ServiceArea;
type FacilityCode = Common.FacilityCode;
type Credential = Common.Credential;

interface AppSettings {
  facility?: FacilityCode;
  serviceArea?: ServiceArea;
}

export default class Cookie {
  static getCredential = (): Credential | undefined => {
    const cookie = new ReactCookie();
    return cookie.get(credentialCookieName);
  };

  static setCookie = (name: string, data: Credential | AppSettings | any) => {
    const cookie = new ReactCookie();
    const days = 1;
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * days);
    cookie.set(name, data, { expires });
  };

  static setCredential = (credential: Credential, rememberMe = false) => {
    Cookie.setCookie(credentialCookieName, { ...credential, rememberMe });
  };

  static removeCredential = () => {
    const cookie = new ReactCookie();
    cookie.remove(credentialCookieName);
  };

  static getUserToken = (): string => {
    try {
      const cookie = new ReactCookie();
      const credential = cookie.get(credentialCookieName);
      return credential.token.token;
    } catch (_) {
      return "";
    }
  };

  static getUserAppSettingsCookieName = (): string | undefined => {
    const userToken = Cookie.getUserToken();
    if (!userToken) return;
    return `${userToken}-${appSettingsCookieName}`;
  };

  static setAppSettings = (appSettings: AppSettings) => {
    const { facility } = appSettings;
    const cookieName = Cookie.getUserAppSettingsCookieName();
    if (!cookieName) return;
    Cookie.setCookie(cookieName, { facility });
  };

  static getAppSettings = (): AppSettings | undefined => {
    const cookieName = Cookie.getUserAppSettingsCookieName();
    if (!cookieName) return;
    const cookie = new ReactCookie();
    return cookie.get(cookieName) || {};
  };

  static setDefaultTab = (email: string, tabName: string) => {
    const cookieName = `${email}-default-tab`;
    if (!cookieName) return;
    Cookie.setCookie(cookieName, tabName);
  };

  static getDefaultTab = (email: string): string => {
    const cookieName = `${email}-default-tab`;
    const cookie = new ReactCookie();
    return cookie.get(cookieName);
  };
}
