import React from "react";
import { Link } from "react-router-dom";
import { Table, TablePaginationConfig } from "antd";
import { Warehouse } from "@secondcloset/types";

import * as S from "./styles";

type Organization = Warehouse.Organization;

interface Props {
  organizations: Organization[];
  loading: boolean;
  page: string;
  perPage: string;
  onPageChange: (page: number, newPageSize?: number) => any;
}

const OrganizationsTable: React.FC<Props> = ({
  organizations,
  loading,
  onPageChange,
  page,
  perPage,
}) => {
  const renderName = (name: string, record: Organization) => {
    return <Link to={`/organizations/${record.id}`}>{name}</Link>;
  };

  const buildColumns = () => {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: renderName,
      },
    ];
    return columns;
  };

  const buildPagination = (): TablePaginationConfig => {
    return {
      size: "default",
      showQuickJumper: true,
      showSizeChanger: true,
      current: +page,
      total: (+page + 1) * +perPage,
      pageSize: +perPage,
      onChange: onPageChange,
    };
  };

  return (
    <S.Container>
      <Table
        bordered
        size="small"
        loading={loading}
        columns={buildColumns()}
        dataSource={organizations}
        pagination={buildPagination()}
        rowKey={(r) => r.id}
      />
    </S.Container>
  );
};

export default OrganizationsTable;
