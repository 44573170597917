import { EditOutlined } from "@ant-design/icons";
import {
  BaseProductItem,
  VirtualKitItem,
} from "@secondcloset/fulfillment-utils";
import { Fulfillment } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
import { Button, Input, Row, Space } from "antd";
import Text from "antd/lib/typography/Text";
import { cloneDeep } from "lodash";
import React, { useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

// Hooks
import { useSoundEffect } from "../../../../hooks/useSoundEffect";
import {
  currentPackageItemIndexState,
  lotNumberTrackingFlowEnabledState,
  PackageType,
  packingFlowOrderState,
  packingFlowPackageContainerState,
  packingFlowPackageOrderItemIDsState,
  packingFlowPackageTypeState,
  packingFlowScannedItemCode,
  packingFlowShippingMethodState,
  packingFlowStepState,
  scannerModeEnabledState,
  shipmentSuppliesState,
  Step,
  SupplyListItem,
  supplyUsedQuantityState,
} from "../../../../recoil/packingFlow/atoms";
import {
  useBoxPackaging,
  useEditPackage,
  useResetPackingFlow,
  useUpdatePackageItems,
  useUpdateShipmentProductTrackingList,
} from "../../../../recoil/packingFlow/helpers";

// Components
import AddItemQuantityModal from "./AddItemQuantityModal";
import AddPackageItem from "./AddPackageItem";
import GenericScanner from "./GenericScanner";
import FeatureToggles from "./FeatureToggles";
import ItemTable from "./ItemTable";
import { TableItem } from "./ItemTable/ItemTable";
import LotTrackingButton from "./LotTrackingButton";

// Styles
import * as S from "./styles";

type OrderItem = Fulfillment.OrderItem;

const SelectItemStep: React.FC = () => {
  const { playSuccessSound } = useSoundEffect();
  const packingOrder = useRecoilValue(packingFlowOrderState);
  const packageType = useRecoilValue(packingFlowPackageTypeState);
  const shippingMethod = useRecoilValue(packingFlowShippingMethodState);
  const dispatchSetStep = useSetRecoilState(packingFlowStepState);
  const dispatchReset = useResetPackingFlow();
  const { updatePackageOrderItemIDs } = useEditPackage();
  const packageOrderItemIDs = useRecoilValue(
    packingFlowPackageOrderItemIDsState
  );
  const packageContainers = useRecoilValue(packingFlowPackageContainerState);
  const currentPackageItemIndex = useRecoilValue(currentPackageItemIndexState);
  const lotNumberTrackingFlowEnabled = useRecoilValue(
    lotNumberTrackingFlowEnabledState
  );
  const [shipmentSupplies, setShipmentSupplies] = useRecoilState(
    shipmentSuppliesState
  );
  const scannerModeEnabled = useRecoilValue(scannerModeEnabledState);
  const [scannedItemCode, setScannedItemCode] = useRecoilState(
    packingFlowScannedItemCode
  );
  const { removeLotTrackingFromPackage } =
    useUpdateShipmentProductTrackingList();
  const isBox = packageType === PackageType.box;
  const [showQuantityModal, setShowQuantityModal] = useState(false);
  const [scannedItemCodeDisplay, setScannedItemCodeDisplay] = useState("");
  const [selectedItem, setSelectedItem] = useState<
    BaseProductItem | VirtualKitItem
  >();
  const scannerRef = useRef<Input>(null);
  const currentPackageSupplies: SupplyListItem[] =
    shipmentSupplies[`${currentPackageItemIndex}`] || [];
  const packageCount = packageContainers.length;

  const {
    calculateUnpackedOrderItems,
    calculateShippableOrderItemIds,
    calculatePackedTableItems,
    calculateUnpackedTableItems,
  } = useUpdatePackageItems();
  const { checkIsAddedItemsTableEmpty } = useBoxPackaging();
  const toBeAddedItems = calculateUnpackedTableItems();
  const addedItems = calculatePackedTableItems();
  const shippableOrderItemIds = calculateShippableOrderItemIds();
  const unpackedOrderItems = calculateUnpackedOrderItems();
  const [scannerError, setScannerError] = useState("");

  const [supplyUsedQuantity, setSupplyUsedQuantity] = useRecoilState(
    supplyUsedQuantityState
  );

  const getKitItemIDsToPack = (item: VirtualKitItem, quantity = 1) => {
    const kit = unpackedOrderItems.find(
      (si) => si.product.id === item.id
    ) as OrderItem;
    const kitRequiredParts = kit?.product?.required_parts;
    const baseProductsInKit = item?.base_products || [];
    const kitItemIDs = baseProductsInKit.reduce(
      (acc: string[], i: BaseProductItem) => {
        const requiredQuantity = kitRequiredParts?.[i.id] ?? 1;
        const numberOfBaseProducts = quantity * requiredQuantity;
        const orderItemIDs = i.orderItemIDs.slice(0, numberOfBaseProducts);
        return [...acc, ...orderItemIDs];
      },
      []
    );
    return kitItemIDs;
  };

  const renderTableFooter = () => {
    return <S.TableFooter>{renderAddedItemsCount()}</S.TableFooter>;
  };

  const renderAddedItemsCount = () => {
    const itemCount =
      packageOrderItemIDs?.[currentPackageItemIndex]?.orderItemIDs?.length || 0;
    return <S.ItemCount>{`${itemCount} Item(s) Added`}</S.ItemCount>;
  };

  const getUpdatedOrderItemIds = (
    orderItemIds: string[],
    operation: "add" | "replace"
  ) => {
    if (operation === "add") {
      const currentIDs =
        packageOrderItemIDs[currentPackageItemIndex]?.orderItemIDs || [];
      return [...currentIDs, ...orderItemIds];
    }
    if (operation === "replace") return orderItemIds;
    return [];
  };

  const onAddItemsButtonClick = (item: VirtualKitItem | BaseProductItem) => {
    const shouldShowModal =
      lotNumberTrackingFlowEnabled ||
      (!scannedItemCode && item.qty > 1 && !scannerModeEnabled);

    if (shouldShowModal) {
      setSelectedItem(item);
      setShowQuantityModal(true);
    } else {
      const isVKItem = !item?.isBase;
      const orderItemIds = isVKItem
        ? getKitItemIDsToPack(item, 1)
        : [item.orderItemIDs[0]];
      const updatedOrderItemIds = getUpdatedOrderItemIds(orderItemIds, "add");
      updatePackageOrderItemIDs(updatedOrderItemIds);
    }

    setScannedItemCode("");
    setScannedItemCodeDisplay("");
  };

  const renderAllPackButton = () => {
    const onPackAllButtonClick = () => {
      setScannerError("");
      const orderItemIds = unpackedOrderItems
        .filter(
          (item) =>
            item.product?.product_type !== "kit" &&
            shippableOrderItemIds?.includes(item.id)
        )
        .map((item) => item.id);
      const updatedOrderItemIds = getUpdatedOrderItemIds(orderItemIds, "add");
      updatePackageOrderItemIDs(updatedOrderItemIds);
      playSuccessSound();
    };
    return (
      <Button type="primary" size="small" onClick={onPackAllButtonClick}>
        Pack All
      </Button>
    );
  };

  const renderShippableItems = () => {
    return (
      <S.ListContainer>
        <S.ListTitle>
          <S.IconWrap>
            <S.EnvironmentFilled /> ITEMS TO BE PACKED
          </S.IconWrap>
          {!scannerModeEnabled && renderAllPackButton()}
        </S.ListTitle>
        {
          <ItemTable
            dataSource={toBeAddedItems || []}
            actionButtonText="ADD"
            onActionButtonClick={onAddItemsButtonClick}
            onErrorModalClose={() => setScannedItemCode("")}
          />
        }
      </S.ListContainer>
    );
  };

  const onRemoveItemsClick = (item: BaseProductItem) => {
    const newOrderItemIds = packageOrderItemIDs[
      currentPackageItemIndex
    ]?.orderItemIDs?.filter((id) => !item.orderItemIDs.includes(id));
    const updatedOrderItemIds = getUpdatedOrderItemIds(
      newOrderItemIds,
      "replace"
    );

    updatePackageOrderItemIDs(updatedOrderItemIds);
    removeLotTrackingFromPackage(currentPackageItemIndex, item.id);
  };

  const onRemoveSupplyClick = (supplyToRemove: SupplyListItem) => {
    // Remove the supply from current package
    const cloneCurrentPackageSupplies = cloneDeep(currentPackageSupplies);
    const newShipmentSupplies = cloneDeep(shipmentSupplies);

    // Remove supply from current package
    const newCurrentPackageSupplies = cloneCurrentPackageSupplies?.filter(
      (supply) => supply?.id !== supplyToRemove?.id
    );
    // Update shipment supplies
    newShipmentSupplies?.splice(
      currentPackageItemIndex,
      1,
      newCurrentPackageSupplies
    );
    setShipmentSupplies(newShipmentSupplies);

    // Update stock quantity
    const newStockValue =
      (supplyUsedQuantity?.[supplyToRemove.id] || 0) + supplyToRemove.quantity;
    setSupplyUsedQuantity({ [supplyToRemove.id]: newStockValue });
  };

  const renderAddedItems = () => {
    const isTableEmpty = checkIsAddedItemsTableEmpty();

    const allAddedItems = [
      ...addedItems,
      ...currentPackageSupplies,
    ] as TableItem[];

    return (
      <S.ListContainer>
        <S.ListTitle>
          <AddPackageItem />
        </S.ListTitle>
        {isTableEmpty ? (
          <S.EmptyContainer>Add Items, Packaging, or Supplies</S.EmptyContainer>
        ) : (
          <ItemTable
            dataSource={allAddedItems || []}
            actionButtonText="REMOVE"
            onActionButtonClick={onRemoveItemsClick}
            onRemoveSupplyClick={onRemoveSupplyClick}
            onErrorModalClose={() => {}}
          />
        )}
        {renderTableFooter()}
      </S.ListContainer>
    );
  };

  const renderQuantityModal = () => {
    return (
      <AddItemQuantityModal
        visible={showQuantityModal}
        setVisible={setShowQuantityModal}
        selectedItem={selectedItem}
        onSubmit={(itemQuantity: number) => {
          if (!selectedItem) return;
          const isVKItem = !selectedItem?.isBase;
          const orderItemIds = isVKItem
            ? getKitItemIDsToPack(selectedItem, itemQuantity)
            : selectedItem?.orderItemIDs?.slice(0, itemQuantity) || [];
          const updatedOrderItemIds = getUpdatedOrderItemIds(
            orderItemIds,
            "add"
          );
          updatePackageOrderItemIDs(updatedOrderItemIds);
          setShowQuantityModal(false);
          setTimeout(() => scannerRef?.current?.focus(), 300);
        }}
      />
    );
  };

  const buildShippingMethod = () => {
    if (packageCount > 6) return <div />;
    return (
      <S.ShipmentMethodWrapper>
        <Space style={{ width: "100%" }}>
          <Text>
            Shipment Method: <strong>{shippingMethod?.toUpperCase()}</strong>
          </Text>
          <Button
            size="small"
            type="link"
            icon={<EditOutlined />}
            onClick={() => {
              dispatchReset({ excludeStep: true });
              dispatchSetStep(Step.selectShipmentMethod);
            }}
          >
            Edit
          </Button>
        </Space>
      </S.ShipmentMethodWrapper>
    );
  };

  const renderShippingMethodAndLotTracking = () => {
    return (
      <S.LotTrackingWrap>
        <LotTrackingButton />
      </S.LotTrackingWrap>
    );
  };

  const buildMaxPackageText = () => {
    return (
      <S.MaxPackageCountText>{`Max ${isBox ? 35 : 6} ${
        isBox ? "Boxes" : "Pallets"
      }`}</S.MaxPackageCountText>
    );
  };

  const renderGenericScanner = () => {
    return (
      <GenericScanner
        scannerRef={scannerRef}
        setScannerError={setScannerError}
        setScannedItemCodeDisplay={setScannedItemCodeDisplay}
        scannedItemCodeDisplay={scannedItemCodeDisplay}
        onAddItemsButtonClick={onAddItemsButtonClick}
      />
    );
  };

  return (
    <S.Container>
      <Row justify="space-between" style={{ marginTop: 20 }}>
        <FeatureToggles organizationID={packingOrder?.organization?.id || ""} />
        {buildMaxPackageText()}
      </Row>
      {buildShippingMethod()}
      {renderShippingMethodAndLotTracking()}
      <ErrorAlert error={scannerError} />
      {renderGenericScanner()}
      <Row>
        {renderShippableItems()}
        {renderAddedItems()}
      </Row>
      {renderQuantityModal()}
    </S.Container>
  );
};

export default SelectItemStep;
