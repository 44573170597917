import { Layout } from "antd";
import styled from "styled-components";

import { LAYOUT } from "../styles";

export const AppContent = styled(Layout.Content)`
  position: relative;
  padding-top: ${LAYOUT.HEADER_HEIGHT};
  min-height: calc(100vh - ${LAYOUT.FOOTER_HEIGHT});
  background-color: #fefefe;
`;
