import React, { useRef, useEffect, useState } from "react";

import ScanModal from "../ScanModal";

import { Typography, Input, Button, Space, Spin } from "antd";

import * as S from "./styles";

import { ScanStateType } from "../AsnProcessPage";
import { Fulfillment } from "@secondcloset/types";
import {
  BarcodeOutlined,
  ExclamationOutlined,
  RotateLeftOutlined,
} from "@ant-design/icons";
import { COLORS } from "../../../../styles";

interface Props {
  state: ScanStateType;
  status?: Fulfillment.ASNStatus;
  onScan: (identifier: string, operation: string) => void;
  scannedItem: string;
  onBatchClick: () => void;
  loading: boolean;
}

const { Text } = Typography;

const Scanner: React.FC<Props> = ({
  state,
  status,
  onScan,
  scannedItem,
  onBatchClick,
  loading,
}) => {
  const inputRef = useRef<Input | null>(null);
  const [isScanDamagedVisible, setIsScanDamagedVisible] = useState(false);
  const [isScanReduceVisible, setIsScanReduceVisible] = useState(false);

  const autoFocus = () => {
    if (!isScanDamagedVisible && !isScanReduceVisible)
      return inputRef?.current?.focus();
  };

  useEffect(autoFocus, [isScanDamagedVisible, isScanReduceVisible]);

  const renderMessage = () => {
    switch (state) {
      case "idle":
        return <Text>Begin Scanning</Text>;
      case "success":
        return (
          <Text>
            Scanned <br /> {scannedItem}
          </Text>
        );
      case "error":
        return (
          <Text>
            Item {scannedItem} <br /> Not on list
          </Text>
        );
    }
  };

  const handleScan = (e: any): void => {
    onScan(e.target.value, "scanOne");
    inputRef?.current?.setValue("");
  };

  const renderDamagedItemModal = () => {
    const handleDamage = (identifier: string) => {
      setIsScanDamagedVisible(false);
      return onScan(identifier, "scanDamagedOne");
    };
    return (
      <ScanModal
        title="Scan UPC of Damaged Item"
        visible={isScanDamagedVisible}
        onClose={() => setIsScanDamagedVisible(false)}
        onSubmit={handleDamage}
      />
    );
  };

  const renderReduceItemModal = () => {
    const handleReduce = (identifier: string, option?: string) => {
      setIsScanReduceVisible(false);
      const operation =
        option === "Received" ? "reduceReceivedOne" : "reduceDamagedOne";
      return onScan(identifier, operation);
    };
    return (
      <ScanModal
        title={"Scan UPC to be reduced"}
        visible={isScanReduceVisible}
        hasSwitch={true}
        switchOptions={["Received", "Damaged"]}
        onClose={() => setIsScanReduceVisible(false)}
        onSubmit={handleReduce}
      />
    );
  };

  const renderInputBar = () => {
    return (
      <S.InputWrap>
        <S.InputTitleWrap>
          <Typography.Title level={5}>Scan SKU/UPC</Typography.Title>
          <S.BatchUpdateButton onClick={onBatchClick}>
            batch?
          </S.BatchUpdateButton>
        </S.InputTitleWrap>
        <Input
          allowClear
          autoFocus
          disabled={status === "draft"}
          placeholder="Scan item code"
          ref={inputRef}
          onPressEnter={(e) => handleScan(e)}
          suffix={<BarcodeOutlined style={{ color: COLORS.GREY }} />}
        />
      </S.InputWrap>
    );
  };

  return (
    <S.Container>
      <div style={{ width: "100%" }}>
        <Spin spinning={loading}>
          <S.Indicator state={state}>
            <S.Label level={5} state={state}>
              {renderMessage()}
            </S.Label>
          </S.Indicator>
        </Spin>
      </div>
      <S.Wrapper>
        <Space direction="vertical" size="middle">
          {renderInputBar()}
          <Button
            danger
            disabled={status === "draft"}
            onClick={() => setIsScanDamagedVisible(true)}
            icon={<ExclamationOutlined />}
            style={{ width: "100%" }}
          >
            Scan Damaged Item
          </Button>
          <Button
            disabled={status === "draft"}
            onClick={() => setIsScanReduceVisible(true)}
            icon={<RotateLeftOutlined />}
            style={{ width: "100%" }}
          >
            Reduce Item
          </Button>
        </Space>
      </S.Wrapper>
      {renderDamagedItemModal()}
      {renderReduceItemModal()}
    </S.Container>
  );
};

export default Scanner;
