import React from "react";

// components
import { Select } from "antd";

// helper
import { Facility } from "@secondcloset/fulfillment-utils";

// types
import { Common } from "@secondcloset/types";
import WithLabel from "../../../../components/WithLabel";
type FacilityCode = Common.FacilityCode;

interface Props {
  selectedFacility?: FacilityCode;
  onSelectFacility: (facility: FacilityCode) => void;
}

const StandardOptions: React.FC<Props> = ({
  selectedFacility,
  onSelectFacility,
}) => {
  const buildFacilityOptions = () => {
    const facilities = Facility.getFulfillmentFacilityList();
    return facilities.map((facility) => {
      return (
        <Select.Option key={facility.code} value={facility.code}>
          {facility.code}
        </Select.Option>
      );
    });
  };

  return (
    <WithLabel name="Facility">
      <Select
        size="large"
        style={{ width: "100%" }}
        value={selectedFacility}
        placeholder="Select Facility"
        onSelect={(code) => onSelectFacility(code)}
      >
        {buildFacilityOptions()}
      </Select>
    </WithLabel>
  );
};

export default StandardOptions;
