import React from "react";
import { version } from "../../../package.json";

//styles
import * as S from "./styles";

const AppFooter: React.FC = () => {
  return (
    <S.Footer>
      {`Version ${version} - Bolt Logistics © 2021. All rights reserved`}
    </S.Footer>
  );
};

export default AppFooter;
