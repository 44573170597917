import { ReloadOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";
import {
  Button,
  notification,
  Row,
  Space,
  Table,
  TablePaginationConfig,
} from "antd";
import moment from "moment";
import { startCase, toUpper } from "lodash-es";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { FixedType } from "rc-table/lib/interface";

import { PaginationInfo } from "../../../../api/warehouse/commonInterfaces";
import { generatePicksheetRequestPdf } from "../../../../api/warehouse/picksheet";
import { buildTablePagination } from "../../../../components/Pagination/helper";
import ItemCountFooter from "../../../../components/Table/ItemCountFooter";
import NullSafeDate from "../../../../components/Table/NullSafeDate";
import NullSafeText from "../../../../components/Table/NullSafeText";
import FailedReasonsModal from "../../FailedReasonsModal";
import {
  findAndRemoveIdFromList,
  updateIdsListWithNewId,
} from "../../PrintPicksheetPage/helpers";
import { ALLOWED_FILTERS, PicksheetStatus } from "./helpers";
import * as S from "./styles";

type PicksheetRequest = Warehouse.PicksheetRequest;
type Picksheet = Warehouse.Picksheet;

interface Props {
  paginationInfo?: PaginationInfo;
  picksheetRequests: PicksheetRequest[];
  isRefreshButtonDisabled: boolean;
  setError: (error: string) => void;
  onChangePage: (page: number, pageSize?: number) => void;
  onRefreshButtonClick: () => void;
}

const RequestsTable: React.FC<Props> = ({
  paginationInfo,
  picksheetRequests,
  isRefreshButtonDisabled,
  setError,
  onChangePage,
  onRefreshButtonClick,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [requestId, setRequestId] = useState<string>("");
  const [pdfIdsBeingDownloaded, setPdfIdsBeingDownloaded] = useState<string[]>(
    []
  );

  const { mutate: onGeneratePicksheetRequestPdf } = useMutation(
    generatePicksheetRequestPdf,
    {
      onMutate: (picksheetId) => {
        const newPdfsBeingDownloaded = updateIdsListWithNewId(
          pdfIdsBeingDownloaded,
          picksheetId
        );
        setPdfIdsBeingDownloaded(newPdfsBeingDownloaded);
      },
      onError: (e, picksheetId: string) => {
        setError(e as string);

        const newPdfsBeingDownloaded = findAndRemoveIdFromList(
          pdfIdsBeingDownloaded,
          picksheetId
        );
        setPdfIdsBeingDownloaded(newPdfsBeingDownloaded);
      },
      onSuccess: (_, picksheetId: string) => {
        const newPdfsBeingDownloaded = findAndRemoveIdFromList(
          pdfIdsBeingDownloaded,
          picksheetId
        );
        setPdfIdsBeingDownloaded(newPdfsBeingDownloaded);
      },
      onSettled: (response) => {
        if (response?.status === 204) {
          notification.info({
            message: "This request has no orders to print",
          });
        }
      },
    }
  );

  const isPdfDownloading = (picksheetId: string) => {
    return pdfIdsBeingDownloaded?.includes(picksheetId);
  };
  const renderStatus = (status: string) => {
    return (
      <S.StatusWrapper>
        <S.Status status={status}>{startCase(status)}</S.Status>
      </S.StatusWrapper>
    );
  };

  const renderDate = (date: string) => <NullSafeDate date={date} />;

  const renderSuccessfulOrders = (picksheets?: Picksheet[]) => {
    const calculateSuccessfulOrders = picksheets?.filter(
      (ps) => !ps.failed_reason
    );

    return <NullSafeText value={`${calculateSuccessfulOrders?.length || 0}`} />;
  };

  const renderFilters = (filters: Record<string, string>[]) => {
    return (
      <div>
        {filters?.map(({ key, id, value }) => {
          let mVal = value;
          const mKey = key.toLowerCase();
          if (!ALLOWED_FILTERS.includes(mKey)) return null;
          if (mKey === "date_from" || mKey === "date_to") {
            mVal = moment(mVal).format("lll").toString();
          }
          return (
            <Row key={id}>
              <Space>
                <S.Label>{toUpper(startCase(mKey))}:</S.Label>
                <S.Value>{mVal}</S.Value>
              </Space>
            </Row>
          );
        })}
      </div>
    );
  };

  const renderFailedOrders = (_, record: PicksheetRequest) => {
    const failedOrderCount =
      record?.picksheets?.filter((ps) => !!ps.failed_reason)?.length || 0;

    if (!failedOrderCount) return "-";
    const onFailedOrdersClick = () => {
      setRequestId(record?.id);
      setIsModalVisible(true);
    };

    return (
      <Button
        type="link"
        style={{ padding: 0 }}
        onClick={() => onFailedOrdersClick()}
        danger
      >
        {`View ${failedOrderCount} failed order`}
      </Button>
    );
  };

  const renderDownloadButton = (
    picksheetRequestId: string,
    record: PicksheetRequest
  ) => {
    const { status } = record;
    if (!record.number_of_orders) return "-";
    return (
      <Button
        type="link"
        style={{ padding: 0 }}
        loading={isPdfDownloading(picksheetRequestId)}
        onClick={() => onGeneratePicksheetRequestPdf(picksheetRequestId)}
        disabled={status !== PicksheetStatus.COMPLETED}
      >
        {isPdfDownloading(picksheetRequestId) ? "Downloading" : "Download"}
      </Button>
    );
  };

  const getPagination = (): TablePaginationConfig => {
    return buildTablePagination({
      paginationInfo,
      showQuickJumper: true,
      showSizeChanger: true,
      onChange: onChangePage,
    });
  };

  const renderTableFooter = () => (
    <ItemCountFooter count={picksheetRequests?.length || 0} />
  );

  const buildTableColumns = () => {
    return [
      {
        title: "Date",
        dataIndex: "created_at",
        width: 180,
        render: renderDate,
        fixed: "left" as FixedType,
      },
      {
        title: "Requested By",
        dataIndex: "requested_by",
        width: 220,
        ellipsis: true,
      },
      {
        title: "Facility",
        dataIndex: "facility",
        width: 70,
      },
      {
        title: "# of Pick Sheets",
        dataIndex: "picksheets",
        render: renderSuccessfulOrders,
        width: 70,
      },
      {
        title: "Download",
        dataIndex: "id",
        width: 100,
        render: renderDownloadButton,
      },
      {
        title: "Applied Filters",
        dataIndex: "picksheet_request_filters",
        width: 220,
        render: renderFilters,
      },
      {
        title: "Failed Orders",
        dataIndex: "failed_orders",
        width: 140,
        render: renderFailedOrders,
      },
      {
        title: "Status",
        dataIndex: "status",
        width: 100,
        render: renderStatus,
      },
    ];
  };

  const renderTableHeader = () => {
    return (
      <Row align="middle" justify="space-between">
        <b>Pick Sheet Requests</b>
        <Button
          type="link"
          disabled={isRefreshButtonDisabled}
          onClick={onRefreshButtonClick}
        >
          <ReloadOutlined />
          Refresh
        </Button>
      </Row>
    );
  };

  return (
    <div>
      <Table
        title={renderTableHeader}
        size="small"
        scroll={{ x: "max-content" }}
        bordered
        dataSource={picksheetRequests}
        columns={buildTableColumns()}
        rowKey="id"
        pagination={getPagination()}
        footer={renderTableFooter}
      />
      {isModalVisible && (
        <FailedReasonsModal
          picksheetRequestId={requestId}
          visible={isModalVisible}
          toggleVisible={setIsModalVisible}
        />
      )}
    </div>
  );
};

export default RequestsTable;
