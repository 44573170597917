import React, { useState } from "react";
import { Modal, Input } from "antd";
import moment from "moment";
import * as S from "./styles";
import { Fulfillment } from "@secondcloset/types";
type ASN = Fulfillment.ASN;

interface Props {
  asn?: ASN;
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (boxes: string, pallets: string) => void;
}

const ConfirmArrivalModal: React.FC<Props> = ({
  asn,
  isVisible,
  onClose,
  onSubmit,
}) => {
  const [boxNumber, setBoxNumber] = useState("");
  const [palletNumber, setPalletNumber] = useState("");

  const renderInput = (onValueChange: (value: string) => void) => {
    return (
      <S.InputWrapper>
        <Input
          placeholder="Large Input"
          onChange={(e) => onValueChange(e.target.value)}
        ></Input>
        <S.Label>Optional</S.Label>
      </S.InputWrapper>
    );
  };

  const renderPalletInput = () => {
    if (asn?.shipment_type === "pallet") {
      return (
        <S.Card>
          <S.Label>Total number of Pallets Processed</S.Label>
          {renderInput(setPalletNumber)}
        </S.Card>
      );
    }
  };

  const handleSubmit = () => {
    onSubmit(boxNumber, palletNumber);
  };

  return (
    <Modal visible={isVisible} onCancel={onClose} footer={null}>
      <S.Container direction="vertical" size="middle">
        <S.HeaderTitle>Mark as Arrived</S.HeaderTitle>
        <S.Card>
          <S.Label>Arrival Date</S.Label>
          {moment().format("dddd, LL")}
        </S.Card>
        <S.Card>
          <S.Label>Total number of Boxes Processed</S.Label>
          {renderInput(setBoxNumber)}
        </S.Card>
        {renderPalletInput()}
        <S.Button type="primary" onClick={handleSubmit}>
          Confirm Arrival
        </S.Button>
      </S.Container>
    </Modal>
  );
};

export default ConfirmArrivalModal;
