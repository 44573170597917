import { Warehouse } from "@secondcloset/types";
import queryString from "query-string";
import { getAPIFromDomain } from "../../lib/api";
import { validateFacilityCode } from "../../lib/validateFacilityCode";
import { PaginatedData, PaginationOptions } from "./commonInterfaces";

type CarrierTransferPallet = Warehouse.CarrierTransferPallet;

interface Query {
  q: string;
}

const basePath = "carrier-transfer-pallets";

export const fetchCarrierTransferPallets = async (
  query: PaginationOptions & Partial<CarrierTransferPallet> & Partial<Query>
): Promise<PaginatedData<CarrierTransferPallet>> => {
  const api = getAPIFromDomain("november");
  const formattedQuery = {
    ...query,
    facility: validateFacilityCode(query?.facility),
  };
  const url = `/${basePath}?${queryString.stringify(formattedQuery || {})}`;
  const response = await api.get(url);
  return response.data as PaginatedData<CarrierTransferPallet>;
};

export const fetchCarrierTransferPalletsById = async (
  id: string,
  query: PaginationOptions & Partial<CarrierTransferPallet> & Partial<Query>
): Promise<CarrierTransferPallet> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/${id}?${queryString.stringify(query || {})}`;
  const response = await api.get(url);
  return response.data as CarrierTransferPallet;
};

export const createCarrierTransferPallet = async (
  body: Partial<CarrierTransferPallet>
): Promise<CarrierTransferPallet> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}`;
  const respond = await api.post(url, body);
  return respond.data as CarrierTransferPallet;
};

export const updateCarrierTransferPallet = async (
  id: string,
  body: Partial<CarrierTransferPallet>
): Promise<CarrierTransferPallet> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/${id}`;
  const respond = await api.put(url, body);
  return respond.data as CarrierTransferPallet;
};

export const deleteCarrierTransferPallet = async (
  id: string
): Promise<void> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/${id}`;
  await api.delete(url);
};
