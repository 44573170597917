import { useState, useEffect, SetStateAction } from "react";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

interface FieldsToBeUpdated {
  [key: string]: any;
}

export const useBatchUpdateUrlState = (
  initialState?: FieldsToBeUpdated
): ((fieldsToBeUpdated: FieldsToBeUpdated) => void) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (initialState) {
      const params = queryString.parse(location.search, {
        parseNumbers: true,
        parseBooleans: true,
      });
      Object.entries(initialState).forEach(([key, value]) => {
        if (params[key]) return;
        params[key] = value;
      });
      const query = queryString.stringify(params, {
        skipEmptyString: true,
      });
      history.replace(`${location.pathname}?${query}`);
    }
    /*eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (fieldsToBeUpdated: FieldsToBeUpdated): void => {
    const params = queryString.parse(location.search, {
      parseNumbers: true,
      parseBooleans: true,
    });
    Object.entries(fieldsToBeUpdated).map(([key, value]) => {
      params[key] = value;
      return null;
    });

    const query = queryString.stringify(params, {
      skipEmptyString: true,
    });

    history.replace(`${location.pathname}?${query}`);
  };
};

const useURLState = (key: string, initialValue?: any) => {
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });
  const defaultValue = params[key] ? params[key] : initialValue;
  const [value, setValue] = useState<any>(defaultValue);

  useEffect(() => {
    if (value) params[key] = value;
    else delete params[key];
    const query = queryString.stringify(params, {
      skipEmptyString: true,
    });
    history.replace(`${location.pathname}?${query}`);
    /*eslint-disable react-hooks/exhaustive-deps */
  }, [value]);

  useEffect(() => {
    const newValue = params[key];
    if (newValue !== value) setValue(newValue);
  }, [params]);

  return [value, setValue] as [any, SetStateAction<any>];
};

export default useURLState;
