import styled from "styled-components";

import { COLORS } from "../../../styles";

export const Label = styled.div`
  color: ${COLORS.GREY};
  font-size: 12px;
`;
export const Value = styled.div`
  font-size: 12px;
`;
