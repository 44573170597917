import {
  BaseProductItem,
  VirtualKitItem,
} from "@secondcloset/fulfillment-utils";
import { Warehouse } from "@secondcloset/types";
import { Button, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

// Hooks
import { useSoundEffect } from "../../../../../hooks/useSoundEffect";
import { packingFlowScannedItemCode } from "../../../../../recoil/packingFlow/atoms";
import {
  useBoxPackaging,
  useUpdatePackageItems,
} from "../../../../../recoil/packingFlow/helpers";
import {
  checkSupplyIsBox,
  checkSupplyIsPackage,
} from "../AddPackageItem/helpers";

// Components
import ScannerInput from "../../../../../components/ScannerInput";

// Types
type Supply = Warehouse.Supply;

interface Props {
  scannedItemCodeDisplay: string;
  scannerRef?: React.Ref<Input>;
  setScannedItemCodeDisplay: (val: string) => void;
  setScannerError: (val: string) => void;
  onAddItemsButtonClick: (item: VirtualKitItem | BaseProductItem) => void;
}

const GenericScanner: React.FC<Props> = ({
  scannedItemCodeDisplay,
  scannerRef,
  setScannedItemCodeDisplay,
  onAddItemsButtonClick,
  setScannerError,
}) => {
  const { getSupplyByCode } = useBoxPackaging();

  const { calculateUnpackedTableItems } = useUpdatePackageItems();
  const { playSuccessSound, playFailedSound } = useSoundEffect();
  const [scannedItemCode, setScannedItemCode] = useRecoilState(
    packingFlowScannedItemCode
  );
  const toBeAddedItems = calculateUnpackedTableItems();

  const [loading, setLoading] = useState(false);
  const resetScannedCode = () => {
    setScannedItemCode("");
    setScannedItemCodeDisplay("");
  };

  useEffect(() => {
    if (scannedItemCode) {
      setScannerError("");
      const asyncCheckScannedCode = async () => {
        setLoading(true);
        for (let i = 0; i < toBeAddedItems?.length; i++) {
          const item = toBeAddedItems?.[i];
          if (item?.sku === scannedItemCode || item?.upc === scannedItemCode) {
            setLoading(false);
            onAddItemsButtonClick(item);
            playSuccessSound();
            return;
          }

          const baseProducts = item?.base_products || [];
          const foundBaseProduct = baseProducts.find(
            (bp) => bp?.sku === scannedItemCode || bp?.upc === scannedItemCode
          );

          if (foundBaseProduct) {
            setLoading(false);
            onAddItemsButtonClick(foundBaseProduct);
            playSuccessSound();
            return;
          }
        }
        try {
          const supply = (await getSupplyByCode()) as Supply;
          const isSupply = checkSupplyIsPackage(supply);
          const isBox = checkSupplyIsBox(supply);
          if (isSupply || isBox) {
            playSuccessSound();
            resetScannedCode();
            const ref = scannerRef as any;
            if (ref) {
              setTimeout(() => {
                ref.current?.focus();
              }, 300);
            }
          }
          setLoading(false);
        } catch (error: any) {
          playFailedSound();
          setScannerError(
            error?.message || "No item or packaging found with this code"
          );
          resetScannedCode();
          setLoading(false);
          const ref = scannerRef as any;
          if (ref) {
            setTimeout(() => {
              ref.current?.focus();
            }, 300);
          }
        }
      };
      asyncCheckScannedCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannedItemCode]);

  const renderScanner = () => {
    return (
      <ScannerInput
        scannerRef={scannerRef}
        size="large"
        onEnter={setScannedItemCode}
        autoFocus
        value={scannedItemCodeDisplay}
        onChange={setScannedItemCodeDisplay}
        containerStyles={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          marginRight: 20,
          alignItems: "center",
        }}
        loading={loading}
      />
    );
  };

  const renderClearInputButton = () => {
    return (
      <Button
        onClick={() => setScannedItemCodeDisplay("")}
        size="large"
        style={{ display: "flex", flex: 0, marginRight: 10 }}
      >
        Clear
      </Button>
    );
  };

  return (
    <Row style={{ marginRight: 40 }}>
      <Row align="middle" style={{ display: "flex", flex: 1 }}>
        {renderScanner()}
        {renderClearInputButton()}
      </Row>
      <div style={{ display: "flex", flex: 1 }} />
    </Row>
  );
};

export default GenericScanner;
