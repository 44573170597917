import styled from "styled-components";
import colors from "../../../styles/colors";

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Status = styled.span<{ status: string }>`
  color: ${({ status }) => {
    switch (status) {
      case "success":
        return colors.GREEN;
      case "in_progress":
        return colors.ORANGE;
      case "failed":
        return colors.RED;
      default:
        return colors.GREY;
    }
  }};
`;

export const Cell = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-overflow: clip;
    white-space: normal;
  }
`;
