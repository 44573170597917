import styled from "styled-components";
import colors from "../../../styles/colors";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.span`
  margin-bottom: 5px;
`;
