import {
  BaseProductItem,
  VirtualKitItem,
} from "@secondcloset/fulfillment-utils";
import { Input, Space } from "antd";
import { cloneDeep } from "lodash";
import React, { useEffect } from "react";
import { constructTrackingInputs } from "./helpers";
import { LotTrackingList } from "../../../../../../recoil/packingFlow/atoms";
import * as S from "./styles";

interface Props {
  selectedItem: BaseProductItem | VirtualKitItem;
  itemQuantity: number;
  lotTrackings: LotTrackingList;
  setLotTrackings: (v: LotTrackingList) => void;
}

const LotTrackingForm: React.FC<Props> = ({
  selectedItem,
  itemQuantity,
  lotTrackings,
  setLotTrackings,
}) => {
  useEffect(() => {
    const trackingInputs = constructTrackingInputs(selectedItem, itemQuantity);
    setLotTrackings(trackingInputs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, itemQuantity]);

  const renderLabeledInput = (
    label: string,
    value: string,
    onChange: (v: string) => void
  ) => {
    return (
      <S.LabelInputWrap>
        <S.Label>{label}</S.Label>
        <Input
          autoCapitalize="off"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </S.LabelInputWrap>
    );
  };

  const onChange = (
    productID: string,
    fieldName: string,
    index: number,
    value: string
  ) => {
    const newTrackings = cloneDeep(lotTrackings);
    const tracking = newTrackings[productID][index];
    tracking[fieldName] = value;
    setLotTrackings(newTrackings);
  };

  const renderBaseProductTracking = (baseProductID: string) => {
    const trackings = lotTrackings[baseProductID];
    if (!trackings || trackings.length === 0) return null;

    const name = trackings[0].name;
    return (
      <S.TrackingWrap>
        <S.ProductName>
          {name} x {trackings.length}
        </S.ProductName>
        {trackings.map((t, i) => {
          return (
            <Space key={t.name + i}>
              {renderLabeledInput("Lot number", t.lotNumber, (v) => {
                onChange(baseProductID, "lotNumber", i, v);
              })}
              {renderLabeledInput("Serial number", t.serialNumber, (v) => {
                onChange(baseProductID, "serialNumber", i, v);
              })}
            </Space>
          );
        })}
      </S.TrackingWrap>
    );
  };

  return (
    <S.Container>
      {Object.keys(lotTrackings).map((id) => renderBaseProductTracking(id))}
    </S.Container>
  );
};

export default LotTrackingForm;
