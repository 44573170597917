import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useMutation } from "react-query";
import {
  Button,
  Popconfirm,
  Alert,
  PageHeader,
  Card,
  Row,
  Col,
  Space,
} from "antd";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Warehouse } from "@secondcloset/types";

// api
import { createLocationTemplate } from "../../../api/warehouse/locationTemplate";

// components
import PageContainer from "../../../components/PageContainer";
import CreateLocationTemplateForm from "./CreateLocationTemplateForm";

// styles
import * as S from "./styles";

type LocationTemplate = Warehouse.LocationTemplate;

const LocationTemplateIndexPage: React.FC = () => {
  const [name, setName] = useState("");
  const [format, setFormat] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();
  const { mutate: onCreateLocationTemplate, isLoading } = useMutation(
    () =>
      createLocationTemplate({
        name,
        format: `FACILITY-${format.toUpperCase()}`,
      }),
    {
      onSuccess: (locationTemplate: LocationTemplate) => {
        history.push(
          `/location-templates/create/success/${locationTemplate.name}`
        );
      },
      onError: (error: string) => {
        setError(error);
      },
    }
  );

  useEffect(() => {
    setError("");
  }, [name, format]);

  const onSaveClick = () => {
    if (!name) return setError("Name is required");
    if (!format) return setError("Please add labels");
    onCreateLocationTemplate();
  };

  const renderHeaderButtons = () => {
    const isFormEmpty = !name && !format;
    return (
      <Row justify="center" gutter={[8, 16]}>
        <Col xs={24} lg={4}>
          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            disabled={isFormEmpty}
            onConfirm={() => history.push("/location-templates")}
          >
            <Button
              size="large"
              icon={<CloseOutlined />}
              block
              onClick={() =>
                isFormEmpty ? history.push("/location-templates") : null
              }
            >
              Cancel
            </Button>
          </Popconfirm>
        </Col>
        <Col xs={24} lg={4}>
          <Button
            size="large"
            type="primary"
            icon={<SaveOutlined />}
            block
            loading={isLoading}
            onClick={onSaveClick}
          >
            Save
          </Button>
        </Col>
      </Row>
    );
  };

  const buildAlertBox = () => {
    if (!error) return null;
    return (
      <S.AlertBoxWrap>
        <Alert
          message="Validation Error"
          description={error}
          type="error"
          showIcon
        />
      </S.AlertBoxWrap>
    );
  };

  return (
    <PageContainer withPadding>
      <PageHeader
        title="Create Location Template"
        onBack={() => window.history.back()}
      />
      {buildAlertBox()}
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <Card bordered>
          <CreateLocationTemplateForm
            name={name}
            onNameChange={setName}
            setFormat={setFormat}
          />
        </Card>
        {renderHeaderButtons()}
      </Space>
    </PageContainer>
  );
};

export default LocationTemplateIndexPage;
