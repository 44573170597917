import React, { useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import {
  Input,
  Select,
  Form,
  FormInstance,
  notification,
  Space,
  Button,
  Row,
  Col,
  PageHeader,
  Card,
} from "antd";
import { CheckOutlined, UndoOutlined } from "@ant-design/icons";
import { ErrorAlert } from "@secondcloset/web-components";
import { Fulfillment, Warehouse } from "@secondcloset/types";

import { MANUAL_ITEM_TYPE, MANUAL_ITEM_TYPE_OPTIONS } from "../helpers";
import { createManualItem } from "../../../api/warehouse";
import PageContainer from "../../../components/PageContainer";

import * as S from "./styles";
import OrderAndCustomerSearchBar from "../../../components/OrderAndCustomerSearchBar";

type ManualItem = Warehouse.ManualItem;
type ManualItemType = Warehouse.ManualItemType;
type Order = Fulfillment.Order;

const ManualItemCreatePage: React.FC = () => {
  const history = useHistory();
  const [form]: FormInstance[] = Form.useForm();
  const [error, setError] = useState<string>("");
  const [order, setOrder] = useState<Order>();
  const [isSearching, setSearching] = useState<boolean>(false);
  const [noteRequired, setNoteRequired] = useState<boolean>(false);
  const [manualItemType, setManualItemType] = useState<ManualItemType>();
  const [storageCustomerName, setStorageCustomerName] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const isStorage = manualItemType === MANUAL_ITEM_TYPE.STORAGE;

  const { mutate: create, isLoading } = useMutation(createManualItem, {
    onError: (e: string) => {
      setError(e);
    },
    onSuccess: (manualItem: ManualItem) => {
      setError("");
      history.push({
        pathname: `/manual-items/create/success/${manualItem.code}`,
        state: manualItem,
      });
    },
  });

  const resetForm = () => {
    form.resetFields();
    setOrder(undefined);
    setNotes("");
    setStorageCustomerName("");
    setManualItemType(undefined);
  };

  const submitForm = async () => {
    setError("");
    const isValid = await form.validateFields();
    if (!isValid) return;

    if (!order && !storageCustomerName) {
      return notification.error({
        message: "Please Lookup and Select an Order",
      });
    }

    create({
      type: manualItemType,
      customer_name:
        order?.customer?.name?.trim() || storageCustomerName || undefined,
      organization_id: order?.organization?.id.trim() || undefined,
      order_number: order?.external_order_number.trim() || undefined,
      note: notes.trim() || undefined,
    });
  };

  const handleError = (errorInfo: any) => {
    setError(errorInfo?.message);
  };

  const handleTypeChange = (value: ManualItemType) => {
    setNoteRequired(value === MANUAL_ITEM_TYPE.OTHER);
    setManualItemType(value);
  };

  const renderItemTypeSelectInput = () => {
    return (
      <Form.Item
        name="type"
        label="Item Type"
        rules={[
          {
            required: true,
            message: "Item type is required!",
          },
        ]}
      >
        <Select
          value={manualItemType}
          size="large"
          options={MANUAL_ITEM_TYPE_OPTIONS}
          placeholder="Select type"
          onChange={handleTypeChange}
          allowClear
        />
      </Form.Item>
    );
  };

  const renderNoteInput = () => {
    return (
      <Form.Item
        name="note"
        label="Note"
        rules={[
          {
            required: noteRequired,
            message: "Note is required for `Other` type of items!",
          },
        ]}
      >
        <Input.TextArea
          size="large"
          allowClear
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </Form.Item>
    );
  };

  const renderSubmitButton = () => {
    return (
      <Button
        size="large"
        htmlType="button"
        onClick={submitForm}
        type="primary"
        block
        icon={<CheckOutlined />}
        disabled={isSearching}
        loading={isLoading}
      >
        Submit
      </Button>
    );
  };

  const renderResetButton = () => {
    return (
      <Button
        size="large"
        icon={<UndoOutlined />}
        block
        disabled={isSearching}
        htmlType="button"
        onClick={resetForm}
      >
        Reset All
      </Button>
    );
  };

  const renderButtonGroup = () => {
    return (
      <Form.Item>
        <Row gutter={[8, 16]} justify="center">
          <Col xs={24} lg={4}>
            {renderResetButton()}
          </Col>
          <Col xs={24} lg={4}>
            {renderSubmitButton()}
          </Col>
        </Row>
      </Form.Item>
    );
  };

  return (
    <PageContainer withPadding loading={isLoading}>
      <PageHeader
        title="Create Manual Item"
        onBack={() => window.history.back()}
      />
      <ErrorAlert error={error} />
      <Form
        form={form}
        size="large"
        layout="vertical"
        onFinishFailed={handleError}
      >
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Card>
            <Row justify="center" align="middle">
              <Col xs={24}>{renderItemTypeSelectInput()}</Col>
              <Col xs={24}>{renderNoteInput()}</Col>
            </Row>
          </Card>
          <Card>
            <S.Label>{isStorage ? "Storage Customer" : "Order"}</S.Label>
            <Row justify="center" align="middle" gutter={[8, 24]}>
              <Col xs={24}>
                <OrderAndCustomerSearchBar
                  size="large"
                  onLoad={setSearching}
                  onError={setError}
                  onSelect={(value) => {
                    if (isStorage) setStorageCustomerName(value);
                    else setOrder(value);
                  }}
                  type={manualItemType}
                />
              </Col>
            </Row>
          </Card>
          {renderButtonGroup()}
        </Space>
      </Form>
    </PageContainer>
  );
};

export default ManualItemCreatePage;
