import { Warehouse } from "@secondcloset/types";
import { getAPIFromDomain } from "../../lib/api";

type Product = Warehouse.Product;
type ManualItem = Warehouse.ManualItem;

export interface LookupResult {
  item: Product | ManualItem;
  type: string;
}

const basePath = "lookup-engine";

export const fetchItemByIdentifier = async (
  id: string
): Promise<LookupResult> => {
  const api = getAPIFromDomain("november");
  const url = `/${basePath}/code?q=${id}`;
  const response = await api.get(url);
  return response.data as LookupResult;
};
