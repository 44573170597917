import React, { useState } from "react";
import { Result, Button, Spin, Tooltip } from "antd";
import { useQuery } from "react-query";
import { useParams, useHistory } from "react-router";
import { DownloadOutlined } from "@ant-design/icons";

// components
import PageContainer from "../../../components/PageContainer";
import ScanPalletModal from "./ScanPalletModal";
import ItemsTable from "../../Pallets/GroupItemsToPalletPage/ItemsTable";
import BannerWithButton from "../../../components/BannerWithButton";

// api
import { fetchLocationItems } from "../../../api/warehouse";

// styles
import * as S from "./styles";
import { ErrorAlert } from "@secondcloset/web-components";

const BatchMoveSuccessPage: React.FC = () => {
  const params = useParams<{ locationCode: string }>();
  const location = params.locationCode;
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [attachedPalletId, setAttachedPalletId] = useState("");
  const [error, setError] = useState("");

  const { data: locationItems, isLoading: loadingItems } = useQuery(
    ["getLocationItems", location],
    () => fetchLocationItems({ location_code: location }),
    {
      onError: setError,
      onSuccess: (data) => {
        // check if pallet exists at this location
        if (data.items.length) {
          const itemWithPallet = data.items.find(
            (locationItem) => locationItem.pallet_id !== null
          );
          if (itemWithPallet) setAttachedPalletId(itemWithPallet.pallet_id);
        }
      },
    }
  );

  const renderSuccessBanner = () => {
    return (
      <BannerWithButton
        type="info"
        message={`There is a pallet at this location: ${attachedPalletId}`}
        buttonText="Go to pallet"
        onClickButton={() => history.push(`/pallets/${attachedPalletId}`)}
      />
    );
  };

  return (
    <PageContainer withHeader withFooter>
      <S.Container>
        {attachedPalletId ? renderSuccessBanner() : ""}
        <ErrorAlert error={error} />
        <Spin spinning={loadingItems}>
          <Result
            status="success"
            title={`Items have been moved to location: ${location}`}
            extra={[
              <div key="table" style={{ marginBottom: 30 }}>
                <ItemsTable
                  items={locationItems?.items || []}
                  location={location}
                />
              </div>,
              <Button
                type="primary"
                key="home"
                onClick={() => history.goBack()}
                disabled={loadingItems}
              >
                Back
              </Button>,
              <Tooltip
                key="attach"
                title={attachedPalletId ? "There is already a pallet here" : ""}
              >
                <Button
                  icon={<DownloadOutlined />}
                  onClick={() => setIsModalVisible(true)}
                  disabled={loadingItems || !!attachedPalletId}
                >
                  Attach Pallet Sticker
                </Button>
              </Tooltip>,
            ]}
          />
        </Spin>

        <ScanPalletModal
          location={location}
          isVisible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onSubmit={(palletId: string) => {
            setIsModalVisible(false);
            setAttachedPalletId(palletId);
          }}
        />
      </S.Container>
    </PageContainer>
  );
};

export default BatchMoveSuccessPage;
