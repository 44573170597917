import styled from "styled-components";
import { COLORS } from "../../styles";

export const Container = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
  border-bottom: 2px solid ${COLORS.GREY_LIGHT};
`;

export const InfoBlock = styled.div``;
export const BlockTitle = styled.div`
  font-weight: 600;
  margin-bottom: 5px;
`;

export const BlockContent = styled.pre`
  white-space: pre-wrap;
  font-weight: 400;
  font-size: 12px;
  font-family: "Open Sans";
`;
export const BlockGrouper = styled.div``;

export const DescriptionContent = styled.pre``;
