import React, { useMemo } from "react";
import { Col, Table, Typography } from "antd";
import { Warehouse } from "@secondcloset/types";

import NullSafeText from "../../../../components/Table/NullSafeText";
import ProductIdentifiersField from "../../../../components/Table/ProductIdentifiersField";

import * as S from "./styles";

type LocationItem = Warehouse.LocationItem;

interface Props {
  items?: LocationItem[];
  loading?: boolean;
  location?: string;
  showTableHeader?: boolean;
}

const ItemsTable: React.FC<Props> = ({
  items,
  loading,
  location,
  showTableHeader,
}) => {
  const totalQuantity = useMemo(() => {
    if (!items || !items.length) return 0;
    return items.reduce((acc, cv) => {
      return acc + cv.quantity;
    }, 0);
  }, [items]);

  const renderNullSafeText = (v?: string) => <NullSafeText value={v} />;

  const renderItemDetail = (item) => {
    const sku = item?.product?.sku;
    const upc = item?.product?.upc;
    const name = item?.product?.name;
    const productId = item?.product?.id;
    const manualItemCode = item?.manual_item?.code;
    const customer = item?.manual_item?.customer_name;
    return (
      <ProductIdentifiersField
        sku={sku}
        upc={upc}
        name={name}
        code={manualItemCode}
        customer={customer}
        productId={productId}
      />
    );
  };

  const buildColumns = () => {
    return [
      {
        key: "id",
        title: "Item",
        width: 300,
        render: renderItemDetail,
      },
      {
        key: "quantity",
        title: "Quantity",
        width: 120,
        dataIndex: "quantity",
      },
      {
        key: "pallet_id",
        title: "Pallet",
        width: 180,
        dataIndex: "pallet_id",
        render: renderNullSafeText,
      },
    ];
  };

  const renderTableHeader = () => {
    return (
      showTableHeader && (
        <S.TableHeader>
          <Col>
            <Typography.Text strong>
              {location && items?.length ? `Items at Location ${location}` : ""}
            </Typography.Text>
            <Typography.Text strong>Total: {totalQuantity}</Typography.Text>
          </Col>
        </S.TableHeader>
      )
    );
  };

  return (
    <Table
      size="small"
      title={renderTableHeader}
      rowKey={(r) => r.id}
      scroll={{ x: "max-content" }}
      columns={buildColumns()}
      loading={loading}
      bordered
      pagination={false}
      dataSource={items || []}
    />
  );
};

export default ItemsTable;
