export const MANUAL_ITEM_TYPE = {
  STRUCTUBE_PALLET: "structube_pallet",
  IKEA_PALLET: "ikea_pallet",
  ROVE_PALLET: "rove_pallet",
  STORAGE: "storage",
  OTHER: "other",
};

export const MANUAL_ITEM_LABEL = {
  structube_pallet: "STRUCTUBE Pallet",
  ikea_pallet: "IKEA Pallet",
  rove_pallet: "ROVE Pallet",
  storage: "Storage",
  other: "Other",
};

export const MANUAL_ITEM_TYPE_OPTIONS = [
  {
    key: MANUAL_ITEM_TYPE.STRUCTUBE_PALLET,
    value: MANUAL_ITEM_TYPE.STRUCTUBE_PALLET,
    label: MANUAL_ITEM_LABEL[MANUAL_ITEM_TYPE.STRUCTUBE_PALLET],
    disabled: false,
  },
  {
    key: MANUAL_ITEM_TYPE.IKEA_PALLET,
    value: MANUAL_ITEM_TYPE.IKEA_PALLET,
    label: MANUAL_ITEM_LABEL[MANUAL_ITEM_TYPE.IKEA_PALLET],
    disabled: false,
  },
  {
    key: MANUAL_ITEM_TYPE.ROVE_PALLET,
    value: MANUAL_ITEM_TYPE.ROVE_PALLET,
    label: MANUAL_ITEM_LABEL[MANUAL_ITEM_TYPE.ROVE_PALLET],
    disabled: false,
  },
  {
    key: MANUAL_ITEM_TYPE.STORAGE,
    value: MANUAL_ITEM_TYPE.STORAGE,
    label: MANUAL_ITEM_LABEL[MANUAL_ITEM_TYPE.STORAGE],
    disabled: false,
  },
  {
    key: MANUAL_ITEM_TYPE.OTHER,
    value: MANUAL_ITEM_TYPE.OTHER,
    label: MANUAL_ITEM_LABEL[MANUAL_ITEM_TYPE.OTHER],
    disabled: false,
  },
];

export const getManualItemLabelUrl = (id: string): string => {
  return `${process.env.REACT_APP_WAREHOUSE_API}/manual-items/${id}/label`;
};

export const downloadManualItemCSV = (code: string) => {
  const header = [["code"], [code]];
  const csvContent = header.map((e) => e.join(",")).join("\n");
  const prefix = "data:text/csv;charset=utf-8,";
  const encodedUri = prefix + encodeURIComponent(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${code}.csv`);
  document.body.appendChild(link); // Required for FF
  link.click();
};
