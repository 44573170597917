import React from "react";
import { Select } from "antd";
import { Facility } from "@secondcloset/fulfillment-utils";
import WithLabel from "../WithLabel";

interface FacilityDropdownProps {
  selectedFacility: string;
  onSelect: (facility) => void;
}

const FacilityDropdown: React.FC<FacilityDropdownProps> = (props) => {
  const { selectedFacility, onSelect } = props;
  const facilities = Facility.getFulfillmentFacilityList();

  const options = facilities.map((facility) => {
    return (
      <Select.Option value={facility.code} key={facility.code}>
        {facility.code.trim().toUpperCase()}
      </Select.Option>
    );
  });

  return (
    <WithLabel name="Facility">
      <Select size="large" value={selectedFacility} onSelect={onSelect}>
        <Select.Option value={"All"} key={"All"}>
          All
        </Select.Option>
        {options}
      </Select>
    </WithLabel>
  );
};

export default FacilityDropdown;
