import { Warehouse } from "@secondcloset/types";
import { Table } from "antd";
import React from "react";
import { PaginationInfo } from "../../../../api/warehouse/commonInterfaces";
import { buildTablePagination } from "../../../../components/Pagination/helper";
import ItemCountFooter from "../../../../components/Table/ItemCountFooter";
import NullSafeText from "../../../../components/Table/NullSafeText";
import ProductIdentifiersField from "../../../../components/Table/ProductIdentifiersField";

type LocationItem = Warehouse.LocationItem;

interface Props {
  locationItems: LocationItem[];
  paginationInfo?: PaginationInfo;
  loading: boolean;
  onPageChange?: (page: number) => void;
}

const LocationItemsTable: React.FC<Props> = ({
  locationItems,
  paginationInfo,
  loading,
  onPageChange,
}) => {
  const renderNullSafeText = (v?: string) => <NullSafeText value={v} />;

  const renderItemOwnerField = (record) => {
    const itemOwner = record?.product?.organization.name;
    return <NullSafeText value={itemOwner} />;
  };

  const renderItemDetail = (item) => {
    const sku = item?.product?.sku;
    const upc = item?.product?.upc;
    const name = item?.product?.name;
    const productId = item?.product?.id;
    const manualItemCode = item?.manual_item?.code;
    const customer = item?.manual_item?.customer_name;
    return (
      <ProductIdentifiersField
        sku={sku}
        upc={upc}
        name={name}
        code={manualItemCode}
        customer={customer}
        productId={productId}
      />
    );
  };

  const buildTableColumns = () => {
    return [
      {
        title: "Item",
        key: "id",
        render: renderItemDetail,
      },
      {
        title: "Pallet ID",
        dataIndex: "pallet_id",
        key: "pallet_id",
        render: renderNullSafeText,
      },
      {
        title: "Organization",
        render: renderItemOwnerField,
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        key: "quantity",
        width: "5%",
        render: renderNullSafeText,
      },
    ];
  };

  const buildPagination = () => {
    return buildTablePagination({
      showQuickJumper: true,
      paginationInfo,
      onChange: onPageChange,
    });
  };

  const renderTableFooter = () => (
    <ItemCountFooter count={paginationInfo?.totalItems || 0} />
  );

  return (
    <Table
      bordered
      size="small"
      dataSource={locationItems}
      columns={buildTableColumns()}
      rowKey={(r) => r.id}
      footer={renderTableFooter}
      pagination={buildPagination()}
      loading={loading}
    />
  );
};

export default LocationItemsTable;
