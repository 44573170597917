import styled from "styled-components";
import { COLORS } from "../../styles";

export const Container = styled.div``;

export const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const SectionTitle = styled.div`
  margin-bottom: 5px;
  color: ${COLORS.GREY};
`;
export const DateWrap = styled.div``;
export const CarrierCodeWrap = styled.div`
  margin-left: 20px;
`;

export const SearchButtonWrap = styled.div`
  margin-left: 20px;
  align-self: flex-end;
`;

export const AlertBoxWrap = styled.div`
  margin: 20px 0;
`;

export const ManifestWrap = styled.div`
  margin-top: 20px;
`;

export const ManifestURL = styled.a``;
