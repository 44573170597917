import { Warehouse } from "@secondcloset/types";
import { Button, Modal, Table, TablePaginationConfig } from "antd";
import { startCase } from "lodash-es";
import React, { useState } from "react";
import { useMutation } from "react-query";

import { batchGeneratePalletLabel } from "../../../api/warehouse";
import { PaginationInfo } from "../../../api/warehouse/commonInterfaces";
import { buildTablePagination } from "../../../components/Pagination/helper";

import NullSafeDate from "../../../components/Table/NullSafeDate";
import {
  findAndRemoveIdFromList,
  isPdfDownloading,
  updateIdsListWithNewId,
} from "./helpers";
import * as S from "./styles";

type PalletRequestLog = Warehouse.PalletRequestLog;

interface Props {
  requestLogs: PalletRequestLog[];
  paginationInfo?: PaginationInfo;
  loading: boolean;
  onPageChange: (page: number, newPageSize?: number) => void;
  setError: (err: string) => void;
}

const RequestLogsTable: React.FC<Props> = ({
  requestLogs,
  paginationInfo,
  loading,
  onPageChange,
  setError,
}) => {
  const [pdfIdsBeingDownloaded, setPdfIdsBeingDownloaded] = useState<string[]>(
    []
  );
  const { mutate: downloadQrCodes } = useMutation(
    (requestId: string) => batchGeneratePalletLabel(requestId),
    {
      onMutate: (requestId) => {
        const newPdfsBeingDownloaded = updateIdsListWithNewId(
          pdfIdsBeingDownloaded,
          requestId
        );
        setPdfIdsBeingDownloaded(newPdfsBeingDownloaded);
      },
      onSettled: (data, err, requestId) => {
        const newPdfsBeingDownloaded = findAndRemoveIdFromList(
          pdfIdsBeingDownloaded,
          requestId
        );
        setPdfIdsBeingDownloaded(newPdfsBeingDownloaded);
      },
      onError: setError,
    }
  );

  const handleDownloadPdf = (record: PalletRequestLog) => {
    downloadQrCodes(record.id);
  };
  const renderStatus = (_, record: PalletRequestLog) => {
    const { status, fail_reason } = record;
    const parsedString = startCase(status.replace("-", " "));
    const isFailed = fail_reason && status === "failed";

    const showErrorReason = () => {
      Modal.error({
        title: "Request failed because...",
        content: fail_reason,
      });
    };

    const renderReason = () => {
      return (
        <Button
          type="link"
          onClick={showErrorReason}
          style={{ padding: "0 10px" }}
        >
          (reason)
        </Button>
      );
    };

    return (
      <S.StatusWrapper>
        <S.Status status={status}>{parsedString}</S.Status>
        {isFailed ? renderReason() : ""}
      </S.StatusWrapper>
    );
  };

  const renderCell = (content: string) => {
    return <S.Cell>{content}</S.Cell>;
  };

  const renderDate = (date: string) => <NullSafeDate date={date} />;

  const renderSource = (source: string) => startCase(source);

  const renderDownloadPdfButton = (_, record: PalletRequestLog) => {
    const { status } = record;
    if (status !== ("success" as Warehouse.PalletRequestStatus)) return "-";
    return (
      <Button
        type="link"
        style={{ padding: 0 }}
        onClick={() => handleDownloadPdf(record)}
        loading={isPdfDownloading(record.id, pdfIdsBeingDownloaded)}
      >
        Download QR Code(s) PDF
      </Button>
    );
  };

  const getPagination = (): TablePaginationConfig => {
    return buildTablePagination({
      paginationInfo,
      showQuickJumper: true,
      showSizeChanger: true,
      onChange: onPageChange,
    });
  };

  const buildTableColumns = () => {
    return [
      {
        title: "# of IDs",
        dataIndex: "count",
        width: 70,
      },
      {
        title: "Requested By",
        dataIndex: "user_email",
        width: 250,
        ellipsis: true,
        render: renderCell,
      },
      {
        title: "Status",
        dataIndex: "status",
        width: 50,
        render: renderStatus,
      },
      {
        title: "Source",
        dataIndex: "source",
        width: 140,
        render: renderSource,
      },
      {
        title: "PDF Download Link",
        dataIndex: "id",
        width: 100,
        render: renderDownloadPdfButton,
      },
      {
        title: "Date Created",
        dataIndex: "created_at",
        width: 200,
        render: renderDate,
      },
    ];
  };

  return (
    <Table
      size="small"
      scroll={{ x: "max-content" }}
      bordered
      dataSource={requestLogs}
      columns={buildTableColumns()}
      rowKey="id"
      loading={loading}
      pagination={getPagination()}
    />
  );
};

export default RequestLogsTable;
