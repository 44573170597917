import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Layout } from "antd";

import routes from "./routes";
import PrivateRoute from "./PrivateRoute";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";

import { AppContent } from "./styles";

const Router: React.FC = () => {
  const privateRoutes = routes
    .filter((r) => r.isPrivate)
    .map((route) => <PrivateRoute exact {...route} key={route.key} />);

  const publicRoutes = routes
    .filter((r) => !r.isPrivate)
    .map((route) => {
      return <Route exact {...route} key={route.key} />;
    });

  return (
    <Layout>
      <BrowserRouter>
        <AppHeader />
        <AppContent>
          <Switch>
            {privateRoutes}
            {publicRoutes}
          </Switch>
        </AppContent>
        <AppFooter />
      </BrowserRouter>
    </Layout>
  );
};

export default Router;
