import styled from "styled-components";
import { COLORS } from "../../../../../../styles";

export const Container = styled.div`
  margin-top: 20px;
  max-height: 300px;
  overflow-y: auto;
`;

export const TrackingWrap = styled.div`
  margin-top: 20px;
`;
export const ProductName = styled.div`
  font-weight: 600;
  border-bottom: 1px solid ${COLORS.GREY_LIGHT};
  margin-bottom: 10px;
`;

export const LabelInputWrap = styled.div``;
export const Label = styled.div``;
