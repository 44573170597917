import React, { useState } from "react";

// styles
import * as S from "./styles";

// types
import { Fulfillment } from "@secondcloset/types";
import { useRecoilValue } from "recoil";
import {
  packingFlowOrderState,
  packingFlowShipmentState,
} from "../../recoil/packingFlow/atoms";
import ShipmentDetailsModal from "../ShipmentDetailsModal";
import { Button } from "antd";
type ExternalCarrierShippingMethod = Fulfillment.ExternalCarrierShippingMethod;

const OrderInfoSection: React.FC = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const packingOrder = useRecoilValue(packingFlowOrderState);
  const shipment = useRecoilValue(packingFlowShipmentState);

  const renderCustomer = () => {
    const shippingMethod =
      shipment?.shipping_method as ExternalCarrierShippingMethod;
    const customerName = shippingMethod?.ship_to_address?.contact_name;
    return customerName;
  };

  const renderAddress = () => {
    const shippingMethod =
      shipment?.shipping_method as ExternalCarrierShippingMethod;
    const shipTo = shippingMethod?.ship_to_address || {};
    const shipToAddress = [
      `${shipTo.address} ${shipTo.city}`,
      `${shipTo.province} ${shipTo.country} `,
      `${shipTo.postal_code}`,
    ];
    return shipToAddress.map((e) => <div key={e}>{e}</div>);
  };

  const marketplaceNotes = packingOrder?.notes || "n/a";
  const internalNotes = packingOrder?.internal_notes || "n/a";
  const packingNotes = packingOrder?.organization?.packing_notes || "n/a";
  const orderNumber = packingOrder?.external_order_number;

  const getMarketplaceNotesTitle = () => {
    switch (packingOrder?.platform) {
      case "shopify":
        return "Shopify Notes";
      case "woo_commerce":
        return "WooCommerce Notes";
      case "shipstaion":
        return "Shipstation Notes";
      default:
        return "Marketplace Notes";
    }
  };

  const renderMoreInfoButton = () => {
    return (
      <Button
        size="small"
        type="primary"
        ghost
        onClick={() => setModalVisible(true)}
      >
        More Info
      </Button>
    );
  };

  return (
    <S.Container>
      <S.BlockGrouper>
        <S.InfoBlock>
          <S.BlockTitle>CUSTOMER:</S.BlockTitle>
          <S.BlockContent>{renderCustomer()}</S.BlockContent>
        </S.InfoBlock>
        <S.InfoBlock>
          <S.BlockTitle>SHIP TO:</S.BlockTitle>
          <S.BlockContent>{renderAddress()}</S.BlockContent>
        </S.InfoBlock>
        <S.InfoBlock>
          <S.BlockTitle>SIGNATURE REQUIRED:</S.BlockTitle>
          <S.BlockContent>
            {packingOrder?.signature_required ? "Yes" : "No"}
          </S.BlockContent>
        </S.InfoBlock>
      </S.BlockGrouper>
      <S.BlockGrouper>
        <S.InfoBlock>
          <S.BlockTitle>ORDER #:</S.BlockTitle>
          <S.BlockContent>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_SIERRA_DOMAIN}/fulfillment/orders/${packingOrder?.id}`}
            >
              {orderNumber}
            </a>
          </S.BlockContent>
        </S.InfoBlock>
        <S.InfoBlock>
          <S.BlockTitle>PACKING NOTES:</S.BlockTitle>
          <S.BlockContent>{packingNotes}</S.BlockContent>
        </S.InfoBlock>
      </S.BlockGrouper>
      <S.BlockGrouper>
        <S.InfoBlock>
          <S.BlockTitle>INTERNAL NOTES:</S.BlockTitle>
          <S.BlockContent>{internalNotes}</S.BlockContent>
        </S.InfoBlock>
        <S.InfoBlock>
          <S.BlockTitle>
            {getMarketplaceNotesTitle().toUpperCase()}:
          </S.BlockTitle>
          <S.BlockContent>{marketplaceNotes}</S.BlockContent>
        </S.InfoBlock>
      </S.BlockGrouper>
      {renderMoreInfoButton()}
      {shipment && (
        <ShipmentDetailsModal
          shipment={shipment}
          onCancel={() => setModalVisible(false)}
          visible={modalVisible}
          footer={null}
        />
      )}
    </S.Container>
  );
};

export default OrderInfoSection;
