import styled from "styled-components";
import { COLORS } from "../../../../../styles";

export const Container = styled.div``;

export const LotTrackingButton = styled.div`
  color: ${COLORS.BLUE};
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
